import React from 'react';
import { useStoreValue } from 'store';
import { LayoutPage, SystemUnavailable } from 'components';
import { APPROVED, DECLINED, REFERRED, SERVICE_UNAVAILABLE, CONDITIONALLY_APPROVED } from 'store/constants';
import { OutcomeApproved } from './OutcomeApproved/OutcomeApproved';
import { OutcomeDeclined } from './OutcomeDeclined/OutcomeDeclined';
import { OutcomeReferred } from './OutcomeReferred/OutcomeReferred';
import { OutcomeConditionallyApproved } from './OutcomeConditionallyApproved';

const OutcomeComponent = ({ storeState }) => {
  switch (storeState.applicationStatus) {
    case APPROVED:
      return <OutcomeApproved storeState={storeState} />;
    case DECLINED:
      return <OutcomeDeclined storeState={storeState} />;
    case REFERRED:
      return <OutcomeReferred storeState={storeState} />;
    case SERVICE_UNAVAILABLE:
      return <SystemUnavailable applicationRef={storeState.applicationRef} />;
    case CONDITIONALLY_APPROVED:
      return <OutcomeConditionallyApproved storeState={storeState} />;
    default:
      return <OutcomeReferred storeState={storeState} />;
  }
};
function Outcome() {
  const [storeState] = useStoreValue();
  return (
    <LayoutPage noChildrenPadding>
      <OutcomeComponent storeState={storeState} />
    </LayoutPage>
  );
}

export { Outcome };
