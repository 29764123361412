/* eslint-disable no-script-url */
/* eslint-disable consistent-return */
import React from 'react';
import { Text, Link, Container } from 'basis';
import { SHARE_BANK_STATEMENT, UPLOAD_PAYSLIPS, UPLOAD_DOCUMENTS, ONLINE_BANK_CONNECT } from 'store';
import { isFeatureOn } from 'featureToggles';
import { byCard } from 'utils';
import { PRODUCT_CODE_AFS } from '_config';
import { StyledUL } from '../../components';

export const ShareBankStatementContent = ({ trackEventSender, handleOpenModal }) => {
  return (
    <>
      <Text textStyle="body1">
        <strong>We could give you a decision now</strong>, by securely logging in and sharing your bank statement
        online.{' '}
        <Link
          href={`#${SHARE_BANK_STATEMENT}`}
          newTab={false}
          onClick={() => {
            trackEventSender({
              category: 'text-link',
              action: 'interaction',
              location: 'Verify your income',
              label: 'Safely and securely verify your income',
            });
            handleOpenModal(SHARE_BANK_STATEMENT);
          }}
        >
          Safely and securely verify your income.
        </Link>
      </Text>
    </>
  );
};
export const OnlineBankConnectContent = ({ trackEventSender, handleOpenModal }) => {
  return (
    <>
      <StyledUL>
        <li>Secure and easy</li>
        <li>Fast response</li>
        <li>One-time access to your bank statements. Your login details are never stored</li>
      </StyledUL>
      <Container padding="4 0">
        <Link
          href={`#${SHARE_BANK_STATEMENT}`}
          newTab={false}
          onClick={() => {
            trackEventSender({
              category: 'text-link',
              action: 'interaction',
              location: 'Verify your income',
              label: 'See how this works',
            });
            handleOpenModal(ONLINE_BANK_CONNECT);
          }}
        >
          See how this works
        </Link>
      </Container>
    </>
  );
};

export const UploadPayslipsContent = ({ trackEventSender, handleOpenModal }) => {
  return (
    <Text textStyle="body1">
      {isFeatureOn('dynamicCreditLimit')
        ? `We'll process your uploaded payslips. `
        : `We’ll automatically process your uploaded payslips. `}
      <Link
        href={`#${UPLOAD_PAYSLIPS}`}
        newTab={false}
        onClick={() => {
          trackEventSender({
            category: 'text-link',
            action: 'interaction',
            location: 'Upload your payslips',
            label: 'Find out more',
          });
          handleOpenModal(UPLOAD_PAYSLIPS);
        }}
      >
        {' '}
        Find out more.
      </Link>{' '}
      <strong>This gives you a decision within 1-2 days.</strong>
    </Text>
  );
};

export const UploadDocumentsContent = ({ trackEventSender, handleOpenModal }) => {
  return (
    <Text textStyle="body1">
      You can manually upload your bank statements, IRD summary of earnings and financial statements.{' '}
      <Link
        href={`#${UPLOAD_DOCUMENTS}`}
        newTab={false}
        onClick={() => {
          trackEventSender({
            category: 'text-link',
            action: 'interaction',
            location: 'Upload other documents',
            label: 'Find out more',
          });
          handleOpenModal(UPLOAD_DOCUMENTS);
        }}
      >
        Find out more.
      </Link>{' '}
      <br />
      <strong>This gives you a decision within 2 days.</strong>
    </Text>
  );
};
export const DocumentUploadContent = ({ trackEventSender }) => {
  return (
    <>
      <StyledUL>
        <li>Manually upload your income documents (e.g.Payslips, Bank or Centrelink statements etc)</li>
        <li>For a quick response, upload a clear legible copy of your payslip(s)</li>
        <li>
          Other documents can take <strong>up to 1-2 business days</strong> to process.
        </li>
      </StyledUL>
    </>
  );
};

export const DocumentUploadContentAU = ({ trackEventSender }) => {
  return (
    <>
      <StyledUL>
        <li>Manually upload your income documents (e.g. payslips, bank or Centrelink statements etc.)</li>
        <li>For a quick response, make sure your documents are clearly legible</li>
        <li>
          {byCard({
            [PRODUCT_CODE_AFS]: (
              <>
                The documents can take <strong>up to 2 business hours</strong> to process.
              </>
            ),
            default: (
              <>
                Other documents can take <strong>up to 1-2 business days</strong> to process.
              </>
            ),
          })}
        </li>
      </StyledUL>
    </>
  );
};
