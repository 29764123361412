import React from 'react';
import { Text, Button, Container, Flex } from 'basis';
import { Modal } from 'components/modal/Modal';

const modalStyle = {
  width: 600,
};

const DvsModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} style={modalStyle}>
      <Container padding="3" padding-md="4">
        <Container padding="3" padding-md="4">
          <Text textStyle="heading4" margin="1 0 3 0" margin-md="1 0 3 0">
            ID check with the document issuer
          </Text>

          <Container padding="3 0 3 0">
            <Text textStyle="body2">
              If you agree to an ID check with the document issuer we will use the document details you have entered
              (either your Driver licence or passport details) and check them with the issuer of that document using
              third party systems, to verify your identity.
            </Text>
          </Container>

          <Flex placeItems="center">
            <Container padding="2">
              <Button width="160px" onClick={onClose}>
                Close
              </Button>
            </Container>
          </Flex>
        </Container>
      </Container>
    </Modal>
  );
};

export { DvsModal };
