import React from 'react';
import { Text, Stack, Grid, useTheme as useBasisTheme } from 'basis';
import { APPLE_MERCHANT } from '_config';
import { useTheme } from '@emotion/react';
import phone from 'images/icon-phone.svg';
import { displayCreditLimit } from 'utils';
import { LayoutOutcomePage, ReturnButton } from 'components';
import { PhoneIcon } from '../Outcome.styles';
import { AppleApplicantsBox } from '../components/AppleApplicantsBox';
import { WeWillBeInTouch } from '../components/WeWillBeInTouch';

function ProofOfIncome({ storeState }) {
  const basisTheme = useBasisTheme();
  const theme = useTheme();
  const {
    applicationRef,
    applyAndBuy: { merchantName },
  } = storeState;
  const creditLimit = displayCreditLimit(storeState);

  return (
    <LayoutOutcomePage useConfetti>
      <Stack gap="8">
        <Text
          as="h1"
          textStyle="heading5"
          textStyle-md="heading4"
          color={theme.headingColor}
          testId="creditLimitOutcome"
        >
          Congratulations!
          <br />
          {`You’re conditionally approved for $${creditLimit}`}
          <br />
        </Text>

        <Text testId="gotEverythingForNow">
          Your application number is <strong data-hj-suppress>{applicationRef}</strong>.
        </Text>

        <Text>We've got everything we need for now.</Text>

        <Grid>
          <Grid.Item rowSpan="1">
            <PhoneIcon src={phone} theme={basisTheme} />
          </Grid.Item>
          <Grid.Item rowSpan="1">
            <WeWillBeInTouch />
          </Grid.Item>
        </Grid>

        {merchantName === APPLE_MERCHANT && <AppleApplicantsBox storeState={storeState} />}
        <ReturnButton />
      </Stack>
    </LayoutOutcomePage>
  );
}

export { ProofOfIncome };
