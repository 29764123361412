import { CHANNEL_APPLY_AND_SHOP, CHANNEL_INSTORE } from '_config';

export const APPLE_MERCHANT = 'Apple';

export const SMP = 'smp';
export const DAVID_JONES_APPLY_AND_SHOP_INSTANTLY_MERCHANT_ID = '000421316';
export const DAVID_JONES_QR_CODE_MERCHANT_ID = '000421441';
export const DAVID_JONES_PARTNER_WEBSITE_MERCHANT_ID = '000421844';
export const DAVID_JONES_SHORT_CODE = '299';
export const DAVID_JONES_APPLY_AND_BUY = {
  shortCode: DAVID_JONES_SHORT_CODE,
  merchantName: 'David Jones',
};
export const DAVID_JONES_APPLY_AND_SHOP_INSTANTLY = {
  channel: CHANNEL_APPLY_AND_SHOP,
  ...DAVID_JONES_APPLY_AND_BUY,
};
export const DAVID_JONES_PREMIERE_APPLY_AND_SHOP_INSTANTLY = {
  solCode: 'HJ2',
  ...DAVID_JONES_APPLY_AND_SHOP_INSTANTLY,
};
export const DAVID_JONES_PRESTIGE_APPLY_AND_SHOP_INSTANTLY = {
  solCode: 'HJ3',
  ...DAVID_JONES_APPLY_AND_SHOP_INSTANTLY,
};
export const DAVID_JONES_QR_CODE = {
  channel: CHANNEL_INSTORE,
  ...DAVID_JONES_APPLY_AND_BUY,
};
export const DAVID_JONES_PREMIERE_QR_CODE = {
  solCode: 'HX2',
  ...DAVID_JONES_QR_CODE,
};
export const DAVID_JONES_PRESTIGE_QR_CODE = {
  solCode: 'HX3',
  ...DAVID_JONES_QR_CODE,
};
export const DAVID_JONES_PREMIERE_PARTNER_WEBSITE = {
  solCode: 'HL2',
  ...DAVID_JONES_APPLY_AND_SHOP_INSTANTLY,
};
export const DAVID_JONES_PRESTIGE_PARTNER_WEBSITE = {
  solCode: 'HL3',
  ...DAVID_JONES_APPLY_AND_SHOP_INSTANTLY,
};

export const HARVEY_NORMAN_PARAMS = {
  shortCode: '460',
  merchantSourceCode: 'LN',
};

export const IN_STORE_MERCHANT_PARAMS = {
  solCode: 'FK1',
  channel: CHANNEL_INSTORE,
};

export const APPLE_QR_MERCHANT_PARAMS = {
  shortCode: '513',
  ...IN_STORE_MERCHANT_PARAMS,
};

export const HARVEY_NORMAN_MANUK_COM_MERCHANT_ID = '090002412';
export const HARVEY_NORMAN_MANUK_ELE_MERCHANT_ID = '090002415';
export const HARVEY_NORMAN_WAIRAU_PARK_ELE_MERCHANT_ID = '090002470';
export const HARVEY_NORMAN_WAIRAU_PARK_COM_MERCHANT_ID = '090002469';
export const HARVEY_NORMAN_WESTGATE_ELE_MERCHANT_ID = '090680558';
export const HARVEY_NORMAN_QR = '090685742';
export const HARVEY_NORMAN_EXTERNAL_QR = '090685893';
export const NOEL_LEEMING_QR = '090685743';
export const ALPINE_SPAS_QR = '090685744';
export const APPLE_QR = '090685745';
export const BIG_SAVE_FURNITURE_QR = '090685746';
export const FREEDOM_FURNITURE_QR = '090685747';
export const HARRISONS_AT_HOME_QR = '090685748';
export const MICHEAL_HILL_QR = '090685749';
export const ECO_TRAVELS_QR = '090685750';
export const GLOBAL_TRAVEL_NETWORK_QR = '090685751';
export const HOUSE_OF_TRAVEL_QR = '090685752';
export const TIER_3_QR = '090685757';
export const PB_TECH_QR = '090685935';
export const HEATHCOTE_APPLIANCES_EXTERNAL_QR = '090685981';
export const HEATHCOTE_APPLIANCES_QR = '090686039';
export const JB_HI_FI_QR = '090686060';
export const JB_HI_FI_EXTERNAL_QR = '090686061';

export const HARVEY_NORMAN_IN_STORE_MERCHANTS = [
  HARVEY_NORMAN_MANUK_COM_MERCHANT_ID,
  HARVEY_NORMAN_MANUK_ELE_MERCHANT_ID,
  HARVEY_NORMAN_WAIRAU_PARK_ELE_MERCHANT_ID,
  HARVEY_NORMAN_WAIRAU_PARK_COM_MERCHANT_ID,
  HARVEY_NORMAN_WESTGATE_ELE_MERCHANT_ID,
  HARVEY_NORMAN_QR,
  HARVEY_NORMAN_EXTERNAL_QR,
];

// APPLE_QR is not included here as it has different shortCode
export const TIER_3_IN_STORE_MERCHANTS = [
  NOEL_LEEMING_QR,
  ALPINE_SPAS_QR,
  BIG_SAVE_FURNITURE_QR,
  FREEDOM_FURNITURE_QR,
  HARRISONS_AT_HOME_QR,
  MICHEAL_HILL_QR,
  ECO_TRAVELS_QR,
  GLOBAL_TRAVEL_NETWORK_QR,
  HOUSE_OF_TRAVEL_QR,
  TIER_3_QR,
  PB_TECH_QR,
  HEATHCOTE_APPLIANCES_EXTERNAL_QR,
  HEATHCOTE_APPLIANCES_QR,
  JB_HI_FI_QR,
  JB_HI_FI_EXTERNAL_QR,
];

/*
 * Merchant list: https://di.latitudefinancial.com/wiki/pages/viewpage.action?pageId=120663117
 * Sol code: https://di.latitudefinancial.com/wiki/display/CS/Current+Sol+Codes
 */
export const merchants = {
  // QR Code,
  [HARVEY_NORMAN_MANUK_COM_MERCHANT_ID]: {
    merchantName: 'HARVEY NORMAN - MANUK COM',
  },
  [HARVEY_NORMAN_MANUK_ELE_MERCHANT_ID]: {
    merchantName: 'HARVEY NORMAN - MANUK ELE',
  },
  [HARVEY_NORMAN_WAIRAU_PARK_COM_MERCHANT_ID]: {
    merchantName: 'HARVEY NORMAN - WAIRAU PARK COMPUTERS',
  },
  [HARVEY_NORMAN_WESTGATE_ELE_MERCHANT_ID]: {
    merchantName: 'Harvey Norman - Westgate Electrical',
  },
  [HARVEY_NORMAN_WAIRAU_PARK_ELE_MERCHANT_ID]: {
    merchantName: 'Harvey Norman - Wairau Park Electrical',
  },
  [HARVEY_NORMAN_QR]: {
    merchantName: 'Harvey Norman - QR',
    merchantSourceCode: 'YA',
  },
  [HARVEY_NORMAN_EXTERNAL_QR]: {
    merchantName: 'Harvey Norman - External QR',
    merchantSourceCode: 'YA',
    solCode: 'FD1',
  },
  [NOEL_LEEMING_QR]: {
    merchantName: 'Noel Leeming - QR',
    merchantSourceCode: 'YA',
  },
  [ALPINE_SPAS_QR]: {
    merchantName: 'Alpine Spas - QR',
    merchantSourceCode: 'YA',
  },
  [APPLE_QR]: {
    merchantName: 'Apple - QR',
    merchantSourceCode: 'YA',
  },
  [BIG_SAVE_FURNITURE_QR]: {
    merchantName: 'Big Save Furniture - QR',
    merchantSourceCode: 'LB',
  },
  [FREEDOM_FURNITURE_QR]: {
    merchantName: 'Freedom Furniture - QR',
    merchantSourceCode: 'LB',
  },
  [HARRISONS_AT_HOME_QR]: {
    merchantName: 'Harrisons at Home - QR',
    merchantSourceCode: 'LD',
  },
  [MICHEAL_HILL_QR]: {
    merchantName: 'Michael Hill - QR',
    merchantSourceCode: 'YA',
  },
  [ECO_TRAVELS_QR]: {
    merchantName: 'Eco Travels - QR',
    merchantSourceCode: 'YA',
  },
  [GLOBAL_TRAVEL_NETWORK_QR]: {
    merchantName: 'Global Travel Network - QR',
    merchantSourceCode: 'YA',
  },
  [HOUSE_OF_TRAVEL_QR]: {
    merchantName: 'House of Travel - QR',
    merchantSourceCode: 'YA',
  },
  [TIER_3_QR]: {
    merchantName: 'Tier 3 - QR',
    merchantSourceCode: 'YA',
  },
  [PB_TECH_QR]: {
    merchantName: 'PB Tech - QR',
    merchantSourceCode: 'YA',
    solCode: 'FK1',
  },
  [HEATHCOTE_APPLIANCES_EXTERNAL_QR]: {
    merchantName: 'Heathcote Appliances - External QR',
    merchantSourceCode: 'YA',
    solCode: 'FD1',
  },
  [HEATHCOTE_APPLIANCES_QR]: {
    merchantName: 'Heathcote Appliances - QR',
    merchantSourceCode: 'YA',
    solCode: 'FK1',
  },
  [JB_HI_FI_QR]: {
    merchantName: 'JB Hi Fi - QR',
    merchantSourceCode: 'YA',
    solCode: 'FK1',
  },
  [JB_HI_FI_EXTERNAL_QR]: {
    merchantName: 'JB Hi Fi - External QR',
    merchantSourceCode: 'YA',
    solCode: 'FD1',
  },
  [DAVID_JONES_APPLY_AND_SHOP_INSTANTLY_MERCHANT_ID]: {
    merchantName: 'David Jones',
  },
  [DAVID_JONES_QR_CODE_MERCHANT_ID]: {
    merchantName: 'David Jones',
  },
  [DAVID_JONES_PARTNER_WEBSITE_MERCHANT_ID]: {
    merchantName: 'David Jones',
  },

  // Apply and Buy,
  '090680140': {
    merchantName: APPLE_MERCHANT,
    returnUrl: 'https://www.apple.com/nz/shop/browse/financing',
  },
  '090355598': {
    merchantName: 'Harvey Norman',
    returnUrl: 'https://www.harveynorman.co.nz/index.php?dispatch=onepage.checkout',
  },
  '090684415': {
    merchantName: 'Skinny Mobile',
    returnUrl: 'https://skinny.co.nz/ifpcheckout/',
  },
  '090681227': {
    merchantName: 'Rubber Monkey',
    returnUrl: 'https://www.rubbermonkey.co.nz/Checkout/ProcessOrderCallback',
  },
};
