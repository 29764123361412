import React, { useState } from 'react';
import { Text, Icon, Stack } from 'basis';
import { formatNumber } from 'utils';
import styled from '@emotion/styled';
import { Tooltip, IconButton } from '@chakra-ui/react';

const LoanBalanceToolTip = () => {
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <Tooltip
      placement="top"
      hasArrow
      width={245}
      color="white"
      backgroundColor="grey.t75"
      fontSize="md"
      textAlign="center"
      openDelay={50}
      closeOnClick={false}
      pointerEvents="all"
      isOpen={showTooltip}
      onOpen={() => {
        setShowTooltip(true);
      }}
      label={
        <TooltipContent>
          A calculated amount if you'd only make the minimum repayments
          <IconButton
            variant="ghost"
            style={{ background: 'inherit', outline: 'none' }}
            icon={<Icon name="cross" color="white" />}
            onClick={() => {
              setShowTooltip(false);
            }}
          />
        </TooltipContent>
      }
    >
      <span>
        <Icon name="question" color="grey.t75" />
      </span>
    </Tooltip>
  );
};

export const LoanProviderDetails = ({ loanProviderName, loanProviderBalance }) => {
  return (
    <Stack gap="6">
      <>
        <Text textStyle="heading6">Loan Provider</Text>
        <Text margin="2 0 0 0">{loanProviderName}</Text>
      </>
      <>
        <IdentifiedLoanBalanceWrapper>
          <Text textStyle="heading6" margin="2 0 0 0">
            Identified Loan Balance
          </Text>
          <LoanBalanceToolTip />
        </IdentifiedLoanBalanceWrapper>
        <Text margin="2 0 0 0">${formatNumber(loanProviderBalance)}</Text>
      </>
    </Stack>
  );
};

const IdentifiedLoanBalanceWrapper = styled.div`
  display: flex;
  img {
    width: 36px;
    height: 36px;
  }
  svg {
    width: 36px;
    height: 36px;
  }
`;

const TooltipContent = styled.div`
  display: flex;
  svg {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 36px;
    height: 36px;
  }
`;
