import React from 'react';
import { Container } from 'basis';
import styled from '@emotion/styled';
import { device } from 'media';
import { useTheme } from '@emotion/react';

import confettiTL from 'images/confetti-TL.svg';
import confettiTR from 'images/confetti-TR.svg';
import confettiBR from 'images/confetti-BR.svg';
import confettiBL from 'images/confetti-BL.svg';

const confetti = {
  TL: confettiTL,
  TR: confettiTR,
  BR: confettiBR,
  BL: confettiBL,
};

const PageContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  @media ${device.mobile} {
    background-color: ${p => p.backgroundColor};
  }
`;

const ShadowContainer = styled.div`
  position: relative;
  max-width: 680px;
  margin: 0 auto;

  &:before {
    content: '';
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 60px;
    left: 60px;
    box-sizing: border-box;
    border: ${p => `40px solid ${p.borderColor}`};
  }

  & > div:last-of-type {
    position: relative;
    border: none;
  }
  @media ${device.tablet} {
    margin-bottom: ${p => (p.useConfetti ? '140px' : '60px')};
    &:before {
      display: block;
    }
    & > div:last-of-type {
      margin: 0 0 20px;
    }
  }
`;

const Confetti = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: none;
  @media ${device.tablet} {
    display: block;
  }
`;

const ConfettiPiece = styled.img`
  position: absolute;
  ${p => {
    switch (p.align) {
      case 'TL':
        return `top: 0; left: -24px; transform: translate(-100%, -10%);`;
      case 'TR':
        return `top: 0; right: -84px; transform: translate(100%, -20%);`;
      case 'BR':
        return `bottom: -60px; right: -60px; transform: translate(100%, 100%);`;
      case 'BL':
        return `bottom: -60px; left: 0; transform: translate(-100%, 100%);`;
      default:
        return '';
    }
  }}
`;

function LayoutOutcomePage({ useConfetti, children }) {
  const theme = useTheme();

  return (
    <PageContainer backgroundColor={theme.approvedBackgroundColor}>
      <Container padding="0 0" padding-sm="6 4" padding-md="14 4">
        <ShadowContainer borderColor={theme.approvedBorderColor} useConfetti={useConfetti}>
          {useConfetti && (
            <Confetti>
              {Object.keys(confetti).map(key => (
                <ConfettiPiece key={key} src={confetti[key]} align={key} />
              ))}
            </Confetti>
          )}
          <Container padding="8 8" padding-md="10 10" bg="white" hasBorder>
            {children}
          </Container>
        </ShadowContainer>
      </Container>
    </PageContainer>
  );
}

export { LayoutOutcomePage };
