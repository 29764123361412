import { useEffect, useState } from 'react';

// type FocusableElement =
//   | HTMLButtonElement
//   | HTMLInputElement
//   | HTMLSelectElement
//   | HTMLTextAreaElement
//   | HTMLAnchorElement;

const focusableTypes = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
const getFocusListener = modal => e => {
  const isTabPressed = e.key === 'Tab' || e.keyCode === 9;

  if (!isTabPressed) {
    return;
  }

  const focusableElements = modal.querySelectorAll(focusableTypes);
  const firstFocusableElement = focusableElements[0];
  const lastFocusableElement = focusableElements[focusableElements.length - 1];

  if (e.shiftKey) {
    if (document.activeElement === firstFocusableElement) {
      lastFocusableElement.focus();
      e.preventDefault();
    }
  } else if (document.activeElement === lastFocusableElement) {
    firstFocusableElement.focus();
    e.preventDefault();
  }
};

export const useScrollLock = enabled => {
  useEffect(() => {
    const { body } = document;
    const { scrollY } = window;

    if (enabled) {
      body.style.position = 'fixed';
      body.style.width = '100%';
      body.style.top = `${-scrollY}px`;

      return () => {
        body.style.position = '';
        body.style.width = '';
        body.style.top = '';
        window.scrollTo(0, scrollY || 0 * -1);
      };
    }

    return () => undefined;
  }, [enabled]);
};

// type FocusLockEl: HTMLDivElement | null
export const useFocusLock = (enabled, el) => {
  useEffect(() => {
    if (!enabled || !el) return () => {};
    const focusListener = getFocusListener(el);
    const { activeElement } = document;
    el.focus({ preventScroll: true });
    document.addEventListener('keydown', focusListener);

    return () => {
      activeElement && activeElement.focus({ preventScroll: true });
      document.removeEventListener('keydown', focusListener);
    };
  }, [enabled, el]);
};

// type ReturnPortalElement = HTMLDivElement | null
export const usePortalElement = enabled => {
  const [node, setNode] = useState(null);

  useEffect(() => {
    if (!enabled) return () => {};

    const domNode = document.createElement('div');
    domNode.tabIndex = -1;
    domNode.dataset.testid = 'Portal';
    document.body.appendChild(domNode);
    setNode(domNode);

    return () => {
      document.body.removeChild(domNode);
    };
  }, [enabled]);

  return node;
};
