import React, { useState, useCallback } from 'react';
import { Button } from 'basis';
import { useStoreValue } from 'store';
import { useEventTracking } from 'react-event-tracker';
import { getPageTitle, getDataLayerElements } from 'utils';
import { useHistory, useLocation } from 'react-router-dom';
import { STEP_SAVED } from '_config';
import { useDataLayerAndAnalyticsLinks, useSaveDraft } from 'hooks';
import { SaveAndCompleteLaterModal } from './SaveAndCompleteLaterModal';

export const SaveAndCompleteLaterButton = ({ isDrafting = false, formId, formData, infoMessage }) => {
  const [storeState, updateStore] = useStoreValue();
  const { saveDraft, savingDraft } = useSaveDraft();
  const history = useHistory();
  const location = useLocation();
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const { trackEvent } = useEventTracking();
  const { trackEventSender } = useDataLayerAndAnalyticsLinks(storeState);
  const formValues = formData?.state?.values;
  const onConfirmSaveAndCompleteLater = useCallback(async () => {
    if (isDrafting) {
      if (savingDraft) {
        return;
      }

      const newStoreState = {
        ...storeState,
        acquisition: {
          ...storeState.acquisition,
          // saving current page's fields even if they are incomplete and not yet validated.
          // This behaviour is relying on the user will always resume to page where they last saved. i.e. set `activeStep` to `location.pathname`.
          // So that, they will always need to click the Next button, which triggers validations, to proceed to the page where submit the application.
          [formId]: { ...formValues },
        },
      };

      await saveDraft({
        ...newStoreState,
        activeStep: location.pathname,
      });

      // updating store so that when route changes to `/saved`, utag view will have the current page's updated fields' data.
      updateStore(newStoreState);

      trackEvent({
        event: {
          category: 'application',
          action: 'application-navigation',
          location: getPageTitle(location.pathname, storeState.applicationStatus),
          label: 'Yes, continue',
        },
        ...getDataLayerElements(newStoreState),
      });
    }

    updateStore({
      activeStep: STEP_SAVED,
    });

    history.push(STEP_SAVED);
  }, [
    formValues,
    formId,
    history,
    isDrafting,
    location.pathname,
    saveDraft,
    savingDraft,
    storeState,
    trackEvent,
    updateStore,
  ]);

  const onCancelSaveAndCompleteLater = useCallback(async () => {
    setIsSaveModalOpen(false);
    trackEventSender({
      category: 'application',
      action: 'application-navigation',
      location: getPageTitle(location.pathname, storeState.applicationStatus),
      label: 'No, cancel',
    });
  }, [location.pathname, storeState.applicationStatus, trackEventSender]);

  const onSaveAndCompleteLater = useCallback(async () => {
    setIsSaveModalOpen(true);
    trackEventSender({
      category: 'application',
      action: 'application-navigation',
      location: getPageTitle(location.pathname, storeState.applicationStatus),
      label: 'Save and complete later',
    });
  }, [location.pathname, storeState.applicationStatus, trackEventSender]);

  return (
    <>
      <Button
        testId="saveAndResumeLaterButton"
        variant="secondary"
        onClick={onSaveAndCompleteLater}
        width-xs="100%"
        width-sm="auto"
      >
        Save and complete later
      </Button>
      <SaveAndCompleteLaterModal
        isOpen={isSaveModalOpen}
        isSaving={savingDraft}
        onClose={onCancelSaveAndCompleteLater}
        onContinue={onConfirmSaveAndCompleteLater}
        infoMessage={infoMessage}
      />
    </>
  );
};
