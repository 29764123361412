import { useEffect, useState, useRef, useCallback } from 'react';

export const useCountdownTimer = (timeInSeconds, onTimeout, startWhen = true) => {
  const [seconds, setSeconds] = useState(timeInSeconds);
  const onTimeoutRef = useRef(onTimeout);
  onTimeoutRef.current = onTimeout;
  const interval = useRef();

  const reset = useCallback(() => setSeconds(timeInSeconds), [setSeconds, timeInSeconds]);
  const clear = useCallback(() => {
    clearInterval(interval.current);
    interval.current = undefined;
  }, []);

  useEffect(() => {
    if (startWhen) {
      interval.current = setInterval(() => setSeconds(t => t - 1), 1000);
      return () => interval.current && clearInterval(interval.current);
    }
    return () => {};
  }, [startWhen]);

  useEffect(() => {
    if (seconds <= 0) {
      onTimeoutRef.current();
      clear();
    }
  }, [clear, seconds]);

  return [seconds, reset, clear];
};
