import { useCallback } from 'react';
import axios from 'axios';
import { byCountry, getAuthHeader } from '../utils';

export const useSetUserCredentials = () => {
  const method = 'PUT';
  const url = `${process.env.REACT_APP_USER_REGISTER_URL}/credentials`;

  return useCallback(
    async password => {
      try {
        const headers = {
          Authorization: getAuthHeader(),
          'lfs-request-channel': byCountry({
            NZ: process.env.REACT_APP_OKTA_HEADER,
            AU: process.env.REACT_APP_OKTA_HEADER_AU,
          }),
          'Content-Type': 'application/json',
        };
        await axios.request({
          method,
          url,
          headers,
          data: {
            password: {
              value: password,
            },
          },
        });
        return { error: null };
      } catch (error) {
        return { error };
      }
    },
    [url],
  );
};
