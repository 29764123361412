import React from 'react';
import { Input } from 'basis';
import { validation } from '_config/_validation/validation';
import _cloneDeep from 'lodash/cloneDeep';
import { useFormCache } from '../../hooks/useFormCache';

const ValidateInput = ({ validates, formName, keyName, ...props }) => {
  const { getFormCacheValues } = useFormCache();
  const ruleKey = getFormCacheValues(formName)[keyName];

  if (validates.validationRules && validates.validationRules[ruleKey]) {
    const { minLength, maxLength, fixedLength, pattern, charType, messageOverride } = validates.validationRules[
      ruleKey
    ];
    const messages = _cloneDeep(validates.messages);
    if (messages?.invalid) {
      // If messageOverride is provided, use it instead of the default message
      if (messageOverride) {
        messages.invalid = messageOverride;
      } else {
        messages.invalid = messages.invalid.replace('{{charType}}', charType);
        fixedLength
          ? (messages.invalid = messages.invalid.replace('{{length}}', fixedLength))
          : (messages.invalid = messages.invalid.replace('{{length}}', `${minLength} - ${maxLength}`));
      }
    }

    const validate = validation(
      {
        minLength,
        maxLength,
        fixedLength,
        validPattern: pattern,
      },
      messages,
      'Input',
    )[0];
    return (
      <Input maxLength={maxLength || fixedLength ? maxLength || fixedLength : null} validate={validate} {...props} />
    );
  }
  return <Input {...props} />;
};

export { ValidateInput };
