import React from 'react';
import styled from '@emotion/styled';
import { Modal } from 'components/modal/Modal';
import { Button, Container, Flex, List, Text, Link } from 'basis';
import { config } from '_config';

const personalInformationList = [
  'sensitive information” as defined under the Privacy Act;',
  'credit information” such as:',
  'your identification details,',
  'information about consumer credit accounts you hold, or have held with other credit providers including whether it is open or closed and the relevant dates and the type and amount of credit you,',
  'your repayment history information, such as whether you have made, or missed, a payment on your credit facility,',
  'financial hardship information, about whether you have entered into a financial hardship arrangement;',
  'default information, ); and',
  '"credit eligibility information", which is information about you disclosed to us by a credit reporting body or information we derive about you using credit information.',
];

const lfsPersonalInformationList = [
  'information you provide in the Application Form;',
  'purchase and cash advance information (for example, the type of goods purchased and where they were purchased);',
  'information relating to your credit facility, your ongoing use of that account and your credit limit;',
  'information about your participation in the relevant associated loyalty or rewards program;',
  'information you may provide from time to time through contact with David Jones, David Jones Rewards or the Credit Provider call centres;',
  'information you provide when registering, or using, the credit facility on one of our websites;',
  'information held by a partner or service provider (for example, a loyalty program operator, a customer service organisation such as a call centre, a rewards provider and others described below under “Who your personal information may be shared with”);',
  'transaction data and purchase history',
  'sensitive information (in particular, health information) where it is necessary to provide you with a Latitude Group product or a service (including assessing hardship applications);',
  'information from third parties (such as employers, government bodies, accountants, financial institutions and related companies, here or overseas).',
];

const djPersonalInformationList = [
  'information you provide in the Application Form;',
  'transaction data and purchase history',
  'your shopping preferences;',
  'information about your participation in the relevant associated loyalty or rewards program;',
  'information you may provide from time to time through contact with Latitude;',
  'information you provide when using David Jones products and services, such as when you make a purchase using your Latitude credit card or when you access the David Jones website;',
  'information as further set out in the David Jones Privacy Policy.',
];

const lfsPersonalInformationReasonList = [
  'to assess your application for a credit facility and if it is approved, for the establishment and subsequent administration of the credit facility;',
  'to share your personal information where allowed under legislation with David Jones, the Latitude Group and related bodies, third parties that help administer the product or services being offered for the purposes set out in this Privacy Notice; and',
  'to assess, establish and administer any insurance product (including, if applicable, assessing your application for insurance).',
  'for risk assessment modelling;',
  'for fraud and crime prevention and investigation;',
  'to comply with laws that may require or authorise us to obtain information about you, such as the Anti-Money Laundering and Counter-Terrorism Financing Act 2006 and other anti-money laundering legislation (for example, for identity verification), the National Consumer Credit Protection Act 2009 and other regulatory legislation (for example, requiring us to maintain client and transaction records, to provide information relating to loans to the Australian Prudential Regulation Authority and to make reports and provide other information to regulators) and the Taxation Administration Act 1953, the Income Tax Assessment Act 1997 and other taxation laws (for example, to comply with information requests issued by the Commissioner of Taxation); and',
  'to assist in finalising your application for your credit facility or making a purchase on your credit facility.',
];

const personalInformationPurposes = [
  'to perform administrative tasks and manage business operations related to the credit facility and any associated loyalty or rewards program, including dispute resolution;',
  'to provide associated loyalty or rewards program;',
  'to tailor how we provide and market our products and services to you (such as through targeted content and marketing) based on your purchase history, shopping preferences and personal information you have provided us;',
  'promote and provide benefits associated with the credit facility including discounts and special offers;',
  'to send you direct marketing communications and to promote and provide products, services and offers (including special promotions, events, product launches and other special offers and discounts through various communication channels) of David Jones and the Latitude Group and other Latitude related entities and other organisations;',
  'to carry out planning, product and service development and research (including via customer satisfaction surveys), analysis and tracking;',
  'to produce data analytics and reports;',
  'for other purposes as listed in David Jones’ Privacy Policy and the Latitude Group’s Privacy and Credit Reporting Policy',
];

const personalInformationSharing = [
  'related entities of David Jones or other entities within the Latitude Group, whether in Australia or overseas, for the purposes set out in this Privacy Notice;',
  'program partners for any loyalty or rewards program associated with your card;',
  'business partners and co-brand partners from time to time for analysis and research purposes and in the development of products and services and promotional offers;',
  'service providers such as customer service organisations, call centres, mailing houses, researchers, collections agents, data analysts, delivery companies;',
  'professional advisers (such as accountants), auditors,',
  'organisations providing services and rewards relevant to any associated loyalty or rewards program,',
  'other organisations as required or authorised by law, for example, to government or regulatory bodies for the prevention or detection of unlawful activities',
  'other third parties as listed in the David Jones’ Privacy Policy and the Latitude Group’s Privacy and Credit Reporting Policy.',
];

const lfsPersonalInformationSharing = [
  'an employer named in the Application Form to verify the personal information provided;',
  'credit reporting bodies or other businesses or other organisations that provide personal credit information as described above in the section “Collection, use and disclosure of your credit information and credit eligibility information”;',
  'additional cardholders or other persons (if any) authorised by you to operate or access your credit facility;',
  'organisations through whom you choose to make payments to Latitude; and',
  'providers of services related to the credit facility such as statement producers, debt collection agencies and card manufacturers.',
  'any Introducers (including brokers, dealers and retail partners), if applicable. To the extent that an Introducer is acting on your behalf, you authorise the Introducer to receive personal information, including credit eligibility information, from us;',
  'other financial institutions, government bodies and credit providers;',
  'card schemes such as Mastercard; your nominated referees;',
  'organisations wishing to acquire an interest in any part of Latitude Groups business for assessing or implementing any such acquisition.',
];

const djPersonalInformationSharing = [
  'Latitude;',
  'David Jones’ contractors or service providers who provide services or functions on David Jones’ behalf. This may include internet service providers, IT systems administrators, mailing houses, couriers, payment processors, and data entry service providers;  business partners and other third parties with whom David Jones jointly offers products and services for marketing and related services, such as reward redemption partners;',
  'if required by law, to any person authorised by such law. This may include the police service, or government departments or regulators within Australia or New Zealand',
  'David Jones’ related entities',
  'people you authorise to use or access your account such as additional card members',
  'other third parties as listed in the David Jones Privacy Policy.',
];

const countries = [
  'China;',
  'countries within the European Union;',
  'India;',
  'Israel;',
  'Japan;',
  'Mexico;',
  'New Zealand;',
  'the Philippines;',
  'Singapore;',
  'Spain;',
  'South Korea;',
  'United Kingdom;',
  'United States of America',
];

const CreditReport = ({ title, website, address }) => (
  <Container>
    <Text>
      <strong>{title}</strong>
    </Text>
    <Text>
      Website:{' '}
      <Link newTab href={`https://${website}`}>
        {website}
      </Link>
    </Text>
    <Text>{address}</Text>
  </Container>
);

export const PrivacyNoticeModal = ({ isOpen, onClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      style={{
        width: '886px',
      }}
    >
      <Container padding="3" padding-md="6">
        <Container padding="4 2 2">
          <Text textStyle="heading4" textStyle-md="heading4">
            Important Privacy Notice
          </Text>
        </Container>
        <PrivacyNoticeContent>
          <Container padding="2 3">
            <Text>
              <strong>
                You acknowledge and consent to all of the matters set out in this Privacy Notice, including the{' '}
                collection, use and disclosure of your personal information by Latitude Finance Australia and David{' '}
                Jones.
              </strong>
            </Text>
          </Container>

          <Container padding="2 3">
            <Text textStyle="heading5" textStyle-md="heading5">
              Personal information
            </Text>
          </Container>

          <Container padding="2 3">
            <Text textStyle="heading5" textStyle-md="heading5">
              In this Privacy Notice, references to “personal information” include:
            </Text>
          </Container>
          <Container padding="2 6">
            <List>
              {personalInformationList.map((item, index) => {
                return <List.Item key={index}>{item}</List.Item>;
              })}
            </List>
          </Container>

          <Container padding="2 3">
            <Text textStyle="heading5" textStyle-md="heading5" as="h5">
              Consequences for you if your personal information is not provided to us
            </Text>
            <Text>
              If this all or some of the personal information about you is not provided to us, it may result in Latitude{' '}
              being unable to process your application and provide the credit facility or may result in us not being{' '}
              able to provide the benefits associated with it.
            </Text>
          </Container>

          <Container padding="2 3">
            <Text textStyle="heading5" textStyle-md="heading5" as="h5">
              Privacy Act
            </Text>
            <Text>In this Privacy Notice, a reference to the Privacy Act 1988 includes amendments to the Act.</Text>
          </Container>

          <Container padding="2 3">
            <Text textStyle="heading5" textStyle-md="heading5" as="h5">
              Privacy Notice
            </Text>
            <Text>
              This Privacy Notice contains important information about the collection, use and disclosure of personal{' '}
              information by:
            </Text>
            <Container padding="2 6">
              <List>
                <List.Item>
                  David Jones Pty Limited ABN 72 000 074 573 (“David Jones”) and its related entities.
                </List.Item>
                <List.Item>
                  Latitude Finance Australia ABN 42 008 583 588 (“Latitude”), the credit provider under the credit{' '}
                  facility referred to as the “Credit Provider”. The Credit Provider and its related entities are{' '}
                  referred to as the “Latitude Group”.
                </List.Item>
              </List>
            </Container>
            <Container>
              <Text>
                David Jones has a contractual arrangement with Latitude to provide the David Jones credit cards to{' '}
                approved customers to facilitate credit purchases of goods and services.
              </Text>
              <Text>
                Where personal information is collected, used and disclosed for the same purposes by Latitude and David{' '}
                Jones, the word “we” or “us” is used. Where personal information is managed by only either Latitude or{' '}
                David Jones, it will be identified separately as relevant to each entity. The words “you”/ “your”{' '}
                includes all borrowers or other relevant individuals (such as directors or shareholders) connected with{' '}
                a borrower and named in an Application Form.
              </Text>
              <Text>
                For further information on Latitudes privacy practices including more detailed information on the items{' '}
                contained in this Privacy Notice and information related on how to access your personal information,{' '}
                request a correction, or make a complaint please see Latitude’s Privacy & Credit Reporting Policy which
                is available on the Latitude website. Latitude may update Its policy from time to time and the updated{' '}
                version will be published on Its website{' '}
                <Link newTab href="https://www.latitudefinancial.com.au/privacy">
                  www.latitudefinancial.com.au/privacy/
                </Link>
              </Text>
            </Container>
            <Container>
              <Text>
                If you have any queries in relation to Latitude’s Privacy & Credit Reporting practices, you can:
              </Text>
              <Container padding="2 6">
                <List>
                  <List.Item>
                    write to us at Level 18, 130 Lonsdale Street, Melbourne VIC 3000. Attention: Privacy Officer; or
                  </List.Item>
                  <List.Item>call {config.phoneNumbers.assistance}.</List.Item>
                </List>
              </Container>
            </Container>
            <Container>
              <Text>
                For further information on David Jones privacy practices including details on how to access and correct{' '}
                your personal Information and make a complaint, please see the David Jones Privacy Policy available on{' '}
                the David Jones website.
              </Text>
              <Text>
                David Jones may update Its Privacy Policy from time to time. Amendments will be published on the David{' '}
                Jones website:
                <Link newTab href="https://www.davidjones.com/information/privacy-and-security">
                  https://www.davidjones.com/information/privacy-and-security
                </Link>
              </Text>
              <Text>If you have any queries in relation to David Jones Privacy practices, you can:</Text>
              <Container padding="2 6">
                <List>
                  <List.Item>
                    contact David Jones at Legal Services, Building 2, 572 Swan Street, Burnley VIC 3121. Attention: The{' '}
                    Privacy Officer; or
                  </List.Item>
                  <List.Item>call 1800 720 025; or</List.Item>
                  <List.Item>email privacy@davidjones.com.au</List.Item>
                </List>
              </Container>
            </Container>
          </Container>
          <Container padding="2 3">
            <Text textStyle="heading5" textStyle-md="heading5" as="h5">
              How your personal information is collected
            </Text>
            <Text>
              Wherever it is possible and practical to do so, we will collect personal information directly from you.{' '}
              This may be in person, over the telephone, by email, over the internet, by email or fax, or by completion{' '}
              of a form (such as an application form).
            </Text>
            <Text>
              We may also collect your personal information from a third party including a merchant or a third party{' '}
              with whom we have an arrangement, partnership, or joint venture with. This will be limited to{' '}
              circumstances where it is impracticable or unreasonable for us to collect it directly from you. The type{' '}
              of third party who might provide information to will vary depending on the nature of the business and{' '}
              interaction with you. For example, Latitude may collect credit information and credit eligibility{' '}
              information from a credit reporting body or personal information from your employer to verify information{' '}
              in your credit application or, hardship application.
            </Text>
            <Text>When Latitude provides credit, third parties could include:</Text>
            <Container padding="2 6">
              <List>
                <List.Item>a credit reporting body;</List.Item>
                <List.Item>collection agents; </List.Item>
                <List.Item>an employer; or </List.Item>
                <List.Item>another financial institution. </List.Item>
              </List>
            </Container>
            <Text>
              Whilst this is not an exhaustive list, Latitude may obtain your personal information from other third{' '}
              parties which could include:
            </Text>
            <Container padding="2 6">
              <List>
                <List.Item>David Jones;</List.Item>
                <List.Item>an introducer such as a broker, agent, dealer and retail partner;</List.Item>
                <List.Item>
                  someone authorised to act on your behalf (e.g., a financial adviser, executor, administrator, trustee,{' '}
                  guardian, attorney, accountant or consumer advocate);
                </List.Item>
                <List.Item>research agencies;</List.Item>
                <List.Item>a referee; </List.Item>
                <List.Item>a government body;</List.Item>
                <List.Item>third party service providers and / or suppliers; or</List.Item>
                <List.Item>one or more of Latitude's related companies, in Australia or overseas.</List.Item>
              </List>
            </Container>
          </Container>
          <Container padding="2 3">
            <Text textStyle="heading6" textStyle-md="heading6">
              David Jones:
            </Text>
            <Text>
              In addition to collecting information directly from you, David Jones obtains your personal information{' '}
              from third parties. These include:
            </Text>
            <Container padding="2 6">
              <List>
                <List.Item>Latitude;</List.Item>
                <List.Item>
                  credit reporting bodies, as described above for credit information where allowed or for identity{' '}
                  verification;
                </List.Item>
                <List.Item>
                  third party service providers who provide services or functions on David Jones’ behalf, such as IT{' '}
                  service providers, delivery service providers and fulfilment managers, mailing houses and marketing{' '}
                  agencies;
                </List.Item>
                <List.Item>third parties named in your card application.</List.Item>
              </List>
            </Container>
          </Container>
          <Container padding="2 3">
            <Text textStyle="heading5" textStyle-md="heading5">
              What personal information is collected
            </Text>
            <Text>Generally, Latitude will collect (not limited to):</Text>
            <Container padding="2 6">
              <List>
                {lfsPersonalInformationList.map((item, index) => (
                  <List.Item key={index}>{item}</List.Item>
                ))}
              </List>
              <Text>Generally, David Jones will collect (not limited to):</Text>
              <List>
                {djPersonalInformationList.map((item, index) => (
                  <List.Item key={index}>{item}</List.Item>
                ))}
              </List>
            </Container>
          </Container>
          <Container padding="2 3">
            <Text textStyle="heading5" textStyle-md="heading5">
              Why personal information is collected, used and disclosed
            </Text>
            <Text>Generally, Latitude will collect, use and disclose your personal information (not limited to):</Text>
            <Container padding="2 6">
              <List>
                {lfsPersonalInformationReasonList.map((item, index) => (
                  <List.Item key={index}>{item}</List.Item>
                ))}
              </List>
            </Container>
            <Text>We may also collect, use and disclose personal information for a number of purposes, including:</Text>
            <Container padding="2 6">
              <List>
                {personalInformationPurposes.map((item, index) => (
                  <List.Item key={index}>{item}</List.Item>
                ))}
              </List>
            </Container>
            <Text>
              Latitude will disclose your personal information (including your purchase history and transaction data) to{' '}
              David Jones. David Jones will use this Information to update and correct your details In David Jones'{' '}
              customer database, to provide David Jones products and services to you and to communicate with you about{' '}
              offers and promotions available at David Jones.
            </Text>
          </Container>
          <Container padding="2 3">
            <Text textStyle="heading5" textStyle-md="heading5">
              Who your personal information may be shared with
            </Text>
            <Text>
              For the purposes listed above or as described, we may share your personal information with each other and{' '}
              with the following (as well as otherwise permitted by the Privacy Act 1988):
            </Text>
            <Container padding="2 6">
              <List>
                {personalInformationSharing.map((item, index) => (
                  <List.Item key={index}>{item}</List.Item>
                ))}
              </List>
            </Container>
          </Container>
          <Container padding="2 3">
            <Text textStyle="heading6" textStyle-md="heading6">
              Latitude
            </Text>
            <Text>Additionally, Latitude may also share your personal information with:</Text>
            <Container padding="2 6">
              <List>
                {lfsPersonalInformationSharing.map((item, index) => (
                  <List.Item key={index}>{item}</List.Item>
                ))}
              </List>
            </Container>
          </Container>
          <Container padding="2 3">
            <Text textStyle="heading6" textStyle-md="heading6">
              David Jones
            </Text>
            <Text>David Jones will share your personal information with: </Text>
            <Container padding="2 6">
              <List>
                {djPersonalInformationSharing.map((item, index) => (
                  <List.Item key={index}>{item}</List.Item>
                ))}
              </List>
            </Container>
          </Container>
          <Container padding="2 3">
            <Text textStyle="heading5" textStyle-md="heading5">
              Collection, use and disclosure by Latitude of your credit information and credit eligibility information
            </Text>
            <Text>
              Latitude may obtain your credit reporting information from credit reporting bodies in circumstances{' '}
              permitted by the Privacy Act 1988, for example to assess your application for consumer credit and to{' '}
              collect payments which are overdue for consumer credit.
            </Text>
            <Text>
              Latitude uses credit eligibility information for purposes permitted under the Privacy Act 1988, including
              for the purposes of assessing your initial and ongoing application and availability for consumer credit
              and for internal management purposes.
            </Text>
            <Text>
              Latitude may disclose personal information about you to credit reporting bodies (including credit{' '}
              information, such as details about the credit that we provide to you, your repayment history, financial{' '}
              hardship arrangements and any repayment defaults).
            </Text>
            <Text>Latitude discloses Information to the following Credit Reporting Bodies:</Text>
            <CreditReport
              title="illion Australia Pty Ltd"
              website="www.checkyourcredit.com.au"
              address="Postal address: illion Public Access Centre, \nPO Box 7405, St Kilda Road, Melbourne, VIC 3004"
            />
            <CreditReport
              title="Equifax Australia"
              website="www.equifax.com.au"
              address="Postal address: PO Box 964, North Sydney, NSW 2059"
            />
            <CreditReport
              title="Experian Australia Credit Services Pty Ltd"
              website="www.experian.com.au"
              address="Postal address: PO Box 1969, North Sydney, NSW 2060"
            />
            <Text>
              These credit reporting bodies are each required to have a policy which explains how they will manage your{' '}
              credit-related personal information. To view the policy of any of these credit reporting bodies please{' '}
              visit the relevant credit reporting body's website and follow the "Privacy" links, or you can contact them{' '}
              directly for further information.
            </Text>
            <Text>
              These credit reporting bodies may include the information in reports provided to credit providers to{' '}
              assist them to assess your credit worthiness. Please be aware that you have a right to request that these{' '}
              credit reporting bodies do not:
            </Text>
            <Container padding="2 6">
              <List>
                <List.Item>
                  use your credit reporting information for the purposes of pre-screening of direct marketing by a{' '}
                  credit provider; and
                </List.Item>
                <List.Item>
                  use or disclose your credit reporting information, if you believe on reasonable grounds that you have{' '}
                  been or are likely to be a victim of fraud.
                </List.Item>
              </List>
              <Text>You should also be aware that:</Text>
              <List>
                <List.Item>
                  if you fail to meet your payment obligations in relation to consumer credit or commit a serious credit{' '}
                  infringement, Latitude may be entitled to disclose this to the credit reporting body; and
                </List.Item>
                <List.Item>
                  you have a right to access your information that Latitude holds. Please see below for more details{' '}
                  about access, correction and complaints.
                </List.Item>
              </List>
            </Container>
            <Text>
              Without limiting any other consents contained in this Privacy Notice, you agree that Latitude may share{' '}
              information about your credit arrangements and credit eligibility information about you with any credit{' '}
              providers named by you in the Application Form and any credit providers named in a credit report obtained{' '}
              by Latitude and issued by a credit reporting body in a manner and for purposes that comply with the{' '}
              Privacy Act 1988, including to assess your application for credit and your credit worthiness. Subject to{' '}
              the Privacy Act 1988, Latitude may also share information concerning a credit facility granted to you or{' '}
              the conduct of that credit facility.
            </Text>
          </Container>
          <Container padding="2 3">
            <Text textStyle="heading4" textStyle-md="heading4">
              Information about additional cardholders and other persons
            </Text>
            <Text>
              If you nominate an additional cardholder or provide personal information about any other individual, such{' '}
              as a referee, you must first ensure that the person concerned:
            </Text>
            <Container padding="2 6">
              <List>
                <List.Item>has seen this Privacy Notice and understood its contents; and</List.Item>
                <List.Item>
                  has agreed to their personal information being collected, used and disclosed in accordance with this{' '}
                  Privacy Notice.
                </List.Item>
              </List>
            </Container>
            <Text>
              If you nominate an additional cardholder under 18 years of age who does not have sufficient maturity to{' '}
              understand this Privacy Notice, you must ensure that a parent or guardian agrees to this Privacy Notice on{' '}
              their behalf.
            </Text>
          </Container>
          <Container padding="2 3">
            <Text textStyle="heading5" textStyle-md="heading5">
              Safeguarding personal information
            </Text>
            <Text>
              We will take reasonable steps to keep your personal information safe from loss, unauthorised activity, or{' '}
              other misuse. You must take care to protect your personal information, which can include steps such as{' '}
              using passwords that cannot be easily guessed, ensuring you access your personal information on safe{' '}
              internet networks, and by not disclosing your personal information to unknown third parties. You should{' '}
              also notify us via our contact details listed below as soon as you become aware of any security breaches{' '}
              relating to your account or personal information.
            </Text>
          </Container>
          <Container padding="2 3">
            <Text textStyle="heading5" textStyle-md="heading5">
              Transfer or disclosure of your personal information overseas
            </Text>
            <Text textStyle="heading6" textStyle-md="heading6">
              Latitude
            </Text>
            <Text>
              In some circumstances, for the purposes set out in this Privacy Notice, your personal information, credit{' '}
              information and credit eligibility information may be disclosed by Latitude to organisations located{' '}
              overseas and which do not have an Australian Link (for example, a disclosure to an overseas recipient may{' '}
              be necessary for operational reasons such as because you have requested an international payment to be{' '}
              made or another product or service that involves an international element - or to comply with foreign{' '}
              legal or regulatory requirements). Latitude may also use service providers based overseas. Where such{' '}
              disclosures occur, arrangements will be put in place to protect your personal information. It is not{' '}
              practicable to list every country in which such recipients are located but it is likely that the countries{' '}
              to which your information may be disclosed are as follows:
            </Text>
            <Container padding="2 6">
              <List>
                {countries.map((item, index) => (
                  <List.Item key={index}>{item}</List.Item>
                ))}
              </List>
            </Container>
          </Container>
          <Container padding="2 3">
            <Text textStyle="heading6" textStyle-md="heading6">
              David Jones
            </Text>
            <Text>
              David Jones may disclose your personal information to third parties and service providers located overseas{' '}
              Please see the David Jones Privacy Policy for details of the countries where overseas recipients are{' '}
              located.
            </Text>
          </Container>
          <Container padding="2 3">
            <Text textStyle="heading5" textStyle-md="heading5">
              Transfer of Latitude Group’s rights
            </Text>
            <Text>Latitude and Latitude Group may without notice to you or obtaining your consent:</Text>
            <Container padding="2 6">
              <List>
                <List.Item>assign any of its rights under the credit facility; and</List.Item>
                <List.Item>
                  give information about the credit facility and your obligations under it to any assignee, or anyone
                  who is considering becoming an assignee.
                </List.Item>
              </List>
            </Container>
          </Container>
          <Container padding="2 3">
            <Text textStyle="heading5" textStyle-md="heading5">
              Direct marketing opt-out
            </Text>
            <Text textStyle="heading6" textStyle-md="heading6">
              Latitude
            </Text>
            <Text>
              The consents given by you in relation to the use of your personal information for direct marketing apply{' '}
              to contacting you by all relevant means (for example, by letter, email, SMS or phone) and apply for an{' '}
              indefinite period of time, unless you expressly withdraw those consents.
            </Text>
            <Text>
              If you do not want to receive direct marketing information from Latitude, you may call the Customer{' '}
              Services Team on {config.phoneNumbers.assistance}. You can also unsubscribe from email marketing by
              clicking unsubscribe in the footer of the email.
            </Text>
            <Text>
              Where Latitude has provided your information to our third party or a retail partner to send you marketing{' '}
              or promotional materials, you will need to opt out of these services with them directly.
            </Text>
          </Container>
          <Container padding="2 3">
            <Text textStyle="heading6" textStyle-md="heading6">
              David Jones
            </Text>
            <Text>
              The consents given by you in relation to the use of your personal information for direct marketing include{' '}
              offers, rewards and loyalty programs associated with your card, as well as general offers and rewards from{' '}
              David Jones, including emails about David Jones Rewards.
            </Text>
            <Text>
              If you do not want to receive direct marketing information from David Jones (including David Jones{' '}
              Rewards), you may call David Jones Customer Services on 133 357 or email contactus@davidjones.com.au. You{' '}
              can also unsubscribe from email marketing by clicking unsubscribe in the footer of the email you received.{' '}
              Please note that if you unsubscribe from emails, you will be unsubscribing from David Jones Rewards and{' '}
              all emails from David Jones. This Includes emails about offers and benefits associated with your card. You{' '}
              can control the types of marketing communications you receive from David Jones by tailoring your marketing{' '}
              preferences.
            </Text>
          </Container>
          <Container padding="2 3">
            <Link newTab href="https://www.latitudefinancial.com.au/privacy">
              Privacy & Credit Reporting Policy
            </Link>
            {' | '}
            <Link newTab href="https://www.latitudefinancial.com.au/terms-and-conditions">
              Terms and Conditions
            </Link>
            {' | '}
            <Link newTab href="https://www.latitudefinancial.com.au/security">
              Security
            </Link>
          </Container>
          <Container padding="2 3">
            <Text>
              Credit provided by Latitude Finance Australia ABN 42 008 583 588 Australian Credit Licence number 392145,{' '}
              130 Lonsdale Street Melbourne, VIC 3000, Australia.
            </Text>
          </Container>
        </PrivacyNoticeContent>

        <Flex placeItems="center">
          <Container padding="8 2 4 2">
            <Button width="160px" onClick={onClose}>
              Close
            </Button>
          </Container>
        </Flex>
      </Container>
    </Modal>
  );
};

const PrivacyNoticeContent = styled.div`
  li::before {
    background-color: black;
  }

  overflow-y: auto;
  height: 800px;

  p {
    margin-bottom: 4px;
  }

  h5 {
    margin-bottom: 8px;
  }
`;
