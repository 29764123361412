import styled from '@emotion/styled';

export const StyledUL = styled.ul`
  margin-top: 0;
  padding-left: 16px;
  line-height: 24px;
`;

export const StyledULWideLeftPadding = styled.ul`
  margin-top: 0;
  padding-left: 40px;
  line-height: 24px;
`;

export const StyledDivSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledLeftDivWidth75 = styled.div`
  width: 75%;
`;

export const StyledDivAlignRight = styled.div`
  width: 23%;
  text-align: right;
`;
