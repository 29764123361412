import React from 'react';
import { EXPENSES } from '../_constants';

export const generateOverviewFormConfig = ({ TermAndConditions, StopperMessage, trackApplicationReasonChange }) => [
  {
    component: 'Checkbox',
    name: 'consentGiven',
    label: '',
    testId: 'consent-given',
    children: <TermAndConditions />,
    validate: value =>
      value === false ? 'To proceed, check the box to confirm that you agree to the terms above' : null,
  },
];

export const isSubmitHiddenOnOverview = formData => {
  return !['', EXPENSES].includes(formData.state.values.applicationReason);
};
