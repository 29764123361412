import React from 'react';
import { Footer, Link, Text } from 'basis';
import { FooterSources } from '_config/_constants/externalLinks';
import { config } from '_config';

const { countryCode } = config;

function OutcomeFooter({ state, trackEventSender, getLinkWithAnalytics }) {
  return (
    <Footer>
      <Footer.Header>
        <Footer.Header.Logo name={countryCode === 'NZ' ? 'gem' : 'latitude'} />
      </Footer.Header>

      <Footer.Legal>
        <Footer.Legal.Links>
          {Object.keys(FooterSources).map((key, index) => {
            return (
              <Text key={index} textStyle="legal">
                <Link
                  href={getLinkWithAnalytics(key)}
                  newTab
                  onClick={() => trackEventSender(FooterSources[key].label)}
                >
                  {FooterSources[key].label}
                </Link>
              </Text>
            );
          })}
        </Footer.Legal.Links>
        <Footer.Legal.Copy>
          <Text>Gem Visa is provided by Latitude Financial Services Limited</Text>
        </Footer.Legal.Copy>
      </Footer.Legal>
    </Footer>
  );
}

export { OutcomeFooter };
