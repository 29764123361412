import React, { useMemo } from 'react';
import { LayoutPage, Errors, LayoutContent, SaveAndCompleteLaterButton } from 'components';
import { useStoreValue, OTHER, HOME_LOAN } from 'store';
import { useForm, FormProvider } from 'react-hook-form';
import { BasisProvider as BasisProviderV3 } from 'lfs-ui/v3/BasisProvider';
import { RS_MORTGAGE, STEP_PROCESSING_LOAN_INFORMATION } from '_config';
import { Stack, Button, Text, List, Grid, Message, Container } from 'basis';
import { davidJonesV3Theme } from 'theme';
import { useUpdateDebts } from 'hooks/useUpdateDebts';
import { useHistory } from 'react-router-dom';
import { useEventTracking } from 'react-event-tracker';
import { getDataLayerElements } from 'utils/getDataLayerElements';
import { UndisclosedDebtSection } from './components/UndisclosedDebtSection';
import { AdditionalLoans } from './components/AdditionalLoans';
import { toUpdateDebtsPayload } from './toUpdateDebtsPayload';
import { TotalRepayment } from './components/TotalRepayment';
import { NON_HIDDEN_DEBT_CATEGORIES } from './constants';

const buttonLabel = 'Next - Choose credit limit';

export const LoanInformation = () => {
  const history = useHistory();
  const { trackEvent } = useEventTracking();
  const [storeState, updateStore] = useStoreValue();
  const { sendRequest, loading } = useUpdateDebts();
  const isWithMortgage = RS_MORTGAGE === storeState?.acquisition?.otherDetails?.residentialStatus;
  const formProps = useForm({
    mode: 'onChange',
    shouldUnregister: false,
  });
  const { [HOME_LOAN]: homeLoanDebts, [OTHER]: otherDebts } = useMemo(
    () =>
      storeState.undisclosedDebts
        .filter(debt => NON_HIDDEN_DEBT_CATEGORIES.includes(debt.category))
        .reduce(
          (acc, debt, index) =>
            debt.category === HOME_LOAN
              ? { ...acc, [HOME_LOAN]: [...acc[HOME_LOAN], { ...debt, index }] }
              : { ...acc, [OTHER]: [...acc[OTHER], { ...debt, index }] },
          { [HOME_LOAN]: [], [OTHER]: [] },
        ),
    [storeState.undisclosedDebts],
  );
  const {
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = formProps;

  const onSubmit = async values => {
    trackEvent({
      event: {
        label: buttonLabel,
        location: window.location.pathname.slice(1),
        category: 'application',
        action: 'application-navigation',
      },
      ...getDataLayerElements(storeState),
    });
    const payload = toUpdateDebtsPayload(values, storeState);
    const { status } = await sendRequest(payload);
    const nextStep = STEP_PROCESSING_LOAN_INFORMATION;
    updateStore({
      applicationErrors: null,
      applicationStatus: status,
      activeStep: nextStep,
    });
    history.push(nextStep);
  };

  if (storeState.applicationErrors) {
    return (
      <LayoutPage>
        <Errors applicationRef={storeState.applicationRef} />
      </LayoutPage>
    );
  }

  return (
    <LayoutPage noTitlePadding>
      <LayoutContent>
        <BasisProviderV3 theme={davidJonesV3Theme}>
          <FormProvider {...formProps}>
            <LoanInfo />
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack gap="4">
                <UndisclosedDebtSection
                  header="Home Loans"
                  key="homeLoans"
                  debts={homeLoanDebts}
                  watch={watch}
                  showHomeLoanCheckbox={isWithMortgage}
                />
                <UndisclosedDebtSection header="Other loans" key="otherLoans" debts={otherDebts} watch={watch} />
                <AdditionalLoans isWithMortgage={isWithMortgage} watch={watch} />
                <TotalRepayment watch={watch} />
                <Container padding="8 0">
                  <Grid rowsGap="4" colsGap="10" cols="2">
                    <Grid.Item colSpan="all" colSpan-sm="0" alignItems="center">
                      <Grid>
                        <Button isLoading={isSubmitting || loading} width="100%" type="submit">
                          {buttonLabel}
                        </Button>
                      </Grid>
                    </Grid.Item>
                    <Grid.Item colSpan="all" rowSpan="1" colSpan-sm="1" rowSpan-sm="0">
                      <Grid>
                        <SaveAndCompleteLaterButton />
                      </Grid>
                    </Grid.Item>
                  </Grid>
                </Container>
              </Stack>
            </form>
          </FormProvider>
        </BasisProviderV3>
      </LayoutContent>
    </LayoutPage>
  );
};

const LoanInfo = () => {
  return (
    <>
      <Text>
        As part of our review process, the below loans have been identified at the credit bureau. Please provide{' '}
        <strong>your share of the monthly repayment amount in the below field(s)</strong>.
      </Text>
      <Message severity="info-or-minor" bg="secondary.lightBlue.t25">
        <Stack gap="4">
          <Text>
            Any revised amounts will be passed through with your finances information to complete your application.
          </Text>
          <Text>If a loan is closed, not yours or you don't pay for the loan:</Text>
          <List>
            <List.Item>
              <Text>You may choose to contact the loan provider and update the account on file.</Text>
            </List.Item>
            <List.Item>
              <Text>
                We may ask for proof if the loan is closed or if you do not pay for the loan for our review process.
              </Text>
            </List.Item>
          </List>
        </Stack>
      </Message>
    </>
  );
};
