import { sharedTheme } from './shared';

export const nzTheme = {
  ...sharedTheme,
  highlightColor: 'secondary.lightBlue.t25',
  backgroundColor: '#eff7ff',
  headingColor: 'highlight.blue.t100',
  colors: {
    ...sharedTheme.colors,
    specialBackground: '#eff7ff',
  },
};
