import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useStoreValue } from 'store';
import { useErrorTrackingAndUpdateStore } from './useErrorTrackingAndUpdateStore';

export const UPDATE_IDV_GQL = gql`
  mutation useUpdateIdvDetails($input: UpdateIdvDetailsInput!) {
    updateIdvDetails(input: $input) {
      status
    }
  }
`;

export function useUpdateIdvDetails() {
  const [storeState] = useStoreValue();
  const { applicationId } = storeState;
  const { handleError } = useErrorTrackingAndUpdateStore('hook:useUpdateIdvDetails');
  const [mutate, { loading }] = useMutation(UPDATE_IDV_GQL, {
    onError: err => {
      handleError(err);
    },
  });

  const sendRequest = useCallback(
    async values => {
      const input = { applicationId, ...values };
      const result = await mutate({ variables: { input } });
      return result.data.updateIdvDetails;
    },
    [applicationId, mutate],
  );

  return {
    sendRequest,
    loading,
  };
}
