import React from 'react';
import { Text, Grid, Container, Link, Stack, Flex } from 'basis';
import { Hero, StyledUL, InternalLink } from 'components';
import {
  config,
  APPLE_MERCHANT,
  PRODUCT_CODE_TWENTY_EIGHT_DEGREES,
  PRODUCT_CODE_LOW_RATE,
  OutcomeApprovedSourcesAu,
  OverviewSources,
  PRODUCT_DAVID_JONES,
} from '_config';
import { OutcomeContent } from 'siteContent/outcomeContent';
import { useDataLayerAndAnalyticsLinks } from 'hooks';
import { displayCreditLimit, byCountry, isDJ, byCard, getProductName, isGemAU } from 'utils';
import styled from '@emotion/styled';
import DownloadApple from 'images/download-apple.svg';
import DownloadGoogle from 'images/download-google.svg';
import { kebabCase } from 'lodash';

import appleGoogleSamsungPay from 'images/apple-google-samsung-pay.png';
import ellipse from 'images/ellipse.svg';
import { getRatesAndFees } from 'featureToggles';
import { ContentWrapper, TopContainer } from '../Outcome.styles';
import { ApprovedInformation } from './ApprovedInformation';
import { ShoppingInformation } from './ShoppingInformation';
import { FeatureInformation } from './FeatureInformation';
import { AppleApplicantsBox } from '../components/AppleApplicantsBox';
import { DownloadResources } from './DownloadResources';
import { StuffLayout } from './StuffLayout';
import { ShadowedContainer } from '../../../components/info-card/InformationCard';
import { getAuCardOutcomeApprovedNextSteps, getCardImageMobile } from './auContent';

const StyledNumberListItem = styled.p`
  display: inline-block;
  margin-block-start: inherit;
  text-align: center;
  line-height: 24px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  background: #000;
  border-radius: 24px;
  width: 24px;
  height: 24px;
  margin-block-end: 0;
`;

const StyledStrong = styled.strong`
  color: #414141;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

const TopImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
  ${props =>
    byCard({
      [PRODUCT_CODE_TWENTY_EIGHT_DEGREES]: 'width: 150px; height: 237px;',
      [PRODUCT_DAVID_JONES]: 'width: 211px; height: 133px;',
      default: null,
    })}
`;

const TextAlignWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  ${props => `
      ${`@media (max-width: ${props.theme.screenSize.xs})`} {
        padding: 16px 0;
      }
      ${`@media (min-width: ${props.theme.screenSize.sm})`} {
        padding: 0 16px;
        h2 {
          line-height: 28px;
        }
      }
      `}
`;

const IconTextWrapper = styled.div`
  display: flex;
  margin-left: 16px;
  img {
    height: 28px;
  }
`;

const RightTextWrapper = styled.div`
  display: flex;
  margin-left: 10px;
  width: 90%;
`;

const ImageHeaderWrapper = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

const TextHeaderWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-left: 12px;
  width: 90%;
`;

const NumberedListWrapper = styled.div`
  display: flex;
  margin-bottom: 4px;
`;

const NumberedTextWrapper = styled.div`
  display: flex;
  margin-left: 12px;
  width: 90%;
`;

const SmallContainerWrapper = styled.div`
  background: ${props => props.theme.colors.specialBackground};
  ${props => `
  ${`@media (max-width: ${props.theme.screenSize.xs})`} {
    margin-right: 6px;
  `}
`;

const DownloadAppImage = styled.img`
  height: 40px;
`;

const DividerWithText = styled.hr`
  position: relative;
  border: 0;
  margin: 20px 0 4px;
  display: flex;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;

  &:before {
    content: '';
    background: #7f7f7f;
    position: absolute;
    top: calc(50% - 1px);
    width: calc(100% - 32px);
    height: 1px;
    margin: 0 16px;
  }
  &:after {
    display: flex;
    justify-content: center;
    align-items: center;
    content: attr(data-content);
    position: relative;
    color: #ffffff;
    width: 35px;
    height: 35px;
    background-color: #7f7f7f;
    line-height: 1;
    border-radius: 500px;
  }
`;

function OutcomeApproved({ storeState }) {
  const {
    acquisition,
    gemVisaAccountNumber,
    applyAndBuy: { merchantName },
    applicationRef,
    accountNumber,
  } = storeState;
  const { formHeader } = OutcomeContent[config.countryCode];
  const overviewSources = OverviewSources[config.countryCode];
  const formTealiumLocation = kebabCase(formHeader);
  const internalLinkEventConfig = {
    category: 'text-link',
    action: 'internal-link',
    location: formTealiumLocation,
  };
  const auCard = getCardImageMobile();
  const { groupLFrameCardImageMobile } = OutcomeContent[config.countryCode];
  const { trackEventSender, getLinkWithAnalytics } = useDataLayerAndAnalyticsLinks(storeState);
  const creditLimit = displayCreditLimit(storeState);
  const { shoppingInformation } = OutcomeApprovedSourcesAu;

  const nzRatesAndFees = getRatesAndFees();
  const cashAnnualRate = nzRatesAndFees?.cashAnnualRate;
  const annualFee = nzRatesAndFees?.annualFee;
  const biAnnualFee = nzRatesAndFees?.annualFee / 2;
  const currentInterestRate = nzRatesAndFees?.purchaseAnnualRate;
  const establishmentFee = nzRatesAndFees?.establishmentFee;
  const cashWithdrawalFee = nzRatesAndFees?.cashWithdrawalFee;
  const { whatHappensNextStepsAu, shopOnlineAndInStoreAu } = getAuCardOutcomeApprovedNextSteps(
    storeState,
    trackEventSender,
  );

  const OutcomeApprovedNZ = () => {
    return (
      <ContentWrapper>
        <TopContainer>
          <Grid preset="page" rowsGap={0} colsGap={5}>
            <Grid.Item colSpan="all" colSpan-sm="all" colSpan-md="4-11" colSpan-lg="5-11" rowSpan-md="0">
              <Container bg="secondary.lightBlue.t15">
                <Hero src="" srcMobile={groupLFrameCardImageMobile} alt="LFrame card" />
              </Container>
            </Grid.Item>

            <Grid.Item colSpan="all" colSpan-sm="all" colSpan-md="0-3" colSpan-lg="0-4" rowSpan-md="0">
              <ApprovedInformation
                merchantName={merchantName}
                trackEventSender={trackEventSender}
                getLinkWithAnalytics={getLinkWithAnalytics}
                creditLimit={creditLimit}
                gemVisaAccountNumber={gemVisaAccountNumber}
                firstName={acquisition.personalDetails.firstName}
                email={acquisition.contactDetails.emailAddress}
              />
            </Grid.Item>

            {merchantName === APPLE_MERCHANT && (
              <Grid.Item colSpan="all" colSpan-sm="all" colSpan-md="0-11" colSpan-lg="0-11" rowSpan-md="1">
                <AppleApplicantsBox storeState={storeState} />
              </Grid.Item>
            )}

            <Grid.Item colSpan="all" colSpan-sm="all" colSpan-md="0-5" colSpan-lg="0-5" rowSpan-md="2">
              <DownloadResources trackEventSender={trackEventSender} getLinkWithAnalytics={getLinkWithAnalytics} />
            </Grid.Item>
          </Grid>
        </TopContainer>

        <Container bg="secondary.lightBlue.t15" bg-md="white" padding="5" padding-md="10 0 10 10">
          <Text textStyle="heading4" textStyle-md="heading4" padding="4">
            Thank you for choosing Gem Visa
          </Text>
        </Container>

        <Container>
          <Grid preset="page" colsGap={2}>
            <Grid.Item
              colSpan="all"
              colSpan-sm="all"
              colSpan-md="all"
              colSpan-lg="0-5"
              rowSpan="0"
              rowSpan-md="0"
              rowSpan-lg="0"
            >
              <ShoppingInformation trackEventSender={trackEventSender} getLinkWithAnalytics={getLinkWithAnalytics} />
            </Grid.Item>
            <Grid.Item
              colSpan="all"
              colSpan-sm="all"
              colSpan-md="all"
              colSpan-lg="6-11"
              rowSpan="1"
              rowSpan-md="1"
              rowSpan-lg="0"
            >
              <Container>
                <Grid preset="page">
                  <Grid.Item colSpan="all">
                    <FeatureInformation
                      trackEventSender={trackEventSender}
                      getLinkWithAnalytics={getLinkWithAnalytics}
                    />
                  </Grid.Item>
                  <Grid.Item colSpan="all">
                    <StuffLayout />
                  </Grid.Item>
                </Grid>
              </Container>
            </Grid.Item>
          </Grid>

          <Container bg="secondary.lightBlue.t15" padding="6 8" margin="4" margin-md="10">
            <Container>
              <Text align="left" textStyle="legal">
                *Normal credit and lending criteria and fees, including a Gem Visa ${establishmentFee} establishment fee
                and ${annualFee} annual fee (charged ${biAnnualFee?.toFixed(2)} half-yearly) apply. Prevailing interest
                rate (currently {currentInterestRate}% p.a.) applies to any remaining balance on the expiry of the
                interest free period. Paying only the minimum monthly repayment of 3% of the outstanding monthly balance
                or $20 (whichever is more), will not be sufficient to repay the purchase amount(s) within the
                promotional period. Amount payable will be shown on your monthly statement. For cash advances, an
                interest rate of {cashAnnualRate}% p.a. and a ${cashWithdrawalFee} fee applies. Further information on
                rates and fees can be found at{' '}
                <InternalLink
                  label={overviewSources.gemfinance_url.label}
                  url={overviewSources.gemfinance_url.url}
                  eventConfig={internalLinkEventConfig}
                  newTab
                />
                . Available to approved Gem Visa credit card customers only. Credit provided by Latitude Financial
                Services Ltd.
              </Text>
            </Container>

            <Container padding="2 0">
              <Text align="left" textStyle="legal">
                #Long term interest free offers may vary. Available at participating retailers to approved customers
                only. Conditions, fees and charges may apply. Ask in-store for further details.
              </Text>
            </Container>

            <Container padding="2 0">
              <Text align="left" textStyle="legal">
                **Apple Store and the Apple logo are trademarks of Apple Inc., registered in the U.S. and other
                countries and regions. Apple Store is a service mark of Apple Inc.
              </Text>
            </Container>

            <Container padding="2 0">
              <Text align="left" textStyle="legal">
                Google Play and the Google Play logo are trademarks of Google LLC.
              </Text>
            </Container>

            <Container padding="2 0">
              <Text align="left" textStyle="legal">
                Visa and the Visa brand are registered trademarks of Visa International.
              </Text>
            </Container>

            <Container padding="2 0">
              <Text align="left" textStyle="legal">
                Gem Visa is a credit facility provided by Latitude Financial Services Limited.
              </Text>
            </Container>
          </Container>
        </Container>
      </ContentWrapper>
    );
  };

  const OutcomeApprovedAU = () => {
    const productName = getProductName();
    const ratesAndFees = getRatesAndFees();

    return (
      <Container margin="0 0 13 0" hasBreakpointWidth>
        <Container padding="5 0" padding-md="5" testId="LAYOUT FORM">
          <Grid preset="page" debug={false}>
            <Grid.Item colsGap="2" colSpan="all" colSpan-sm="1-6" colSpan-md="1-6" colSpan-lg="2-9" colSpan-xl="3-8">
              <Grid rowsGap="6">
                <Container padding="1 0 0 0" padding-lg="8 0 0 0">
                  <Text as="h1" textStyle="heading3" testId="congratulations">
                    Congratulations!
                  </Text>
                  <Text />
                </Container>
                <SmallContainerWrapper>
                  <Container padding="8" padding-lg="10">
                    <Grid rowsGap="4">
                      <Grid.Item colSpan="all" colSpan-lg="0-5" rowSpan-md="0">
                        <TopImageWrapper productCode={config.productCode}>
                          <img src={auCard} alt="AU Card" />
                        </TopImageWrapper>
                      </Grid.Item>
                      <Grid.Item colSpan="all" colSpan-lg="6-11" rowSpan="1" rowSpan-lg="0">
                        <TextAlignWrapper>
                          <Text as="h2" textStyle="heading5" align="center">
                            {isDJ() ? (
                              <>
                                You're approved for <data-hj-suppress>${creditLimit}</data-hj-suppress> {productName}{' '}
                                credit card
                              </>
                            ) : (
                              <>
                                You're approved for the {productName}{' '}
                                {byCard({
                                  [PRODUCT_CODE_LOW_RATE]: '',
                                  default: 'credit card',
                                })}{' '}
                                with a <data-hj-suppress>${creditLimit}</data-hj-suppress> credit limit
                              </>
                            )}
                          </Text>
                        </TextAlignWrapper>
                      </Grid.Item>
                    </Grid>
                  </Container>
                </SmallContainerWrapper>
                <Container>
                  <Text>
                    {isDJ() ? (
                      <>
                        Application reference number: <b>{applicationRef}</b>
                      </>
                    ) : (
                      <>
                        Account number: <b>{accountNumber}</b>
                      </>
                    )}
                  </Text>
                </Container>
                <Stack gap="4">
                  <Container margin="0 0 0 4">
                    <Text as="h3" textStyle="heading5">
                      What happens next?
                    </Text>
                  </Container>
                  {whatHappensNextStepsAu.map(({ icon, label }, index) => (
                    <IconTextWrapper key={label + index}>
                      {icon}
                      <RightTextWrapper>
                        <Text>{label}</Text>
                      </RightTextWrapper>
                    </IconTextWrapper>
                  ))}
                </Stack>
                <Container bg="grey.t03" margin="0 2 0 0" margin-xs={0} padding="0 0 4 0">
                  {shopOnlineAndInStoreAu.boxes.map(({ heading, steps }, index) => (
                    <>
                      {index !== 0 &&
                        shopOnlineAndInStoreAu.boxes.length > 1 &&
                        shopOnlineAndInStoreAu.showSeparator && <DividerWithText data-content="or" />}
                      <Container key={`shopOnlineAndInStoreAu.boxes.ShadowedContainer ${index}`} padding="4 4 0 4">
                        <ShadowedContainer>
                          <Container bg="white" padding="6">
                            <ImageHeaderWrapper>
                              <img src={heading.imageSrc} alt={heading.altText} />
                              <TextHeaderWrapper>
                                <Text as="h3" textStyle="heading5">
                                  {heading.text}
                                </Text>
                              </TextHeaderWrapper>
                            </ImageHeaderWrapper>
                            {steps.map(
                              (
                                { title, subTitle, list, showAppDownloadButtons, showAppleGoogleSamsungPay, ctaButton },
                                idx,
                              ) => (
                                <React.Fragment key={`steps ${idx}`}>
                                  <NumberedListWrapper>
                                    <StyledNumberListItem style={{ background: ellipse }}>
                                      {idx + 1}
                                    </StyledNumberListItem>
                                    <NumberedTextWrapper>
                                      <StyledStrong>{title}</StyledStrong>
                                    </NumberedTextWrapper>
                                  </NumberedListWrapper>
                                  {subTitle && <Text margin="0 0 0 9">{subTitle}</Text>}
                                  <StyledUL marginLeft="24px" marginTop="4px" marginBottom="8px">
                                    {list.map(({ title: listItemTitle, text }, listIdx) => (
                                      <li key={`steps.list ${idx} ${listIdx}`}>
                                        {listItemTitle && <StyledStrong>{listItemTitle}</StyledStrong>} {text}
                                      </li>
                                    ))}
                                  </StyledUL>
                                  {showAppDownloadButtons && (
                                    <Container margin="1 0 2 10" padding="3 0 4 3">
                                      <Flex width="100%">
                                        <Container>
                                          <Link appearance="icon" href={shoppingInformation.apple_store.url} newTab>
                                            <DownloadAppImage
                                              src={DownloadApple}
                                              onClick={() =>
                                                trackEventSender({
                                                  location: shoppingInformation.apple_store.location,
                                                  label: shoppingInformation.apple_store.label,
                                                })
                                              }
                                            />
                                          </Link>
                                        </Container>
                                        <Container>
                                          <Link appearance="icon" href={shoppingInformation.google_store.url} newTab>
                                            <DownloadAppImage
                                              src={DownloadGoogle}
                                              onClick={() =>
                                                trackEventSender({
                                                  location: shoppingInformation.google_store.location,
                                                  label: shoppingInformation.google_store.label,
                                                })
                                              }
                                            />
                                          </Link>
                                        </Container>
                                      </Flex>
                                    </Container>
                                  )}
                                  {showAppleGoogleSamsungPay && (
                                    <Container margin="1 0 2 10" padding="3 0 4 3">
                                      <img src={appleGoogleSamsungPay} alt="Apple Google and Samsung Pay" />
                                    </Container>
                                  )}
                                  {ctaButton && <div>{ctaButton}</div>}
                                </React.Fragment>
                              ),
                            )}
                          </Container>
                        </ShadowedContainer>
                      </Container>
                    </>
                  ))}
                </Container>
                {isGemAU() && (
                  <Text>
                    ^To take advantage of up to 55 days Interest Free on every day credit card purchases, you need to
                    pay the full closing balance (excluding un-expiring {ratesAndFees.interestRate}% Interest Payment
                    Plans) on each statement of account by the applicable due date.
                  </Text>
                )}
              </Grid>
            </Grid.Item>
          </Grid>
        </Container>
      </Container>
    );
  };
  return byCountry({
    AU: <OutcomeApprovedAU />,
    NZ: <OutcomeApprovedNZ />,
  });
}

export { OutcomeApproved };
