import { useEventTracking } from 'react-event-tracker';
import { getDataLayerElements } from 'utils/getDataLayerElements';

const useDataLayerAndAnalyticsLinks = storeState => {
  const { trackEvent, getQueryString } = useEventTracking();

  const trackEventSender = (
    { category = 'text-link', action = 'internal-link', location, label, value },
    updateState,
  ) => {
    trackEvent({
      event: {
        category,
        action,
        location,
        label,
        ...(value ? { value } : {}),
      },
      ...getDataLayerElements({ ...storeState, ...updateState }),
    });
  };

  const getLinkWithAnalytics = ({ location, label, url }) => {
    if (!location || !label || !url) {
      return null;
    }

    const gemid2ParamValue = getQueryString({
      event: {
        category: 'text-link',
        action: 'internal-link',
        location,
        label,
      },
    });

    const link = new URL(url);
    link.searchParams.set('gemid2', gemid2ParamValue);
    return link.href;
  };

  return { trackEventSender, getLinkWithAnalytics };
};

export { useDataLayerAndAnalyticsLinks };
