import React from 'react';
import { useStoreValue } from 'store';
import { LayoutPage, LayoutContent, Form, FormRenderer } from 'components';
import { initFormConfig, submitHandler } from 'utils';
import { config } from '_config';
import { useFormInitialValues, useSteps } from '../../hooks';

const FORM_ID = 'biometricsAboutYou';
const formConfig = initFormConfig(config.formConfigs[FORM_ID]);

export const BiometricsAboutYou = () => {
  const { goNext } = useSteps();
  const [{ acquisition }] = useStoreValue();
  const initialValues = useFormInitialValues(FORM_ID);

  const onSubmit = ({ values, errors }) => {
    function updateStoreAndRedirect() {
      const newAcquisition = {
        ...acquisition,
        biometricsAboutYou: { ...values },
      };

      goNext({ storePatch: { acquisition: newAcquisition } });
    }

    submitHandler({ submit: updateStoreAndRedirect, errors });
  };

  return (
    <LayoutPage noSessionTimeout>
      <Form
        id={FORM_ID}
        initialValues={initialValues}
        onSubmit={onSubmit}
        showSaveDraftButton
        saveDraftOnSubmit
        saveDraftOnSessionTimeout
      >
        {formData => {
          return (
            <LayoutContent>
              <FormRenderer config={formConfig} formData={formData} />
            </LayoutContent>
          );
        }}
      </Form>
    </LayoutPage>
  );
};
