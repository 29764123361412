import { useState } from 'react';
import axios from 'axios';
import { useHandleApiErrors } from './useHandleApiErrors';

const useGetUserInfo = ({ onSuccess, onError } = {}) => {
  const [loading, setLoading] = useState(false);
  const { handleError } = useHandleApiErrors('axios', onError);

  const getUserInfo = async accessToken => {
    const url = `${process.env.REACT_APP_OKTA_DOMAIN}/oauth2/${process.env.REACT_APP_OKTA_ISSUER_ID}/v1/userinfo`;
    const options = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    try {
      setLoading(true);
      const result = await axios.get(url, options);
      setLoading(false);
      onSuccess && onSuccess(result.data);
      return result.data;
    } catch (error) {
      setLoading(false);
      handleError(error);
      return false;
    }
  };

  return { loading, getUserInfo };
};

export { useGetUserInfo };
