import React from 'react';
import { Container, Flex, Text } from 'basis';
import { formatNumber } from 'utils/formatNumber';
import { calculateMonthlyRepayment } from 'utils/calculateMonthlyRepayment';
import { isAU, isDJ } from 'utils';

export const ApprovedCreditLimit = ({ creditLimitApproved }) => {
  const bgColor = isAU() && !isDJ() ? 'specialBackground' : 'grey.t07';
  return (
    <>
      <Container name="creditLimitAmount" bg={bgColor} width="100%" padding="6" margin="3 0 0 0">
        <Flex height="100%" placeItems="top center">
          <Text textStyle="heading3" testId="approved-credit-limit">
            <span data-hj-suppress>${formatNumber(creditLimitApproved)}</span>
          </Text>
        </Flex>
      </Container>
      <Container margin="0 0 8 0">
        <Text testId="monthly-repayment">
          Your minimum monthly repayment will be{' '}
          <b data-hj-suppress>${calculateMonthlyRepayment(creditLimitApproved)} at this limit</b>
        </Text>
      </Container>
    </>
  );
};
