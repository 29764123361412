import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Grid } from 'basis';
import { useSteps } from 'hooks';
import { isFeatureOn } from 'featureToggles';
import { useEventTracking } from 'react-event-tracker';
import { startCase } from 'lodash';
import { byCountry } from 'utils';
import styled from '@emotion/styled';
import { SaveAndCompleteLaterButton } from 'components/save-and-resume';
import { LayoutContent } from 'components/layout-content/LayoutContent';

export const FormButtons = ({
  formId,
  formData,
  loading,
  disabled,
  submitButtonLabel,
  noSaveDraft,
  showBackButton,
}) => {
  const history = useHistory();
  const { activeStep, pathname, isDrafting, previousStep, accessibleSteps, nextStep } = useSteps();
  const hasBackButton =
    showBackButton ?? (isDrafting && activeStep !== accessibleSteps[0] && pathname !== accessibleSteps[0]);

  const { trackEvent } = useEventTracking();
  const onClickBack = useCallback(() => {
    trackEvent({
      event: {
        category: 'application',
        action: 'application-navigation',
        location: pathname.slice(1),
        label: 'Back',
      },
    });
    if (previousStep) {
      history.push(previousStep);
    }
  }, [history, previousStep, trackEvent, pathname]);

  const buttonLabel = useMemo(() => {
    const nextStepTitle = startCase(nextStep);
    return submitButtonLabel.replace(/%%NEXT_STEP/, nextStepTitle);
  }, [nextStep, submitButtonLabel]);

  const isSaveAndResumeFeatureOn = byCountry({
    AU: isFeatureOn('saveAndResume'),
    NZ: isFeatureOn('saveAndResume') && isFeatureOn('showSaveAndResumeButton'),
  });

  const formButtonsConfig = {
    hasBackButton,
    buttonLabel,
    loading,
    disabled,
    onClickBack,
    formId,
    formData,
    isDrafting,
    noSaveDraft,
    isSaveAndResumeFeatureOn,
  };

  const ButtonGroup = byCountry({
    AU: ButtonGroupWithSaveAndCompleteLaterButton,
    NZ: isSaveAndResumeFeatureOn ? ButtonGroupWithSaveAndCompleteLaterButton : ButtonGroupWithBackButton,
  });

  return (
    <LayoutContent>
      <ButtonGroup {...formButtonsConfig} />
    </LayoutContent>
  );
};

const ButtonGroupWithBackButton = ({ hasBackButton, buttonLabel, loading, disabled, onClickBack }) => {
  return (
    <Grid rowsGap="4" colsGap="2">
      {hasBackButton && (
        <Grid.Item colSpan="all" rowSpan="1" colSpan-sm="0-2" rowSpan-sm="0">
          <Grid>
            <Button testId="backButton" type="button" variant="secondary" disabled={loading} onClick={onClickBack}>
              Back
            </Button>
          </Grid>
        </Grid.Item>
      )}
      <Grid.Item colSpan="all" colSpan-sm={hasBackButton ? '5-7' : 'all'} alignItems="center">
        <Grid>
          <Button type="submit" loading={loading} disabled={disabled}>
            {buttonLabel}
          </Button>
        </Grid>
      </Grid.Item>
    </Grid>
  );
};

const ButtonGroupWithSaveAndCompleteLaterButton = ({
  hasBackButton,
  buttonLabel,
  loading,
  disabled,
  onClickBack,
  formId,
  formData,
  isDrafting,
  noSaveDraft,
  isSaveAndResumeFeatureOn,
}) => {
  return (
    <Grid rowsGap="4" colsGap="10" cols="2">
      <Grid.Item colSpan="all" colSpan-sm="0" alignItems="center">
        <FormButtonWrapper>
          <Grid>
            <Button type="submit" loading={loading} disabled={disabled} padding="0 2">
              {buttonLabel}
            </Button>
          </Grid>
        </FormButtonWrapper>
      </Grid.Item>
      {isSaveAndResumeFeatureOn && isDrafting && noSaveDraft !== true && (
        <Grid.Item colSpan="all" colSpan-sm="1" rowSpan-sm="0">
          <FormButtonWrapper>
            <Grid>
              <SaveAndCompleteLaterButton formId={formId} formData={formData} isDrafting />
            </Grid>
          </FormButtonWrapper>
        </Grid.Item>
      )}
    </Grid>
  );
};

const FormButtonWrapper = styled.div`
  & button {
    padding: 12px 16px;
  }
`;
