import React, { createContext, useContext, useReducer } from 'react';
import { storeToDataDogGlobalContext } from 'utils/loadDataDogRum';
import { setSessionStorage } from 'utils/sessionStorage';
import 'types';

export const storageStateKey = 'store';

/**
 *
 * @param {storeState} existingState
 * @param {storeState} newState
 * @returns storeState
 */
const reducer = (existingState, newState) => {
  const state = {
    ...existingState,
    ...newState,
  };
  setSessionStorage(storageStateKey, state);
  storeToDataDogGlobalContext(state);
  return state;
};

export const StoreContext = createContext({});

/**
 * @name StoreProvider
 * @description Provider component for the store
 * @typedef {Object} PropsType
 * @property {storeState} initialState
 * @property {ReactNode} children
 * @param {PropsType} props
 * @returns {JSX.Element}
 */
export function StoreProvider({ initialState, children }) {
  return <StoreContext.Provider value={useReducer(reducer, initialState)}>{children}</StoreContext.Provider>;
}

/**
 * @name useStoreValue
 * @returns {storeState}
 */
export const useStoreValue = () => useContext(StoreContext);
