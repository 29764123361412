import React from 'react';
import { Button, Container, Text, List, Grid } from 'basis';
import { Modal } from 'components/modal/Modal';

const WarningModalContent = ({ warnings }) => {
  switch (true) {
    case warnings.customContent !== undefined:
      return <>{warnings.customContent}</>;
    case warnings.length === 1:
      return <Text>{warnings[0]}</Text>;
    case warnings.length > 1:
      return (
        <List type="ordered">
          {warnings.map((warning, index) => (
            <List.Item key={index}>
              <Text>{warning}</Text>
            </List.Item>
          ))}
        </List>
      );
    default:
      return null;
  }
};

export const WarningModal = ({
  title,
  isOpen,
  onClose,
  warnings,
  onSubmit,
  editButtonText = 'Edit',
  confirmButtonText = 'Continue',
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} style={{ maxWidth: 600 }}>
      <Container padding="9">
        <Text textStyle="heading5">{title}</Text>

        <Container padding="9 0">
          <WarningModalContent warnings={warnings} />
        </Container>

        <Grid rowsGap="4" colsGap="2">
          <Grid.Item colSpan="all" colSpan-sm="0-1">
            <Grid>
              <Button testId="editButton" type="button" variant="secondary" onClick={onClose}>
                {editButtonText}
              </Button>
            </Grid>
          </Grid.Item>
          <Grid.Item colSpan="all" colSpan-sm="2-3" alignItems="center">
            <Grid>
              <Button onClick={onSubmit}>{confirmButtonText}</Button>
            </Grid>
          </Grid.Item>
        </Grid>
      </Container>
    </Modal>
  );
};
