import { useState } from 'react';
import axios from 'axios';
import { useStoreValue } from 'store';
import { DEV_FEATURES } from '__dev/devFeatures';
import { byCountry } from 'utils/byConfig';
import { useOktaClient } from './useOktaClient';
import { useHandleApiErrors } from './useHandleApiErrors';

const registerURL = process.env.REACT_APP_USER_REGISTER_URL;

const useRegisterAndLoginUser = ({ onSuccess, onError }) => {
  const [{ applicationId }, updateStore] = useStoreValue();
  const [loading, setLoading] = useState(false);
  const { handleError } = useHandleApiErrors('axios', onError);
  const { loginAndSendOTP } = useOktaClient({ onError });

  const submit = async ({ email, mobile }) => {
    const mobilePhone =
      DEV_FEATURES.OTP_MOBILE_NUMBER ||
      byCountry({
        AU: `+61${mobile.slice(1)}`,
        NZ: `+64${mobile.slice(1)}`,
      });

    const payload = {
      profile: {
        email,
        mobile_phone: mobilePhone,
      },
    };

    const options = {
      headers: {
        'x-correlation-id': applicationId,
        'lfs-request-channel': byCountry({
          NZ: process.env.REACT_APP_OKTA_HEADER,
          AU: process.env.REACT_APP_OKTA_HEADER_AU,
        }),
      },
    };

    try {
      setLoading(true);

      const registerData = await axios.post(registerURL, payload, options);

      updateStore({
        applicationErrors: null,
        maskedMobileNumber: registerData.data.profile?.masked_mobile_phone
          ?.replace('+61', '0')
          ?.replace('+64', '0')
          ?.replace('****', ' **** '),
      });

      const success = await loginAndSendOTP(email);

      setLoading(false);
      success && onSuccess && onSuccess();
    } catch (err) {
      setLoading(false);
      handleError(err);
    }
  };

  return {
    loading,
    submit,
  };
};

export { useRegisterAndLoginUser };
