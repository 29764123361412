export const initialFormValues = {
  overview: {
    promoCode: '',
    consentGiven: null,
    applicationReason: '',
    conditionalStop: '',
  },
  contactDetails: {
    mobileNumber: '',
    emailAddress: '',
    confirmEmail: '',
  },
  verification: {
    verificationCode: '',
  },
  emailVerification: {
    verificationCode: '',
  },
  password: {
    password: '', // verbose password. Required when a user doesn't have a valid biometrics verification.
  },
  biometricsAboutYou: {
    title: '',
    relationshipStatus: '',
    numberOfDependents: '',
  },
  biometricsAddressDetails: {
    residentialStatus: '',
    currentAddress: {
      useManualAddress: false,
      address: {
        unitNumber: '',
        streetNumber: '',
        streetName: '',
        streetType: '',
        suburb: '',
        postcode: '',
        poBoxNumber: '',
        addressType: '',
        countryCode: '',
        state: '',
      },
    },
    previousAddress: {
      useManualAddress: false,
      address: {
        unitNumber: '',
        streetNumber: '',
        streetName: '',
        streetType: '',
        suburb: '',
        postcode: '',
        poBoxNumber: '',
        addressType: '',
        countryCode: '',
        state: '',
      },
    },
    postalAddress: {
      useManualAddress: false,
      address: {
        unitNumber: '',
        streetNumber: '',
        streetName: '',
        streetType: '',
        suburb: '',
        postcode: '',
        poBoxNumber: '',
        addressType: '',
        countryCode: '',
        state: '',
      },
    },
    hasNonDomesticPreviousAddress: false,
    isPostalSameWithResidential: '',
    lengthOfCurrentResidency: {
      years: '',
      months: '',
    },
  },
  personalDetails: {
    title: '',
    firstName: '',
    middleName: '',
    lastName: '',
    dateOfBirth: {
      day: '',
      month: '',
      year: '',
    },
    hasAuDL: 'yes',
    hasMiddleName: 'no',
    australianState: '',
    driverLicenceNumber: '',
    driverLicenceCardNumber: '',
    relationshipStatus: '',
    numberOfDependents: '',
    crbConsent: false,
    dvsConsent: false,
  },
  otherDetails: {
    residentialStatus: '',
    lengthOfCurrentResidency: {
      years: '',
      months: '',
    },
    isPostalSameWithResidential: '',
    hasNonDomesticPreviousAddress: false,
    currentAddress: {
      useManualAddress: false,
      address: {
        unitNumber: '',
        streetNumber: '',
        streetName: '',
        streetType: '',
        suburb: '',
        postcode: '',
        poBoxNumber: '',
        addressType: '',
        countryCode: '',
        state: '',
      },
    },
    previousAddress: {
      useManualAddress: false,
      address: {
        unitNumber: '',
        streetNumber: '',
        streetName: '',
        streetType: '',
        suburb: '',
        postcode: '',
        poBoxNumber: '',
        addressType: '',
        countryCode: '',
        state: '',
      },
    },
    postalAddress: {
      useManualAddress: false,
      address: {
        unitNumber: '',
        streetNumber: '',
        streetName: '',
        streetType: '',
        suburb: '',
        postcode: '',
        poBoxNumber: '',
        addressType: '',
        countryCode: '',
        state: '',
      },
    },
  },
  extraDetails: {
    idType: '',
    medicareCardName: '',
    individualReferenceNumber: '',
    medicareCardNumber: '',
    licenceNumber: '',
    version: '',
    idNumber: '',
    expiryDate: {
      day: '',
      month: '',
      year: '',
    },
    australianState: '',
    auPassportNumber: '',
    issuingCountry: '',
  },
  expensesDetails: {
    isExpensesShared: '',
    mortgageRentBoard: {
      amount: '',
      frequency: 'monthly',
    },
    foodGroceriesLifestyleEntertainment: {
      amount: '',
      frequency: 'monthly',
    },
    insurance: {
      amount: '',
      frequency: 'monthly',
    },
    utilities: {
      amount: '',
      frequency: 'monthly',
    },
    educationAndChildcare: {
      amount: '',
      frequency: 'monthly',
    },
    transport: {
      amount: '',
      frequency: 'monthly',
    },
    personalAndOtherExpense: {
      amount: '',
      frequency: 'monthly',
    },
  },
  employmentDetails: {
    industry: '',
    employerName: '',
    lengthOfTimeAtCurrentEmployer: {
      years: '',
      months: '',
    },
    lengthOfUnemployment: {
      years: '',
      months: '',
    },
    income: {
      amount: '',
      frequency: 'fortnightly',
    },
    employmentStatus: '',
    hasOtherIncome: '',
    otherEmploymentStatus: '',
    otherIncome: {
      amount: '',
      frequency: 'monthly',
    },
  },
  additionalDebtsDetails: {
    hasOtherDebts: '',
    secondaryPropertyMortgageRentBoard: {
      amount: '',
      frequency: 'monthly',
    },
    totalCardLimits: '',
    amountOwingOnCreditCard: '',
    loanRepayment: {
      amount: '',
      frequency: 'monthly',
    },
    hasLoanLumpSumPayment: '',
    payLumpSumWithExistingSaving: '',
  },
  confirmCreditLimit: {
    newCreditLimit: '',
    creditLimitOptions: null,
  },
  confirmReferredCreditLimit: {
    newCreditLimit: '',
  },
  acceptance: {
    accepted: false,
  },
  achDetails: {
    hasAch: null,
    sameCurrentAddress: 'yes',
    personalDetails: {
      hasMiddleName: 'no',
      title: '',
      firstName: '',
      lastName: '',
      middleName: '',
      dateOfBirth: {
        day: '',
        month: '',
        year: '',
      },
      mobileNumber: '',
      emailAddress: '',
    },
    currentAddress: {
      useManualAddress: false,
      address: {
        unitNumber: '',
        streetNumber: '',
        streetName: '',
        streetType: '',
        suburb: '',
        postcode: '',
        poBoxNumber: '',
        addressType: '',
        countryCode: '',
        state: '',
      },
    },
  },
};
