import React from 'react';
import styled from '@emotion/styled';
import { Text, Flex } from 'basis';

const Order = styled.div`
  border-radius: 50%;
  text-align: center;
  background-color: black;
  color: white;
  width: 24px;
  line-height: 24px;
  margin-right: 12px;
  height: 24px;
  flex-shrink: 0;
`;

export const AusPostAction = ({ order, children }) => {
  return (
    <Flex>
      <Order>{order}</Order>
      <Text>
        <strong>{children}</strong>
      </Text>
    </Flex>
  );
};
