import { numbersOnlyPattern, creditLimitSourceOptions, OptionUser } from '../_constants';
import { minCreditLimit, maxCreditLimit, creditLimitIncrement } from './constants';

const creditLimitValidationMessages = {
  required: 'Please tell us your preferred credit limit in dollars.',
  minValue: `Please enter an credit limit amount of \${{minValue}} or more in \${{incrementValue}} increments.`,
  maxValue: `The maximum amount you can enter is \${{maxValue}}.`,
  minLength: 'Must have at least {{minLength}} digits.',
  maxLength: 'Must have at most {{maxLength}} digits.',
  incrementValue: `Please enter an amount in \${{incrementValue}} increments.`,
  invalid: 'Please enter a whole dollar amount, without cents.',
};

export const creditLimitDetails = [
  {
    label: 'What credit limit would you like?',
    component: 'RadioGroup',
    name: 'creditLimitSource',
    testId: 'credit-limit-source',
    options: creditLimitSourceOptions,
    columns: 1,
    validate: {
      messages: {
        required: 'Please select an option.',
      },
    },
  },
  {
    condition: values => values.creditLimitSource === OptionUser,
    label: 'Enter your preferred credit limit',
    helpText: `Your credit limit cannot be less than $${new Intl.NumberFormat().format(minCreditLimit)}.`,
    component: 'Input',
    variant: 'commaNumeric',
    name: 'creditLimit',
    testId: 'credit-limit',
    prefix: '$',
    validate: {
      props: {
        minValue: minCreditLimit,
        maxValue: maxCreditLimit,
        minLength: 3,
        maxLength: 6,
        incrementValue: creditLimitIncrement,
        validPattern: numbersOnlyPattern,
      },
      messages: creditLimitValidationMessages,
    },
  },
];
