import React from 'react';
import { Button, Container, Text, Flex, List, Grid, Link } from 'basis';
import { Modal } from 'components/modal/Modal';
import QLDBack from 'images/driverLicences/QLD_BACK.png';
import QLDFront from 'images/driverLicences/QLD_FRONT.png';
import VICBack from 'images/driverLicences/VIC_BACK2.png';
import VICBackNew from 'images/driverLicences/VIC_BACK_NEW2.png';
import VICChange from 'images/driverLicences/VIC-CHANGEOFADDRESS3.png';
import NSW from 'images/driverLicences/LFS_NSW2.png';
import ACT from 'images/driverLicences/LFS_ACT1.png';
import NTPre from 'images/driverLicences/NT_PRE_NOV1.png';
import NTPost from 'images/driverLicences/LFS_NT_POST1.png';
import TAS from 'images/driverLicences/TAS_BACK1.png';
import SA from 'images/driverLicences/SA_BACK2.png';
import WA from 'images/driverLicences/WA_BACK1.png';
import styled from '@emotion/styled';
import { useStoreValue } from 'store';
import { useDataLayerAndAnalyticsLinks } from 'hooks/useDataLayerAndAnalyticsLinks';
import { useFormCache } from '../../hooks/useFormCache';

const modalStyle = {
  width: 600,
};

const driverLicenceImages = {
  QLD: [
    {
      type: 'text',
      value: 'Driver Licence issued post 12 June 2019',
      key: 'qld-text-1',
    },
    {
      type: 'image',
      value: {
        url: QLDFront,
        alt: 'driver-licence-card',
        key: 'qld-image-1',
      },
    },
    {
      type: 'text',
      value: 'Driver Licence issued before June 2019',
      key: 'qld-text-2',
    },
    {
      type: 'image',
      value: {
        url: QLDBack,
        alt: 'driver-licence-card',
        key: 'qld-image-2',
      },
    },
    {
      type: 'findCardText',
      value:
        'Card number is located either on the front of the card and in the middle bottom or back of the card and on the bottom right. ',
      key: 'qld-find-card-text',
    },
  ],
  VIC: [
    {
      type: 'image',
      value: {
        url: VICBack,
        alt: 'driver-licence-card',
        key: 'vic-image-1',
      },
    },
    {
      type: 'text',
      value: 'Driver Licence issued post November 2022',
      key: 'vic-text-1',
    },
    {
      type: 'image',
      value: {
        url: VICBackNew,
        alt: 'driver-licence-card',
        key: 'vic-image-2',
      },
    },
    {
      type: 'text',
      value: 'Driver Licence issued pre November 2020',
      key: 'vic-text-2',
    },
    {
      type: 'image',
      value: {
        url: VICChange,
        alt: 'driver-licence-card',
        key: 'vic-image-3',
      },
    },
    {
      type: 'findCardText',
      value: 'Card number is located on the back of the card either in the middle near birth year or on the top right.',
      key: 'vic-find-card-text',
    },
  ],
  NSW: [
    {
      type: 'image',
      value: {
        url: NSW,
        alt: 'driver-licence-card',
        key: 'nsw-image',
      },
    },
    {
      type: 'findCardText',
      value: 'Card number is located on the front of your card on the top right.',
      key: 'nsw-find-card-text',
    },
  ],
  ACT: [
    {
      type: 'image',
      value: {
        url: ACT,
        alt: 'driver-licence-card',
        key: 'act-image',
      },
    },
    {
      type: 'findCardText',
      value: 'Card number is located on the front of the card and runs vertically alongside the photo.',
      key: 'act-find-card-text',
    },
  ],
  NT: [
    {
      type: 'text',
      value: 'Driver Licence issued pre 1st November 2020',
      key: 'nt-text-1',
    },
    {
      type: 'image',
      value: {
        url: NTPre,
        alt: 'driver-licence-card',
        key: 'nt-image-1',
      },
    },
    {
      type: 'text',
      value: 'Driver Licence issued post 1st November 2020',
      key: 'nt-text-2',
    },
    {
      type: 'image',
      value: {
        url: NTPost,
        alt: 'driver-licence-card',
        key: 'nt-image-2',
      },
    },
    {
      type: 'findCardText',
      value:
        'Card number is located on the back of the card post November 2020 and on the bottom left pre November 2020.',
      key: 'nt-find-card-text',
    },
  ],
  TAS: [
    {
      type: 'image',
      value: {
        url: TAS,
        alt: 'driver-licence-card',
        key: 'tas-image',
      },
    },
    {
      type: 'findCardText',
      value: 'Card number is located on the back of the card and on the top right.',
      key: 'tas-find-card-text',
    },
  ],
  SA: [
    {
      type: 'image',
      value: {
        url: SA,
        alt: 'driver-licence-card',
        key: 'sa-image',
      },
    },
    {
      type: 'findCardText',
      value: 'Card number is located on the back of the card and on the top right.',
      key: 'sa-find-card-text',
    },
  ],
  WA: [
    {
      type: 'image',
      value: {
        url: WA,
        alt: 'driver-licence-card',
        key: 'wa-image',
      },
    },
    {
      type: 'findCardText',
      value: 'Card number is located on the back of the card and in the middle on the justified right.',
      key: 'wa-find-card-text',
    },
  ],
};

const StyledList = styled.div`
  li::before {
    background-color: black;
    width: 0.3em;
    height: 0.3em;
  }
`;

const CardNumberModal = ({ isOpen, onClose }) => {
  const { getFormCacheValues } = useFormCache();
  const { australianState } = getFormCacheValues('personalDetails');
  const [state] = useStoreValue();
  const { trackEventSender } = useDataLayerAndAnalyticsLinks(state);

  if (!australianState || !driverLicenceImages[australianState]) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} style={modalStyle}>
      <Container padding="3" padding-md="8">
        <Text textStyle="heading4" margin="0 0 6 0" margin-md="0 0 7 0" testId="card-number-modal-title">
          {australianState} Licence Card Number
        </Text>

        <Container>
          {driverLicenceImages[australianState].map(element => {
            if (element.type === 'text') {
              return (
                <Text margin="3 0 3 0" key={element.key}>
                  <b>{element.value}</b>
                </Text>
              );
            }
            if (element.type === 'image') {
              return (
                <Grid preset="page" colsGap="0px" key={element.value.key}>
                  <Grid.Item colSpan="0-1" colSpan-sm="1-4" colSpan-md="1-3" colSpan-lg="3-6">
                    <Flex height="100%" placeItems="center left">
                      <img key={element.value.url} src={element.value.url} alt={element.value.alt} />
                    </Flex>
                  </Grid.Item>
                </Grid>
              );
            }
            return null;
          })}
        </Container>

        <Container padding="3 0 1 0">
          <Text textStyle="body1" margin="3 0">
            <b>Find your card number</b>
          </Text>
          <Text textStyle="body1" margin="3 0">
            {driverLicenceImages[australianState].find(item => item.type === 'findCardText').value}
          </Text>
        </Container>

        <Container padding="3 0 1 0">
          <Text textStyle="body1">
            <b>Card number explained</b>
          </Text>
        </Container>

        <Container padding="3 1">
          <Text textStyle="body1" margin="0 0 2 0">
            The card number is a unique number that changes each time you get a new card. This ensures that:
          </Text>
          <StyledList>
            <List textStyle="body1" margin="3 1 3 12">
              <List.Item>the ID card is current</List.Item>
              <List.Item>the ID card is not lost or stolen</List.Item>
              <List.Item>risk of identity theft is minimised</List.Item>
            </List>
          </StyledList>
          <Text margin="2 0" textStyle="body1">
            For more information visit the{' '}
            <Link
              href="https://www.experian.com/"
              onClick={() =>
                trackEventSender({
                  location: 'about-you',
                  action: 'external-link',
                  label: 'Experian Knowledge Hub',
                })
              }
              newTab
              testId="experian-link"
            >
              Experian Knowledge Hub
            </Link>
            .
          </Text>
        </Container>

        <Flex placeItems="center">
          <Container padding="2">
            <Button width="160px" onClick={onClose}>
              Close
            </Button>
          </Container>
        </Flex>
      </Container>
    </Modal>
  );
};

export { CardNumberModal };
