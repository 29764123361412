import { Container } from 'basis';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import styled from '@emotion/styled';
import React, { useCallback } from 'react';
import { WidthContainer } from 'components/twenty-eight-degrees/Containers';
import { useEventTracking } from 'react-event-tracker';
import { getDataLayerElements, canGoBackToMerchant } from 'utils';
import { useStoreValue } from 'store';

export function LandingSubHeader({ upstreamUrl, merchantGroupName }) {
  const { trackEvent } = useEventTracking();
  const [storeState] = useStoreValue();

  const onClick = useCallback(() => {
    trackEvent({
      event: {
        label: 'Back to merchant',
        location: window.location.pathname,
        category: 'button-link',
        action: 'external-link',
      },
      ...getDataLayerElements(storeState),
    });
    window.location.href = upstreamUrl;
  }, [storeState, trackEvent, upstreamUrl]);

  if (!canGoBackToMerchant(storeState)) {
    return null;
  }

  return (
    <Container padding="3 0">
      <WidthContainer>
        <TextButton onClick={onClick} data-testid="back-to-merchant-button">
          <ChevronLeftIcon boxSize={24} paddingBottom={4} />
          <span>Back to {merchantGroupName}</span>
        </TextButton>
      </WidthContainer>
    </Container>
  );
}

const TextButton = styled.b`
  margin-left: -4px;
  color: ${props => props?.theme?.a?.color || '#0061EE'};
  cursor: pointer;
`;
