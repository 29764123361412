import { useCallback } from 'react';
import { useStoreValue } from 'store';

export const useEnv = () => {
  const [storeValue] = useStoreValue();

  const getEnv = useCallback(
    envName => {
      if (!envName) {
        if (process.env.REACT_APP_ENV === 'prod') {
          return process.env;
        }
        return { ...process.env, ...storeValue.overriddenEnv };
      }

      if (process.env.REACT_APP_ENV === 'prod') {
        return process.env[envName];
      }

      return storeValue.overriddenEnv?.[envName] || process.env[envName];
    },
    [storeValue.overriddenEnv],
  );

  return getEnv;
};

export const useOverrideDevEnv = () => {
  const [storeValue, updateStore] = useStoreValue();

  const overrideEnv = useCallback(
    (envName, envValue) => {
      updateStore({
        overriddenEnv: {
          ...storeValue.overriddenEnv,
          [envName]: envValue,
        },
      });
    },
    [storeValue.overriddenEnv, updateStore],
  );

  return overrideEnv;
};
