const size = {
  xs: '380px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
};

export const device = {
  xsMobile: `(min-width: ${size.xs})`,
  mobile: `(min-width: ${size.sm})`,
  tablet: `(min-width: ${size.md})`,
  laptop: `(min-width: ${size.lg})`,
  desktop: `(min-width: ${size.xl})`,
  mobileOrSmaller: `(max-width: ${size.sm})`,
};
