import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { isFeatureOn } from 'featureToggles';
import { useHandleApiErrors } from './useHandleApiErrors';

export const SUBMIT_SAVED_APPLICATION_GQL = gql`
  mutation submitSavedApplication($input: SubmitSavedApplicationInput!) {
    submitSavedApplication(input: $input) {
      status
    }
  }
`;

const useSubmitSavedApplication = ({ storeState, onSuccess, onError }) => {
  const { handleError } = useHandleApiErrors('apollo', onError);

  const mutation = SUBMIT_SAVED_APPLICATION_GQL;

  const [submitSavedApplication, { loading }] = useMutation(mutation, {
    onCompleted: data => {
      onSuccess(data.submitSavedApplication);
    },
    onError: err => {
      handleError(err);
    },
  });

  const submit = useCallback(
    ({ incomeVerificationType, income, otherIncome, expectedIncomeChange }) => {
      const variables = isFeatureOn('incomeReplay')
        ? {
            input: {
              applicationId: storeState.applicationId,
              poiType: incomeVerificationType || storeState.incomeVerificationType,
              income: toIncome(income),
              otherIncome: otherIncome?.amount === '' ? null : toIncome(otherIncome),
              expectedIncomeChange: toBoolean(expectedIncomeChange),
            },
          }
        : {
            input: {
              applicationId: storeState.applicationId,
              poiType: incomeVerificationType || storeState.incomeVerificationType,
            },
          };

      return submitSavedApplication({ variables });
    },
    [storeState.applicationId, storeState.incomeVerificationType, submitSavedApplication],
  );

  return { submit, loading };
};

export { useSubmitSavedApplication };

const toUpperCase = value => {
  return typeof value === 'string' ? value.toUpperCase() : '';
};

const toInt = value => {
  return typeof value === 'string' ? parseInt(value, 10) : 0;
};

const toIncome = input => {
  return {
    amount: toInt(input.amount),
    frequency: toUpperCase(input.frequency),
  };
};

const toBoolean = value => {
  return value === 'yes';
};
