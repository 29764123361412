import {
  validVerbalPasswordCharsPattern,
  validVerbalPasswordLettersPattern,
  validVerbalPasswordNumbersPattern,
  validVerbalPasswordMinLength,
  validVerbalPasswordMaxLength,
} from '../_constants';
import { Password } from '../../views/verbal-password/Password';

const validationMessages = {
  required: 'Please enter a password that matches the criteria below.',
  minLength: 'Please ensure your password matches the criteria below.',
  maxLength: 'Please ensure your password matches the criteria below.',
  invalid: 'Please ensure your password matches the criteria below.',
};

const fields = [
  {
    component: 'InfoBlock',
    name: 'passwordInfo',
    text: 'Your password is used to identify you when calling us for any support with your account.',
  },
  {
    component: 'CustomComponent',
    children: Password,
    name: 'password',
    label: 'Password',
    testId: 'password',
    validate: {
      props: {
        minLength: validVerbalPasswordMinLength,
        maxLength: validVerbalPasswordMaxLength,
        validPattern: [
          validVerbalPasswordCharsPattern,
          validVerbalPasswordLettersPattern,
          validVerbalPasswordNumbersPattern,
        ],
      },
      messages: validationMessages,
    },
  },
];

export const password = fields;
