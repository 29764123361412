import styled from '@emotion/styled';
import { device } from 'media';
import groupLFrameCardImageNZ from 'images/hero-NZ.png';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.mobile} {
    & > div {
      width: 100%;
    }
  }

  @media ${device.laptop} {
    & > div {
      max-width: 1280px;
    }
  }
`;

export const TopContainer = styled.div`
  @media ${device.tablet} {
    background-image: url(${groupLFrameCardImageNZ});
    background-position-x: right;
    background-position-y: top;
    background-repeat: no-repeat;
    background-size: 50%;
    background-color: #eff7ff;
  }
`;

export const BannerTitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 12px;
`;

export const DownloadImage = styled.img`
  height: 32px;
  @media ${device.mobile} {
    height: 40px;
  }
`;

export const ImageIcons = styled.img`
  height: 72px;
  margin-right: 16px;
`;

export const LayoutImageIcons = styled.img`
  height: 60px;
  @media ${device.tablet} {
    height: 92px;
  }
`;

export const PhoneIcon = styled.img`
  width: 40px;
  height: 40px;

  ${props => `
    ${props.theme.minMediaQueries.sm} {
      width: 50px;
      height: 50px;
    }
  `}
`;

export const List = styled.ul`
  margin-bottom: 0;
  margin-top: ${p => (p.marginTop ? `${p.marginTop}px` : '')};
  font-family: 'Roboto', sans-serif;
  font-size: ${p => `${p.fontSize}px`};
  font-weight: 300;
  line-height: 1.5;
  ${p => p.noPadding && 'padding-left: 1rem'};
`;

export const CustomWrapper = styled.div`
  & > h3 > button > div {
    color: ${p => p.theme.a.color};
    & > svg > path {
      fill: ${p => p.theme.a.color};
    }
  }
`;
