import React from 'react';
import { Accordion, Container, Text, useBreakpoint } from 'basis';
import { useTheme as emotionUseTheme } from '@emotion/react';
import ClockIcon from 'images/hero-clock.png';
import { EligibilityContent } from './EligibilityContent';
import { WhatYouMayNeed } from './WhatYouMayNeed';
import { IconTitleWrapper } from './Overview.styles';

export function EligibilityAndInformationTextArea() {
  const breakpoint = useBreakpoint();
  return ['md', 'lg', 'xl'].includes(breakpoint) ? (
    <EligibilityAndInformationTextAreaForLargerDevices />
  ) : (
    <EligibilityAndInformationTextAreaForSmallerDevices />
  );
}

function EligibilityAndInformationTextAreaForSmallerDevices() {
  return (
    <Accordion color="secondary.lightBlue.t25">
      <Container hasBreakpointWidth>
        <IconTitleWrapper>
          <img src={ClockIcon} width="32px" height="32px" alt="form-title-icon" />
          <Text as="h2" textStyle="heading4">
            <strong>This application will take around 10 minutes to complete</strong>
          </Text>
        </IconTitleWrapper>
      </Container>
      <Accordion.Item initiallyOpen>
        <Accordion.Item.Header>Check your eligibility</Accordion.Item.Header>
        <Accordion.Item.Content padding="5">
          <EligibilityContent />
        </Accordion.Item.Content>
      </Accordion.Item>
      <Accordion.Item initiallyOpen>
        <Accordion.Item.Header>Things you will need</Accordion.Item.Header>
        <Accordion.Item.Content>
          <WhatYouMayNeed />
        </Accordion.Item.Content>
      </Accordion.Item>
    </Accordion>
  );
}

function EligibilityAndInformationTextAreaForLargerDevices() {
  const theme = emotionUseTheme();
  const bgColor = theme.highlightColor;
  return (
    <Container bg={bgColor} margin="-14 0 10 10" margin-md="-8 0 10 10" margin-lg="-14 0 10 10" padding="5">
      <Container bg="white" padding="6 14 6 6" padding-md="6" padding-lg="6 14 6 6">
        <IconTitleWrapper theme={theme}>
          <img src={ClockIcon} width="32px" height="32px" alt="form-title-icon" />
          <Text as="h2" textStyle="heading4">
            <strong>This application will take around 10 minutes to complete</strong>
          </Text>
        </IconTitleWrapper>
        <Text as="h2" textStyle="heading4" margin="0 0 10 0">
          <strong>Check your eligibility</strong>
        </Text>

        <EligibilityContent />

        <Text as="h2" textStyle="heading4" margin="10 0">
          <strong>Things you will need</strong>
        </Text>

        <WhatYouMayNeed />
      </Container>
    </Container>
  );
}
