import React, { useEffect } from 'react';
import { usePrevious } from 'hooks';
import { FormRenderer } from 'components';

function ContactDetailsFields({ isResuming, formData, config }) {
  const { state, validateField } = formData;
  const { emailAddress, confirmEmail } = state.values;
  const prevEmailAddress = usePrevious(emailAddress);

  useEffect(() => {
    if (!isResuming && emailAddress !== prevEmailAddress && confirmEmail.trim() !== '') {
      validateField('confirmEmail');
    }
  }, [emailAddress, prevEmailAddress, confirmEmail, validateField, isResuming]);

  return <FormRenderer config={config} formData={formData} />;
}

export { ContactDetailsFields };
