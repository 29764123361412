import React from 'react';

export function IconLogo({ width = 204, height = 32 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" focusable="false" role="img" viewBox={`0 0 ${width} ${height}`}>
      <path
        d="M29.2 8.80017C27.6538 8.80017 26.4001 10.0539 26.4001 11.6001C26.4001 13.1464 27.6538 14.4001 29.2 14.4001C30.7463 14.4001 32 13.1464 32 11.6001C32 10.0539 30.7463 8.80017 29.2 8.80017ZM29.2 5.5995C30.7463 5.5995 32 4.34623 32 2.79996C32 1.25369 30.7463 0 29.2 0C27.6538 0 26.4001 1.25369 26.4001 2.79996C26.4001 4.34623 27.6538 5.5995 29.2 5.5995ZM29.2 17.6007C27.6538 17.6007 26.4001 18.854 26.4001 20.4007C26.4001 21.947 27.6538 23.2002 29.2 23.2002C30.7463 23.2002 32 21.947 32 20.4007C32 18.854 30.7463 17.6007 29.2 17.6007ZM20.3999 5.5995C21.9461 5.5995 23.1994 4.34623 23.1994 2.79996C23.1994 1.25369 21.9461 0 20.3999 0C18.8536 0 17.6003 1.25369 17.6003 2.79996C17.6003 4.34623 18.8536 5.5995 20.3999 5.5995ZM11.5997 5.5995C13.146 5.5995 14.3997 4.34623 14.3997 2.79996C14.3997 1.25369 13.146 0 11.5997 0C10.0534 0 8.79934 1.25369 8.79934 2.79996C8.79934 4.34623 10.0534 5.5995 11.5997 5.5995ZM5.59992 26.4005H32V32H0V0H5.59992V26.4005Z"
        fill="#0046AA"
      />
    </svg>
  );
}
