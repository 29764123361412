import React from 'react';
import styled from '@emotion/styled';

export const ListWrapper = styled.ul`
  font-size: 16px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : `auto`)};
  margin-left: ${props => (props.marginLeft ? props.marginLeft : `auto`)};
  margin-left: ${props => (props.marginLeft ? props.marginLeft : `auto`)};
  margin-top: ${props => (props.marginTop ? props.marginTop : `0`)};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : `0`)};
`;
export const StyledUL = props => {
  const { children, paddingLeft, marginLeft, marginTop, marginBottom } = props;
  return (
    <ListWrapper paddingLeft={paddingLeft} marginLeft={marginLeft} marginTop={marginTop} marginBottom={marginBottom}>
      {children}
    </ListWrapper>
  );
};
