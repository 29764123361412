/* eslint-disable no-case-declarations */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useCallback } from 'react';
import { Container, LoadingIcon, Grid, Button } from 'basis';
import {
  useDataLayerAndAnalyticsLinks,
  useCreateBankConnectSession,
  useErrorTrackingAndUpdateStore,
  useUpdatePOIType,
} from 'hooks';
import { LayoutPage, LayoutContent, Errors, SaveAndCompleteLaterButton } from 'components';
import FiConnect, { FiConnectEventTypes } from 'fi-connect';
import { useHistory } from 'react-router-dom';
import { POI_CHECK_PENDING, POITYPE_BANK_CONNECT, useStoreValue } from 'store';
import { config } from '_config';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { isAU, byCountry } from 'utils';
import LeftArrowIcon from 'images/icon-left-arrow.svg';
import { isFeatureOn } from 'featureToggles';
import { VerifyBankStatementContent } from './verifyBankStatementContent';

export const VerifyBankStatements = () => {
  const [storeState, updateStore] = useStoreValue();
  const controlRef = useRef({});
  const history = useHistory();
  const { trackEventSender } = useDataLayerAndAnalyticsLinks(storeState);
  const { handleErrorCallback } = useErrorTrackingAndUpdateStore();
  const theme = useTheme();

  const {
    institutionsTextTitle,
    institutionsTextIntro,
    labelSelectInstitution,
    textTermsAndConditions,
    accountsTextInfo,
    optionNoIncome,
    commonLinkSecureMessage,
    commonTextSecureMessageContent,
    incomeButtonSubmit,
  } = VerifyBankStatementContent[config.countryCode];
  const handleGenerateSuccess = data => {
    updateStore({
      bankConnectSessionGUID: data.guid,
      bankConnectUserToken: data.userToken,
      assessmentId: data.assessmentId,
    });
  };

  const { createSession, loading } = useCreateBankConnectSession({
    storeState,
    onSuccess: handleGenerateSuccess,
    onError: handleErrorCallback,
  });

  const goToProcessingPage = () => {
    updateStore({
      applicationErrors: null,
      activeStep: '/processing-pending-poi',
    });

    history.push('/processing-pending-poi');
  };
  const { updatePOI } = useUpdatePOIType({
    storeState,
    onSuccess: goToProcessingPage,
    onError: handleErrorCallback,
  });

  useEffect(() => {
    createSession(storeState.applicationId);
  }, [storeState.applicationId]);

  const handleFiConnectEvent = evt => {
    switch (evt.type) {
      case FiConnectEventTypes.ANALYTICS:
        const {
          bankSelected,
          numberOfAccountsSelected,
          numberOfTransactionsSelected,
          totalIncomeNotShownSelected,
        } = evt.data;
        bankSelected !== storeState.bankSelected && updateStore({ bankSelected });
        numberOfAccountsSelected !== storeState.numberOfAccountsSelected && updateStore({ numberOfAccountsSelected });
        numberOfTransactionsSelected !== storeState.numberOfTransactionsSelected &&
          updateStore({ numberOfTransactionsSelected, bankConnectTotalIncomeNotShown: totalIncomeNotShownSelected });
        trackEventSender(evt.data.event, {
          ...storeState,
          bankSelected: bankSelected || storeState.bankSelected,
          numberOfAccountsSelected: numberOfAccountsSelected || storeState.numberOfAccountsSelected,
          numberOfTransactionsSelected: numberOfTransactionsSelected || storeState.numberOfTransactionsSelected,
        });
        break;
      case FiConnectEventTypes.COMPLETE:
        if (isAU()) {
          updateStore({
            activeStep: '/processing-pending-poi',
            applicationErrors: null,
            bankConnectCompleteStatus: evt.status,
            status: POI_CHECK_PENDING,
          });
          history.push('/processing-pending-poi');

          updatePOI(POITYPE_BANK_CONNECT);
        } else {
          updateStore({
            activeStep: '/processing-poi',
            applicationErrors: null,
            bankConnectCompleteStatus: evt.status,
          });

          history.push('/processing-poi');
        }

        break;
      case FiConnectEventTypes.RESET:
        updateStore({
          bankConnectSessionGUID: '',
          bankConnectUserToken: '',
          assessmentId: '',
        });
        createSession(storeState.applicationId);
        break;
      case FiConnectEventTypes.ERROR:
        handleErrorCallback(evt.message);
        break;
      default:
        break;
    }
  };

  const handleBackToPoiPage = () => {
    updateStore({
      poiStartDate: '',
    });

    history.push('/verify-income');
  };

  const fiConnectConfig = useMemo(
    () => ({
      countryCode: config.fiConnectRegion,
      guid: storeState.bankConnectSessionGUID,
      userToken: storeState.bankConnectUserToken,
      onEvent: handleFiConnectEvent,
      enableNoneAreMyIncome: byCountry({
        NZ: !isFeatureOn('incomeReplay'),
        AU: true,
      }),
      enableTotalIncomeNotShown: byCountry({
        NZ: !!isFeatureOn('incomeReplay'),
        AU: false,
      }),
      noBackButton: byCountry({
        NZ: false,
        AU: true,
      }),
      initialBackButton: byCountry({
        NZ: (
          <Button testId="InitialBackButton" type="button" variant="secondary" onClick={handleBackToPoiPage}>
            Back
          </Button>
        ),
        AU: null,
      }),
      saveAndCompleteLaterButton: byCountry({
        NZ: null,
        AU: <SaveAndCompleteLaterButton />,
      }),
      controlRef: byCountry({
        NZ: null,
        AU: controlRef,
      }),
      additionalErrorMessage: (
        <>
          {' '}
          If you’re having trouble logging in, you can{' '}
          <StyledAdditionalErrorMessage onClick={handleBackToPoiPage}>
            upload payslips, or other documents.
          </StyledAdditionalErrorMessage>
        </>
      ),
      errorFallbackButton: (
        <Container padding="6 0">
          <Grid rowsGap="4" colsGap="2">
            <Grid.Item colSpan="all" alignItems="center">
              <Grid>
                <Button testId="ErrorFallbackButton" type="button" variant="secondary" onClick={handleBackToPoiPage}>
                  Or, choose another option
                </Button>
              </Grid>
            </Grid.Item>
          </Grid>
        </Container>
      ),
      formButtonsLayout: byCountry({
        NZ: 'columns',
        AU: 'columns-sequent',
      }),
      content: {
        institutions: {
          textTitle: institutionsTextTitle,
          textIntro: institutionsTextIntro,
          labelSelectInstitution,
        },
        common: {
          textSecureMessageTitle: 'Sharing your bank statement',
          textSecureMessageSubtitle: 'Securely connecting with your bank online',
          textSecureMessageContent: commonTextSecureMessageContent,
          linkSecureMessage: commonLinkSecureMessage,
        },
        login: {
          buttonSubmit: byCountry({ NZ: 'Securely connect', AU: 'One time Bank Connect' }),
          textTermsAndConditions,
        },
        accounts: {
          textInfo: accountsTextInfo,
        },
        income: {
          optionNoIncome,
          buttonSubmit: incomeButtonSubmit,
          ...(isFeatureOn('incomeReplay')
            ? {
                validationPleaseSelectIncome: "Select this if your total income isn't shown above.",
                totalIncomeNotShown: 'Tick this box to update your total income in the next step',
              }
            : {}),
        },
      },
      theme: {
        radioColor: theme.radioColor,
        checkboxColor: theme.checkboxColor,
        headingColor: theme.headingColor,
        highlightColor: theme.highlightColor,
        style: theme.fiConnectStyle,
        headingSize: theme.headingSize,
      },
    }),
    [storeState.bankConnectSessionGUID, storeState.bankConnectUserToken, storeState.assessmentId, history],
  );

  if (storeState.applicationErrors) {
    const retry = () => {
      updateStore({
        applicationErrors: null,
      });
      createSession(storeState.applicationId);
    };

    return (
      <LayoutPage>
        <Errors applicationRef={storeState.applicationRef} retry={retry} />
      </LayoutPage>
    );
  }

  return (
    <LayoutPage hideBackButton preHeader={<BackNavigator controlRef={controlRef} />}>
      <Grid.Item colSpan="all">
        <Container padding="5 0" padding-md="5" hasBreakpointWidth>
          <LayoutContent>
            {storeState.bankConnectSessionGUID && storeState.bankConnectUserToken && !loading ? (
              <div data-hj-suppress>
                <FiConnect
                  config={fiConnectConfig}
                  environment={process.env.REACT_APP_ENV === 'prod' ? 'production' : 'development'}
                />
              </div>
            ) : (
              <Container padding-md="10" textAlign="center">
                <LoadingIcon color={theme.spinnerColor} />
              </Container>
            )}
          </LayoutContent>
        </Container>
      </Grid.Item>
    </LayoutPage>
  );
};

const StyledAdditionalErrorMessage = styled.div({
  cursor: 'pointer',
  color: props => props.theme.a.color,
});

const TextButton = styled.b`
  color: ${props => props.theme.a.color};
  cursor: pointer;
`;

function BackNavigator({ controlRef }) {
  const history = useHistory();

  const onGoBack = useCallback(() => {
    controlRef.current.goBack({
      onFirstPage: () => {
        history.goBack();
      },
    });
  }, []);

  if (!isAU()) {
    return null;
  }

  return (
    <Grid.Item colSpan="all">
      <Container padding="2 0" padding-md="2 0" hasBreakpointWidth>
        <LayoutContent>
          <TextButton onClick={onGoBack}>
            <img src={LeftArrowIcon} alt="Left Arrow Icon" style={{ marginRight: '4px' }} />
            <span>Back</span>
          </TextButton>
        </LayoutContent>
      </Container>
    </Grid.Item>
  );
}
