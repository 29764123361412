import React, { useCallback } from 'react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import styled from '@emotion/styled';
import { LayoutContent } from 'components/layout-content/LayoutContent';

const TextButton = styled.b`
  color: ${props => props?.theme?.a?.color || '#0061EE'};
  cursor: pointer;
`;

export function BackNavigator({ goBack }) {
  const onGoBack = useCallback(() => {
    goBack();
  }, [goBack]);

  return (
    <LayoutContent testId="back-navigator" padding="0 0 20px 0">
      <TextButton onClick={onGoBack}>
        <ChevronLeftIcon boxSize={25} paddingBottom={5} />
        <span>Back</span>
      </TextButton>
    </LayoutContent>
  );
}
