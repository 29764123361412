import React from 'react';
import { Text, Container, Grid, Message } from 'basis';
import { LayoutPage, LayoutContent, StyledUL } from 'components';
import { useStoreValue } from 'store';
import { useTheme } from '@emotion/react';

export const ResumeApplicationExpired = () => {
  const theme = useTheme();
  const [storeState] = useStoreValue();
  return (
    <LayoutPage>
      <Container margin="0 0 0 0" hasBreakpointWidth>
        <LayoutContent rowsGap="0">
          <Container padding="0" margin="0">
            <Grid preset="page" colsGap={6}>
              <Grid.Item colSpan="all">
                <Grid rowsGap={6}>
                  <Container padding="8 0 0 0">
                    <Text as="h1" textStyle="heading5" textStyle-md="heading4" color={theme.headingColor}>
                      We couldn’t find your application.
                    </Text>
                  </Container>
                  {storeState.applicationRef && (
                    <Text>
                      Application reference number: <b>{storeState.applicationRef}</b>
                    </Text>
                  )}
                  <Message severity="blocking" bg="secondary.pink.t30">
                    <Text>
                      <strong>Unfortunately, we weren't able to locate your application.</strong>
                    </Text>
                    <Text>This could be because:</Text>
                    <StyledUL paddingLeft="24px">
                      <li>your application is over 60 days old and has expired</li>
                      <li>your application has been withdrawn or cancelled</li>
                      <li>you used a different email address to submit your application</li>
                    </StyledUL>
                    <br />
                    <Text>You can try again via the link in your email or start a new application.</Text>
                  </Message>
                </Grid>
              </Grid.Item>
            </Grid>
          </Container>
        </LayoutContent>
      </Container>
    </LayoutPage>
  );
};
