/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
export function checkboxTheme(theme, { getColor, getTextStyle }) {
  return {
    getCSS: ({ targetElement, color, isChecked, __internal__keyboardFocus }) => {
      switch (targetElement) {
        case 'container': {
          return {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
          };
        }

        case 'input': {
          return {
            ...theme.focusStyles.focusVisibleAdjacentLabel,
          };
        }

        case 'label': {
          return {
            display: 'inline-flex',
            alignItems: 'flex-start',
            padding: `${theme.space[3]} ${theme.space[4]}`,
            minHeight: '24px',
            fontSize: theme.fontSizes[1],
            fontWeight: theme.fontWeights.light,
            lineHeight: theme.lineHeights[2],
            fontFamily: theme.fonts.body,
            color: theme.colors.black,
            backgroundColor: theme.color.white,
            borderRadius: theme.radii[0],
            ...(__internal__keyboardFocus && theme.focusStyles.__keyboardFocusAdjacentLabel),
          };
        }

        case 'svg': {
          return {
            flexShrink: 0,
            width: '20px',
            height: '20px',
            marginRight: theme.space[3],
            marginTop: '2px',
          };
        }

        case 'svgRect': {
          // background of the tick mark
          return {
            fill: isChecked ? theme.colors.grey.t75 : theme.colors.white,
            stroke: theme.colors.grey.t75,
            strokeWidth: '25',
          };
        }

        case 'svgPath': {
          // tick mark
          return {
            stroke: theme.colors.white,
          };
        }

        default: {
          return null;
        }
      }
    },
  };
}
