import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { LayoutPage, Errors } from 'components';
import {
  useEnv,
  useCountdownTimer,
  useErrorTrackingAndUpdateStore,
  useSubmitSavedApplication,
  useWaitAndRedirect,
} from 'hooks';
import { Container, Text, LoadingIcon, Flex, Stack } from 'basis';
import { useTheme } from '@emotion/react';
import { useStoreValue } from 'store';
import { byCountry } from 'utils';
import { isAU, isNZ } from '../../utils/byConfig';

export const ProcessingPOI = () => {
  const theme = useTheme();
  const [storeState, updateStore] = useStoreValue();
  const { applicationId, applicationRef, applicationErrors } = storeState;
  const { handleErrorCallback } = useErrorTrackingAndUpdateStore();
  const history = useHistory();

  const { retry, loading, stop } = useWaitAndRedirect({
    applicationId,
    testId: 'getPoiVerifiedApplication',
    isNzProcessingPOI: isNZ(),
  });

  const { submit: submitApplication } = useSubmitSavedApplication({
    storeState,
    onError: handleErrorCallback,
    onSuccess: data => {
      updateStore({
        applicationStatus: data.status,
        applicationErrors: null,
        activeStep: '/processing',
      });
      history.push('/processing');
    },
  });

  const { clear: stopCountDown } = usePOICountdown({
    shouldStop: !!applicationErrors,
    onTimeout: () => {
      stop();
      stopCountDown();
      if (!isAU()) {
        submitApplication({ incomeVerificationType: storeState.incomeVerificationType });
      }
    },
  });

  if (applicationErrors) {
    return (
      <LayoutPage>
        <Errors applicationRef={applicationRef} retry={retry} retrying={loading} />
      </LayoutPage>
    );
  }

  return (
    <LayoutPage>
      <Container testId="ProcessingPOI" height="400" padding="6" hasBreakpointWidth>
        <Flex height="100%" placeItems="center">
          <Stack gap="8">
            <Text textStyle="heading4" color={theme.headingColor} align="center">
              Please wait while we process your information.
            </Text>
            <Container textAlign="center">
              <LoadingIcon color={theme.spinnerColor} />
            </Container>
            <Text as="p" textStyle="subtitle2" align="center">
              This typically takes less than 2 minutes.
            </Text>
          </Stack>
        </Flex>
      </Container>
    </LayoutPage>
  );
};

const usePOICountdown = ({ shouldStop, onTimeout }) => {
  const getEnv = useEnv();

  const [, , clear] = useCountdownTimer(
    getEnv(
      byCountry({
        NZ: 'REACT_APP_APPLICATION_PROCESS_TIME_IN_SEC',
        AU: 'REACT_APP_AU_APPLICATION_PROCESS_TIME_IN_SEC',
      }),
    ),
    onTimeout,
  );

  useEffect(() => {
    shouldStop && clear && clear();
  }, [clear, shouldStop]);

  return { clear };
};
