import React from 'react';
import { Stepper as BasisStepper } from 'basis';
import { byCountry, isNZ, isDJ } from 'utils';
import styled from '@emotion/styled';

const StyledStepper = styled.div`
  div[data-testid='stepper'] > div > div > div > div:nth-child(2) strong {
    color: ${isNZ() || isDJ() ? 'inherit' : 'white'};
  }
`;

export function ProgressStepper({ progress }) {
  const Stepper = byCountry({ NZ: NZProgressStepper, AU: AUProgressStepper });
  return (
    <StyledStepper>
      <Stepper progress={progress} />
    </StyledStepper>
  );
}

function NZProgressStepper({ progress }) {
  return (
    <BasisStepper>
      <BasisStepper.Item label="You" label-md="About you" current={progress === 'You'} />
      <BasisStepper.Item minor current={progress === 'Address'} />
      <BasisStepper.Item minor current={progress === 'Extra'} />
      <BasisStepper.Item label="Work" label-sm="Income" current={progress === 'Work'} />
      <BasisStepper.Item minor current={progress === 'Expenses'} />
      <BasisStepper.Item minor current={progress === 'Debts'} />
      <BasisStepper.Item label="Outcome" label-sm="Outcome" current={progress === 'Outcome'} />
    </BasisStepper>
  );
}

function AUProgressStepper({ progress }) {
  return (
    <BasisStepper testId="stepper">
      <BasisStepper.Item label-md="About you" label="About" current={progress === 'You'} />
      <BasisStepper.Item label="Finances" current={progress === 'Work'} />
      <BasisStepper.Item label-md="Additional checks" label="Checks" current={progress === 'Debts'} />
      <BasisStepper.Item label="Outcome" current={progress === 'Outcome'} />
    </BasisStepper>
  );
}

ProgressStepper.ID = 'FixedStepperAndPageTitle';
ProgressStepper.HEIGHT_MAP = {
  default: BasisStepper.HEIGHT_MAP.default,
};
