import React from 'react';
import { formatNumber } from 'utils';
import { Text, Container } from 'basis';
import styled from '@emotion/styled';
import { FIELD_ADDITIONAL_LOANS, FIELD_DISCLOSED_DEBTS } from '../constants';

const TotalRepaymentBox = styled.div`
  div {
    background-color: ${props => props.theme.colors.specialBackground};
  }
`;

export const TotalRepayment = ({ watch }) => {
  const [discoveredDebts, additionalLoans] = watch([FIELD_DISCLOSED_DEBTS, FIELD_ADDITIONAL_LOANS]);
  const monthlyRepayments = [...(discoveredDebts || []), ...(additionalLoans || [])].map(debt =>
    debt?.monthlyRepayment ? Number(debt.monthlyRepayment) : 0,
  );
  const total = monthlyRepayments.reduce((a, b) => a + b, 0);
  return (
    <TotalRepaymentBox>
      <Container bg="grey.t05" padding="4 2" padding-md="8" margin="4 0">
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Text textStyle="heading5">Your total monthly repayment</Text>
          <Text textStyle="heading3" testId="totalRepayment">
            ${formatNumber(total)}
          </Text>
        </div>
      </Container>
    </TotalRepaymentBox>
  );
};
