import React from 'react';
import { applicationReasonOptions, EXPENSES } from '../_constants';

export const generateOverviewFormConfig = ({ TermAndConditions, StopperMessage, trackApplicationReasonChange }) => [
  {
    component: 'RadioGroup',
    name: 'applicationReason',
    label: 'Tell us how you will use your Gem Visa',
    testId: 'application-reason',
    options: applicationReasonOptions,
    showCircles: true,
    columns: 1,
    onChange: trackApplicationReasonChange,
    validate: value => (value === '' ? 'Please make a selection' : null),
  },
  {
    condition: values => values.applicationReason === EXPENSES,
    component: 'Checkbox',
    name: 'consentGiven',
    label: '',
    testId: 'consent-given',
    children: <TermAndConditions />,
    validate: value =>
      value === false ? 'To proceed, check the box to confirm that you agree to the terms above' : null,
  },
  {
    condition: values => ![EXPENSES, ''].includes(values.applicationReason),
    component: 'ConditionalStop',
    name: 'conditionalStop',
    severity: 'blocking',
    testId: 'not-for-you',
    subtitle: <StopperMessage />,
    validate: () => '@@STOP@@',
  },
];

export const isValidOverview = formData => {
  const isValid =
    Object.keys(formData.state.errors).length === 0 &&
    formData.state.values.applicationReason === EXPENSES &&
    formData.state.values.consentGiven === true;
  return !!isValid;
};

export const isSubmitHiddenOnOverview = formData => {
  return !['', EXPENSES].includes(formData.state.values.applicationReason);
};
