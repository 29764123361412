/* eslint-disable camelcase */
import React from 'react';
import { Accordion, Container, Text, useBreakpoint, Flex, Link } from 'basis';
import { useTheme as emotionUseTheme } from '@emotion/react';
import DownloadApple from 'images/download-apple.svg';
import DownloadGoogle from 'images/download-google.svg';
import { OutcomeApprovedSources } from '_config/_constants/externalLinks';
import { DownloadImage } from '../Outcome.styles';

const { shoppingInformation } = OutcomeApprovedSources;

const ShoppingInformationForSmallerDevices = ({ trackEventSender, getLinkWithAnalytics }) => {
  const { use_account_number, google_store, apple_store } = shoppingInformation;
  return (
    <Accordion color="secondary.lightBlue.t25">
      <Accordion.Item initiallyOpen>
        <Accordion.Item.Header>Start shopping straight away</Accordion.Item.Header>
        <Accordion.Item.Content padding="2 5 5 5">
          <Text>
            From now, you can use your Gem Visa account number to make purchases, online and in-store at any one of our
            retailers in our network to make a long term interest free purchase. You will not need to wait for your
            card.{' '}
            <Link
              href={getLinkWithAnalytics({
                location: use_account_number.location,
                label: use_account_number.label,
                url: use_account_number.url,
              })}
              newTab
              onClick={() =>
                trackEventSender({ location: use_account_number.location, label: use_account_number.label })
              }
            >
              {use_account_number.label}.
            </Link>
          </Text>
        </Accordion.Item.Content>
      </Accordion.Item>
      <Accordion.Item initiallyOpen>
        <Accordion.Item.Header>Add Gem Visa into your mobile wallet today!</Accordion.Item.Header>
        <Accordion.Item.Content>
          <Text>
            Download the Latitude App to add your Gem Visa to your mobile wallet. You can transact anywhere Visa is
            accepted, worldwide and using payWave.
          </Text>

          <Flex margin="4 0" width="100%" placeItems="center">
            <Container margin="0 8 0 0">
              <Link appearance="icon" href={shoppingInformation.apple_store.url} newTab>
                <DownloadImage
                  src={DownloadApple}
                  onClick={() => trackEventSender({ location: apple_store.location, label: apple_store.label })}
                />
              </Link>
            </Container>
            <Container>
              <Link appearance="icon" href={shoppingInformation.google_store.url} newTab>
                <DownloadImage
                  src={DownloadGoogle}
                  onClick={() => trackEventSender({ location: google_store.location, label: google_store.label })}
                />
              </Link>
            </Container>
          </Flex>
        </Accordion.Item.Content>
      </Accordion.Item>
    </Accordion>
  );
};

const ShoppingInformationForLargerDevices = ({ trackEventSender, getLinkWithAnalytics }) => {
  const theme = emotionUseTheme();
  const bgColor = theme.highlightColor;
  const { use_account_number, google_store, apple_store } = shoppingInformation;
  return (
    <Container bg={bgColor} margin="0 10 10 10" padding="5">
      <Container bg="white" padding="5">
        <Text as="h2" textStyle="heading4" margin="0 0 10 0">
          <strong>Start shopping straight away</strong>
        </Text>

        <Container>
          <Text>
            From now, you can use your Gem Visa account number to make purchases, online and in-store at any one of our
            retailers in our network to make a long term interest free purchase. You will not need to wait for your
            card.{' '}
            <Link
              href={getLinkWithAnalytics({
                location: use_account_number.location,
                label: use_account_number.label,
                url: use_account_number.url,
              })}
              newTab
              onClick={() =>
                trackEventSender({ location: use_account_number.location, label: use_account_number.label })
              }
            >
              {use_account_number.label}.
            </Link>
          </Text>
        </Container>

        <Text as="h2" textStyle="heading4" margin="10 0 10 0">
          <strong>Add Gem Visa into your mobile wallet today!</strong>
        </Text>

        <Container>
          <Text>
            Download the Latitude App to add your Gem Visa to your mobile wallet. You can transact anywhere Visa is
            accepted, worldwide and using payWave.
          </Text>
        </Container>

        <Container padding="6 0 0">
          <Flex width="100%" placeItems="center left">
            <Container margin="0 8 0 0">
              <Link appearance="icon" href={shoppingInformation.apple_store.url} newTab>
                <DownloadImage
                  src={DownloadApple}
                  onClick={() => trackEventSender({ location: apple_store.location, label: apple_store.label })}
                />
              </Link>
            </Container>
            <Container>
              <Link appearance="icon" href={shoppingInformation.google_store.url} newTab>
                <DownloadImage
                  src={DownloadGoogle}
                  onClick={() => trackEventSender({ location: google_store.location, label: google_store.label })}
                />
              </Link>
            </Container>
          </Flex>
        </Container>
      </Container>
    </Container>
  );
};

const ShoppingInformation = ({ trackEventSender, getLinkWithAnalytics }) => {
  const breakpoint = useBreakpoint();
  return ['md', 'lg', 'xl'].includes(breakpoint) ? (
    <ShoppingInformationForLargerDevices
      trackEventSender={trackEventSender}
      getLinkWithAnalytics={getLinkWithAnalytics}
    />
  ) : (
    <ShoppingInformationForSmallerDevices
      trackEventSender={trackEventSender}
      getLinkWithAnalytics={getLinkWithAnalytics}
    />
  );
};

export { ShoppingInformation };
