import React from 'react';
import { Container, Grid, Flex } from 'basis';
import styled from '@emotion/styled';
import { DavidJonesLogo } from './Logo';
import { LatitudeIcon } from '../icons';

const CenterTitle = styled.span`
  text-align: center;
  color: white;
  user-select: none;
  font-size: 18px;
  ${props => `
    ${`@media (min-width: ${props.theme.screenSize.md})`} {
      font-size: 20px;
  `};
`;

export const HeaderWrapper = styled.div`
  ${props => `
    ${`@media (min-width: ${props.theme.screenSize.lg})`} {
      & > div {
        display: flex;
      }
    }
  `}
`;

const HEIGHT_MAP = {
  default: 54,
  lg: 72,
};

export function DavidJonesHeader() {
  return (
    <header data-testid="david-jones-header">
      <HeaderWrapper>
        <Container bg="black" height={HEIGHT_MAP.default} height-md={HEIGHT_MAP.lg} padding="5" padding-md="6 5">
          <Container margin="auto" margin-md="auto">
            <Grid colsGutter="0" cols="6">
              <Grid.Item colSpan="0-1" colSpan-md="0" colSpan-lg="0" rowSpan="0" rowSpan-md="0" margin-md="0">
                <Flex placeItems="center left">
                  <DavidJonesLogo />
                </Flex>
              </Grid.Item>
              <Grid.Item colSpan="3" colSpan-md="3" rowSpan-md="0" margin-md="0">
                <Flex placeItems="center left">
                  <CenterTitle>Apply</CenterTitle>
                </Flex>
              </Grid.Item>
              <Grid.Item colSpan="5-6" colSpan-md="5" colSpan-lg="5" rowSpan="0" rowSpan-md="0" margin-md="0">
                <Flex placeItems="center right">
                  <LatitudeIcon />
                </Flex>
              </Grid.Item>
            </Grid>
          </Container>
        </Container>
      </HeaderWrapper>
    </header>
  );
}

DavidJonesHeader.HEIGHT_MAP = HEIGHT_MAP;
