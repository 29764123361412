import React from 'react';
import {
  LayoutPage,
  LayoutContent,
  YellowMessage,
  ApprovedCreditLimit,
  AcceptedCreditLimit,
  BenefitInfo,
} from 'components';
import { Container, Button, Message, Form, Text, RadioGroup } from 'basis';
import { useStoreValue } from 'store';
import { useTheme } from '@emotion/react';
import { useFormInitialValues } from 'hooks';
import { formatNumber, cleanNumberFormat } from 'utils/formatNumber';
import { isDJ } from 'utils';

export const DclCa = ({ onSubmit, submitting }) => {
  const [storeState] = useStoreValue();
  const theme = useTheme();
  const { creditLimitApproved } = storeState;
  const initialValues = useFormInitialValues('confirmCreditLimit');
  const validCreditLimit = (formData, store) => {
    if (formData.state.values.creditLimitOptions === 'ownCreditLimit') {
      return storeState.creditLimitAccepted &&
        storeState.creditLimitAccepted === cleanNumberFormat(formData.state.values.newCreditLimit)
        ? `$${formatNumber(storeState.creditLimitAccepted)} `
        : '';
    }
    if (formData.state.values.creditLimitOptions === 'acceptCreditLimit') {
      return `$${formatNumber(storeState.creditLimitApproved)} `;
    }
    return '';
  };

  const MsgContainer = isDJ() ? WarningMessage : Container;

  return (
    <LayoutPage>
      <Container margin="0 0 13 0" hasBreakpointWidth>
        <Form
          id="confirmCreditLimit"
          initialValues={initialValues}
          onSubmit={onSubmit}
          showSaveDraftButton
          disableFormCache
          saveDraftOnSubmit
          saveDraftOnSessionTimeout
        >
          {formData => {
            return (
              <LayoutContent rowsGap="0" noSessionTimeout>
                <Container margin="8 0 0 0">
                  <Text
                    as="h1"
                    textStyle="heading5"
                    textStyle-md="heading4"
                    testId="creditLimitAUHeader"
                    color={theme?.headingColor}
                  >
                    Choose your credit card limit
                  </Text>
                  <Container margin="10 0 0 0">
                    <Text>You have been conditionally approved for a maximum credit card limit* of</Text>
                  </Container>
                  <ApprovedCreditLimit creditLimitApproved={creditLimitApproved} />
                  <MsgContainer margin="4 0">
                    <Text>
                      Please consider your needs when deciding a credit card limit. It is important that you are
                      comfortable with your credit limit, including when you accept the conditionally approved credit
                      limit.
                    </Text>
                  </MsgContainer>
                  <Container margin="8 0" />
                  <RadioGroup
                    name="creditLimitOptions"
                    label="Please make a choice that suits your needs"
                    testId="has-own-credit-limit"
                    showCircles="true"
                    columns="1"
                    color="white"
                    options={[
                      {
                        label: 'Choose my own credit limit',
                        value: 'ownCreditLimit',
                      },
                      {
                        label: 'Accept the conditionally approved credit limit',
                        value: 'acceptCreditLimit',
                      },
                    ]}
                  />
                  <Container margin="8 0" />
                  {formData.state.values.creditLimitOptions === 'ownCreditLimit' && (
                    <AcceptedCreditLimit formData={formData} maxCreditLimit={creditLimitApproved} />
                  )}
                  <Container margin="8 0" />
                  <BenefitInfo />
                </Container>
                <Container>
                  <Button margin="8 0 0 0" type="submit" loading={submitting}>
                    Accept {validCreditLimit(formData, storeState)} credit card limit
                  </Button>
                </Container>
              </LayoutContent>
            );
          }}
        </Form>
      </Container>
    </LayoutPage>
  );
};

const WarningMessage = ({ children, ...props }) => {
  return (
    <YellowMessage name="confirmCreditLimitMessage" testId="confirm-credit-limit-message" {...props}>
      <Message testId="yellow-message" severity="warning-or-significant">
        {children}
      </Message>
    </YellowMessage>
  );
};
