import React from 'react';
import { Container, Text } from 'basis';
import { BiometricsChangeAddressButton } from 'views/biometrics/BiometricsChangeAddressButton';

export const DisplayCurrentAddress = ({ storeState, formData }) => {
  const onclick = () => {
    formData.setValues(values => ({
      ...values,
      changeAddress: true,
    }));
  };

  if (!storeState.biometricsResult?.address) {
    return null;
  }

  const {
    unitNumber,
    streetNumber,
    streetName,
    streetType,
    suburb,
    city,
    postcode,
  } = storeState.biometricsResult.address;

  const street = [unitNumber, streetNumber, streetName, streetType].filter(Boolean).join(' ');
  const area = [suburb, city].filter(Boolean).join(' ');

  return (
    <Container testId="currentAddress">
      <div data-hj-suppress>
        <Text as="h4" textStyle="body" color="grey.t75">
          <strong>Current residential address</strong>
        </Text>
        <Text testId="currentAddress.street">{street}</Text>
        <Text testId="currentAddress.city">{area}</Text>
        <Text testId="currentAddress.postcode">{postcode}</Text>
        <Text testId="currentAddress.country">New Zealand</Text>
      </div>
      <BiometricsChangeAddressButton onClick={onclick}>Change address</BiometricsChangeAddressButton>
    </Container>
  );
};
