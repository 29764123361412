import { validateExpectedIncomeChangeConfig } from '_config/nz/employmentDetails';
import { yesNoOptions, numbersOnlyPattern } from '_config/_constants';
import { initFormConfig } from 'utils';

export const getInitialValues = storeState => ({
  income: storeState.acquisition.employmentDetails.income,
  hasOtherIncome: storeState.acquisition.employmentDetails.hasOtherIncome,
  otherIncome: storeState.acquisition.employmentDetails.otherIncome,
  expectedIncomeChange: storeState.acquisition.employmentDetails.expectedIncomeChange,
  conditionalStop: '',
});

export const validationMessages = {
  income: {
    required: 'Please enter your income after tax.',
    minLength: 'Must have at least {{minLength}} characters.',
    maxLength: 'Must have at most {{maxLength}} characters',
    invalid: 'Please enter a whole dollar amount, without cents.',
  },
};

export const formConfig = initFormConfig([
  {
    component: 'Frequency',
    name: 'income',
    label: 'Enter your total primary income after tax',
    mode: 'select',
    amountPrefix: '$',
    annually: false,
    testId: 'income',
    validate: {
      props: { minLength: 1, maxLength: 6, validPattern: numbersOnlyPattern },
      messages: validationMessages.income,
      component: 'Frequency',
    },
  },
  {
    condition: values => values.hasOtherIncome === 'yes',
    name: 'otherIncomeGroup',
    fields: [
      {
        component: 'Frequency',
        name: 'otherIncome',
        label: 'Enter your total secondary income after tax',
        mode: 'select',
        amountPrefix: '$',
        annually: false,
        testId: 'other-income',
        validate: {
          props: { minLength: 1, maxLength: 6, validPattern: numbersOnlyPattern },
          messages: validationMessages.income,
          component: 'Frequency',
        },
      },
    ],
  },
  {
    condition: values => values.hasOtherIncome,
    component: 'RadioGroup',
    name: 'expectedIncomeChange',
    label: 'Are you aware of any changes in the near future that may reduce your income?',
    testId: 'has-income-changes',
    options: yesNoOptions,
    showCircles: true,
    validate: {
      messages: {
        required: 'Please select yes or no.',
      },
    },
  },
  {
    condition: values => values.expectedIncomeChange === 'yes' && values.income.amount,
    component: 'ConditionalStop',
    name: 'conditionalStop',
    testId: 'income-adjustment-required',
    title: 'Please adjust your income',
    subtitle: 'Please adjust your income to the expected lower amount in the income fields above.',
    ...validateExpectedIncomeChangeConfig(),
  },
]);
