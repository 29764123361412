import { oktaAuthClient } from 'library/oktaAuthClient';
import { getSubFromToken } from './getSubFromToken';

const getOktaClientToken = () => {
  const accessToken = oktaAuthClient.getAccessToken();
  return accessToken && `Bearer ${accessToken}`;
};

export const getAuthHeader = () => {
  const testToken = process.env.REACT_APP_OKTA_TOKEN && `Bearer ${process.env.REACT_APP_OKTA_TOKEN}`;
  const clientToken = getOktaClientToken();
  return clientToken || testToken;
};

export const getLatitudeId = token => {
  const accessToken = token || oktaAuthClient.getAccessToken();
  return getSubFromToken(accessToken);
};
