import React from 'react';
import { Container, Grid, Link, Stack, Text } from 'basis';
import { LayoutPage } from 'components';
import AdrianaMartinezSignature from 'images/adriana_martinez.png';
import { config } from '../../../../_config';
import {
  StyledDivSpaceBetween,
  StyledULWideLeftPadding as StyledUL,
  StyledLeftDivWidth75,
  StyledDivAlignRight,
} from './Styles';

export function AfsGemFinancialTable({
  ratesAndFees,
  statementDate,
  downloadOrPrintFile,
  productName,
  accountNumber,
  creditLimit,
  productCode,
}) {
  const {
    establishmentFee,
    purchaseAnnualRate,
    cashAnnualRate,
    monthlyFee,
    cashAdvanceFeePercentagePerTransaction,
    cashAdvanceFeeFixed,
    lateFee,
    paperStatementFee,
    ausPostHandlingFee,
    onlinePaymentHandlingFee,
    counterPaymentWithDrawalFee,
    replacementCardFee,
  } = ratesAndFees;

  return (
    <LayoutPage hideBackButton>
      <Container margin="0 0 0 0">
        <Container padding="5 0" padding-md="5" testId="LAYOUT FORM">
          <Grid preset="page" debug={false}>
            <Grid.Item colSpan="all">
              <Grid rowsGap={0}>
                <Container padding="4 4" margin="0 8">
                  <div style={{ marginBottom: '40px' }}>
                    <Link variant="secondary" id="pdfButton" onClick={downloadOrPrintFile}>
                      Download or Print file
                    </Link>
                  </div>
                  <Grid preset="page" colsGap={11}>
                    <Grid.Item colSpan="all">
                      <Grid rowsGap={1}>
                        <Container padding="0">
                          <div id="financial-table">
                            <Stack gap="1">
                              <StyledDivSpaceBetween>
                                <Text as="h1" textStyle="heading5" textStyle-md="heading4">
                                  FINANCIAL TABLE
                                </Text>
                                <StyledDivAlignRight>
                                  <Text textStyle="heading5" textStyle-md="heading4">
                                    CreditLine
                                  </Text>
                                </StyledDivAlignRight>
                              </StyledDivSpaceBetween>
                              <hr />
                              <Text>
                                The details below are correct as at the disclosure date. The{' '}
                                <strong>financial table</strong> does not contain all of the pre-contractual information{' '}
                                <strong>we</strong> are required by law to give <strong>you</strong>. Further terms and{' '}
                                conditions (and an explanation of some of the words used in the{' '}
                                <strong>financial table</strong>) are in the Conditions of Use in the enclosed booklet{' '}
                                entitled {productName} Conditions of Use, which includes important information about{' '}
                                <strong>your credit contract</strong> and privacy.
                              </Text>
                              <br />
                              <StyledDivSpaceBetween>
                                <Container>
                                  <Text textStyle="heading6">Account Number</Text>
                                </Container>
                                <Container>
                                  <Text textStyle="heading6">Disclosure Date</Text>
                                </Container>
                                <Text textStyle="heading6">Credit Limit</Text>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <Container>
                                  <data-hj-suppress>
                                    <Text>{accountNumber}</Text>
                                  </data-hj-suppress>
                                </Container>
                                <Container margin={0} margin-md="0 0 0 -11">
                                  <Text>{statementDate}</Text>
                                </Container>
                                <data-hj-suppress>
                                  <Text>${creditLimit}</Text>
                                </data-hj-suppress>
                              </StyledDivSpaceBetween>
                              <br />
                              <Text>
                                <strong>Cash Limit</strong> <i>(refer to your statement)</i>
                              </Text>
                              <hr />
                              <Text>
                                A cash limit forms part of your credit limit which may be withdrawn in cash or cash{' '}
                                equivalent transactions. A cash limit may be imposed, withdrawn or changed on your{' '}
                                account from time to time, which is notified to you on each statement we give you and,{' '}
                                is effective from the date of each statement.{' '}
                              </Text>
                              <br />
                              <Text textStyle="heading5">Annual Percentage Rates</Text>
                              <hr />
                              <StyledDivSpaceBetween>
                                <Text textStyle="heading6">Purchase rate</Text>
                                <Text>{purchaseAnnualRate?.toFixed(2)}%</Text>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <Text textStyle="heading6">Cash advance rate</Text>
                                <Text>{cashAnnualRate?.toFixed(2)}%</Text>
                              </StyledDivSpaceBetween>
                              <hr />
                              <Text>
                                The <strong>cash advance</strong> rate applies to all <strong>cash advances</strong>{' '}
                                made using your <strong>account</strong>.
                              </Text>
                              <br />

                              <Text textStyle="heading5">Maximum Interest Free Period</Text>
                              <hr />
                              <Text>
                                Where we make a <strong>promotional offer</strong>, the maximum interest free period is{' '}
                                60 months. However, the interest free period (if any) for any particular{' '}
                                <strong>advance</strong> under a <strong>promotional offer</strong> will be specified in{' '}
                                accordance with 18.2.
                              </Text>
                              <br />
                              <Text textStyle="heading5">Minimum Repayments</Text>
                              <hr />
                              <Text>
                                The minimum amount payable for a <strong>statement</strong> period will consist of:
                              </Text>
                              <StyledUL>
                                <li>
                                  the <strong>minimum monthly payment</strong> – this amount is payable by the due date{' '}
                                  shown on the relevant <strong>statement</strong> of account; and
                                </li>
                                <li>
                                  any overdue amount (which is any amount not paid in full to us by its due date), and{' '}
                                  any over limit amount (which is, at any time, the excess (if any) of the amount owed{' '}
                                  on <strong>the account</strong> at the end of the <strong>statement</strong> period at{' '}
                                  the time over the credit limit) and any enforcement expenses – these amounts are{' '}
                                  payable immediately, but will not exceed the <strong>balance</strong> on the{' '}
                                  <strong>account</strong>
                                </li>
                              </StyledUL>
                              <br />

                              <Text textStyle="heading5">Fees and Charges</Text>
                              <hr />
                              <StyledDivSpaceBetween>
                                <StyledLeftDivWidth75>
                                  <Text>
                                    We will charge an establishment fee when the first <strong>advance</strong> is{' '}
                                    debited to the <strong>account</strong>. This fee is the total amount of the{' '}
                                    <strong>credit fees and charges</strong> payable under this{' '}
                                    <strong>contract</strong> to the extent ascertainable at the disclosure date. Except{' '}
                                    where the first <strong>advance</strong> is under a{' '}
                                    <strong>buy now pay later promotion</strong>, this fee is payable as part of the{' '}
                                    first <strong>minimum monthly payment</strong>. When the first{' '}
                                    <strong>advance</strong> is under a <strong>buy now pay later promotion</strong>,
                                    this fee, once debited, still forms part of the <strong>balance</strong>, but is not
                                    payable until the expiry of the <strong>promotional offer term</strong>, from which
                                    time it is payable as part of the <strong>minimum monthly payment</strong>.
                                  </Text>
                                </StyledLeftDivWidth75>
                                <div style={{ textAlign: 'right' }}>
                                  <Text>
                                    <strong>${establishmentFee?.toFixed(2)}</strong>
                                  </Text>
                                </div>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <StyledLeftDivWidth75>
                                  <Text>
                                    An account service fee which will be debited to the account monthly. Account service{' '}
                                    fees, once debited, form part of the balance, and are payable as part of the minimum{' '}
                                    monthly payment for each statement period where:
                                  </Text>
                                  <StyledUL>
                                    <li>
                                      the balance of any <strong>advance</strong> under a{' '}
                                      <strong>buy now pay later promotion</strong> at the end of the{' '}
                                      <strong>statement</strong> period is zero; or
                                    </li>
                                    <li>
                                      the <strong>balance</strong> includes amounts other than the advance under a{' '}
                                      <strong>buy now pay later promotion</strong> and any outstanding fees.
                                    </li>
                                  </StyledUL>
                                </StyledLeftDivWidth75>
                                <div style={{ textAlign: 'right' }}>
                                  <Text>
                                    <strong>${monthlyFee?.toFixed(2)}</strong>
                                  </Text>
                                </div>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <StyledLeftDivWidth75>
                                  <Text>
                                    A cash advance fee will be charged to your account on each occasion on which you{' '}
                                    request a <strong>cash advance</strong>.
                                  </Text>
                                </StyledLeftDivWidth75>
                                <div style={{ textAlign: 'right' }}>
                                  <Text>{cashAdvanceFeePercentagePerTransaction}% of the</Text>
                                  <Text>
                                    <strong>cash advance</strong> or
                                  </Text>
                                  <Text>${cashAdvanceFeeFixed}, whichever is </Text>
                                  <Text>greater</Text>
                                </div>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <Text>
                                  An over the counter payment fee will be debited to the <strong>account</strong> on{' '}
                                  each occasion on which you make a payment on the <strong>account</strong> over the{' '}
                                  counter at any branch of Westpac Banking Corporation.
                                </Text>
                                <StyledDivAlignRight>
                                  <Text>
                                    <strong>${counterPaymentWithDrawalFee?.toFixed(2)}</strong>
                                  </Text>
                                </StyledDivAlignRight>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <Text>
                                  An overdue fee will be charged to the <strong>account</strong> in each{' '}
                                  <strong>statement period</strong> when any amount due and payable to{' '}
                                  <strong>us</strong> is not paid in full by the <strong>due date</strong>.
                                </Text>
                                <StyledDivAlignRight>
                                  <Text>
                                    <strong>${lateFee?.toFixed(2)}</strong>
                                  </Text>
                                </StyledDivAlignRight>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <StyledLeftDivWidth75>
                                  <Text>
                                    A paper statement fee will be charged for each paper statement of account sent to{' '}
                                    you. This fee will not be charged if you consent to receiving your statements{' '}
                                    electronically.
                                  </Text>
                                </StyledLeftDivWidth75>
                                <StyledDivAlignRight>
                                  <Text>
                                    <strong>${paperStatementFee?.toFixed(2)}</strong>
                                  </Text>
                                </StyledDivAlignRight>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <StyledLeftDivWidth75>
                                  <Text>
                                    A payment handling fee will be charged to <strong>your account</strong> each time{' '}
                                    <strong>you</strong> make a payment to <strong>your account</strong>, except that no{' '}
                                    fee is payable for payments made by mail, by direct debit or via the{' '}
                                    <strong>Latitude Service Centre</strong>.
                                  </Text>
                                </StyledLeftDivWidth75>
                                <StyledDivAlignRight>
                                  <Text>
                                    <strong>${ausPostHandlingFee?.toFixed(2)} (in person)</strong>
                                  </Text>
                                  <Text>
                                    <strong>${onlinePaymentHandlingFee?.toFixed(2)} (online)</strong>
                                  </Text>
                                </StyledDivAlignRight>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <StyledLeftDivWidth75>
                                  <Text>
                                    A replacement card fee will be charged to the <strong>account</strong> when{' '}
                                    <strong>you</strong> request that we provide <strong>you</strong> or an{' '}
                                    <strong>additional cardholder</strong> with a replacement card when any{' '}
                                    <strong>card</strong> is lost, stolen or damaged.
                                  </Text>
                                </StyledLeftDivWidth75>
                                <StyledDivAlignRight>
                                  <Text>
                                    <strong>${replacementCardFee?.toFixed(2)}</strong>
                                  </Text>
                                </StyledDivAlignRight>
                              </StyledDivSpaceBetween>
                              <hr />
                              <Container>
                                <Text>
                                  These fees and charges will be payable as part of the <strong>balance</strong> of your{' '}
                                  <strong>account</strong> in the <strong>minimum monthly payments</strong>. You will{' '}
                                  not have to make a payment in a statement period if your monthly payment would{' '}
                                  otherwise consist of a paper statement fee.
                                </Text>
                              </Container>
                              <br />
                              <Container>
                                <Text textStyle="heading5">Changes to the Credit Contract</Text>
                              </Container>
                              <hr />
                              <Container>
                                <Text>
                                  The credit limit, any of the annual percentage rate(s), the maximum interest free{' '}
                                  period, the amount, frequency or time for payment or method of calculation of a fee or{' '}
                                  charge or minimum amount payable in a statement period may be changed, and new fees or{' '}
                                  charges may be imposed, in each case without your consent (except that we cannot{' '}
                                  increase the credit limit except at your request or with your consent in any form{' '}
                                  required by law). You will be notified of the change or new fees or charges in the{' '}
                                  manner set out in the Conditions of Use.
                                </Text>
                              </Container>
                              <br />
                              <Container>
                                <Text textStyle="heading5">Commission</Text>
                                <hr />
                                <Text>
                                  We pay commission for the introduction of credit business (the amounts of which are{' '}
                                  unascertainable) to merchants, their staff and their head offices, when further credit{' '}
                                  is provided to you for subsequent transactions through those merchants ("head office"{' '}
                                  means the company that provides certain co-ordination, administrative or other{' '}
                                  services for a chain of stores. This may be a related company, or in the case of a{' '}
                                  franchise, the franchisor) for the introduction of that credit business to the staff{' '}
                                  member who processed your request. We also pay commission to our staff when they{' '}
                                  process subsequent <strong>cash advances</strong>, <strong>purchases</strong> or{' '}
                                  <strong>balance transfers</strong>.
                                </Text>
                              </Container>
                              <Container>
                                <Text textStyle="heading5">Other Information</Text>
                                <hr />
                                <Text>
                                  If you do not want to receive direct marketing information about other products or{' '}
                                  services, please call {config.phoneNumbers.assistance}.
                                </Text>
                              </Container>
                              <Container>
                                <Text textStyle="heading5">Offer to New Account Holders</Text>
                              </Container>
                              <hr />
                              <Text>
                                Latitude Finance Australia ABN 42 008 583 588, Australian Credit Licence number 392145,{' '}
                                as the credit provider, offers to provide you with a CreditLine <strong>account</strong>{' '}
                                on the conditions set out in the <strong>contract</strong>. Subject to clause 2 of the{' '}
                                Conditions of Use, you accept this offer for a <strong>contract</strong> by using the{' '}
                                <strong>account</strong> to access or drawdown credit to incur a liability on the{' '}
                                <strong>account</strong>.
                              </Text>
                              <br />
                              <Container padding="4 0">
                                <Text>Signed on behalf of Latitude Finance Australia.</Text>
                                <br />
                                <img src={AdrianaMartinezSignature} width="150px" alt="form-title-icon" />
                                <Text>
                                  <strong>Adriana Martinez</strong>
                                </Text>
                                <Text>Executive General Manager - Latitude Pay</Text>
                              </Container>
                            </Stack>
                          </div>
                        </Container>
                      </Grid>
                    </Grid.Item>
                  </Grid>
                </Container>
              </Grid>
            </Grid.Item>
          </Grid>
        </Container>
      </Container>
    </LayoutPage>
  );
}
