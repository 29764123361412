import React from 'react';
import { Text } from 'basis';
import { yesNoOptions, numbersOnlyPattern } from '../_constants';

const dollarAmountValidationMessages = {
  required: 'You must enter a dollar amount.',
  invalid: 'Please enter a whole dollar amount, without cents.',
  maxValue: 'Must not be more than $99,999,999.',
  maxLength: 'Must not be more than $99,999,999.',
};

const validationMessages = {
  dollarAmount: dollarAmountValidationMessages,
};

export const expensesDetails = [
  {
    component: 'InfoBlock',
    name: 'expensesInfo',
    text:
      'Please enter your expenses as accurately as possible. If you share expenses, only enter what you contribute.',
  },
  {
    component: 'InfoBlock',
    name: 'yourMonthlyExpenses',
    padding: '8 0 0 0',
    title: 'Your monthly expenses',
    color: 'highlight.blue.t100',
    titleStyle: 'heading4',
  },
  {
    name: 'isExpensesShared',
    label: (values, storeState) =>
      storeState.acquisition.extraDetails.relationshipStatus === 'SINGLE_OTHER'
        ? 'Does anyone else pay towards the household expenses?'
        : 'Does anyone else, including your partner, pay towards the household expenses?',
    component: 'RadioGroup',
    options: yesNoOptions,
    showCircles: true,
    testId: 'is-expenses-shared',
  },
  {
    condition: values => values.isExpensesShared === 'yes',
    component: 'InfoBanner',
    name: 'householdInfoBanner',
    children: <Text textStyle="body2">Enter only the amount that you pay towards each expense.</Text>,
  },
  {
    component: 'RadioGroup',
    name: 'isRevolvingOrOffsetMortgage',
    label: 'Do you have a revolving or offset mortgage?',
    helpText:
      'A revolving or offset mortgage uses money in your everyday and savings accounts to reduce the interest you pay on your home loan.',
    testId: 'revolving-or-offset-mortgage',
    options: yesNoOptions,
    showCircles: true,
  },
  {
    condition: values => values.isRevolvingOrOffsetMortgage === 'yes',
    label: 'What is your facility limit?',
    component: 'Input',
    variant: 'numeric',
    name: 'facilityLimit',
    testId: 'facility-limit',
    prefix: '$',
    validate: {
      props: { minValue: 0, maxValue: 99999999, validPattern: numbersOnlyPattern },
      messages: validationMessages.dollarAmount,
    },
  },
  {
    component: 'InfoBlock',
    name: 'yourResidentialExpenses',
    color: 'highlight.blue.t100',
    padding: '8 0 0 0',
    title: 'Your residential expenses',
    titleStyle: 'heading4',
  },
  /**
   * Your residential expenses
   ** */
  {
    component: 'Frequency',
    name: 'primaryPropertyMortgageRentBoard',
    label: 'Mortgage, rent or board',
    testId: 'mortgage-rent-board',
    helpText: 'For your primary residence',
    mode: 'select',
    amountPrefix: '$',
    annually: false,
    validate: {
      props: { maxLength: 8, validPattern: numbersOnlyPattern },
      messages: validationMessages.dollarAmount,
      component: 'Frequency',
    },
  },
  {
    component: 'Frequency',
    name: 'secondaryPropertyMortgageRentBoard',
    label: 'Secondary mortgage, rent or board',
    testId: 'secondary-mortgage-rent-board',
    helpText: 'For any secondary or investment property',
    mode: 'select',
    amountPrefix: '$',
    annually: false,
    validate: {
      props: { maxLength: 8, validPattern: numbersOnlyPattern },
      messages: validationMessages.dollarAmount,
      component: 'Frequency',
    },
  },
  {
    component: 'Frequency',
    name: 'propertyRates',
    label: 'Property rates',
    testId: 'property-rates',
    helpText: 'Rates for both primary and secondary properties',
    mode: 'select',
    amountPrefix: '$',
    annually: false,
    validate: {
      props: { maxLength: 8, validPattern: numbersOnlyPattern },
      messages: validationMessages.dollarAmount,
      component: 'Frequency',
    },
  },
  {
    component: 'Frequency',
    name: 'propertyUtilitiesOther',
    label: 'Utilities and other property expenses',
    testId: 'utilities-and-other-property-expenses',
    helpText:
      'E.g. body corporate fees, water, rubbish collection, recycling, general property maintenance not included in the above',
    mode: 'select',
    amountPrefix: '$',
    annually: false,
    validate: {
      props: { maxLength: 8, validPattern: numbersOnlyPattern },
      messages: validationMessages.dollarAmount,
      component: 'Frequency',
    },
  },
  {
    component: 'InfoBlock',
    name: 'yourLivingExpenses',
    color: 'highlight.blue.t100',
    padding: '8 0 0 0',
    title: 'Your living expenses',
    titleStyle: 'heading4',
  },
  /**
   * Your residential expenses
   ** */
  {
    component: 'Frequency',
    name: 'foodGroceriesLifestyleEntertainment',
    label: 'Food, groceries & entertainment',
    testId: 'food-groceries-lifestyle-entertainment',
    helpText: 'E.g. supermarket, bakery, restaurants, takeaways, lunches, coffees',
    mode: 'select',
    amountPrefix: '$',
    annually: false,
    validate: {
      props: { maxLength: 8, validPattern: numbersOnlyPattern },
      messages: validationMessages.dollarAmount,
      component: 'Frequency',
    },
  },
  {
    component: 'Frequency',
    name: 'healthLifeInsurance',
    label: 'Health & life insurance',
    testId: 'health-life-insurance',
    mode: 'select',
    amountPrefix: '$',
    annually: false,
    validate: {
      props: { maxLength: 8, validPattern: numbersOnlyPattern },
      messages: validationMessages.dollarAmount,
      component: 'Frequency',
    },
  },
  {
    component: 'Frequency',
    name: 'generalInsurance',
    label: 'General insurances',
    testId: 'general-insurance',
    helpText: 'E.g. home, vehicle, and contents insurance etc.',
    mode: 'select',
    amountPrefix: '$',
    annually: false,
    validate: {
      props: { maxLength: 8, validPattern: numbersOnlyPattern },
      messages: validationMessages.dollarAmount,
      component: 'Frequency',
    },
  },
  {
    component: 'Frequency',
    name: 'transport',
    label: 'Transport & auto',
    testId: 'transport-auto',
    helpText: 'E.g. public transport, ride sharing, petrol, parking, vehicle maintenance and registration etc.',
    mode: 'select',
    amountPrefix: '$',
    annually: false,
    validate: {
      props: { maxLength: 8, validPattern: numbersOnlyPattern },
      messages: validationMessages.dollarAmount,
      component: 'Frequency',
    },
  },
  {
    component: 'Frequency',
    name: 'educationChildcare',
    label: 'Education & childcare',
    testId: 'education-childcare',
    helpText: 'E.g. private school and higher education fees, day-care etc.',
    mode: 'select',
    amountPrefix: '$',
    annually: false,
    validate: {
      props: { maxLength: 8, validPattern: numbersOnlyPattern },
      messages: validationMessages.dollarAmount,
      component: 'Frequency',
    },
  },
  {
    component: 'Frequency',
    name: 'personalFitnessMedical',
    label: 'Personal, fitness & medical',
    testId: 'personal-fitness-medical',
    helpText: 'E.g. phone, clothing, medical, fitness, vet, doctor etc.',
    mode: 'select',
    amountPrefix: '$',
    annually: false,
    validate: {
      props: { maxLength: 8, validPattern: numbersOnlyPattern },
      messages: validationMessages.dollarAmount,
      component: 'Frequency',
    },
  },
  {
    component: 'Frequency',
    name: 'other',
    label: 'Any Additional expenses',
    testId: 'any-additional-expenses',
    helpText:
      'E.g. child maintenance, donations and tithings to family and/or the church.  Also include any expenses that are incurred due to your particular circumstances, or expenses that you need to pay that are less frequent. ',
    mode: 'select',
    amountPrefix: '$',
    annually: false,
    validate: {
      props: { maxLength: 8, validPattern: numbersOnlyPattern },
      messages: validationMessages.dollarAmount,
      component: 'Frequency',
    },
  },
];
