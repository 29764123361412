import React from 'react';
import { useStoreValue } from 'store';
import { Message, Text } from 'basis';
import { GreenMessage } from 'components/message/GreenMessage';

export const SuccessMessage = () => {
  const [storeState] = useStoreValue();

  const message = getMessage(storeState.previousApplicationStatus);

  if (!message) {
    return null;
  }

  return (
    <GreenMessage>
      <Message testId="green-message" severity="success" contentColor="conditional.positive.text">
        <Text color="conditional.positive.text">{message}</Text>
      </Message>
    </GreenMessage>
  );
};

function getMessage(previousApplicationStatus) {
  switch (previousApplicationStatus) {
    case 'POI_CHECK_REQUIRED':
    case 'POI_CHECK_PENDING':
    case 'BIOMETRICS_CHECK_REQUIRED':
      return `Success, we've verified your proof of income.`;
    case 'IDV_CHECK_REQUIRED':
    case 'IDV_CHECK_PENDING':
      return `Success, we've verified your ID documents.`;
    case 'LATITUDE_ID_PASSWORD_REQUIRED':
      return `Success, your Latitude ID and password is all set!`;
    case 'EMAIL_VERIFICATION_REQUIRED':
      return `Success, we've verified your email!`;
    case 'QUOTE_INITIAL':
    case 'QUOTE_DECLINE':
    case 'PROCESSING_ACCEPTANCE':
      return `Success, you've submitted your application.`;
    default:
      return null;
  }
}
