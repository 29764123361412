/* eslint-disable array-callback-return */
import { convertRateToMonthlyString } from 'utils/convertRateToMonthly';
import { byCountry, isAU } from './byConfig';
import 'types';

const expensesItemsNeedToCheckNZ = [
  'primaryPropertyMortgageRentBoard',
  'secondaryPropertyMortgageRentBoard',
  'propertyRates',
  'propertyUtilitiesOther',
  'foodGroceriesLifestyleEntertainment',
  'healthLifeInsurance',
  'generalInsurance',
  'transport',
  'educationChildcare',
  'personalFitnessMedical',
  'other',
];

const expensesItemsNeedToCheckAU = [
  'mortgageRentBoard',
  'foodGroceriesLifestyleEntertainment',
  'insurance',
  'utilities',
  'educationAndChildcare',
  'transport',
  'personalAndOtherExpense',
];

/**
 * @name calculatedTotalMonthlyExpenses
 * @description Calculates the total monthly expenses
 * @param {storeState['acquisition']['expensesDetails']} values - The storeState acquisition.expensesDetails object
 * @returns {number}
 */
export const calculatedTotalMonthlyExpenses = values => {
  if (!values) {
    return null;
  }
  const result = byCountry({ NZ: expensesItemsNeedToCheckNZ, AU: expensesItemsNeedToCheckAU })
    .map(item => {
      return Number(convertRateToMonthlyString(values[item], 2));
    })
    .reduce((a, b) => a + b, 0);
  return result;
};

export const getStops = values => {
  let stop;
  const totalMonthlyExpenses = calculatedTotalMonthlyExpenses(values);
  const mortgageRentBoardMonthlyExpense = convertRateToMonthlyString(values.primaryPropertyMortgageRentBoard);
  const hasFieldEmpty = checkFieldAmountEmpty(values);

  const expensesSpecialItemsNeedToCheck = [
    { value: values.primaryPropertyMortgageRentBoard.amount, message: 'Mortgage, rent, board' },
    { value: values.foodGroceriesLifestyleEntertainment.amount, message: 'Food & Groceries & Entertainment' },
    { value: values.transport.amount, message: 'Transport & Auto' },
    { value: values.personalFitnessMedical.amount, message: 'Personal, Fitness & Medical' },
    { value: values.healthLifeInsurance.amount, message: 'Health & Life Insurance' },
  ];

  const checkSpecialItems = expensesSpecialItemsNeedToCheck.find(item => item.value === '0');

  if (checkSpecialItems) {
    const messages = [];
    expensesSpecialItemsNeedToCheck.map(item => {
      if (item.value === '0') {
        messages.push(item.message);
      }
    });
    stop = {
      stopType: 'soft',
      title: 'Are you sure the amount is correct?',
      messages,
    };
  }

  if (mortgageRentBoardMonthlyExpense > 15000) {
    stop = {
      stopType: 'soft',
      title: 'Are you sure the amount is correct?',
      messages: ['Monthly mortgage, rent or board is more than $15,000'],
    };
  }

  if (totalMonthlyExpenses > 30000) {
    stop = {
      stopType: 'soft',
      title: 'Are you sure the total expenses amount is correct?',
      messages: ['Total monthly residential and living expenses more than $30,000'],
    };
  }

  if (!hasFieldEmpty && totalMonthlyExpenses === 0) {
    stop = {
      stopType: 'hard',
      title: 'Total residential and living expenses must be greater than $0',
    };
  }

  if (!hasFieldEmpty && totalMonthlyExpenses > 99999999) {
    stop = {
      stopType: 'hard',
      title: 'Your total monthly expenses must not be more than $99,999,999.',
    };
  }

  return stop;
};

export const allowNextStep = values => {
  if (isAU()) {
    return true;
  }
  return getStops(values) === undefined || getStops(values)?.stopType !== 'hard';
};

export const checkFieldAmountEmpty = values => {
  const result = expensesItemsNeedToCheckNZ
    .map(item => {
      return values[item].amount;
    })
    .includes('');

  return result;
};
