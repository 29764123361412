import React, { useEffect } from 'react';
import {
  APPLICATION_RESUME_PERIOD_DAYS,
  APPLICATION_START_DATE_PATTERN,
  APPLICATION_START_DATE_PARAMETER,
  STEP_RESUME_APPLICATION_EXPIRED,
  setConfig,
  config,
} from '_config';
import { getDaysBetweenDates } from 'utils/getDaysBetweenDates';
import { ContactDetails } from 'views/contact-details/ContactDetails';
import { useStoreValue, PENDING, REFERRED, getInitialState } from 'store';
import { useHistory } from 'react-router-dom';

/**
 * Supported query params:
 * - `applicationStartDate`?: string. Date string in format `YYYY-MM-DD`
 * - `stage`?: 'pre-submission' | `post-submission`.
 * - `applicationId`?: string.
 * - `productCode`?: 'CCAUDJG'. To determine the styling theme.
 * - `cardType`?: 'DJ'. To determine the styling theme.
 */
export function ResumeYourApplication() {
  const [storeState, updateStore] = useStoreValue();
  const history = useHistory();

  const queryString = window.location.search;
  const path = window.location.pathname;

  useEffect(() => {
    const queryParams = new URLSearchParams(queryString);
    const productCode = queryParams.get('productCode');
    const cardType = queryParams.get('cardType');

    if (productCode) {
      setConfig({ productCode });
    }

    if (!productCode && cardType) {
      setConfig({ countryCode: cardType === 'DJ' ? 'AU' : 'NZ' });
    }

    updateStore({
      ...getInitialState(),
      countryCode: config.countryCode,
      isResuming: true,
      activeStep: path,
      applicationId: queryParams.get('applicationId') || undefined,
      applicationStartDate: queryParams.get(APPLICATION_START_DATE_PARAMETER) || undefined,
      applicationStatus: 'RESUME',
    });
  }, [path, queryString, updateStore]);

  useEffect(() => {
    if (hasApplicationExpired(storeState.applicationStartDate)) {
      const nextStep = STEP_RESUME_APPLICATION_EXPIRED;
      updateStore({
        activeStep: nextStep,
      });

      history.push(nextStep);
    }
  }, [history, updateStore, storeState.applicationStartDate]);

  useEffect(() => {
    if (storeState.isResuming && storeState.applicationStatus === PENDING) {
      // handle applications which are stuck in pending status due to Activate downtime
      updateStore({
        activeStep: '/outcome',
        applicationStatus: REFERRED,
      });
    }
  }, [storeState.applicationStatus, storeState.isResuming, updateStore]);

  return <ContactDetails />;
}

/**
 * Parse query params `applicationStartDate` and determine if application has expired.
 */
function hasApplicationExpired(applicationStartDate) {
  // Treat missing parameter as not expired (to allow resume from homepage)
  if (!applicationStartDate) {
    return false;
  }

  // Invalid parameters as expired
  const pattern = new RegExp(APPLICATION_START_DATE_PATTERN);

  if (!pattern.test(applicationStartDate)) {
    return true;
  }

  if (getDaysBetweenDates(new Date(applicationStartDate), new Date()) < APPLICATION_RESUME_PERIOD_DAYS) {
    return false;
  }

  return true;
}
