import { APPROVED, DECLINED, REFERRED, CONDITIONALLY_APPROVED, SERVICE_UNAVAILABLE } from 'store';
import { LANDING_PAGE_STEPS } from '_config';
import { byCountry } from './byConfig';

export const getSiteSubSection = pathname => {
  if (LANDING_PAGE_STEPS.includes(pathname)) {
    return 'intro';
  }
  if (pathname === '/outcome' || pathname === '/acceptance') {
    return 'application-outcome';
  }
  return 'application';
};

export const getPageTitle = (pathname, applicationStatus) => {
  if (LANDING_PAGE_STEPS.includes(pathname)) {
    return byCountry({
      AU: 'landing',
      NZ: 'what-to-expect',
    });
  }

  if (pathname === '/credit-limit-details') {
    return byCountry({
      AU: 'credit-limit',
      NZ: 'credit-limit-details',
    });
  }

  if (pathname === '/check-your-identity') {
    return 'identification';
  }

  if (pathname === '/processing-poi') {
    return 'application-processing';
  }

  if (pathname === '/processing') {
    return 'application-processing';
  }

  if (pathname === '/verify-income') {
    return 'poi-landing';
  }

  if (pathname === '/verify-ficonnect') {
    return 'bank-connect';
  }

  if (pathname === '/acceptance') {
    return 'contract-acceptance';
  }

  if (pathname === '/outcome') {
    switch (applicationStatus) {
      case APPROVED:
        return 'approved';
      case DECLINED:
        return 'declined';
      case REFERRED:
        return 'referred';
      case SERVICE_UNAVAILABLE:
        return 'system-unavailable';
      case CONDITIONALLY_APPROVED:
        return 'ConditionalApproval';
      default:
        return null;
    }
  }

  return pathname.substring(1);
};
