import { emailMaxLength, validEmailPattern } from '../_constants';
import { validNzMobilePattern } from './constants';

const mobileNumberValidationMessages = {
  required: 'Please enter a 9-11 digit phone number.',
  invalid: 'Mobile number must begin with 02 followed by 7-9 numbers in total with no spaces or dashes',
};

const emailValidationMessages = {
  required: 'Please enter a valid email address.',
  matchField: 'Email addresses do not match - please re-enter your email address.',
  maxLength: 'Must have at most {{maxLength}} characters',
  invalid: 'Please enter in the format of name@example.com',
};

const validationMessages = {
  mobileNumber: mobileNumberValidationMessages,
  emailAddress: emailValidationMessages,
  confirmEmail: emailValidationMessages,
};

const fields = isResuming => {
  if (isResuming) {
    return [
      {
        component: 'InfoBlock',
        name: 'resumeApplicationContactSubtitle',
        title: 'Enter your mobile number and email address',
        titleStyle: 'heading5',
      },
      {
        component: 'InfoBlock',
        name: 'resumeApplicationContactDescription',
        text: 'Please enter the mobile number and email address provided in your application.',
      },
      {
        component: 'Input',
        name: 'mobileNumber',
        label: 'Mobile number',
        testId: 'mobile-number',
        validate: { props: { validPattern: validNzMobilePattern }, messages: validationMessages.mobileNumber },
      },
      {
        component: 'Input',
        name: 'emailAddress',
        label: 'Email address',
        testId: 'email-address',
        validate: {
          props: { maxLength: emailMaxLength, validPattern: validEmailPattern },
          messages: validationMessages.emailAddress,
        },
      },
    ];
  }
  return [
    {
      component: 'Input',
      name: 'mobileNumber',
      label: 'Mobile number',
      testId: 'mobile-number',
      helpText: `Only New Zealand mobile phone numbers are accepted.`,
      validate: { props: { validPattern: validNzMobilePattern }, messages: validationMessages.mobileNumber },
    },
    {
      component: 'Input',
      name: 'emailAddress',
      label: 'Email address',
      testId: 'email-address',
      validate: {
        props: { maxLength: emailMaxLength, validPattern: validEmailPattern },
        messages: validationMessages.emailAddress,
      },
    },
    {
      component: 'Input',
      name: 'confirmEmail',
      label: 'Confirm email address',
      testId: 'confirm-email-address',
      validate: {
        props: {
          maxLength: emailMaxLength,
          validPattern: validEmailPattern,
          matchField: { name: 'emailAddress', label: 'Email address' },
        },
        messages: validationMessages.confirmEmail,
      },
      pasteAllowed: false,
    },
  ];
};

export const contactDetails = isResuming => fields(isResuming);
