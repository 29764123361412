import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useStoreValue } from 'store';
import { LayoutPage, LayoutContent, FormRenderer, Form, ExpensesFooter, WarningModal } from 'components';
import { initFormConfig, submitHandler, isAU, byCountry } from 'utils';
import { config } from '_config';
import { useEventTracking } from 'react-event-tracker';
import { getDataLayerElements } from 'utils/getDataLayerElements';
import { allowNextStep } from 'utils/expensesFooterHelper';
import { useWarning } from '_config/au/expensesDetails';
import { useFormInitialValues } from '../../hooks';

const FORM_ID = 'expensesDetails';
const formConfig = initFormConfig(config.formConfigs[FORM_ID]);

export function ExpensesDetails() {
  const [storeState, updateStore] = useStoreValue();
  const history = useHistory();
  const { trackEvent } = useEventTracking();
  const initialValues = useFormInitialValues(FORM_ID);
  const [isWarningModalOpen, setWarningModalOpen] = useState(false);
  const onModalClose = useCallback(() => setWarningModalOpen(false), [setWarningModalOpen]);
  const { check, warnings } = useWarning();

  const onSubmit = useCallback(
    ({ values, errors }) => {
      const newAcquisition = {
        ...storeState.acquisition,
        expensesDetails: { ...values },
      };

      function updateStoreAndRedirect() {
        updateStore({
          activeStep: '/commitments',
          acquisition: newAcquisition,
        });

        history.push('/commitments');

        trackEvent({
          event: {
            category: 'application',
            action: 'application-navigation',
            location: 'expenses',
            label: byCountry({
              AU: 'Next - Other financial commitments',
              NZ: 'Next',
            }),
          },
          ...getDataLayerElements({ ...storeState, acquisition: newAcquisition }),
        });
      }

      if (allowNextStep(values)) {
        submitHandler({ submit: updateStoreAndRedirect, errors });
      }
    },
    [history, storeState, trackEvent, updateStore],
  );

  const onClickNext = useCallback(
    ({ values, errors }) => {
      if (Object.keys(errors).length > 0) {
        return;
      }
      if (isAU() && !check(values, storeState)) {
        setWarningModalOpen(true);
      } else {
        onSubmit({ values, errors });
      }
    },
    [check, onSubmit, storeState],
  );

  return (
    <LayoutPage noSessionTimeout>
      <Form
        id={FORM_ID}
        initialValues={initialValues}
        onSubmit={onClickNext}
        footer={<ExpensesFooter />}
        showSaveDraftButton
        saveDraftOnSubmit
        saveDraftOnSessionTimeout
      >
        {formData => {
          return (
            <LayoutContent>
              <FormRenderer config={formConfig} formData={formData} storeState={storeState} />
              <WarningModal
                title="Can you confirm these finances are correct?"
                isOpen={isWarningModalOpen}
                onClose={onModalClose}
                onSubmit={() => onSubmit(formData.state)}
                warnings={warnings}
                editButtonText="Edit Finance"
                confirmButtonText="Confirm and Continue"
              />
            </LayoutContent>
          );
        }}
      </Form>
    </LayoutPage>
  );
}
