import React from 'react';
import { Button, Container, Text, Grid } from 'basis';
import { Modal } from 'components/modal/Modal';
import { AU_MEDICARE, AU_PASSPORT } from '_config/au/constants';
import compact from 'lodash/compact';
import { medicareCardColourOptions, MEDICARE_CARD_GREEN } from '_config';

export const ConfirmationModal = ({ storeState, isOpen, onClose, onVerify, formValues, loading }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} style={{ maxWidth: 600 }}>
      <Container padding="9">
        <Text textStyle="heading5">Can you confirm these ID details are correct?</Text>

        <Container testId="confirmation-modal" padding="9 0">
          {formValues.idType === AU_PASSPORT && <AuPassportData formValues={formValues} storeState={storeState} />}
          {formValues.idType === AU_MEDICARE && <MedicareData formValues={formValues} />}
        </Container>

        <Grid rowsGap="4" colsGap="2">
          <Grid.Item colSpan="all" colSpan-sm="0-1">
            <Grid>
              <Button testId="editButton" type="button" variant="secondary" onClick={onClose}>
                Edit
              </Button>
            </Grid>
          </Grid.Item>
          <Grid.Item colSpan="all" colSpan-sm="2-3" alignItems="center">
            <Grid>
              <Button onClick={onVerify} loading={loading}>
                Verify now
              </Button>
            </Grid>
          </Grid.Item>
        </Grid>
      </Container>
    </Modal>
  );
};

const AuPassportData = ({ formValues, storeState }) => {
  const { firstName, middleName, lastName } = storeState.acquisition.personalDetails;
  const fullName = compact([firstName, middleName, lastName]).join(' ');

  return (
    <div data-hj-suppress>
      <Text>
        <b>ID document</b>: Australian Passport
      </Text>
      <Text>
        <b>Name on passport</b>: {fullName}
      </Text>
      <Text>
        <b>Australian Passport no.</b>: {formValues.idNumber}
      </Text>
    </div>
  );
};

const MedicareData = ({ formValues }) => {
  const { fullName } = JSON.parse(formValues.name);

  const medicareCardColour = medicareCardColourOptions.find(opt => formValues.medicareCardColour === opt.value).label;

  const expiryDate =
    formValues.medicareCardColour === MEDICARE_CARD_GREEN
      ? `${formValues.expiryDateGreen.month}/${formValues.expiryDateGreen.year}`
      : `${formValues.expiryDateNonGreen.day}/${formValues.expiryDateNonGreen.month}/${formValues.expiryDateNonGreen.year}`;

  return (
    <div data-hj-suppress>
      <Text>
        <b>ID document</b>: Medicare
      </Text>
      <Text>
        <b>Name on card</b>: {fullName}
      </Text>
      <Text>
        <b>Medicare colour</b>: {medicareCardColour}
      </Text>
      <Text>
        <b>Individual reference number</b>: {formValues.individualReferenceNumber}
      </Text>
      <Text>
        <b>Card number</b>: {formValues.medicareCardNumber}
      </Text>
      <Text>
        <b>Expiry date</b>: {expiryDate}
      </Text>
    </div>
  );
};
