import { COUNTRY_CODE_NZ } from '_config';
import { residentialStatusOptions, yesNoOptions, numbersOnlyPattern } from '../_constants';

import { minimumResidencyInMonths } from './constants';

export const lengthOfCurrentResidencyValidationMessages = {
  required: "Please enter the number of years you've lived at this address.",
  invalid: 'Please enter numbers only.',
  monthsError: 'Please indicate the number of months at current address. If less than 1 month, enter 0.',
  yearsError: "Please enter the number of years you've lived at this address.",
  monthRangeError: 'Months must be within {{minMonths}}-{{maxMonths}}.',
  yearRangeError: 'Years must be within {{minYears}}-{{maxYears}}.',
};

export function showPreviousAddress({ years, months }) {
  if (years && months) {
    const durationMonths = parseInt(years, 10) * 12 + parseInt(months, 10);
    return durationMonths < minimumResidencyInMonths;
  }
  return false;
}

const fields = [
  {
    component: 'Select',
    name: 'residentialStatus',
    label: 'Residential status',
    testId: 'residential-status',
    options: residentialStatusOptions,
    validate: {
      messages: {
        required: 'Please select residential status.',
      },
    },
  },
  {
    component: 'Address',
    name: 'currentAddress',
    label: 'Current NZ residential address',
    testId: 'current-residential-address',
    allowPropertyName: false,
    allowPO: false,
    countryCode: COUNTRY_CODE_NZ,
    autoAddressErrorMessage: 'Please enter your current NZ residential address.',
    autoAddressHelpText: 'We do not accept PO Box or international addresses',
  },
  {
    component: 'RadioGroup',
    name: 'isPostalSameWithResidential',
    label: 'This is also my mailing address',
    testId: 'is-postal-same-with-residential',
    options: yesNoOptions,
    showCircles: true,
    validate: {
      messages: {
        required: 'Is your postal address the same as your current residential address?',
      },
    },
  },
  {
    condition: values => values.isPostalSameWithResidential === 'no',
    component: 'Address',
    name: 'postalAddress',
    label: 'Postal address',
    testId: 'postal-address',
    allowPropertyName: false,
    allowPO: true,
    countryCode: COUNTRY_CODE_NZ,
    autoAddressErrorMessage: 'Please enter your postal address.',
  },
  {
    component: 'TimeSpan',
    name: 'lengthOfCurrentResidency',
    label: 'Length of time at current residential address',
    testId: 'current-residential-address-length',
    validate: {
      props: { minYears: 0, maxYears: 99, minMonths: 0, maxMonths: 11, validPattern: numbersOnlyPattern },
      messages: lengthOfCurrentResidencyValidationMessages,
      component: 'TimeSpan',
    },
  },
  {
    condition: values => showPreviousAddress(values.lengthOfCurrentResidency),
    name: 'previousAddressGroup',
    fields: [
      {
        condition: values => values.hasNonDomesticPreviousAddress === false,
        component: 'Address',
        name: 'previousAddress',
        label: 'Previous residential address',
        testId: 'previous-residential-address',
        allowPropertyName: false,
        allowPO: false,
        countryCode: COUNTRY_CODE_NZ,
        autoAddressErrorMessage: 'Please provide your previous NZ address or confirm that it was not in NZ.',
      },
      {
        component: 'Checkbox',
        name: 'hasNonDomesticPreviousAddress',
        label: '',
        children: 'My previous address was not in New Zealand',
        testId: 'previous-address-non-domestic',
        validate: false,
      },
    ],
  },
];

export const otherDetails = fields;
