import threatmetrix from 'library/threatmetrix';
import { useEffect } from 'react';
import { useStoreValue } from 'store';

export function useThreatMetrix({ disabled = false }) {
  const threatMetrixOrgId = process.env.REACT_APP_THREATMETRIX_ORGID;
  const threatMetrixProfileId = process.env.REACT_APP_THREATMETRIX_PROFILE_ID;
  const [storeState] = useStoreValue();
  const { applicationId } = storeState;

  useEffect(() => {
    if (!disabled) {
      threatmetrix.profile(threatMetrixProfileId, threatMetrixOrgId, applicationId);
    }
  }, [applicationId, disabled, threatMetrixOrgId, threatMetrixProfileId]);
}
