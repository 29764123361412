// Source https://di.latitudefinancial.com/wiki/pages/viewpage.action?spaceKey=LFS&title=Activate+Additional+Info
export const residentialStatusOptions = [
  {
    value: 'HOME_OWNER',
    label: 'I own my home outright',
  },
  {
    value: 'MORTGAGE',
    label: 'I have a mortgage',
  },
  {
    value: 'BOARDING',
    label: "I'm boarding",
  },
  {
    value: 'RENT',
    label: "I'm renting",
  },
  {
    value: 'LIVING_WITH_RELATIVE',
    label: 'I live with my parents',
  },
];

export const RS_HOME_OWNER = 'HOME_OWNER';
export const RS_MORTGAGE = 'MORTGAGE';
export const RS_BOARDING = 'BOARDING';
export const RS_RENT = 'RENT';
export const RS_LIVING_WITH_RELATIVE = 'LIVING_WITH_RELATIVE';
export const RS_OTHER = 'OTHER';

export const residentialStatusOptionsAU = [
  {
    value: RS_HOME_OWNER,
    label: 'I own my own home without a mortgage',
  },
  {
    value: RS_MORTGAGE,
    label: 'I own my own home with a mortgage',
  },
  {
    value: RS_BOARDING,
    label: "I'm boarding",
  },
  {
    value: RS_RENT,
    label: "I'm renting",
  },
  {
    value: RS_LIVING_WITH_RELATIVE,
    label: 'I live with my parents',
  },
  {
    value: RS_OTHER,
    label: 'Other',
  },
];
