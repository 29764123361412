import React from 'react';
import { isAU } from 'utils';
import { NZErrors } from './NZErrors';
import { AUErrors } from './AUErrors';

export function Errors({ applicationRef, retry, retrying, isPreSubmission = false }) {
  if (isAU()) {
    return (
      <AUErrors applicationRef={applicationRef} retry={retry} retrying={retrying} isPreSubmission={isPreSubmission} />
    );
  }

  return <NZErrors applicationRef={applicationRef} retry={retry} retrying={retrying} />;
}
