import React, { useCallback } from 'react';
import { useStoreValue } from 'store';
import { Input, Container, Text } from 'basis';
import { calculateMonthlyRepayment } from 'utils/calculateMonthlyRepayment';
import { numbersOnlyPattern } from '_config/_constants';
import { creditLimitIncrement } from '_config/au/constants';
import { validation } from '_config/_validation/validation';
import { formatNumber, cleanNumberFormat } from 'utils/formatNumber';
import { getProductInfo } from 'utils';

export const AcceptedCreditLimit = ({ formData, maxCreditLimit }) => {
  const [storeState, updateStore] = useStoreValue();
  const { minCreditLimit } = getProductInfo();
  const validationRules = {
    props: {
      minValue: minCreditLimit,
      maxValue: maxCreditLimit,
      minLength: 3,
      maxLength: 6,
      incrementValue: creditLimitIncrement,
      validPattern: numbersOnlyPattern,
    },
    messages: {
      required: 'Please tell us your preferred credit limit in dollars.',
      minValue: `Please enter an credit limit amount of ${formatNumber(
        minCreditLimit,
      )} or more in \${{incrementValue}} increments.`,
      maxValue: `The maximum amount you can enter is $ ${formatNumber(maxCreditLimit)}.`,
      minLength: 'Must have at least {{minLength}} digits.',
      maxLength: 'Must have at most {{maxLength}} digits.',
      incrementValue: `Please enter an amount in \${{incrementValue}} increments.`,
      invalid: `Please enter a numeric value of ${formatNumber(
        minCreditLimit,
      )} or more in \${{incrementValue}} increments.`,
    },
  };

  const validate = useCallback(
    value => validation(validationRules.props, validationRules.messages)[0](cleanNumberFormat(value)),
    [validationRules.messages, validationRules.props],
  );

  const onChange = useCallback(
    event => {
      const input = cleanNumberFormat(event.value);
      formData.setValues(values => ({
        ...values,
        newCreditLimit: formatNumber(input),
      }));
      const invalid = validation(validationRules.props, validationRules.messages)[0](input);
      if (!invalid) {
        updateStore({
          creditLimitAccepted: input,
        });
      }
    },
    [formData, updateStore, validationRules.messages, validationRules.props],
  );

  return (
    <>
      <Input
        label="I would like a credit limit of:"
        component="Input"
        variant="numeric"
        name="newCreditLimit"
        testId="new-credit-limit"
        prefix="$"
        maxLength="6"
        onChange={onChange}
        validate={validate}
      />
      {storeState.creditLimitAccepted &&
        storeState.creditLimitAccepted === cleanNumberFormat(formData.state.values.newCreditLimit) && (
          <Container name="realMonthlyPayment" margin="1 0">
            <Text testId="real-monthly-payment">
              Your minimum monthly repayment will be{' '}
              <b data-hj-suppress>${calculateMonthlyRepayment(storeState.creditLimitAccepted)} at this limit</b>
            </Text>
          </Container>
        )}
    </>
  );
};
