import React from 'react';
import { useEventTracking } from 'react-event-tracker';
import { getDataLayerElements } from 'utils/getDataLayerElements';
import { DATADOG_SOURCE_SOURCE, useStoreValue } from 'store';

import { datadogRum } from '@datadog/browser-rum';
import { byCountry } from 'utils';
import { NZErrors } from './NZErrors';
import { AUErrors } from './AUErrors';

export class Boundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error) {
    this.props && this.props.onError && this.props.onError(error);
  }

  render() {
    if (this.state.hasError && process.env.REACT_APP_ENV !== 'dev') {
      return byCountry({
        AU: <AUErrors />,
        NZ: <NZErrors />,
      });
    }
    return this.props.children;
  }
}

export const ErrorBoundary = ({ children }) => {
  const [state] = useStoreValue();
  const { trackEvent } = useEventTracking();

  const handleOnError = error => {
    trackEvent({
      event: {
        category: 'application',
        action: 'application-error',
        location: window.location.pathname,
        label: 'Runtime application error',
      },
      ...getDataLayerElements({
        ...state,
        applicationErrors: error,
        applicationOutcome: 'Error',
      }),
    });
    datadogRum.addError(error, undefined, DATADOG_SOURCE_SOURCE);

    if (process.env.REACT_APP_ENV !== 'prod') {
      // eslint-disable-next-line
      console.warn(error);
    }
  };

  return <Boundary onError={handleOnError}>{children}</Boundary>;
};
