import { byCountry, getProductInfo } from './byConfig';

export const getUploadUrl = ({ firstName, lastName, applicationRef, defaultDocumentUploadUrl }) => {
  const cardType = getProductInfo().docUploadCardType;
  const params = `?cardType=${cardType}&firstName=${firstName}&lastName=${lastName}&appNum=${applicationRef}&empStatus=Permanent%20Full%20Time`;

  const url = byCountry({
    AU: defaultDocumentUploadUrl || `https://upload.gemfinance.co.nz/fileupload/income`,
    NZ: `https://upload.gemfinance.co.nz/fileupload/income`,
  });

  return `${url}${params}`;
};
