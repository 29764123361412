// enum of ApplicationOutcome/ApplicationStatus.
export const PENDING_CUSTOMER_ACCEPTANCE = 'PENDING_CUSTOMER_ACCEPTANCE';
export const PROCESSING_ACCEPTANCE = 'PROCESSING_ACCEPTANCE';
export const BIOMETRICS_STARTED = 'BIOMETRICS_STARTED';
export const BIOMETRICS_COMPLETED = 'BIOMETRICS_COMPLETED';
export const BIOMETRICS_CANCELLED = 'BIOMETRICS_CANCELLED';

export const APPLICATION_NOT_FOUND = 'APPLICATION_NOT_FOUND';
export const CREATED = 'CREATED';
export const SAVED = 'SAVED';
export const PENDING = 'PENDING';
export const APPROVED = 'APPROVED';
export const DECLINED = 'DECLINED';
export const REFERRED = 'REFERRED';
export const SERVICE_UNAVAILABLE = 'SERVICE_UNAVAILABLE';
export const CONDITIONALLY_APPROVED = 'CONDITIONALLY_APPROVED';
export const POI_CHECK_REQUIRED = 'POI_CHECK_REQUIRED';
export const POI_CHECK_PENDING = 'POI_CHECK_PENDING';
export const IDV_CHECK_PENDING = 'IDV_CHECK_PENDING';
export const IDV_CHECK_REQUIRED = 'IDV_CHECK_REQUIRED';
export const PROOF_OF_ADDRESS = 'PROOF_OF_ADDRESS';
export const ANTI_MONEY_LAUNDERING = 'ANTI_MONEY_LAUNDERING';
export const PROOF_OF_INCOME = 'PROOF_OF_INCOME';
export const ANTI_MONEY_LAUNDERING_AND_PROOF_OF_INCOME = 'ANTI_MONEY_LAUNDERING_AND_PROOF_OF_INCOME';
export const PROOF_OF_ADDRESS_AND_PROOF_OF_INCOME = 'PROOF_OF_ADDRESS_AND_PROOF_OF_INCOME';
export const PROCESSING_TIMEOUT = 'PROCESSING_TIMEOUT';
export const BIOMETRICS_CHECK_REQUIRED = 'BIOMETRICS_CHECK_REQUIRED';
export const POI_BIO_CHECK_REQUIRED = 'POI_BIO_CHECK_REQUIRED';
export const CCR_REQUIRED = 'CCR_REQUIRED';
export const CCR_PENDING = 'CCR_PENDING';
export const QUOTE_INITIAL = 'QUOTE_INITIAL';
export const QUOTE_DECLINE = 'QUOTE_DECLINE';

export const AUTO_LOAN = 'AUTO_LOAN';
export const CREDIT_CARD = 'CREDIT_CARD';
export const HOUSING_COSTS = 'HOUSING_COSTS';
export const HOME_LOAN = 'HOME_LOAN';
export const OTHER = 'OTHER';
export const PERSONAL_LOAN_FIXED_TERM = 'PERSONAL_LOAN_FIXED_TERM';
export const PERSONAL_LOAN_REVOLVING = 'PERSONAL_LOAN_REVOLVING';
export const CUSTOMER_SELECTED = 'CUSTOMER_SELECTED';

/**
 * List of application statuses that are temporary and waiting for a trigger of update from the backend.
 * UI typically shows a spinner page while on these statuses.
 */
export const TEMPORARY_STATUSES = [PENDING, POI_CHECK_PENDING, IDV_CHECK_PENDING, CCR_PENDING, PROCESSING_ACCEPTANCE];

// API Errors
export const UNKNOWN_API_ERROR = 'UNKNOWN_ERROR';

export const APOLLO_NETWORK_ERROR = 'APOLLO_NETWORK_ERROR';
export const APOLLO_GRAPHQL_ERROR = 'APOLLO_GRAPHQL_ERROR';
export const APOLLO_UNKNOWN_ERROR = 'APOLLO_UNKNOWN_ERROR';

export const AXIOS_NETWORK_ERROR = 'AXIOS_NETWORK_ERROR';
export const AXIOS_NO_RESPONSE_ERROR = 'AXIOS_NO_RESPONSE_ERROR';
export const AXIOS_UNKNOWN_ERROR = 'AXIOS_UNKNOWN_ERROR';

export const OKTA_LOGIN_AND_SEND_OTP_ERROR = 'OKTA_LOGIN_AND_SEND_OTP_ERROR';
export const OKTA_OTP_TOO_OFTEN_ERROR = 'OKTA_OTP_TOO_OFTEN_ERROR';
export const OKTA_SYNTHETIC_TOKEN_ERROR = 'OKTA_SYNTHETIC_TOKEN_ERROR';
export const OKTA_VERIFICATION_ERROR = 'OKTA_VERIFICATION_ERROR';
export const OKTA_VERIFICATION_LOCKED_ERROR = 'OKTA_VERIFICATION_LOCKED_ERROR';
export const OKTA_VERIFICATION_CODE_INCORRECT_ERROR = 'OKTA_VERIFICATION_CODE_INCORRECT_ERROR';
export const OKTA_EMPTY_MOBILE_NUMBER_ERROR = 'OKTA_EMPTY_MOBILE_NUMBER_ERROR';
export const OKTA_ERROR = 'OKTA_ERROR';

export const OKTA_ERROR_CODE_INVALID_PASSCODE_FOR_INCORRECT_OTP = 'E0000068';
export const OKTA_ERROR_CODE_INVALID_TOKEN_FOR_EXPIRED_OTP = 'E0000011';
export const OKTA_ERROR_CODE_SMS_WAIT_30_SECS = 'E0000109';
export const OKTA_ERROR_CODE_CAN_ONLY_BE_USED_ONCE = 'E0000082';
export const OKTA_ERROR_SESSION_EXPIRED_MESSAGE = 'invalid okta token';

export const GQL_AXIOS_API_ERRORS = [
  APOLLO_GRAPHQL_ERROR,
  APOLLO_NETWORK_ERROR,
  APOLLO_UNKNOWN_ERROR,
  AXIOS_NETWORK_ERROR,
  AXIOS_NO_RESPONSE_ERROR,
  AXIOS_UNKNOWN_ERROR,
];

export const OKTA_ERRORS = [
  OKTA_LOGIN_AND_SEND_OTP_ERROR,
  OKTA_SYNTHETIC_TOKEN_ERROR,
  OKTA_VERIFICATION_LOCKED_ERROR,
  OKTA_VERIFICATION_ERROR,
  OKTA_OTP_TOO_OFTEN_ERROR,
  OKTA_VERIFICATION_CODE_INCORRECT_ERROR,
  OKTA_ERROR,
];

export const API_ERRORS = GQL_AXIOS_API_ERRORS.concat(OKTA_ERRORS);

export const DATADOG_NETWORK_SOURCE = 'network';
export const DATADOG_CUSTOM_SOURCE = 'custom';
export const DATADOG_SOURCE_SOURCE = 'source';

export const ERROR_KIND_USER_ERROR = 'user-error';
export const ERROR_KIND_SYSTEM_ERROR = 'system-error';

export const SHARE_BANK_STATEMENT = 'Share bank statement';
export const ONLINE_BANK_CONNECT = 'Online Bank Connect';
export const UPLOAD_PAYSLIPS = 'Submit application & provide payslips';
export const PROVIDE_PAYSLIPS = 'Provide payslips';
export const UPLOAD_DOCUMENTS = 'Submit application & provide documents';
export const UPLOAD_YOUR_DOCUMENTS = 'Upload your documents';
export const PROVIDE_DOCUMENTS = 'Provide documents';
export const POITYPE_BANK_CONNECT = 'POITYPE_BANK_CONNECT';
export const POITYPE_PAYSLIP_UPLOAD = 'POITYPE_PAYSLIP_UPLOAD';
export const POITYPE_DOC_UPLOAD = 'POITYPE_DOC_UPLOAD';
export const DOC_UPLOAD_APPLICATION_PROCESS_TIME_IN_SEC = 300;
export const UPLOAD_DOC_SUPPORTED_STATUS = [
  PROOF_OF_INCOME,
  ANTI_MONEY_LAUNDERING_AND_PROOF_OF_INCOME,
  PROOF_OF_ADDRESS_AND_PROOF_OF_INCOME,
];

export const OKTA_EMAIL_FACTOR = 'email';
export const OKTA_SMS_FACTOR = 'sms';
