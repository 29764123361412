import { HOME_LOAN, OTHER } from 'store';

export const debtCategoryOptions = [
  { value: HOME_LOAN, label: 'Home loan' },
  { value: OTHER, label: 'Other loan' },
];

export const FIELD_DISCLOSED_DEBTS = 'disclosedDebts';
export const FIELD_ADDITIONAL_LOANS = 'additionalLoans';

export const NON_HIDDEN_DEBT_CATEGORIES = ['AUTO_LOAN', 'PERSONAL_LOAN_FIXED_TERM', 'HOME_LOAN'];
