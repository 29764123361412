import React from 'react';
import styled from '@emotion/styled';

const StyledText = styled.span`
  color: ${props => props.theme.a.color};
  text-decoration: underline;
  cursor: pointer;
`;

export const ActionLink = ({ testId, onClick, children }) => {
  return (
    <StyledText data-testid={testId} onClick={onClick}>
      {children}
    </StyledText>
  );
};
