import React from 'react';
import { ValidateInput } from 'components/validate-input/ValidateInput';
import { DLNumberHelpText } from './DLNumberHelpText';

const DLNumber = ({ validates, formName, keyName, ...props }) => {
  return (
    <ValidateInput
      {...props}
      formName={formName}
      keyName={keyName}
      helpText={<DLNumberHelpText />}
      validates={validates}
    />
  );
};

export { DLNumber };
