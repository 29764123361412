import React from 'react';
import { Container } from 'basis';
import { HeroImg, ImageContainer } from './Hero.style';

export function Hero({ src, srcMobile, alt }) {
  return (
    <Container>
      <ImageContainer>
        <HeroImg src={src} srcMobile={srcMobile} alt={alt} />
      </ImageContainer>
    </Container>
  );
}
