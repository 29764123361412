import React from 'react';
import { LayoutPage, FormRenderer, LayoutContent, Errors } from 'components';
import { Container, Button, Message, Form } from 'basis';
import { submitHandler } from 'utils';
import { useStoreValue } from 'store';
import { useHistory } from 'react-router-dom';
import { useEventTracking } from 'react-event-tracker';
import { useAcceptPcaContract, useErrorTrackingAndUpdateStore } from 'hooks';
import { DECLINED } from 'store/constants';
import { confirmCreditLimitEvent } from './dataLayer';

export const QuoteDeclined = ({ FORM_ID, initialValues, formConfig }) => {
  const [storeState, updateStore] = useStoreValue();
  const history = useHistory();
  const { trackEvent } = useEventTracking();

  const { handleErrorCallback } = useErrorTrackingAndUpdateStore('Submit');

  const handleAcceptSuccess = () => {
    updateStore({
      applicationErrors: null,
      activeStep: '/outcome',
      applicationStatus: DECLINED,
    });
    trackEvent(confirmCreditLimitEvent);
    history.push('/outcome');
  };

  const { retry, submit: accept, loading: accepting } = useAcceptPcaContract({
    applicationId: storeState.applicationId,
    onSuccess: handleAcceptSuccess,
    onError: handleErrorCallback,
  });

  const onSubmit = ({ values, errors }) => {
    function submit() {
      updateStore({
        activeStep: '/processing-acceptance',
        creditLimitAccepted: parseInt(values.newCreditLimit, 10),
      });
      accept({
        accepted: false,
        acceptedCreditLimit: parseInt(values.newCreditLimit, 10),
      });
    }
    submitHandler({ submit, values, errors });
  };

  if (storeState.applicationErrors) {
    return (
      <LayoutPage>
        <Errors applicationRef={storeState.applicationRef} retry={retry} retrying={accepting} />
      </LayoutPage>
    );
  }

  return (
    <LayoutPage>
      <Container margin="0 0 13 0" hasBreakpointWidth>
        <Form
          id={FORM_ID}
          initialValues={initialValues}
          onSubmit={onSubmit}
          submitButtonLabel="Next"
          showSaveDraftButton
          saveDraftOnSubmit
          saveDraftOnSessionTimeout
        >
          {formData => {
            return (
              <>
                <LayoutContent rowsGap="0" noSessionTimeout>
                  <Container margin="8 0 0 0">
                    <FormRenderer config={formConfig} formData={formData} />
                  </Container>
                  <Button margin="8 0 0 0" type="submit" loading={accepting}>
                    Confirm credit limit
                  </Button>
                  <Container margin="5 0 0 0">
                    <Message margin="8 0 0 0" severity="info-or-minor" bg="secondary.lightBlue.t25">
                      You can always contact us in the future to request a decrease or increase to your credit limit if
                      your circumstances change.
                    </Message>
                  </Container>
                </LayoutContent>
              </>
            );
          }}
        </Form>
      </Container>
    </LayoutPage>
  );
};
