import groupLFrameCardImage from 'images/group-lframe-card.png';
import groupLFrameCardImageMobile from 'images/group-lframe-card-mobile.png';
import groupLFrameCardImageNZ from 'images/hero-NZ.png';
import groupLFrameCardImageMobileNZ from 'images/hero-mobile-NZ.png';

export const OutcomeContent = {
  AU: {
    groupLFrameCardImage,
    groupLFrameCardImageMobile,
  },
  NZ: {
    groupLFrameCardImage: groupLFrameCardImageNZ,
    groupLFrameCardImageMobile: groupLFrameCardImageMobileNZ,
  },
};
