import React, { useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Icon } from 'basis';
import { useKeyPress } from 'hooks/useKeyPress';
import * as s from './Modal.styles';
import { useFocusLock, usePortalElement, useScrollLock } from './utils';

export const Modal = ({ isOpen, onClose, children, style, disableExitShortcuts = false }) => {
  const escapePressed = useKeyPress('Escape');
  const node = usePortalElement(isOpen);
  useScrollLock(isOpen);
  useFocusLock(isOpen, node);

  useEffect(() => {
    if (escapePressed && isOpen && !disableExitShortcuts) {
      onClose();
    }
  }, [escapePressed, onClose, disableExitShortcuts, isOpen]);

  const onClickBackdrop = useCallback(() => {
    if (!disableExitShortcuts) {
      onClose();
    }
  }, [disableExitShortcuts, onClose]);

  if (!node || !isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <s.ModalContainer data-testid="ModalContainer">
      <s.ModalBackdrop data-testid="ModalBackdrop" onClick={onClickBackdrop} />
      <s.ModalWrapper>
        <s.Modal style={style} data-testid="Modal">
          {children}
          {!disableExitShortcuts && (
            <s.ModalClose data-testid="ModalClose" type="button" onClick={onClose}>
              <Icon name="cross" color="grey.t75" />
            </s.ModalClose>
          )}
        </s.Modal>
      </s.ModalWrapper>
    </s.ModalContainer>,
    node,
  );
};
