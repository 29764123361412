import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useFormCache } from 'hooks';

export const RouteScrollHandler = () => {
  const location = useLocation();
  const { getSubmitRequired } = useFormCache();

  useEffect(() => {
    if (!getSubmitRequired()) {
      const element = document.getElementById(location.hash);

      setTimeout(() => {
        window.scrollTo({
          top: element ? element.offsetTop : 0,
        });
      }, 100);
    }
  }, [location, getSubmitRequired]);

  return null;
};
