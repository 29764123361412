import React, { useCallback } from 'react';
import { FieldMoneyInput, FieldInput, FieldSelect } from 'components';
import { Divider, Button } from '@chakra-ui/react';
import { Stack, Text } from 'basis';
import { AddIcon, CloseIcon } from '@chakra-ui/icons';
import { useFieldArray } from 'react-hook-form';
import { useDataLayerAndAnalyticsLinks } from 'hooks';
import { HOME_LOAN, useStoreValue } from 'store';
import { FIELD_ADDITIONAL_LOANS, debtCategoryOptions } from '../constants';
import { HomeLoanCheckbox } from './Fields';

export function AdditionalLoans({ isWithMortgage, watch }) {
  const { fields: additionalLoans, append, remove } = useFieldArray({
    name: FIELD_ADDITIONAL_LOANS,
  });
  const [storeState] = useStoreValue();
  const { trackEventSender } = useDataLayerAndAnalyticsLinks(storeState);

  const addLoanButtonLabel = 'Add additional loan';
  const removeLoanButtonLabel = 'Remove';

  const onAddAdditionalLoan = useCallback(() => {
    append({});

    trackEventSender({
      label: addLoanButtonLabel,
      location: window.location.pathname.slice(1),
      category: 'application',
      action: 'application-navigation',
    });
  }, [append, trackEventSender]);

  const onRemoveLoan = index => {
    remove(index);

    trackEventSender({
      label: removeLoanButtonLabel,
      location: window.location.pathname.slice(1),
      category: 'application',
      action: 'application-navigation',
    });
  };

  return (
    <Stack gap="6">
      <Button
        onClick={onAddAdditionalLoan}
        leftIcon={<AddIcon />}
        colorScheme="blue"
        variant="ghost"
        fontSize={16}
        textDecoration="underline"
        padding="0"
      >
        {addLoanButtonLabel}
      </Button>
      {additionalLoans.map((loan, index) => (
        <Stack gap="6">
          {index === 0 && <Divider marginBottom="6" />}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Text as="span" textStyle="heading5">
              Additional loan {index + 1}
            </Text>
            <Button
              colorScheme="red"
              variant="ghost"
              textDecoration="underline"
              rightIcon={<CloseIcon />}
              onClick={() => {
                onRemoveLoan(index);
              }}
            >
              {removeLoanButtonLabel}
            </Button>
          </div>
          <FieldSelect
            id={`${FIELD_ADDITIONAL_LOANS}.${index}.category`}
            label="What is the loan type?"
            options={debtCategoryOptions}
            rules={{
              required: 'Please select a loan type',
            }}
          />
          <HomeLoanCheckbox
            id={`${FIELD_ADDITIONAL_LOANS}.${index}.isPrimaryResidencyAssociated`}
            conditional={isWithMortgage && watch(`${FIELD_ADDITIONAL_LOANS}.${index}.category`) === HOME_LOAN}
          />
          <FieldInput
            id={`${FIELD_ADDITIONAL_LOANS}.${index}.creditProvider`}
            label="Loan provider"
            rules={{
              required: 'Please enter the loan provider',
              maxLength: { value: 190, message: 'Must be less than 190 characters' },
            }}
          />
          <FieldMoneyInput id={`${FIELD_ADDITIONAL_LOANS}.${index}.currentLimit`} label="Loan balance" />
          <FieldMoneyInput id={`${FIELD_ADDITIONAL_LOANS}.${index}.monthlyRepayment`} label="Monthly repayment" />
          {index + 1 !== additionalLoans.length && <Divider marginBottom="6" />}
        </Stack>
      ))}
    </Stack>
  );
}
