import { useEffect, useState } from 'react';
import { DEV_FEATURES } from '__dev/devFeatures';

/**
 * A react hook to detect adblockers.
 * @returns [isAdblockCheckComplete, isAdblockEnabled]
 */
export const useAdblockDetector = url => {
  const [isAdblockDetected, setIsAdblockDetected] = useState(false);
  const [isAdblockCheckComplete, setIsAdblockCheckComplete] = useState(false);

  useEffect(() => {
    if (DEV_FEATURES.DISABLE_ADBLOCKER_DETECTION) {
      setIsAdblockDetected(false);
      setIsAdblockCheckComplete(true);
    } else {
      fetch(url, {
        method: 'HEAD',
        mode: 'no-cors',
        cache: 'no-store',
      })
        .then(res => {
          setIsAdblockDetected(false);
          setIsAdblockCheckComplete(true);
        })
        .catch(() => {
          setIsAdblockDetected(true);
          setIsAdblockCheckComplete(true);
        });
    }
  }, [url]);

  return [isAdblockCheckComplete, isAdblockDetected];
};
