import React from 'react';
import { APPROVED, CONDITIONALLY_APPROVED, DECLINED } from 'store/constants';
import { Text, Container, List } from 'basis';
import { PhoneNumberLink } from 'components';

function AppleApplicantsBox({ storeState }) {
  const { applicationStatus, gemVisaAccountNumber } = storeState;
  if (applicationStatus === APPROVED) {
    return (
      <Container bg="secondary.lightBlue.t15" hasBorder margin="0 5 0 5" margin-md="8 10">
        <Container bg-md="white" padding="5 5 0 5" padding-md="10 10 0 10">
          <Text textStyle="heading4" textStyle-md="heading5" padding="4">
            How to place your order at Apple?
          </Text>
        </Container>

        <Container bg-md="white" padding="5" padding-md="10">
          <List type="steps">
            <List.Item testId="pleaseCallApple">
              Please call Apple on 0800 MY APPLE (<PhoneNumberLink id="appleMerchant" />) to place your order.
            </List.Item>
            <List.Item testId="mobilePhoneAccountNumber">
              You will need your mobile phone on hand & your account number{' '}
              <span data-hj-suppress>{gemVisaAccountNumber}</span>.
            </List.Item>
          </List>
        </Container>
      </Container>
    );
  }

  if (applicationStatus === DECLINED) {
    return (
      <Container>
        <Container padding="6 0">
          <Text textStyle="subtitle2" textStyle-md="subtitle2">
            <b>Would you like to continue your Apple purchase?</b>
          </Text>
        </Container>
        <Container padding="0 0 4 0">Apple offers a number of alternative payment methods including:</Container>
        <Container>
          <List>
            <List.Item>Credit card(Visa, Mastercard, American Express)</List.Item>
            <List.Item>Debit card</List.Item>
            <List.Item>Bank transfer</List.Item>
          </List>
        </Container>
        <Container padding="6 0 0 0">
          <Text textStyle="body1" padding="4">
            If you would like to continue with your Apple purchase using one of the above payment methods, please call{' '}
            <PhoneNumberLink id="appleMerchant" /> and select option 1.
          </Text>
        </Container>
      </Container>
    );
  }
  if (applicationStatus === CONDITIONALLY_APPROVED) {
    return (
      <Container>
        <Container padding="6 0">
          <Text textStyle="heading6" textStyle-md="heading5">
            How to place your order at Apple
          </Text>
        </Container>
        <Container>
          <Text>
            Once your application is fully approved, an Apple Expert should then call you within 2 business hours to
            complete your order. If you have any questions in relation to your purchase please call Apple on{' '}
            <PhoneNumberLink id="appleMerchant" />.
          </Text>
        </Container>
      </Container>
    );
  }

  return null;
}

export { AppleApplicantsBox };
