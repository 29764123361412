import React from 'react';
import { Modal } from 'components/modal/Modal';
import { Button, Container, Flex, Text, Link, List } from 'basis';
import { OverviewSources } from '_config';
import { getRatesAndFees } from 'featureToggles';

export const AuGemFeaturesAndBenefits = ({ isOpen, onClose, getLinkWithAnalytics, trackEventSender }) => {
  const { purchaseAnnualRate, cashAnnualRate, expiredPromotionalPurchaseRate, monthlyFee } = getRatesAndFees();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Container padding="6" padding-md="8">
        <Container padding="0 0 4">
          <Flex placeItems="left center">
            <Text textStyle="heading6" textStyle-md="heading4">
              Credit card features and benefits
            </Text>
          </Flex>
        </Container>
        <Container>
          Please ensure the{' '}
          <Link
            href={getLinkWithAnalytics(OverviewSources.AU.latitude_gem_visa)}
            newTab
            onClick={() => trackEventSender(OverviewSources.AU.latitude_gem_visa)}
          >
            {OverviewSources.AU.latitude_gem_visa.label}
          </Link>{' '}
          credit card is right for you.
        </Container>
        <Container padding="6 0" margin="4 0 0 0">
          <List>
            <List.Item>
              <Text textStyle="body1">
                Enjoy 6 months interest free on all purchases $250 and over and up to 55 days interest free on everyday
                purchases under $250
              </Text>
            </List.Item>
            <List.Item>
              <Text textStyle="body1">
                Interest free offers available from 6 to 60 months at thousands of Australia's leading retailers
              </Text>
            </List.Item>
            <List.Item>
              <Text textStyle="body1">
                Get exclusive deals, discounts and offers by simply using your Latitude Gem Visa credit card at home and{' '}
                overseas
              </Text>
            </List.Item>
            <List.Item>
              <Text textStyle="body1">
                Interest Rates - {purchaseAnnualRate?.toFixed(2)}% p.a. interest rate on everyday purchases,{' '}
                {cashAnnualRate?.toFixed(2)}% p.a. on cash advances and {expiredPromotionalPurchaseRate?.toFixed(2)}%{' '}
                p.a on expired Interest Free Payment Plans
              </Text>
            </List.Item>
            <List.Item>
              Account Fee - ${monthlyFee.toFixed(2)} monthly account service fee (see other{' '}
              <Link
                newTab
                href={getLinkWithAnalytics({
                  label: 'Rates and Fees',
                  url: OverviewSources.AU.latitude_gem_visa.url,
                })}
                onClick={() =>
                  trackEventSender({
                    location: 'landing',
                    label: 'Rates and Fees',
                  })
                }
              >
                Rates and Fees
              </Link>{' '}
              here)
            </List.Item>
            <List.Item>
              <Text textStyle="body1">
                Safe and secure - Shop knowing that your card and account are protected by stringent security systems
              </Text>
            </List.Item>
            <List.Item>
              Before making a decision, please read the{' '}
              <Link
                newTab
                href={getLinkWithAnalytics(OverviewSources.AU.key_facts_sheet)}
                onClick={() =>
                  trackEventSender({
                    location: 'landing',
                    label: OverviewSources.AU.key_facts_sheet.label,
                  })
                }
              >
                {OverviewSources.AU.key_facts_sheet.label}
              </Link>{' '}
              and{' '}
              <Link
                newTab
                href={OverviewSources.AU.gem_conditions_of_use.url}
                onClick={() =>
                  trackEventSender({
                    location: 'landing',
                    label: OverviewSources.AU.gem_conditions_of_use.label,
                  })
                }
              >
                {OverviewSources.AU.gem_conditions_of_use.label}
              </Link>{' '}
              to ensure you're aware of all the fees, charges and interest rates.
            </List.Item>
          </List>
        </Container>
        <Container padding="4 0">
          <Text>
            To take advantage of up to 55 days interest free on everyday credit card purchases, you need to pay the full
            closing balance (excluding un-expiring interest payment plans) on each statement of account by the
            applicable due date.
          </Text>
        </Container>

        <Flex placeItems="center">
          <Container padding="2">
            <Button width="160px" onClick={onClose}>
              Close
            </Button>
          </Container>
        </Flex>
      </Container>
    </Modal>
  );
};
