import React from 'react';
import { Modal } from 'components/modal/Modal';
import { Button, Container, Flex, List, Text, Link } from 'basis';
import {
  PRODUCT_CODE_DAVID_JONES_PREMIERE,
  PRODUCT_CODE_DAVID_JONES_PRESTIGE,
  ProductComparisonLinks,
  config,
} from '_config';
import { EarnRateTiers } from 'components';
import styled from '@emotion/styled';
import { ListWrapper } from './LandingPageDJ.styles';

const modalContent = {
  [PRODUCT_CODE_DAVID_JONES_PREMIERE]: [
    {
      subtitle: 'David Jones exclusive offers and events',
      description: 'Enjoy invitations to exclusive events, designer previews and offers at David Jones.',
    },
    {
      subtitle: 'Pay With Points',
      description: 'Use your David Jones Points to purchase your favourite items online at David Jones.',
    },
    {
      subtitle: 'Complimentary express delivery',
      description: 'Enjoy your purchases sooner with complimentary express delivery. ',
    },
    {
      subtitle: 'Complimentary gift wrapping',
      description: 'Enjoy complimentary gift wrapping on your purchases at David Jones when you use your credit card.',
    },
  ],
  [PRODUCT_CODE_DAVID_JONES_PRESTIGE]: [
    {
      subtitle: 'David Jones exclusive offers and events',
      description: 'Enjoy invitations to exclusive events, designer previews and offers at David Jones.',
    },
    {
      subtitle: 'Pay With Points',
      description: 'Use your David Jones Points to purchase your favourite items online at David Jones.',
    },
    {
      subtitle: 'Complimentary delivery and gift wrapping',
      description:
        'Enjoy your purchases sooner with complimentary express or same day delivery. Also enjoy complimentary gift wrapping on your purchases at David Jones.',
    },
    {
      subtitle: 'Travel in style',
      description:
        'Make your travel more luxurious with a range of travel benefits including discounts, airport lounge passes and complimentary travel insurance.',
    },
    {
      subtitle: 'Tailored Experiences',
      description: 'Get the perfect fit each time you shop at David Jones with complimentary instore alterations.',
    },
    {
      subtitle: 'Share your world',
      description:
        'Invite a family member into your world of luxury by giving them an additional card with no extra fees.',
    },
    {
      subtitle: 'Personal Styling Session',
      description:
        'Receive a $150 David Jones gift card each year when you spend over $1000 in our luxury Personal Styling Suites.',
    },
  ],
};

const RateWrapper = styled.div`
  ul {
    margin: 0 !important;
  }

  li::before {
    width: 0.25em;
    height: 0.25em;
  }
`;

const FeaturesAndBenefitsModal = ({ isOpen, onClose, trackEventSender }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Container padding="3" padding-md="6">
        <Container padding="4 2 2">
          <Text textStyle="heading4" textStyle-md="heading4">
            {config.productName} credit card features and benefits
          </Text>
        </Container>

        <Container padding="2 2 8">
          <Text>
            Please ensure the{' '}
            <Link
              newTab
              href={
                config.productCode === PRODUCT_CODE_DAVID_JONES_PRESTIGE
                  ? ProductComparisonLinks.dj_prestige_compare.url
                  : ProductComparisonLinks.dj_premiere_compare.url
              }
              onClick={() => trackEventSender()}
            >
              {config.productName} credit card
            </Link>{' '}
            is right for you.
          </Text>
        </Container>
        <ListWrapper>
          <List>
            <List.Item>
              <Container padding="0 3 3 0">
                <Text>
                  <strong>Earn and redeem David Jones Points</strong>
                </Text>
                <Text>Savour more of the finer things by earning David Jones Points -</Text>
                <RateWrapper>
                  <EarnRateTiers productCode={config.productCode} />
                </RateWrapper>
                <Text>Redeem your points through the David Jones Points Hub for gift cards or Points for credit.</Text>
              </Container>
            </List.Item>
            {modalContent[config.productCode]?.map((item, index) => {
              return (
                <List.Item key={index}>
                  <Container padding="0 3 3 0">
                    <Text testId={`textSubtitle-${index}`}>
                      <strong>{item.subtitle}</strong>
                    </Text>
                    <Text testId={`textDescription-${index}`}>{item.description}</Text>
                  </Container>
                </List.Item>
              );
            })}
          </List>
        </ListWrapper>

        <Flex placeItems="center">
          <Container padding="2 2 4 2">
            <Button width="160px" onClick={onClose}>
              Close
            </Button>
          </Container>
        </Flex>
      </Container>
    </Modal>
  );
};

export { FeaturesAndBenefitsModal };
