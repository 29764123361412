export const OptionUser = 'user';
export const OptionSystem = 'system';

export const creditLimitSourceOptions = [
  {
    value: OptionSystem,
    label: 'Offer me a limit based on the information in my application',
  },
  {
    value: OptionUser,
    label: 'I would like to choose a specific credit limit',
  },
];
