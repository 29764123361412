import styled from '@emotion/styled';
import { device } from 'media';

export const EIDVFailureMessage = styled.div`
  @media ${device.mobile} {
    & img {
      width: 340px;
      height: 224px;
    }
  }
`;
