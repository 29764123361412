/* eslint-disable consistent-return */
import React, { useEffect } from 'react';
import { Text } from 'basis';
import { useStoreValue, POITYPE_BANK_CONNECT } from 'store';
import { LayoutPage, LayoutContent, FormRenderer, Form } from 'components';
import { useSteps, useErrorTrackingAndUpdateStore, useSubmitSavedApplication } from 'hooks';
import { submitHandler } from 'utils';
import { PENDING } from 'store/constants';
import { isEligibleForBankConnect } from '_config/_constants';
import { formatNumber } from 'utils/formatNumber';
import { getInitialValues, formConfig } from './formConfig';

export const ReplayIncome = () => {
  const [storeState, updateStore] = useStoreValue();
  const { goNext } = useSteps();
  const { handleErrorCallback } = useErrorTrackingAndUpdateStore();
  const { submit: submitApplication } = useSubmitSavedApplication({
    storeState,
    onError: handleErrorCallback,
    onSuccess: data => {
      goNext({
        storePatch: {
          applicationStatus: PENDING,
          applicationErrors: null,
        },
      });
    },
  });

  useEffect(() => {
    if (!isEligibleForBankConnect(storeState.acquisition.employmentDetails.employmentStatus)) {
      // Skip due to the selected employment status is not supported by Bank Connect and the user should have already provided income amount information on the employment page.
      submitApplication({
        incomeVerificationType: storeState.incomeVerificationType,
        income: storeState.acquisition.employmentDetails.income,
        otherIncome: storeState.acquisition.employmentDetails.otherIncome,
        expectedIncomeChange: storeState.acquisition.employmentDetails.expectedIncomeChange,
      });
    }
  });

  const initialValues = getInitialValues(storeState);

  const onSubmit = ({ values, errors }) => {
    submitHandler({
      errors,
      submit: () => {
        // Update data layer
        updateStore({
          acquisition: {
            ...storeState.acquisition,
            employmentDetails: {
              ...storeState.acquisition.employmentDetails,
              income: values.income,
              otherIncome: values.otherIncome,
              expectedIncomeChange: values.expectedIncomeChange,
            },
          },
        });

        // Submit application to Activate
        submitApplication({
          incomeVerificationType: storeState.incomeVerificationType,
          income: values.income,
          otherIncome: values.otherIncome,
          expectedIncomeChange: values.expectedIncomeChange,
        });
      },
    });
  };

  return (
    <LayoutPage noSessionTimeout>
      <Form
        id="replayIncome"
        key="replayIncome"
        submitButtonLabel="Next"
        initialValues={initialValues}
        onSubmit={onSubmit}
        hideFormButtons={false}
        showBackButton={storeState.incomeVerificationType !== 'POITYPE_BANK_CONNECT'}
        disableFormCache
      >
        {formData => {
          return (
            <LayoutContent>
              {VerifiedIncome(storeState)}
              <FormRenderer config={formConfig} formData={formData} />
            </LayoutContent>
          );
        }}
      </Form>
    </LayoutPage>
  );
};

const VerifiedIncome = storeState => {
  if (storeState.incomeVerificationType !== POITYPE_BANK_CONNECT) {
    return <Text>Please provide your primary and secondary income amounts separately below.</Text>;
  }

  if (
    storeState.incomeVerificationType === POITYPE_BANK_CONNECT &&
    (storeState.bankConnectCompleteStatus === 'no-income-selected' ||
      storeState.bankConnectCompleteStatus === 'no-income-found' ||
      storeState.bankConnectTotalIncomeNotShown === true ||
      storeState.bankConnectVerifiedIncome === 0)
  ) {
    return (
      <>
        <Text>Thanks for sharing your bank statement with us.</Text>
        <Text>As part of our regulatory obligations we now require you to Confirm your income with us.</Text>
        <Text>Please provide your primary and secondary income amounts separately below.</Text>
      </>
    );
  }

  if (storeState.incomeVerificationType === POITYPE_BANK_CONNECT && storeState.bankConnectVerifiedIncome !== 0) {
    return (
      <>
        <Text>This is what we've calculated that you earn per month, from what you selected.</Text>

        <Text as="h4" textStyle="heading4">
          ${formatNumber(storeState.bankConnectVerifiedIncome)}{' '}
          <strong style={{ fontSize: '16px' }}>per month, after tax</strong>
        </Text>
      </>
    );
  }
  return null;
};
