// NZ Specific employment Status
export const RENTAL_INCOME = 'RENTAL_INCOME';
export const INVESTMENT = 'INVESTMENT';
export const SELF_EMPLOYED = 'SELF_EMPLOYED';
export const CONTRACTOR_SELF_EMPLOYED = 'CONTRACTOR_SELF_EMPLOYED';
export const PERMANENT = 'PERMANENT';
export const CASUAL_SEASONAL_TEMPORARY = 'CASUAL_SEASONAL_TEMPORARY';
export const CONTRACTOR_FIXED_TERM = 'CONTRACTOR_FIXED_TERM';
export const SUPERANNUATION = 'SUPERANNUATION';
export const GOVERNMENT_BENEFIT = 'GOVERNMENT_BENEFIT';
export const ACC = 'ACC';

// Australia specific employment Status
export const CASUAL_TEMPORARY = 'CASUAL_TEMPORARY';
export const PERMANENT_PART_TIME = 'PERMANENT_PART_TIME';
export const PERMANENT_FULL_TIME = 'PERMANENT_FULL_TIME';
export const SELF_EMPLOYED_SUBCONTRACTOR = 'SELF_EMPLOYED_SUBCONTRACTOR';
export const CONTRACTOR_PART_TIME = 'CONTRACTOR_PART_TIME';
export const CONTRACTOR_FULL_TIME = 'CONTRACTOR_FULL_TIME';
export const WORKERS_COMPENSATION = 'WORKERS_COMPENSATION';
export const GOVERNMENT_BENEFITS = 'GOVERNMENT_BENEFITS';
export const UNEMPLOYED = 'UNEMPLOYED';
export const OTHER = 'OTHER';
export const SELF_FUNDED_RETIREES = 'SELF_FUNDED_RETIREES';
export const CHILD_SUPPORT = 'CHILD_SUPPORT';

// Australia specific income employment Status
export const PERMANENT_FULL_PART_TIME_FIXED_TERM = 'PERMANENT_FULL_PART_TIME_FIXED_TERM';
export const employmentStatusOptionsNZ = [
  {
    value: PERMANENT,
    label: 'Permanent full or part time',
  },
  {
    value: CASUAL_SEASONAL_TEMPORARY,
    label: 'Casual/Seasonal/Temporary',
  },
  {
    value: CONTRACTOR_FIXED_TERM,
    label: 'Contractor - fixed term',
  },
  {
    value: CONTRACTOR_SELF_EMPLOYED,
    label: 'Contractor - self employed',
  },
  {
    value: SELF_EMPLOYED,
    label: 'Self employed',
  },
  {
    value: SUPERANNUATION,
    label: 'Superannuation',
  },
  {
    value: GOVERNMENT_BENEFIT,
    label: 'Government Benefit',
  },
  {
    value: ACC,
    label: 'ACC',
  },
  {
    value: RENTAL_INCOME,
    label: 'Rental income',
  },
  {
    value: INVESTMENT,
    label: 'Investment',
  },
];

export const employmentStatusOptionsAU = [
  {
    value: CASUAL_TEMPORARY,
    label: 'Casual/Temporary',
  },
  {
    value: PERMANENT_PART_TIME,
    label: 'Permanent Part Time',
  },
  {
    value: PERMANENT_FULL_TIME,
    label: 'Permanent Full Time',
  },
  {
    value: SELF_EMPLOYED_SUBCONTRACTOR,
    label: 'Self-Employed/Subcontractor',
  },
  {
    value: CONTRACTOR_PART_TIME,
    label: 'Contractor Part Time',
  },
  {
    value: CONTRACTOR_FULL_TIME,
    label: 'Contractor Full Time',
  },
  {
    value: WORKERS_COMPENSATION,
    label: "Worker's Compensation",
  },
  {
    value: GOVERNMENT_BENEFITS,
    label: 'Government Benefits',
  },
  {
    value: UNEMPLOYED,
    label: 'Unemployed',
  },
  {
    value: OTHER,
    label: 'Other',
  },
];

export const employmentStatusLabelsByValueNZ = employmentStatusOptionsNZ.reduce((output, option) => {
  return {
    ...output,
    [option.value]: option.label,
  };
}, {});

export const incomeEmploymentStatusOptionsAU = [
  {
    value: PERMANENT_FULL_PART_TIME_FIXED_TERM,
    label: 'Permanent Full/Part Time & Fixed Term Contract',
  },
  {
    value: CASUAL_SEASONAL_TEMPORARY,
    label: 'Casual/Seasonal/Temporary',
  },
  {
    value: GOVERNMENT_BENEFITS,
    label: 'Government Benefits',
  },
  {
    value: SELF_EMPLOYED_SUBCONTRACTOR,
    label: 'Self-Employed/Subcontractor',
  },
  {
    value: RENTAL_INCOME,
    label: 'Rental income',
  },
  {
    value: SELF_FUNDED_RETIREES,
    label: 'Self-Funded Retirees',
  },
  {
    value: WORKERS_COMPENSATION,
    label: 'Workers Compensation',
  },
  {
    value: CHILD_SUPPORT,
    label: 'Child Support',
  },
];

export const isRentalOrInvestment = employmentStatus => {
  return employmentStatus === RENTAL_INCOME || employmentStatus === INVESTMENT;
};

export const isSelfEmployedByStatus = employmentStatus => {
  return [SELF_EMPLOYED, CONTRACTOR_SELF_EMPLOYED].includes(employmentStatus);
};

export const isSuperOrGov = employmentStatus => {
  return [SUPERANNUATION, GOVERNMENT_BENEFIT].includes(employmentStatus);
};

export const isEligibleForBankConnect = employmentStatus => {
  return [PERMANENT, CASUAL_SEASONAL_TEMPORARY, CONTRACTOR_FIXED_TERM, SUPERANNUATION, ACC].includes(employmentStatus);
};

export const isEligibleForBankConnectAu = employmentStatus => {
  return [
    CASUAL_TEMPORARY,
    PERMANENT_PART_TIME,
    PERMANENT_FULL_TIME,
    CONTRACTOR_PART_TIME,
    CONTRACTOR_FULL_TIME,
    GOVERNMENT_BENEFITS,
    OTHER,
  ].includes(employmentStatus);
};
