import React, { useEffect, useRef } from 'react';
import { Message, Input } from 'basis';

// interface FormValues {
//   [key: string]: string | number
// }

// type Config {
//   onMount?: (input: FormValues) => void,
//   onUnMount?: (input: FormValues) => void,
//   onValidate: (input: FormValues) => Boolean, // Return true if valid
// }

export const ConditionalStop = ({
  formData,
  name,
  testId,
  title,
  subtitle,
  onMount,
  onUnmount,
  onValidationChange,
  onValidate,
  severity = 'warning-or-significant',
}) => {
  // Prevent constant mount and unmount triggering
  const { values } = formData.state;
  const valuesRef = useRef(values);
  valuesRef.current = values;

  useEffect(() => {
    onMount && onMount(valuesRef.current);
    return () => onUnmount && onUnmount(valuesRef.current);
  }, [onMount, onUnmount]);

  const isValid = onValidate ? onValidate(values) : false;

  useEffect(() => {
    onValidationChange && onValidationChange(isValid, formData);
  });

  if (isValid) {
    return null;
  }

  return (
    <>
      <div style={{ display: 'none' }}>
        <Input name={name} label="" testId={`${testId}-input`} validate={() => title || 'Error message'} />
      </div>
      <Message testId={testId} severity={severity} bg="secondary.pink.t30">
        {title && <strong>{title}</strong>}
        {title && subtitle && <br />}
        {subtitle && <>{subtitle}</>}
      </Message>
    </>
  );
};
