export const UPLOAD_BTN_LABEL = 'Open document upload';

export const UPLOAD_PAYSLIPS = [
  {
    listTitle: `If your payslips clearly shows your current address, then you can just upload your payslips to verify both.
      We’ll need you to upload 2 consecutive payslips that clearly show:`,
    listItem: [
      'Your full name',
      'Your employer’s name',
      'That the most recent payment is dated within the last 31 day',
      'Shows net/gross income',
    ],
  },
];

export const PROOF_OF_ADDRESS_LIST = [
  {
    listTitle:
      'We need just one document from the following list, containing your name and address, from the last 6 months ',
    listItem: [
      'Utility bill',
      'Rates notice',
      'Bank statement or credit card statement',
      'Loan statement',
      'Home and contents insurance policy (12 months for annual policy)',
      'Government issued document',
      'Rental tenancy agreement ',
      'Property purchase and sale agreements',
      'Letter from educational institutions',
      'Kiwisaver statement',
    ],
  },
];

export const PROOF_OF_INCOME_LIST = [
  {
    listTitle: 'You can upload one of the following documents:',
  },
  {
    listTitle: 'Bank statement',
    listItem: [
      `Show your name and consecutive income payments over two months (send multiple statements if
    needed)`,
      'Most recent payment is dated within the last 60 days',
    ],
  },
  {
    listTitle: 'Accountant letter',
    listItem: ['Income details no more than 18 months old', 'Dated within the last 60 days'],
  },
  {
    listTitle: 'Tax return',
    listItem: [
      'Prepared by an Accountant',
      'End of Financial Year date within the last 18 months',
      'Include all pages',
    ],
  },
  {
    listTitle: 'Tax office Notice of Assessment',
    listItem: ['End of Financial Year date within the last 18 months'],
  },
  {
    listTitle: 'Superannuation statement',
    listItem: ['Issued within the last 12 months'],
  },
  {
    listTitle: 'Term deposit statement',
    listItem: ['Shows amount invested, future maturity date and annual interest rate'],
  },
  {
    listTitle: 'Rental income',
    listItem: [
      'Real estate agent’s letter or statement',
      'Shows the last 3 months rental income',
      'Dated within the last 60 days',
    ],
  },
  {
    listTitle: 'Current tenancy agreement',
    listItem: ['Shows at least 6 months remaining on the agreement', 'Countersigned by agent/landlord'],
  },
];

export const PROOF_OF_INCOME_LIST_ACCORDION = [
  {
    accordionTitle: 'Permanent full or part time workers',
    accordionData: [
      { description: 'To verify your permanent full or part time income, we require one of the following documents:' },
      {
        listTitle: 'Payslips(s)',
        listItem: [
          'Is a PDF, screenshot or photo of a full computer-generated payslip',
          'Shows your full name',
          "Your employer's name",
          'Most recent payment is dated within the last 31 days',
          'Includes minimum 2 months of year-to-date income or supply 2 consecutive payslips',
          'Shows either Net or Gross income and frequency of pay',
        ],
      },
      {
        listTitle: 'Bank Statement/Online Transaction History',
        listItem: [
          'Shows your full name',
          'Your bank, branch, account number & account name',
          'Two months of income payments, most recent income showing within the last 31 days',
          "Employer's name corresponding to the deposits",
        ],
      },
      {
        listTitle: 'Summary of Earnings issued by IRD',
        listItem: [
          'Is dated within the last 31 days',
          'Is for the previous 12 months',
          'Shows your full name',
          "Your employer's name",
        ],
      },
    ],
  },
  {
    accordionTitle: 'Casual, seasonal or temporary workers',
    accordionData: [
      {
        description: 'To verify your casual, seasonal or temporary income, we require one of the following documents:',
      },
      {
        listTitle: 'Payslips(s)',
        listItem: [
          'Is a PDF, screenshot or photo of a full computer-generated payslip',
          'Shows your full name',
          "Your employer's name",
          'Most recent payment is dated within the last 31 days',
          'Includes minimum 2 months of year-to-date income',
          'Shows either Net or Gross income and frequency of pay',
        ],
      },
      {
        listTitle: 'Bank Statement/Online Transaction History',
        listItem: [
          'Shows your full name',
          'Your bank, branch, account number & account name',
          'Two months of income payments, most recent income showing within the last 31 days',
          "Employer's name corresponding to the deposits",
        ],
      },
      {
        listTitle: 'Summary of Earnings issued by IRD',
        listItem: [
          'Is dated within the last 31 days',
          'Is for the previous 12 months',
          'Shows your full name',
          "Your employer's name",
        ],
      },
    ],
  },
  {
    accordionTitle: 'Fixed term contractors',
    accordionData: [
      {
        description: 'To verify your fixed term income, we require one of the following documents:',
      },
      {
        listTitle: 'Payslips(s)',
        listItem: [
          `Is a PDF, screenshot or photo of a full computer-generated payslip`,
          `Shows your full name`,
          `Your employer's name`,
          `Most recent payment is dated within the last 31 days`,
          `Includes minimum 2 months of year-to-date income or supply 2 consecutive payslips`,
          `Shows either Net or Gross income and frequency of pay`,
        ],
      },
      {
        listTitle: 'Bank Statement/Online Transaction History',
        listItem: [
          `Shows your full name`,
          `Your bank, branch, account number & account name`,
          `Two months of income payments, most recent income showing within the last 31 days`,
          `Employer's name corresponding to the deposits`,
        ],
      },
      {
        listTitle: 'Summary of Earnings issued by IRD',
        listItem: [
          `Is dated within the last 31 days`,
          `Is for the previous 12 months`,
          `Shows your full name`,
          `Your employer's name`,
        ],
      },
    ],
  },
  {
    accordionTitle: 'Self-employed, investment',
    accordionData: [
      { description: 'To verify your self-employed or investment income we require one of the following documents:' },
      {
        listTitle: 'Summary of Earnings issued by IRD',
        listItem: [
          'Is dated within the last 31 days',
          'Is for the previous 12 months',
          'Shows your full name',
          'Your business name (if any)',
        ],
      },
      {
        listTitle: 'Accountant’s Letter',
        listItem: [
          'Shows your full name',
          "Your accountant's name, address and telephone number",
          'Is dated within the last 31 days',
          'States your Net income after tax and deductions',
          'Is accompanied by your most recent bank statement(s) showing 3 months of income',
        ],
      },
      {
        listTitle: 'Tax Return (IR3)',
        listItem: [
          'Is prepared by an accountant with accountant’s details included',
          'Shows your full name',
          'End of financial year date within the last 18 months',
          'Includes all pages',
          'Is accompanied by your most recent bank statement(s) showing 3 months of income',
        ],
      },
      {
        listTitle: 'Full Financial Statement',
        listItem: [
          'Is prepared by an accountant with accountant’s details included',
          'Shows income period within the last 18 months',
          'Your full name',
          'Is accompanied by your most recent bank statement(s) showing 3 months of income',
        ],
      },
    ],
  },
  {
    accordionTitle: 'Government benefits',
    accordionData: [
      {
        description: 'To verify your government benefits income, we require one of the following documents:',
      },
      {
        listTitle: 'Bank Statement/Online Transaction History',
        listItem: [
          'Shows your full name',
          'Your bank, branch, account number & account name',
          'Two consecutive income payments, most recent income showing within the last 31 days',
          'Shows deposit amounts and dates',
          'Shows who made the payment, e.g. WINZ',
        ],
      },
      {
        listTitle: 'Work & Income/IRD Letter or Statement',
        listItem: [
          'Shows your full name',
          'The government agency’s name',
          'Benefit type, amount and frequency',
          'Is dated within the last 31 days',
        ],
      },
      {
        listTitle: 'Summary of Earnings issued by IRD',
        listItem: [
          'Is dated within the last 31 days',
          'Is for the previous 12 months',
          'Shows your full name',
          'The government agency’s name',
        ],
      },
    ],
  },
  {
    accordionTitle: 'ACC',
    accordionData: [
      { description: 'To verify your ACC income, we require the following document:' },
      {
        listTitle: 'Letter/Statement from ACC',
        listItem: [
          'Shows your full name',
          'Start date of payments',
          'The end date (if applicable), and the amount and frequency of payments',
          'Is dated within the last 31 days',
        ],
      },
    ],
  },
  {
    accordionTitle: 'Rental income',
    accordionData: [
      {
        description: 'To verify your rental income, we require one of the following documents:',
      },
      {
        listTitle: 'Bank Statement/Online Transaction History',
        listItem: [
          'Shows your full name',
          'Your bank, branch, account number & account name',
          'Two months of income payments, most recent income showing within the last 31 days',
          "Tenant's address or rental agency's name corresponding to the deposits",
        ],
      },
      {
        listTitle: 'Real Estate Rental Statements or Receipts',
        listItem: [
          'Shows your full name',
          'The last 2 months of rental income',
          'Most recent rental statement or receipt dated within the last 31 days',
          "Shows the real estate agent's name, address and telephone number",
        ],
      },
      {
        listTitle: 'Current Rental Agreement (only for newly signed)',
        listItem: [
          'Shows your full name',
          'The rental address',
          'Term of the rental agreement',
          'Gross rental income',
          "Contracting parties' signatures",
          'Accompanied with another rental income document if over 4 weeks old',
        ],
      },
    ],
  },
];
