import { useEffect } from 'react';

export function useFocusOnFirstFormElement() {
  const firstElement = document.forms[0]?.elements[0];

  useEffect(() => {
    if (firstElement && document.forms[0]) {
      document.forms[0].elements[0].focus();
    }
  }, [firstElement]);
}
