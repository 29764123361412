import React from 'react';
import { List } from 'basis';
import styled from '@emotion/styled';
import { PRODUCT_CODE_DAVID_JONES_PREMIERE, PRODUCT_CODE_DAVID_JONES_PRESTIGE } from '_config';

const djEarnRate = {
  [PRODUCT_CODE_DAVID_JONES_PREMIERE]: [
    '2 points per $1 spent at David Jones',
    '3 points per $1 spent at participating petrol stations & supermarkets and',
    '1 point per $1 spent everywhere else.',
  ],
  [PRODUCT_CODE_DAVID_JONES_PRESTIGE]: [
    '4 points per $1 spent at David Jones',
    '3 points per $1 spent at participating petrol stations & supermarkets',
    '1 point per $1 spent everywhere else',
  ],
};

const Wrapper = styled.div`
  ul {
    padding-left: 1em;
  }

  li {
    &::before {
      width: 0.3em;
      height: 0.3em;
      left: -1em;
      line-height: 24px;
    }

    &:not(:first-of-type) {
      margin-top: 0;
    }
  }
`;

export function EarnRateTiers({ productCode }) {
  return (
    <Wrapper>
      <List>
        {djEarnRate[productCode]?.map(rate => (
          <List.Item>{rate}</List.Item>
        ))}
      </List>
    </Wrapper>
  );
}
