import React from 'react';
import { Container, Text, Stack, Grid, List, Link } from 'basis';
import { PhoneNumberLink } from 'components';
import { config } from '_config';
import { MeaningOfTerms } from './MeaningOfTerms';
import { BracketList, Section, SectionTitleBox } from './shared';

export const ConditionsOfUse = ({ trackEventSender, getLinkWithAnalytics }) => {
  return (
    <Container margin="7 0">
      <Stack gap="3" gap-md="5">
        <Text textStyle="heading4">Conditions of Use</Text>
        <Text>
          Your credit contract comprises these Conditions of Use and the initial disclosure statement.
          <br />
          As soon as you activate your Gem Visa card or use your account you are deemed to have agreed to the terms of
          your credit contract. For any enquiries about your Gem Visa account contact us on{' '}
          <PhoneNumberLink
            id="general"
            onClick={() =>
              trackEventSender({
                category: 'contact-us',
                action: 'phone-link',
                location: 'Conditions of Use',
                label: config?.phoneNumbers?.general,
              })
            }
          />
          . Alternatively, you may write to us at the following address:
        </Text>
        <div>
          <Text>Gem</Text>
          <Text>PO Box 4058</Text>
          <Text>Shortland Street</Text>
          <Text>Auckland 1140</Text>
        </div>
        <Text>Please let us know immediately either by telephone, email or in writing if you change your address.</Text>
        <Sections trackEventSender={trackEventSender} />
        <Text textStyle="subtitle1">
          For any queries about Gem Visa or to report lost or stolen cards, please call us on{' '}
          <PhoneNumberLink
            id="general"
            onClick={() =>
              trackEventSender({
                category: 'contact-us',
                action: 'phone-link',
                location: 'contract-acceptance',
                label: config?.phoneNumbers?.general,
              })
            }
          />{' '}
          or visit{' '}
          <Link
            href={getLinkWithAnalytics({
              location: 'contract-acceptance',
              label: 'gemfinance.co.nz',
              url: 'https://gemfinance.co.nz',
            })}
            newTab
            onClick={() =>
              trackEventSender({
                category: 'text-link',
                action: 'internal-link',
                location: 'contract-acceptance',
                label: 'gemfinance.co.nz',
              })
            }
          >
            gemfinance.co.nz
          </Link>
        </Text>
        <div>
          <Text>Name of Creditor:</Text>
          <Text>Latitude Financial Services Limited,</Text>
          <Text>PO Box 4058, Shortland Street, Auckland 1140</Text>
        </div>
      </Stack>
    </Container>
  );
};

const SectionTitle = ({ children, isRoot = false }) => {
  if (isRoot) {
    return (
      <SectionTitleBox>
        <Text textStyle="heading5">{children}</Text>
      </SectionTitleBox>
    );
  }
  return <Text textStyle="heading6">{children}</Text>;
};

const Sections = ({ trackEventSender }) => {
  return sections.map(section => {
    const isRoot = !!section.number.match(/^\d*\.$/);
    const title = section.title ? (
      <SectionTitle isRoot={isRoot}>
        {isRoot && `${section.number} `}
        {section.title}
      </SectionTitle>
    ) : null;
    const description = (
      <>
        {!isRoot && title}
        {section.content &&
          (typeof section.content === 'function' ? (
            section.content({ trackEventSender })
          ) : (
            <Text>{section.content}</Text>
          ))}
      </>
    );

    return (
      <Section key={`section-${section.number}`}>
        {isRoot && title}
        {isRoot && description}
        {!isRoot && <TermItem number={section.number}>{description}</TermItem>}
      </Section>
    );
  });
};

const TermItem = ({ children, number }) => (
  <Grid colsGap="0px" cols={12} cols-md={18}>
    <Grid.Item colSpan="0-1" key={`term-number-${number}`}>
      <Text>{number}</Text>
    </Grid.Item>
    <Grid.Item colSpan="2-12" colSpan-md="2-18" key={`term-description-${number}`}>
      {children}
    </Grid.Item>
  </Grid>
);

const sections = [
  {
    number: '1.',
    title: 'Meaning of Terms',
    content: MeaningOfTerms,
  },
  {
    number: '2.',
    title: 'Information about your card and your account',
  },
  {
    number: '2.1',
    title: 'Receiving and signing your card',
    content:
      'When you receive your card you must sign it immediately. You cannot use your card unless it has been activated in accordance with the instructions provided with the card. You must protect your card from damage and misuse including tampering with or destroying the embedded microchip.',
  },
  {
    number: '2.2',
    title: 'Card ownership',
    content:
      'Your card and your card number are our property. You must return or destroy your card if we tell you to do so.',
  },
  {
    number: '2.3',
    title: 'Use of your card and/or account',
    content: () => (
      <div>
        <Text>
          Subject to your credit contract and within the transaction limits set out in clause 10.4, you may use your
          card and account to obtain credit from us to pay for all or part of the price of purchases, for balance
          transfers and for cash advances. We may refuse to let you use your account or card if:
        </Text>
        <List>
          <List.Item>that use would result in you exceeding your credit limit;</List.Item>
          <List.Item>
            you are in default under your credit contract or any other contract you have with us; or
          </List.Item>
          <List.Item>your card has been reported lost or stolen or has expired.</List.Item>
        </List>
      </div>
    ),
  },
  {
    number: '2.4',
    title: 'Visa payWave',
    content:
      'Subject to clause 2.3 and the transaction limits set out in clause 10.4, you may use Visa payWave to make retail purchases at merchants that have Visa payWave readers and display the Visa payWave logo. Before authorising a Visa payWave purchase you must check that the correct transaction amount is displayed on the Visa payWave reader or on the merchant’s register. Once the purchase is completed you should ask the merchant to provide you with a receipt, which should be retained and checked against your statement.',
  },
  {
    number: '2.5',
    title: 'Balance transfers',
  },
  {
    number: '2.5.1',
    content:
      'We may accept or decline any request from you for a balance transfer or impose any condition on a balance transfer at our discretion.',
  },
  {
    number: '2.5.2',
    content: () => (
      <div>
        <Text>Without limiting clause 2.5.1, we may decline a balance transfer if:</Text>
        <List>
          <List.Item>you are not liable for the other account from which the balance transfer is to be made;</List.Item>
          <List.Item>the other account is a type of account from which we do not accept balance transfers;</List.Item>
          <List.Item>you do not comply with any conditions of any balance transfer offer;</List.Item>
          <List.Item>you do not properly authorise the balance transfer;</List.Item>
          <List.Item>
            you do not provide all information reasonably requested by us to effect the balance transfer; or
          </List.Item>
          <List.Item>the balance transfer would result in your credit limit being exceeded.,</List.Item>
        </List>
      </div>
    ),
  },
  {
    number: '2.5.3',
    content:
      'You must continue to make any required payments to any account from which you transfer a balance until you receive a statement confirming that account has been paid in full and/or closed. We are not liable for any overdue payments or interest incurred on that account.',
  },
  {
    number: '2.6',
    title: 'Cash advances',
  },
  {
    number: '2.6.1',
    content: 'The minimum amount of a cash advance may vary between financial institutions.',
  },
  {
    number: '2.6.2',
    content:
      'We may at any time where in the circumstances we believe it is appropriate to do so, limit, suspend or withdraw the cash advance facility on your account.',
  },
  {
    number: '2.6.3',
    content:
      'You and any additional cardholders may be required at any time to provide identification and other information in order to obtain a cash advance.',
  },
  {
    number: '2.6.4',
    content:
      'Subject to your credit limit, cardholders may obtain cash advances of up to $3,000 per day in aggregate in over the counter advances with financial institutions, plus up to $1,000 per day in aggregate in cash advances from ATMs. Some financial institutions may impose additional restrictions.',
  },
  {
    number: '2.6.5',
    content:
      'We may decline requests for cash equivalent transactions or, at any time, impose any condition on cash equivalent transactions at our discretion.',
  },
  {
    number: '2.7',
    title: 'Instalment Plan Advances with a period of less than 36 months',
    content: () => (
      <div>
        <Text>
          The following are the additional features of Instalment Plan Advances with a period of less than 36 months:
        </Text>
        <BracketList>
          <BracketList.Item>
            The minimum monthly payment under this plan may be greater than the minimum monthly payment for an interest
            free start-up advance for the same period.
          </BracketList.Item>
          <li>
            <Text>
              If for any reason, you do not pay at least the minimum monthly payment required by clause 14.2, for two
              consecutive months, then:
            </Text>
            <ol type="i">
              <li>
                <Text>
                  this plan will automatically convert to an interest free start-up advance for the remainder of the
                  original period of the plan; and
                </Text>
              </li>
              <li>
                <Text>
                  when the interest free period expires, if any remaining balance is unpaid, then interest will be
                  charged at the prevailing interest rate, from the day following the expiry, until the balance is paid
                  in full.
                </Text>
              </li>
            </ol>
          </li>
        </BracketList>
      </div>
    ),
  },
  {
    number: '3.',
    title: 'Additional cardholders',
  },
  {
    number: '3.1',
    content:
      'At your request we may issue an additional card to anyone over 16 years of age. The additional card must be activated in accordance with our instructions provided with the card and signed by the additional cardholder. It may be used by that person on your account in all respects as if it were your card. You will be liable for all transactions carried out by that person as if you had used the additional card yourself.',
  },
  {
    number: '3.2',
    content:
      'You must advise us if you wish to cancel the authority of an additional cardholder to operate on your account. You will remain liable for all transactions carried out by the additional cardholder.',
  },
  {
    number: '3.3',
    content: 'You agree that we may give any additional cardholder information about your account.',
  },
  {
    number: '4.',
    title: 'Credit limit',
  },
  {
    number: '4.1',
    content:
      'The credit limit on your account is the maximum amount of credit that you can access. Your initial credit limit will be shown on the initial disclosure statement and your current credit limit will be shown in your monthly statements.',
  },
  {
    number: '4.2',
    content:
      'You must keep the balance of your account within your credit limit at all times. If you exceed your credit limit you must pay the excess amount to us immediately and we may decline any transactions on your account until you do so.',
  },
  {
    number: '4.3',
    content:
      'You can apply to increase your credit limit or request us to reduce your credit limit at any time. We may decrease your credit limit at any time without your consent where in the circumstances we believe it is appropriate to do so.',
  },
  {
    number: '5.',
    title: 'Amounts debited to your account',
  },
  {
    number: '5.1',
    content:
      'Use of your card and/or your account is an irreversible instruction from you to us, and any transaction carried out by you or any additional cardholder cannot be stopped.',
  },
  {
    number: '5.2',
    content: () => (
      <div>
        <Text>Your account will be debited with, and you agree to pay us:</Text>
        <List>
          <List.Item>
            the amount of each purchase authorised by you including authorisation by use of your card, in writing, by
            telephone (including SMS/MMS), mail, Internet or email;
          </List.Item>
          <List.Item>the amount of each cash advance;</List.Item>
          <List.Item>the amount of each balance transfer;</List.Item>
          <List.Item>interest calculated in accordance with your credit contract;</List.Item>
          <List.Item>fees and charges payable in accordance with your credit contract;</List.Item>
          <List.Item>any other amounts owing under your credit contract.</List.Item>
        </List>
      </div>
    ),
  },
  {
    number: '5.3',
    content:
      'There are risks involved with transactions initiated by mail order, telephone order (including SMS/MMS), Internet or email. You should take into account the standing of the merchant with which you are doing business before carrying out such transactions.',
  },
  {
    number: '5.4',
    content:
      'If your account has been closed, or it is open but there are insufficient funds or available credit, and an EFT terminal processes a transaction on your account, you will be liable for that transaction amount.',
  },
  {
    number: '6.',
    title: 'Statements',
  },
  {
    number: '6.1',
    content:
      'We will send you monthly statements. These statements constitute continuing disclosure statements for the purposes of the CCCFA and show all amounts debited or credited to your account during the statement period.',
  },
  {
    number: '6.2',
    content: () => (
      <div>
        <Text>We will not send you a statement:</Text>
        <List>
          <List.Item>
            if there have been no transactions on your account since your last statement (if any) and the account
            balance is nil; or
          </List.Item>
          <List.Item>
            if you are in breach of your credit contract and we have commenced enforcement proceedings against you or
            have written off any amount owing on your account.
          </List.Item>
        </List>
      </div>
    ),
  },
  {
    number: '6.3',
    content:
      'Where you have consented to disclosure being made in electronic form, an email with a link to an Internet page containing your statement will be sent to the email address provided by you. Where you have not consented to disclosure being made in electronic form, or have withdrawn your consent, statements will be posted to your last known mailing address.',
  },
  {
    number: '7.',
    title: 'PIN',
  },
  {
    number: '7.1',
    content:
      'When your card is issued, you will need to call us to select a PIN. When you choose a PIN you must not select an unsuitable number such as dates of birth, sequential or easily guessed numbers (e.g. 1234 or 7777), or parts of your card number.',
  },
  {
    number: '7.2',
    content:
      'Because anyone who has access to your card and knowledge of your PIN may conduct a transaction on your account, you must take special care to protect both your card and PIN.',
  },
  {
    number: '7.3',
    content: () => (
      <div>
        <Text>To protect your PIN you must:</Text>
        <List>
          <List.Item>try to memorise it;</List.Item>
          <List.Item>not write it on your card, even if it is disguised;</List.Item>
          <List.Item>not keep a record of your PIN with or near your card;</List.Item>
          <List.Item>
            not disclose your PIN to anyone including your family, friends, our staff, or any other financial
            institution staff; and
          </List.Item>
          <List.Item>ensure that no one can read your PIN when you enter it at an EFT terminal.</List.Item>
        </List>
      </div>
    ),
  },
  {
    number: '7.4',
    content:
      'To reduce the risk of unauthorised transactions occurring on your account, do not keep your card and PIN together in a wallet, bag or briefcase even if in different compartments. Do not leave your card and PIN in an unattended area at work, at home, in your vehicle or any place from where they could be stolen. If your card is stolen or lost together with your PIN then someone else could use it to carry out unauthorised transactions, which may result in a loss to you. You must exercise every possible care to ensure the safety of your card and to prevent disclosure of your PIN.',
  },
  {
    number: '7.5',
    content:
      'If you forget your PIN or know or suspect that it has been disclosed to another person, please notify us immediately and arrange a new card. You will need to call us to select a new PIN for this card.',
  },
  {
    number: '8.',
    title: 'Lost or stolen cards and PINs',
    content: `You must tell us immediately if your card or PIN is lost or stolen or you suspect unauthorised transactions have been made on your account. This can be done by calling us at any time on ${config?.phoneNumbers?.general} or on ${config?.phoneNumbers?.generalAlt}. You will be required to provide us all the information you have about the loss, theft or misuse of your card and/or your PIN. If your card or PIN is lost or stolen overseas you may notify us by calling ${config?.phoneNumbers?.generalAlt}. Please ensure you retain a record of your notification. We may require you to confirm your report in writing.`,
  },
  {
    number: '9.',
    title: 'Your liability for unauthorised transactions',
  },
  {
    number: '9.1',
    content: () => (
      <div>
        <Text>
          Once you have notified us that your card or PIN has been lost, stolen or you suspect unauthorised transactions
          have been made on your account, then provided you have not acted fraudulently or negligently, you will not be
          held responsible for any unauthorised transactions that take place after that time.
        </Text>
        <Text>You will be liable to pay a maximum of $50 of any loss that occurs before you notify us.</Text>
        <Text>However the $50 limit will not apply if you have:</Text>
        <List>
          <List.Item>acted fraudulently or negligently;</List.Item>
          <List.Item>disclosed your PIN to another person or allowed them to use your card;</List.Item>
          <List.Item>selected an unsuitable PIN;</List.Item>
          <List.Item>failed to safeguard your card;</List.Item>
          <List.Item>
            unreasonably delayed telling us that your card has been lost, stolen or misused and/or your PIN has become
            known to someone else; or
          </List.Item>
          <List.Item>failed to comply with your credit contract.</List.Item>
        </List>
      </div>
    ),
  },
  {
    number: '9.2',
    content:
      'In the above instances, your liability will be limited to the lesser of the daily withdrawal limits applicable to your card set out in clause 10.4 or the actual loss at the time of notification.',
  },
  {
    number: '10.',
    title: 'Electronic transaction access',
  },
  {
    number: '10.1',
    content: () => (
      <div>
        <Text>
          We will be responsible for maintaining electronic transaction access to your account at all times unless;
        </Text>
        <List>
          <List.Item>an EFT terminal malfunctions or is unavailable for use; or</List.Item>
          <List.Item>your account is over the credit limit; or</List.Item>
          <List.Item>you are in breach of any other provision of your credit contract.</List.Item>
        </List>
      </div>
    ),
  },
  {
    number: '10.2',
    content:
      'We will be responsible for any direct loss or damage caused by the failure of either your card or any EFT terminal (excluding any card or EFT terminal which is obviously faulty, or in the case of an EFT terminal where it has been advised by notice or display as being faulty) to function properly. We will also be liable for any direct or indirect loss or damage resulting from fraudulent or negligent acts or omissions of our employees or agents.',
  },
  {
    number: '10.3',
    content:
      'In addition to the terms of your credit contract, the use of your card at any EFT terminal is subject to the conditions and fees imposed from time to time by other financial institutions that are parties to any EFT system. Access to any EFT terminal is at the relevant merchant’s or financial institution’s sole discretion.',
  },
  {
    number: '10.4',
    content: () => (
      <div>
        <Text>Maximum daily withdrawal limits apply to your card:</Text>
        <List>
          <List.Item>$1,000 for ATM cash advances;</List.Item>
          <List.Item>$3,000 for over the counter cash advances;</List.Item>
          <List.Item>
            your available credit limit for EFT terminal purchases (other than Visa payWave purchases) and promotional
            purchase and balance transfers; and
          </List.Item>
          <List.Item>
            Up to $200 for single Visa payWave purchases with a maximum of 3 purchases. This is subject to change at our
            discretion.
          </List.Item>
        </List>
      </div>
    ),
  },
  {
    number: '11.',
    title: 'Transaction disputes',
  },
  {
    number: '11.1',
    content: () => (
      <div>
        <Text>It is your responsibility to promptly check and verify;</Text>
        <List>
          <List.Item>the monthly statements sent to you by us;</List.Item>
          <List.Item>
            all transaction records provided to you by merchants, financial institutions and EFT terminals as records of
            transactions on your account;
          </List.Item>
        </List>
        <br />
        <Text>and to retain those statements and transaction records.</Text>
      </div>
    ),
  },
  {
    number: '11.2',
    content: `You must notify us without delay and in any event within 30 days of the closing date of the statement period if you believe there is an error on your account, on your statement, or if you believe unauthorised transactions have been made on your account. You can notify us by calling us on ${config?.phoneNumbers?.general}, emailing us, or writing to us at the address shown on your statement. We may ask you to verify your concern in writing.`,
  },
  {
    number: '11.3',
    content:
      'You must give us all information reasonably necessary to enable us to resolve your concern. If we are unable to resolve your concern immediately we will complete our investigation within 21 days and advise you of the outcome, or advise you if we need more time to complete our investigation.',
  },
  {
    number: '11.4',
    content:
      'Failure by you to report your concern or what you believe to be an unauthorised transaction on your account within 30 days of the closing date of the statement period may mean we cannot reverse the transaction and you will be liable for it.',
  },
  {
    number: '11.5',
    content:
      'Subject to clause 11.4, if we decide that an error did occur on your account then we will correct this error including adjusting interest, fees and/or charges and advise you in writing. If we decide that your account was not incorrectly debited or credited or, in the case of an unauthorised transaction, you were responsible for all or part of the loss, then we will notify you setting out the reasons.',
  },
  {
    number: '11.6',
    content:
      'If you have followed this procedure and are still not satisfied with the outcome of our investigation, you may refer the matter to the Insurance and Savings Ombudsman Scheme. Please refer to clause 24 for contact details.',
  },
  {
    number: '12.',
    title: 'Interest',
  },
  {
    number: '12.1',
    content:
      'The current annual interest rate(s) for your account will be shown on each of your monthly statements and is/are subject to change.',
  },
  {
    number: '12.2',
    content: 'Each annual interest rate is divided by 365 days to calculate the daily interest rate.',
  },
  {
    number: '12.3',
    content:
      'Interest on cash advances and balance transfers is charged at the current prevailing interest rates applying at the time, from the date of the cash advance and/or balance transfer until the date the transaction amount is paid in full.',
  },
  {
    number: '12.4',
    content:
      'Interest on interest bearing instalment plan advances is charged at the applicable interest rate, from the date of the advance until the expiry of the instalment plan period.',
  },
  {
    number: '12.5',
    content:
      'Interest on expired promotional purchases is charged at the current prevailing interest rate, from the day following the expiry of the relevant deferred payment and/or interest free start-up period or instalment plan period, until the date the purchases are paid in full.',
  },
  {
    number: '12.6',
    content:
      'Interest on expired promotional balance transfers is charged at the current prevailing cash advance interest rate, from the day following the expiry of the relevant promotional period, until the date the balance transfers are paid in full.',
  },
  {
    number: '12.7',
    content:
      'If the full balance of all retail purchases listed in the current statement period is paid in full by the payment due date shown in that statement, no interest will be charged on those retail purchases. If the full balance is not paid by this date, interest at the current prevailing interest rate will be charged on retail purchases from the transaction date.',
  },
  {
    number: '12.8',
    content:
      'Interest charges are calculated and charged on a daily basis by applying the applicable daily interest rate to the relevant portion of the daily balance of your account, at the end of each day.',
  },
  {
    number: '12.9',
    content:
      'Interest charges will be debited to your account monthly on the date of each statement. Any interest charges debited to your account will form part of the balance on which interest charges are calculated.',
  },
  {
    number: '12.10',
    content: 'We do not pay interest on any credit balance on your account.',
  },
  {
    number: '13.',
    title: 'Fees and charges',
  },
  {
    number: '13.1',
    content:
      'The fees and charges that you may pay under your credit contract are set out in the initial disclosure statement.',
  },
  {
    number: '13.2',
    content:
      'You agree to pay all fees and charges set out in the initial disclosure statement and any other fees and charges determined by us. You authorise us to debit the fees and charges payable under your credit contract to your account. Any fees and charges debited to your account will form part of the balance on which interest charges may be calculated.',
  },
  {
    number: '13.3',
    content:
      'We may change the amount of any fee or charge, the method of calculation and the frequency or time of payment. Subject to Clause 21, we may also impose a new fee or charge under your credit contract at any time.',
  },
  {
    number: '13.4',
    content: () => (
      <div>
        <Text>Fees and charges that you may be required to pay include, but are not limited to:</Text>
        <List>
          <List.Item>Establishment fee;</List.Item>
          <List.Item>Annual account fee;</List.Item>
          <List.Item>ATM withdrawal fee;</List.Item>
          <List.Item>Cash withdrawal fee for processing an over the counter withdrawal request;</List.Item>
          <List.Item>Paper statement fee;</List.Item>
          <List.Item>International transaction fee;</List.Item>
          <List.Item>Late payment fee;</List.Item>
          <List.Item>Payment handling fee for over the counter payments at New Zealand Post branches.</List.Item>
          <List.Item>Replacement card fee</List.Item>
        </List>
      </div>
    ),
  },
  {
    number: '14.',
    title: 'Payments',
  },
  {
    number: '14.1',
    title: 'Methods of payment',
    content:
      'You may make payments by the methods set out in your statement. All payments are to be made in New Zealand dollars and must be accompanied by details identifying your account.',
  },
  {
    number: '14.2',
    title: 'Minimum monthly payment',
  },
  {
    number: '14.2.1',
    content: () => (
      <div>
        <Text>
          If you do not wish to pay the full closing balance shown in your monthly statement, you must pay at least the
          minimum monthly payment by the date specified in the statement.
        </Text>
        <Text>The minimum monthly payment is, either:</Text>
        <BracketList>
          <li>
            <List>
              <List.Item>any payment due under an instalment plan advance; and</List.Item>
              <List.Item>any establishment fee in relation to an instalment plan advance; and</List.Item>
              <List.Item>
                either 3% of the closing balance (excluding any instalment plan advance or unexpired deferred payment
                advance) or $20, whichever is the greater, together with any amount shown as overdue; and
              </List.Item>
              <List.Item>
                an amount sufficient to reduce the closing balance to the credit limit (if applicable); or
              </List.Item>
            </List>
          </li>
          <BracketList.Item>
            any closing balance (excluding any unexpired deferred payment advance) under $20; or
          </BracketList.Item>
          <BracketList.Item>any other amounts as agreed from time to time between you and us.</BracketList.Item>
        </BracketList>
      </div>
    ),
  },
  {
    number: '14.2.2',
    content:
      'You must make the minimum monthly payment by the due date shown on your statement. The due date is 25 days after the date of the statement except for any amount shown as overdue and/or in excess of your credit limit, which must be paid immediately.',
  },
  {
    number: '14.2.3',
    content: 'You may pay more than the minimum monthly payment due if you wish.',
  },
  {
    number: '14.2.4',
    content:
      'You must pay the minimum monthly payment each month even if you do not receive your monthly statement. Details of your account are available from us.',
  },
  {
    number: '14.3',
    title: 'Application of payments',
  },
  {
    number: '14.3.1',
    content: () => (
      <div>
        <Text>
          Payments will be applied to your account in the following order (although we may apply a payment in another
          order without notification to you):
        </Text>
        <BracketList>
          <BracketList.Item>
            any payment due in the current or a previous payment period for any instalment plan advances (including,
            where applicable, fees, charges, interest and principal relating to those advances);
          </BracketList.Item>
          <BracketList.Item>enforcement expenses;</BracketList.Item>
          <BracketList.Item>
            other fees, charges, interest and Gem Visa Shoppers Protection premium due in the current or a previous
            statement period;
          </BracketList.Item>
          <BracketList.Item>any other amount due from a previous statement period;</BracketList.Item>
          <BracketList.Item>any other amount due in the current statement period; and</BracketList.Item>
          <BracketList.Item>any amounts not yet due for payment.</BracketList.Item>
        </BracketList>
      </div>
    ),
  },
  {
    number: '14.3.2',
    content:
      'Payments under clauses 14.3.1(d) and (e) will be applied to any balance transfers, cash advances, expired promotional purchases, retail purchases and unexpired promotional purchases (excluding deferred payment advances).',
  },
  {
    number: '14.3.3',
    content: () => (
      <div>
        <Text>Payments under clause 14.3.1(f) will be applied in full to:</Text>
        <BracketList>
          <BracketList.Item>
            statemented balance transfers, cash advances, expired promotional purchases, retail purchases, unexpired
            promotional purchases (excluding deferred payment advances); and
          </BracketList.Item>
          <BracketList.Item>
            un-statemented balance transfers, cash advances, retail purchases, promotional purchases (excluding deferred
            payment advances); and
          </BracketList.Item>
          <BracketList.Item>instalment plan advances; and</BracketList.Item>
          <BracketList.Item>deferred payment advances.</BracketList.Item>
        </BracketList>
      </div>
    ),
  },
  {
    number: '14.3.4',
    content:
      'Payments applied to unexpired promotional purchases (other than instalment plan advances) will be credited in the order of their expiry dates. Payments applied to all other transactions will be credited in the date order on which they appeared on your account.',
  },
  {
    number: '14.3.5',
    content: () => (
      <div>
        <Text>
          In the event the payment made during the statement period is below the minimum monthly payment, payments will
          initially be applied in the following manner:
        </Text>
        <BracketList>
          <BracketList.Item>
            payments under clause 14.3.1 (a) to 3% of the balance of each of the instalment plan advance(s) with a
            period of less than 36 months.
          </BracketList.Item>
          <BracketList.Item>payments under clause 14.3.1 (d) and (e).</BracketList.Item>
          <BracketList.Item>
            to the remaining due amount of the instalment plan advance(s) with a period of less than 36 months.
          </BracketList.Item>
        </BracketList>
      </div>
    ),
  },
  {
    number: '14.4',
    title: 'Payments in general',
    content:
      'We will not treat a payment as being made until it is actually credited to your account. Depending on the method of payment used it can take up to two working days for a payment to be credited to your account. After this time there is a clearing period of up to eight working days for all payments. During this period you may not be able to access any credit established by such payments to your account. We may choose to waive immediate payment of any amount due under your credit contract. If we do so, such action shall not act as a waiver or prejudice our powers in respect of any remaining or future payment.',
  },
  {
    number: '15.',
    title: 'Card acceptance',
  },
  {
    number: '15.1',
    content:
      'Gem Visa cards are accepted at financial institutions and merchants that display the Visa symbol. However we are not responsible if a financial institution or merchant refuses to accept your card, will not allow you to purchase a product using your card, does not follow proper authorisation procedures, or refuses to accept a return of goods purchased with your card. You must resolve those problems directly with the financial institution or merchant.',
  },
  {
    number: '15.2',
    content:
      'We are not responsible and give no warranty for any goods or services obtained from a merchant using your card, except as required by law. You must resolve any complaints directly with the merchant.',
  },
  {
    number: '15.3',
    content:
      'If you use your card outside of New Zealand you may be subject to exchange controls or other government requirements. Visa processes and converts all cash advances, purchases and/or charges made or incurred in foreign currencies into New Zealand dollars at a rate of exchange fixed by Visa. Visa may convert transactions made in any foreign currency first into United States dollars before converting them into New Zealand dollars.',
  },
  {
    number: '16.',
    title: 'Returned goods',
    content: () => (
      <div>
        <Text>
          When a merchant agrees to accept a return of goods purchased with your card we will apply the resulting refund
          to your account as follows:
        </Text>
        <List>
          <List.Item>
            refunds arising from retail purchases will be applied in reduction of the interest bearing balance on your
            account;
          </List.Item>
          <List.Item>
            refunds arising from promotional purchases (other than Gem Visa promotional purchases) will be applied to
            the relevant promotional purchase; and
          </List.Item>
          <List.Item>
            refunds arising from Gem Visa promotional purchases will be applied to your most recent Gem Visa promotional
            purchase. If the return of goods is in respect of a previous Gem Visa promotional purchase, you may not
            receive the full benefit of the extended interest free start-up period applicable to the most recent Gem
            Visa promotional purchase. We may at our discretion agree to apply any such refund to a prior Gem Visa
            promotional purchase on request from you.
          </List.Item>
        </List>
      </div>
    ),
  },
  {
    number: '17.',
    title: 'Damaged or faulty cards',
    content:
      'We will issue you with a new card if your card becomes faulty or damaged. Please advise us by telephone, email or in writing and send the damaged or faulty card to us together with a description of how the damage or fault occurred in order for us to issue a replacement card.',
  },
  {
    number: '18.',
    title: 'To end your credit contract',
  },
  {
    number: '18.1',
    content: `You may cancel your credit contract at any time by calling us on ${config?.phoneNumbers?.general} or by notifying us in writing and paying us all amounts you owe, including any amounts accrued, authorised or charged but not yet debited to your account.`,
  },
  {
    number: '18.2',
    content:
      'If you cancel your credit contract under clause 18.1 or if we cancel your credit contract under clause 19.1 you must destroy your card and any additional card by cutting them in half.',
  },
  {
    number: '18.3',
    content:
      'If any amounts are charged to your account after you have told us that you want to cancel your credit contract, we can refuse to pay these amounts, or we may pay them and recover the amounts from you. In either case, we can tell any merchant that your credit contract has been cancelled.',
  },
  {
    number: '18.4',
    content:
      'If you wish to close your account you must ensure that all periodic debits on your account are cancelled.',
  },
  {
    number: '19.',
    title: 'Cancellation of your card and our refusal to provide further credit',
  },
  {
    number: '19.1',
    content:
      'We may at any time cancel your card and refuse to provide any further credit to you under your credit contract.',
  },
  {
    number: '19.2',
    content:
      'If we cancel your card, you must not attempt to obtain credit on your account and you must destroy your card and any additional card by cutting them in half.',
  },
  {
    number: '19.3',
    content:
      'If we cancel your card, your credit contract continues to operate. We will still charge you interest, fees and charges and you must still make payments to us.',
  },
  {
    number: '19.4',
    content:
      'All amounts you owe under your credit contract including any amounts accrued, authorised or charged but not yet debited to your account become immediately due and payable in the event of your death or if you become bankrupt or insolvent.',
  },
  {
    number: '20.',
    title: 'Default and enforcement',
  },
  {
    number: '20.1',
    content:
      'If you are in default under your credit contract we may demand immediate payment in full of all amounts you owe under your credit contract including any amounts accrued, authorised or charged but not yet debited to your account. If this happens any promotional purchases will be deemed to have expired even if they may still be within their deferred payment periods and/or interest free start-up periods or agreed instalment periods. Interest on those promotional purchases will accrue at the current applicable interest rate from the date of demand. We will also require the destruction (by cutting in half) or immediate return of your card(s) and any additional card(s) to us.',
  },
  {
    number: '20.2',
    content: () => (
      <div>
        <Text>You are in default under your credit contract if:</Text>
        <List>
          <List.Item>
            you do not pay any amount payable under your credit contract in full by its due date; or
          </List.Item>
          <List.Item>any payment of any amount payable under your credit contract is dishonoured; or</List.Item>
          <List.Item>
            you breach any term of your credit contract or the terms of any other agreement you have with us; or
          </List.Item>
          <List.Item>
            any information that you have given us in connection with your account is false or misleading.
          </List.Item>
        </List>
      </div>
    ),
  },
  {
    number: '20.3',
    content:
      'If you are in default, you may be liable to pay all reasonable enforcement expenses and collection costs (including court costs and costs on a solicitor/client basis and all administration costs) that we incur as a result of the default. We may debit these amounts to your account.',
  },
  {
    number: '20.4',
    content:
      'We may at any time without notice to you, set-off against any money owing by us or any of our related entities to you, any debt or liability you may have to us on any account whatsoever.',
  },
  {
    number: '21.',
    title: 'Variations to your credit contract',
  },
  {
    number: '21.1',
    content:
      'We may vary any of the terms and conditions of your credit contract at any time. If you are not satisfied with a change that we have made, you may cancel your credit contract (see clause 18 above).',
  },
  {
    number: '21.2',
    content: () => (
      <div>
        <Text>Where we change:</Text>
        <List>
          <List.Item>an interest rate or how interest is calculated or applied;</List.Item>
          <List.Item>
            the amount, frequency, time for payment, or method of calculation of any fee, payment or charge payable; or
          </List.Item>
          <List.Item>the amount of your credit limit,</List.Item>
        </List>
        <br />
        <Text>
          We will disclose the full particulars of that change (together with any other information required to be given
          by applicable law) to you within 5 working days of the day on which the change takes effect, or as otherwise
          required or permitted by applicable law. However, where any of the changes in clause 21.2:
        </Text>
        <List>
          <List.Item>reduces your obligations to us; or</List.Item>
          <List.Item>extends the time for any payment to be made by you</List.Item>
        </List>
        <br />
        <Text>
          Then we will communicate that change to you within 5 working days of the day on which the change takes effect,
          or at the time of your next statement, or as otherwise required or permitted by applicable law.
        </Text>
      </div>
    ),
  },
  {
    number: '21.3',
    content: () => (
      <div>
        <Text>
          If we agree with you to change any of the terms and conditions of your credit contract, we will notify you of
          that change (together with any other information required to be given by applicable law) before it takes
          effect, or as otherwise required or permitted by applicable law. However, if any such agreed change to your
          credit contract is one that:
        </Text>
        <List>
          <List.Item>reduces your obligations to us;</List.Item>
          <List.Item>extends the time for any payment to be made by you;</List.Item>
          <List.Item>releases part or all of any security interest relating to your credit contract; or</List.Item>
          <List.Item>increases or decreases your credit limit;</List.Item>
        </List>
        <Text>
          Then we may simply notify you of the full particulars of that change within 5 working days of the day on which
          the change takes effect, or at the time of your next statement, or as otherwise required or permitted by
          applicable law.
        </Text>
      </div>
    ),
  },
  {
    number: '22.',
    title: 'Personal information',
  },
  {
    number: '22.1',
    content:
      'You agree that the Acknowledgement and Authorisation accepted by you when you applied for a Gem Visa card applies to any information you provide to us in connection with your account, including the information you provided at the time of application.',
  },
  {
    number: '22.2',
    content:
      'You agree that we may disclose your personal information and information about your account to any organisation or merchant that is involved in authorising or facilitating the use of your card, any payment to your account, or facilitating any credit limit increase on your account.',
  },
  {
    number: '22.3',
    content:
      'You authorise us to disclose your personal information to any of our related entities and for those entities to disclose your personal information amongst themselves for any of the purposes permitted under the Privacy Act and/or your credit contract including without limitation, for account management and marketing purposes. You further agree that we may communicate with you by mail, telephone (including SMS/MMS), or email for these purposes.',
  },
  {
    number: '22.4',
    content:
      'Your personal information is held by us subject to the Privacy Act. The Privacy Act gives you the right to see and request correction of your information.',
  },
  {
    number: '22.5',
    content:
      'You agree that we may utilise any credit reporters’ monitoring services to receive updates if the information held about you changes. You acknowledge that we will give your personal information (including without limitation, repayment history information, details relating to your account, and payment default information) to credit reporters for these purposes and that those credit reporters may hold the information on their systems and disclose it to authorised users of the credit reporters’ services. You also agree that we may disclose to any credit provider named in a credit report relating to you, information about your creditworthiness and credit history.',
  },
  {
    number: '22.6',
    content:
      'You authorise us when we open and review your account, and offer you products and services or credit limit increases, to make all credit rating and other enquiries that we deem in our discretion to be appropriate.',
  },
  {
    number: '22.7',
    content:
      'You agree that where your account was opened at a merchant premises, all information held about you at any time may be disclosed and exchanged with the merchant for the purpose of marketing its products and services, and to enable the merchant to monitor and maintain its introducer facility with us.',
  },
  {
    number: '22.8',
    content:
      'If you are in default under your credit contract or any other agreement you have with us you agree that your personal information may be provided to, and for the use of, credit rating and debt collection agencies.',
  },
  {
    number: '23.',
    title: 'General conditions',
  },
  {
    number: '23.1',
    content: 'Your credit contract is governed by New Zealand law.',
  },
  {
    number: '23.2',
    content:
      'You acknowledge that we may receive a commission for the introduction of any Gem Visa Shopper’s Protection insurance taken out by you through us. We may also receive or pay a commission to other persons including any supplier of goods or services purchased by you from the proceeds of any advance under your credit contract.',
  },
  {
    number: '23.3',
    content:
      'You cannot assign your account or your credit contract. We may assign, or transfer to any person or otherwise deal in any manner with, any of our rights, obligations or interests under your credit contract. You authorise us to disclose to any such assignee transferee, all information we hold about you or your account including your personal information for all and any of the purposes set out in clause 22.',
  },
  {
    number: '23.4',
    content:
      'You must notify us promptly of any change in your name, postal address, email address (if any) or telephone number.',
  },
  {
    number: '23.5',
    content: 'We may act on your written, email or verbal instructions or that of any additional cardholder.',
  },
  {
    number: '23.6',
    content:
      'Where you use the credit provided under your credit contract primarily for business or investment purposes (or both) your credit contract will not be a consumer credit contract for the purposes of the CCCFA. Accordingly, you acknowledge that, in these circumstances, you do not have any of the rights of a debtor under a consumer credit contract under that Act. Nothing in your credit contract that refers to or incorporates provisions of the CCCFA is intended to imply that any debtor has rights that it would not otherwise have under the Act.',
  },
  {
    number: '24.',
    title: 'Complaints',
    content: `If you have a complaint relating to your account, please contact us first. We have a free internal dispute resolution procedure, which you can access by telephoning ${config?.phoneNumbers?.disputes} or ${config?.phoneNumbers?.disputesAlt} or writing to Latitude Financial Services Limited at PO Box 4058, Auckland, attention Customer Resolution Team. If you have followed this process and your complaint remains unresolved, you may refer the matter to the Insurance and Financial Services Ombudsman Scheme on ${config?.phoneNumbers?.ombudsman}, free call ${config?.phoneNumbers?.ombudsmanAlt} or in writing to PO Box 10845, Wellington, New Zealand.`,
  },
  {
    number: '25.',
    title: 'How to contact us',
    content: ({ trackEventSender }) => {
      return (
        <div>
          <Text>Any enquiries within New Zealand:</Text>
          <Text>
            Call the Customer Service Team on{' '}
            <PhoneNumberLink
              id="general"
              onClick={() =>
                trackEventSender({
                  category: 'contact-us',
                  action: 'phone-link',
                  location: 'How to contact us',
                  label: config?.phoneNumbers?.general,
                })
              }
            />
          </Text>
          <br />
          <Text>
            Any enquiries outside New Zealand: Call{' '}
            <PhoneNumberLink
              id="generalAlt"
              onClick={() =>
                trackEventSender({
                  category: 'contact-us',
                  action: 'phone-link',
                  location: 'How to contact us',
                  label: config?.phoneNumbers?.generalAlt,
                })
              }
            />
          </Text>
          <br />
          <Text>To write to us, please address your letter to:</Text>
          <Text>Gem</Text>
          <Text>PO Box 4058</Text>
          <Text>Shortland Street</Text>
          <Text>Auckland 1140</Text>
        </div>
      );
    },
  },
  {
    number: '26.',
    title: 'Financial Advice – Prescribed Statement Disclosure',
    content:
      'You are protected by responsible lending laws. Because of these protections, the recommendations given to you by us about personal loans, credit cards and any credit related insurance products are not regulated financial advice. This means that duties and requirements imposed on people who give financial advice do not apply to these recommendations. This includes a duty to comply with a code of conduct and a requirement to be licensed.',
  },
];
