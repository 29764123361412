import { theme } from 'lfs-ui/v3/layout/theme';

export const davidJonesV3Theme = {
  ...theme,
  components: {
    ...theme.components,
    CustomCheckbox: {
      baseStyle: ({ colorMode }) => {
        return {
          container: {
            _checked: {
              svg: {
                color: 'white',
                bg: 'black',
              },
            },
            _focus: {},
            px: 0,
            py: 3,
          },
          icon: {
            bg: 'white',
            borderColor: 'black',
            borderStyle: 'solid',
            borderWidth: 2,
            height: 5,
            mr: 4,
            padding: 2,
            width: 5,
          },
        };
      },
    },
  },
};
