import { contactDetails } from './contactDetails';
import { personalDetails } from './personalDetails';
import { biometricsAboutYou } from './biometricsAboutYou';
import { biometricsIdentification } from './biometricsIdentification';
import { biometricsAddressDetails } from './biometricsAddressDetails';
import { extraDetails } from './extraDetails';
import { otherDetails } from './otherDetails';
import { additionalDebtsDetails } from './additionalDebtsDetails';
import { expensesDetails } from './expensesDetails';
import { employmentDetails } from './employmentDetails';
import { creditLimitDetails } from './creditLimitDetails';
import { preferredCreditLimitDetails } from './preferredCreditLimitDetails';
import { password } from './password';
import { confirmCreditLimit } from './confirmCreditLimit';

export { initialFormValues } from './initialFormValues';
export * from './constants';
export * from './overview';

export const formConfigs = {
  contactDetails,
  biometricsAboutYou,
  biometricsIdentification,
  biometricsAddressDetails,
  personalDetails,
  extraDetails,
  otherDetails,
  additionalDebtsDetails,
  expensesDetails,
  employmentDetails,
  creditLimitDetails,
  preferredCreditLimitDetails,
  password,
  confirmCreditLimit,
};
