import React from 'react';
import { Text } from 'basis';
import { PhoneNumberLink } from 'components';

export const WeWillBeInTouch = () => {
  return (
    <Text margin="0" testId="wellBeInTouch">
      <strong>We'll be in touch within the next 2 days to complete your application.</strong>
      <br />
      If you want to talk to us sooner you can call us on <PhoneNumberLink id="originationsTeam" />.
    </Text>
  );
};
