export const relationshipStatusOptions = [
  {
    value: 'SINGLE_OTHER',
    label: 'Single/Other',
  },
  {
    value: 'MARRIED_DEFACTO',
    label: 'Married/Defacto',
  },
];
