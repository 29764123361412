export const MEDICARE_CARD_GREEN = 'G';
export const MEDICARE_CARD_BLUE = 'B';
export const MEDICARE_CARD_YELLOW = 'Y';

export const medicareCardColourOptions = [
  {
    value: MEDICARE_CARD_GREEN,
    label: 'Green (Standard card)',
  },
  {
    value: MEDICARE_CARD_BLUE,
    label: 'Blue (Interim card)',
  },
  {
    value: MEDICARE_CARD_YELLOW,
    label: 'Yellow (Reciprocal Health Care Agreement card)',
  },
];
