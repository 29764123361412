import React from 'react';

import { Container, Grid, Text } from 'basis';
import { BannerTitleContainer, ImageIcons } from './Overview.styles';

export const ImportantFeatures = ({ smallDevices, scrollTo, SaleIcon, HandIcon, theme }) => {
  return (
    <Container padding="10" hasBreakpointWidth>
      <Grid>
        <Left>
          <BannerTitleContainer>
            <ImageIcons src={HandIcon} alt="Make it yours, interest free" theme={theme} />
            <Text textStyle="heading5">Make it yours, long-term Interest Free*</Text>
          </BannerTitleContainer>
          <Text textStyle="legal">
            Enjoy it now, pay later, Interest Free* deals with some of New Zealand’s leading retailers, online, and
            in-store.
          </Text>
        </Left>

        <Right>
          <BannerTitleContainer>
            <ImageIcons src={SaleIcon} alt="6 months interest free" theme={theme} />
            <Text textStyle="heading5">6 months Interest Free*</Text>
          </BannerTitleContainer>
          <Text textStyle="legal">
            Enjoy more time to pay with 6 months Interest Free* on everyday purchases of $250 or more. Use it instore,
            online and worldwide.
          </Text>
        </Right>
      </Grid>
    </Container>
  );
};

function Left({ children, ...props }) {
  return (
    <Grid.Item colSpan="all" colSpan-md="1-2" {...props}>
      <Container padding="8 0" padding-md="0 10 0 0" hasBreakpointWidth>
        {children}
      </Container>
    </Grid.Item>
  );
}

function Right({ children, ...props }) {
  return (
    <Grid.Item colSpan="all" colSpan-md="3-4" {...props}>
      {' '}
      <Container padding="8 0" padding-md="0 0 0 10" hasBreakpointWidth>
        {children}{' '}
      </Container>
    </Grid.Item>
  );
}
