export const initialFormValues = {
  overview: {
    promoCode: '',
    consentGiven: false,
    applicationReason: '',
    conditionalStop: '',
  },
  contactDetails: {
    mobileNumber: '',
    emailAddress: '',
    confirmEmail: '',
  },
  verification: {
    verificationCode: '',
  },
  password: {
    password: '', // verbal password. Required when a user doesn't have a valid biometrics verification.
  },
  biometricsAboutYou: {
    title: '',
    relationshipStatus: '',
    numberOfDependents: '',
  },
  biometricsIdentification: {
    idType: '',
    licenceNumber: '',
    version: '',
    idNumber: '',
    expiryDate: {
      day: '',
      month: '',
      year: '',
    },
    nzResidencyNumber: '',
    issuingCountry: '',
  },
  biometricsAddressDetails: {
    residentialStatus: '',
    updateCurrentAddress: '',
    changeAddress: false,
    currentAddress: {
      useManualAddress: false,
      address: {
        unitNumber: '',
        streetNumber: '',
        streetName: '',
        streetType: '',
        suburb: '',
        city: '',
        postcode: '',
        poBoxNumber: '',
        addressType: '',
        countryCode: '',
      },
    },
    previousAddress: {
      useManualAddress: false,
      address: {
        unitNumber: '',
        streetNumber: '',
        streetName: '',
        streetType: '',
        suburb: '',
        city: '',
        postcode: '',
        poBoxNumber: '',
        addressType: '',
        countryCode: '',
      },
    },
    postalAddress: {
      useManualAddress: false,
      address: {
        unitNumber: '',
        streetNumber: '',
        streetName: '',
        streetType: '',
        suburb: '',
        city: '',
        postcode: '',
        poBoxNumber: '',
        addressType: '',
        countryCode: '',
      },
    },
    hasNonDomesticPreviousAddress: false,
    isPostalSameWithResidential: '',
    lengthOfCurrentResidency: {
      years: '',
      months: '',
    },
  },
  personalDetails: {
    title: '',
    firstName: '',
    middleName: '',
    lastName: '',
    dateOfBirth: {
      day: '',
      month: '',
      year: '',
    },
  },
  otherDetails: {
    residentialStatus: '',
    lengthOfCurrentResidency: {
      years: '',
      months: '',
    },
    isPostalSameWithResidential: '',
    hasNonDomesticPreviousAddress: false,
    currentAddress: {
      useManualAddress: false,
      address: {
        unitNumber: '',
        streetNumber: '',
        streetName: '',
        streetType: '',
        suburb: '',
        city: '',
        postcode: '',
        poBoxNumber: '',
        addressType: '',
        countryCode: '',
      },
    },
    previousAddress: {
      useManualAddress: false,
      address: {
        unitNumber: '',
        streetNumber: '',
        streetName: '',
        streetType: '',
        suburb: '',
        city: '',
        postcode: '',
        poBoxNumber: '',
        addressType: '',
        countryCode: '',
      },
    },
    postalAddress: {
      useManualAddress: false,
      address: {
        unitNumber: '',
        streetNumber: '',
        streetName: '',
        streetType: '',
        suburb: '',
        city: '',
        postcode: '',
        poBoxNumber: '',
        addressType: '',
        countryCode: '',
      },
    },
  },
  extraDetails: {
    relationshipStatus: '',
    numberOfDependents: '',
    idType: '',
    licenceNumber: '',
    version: '',
    idNumber: '',
    expiryDate: {
      day: '',
      month: '',
      year: '',
    },
    nzResidencyNumber: '',
    issuingCountry: '',
  },
  expensesDetails: {
    isExpensesShared: '',
    isRevolvingOrOffsetMortgage: '',
    facilityLimit: '',
    // Your residential expenses
    primaryPropertyMortgageRentBoard: {
      amount: '',
      frequency: 'monthly',
    },
    secondaryPropertyMortgageRentBoard: {
      amount: '',
      frequency: 'monthly',
    },
    propertyRates: {
      amount: '',
      frequency: 'monthly',
    },
    propertyUtilitiesOther: {
      amount: '',
      frequency: 'monthly',
    },
    // Your living expenses
    foodGroceriesLifestyleEntertainment: {
      amount: '',
      frequency: 'monthly',
    },
    healthLifeInsurance: {
      amount: '',
      frequency: 'monthly',
    },
    generalInsurance: {
      amount: '',
      frequency: 'monthly',
    },
    transport: {
      amount: '',
      frequency: 'monthly',
    },
    educationChildcare: {
      amount: '',
      frequency: 'monthly',
    },
    personalFitnessMedical: {
      amount: '',
      frequency: 'monthly',
    },
    other: {
      amount: '',
      frequency: 'monthly',
    },
  },
  employmentDetails: {
    occupation: '',
    industry: '',
    employerName: '',
    lengthOfTimeAtCurrentEmployer: {
      years: '',
      months: '',
    },
    lengthOfUnemployment: {
      years: '',
      months: '',
    },
    income: {
      amount: '',
      frequency: 'monthly',
    },
    employmentStatus: '',
    hasOtherIncome: '',
    otherEmploymentStatus: '',
    otherIncome: {
      amount: '',
      frequency: 'monthly',
    },
    expectedIncomeChange: '',
    incomeAdjustmentRequired: '',
    conditionalStop: '',
  },
  additionalDebtsDetails: {
    hasOtherDebts: '',
    homeLoans: '',
    totalCardLimits: '',
    amountOwingOnCreditCard: '',
    loanRepayment: {
      amount: '',
      frequency: 'monthly',
    },
    hasLoanLumpSumPayment: '',
    payLumpSumWithExistingSaving: '',
  },
  creditLimitDetails: {
    creditLimitSource: '',
    creditLimit: '',
  },
  confirmCreditLimit: {
    newCreditLimit: '',
  },
  acceptance: {
    accepted: false,
  },
};
