import React from 'react';
import { Text, Stack, Container, Grid, useTheme as useBasisTheme } from 'basis';
import { LayoutOutcomePage, ReturnButton } from 'components';
import { APPLE_MERCHANT, config } from '_config';
import { displayCreditLimit } from 'utils';
import { useTheme } from '@emotion/react';
import { AppleApplicantsBox } from '../components/AppleApplicantsBox';
import { AplyIdSteps } from '../components/AplyIdSteps';
import { PhoneIcon } from '../Outcome.styles';
import phone from '../../../images/icon-phone.svg';
import { WeWillBeInTouch } from '../components/WeWillBeInTouch';

function AntiMoneyLaundering({ storeState, withPOI = false }) {
  const theme = useTheme();
  const basisTheme = useBasisTheme();
  const {
    applyAndBuy: { merchantName },
    applicationRef,
  } = storeState;

  const handleVerifyNowClick = () => {
    // eslint-disable-next-line
    // TODO: Analytics here?
  };
  const creditLimit = displayCreditLimit(storeState);

  return (
    <LayoutOutcomePage useConfetti>
      <Stack gap="8">
        <Text as="h1" textStyle="heading5" textStyle-md="heading4" color={theme.headingColor}>
          Congratulations!
          <br />
          {`You’re conditionally approved for $${creditLimit}`}
          <br />
          {withPOI ? 'We just need to verify your income and ID' : 'We just need to verify your ID'}
        </Text>
        <Text testId="gotEverythingForNow">
          Your application number is <strong data-hj-suppress>{applicationRef}</strong>.
        </Text>
        <AplyIdSteps
          type="ID_ONLY"
          linkLabel="Verify now"
          linkHref={config.aplyIdUrl}
          linkOnClick={handleVerifyNowClick}
        />

        {withPOI && (
          <>
            <Text textStyle="heading6">Income verification</Text>
            <br />
            <Container>
              <Text testId="gotEverythingForNow">We've got everything we need for now.</Text>
              <Grid margin="6 0 0">
                <Grid.Item rowSpan="1">
                  <PhoneIcon src={phone} theme={basisTheme} />
                </Grid.Item>
                <Grid.Item rowSpan="1">
                  <WeWillBeInTouch />
                </Grid.Item>
              </Grid>
            </Container>
          </>
        )}

        {merchantName === APPLE_MERCHANT && <AppleApplicantsBox storeState={storeState} />}
        <ReturnButton variant="text" />
      </Stack>
    </LayoutOutcomePage>
  );
}

export { AntiMoneyLaundering };
