import { FieldCheckbox } from 'components';
import React from 'react';
import { FIELD_ADDITIONAL_LOANS, FIELD_DISCLOSED_DEBTS } from '../constants';

export function HomeLoanCheckbox({ id, conditional }) {
  return (
    <FieldCheckbox
      id={id}
      label="This loan is associated with my primary residence"
      conditional={conditional}
      rules={{
        validate: (value, values) => {
          const allDebts = [...(values[FIELD_DISCLOSED_DEBTS] || []), ...(values[FIELD_ADDITIONAL_LOANS] || [])];
          const allNotTicked = allDebts.every(debt => !debt?.isPrimaryResidencyAssociated);
          return conditional && allNotTicked ? 'Must select at least one primary residence' : null;
        },
      }}
    />
  );
}
