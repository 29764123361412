import React from 'react';
import { Modal } from 'components/modal/Modal';
import { Button, Container, Flex, List, Text } from 'basis';
import { OverviewModalContent } from 'siteContent/overviewContent';

const FeaturesAndBenefitsModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Container padding="3" padding-md="10">
        <Container padding="4 2 8">
          <Flex placeItems="left center">
            <Text textStyle="heading6" textStyle-md="heading4" color="highlight.blue.t100">
              {OverviewModalContent.title}
            </Text>
          </Flex>
        </Container>

        <List>
          {OverviewModalContent.body.map((content, key) => {
            return (
              <List.Item key={key}>
                <Container padding="0 6 6 0">
                  <Text textStyle="heading6" testId={`textSubtitle-${key}`}>
                    {content.subtitle}
                  </Text>
                  <Text textStyle="body1" testId={`textDescription-${key}`}>
                    {content.description}
                  </Text>
                </Container>
              </List.Item>
            );
          })}
        </List>

        <Flex placeItems="center">
          <Container padding="2">
            <Button width="160px" onClick={onClose}>
              Close
            </Button>
          </Container>
        </Flex>
      </Container>
    </Modal>
  );
};

export { FeaturesAndBenefitsModal };
