import { titleOptionsNZ, validNamePattern } from '../_constants';
import { minAgeMaxDate, minAge, maxAgeDate, maxAge } from './constants';

export const nameValidationMessages = {
  required: `Please enter your {{typeName}} as shown on your ID.`,
  minLength: 'Must have at least {{minLength}} characters',
  maxLength: 'Must have at most {{maxLength}} characters',
  invalid: 'Please enter a valid name',
};

const dateOfBirthValidationMessages = {
  required: 'Please enter your date of birth.',
  invalid: 'Please enter a valid date.',
  completeYear: 'Please enter the complete year YYYY.',
  maxDate: `Must be ${minAge} years of age or older.`,
  minDate: `The maximum age for a Gem Visa is ${maxAge} years old. Please update.`,
  dayError: 'Day must be within 1-31.',
  monthError: 'Month must be within 1-12.',
};

const validationMessages = {
  firstName: nameValidationMessages,
  middleName: nameValidationMessages,
  lastName: nameValidationMessages,
  dateOfBirth: dateOfBirthValidationMessages,
};

const fields = [
  {
    component: 'Select',
    name: 'title',
    label: 'Title',
    options: titleOptionsNZ,
    testId: 'title',
    validate: {
      messages: {
        required: 'Please select a title.',
      },
    },
  },
  {
    component: 'Input',
    name: 'firstName',
    label: 'First name',
    testId: 'first-name',
    helpText: 'Legal name as shown on ID.',
    validate: {
      props: { minLength: 1, maxLength: 15, typeName: 'first name', validPattern: validNamePattern },
      messages: validationMessages.firstName,
    },
  },
  {
    component: 'Input',
    name: 'middleName',
    label: 'Middle name',
    testId: 'middle-name',
    helpText: 'Only provide if you have one.',
    validate: {
      props: { minLength: 1, maxLength: 15, validPattern: validNamePattern },
      messages: validationMessages.middleName,
    },
    optional: true,
  },
  {
    component: 'Input',
    name: 'lastName',
    label: 'Last name',
    testId: 'last-name',
    helpText: 'Legal name as shown on ID.',
    validate: {
      props: { minLength: 1, maxLength: 23, typeName: 'last name', validPattern: validNamePattern },
      messages: validationMessages.lastName,
    },
  },
  {
    component: 'DatePicker',
    name: 'dateOfBirth',
    label: 'Date of birth',
    testId: 'date-of-birth',
    validate: {
      props: {
        maxDate: minAgeMaxDate,
        minDate: maxAgeDate.toISOString().split('T')[0],
        validPattern: '[1-9]+',
      },
      messages: validationMessages.dateOfBirth,
      component: 'DatePicker',
    },
  },
];

export const personalDetails = fields;
