import React from 'react';
import { Modal } from 'components/modal/Modal';
import { useTheme } from '@emotion/react';
import { Container, Text, Button, Grid } from 'basis';
import styled from '@emotion/styled';

const VirtualLink = styled.span`
  font-weight: 300;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  -webkit-transition: background-color 200ms ease-out, border-bottom-color 200ms ease-out;
  transition: background-color 200ms ease-out, border-bottom-color 200ms ease-out;
  color: #0046aa;
  border-bottom-color: rgba(0, 70, 170, 0.5);
  cursor: pointer;
  &:hover {
    border-bottom-color: #0046aa;
    background-color: #d8edff;
  }
`;

export const BeforeUploadDocumentsModal = ({ isOpen, handleShareBankStatement, onContinue, onClose }) => {
  const theme = useTheme();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Container padding="3" padding-md="8" testId="beforeUploadDocuments">
        <Text textStyle="heading4" color={theme.headingColor} margin="0 0 6 0" margin-md="0 0 7 0">
          Before you upload your documents
        </Text>
        <Container>
          <Text textStyle="body1">
            <b>Providing documents to prove your income will mean you'll receive a decision by email within 2 days.</b>
          </Text>
        </Container>
        <Container padding="6 0">
          <Text textStyle="body2">
            If you need a decision ASAP, we recommend you{' '}
            <VirtualLink data-testid="linkToFiConnect" onClick={handleShareBankStatement}>
              share your bank statements online
            </VirtualLink>
            .
          </Text>
        </Container>
        <Container padding="4 0" padding-md="4">
          <Grid preset="page">
            <Grid.Item colSpan="all" colSpan-sm="1-6" colSpan-md="1-6" colSpan-lg="2-9">
              <Grid rowsGap="4" colsGap="2">
                <Grid.Item colSpan="all" rowSpan="1" colSpan-sm="0-6" rowSpan-sm="0">
                  <Grid>
                    <Button testId="goBackButton" type="button" variant="secondary" onClick={onClose}>
                      Go back
                    </Button>
                  </Grid>
                </Grid.Item>
                <Grid.Item colSpan="all" colSpan-sm="8-12" alignItems="center">
                  <Grid>
                    <Button testId="continueToUploadDocuments" onClick={onContinue}>
                      Continue
                    </Button>
                  </Grid>
                </Grid.Item>
              </Grid>
            </Grid.Item>
          </Grid>
        </Container>
      </Container>
    </Modal>
  );
};
