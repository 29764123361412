import styled from '@emotion/styled';

export const IconTitleWrapper = styled.div`
  display: flex;
  margin-top: 14px;
  margin-bottom: 32px;
  & > img {
    margin-right: 8px;
  }

  ${props => `
  ${`@media (min-width: ${props.theme.screenSize.sm})`} {
    font-size: 18px;
  }
`}
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props => `
    ${`@media (min-width: ${props.theme.screenSize.sm})`} {
      & > div {
        width: 100%;
      }
    }
    ${`@media (min-width: ${props.theme.screenSize.lg})`} {
      & > div:first-of-type {
        height: 480px;
      }

      & > div {
        max-width: 1220px;
        display: flex;
      }
    }
  `}
`;

export const AnchorTag = styled.a`
  font-size: 8px;
  vertical-align: top;
  text-decoration: underline;
  cursor: pointer;
  ${props => `
    color: ${props.theme.a.color};
  `};
`;

export const BannerTitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
`;

export const ImageIcons = styled.img`
  height: 68px;
  margin-right: 16px;
  ${props => `
      ${`@media (min-width: ${props.theme.screenSize.lg})`} {
        height: 68px;
  `};
`;

export const HeaderBannerTextStyle = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  width: 100%;
  height: 100%;
  ${props => `
      ${props.theme.minMediaQueries.md} {
        background-color: transparent !important;
        height: 100% !important;
      }

      ${props.theme.minMediaQueries.xs} {
        height: 240px;
      }
  `}

  & div {
    background-color: transparent !important;
  }
`;
