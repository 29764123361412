import { useLocation } from 'react-router-dom';
import { getSiteSubSection, getPageTitle } from 'utils';
import { useStoreValue } from 'store';

export const useRoutePageInfo = () => {
  const [store] = useStoreValue();
  const location = useLocation();
  const siteSubSection = getSiteSubSection(location.pathname);
  const pageTitle = getPageTitle(location.pathname, store.applicationStatus);
  return { siteSubSection, pageTitle, pathname: location.pathname };
};
