import { Container, Input, OtpInput, Link, Stack } from 'basis';
import { Text } from 'components/text';
import React from 'react';
import { byCountry } from 'utils';

const NUMERIC_REGEX = /^\d*$/;

function validate(value, { isEmpty, data }) {
  const codeLength = Number(data.codeLength);
  if (isEmpty(value)) {
    return `Verification code must be ${codeLength} digits`;
  }
  const trimmedValue = value.trim();
  if (NUMERIC_REGEX.test(trimmedValue) === false) {
    return 'Verification code must be 6 numeric characters';
  }
  if (trimmedValue.length !== codeLength) {
    return `Verification code must be ${codeLength} digits`;
  }
  return null;
}

export function BasisOneTimeCode(props) {
  const Component = byCountry({
    NZ: NzBasisOneTimeCode,
    AU: AuBasisOneTimeCode,
  });
  return <Component {...props} />;
}

function AuBasisOneTimeCode({
  factorTarget,
  name = 'oneTimeCode',
  codeLength = 6,
  width = 'full',
  loading,
  state,
  onResendCodeClick,
  factor = 'sms',
}) {
  return (
    <Stack gap="2">
      <Text textStyle="heading6">Enter your verification code</Text>
      <Text>
        We've just sent an {factor} verification code to{' '}
        <strong>{factor === 'sms' ? factorTarget.replace(/[X*]/gi, '•') : factorTarget}</strong>.
        <br />
        Please enter the {codeLength} digit code. The code will expire in 5 minutes.
      </Text>
      <Stack gap="2" width={width}>
        <div style={{ maxWidth: 300 }}>
          <OtpInput
            name={name}
            validate={validate}
            validateData={{ codeLength }}
            disabled={state === 'pending'}
            testId="verification-code"
          />
        </div>
        <Container textStyle="body2">
          <Stack gap="2" direction="horizontal">
            <Text>Not received your code yet?</Text>
            {loading || state === 'pending' ? (
              <Text>
                <strong>Sending...</strong>
              </Text>
            ) : state === 'success' ? (
              <Text color="conditional.positive.text">
                <strong>Code sent</strong>
              </Text>
            ) : state === 'error' ? (
              <Text color="conditional.negative.text">
                <strong>Error sending, please wait...</strong>
              </Text>
            ) : (
              <Text textStyle="body2">
                <Link href="" newTab={false} onClick={onResendCodeClick}>
                  Resend code
                </Link>
              </Text>
            )}
          </Stack>
        </Container>
      </Stack>
    </Stack>
  );
}

function NzBasisOneTimeCode({
  factorTarget,
  name = 'oneTimeCode',
  label = 'Verification code',
  codeLength = 6,
  width = 'full',
  loading,
  state,
  onResendCodeClick,
  factor = 'sms',
}) {
  return (
    <Stack gap="8">
      <Text textStyle="heading5">Enter your verification code</Text>
      <Text>
        Latitude has sent an {factor} verification code to{' '}
        <strong>{factor === 'sms' ? factorTarget.replace(/[X*]/gi, '•') : factorTarget}</strong>.
        <br />
        Please enter the {codeLength} digit code.
      </Text>
      <Stack gap="2" width={width}>
        <Input
          name={name}
          label={label}
          variant="numeric"
          maxLength={codeLength}
          autoComplete="one-time-code"
          validate={validate}
          validateData={{ codeLength }}
          testId="verification-code"
        />
        <Container textStyle="body2">
          <Stack gap="2" direction="horizontal">
            <Text>Haven't received your code yet?</Text>
            {loading || state === 'pending' ? (
              <Text>
                <strong>Sending...</strong>
              </Text>
            ) : state === 'success' ? (
              <Text color="conditional.positive.text">
                <strong>Code sent</strong>
              </Text>
            ) : state === 'error' ? (
              <Text color="conditional.negative.text">
                <strong>Error sending, please wait...</strong>
              </Text>
            ) : (
              <Text textStyle="body2">
                <Link href="" newTab={false} onClick={onResendCodeClick}>
                  Resend code
                </Link>
              </Text>
            )}
          </Stack>
        </Container>
      </Stack>
    </Stack>
  );
}
