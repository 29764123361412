import { useEffect } from 'react';
import { useStoreValue } from 'store';
import { DEV_FEATURES } from '__dev/devFeatures';

const beforeUnloadHandler = e => {
  e.preventDefault();
  e.returnValue = 'Your application progress may be lost';
};

export const useSessionUnload = () => {
  const [{ applicationId }] = useStoreValue();

  useEffect(() => {
    if (!applicationId || DEV_FEATURES.PREVENT_UNLOAD_MESSAGE) {
      return () => undefined;
    }

    window.addEventListener('beforeunload', beforeUnloadHandler, { capture: true });

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler, { capture: true });
    };
  }, [applicationId]);
};
