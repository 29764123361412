import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useHandleApiErrors } from '../useHandleApiErrors';

export const UPDATE_POI_TYPE_GQL = gql`
  mutation updatePoiVerificationType($input: UpdatePoiVerificationTypeInput!) {
    updatePoiVerificationType(input: $input) {
      status
    }
  }
`;

export function useUpdatePOIType({ storeState, onSuccess, onError }) {
  const { applicationId } = storeState;

  const { handleError } = useHandleApiErrors('apollo', onError);
  const [updatePOIType, { loading }] = useMutation(UPDATE_POI_TYPE_GQL, {
    onCompleted: data => {
      onSuccess(data.updatePoiVerificationType);
    },
    onError: err => {
      handleError(err);
    },
  });
  const updatePOI = useCallback(
    poiType => {
      if (poiType) {
        const input = { applicationId, poiType };
        updatePOIType({ variables: { input } });
      }
    },
    [applicationId, updatePOIType],
  );

  return {
    updatePOI,
    loading,
  };
}
