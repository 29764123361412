import React from 'react';
import { Text, Container, Button, Message } from 'basis';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { PRODUCT_CODE_LOW_RATE } from '_config';
import { byCard, getProductTeamName } from 'utils';
import { YellowMessage } from '../message/YellowMessage';

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  width: 100%;
`;

const ContentWrapper = styled.div`
  max-width: 450px;
  margin: ${props => (props.margin ? props.margin : '8px')};
`;

export function NetworkErrors({ applicationRef, retry, retrying }) {
  const theme = useTheme();
  const productName = getProductTeamName();

  return (
    <Container padding="8 0" testId="network-error" hasBreakpointWidth>
      <ErrorWrapper>
        <ContentWrapper>
          <Container padding="4 0" margin-md="4 0">
            <Text as="h4" textStyle="heading5" textStyle-md="heading4" color={theme.headingColor} align="left">
              Oh no! We’ve lost connection!
            </Text>
          </Container>

          {applicationRef && (
            <Container padding="0 0 4 0">
              <Text testId="network-error-application-ref">
                Application reference number: <b>{applicationRef}</b>
              </Text>
            </Container>
          )}
          <Container margin="2 0">
            <YellowMessage>
              <Message testId="yellow-message" severity="warning-or-significant">
                <Text>
                  <b>Looks like we lost network connection. </b>
                </Text>
                <Text margin="2 0 0 0">
                  Don't worry, your application details are safe. Click the 'Continue with application' button below to{' '}
                  resume.
                </Text>
              </Message>
            </YellowMessage>
          </Container>

          {retry && (
            <Container padding="6 0 0 0">
              <Button testId="retry" loading={retrying} onClick={retry} width="100%">
                Continue with application
              </Button>
            </Container>
          )}

          <Container padding="10 0 0 0">
            <Text align="left">Thank you</Text>
          </Container>
          <Container padding="8 0 0 0">
            <Text align="left">
              The {productName}{' '}
              {byCard({
                default: `credit card team`,
                [PRODUCT_CODE_LOW_RATE]: `Team`,
              })}
            </Text>
          </Container>
        </ContentWrapper>
      </ErrorWrapper>
    </Container>
  );
}
