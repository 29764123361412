import React from 'react';
import { Text, Container, Sticky } from 'basis';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';

import { Header } from 'components/header/Header';
import { Footer } from 'components/footer/Footer';
import { config } from '_config';
import { getFeatureValue } from 'featureToggles';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  width: 100%;
`;

const ContentWrapper = styled.div`
  max-width: 600px;
  min-height: 500px;
  margin: ${props => (props.margin ? props.margin : '16px')};
`;

export function Maintenance() {
  const theme = useTheme();

  return (
    <>
      <Sticky>
        <Sticky.Item>
          <Header />
        </Sticky.Item>
        <Container padding="12 0" testId="error" hasBreakpointWidth>
          <Wrapper>
            <ContentWrapper>
              <Container padding="8 0 0 0">
                <Text as="h5" textStyle="heading5" color={theme.headingColor} align="left">
                  Our application is currently undergoing maintenance
                </Text>
              </Container>

              <Container padding="8 0">
                <Text as="p">{getFeatureValue('maintenanceMessage')[config.productCode]}</Text>
              </Container>
            </ContentWrapper>
          </Wrapper>
        </Container>
      </Sticky>
      <Footer footer="OVERVIEW" />
    </>
  );
}
