import React from 'react';
import { ComparisonFooter, EarnRateTiers, Header } from 'components';
import { Button, Container, Grid, Link, Stack, Text, Sticky } from 'basis';
import { useTheme as emotionUseTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { useEventTracking } from 'react-event-tracker';
import premiereCardImageMobile from 'images/premiere-card.png';
import prestigeCardImageMobile from 'images/prestige-card.png';
import tickBlack from 'images/tick-black.svg';
import { ProductComparisonLinks } from '_config';
import background from 'images/dj-product-compare-bg.svg';
import { getFeatures } from 'featureToggles';
import {
  config,
  COUNTRY_CODE_AU,
  STEP_DAVID_JONES_PREMIERE,
  STEP_DAVID_JONES_PRESTIGE,
  PRODUCT_CODE_DAVID_JONES_PREMIERE,
  PRODUCT_CODE_DAVID_JONES_PRESTIGE,
  setConfig,
} from '../../_config';
import { useStoreValue } from '../../store';
import {
  DAVID_JONES_PREMIERE_QR_CODE,
  DAVID_JONES_PRESTIGE_QR_CODE,
} from '../../hooks/useApplyAndBuyQueryParams/params';

const Image = styled.img`
  ${config.countryCode === 'AU' && `width: 65%;`}
`;
const Div = styled.div`
  display: grid;
  justify-items: center;
  width: 100%;
`;
const CheckList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

const CheckListItem = styled.li`
  display: flex;
  margin: 8px auto;
  img {
    margin-top: 2px;
  }
`;
const P = styled.p`
  font-size: 14px;
  font-weight: 300;
  margin: 0 0 0 8px;
  line-height: 20px;

  ul {
    font-size: 14px;
  }
`;

const Strong = styled.strong`
  font-weight: 600 !important;
  font-family: 'montserrat', 'SemiBold';
  font-size: ${p => (p.large ? '20px' : '18px')};
`;

const LinkWrapper = styled.div`
  a {
    span {
      font-weight: 500;
      font-size: 16px;
    }
  }
`;

const CompareProductContainer = styled.div`
  background: url('${background}') center;
`;

function CompareProducts() {
  const theme = emotionUseTheme();
  const history = useHistory();
  const { trackEvent } = useEventTracking();
  const [storeState, updateStore] = useStoreValue();

  const { prestigeRatesAndFees, premiereRatesAndFees } = getFeatures();

  const onApplyNow = productCode => {
    setConfig({
      countryCode: COUNTRY_CODE_AU,
      productCode,
    });
    trackEvent({
      event: {
        category: 'application',
        action: 'application-navigation',
        location:
          productCode === PRODUCT_CODE_DAVID_JONES_PREMIERE
            ? 'product-comparison-premiere'
            : 'product-comparison-prestige',
        label: 'Apply now',
      },
    });

    const applyAndBuyParams =
      productCode === PRODUCT_CODE_DAVID_JONES_PREMIERE ? DAVID_JONES_PREMIERE_QR_CODE : DAVID_JONES_PRESTIGE_QR_CODE;
    const { applyAndBuy } = storeState;

    updateStore({
      applyAndBuy: { ...applyAndBuy, ...applyAndBuyParams },
      countryCode: COUNTRY_CODE_AU,
      productCode,
    });
    const path =
      productCode === PRODUCT_CODE_DAVID_JONES_PREMIERE ? STEP_DAVID_JONES_PREMIERE : STEP_DAVID_JONES_PRESTIGE;
    history.push(path);
  };

  const PrestigeCard = () => {
    return (
      <Container padding="4 0 0 0" padding-md="4 0 0 0">
        <Grid preset="page" debug={false}>
          <Grid.Item colSpan="all" colSpan-sm="1-6" colSpan-md="2-5" colSpan-lg="3-8" colSpan-xl="4-7">
            <Grid rowsGap={0}>
              <Container padding="5" margin="0 4 0 4" bg="white">
                <Grid preset="page" colsGap={5}>
                  <Grid.Item colSpan="all">
                    <Grid rowsGap={6}>
                      <Div padding="5 0 0 0">
                        <Image src={prestigeCardImageMobile} alt="David Jones Card" />
                      </Div>
                      <Stack align="center">
                        <Text as="p" textStyle="subtitle2" color={theme.headingColor} align="center">
                          <Strong>David Jones Prestige credit card</Strong>
                        </Text>
                        <Text as="p" textStyle="subtitle2" color={theme.headingColor} align="center">
                          <Strong large>${prestigeRatesAndFees?.annualFee}</Strong> annual fee
                        </Text>
                      </Stack>
                      <CheckList>
                        <CheckListItem>
                          <div>
                            <img src={tickBlack} alt="" />
                          </div>
                          <P>
                            Earn David Jones Points -
                            <EarnRateTiers productCode={PRODUCT_CODE_DAVID_JONES_PRESTIGE} />
                          </P>
                        </CheckListItem>
                        <CheckListItem>
                          <div>
                            <img src={tickBlack} alt="" />
                          </div>
                          <P>
                            Exclusive access to David Jones sales, previews and events, complimentary same day delivery,{' '}
                            gift wrapping, alterations when shopping instore at David Jones and more.
                          </P>
                        </CheckListItem>
                        <CheckListItem>
                          <div>
                            <img src={tickBlack} alt="" />
                          </div>
                          <P>Complimentary travel insurance.</P>
                        </CheckListItem>
                        <CheckListItem>
                          <div>
                            <img src={tickBlack} alt="" />
                          </div>
                          <P>Access to two complimentary airport lounge passes each year.</P>
                        </CheckListItem>
                        <CheckListItem>
                          <div>
                            <img src={tickBlack} alt="" />
                          </div>
                          <P>Special discounts on selected hotel bookings through Expedia, Wotif, & Booking.com.</P>
                        </CheckListItem>
                        <CheckListItem>
                          <div>
                            <img src={tickBlack} alt="" />
                          </div>
                          <P>Complimentary purchase protection and eCommerce protection insurance.</P>
                        </CheckListItem>
                        <CheckListItem>
                          <div>
                            <img src={tickBlack} alt="" />
                          </div>
                          <P>Additional cardholders are free.</P>
                        </CheckListItem>
                      </CheckList>
                      <Container padding="0 0" padding-md="0">
                        <Grid preset="page" colsGap={3} colsGap-sm={3}>
                          <Grid.Item colSpan="0-1" colSpan-sm="0-3" colSpan-md="0-3" colSpan-lg="0-5" rowSpan-sm="0">
                            <Grid>
                              <Link
                                testId="prestige-learn-more"
                                appearance="secondary-button"
                                href={ProductComparisonLinks.dj_prestige_compare.url}
                                newTab
                                onClick={() => {
                                  trackEvent({
                                    event: {
                                      category: 'application',
                                      action: 'external-link',
                                      location: 'product-comparison-prestige',
                                      label: 'Learn more',
                                    },
                                  });
                                }}
                              >
                                Learn more
                              </Link>
                            </Grid>
                          </Grid.Item>
                          <Grid.Item
                            colSpan="2-3"
                            colSpan-sm="4-7"
                            colSpan-md="4-7"
                            colSpan-lg="6-11"
                            alignItems="center"
                          >
                            <Grid>
                              <Button
                                testId="apply-prestige-button"
                                type="button"
                                disabled={() => {}}
                                padding={1}
                                onClick={() => {
                                  onApplyNow(PRODUCT_CODE_DAVID_JONES_PRESTIGE);
                                }}
                              >
                                Apply now
                              </Button>
                            </Grid>
                          </Grid.Item>
                        </Grid>
                      </Container>
                    </Grid>
                  </Grid.Item>
                </Grid>
              </Container>
            </Grid>
          </Grid.Item>
        </Grid>
      </Container>
    );
  };
  const PremiereCard = () => {
    return (
      <Container padding="5 0 0 0" padding-md="4 0 0 0" testId="LAYOUT FORM">
        <Grid preset="page" debug={false}>
          <Grid.Item colSpan="all" colSpan-sm="1-6" colSpan-md="2-5" colSpan-lg="3-8" colSpan-xl="4-7">
            <Grid rowsGap={0}>
              <Container padding="5" margin="0 4 4 4" bg="white">
                <Grid preset="page" colsGap={5}>
                  <Grid.Item colSpan="all">
                    <Grid rowsGap={6}>
                      <Div padding="5 0 0 0">
                        <Image src={premiereCardImageMobile} alt="David Jones Card" />
                      </Div>
                      <Stack align="center">
                        <Text as="p" textStyle="subtitle2" color={theme.headingColor} align="center">
                          <Strong>David Jones Premiere credit card</Strong>
                        </Text>
                        <Text as="p" textStyle="subtitle2" color={theme.headingColor} align="center">
                          <Strong large>${premiereRatesAndFees?.annualFee}</Strong> annual fee
                        </Text>
                      </Stack>
                      <CheckList>
                        <CheckListItem>
                          <div>
                            <img src={tickBlack} alt="" />
                          </div>
                          <P>
                            Earn David Jones Points -
                            <EarnRateTiers productCode={PRODUCT_CODE_DAVID_JONES_PREMIERE} />
                          </P>
                        </CheckListItem>
                        <CheckListItem>
                          <div>
                            <img src={tickBlack} alt="" />
                          </div>
                          <P>
                            Access to David Jones sales, previews, complimentary express delivery, gift wrapping and
                            more.
                          </P>
                        </CheckListItem>
                        <CheckListItem>
                          <div>
                            <img src={tickBlack} alt="" />
                          </div>
                          <P>Special discounts on selected hotel bookings through Expedia, Wotif, & Booking.com.</P>
                        </CheckListItem>
                        <CheckListItem>
                          <div>
                            <img src={tickBlack} alt="" />
                          </div>
                          <P>Complimentary eCommerce protection insurance.</P>
                        </CheckListItem>
                      </CheckList>
                      <Container padding="0 0" padding-md="0">
                        <Grid preset="page" colsGap={3} colsGap-sm={3}>
                          <Grid.Item colSpan="0-1" colSpan-sm="0-3" colSpan-md="0-3" colSpan-lg="0-5" rowSpan-sm="0">
                            <Grid>
                              <Link
                                testId="premiere-learn-more"
                                appearance="secondary-button"
                                href={ProductComparisonLinks.dj_premiere_compare.url}
                                onClick={() => {
                                  trackEvent({
                                    event: {
                                      category: 'application',
                                      action: 'external-link',
                                      location: 'product-comparison-premiere',
                                      label: 'Learn more',
                                    },
                                  });
                                }}
                                newTab
                              >
                                Learn more
                              </Link>
                            </Grid>
                          </Grid.Item>
                          <Grid.Item
                            colSpan="2-3"
                            colSpan-sm="4-7"
                            colSpan-md="4-7"
                            colSpan-lg="6-11"
                            alignItems="center"
                          >
                            <Grid>
                              <Button
                                testId="apply-premiere-button"
                                type="submit"
                                disabled={() => {}}
                                padding={1}
                                onClick={() => {
                                  onApplyNow(PRODUCT_CODE_DAVID_JONES_PREMIERE);
                                }}
                              >
                                Apply now
                              </Button>
                            </Grid>
                          </Grid.Item>
                        </Grid>
                      </Container>
                    </Grid>
                  </Grid.Item>
                </Grid>
              </Container>
            </Grid>
          </Grid.Item>
        </Grid>
      </Container>
    );
  };

  return (
    <>
      <Sticky>
        <Sticky.Item>
          <Header />
        </Sticky.Item>
      </Sticky>
      <CompareProductContainer>
        <Container testId="compare-cards" margin="0 0 0 0" padding={0}>
          <Container padding="0" margin="6 6 0 6">
            <Div padding="5 0 0 0">
              <Stack align="center" gap="8">
                <Text as="h1" textStyle="heading4" color={theme.headingColor} align="center">
                  Apply now
                </Text>
                <Div padding="5 0 0 0">
                  <Grid preset="page" debug={false}>
                    <Grid.Item colSpan="all" colSpan-sm="1-6" colSpan-md="2-5" colSpan-lg="3-8" colSpan-xl="4-7">
                      <Text align="center">
                        Choose between the <strong>David Jones Premiere credit card</strong> and the{' '}
                        <strong>David Jones Prestige credit card</strong>.
                      </Text>
                    </Grid.Item>
                  </Grid>
                </Div>
              </Stack>
            </Div>
          </Container>
          <PremiereCard />
          <PrestigeCard />
          <Container padding="0" margin="4 6 5 6">
            <Stack align="center" gap="4">
              <LinkWrapper>
                <Link
                  href={ProductComparisonLinks.dj_compare_cards.url}
                  newTab
                  onClick={() => {
                    trackEvent({
                      event: {
                        category: 'application',
                        action: 'external-link',
                        location: 'product-comparison',
                        label: 'Compare cards',
                      },
                    });
                  }}
                >
                  Compare cards
                </Link>
              </LinkWrapper>
            </Stack>
          </Container>
        </Container>
        <ComparisonFooter />
      </CompareProductContainer>
    </>
  );
}

export { CompareProducts };
