import React, { useState } from 'react';
import { Checkbox, Text, Link } from 'basis';
import { DvsModal } from './Dvs.Modal';

export const DvsConsent = () => {
  const [dvsModalOpen, setDvsModalOpen] = useState(false);

  const handleDvsClick = event => {
    event.preventDefault();
    setDvsModalOpen(true);
  };

  const closeDvsModals = () => {
    setDvsModalOpen(false);
  };

  return (
    <>
      <Checkbox name="dvsConsent" testId="dvs-consent" label="" validate={false}>
        <Text>
          I am authorised to provide the document details and agree to them being checked with the{' '}
          <Link onClick={handleDvsClick} newTab={false} testId="dvs-consent-link">
            identification document issuer
          </Link>{' '}
          and consent to using any identity document provided in the application to verify my identity for this purpose.
          <Text textStyle="body2">e.g. checks your government-issued ID, like your Driver Licence.</Text>
        </Text>
      </Checkbox>
      <DvsModal isOpen={dvsModalOpen} onClose={closeDvsModals} />
    </>
  );
};
