import { AU_MEDICARE, AU_PASSPORT } from '_config/au/constants';
import { MEDICARE_CARD_GREEN } from '_config';

export function toIdvPayload(values) {
  const idAUPassport = toIdAuPassport(values);
  const idAUMedicare = toAuMedicare(values);
  return {
    idType: values.idType,
    idAUPassport,
    idAUMedicare,
  };
}

function toIdAuPassport(values) {
  if (values.idType !== AU_PASSPORT) {
    return null;
  }

  return {
    issuingCountry: 'AU',
    idNumber: values.idNumber,
  };
}
function toAuMedicare(values) {
  if (values.idType !== AU_MEDICARE) {
    return null;
  }

  const name = JSON.parse(values.name || '{}');
  const expiryDate =
    values.medicareCardColour === MEDICARE_CARD_GREEN
      ? {
          day: lastDayOfMonth(values.expiryDateGreen.month, values.expiryDateGreen.year),
          month: Number(values.expiryDateGreen.month),
          year: Number(values.expiryDateGreen.year),
        }
      : {
          day: Number(values.expiryDateNonGreen.day),
          month: Number(values.expiryDateNonGreen.month),
          year: Number(values.expiryDateNonGreen.year),
        };

  return {
    expiryDate,
    medicareCardNumber: values.medicareCardNumber,
    individualReferenceNumber: values.individualReferenceNumber,
    medicareCardMiddleName: name.middleName || null,
    medicareCardColour: values.medicareCardColour,
  };
}

function lastDayOfMonth(monthStr, yearStr) {
  const month = Number(monthStr);
  const year = Number(yearStr);

  // Create a date object of the next month, first day
  const date = new Date(year, month, 1);

  // Subtract one day to get the last day of the previous month
  date.setDate(date.getDate() - 1);

  return date.getDate();
}
