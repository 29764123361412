/* eslint-disable react/no-children-prop */
import React, { useEffect, forwardRef, useCallback } from 'react';
import { Checkbox as V3Checkbox } from 'lfs-ui/v3/Checkbox';
import { Select as V3Select } from 'lfs-ui/v3/Select';
import { useFormContext } from 'react-hook-form';
import {
  InputGroup,
  FormControl,
  FormHelperText,
  FormLabel,
  Input as ChInput,
  Box,
  Text,
  InputLeftElement,
} from '@chakra-ui/react';
import _ from 'lodash';

export function FieldMoneyInput({ maxLength = 10, rules, ...props }) {
  return (
    <FieldInput
      type="number"
      leftElement="$"
      maxLength={maxLength}
      {...props}
      rules={{
        required: 'Please enter a whole dollar amount, without cents.',
        min: { value: 1, message: 'Must not be less than $1.' },
        max: { value: 99999999, message: 'Must not be more than $99,999,999.' },
        pattern: {
          value: /^\d+$/,
          message: 'Please enter a whole dollar amount, without cents.',
        },
        ...rules,
      }}
    />
  );
}

/**
 * Build from scratch because as 2024-02 LFS-UI v3 Input component doesn't support left element like $ sign.
 */
export const FieldInput = forwardRef(
  (
    { id, label, conditional, helpText, leftElement, children, rules, maxLength, type = 'text', ...otherProps },
    ref,
  ) => {
    const {
      register,
      formState: { errors },
      unregister,
    } = useFormContext();

    const error = getError(errors, id)?.message;

    const { isConditionMet } = useConditional({
      conditional,
      unregister,
      id,
    });

    const transformValue = useCallback(
      inputValue => {
        if (maxLength && inputValue !== '' && inputValue.length > maxLength) {
          return inputValue.slice(0, maxLength);
        }
        return inputValue;
      },
      [maxLength],
    );

    const onAfterChange = useCallback(
      event => {
        // eslint-disable-next-line no-param-reassign
        event.target.value = transformValue(event.target.value);
      },
      [transformValue],
    );

    if (!isConditionMet) {
      return null;
    }

    return (
      <FormControl id={id}>
        <FormLabel htmlFor={id}>{label}</FormLabel>
        <ErrorBox error={error}>
          <InputGroup>
            {leftElement && (
              <InputLeftElement pointerEvents="none">
                <b>{leftElement}</b>
              </InputLeftElement>
            )}
            <ChInput
              ref={ref}
              isInvalid={!!error}
              errorBorderColor="default"
              variant="filled"
              data-testid={id}
              error={error}
              type={type}
              maxLength={maxLength}
              onKeyPress={type === 'number' && numberInputOnKeyPress}
              {...register(id, {
                ...rules,
                onChange: onAfterChange,
                setValueAs: transformValue,
              })}
              {...otherProps}
            >
              {children}
            </ChInput>
          </InputGroup>
        </ErrorBox>
        {!!helpText && <FormHelperText>{helpText}</FormHelperText>}
      </FormControl>
    );
  },
);

export const HiddenInput = forwardRef(({ id, value, conditional, rules, ...otherProps }, ref) => {
  const { register, unregister } = useFormContext();

  const { isConditionMet } = useConditional({
    conditional,
    unregister,
    id,
  });

  if (!isConditionMet) {
    return null;
  }

  return (
    <input
      ref={ref}
      data-testid={id}
      type="hidden"
      {...register(id, {
        value,
        ...rules,
      })}
      {...otherProps}
    />
  );
});

export function FieldCheckbox({ id, label, conditional, rules, ...otherProps }) {
  const {
    register,
    unregister,
    formState: { errors },
  } = useFormContext();

  const error = getError(errors, id)?.message;

  const { isConditionMet } = useConditional({
    conditional,
    unregister,
    id,
  });

  if (!isConditionMet) {
    return null;
  }

  return (
    <V3Checkbox id={id} error={error} {...register(id, rules)} {...otherProps}>
      {label}
    </V3Checkbox>
  );
}

export function FieldSelect({ id, label, options, conditional, rules, ...otherProps }) {
  const {
    register,
    unregister,
    formState: { errors },
  } = useFormContext();

  const error = getError(errors, id)?.message;

  const { isConditionMet } = useConditional({
    conditional,
    unregister,
    id,
  });

  if (!isConditionMet) {
    return null;
  }

  return (
    <V3Select
      data-testid={id}
      error={error}
      placeholder="Please select"
      {...register(id, rules)}
      label={label}
      {...otherProps}
    >
      {options.map((opt, index) => (
        <option value={opt.value} key={index}>
          {opt.label}
        </option>
      ))}
    </V3Select>
  );
}

const ErrorBox = ({ error, children }) => (
  <Box width="100%">
    {children}
    {!!error && (
      <Box
        color="conditional.negative.graphics"
        width="100%"
        pt={1}
        {...(!!error && {
          borderTopColor: 'conditional.negative.graphics',
          borderTopWidth: '4px',
        })}
        mt={0}
      >
        <Text fontSize="14px">{error}</Text>
      </Box>
    )}
  </Box>
);

function getError(errors, id) {
  const regex = /\.(\d+)(?=\.|$)/g;
  const position = id.replace(regex, '[$1]');
  return _.get(errors, position);
}

function useConditional({ conditional = true, unregister, id }) {
  const isConditionalFunction = typeof conditional === 'function';
  const isConditionMet = isConditionalFunction ? conditional() : conditional === true;

  useEffect(() => {
    if (!isConditionMet) {
      unregister(id);
    }
  }, [isConditionMet, id, unregister]);

  return { isConditionMet };
}

// Prevent non-numeric characters on number input
// This is needed for browsers that don't support the input type="number" attribute such as Firefox
function numberInputOnKeyPress(event) {
  if (!/^\d+$/.test(event.key)) {
    event.preventDefault();
  }
}
