import React from 'react';
import { Button, Container, Text, Flex } from 'basis';
import { Modal } from 'components/modal/Modal';
import { useTheme } from '@emotion/react';
import IllionLogo from 'images/illion-logo-bank-statements.png';

const modalStyle = {
  width: 600,
  padding: 0,
};

export const OnlineBankConnectModal = ({ isOpen, onClose }) => {
  const theme = useTheme();

  return (
    <Modal isOpen={isOpen} onClose={onClose} style={modalStyle}>
      <Container padding="8">
        <Text textStyle="heading4" color={theme.headingColor}>
          Securely connecting with your bank
        </Text>

        <Container padding="4 0">
          <Flex height="100%" placeItems="center">
            <img src={IllionLogo} alt="IllionLogo" width="240px" />
          </Flex>
        </Container>

        <Container padding="6 0">
          <Text textStyle="body1" si>
            Latitude has partnered with Illion Bank Statements to securely provide digitally encrypted statements
            directly from your bank. It’s a safe and secure service. Your login details are never stored and it is a one
            time access only. This is the fastest way to get a decision.
          </Text>
        </Container>

        <Flex placeItems="center">
          <Container padding="2">
            <Button width="160px" onClick={onClose}>
              Close
            </Button>
          </Container>
        </Flex>
      </Container>
    </Modal>
  );
};
