import React from 'react';
import styled from '@emotion/styled';

const MessageWrapper = styled.div`
  & div[data-testid='yellow-message'] {
    background-color: #fff5e1;
  }
`;
export const YellowMessage = props => {
  const { children } = props;
  return <MessageWrapper>{children}</MessageWrapper>;
};
