import { Link, Message } from 'basis';
import React from 'react';
import { config } from '_config';
import { isAU } from '../../utils';
import { lscLink as lscLinkAu } from '../../_config/au';
import { lscLink as lscLinkNz } from '../../_config/nz';

export const OktaProfileError = () => {
  const supportNumber = config.phoneNumbers.originationsTeam;
  return (
    <Message severity="blocking" bg="secondary.pink.t30">
      Something went wrong and we couldn't complete your request. <br />
      <br />
      For existing Latitude customers you can login to{' '}
      <Link href={isAU() ? lscLinkAu : lscLinkNz} newTab>
        Latitude Service Centre
      </Link>{' '}
      and return to re-apply for our product. <br />
      <br />
      If you are not an existing Latitude customer you can use a different email to apply. <br />
      <br />
      Or alternatively you can call our Contact Centre for assistance on{' '}
      <a href={`tel:${supportNumber}`}>{supportNumber}</a> and quote <strong>`Profile issue`</strong> over the phone.
    </Message>
  );
};
