import React from 'react';
import AddressLookup from 'address-lookup';
import { byCountry } from 'utils';

export function Address(props) {
  const {
    formData,
    name,
    label,
    testId,
    countryCode,
    allowPO,
    allowPropertyName,
    autoAddressErrorMessage,
    autoAddressHelpText,
  } = props;
  return (
    <AddressLookup
      formData={formData}
      name={name}
      label={label}
      testId={testId}
      countryCode={countryCode}
      noCheckboxWrapper={byCountry({ AU: true, NZ: false })}
      allowPropertyName={allowPropertyName}
      allowPO={allowPO}
      autoAddressErrorMessage={autoAddressErrorMessage}
      autoAddressHelpText={autoAddressHelpText}
    />
  );
}
