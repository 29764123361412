import React from 'react';
import { Text, Container, Stack, Link, Grid, useTheme as useBasisTheme } from 'basis';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { getUploadUrl, displayCreditLimit } from 'utils';
import { APPLE_MERCHANT } from '_config/_constants';
import { LayoutOutcomePage, ReturnButton } from 'components';

import phone from 'images/icon-phone.svg';
import documentIcon from 'images/icon-document.svg';

import { useDataLayerAndAnalyticsLinks } from 'hooks';
import { UPLOAD_DOCUMENTS } from 'store';
import { PhoneIcon, List } from '../Outcome.styles';
import { WeWillBeInTouch } from '../components/WeWillBeInTouch';
import { AppleApplicantsBox } from '../components/AppleApplicantsBox';

const DocumentIcon = styled.img`
  position: absolute;
  transform: translate(-100%, -6px);
`;

export function ProofOfAddress({ storeState, withPOI = false }) {
  const basisTheme = useBasisTheme();
  const theme = useTheme();
  const { trackEventSender } = useDataLayerAndAnalyticsLinks(storeState);
  const {
    applicationRef,
    applyAndBuy: { merchantName },
    acquisition,
  } = storeState;
  const { firstName, lastName } = acquisition.personalDetails;
  const creditLimit = displayCreditLimit(storeState);
  return (
    <LayoutOutcomePage useConfetti>
      <Stack gap="8">
        <Text as="h1" textStyle="heading5" textStyle-md="heading4" color={theme.headingColor}>
          Congratulations!
          <br />
          {`You’re conditionally approved for $${creditLimit}`}
          <br />
          We just need to verify your address
        </Text>
        <Text testId="gotEverythingForNow">
          Your application number is <strong data-hj-suppress>{applicationRef}</strong>.
        </Text>
        <Container>
          {withPOI && (
            <>
              <Text textStyle="heading6">Proof of address</Text>
              <br />
            </>
          )}
          <Text margin="0 0 4">
            To help complete your Gem Visa application you need to upload one of the following documents:
          </Text>
          <Container padding="4 8" padding-md="8 12" bg="grey.t05">
            <Text textStyle="legal">
              <DocumentIcon src={documentIcon} width={32} height={32} />
              <strong>
                We need just one document from the following list, containing your name and address, from the last 6
                months
              </strong>
            </Text>
            <List marginTop="20" fontSize={14} noPadding>
              <li>Utility bill</li>
              <li>Rates notice</li>
              <li>Bank statement or credit card statement</li>
              <li>Loan statement</li>
              <li>Home and contents insurance policy (12 months for annual policy)</li>
              <li>Government issued document</li>
              <li>Rental tenancy agreement</li>
              <li>Property purchase and sale agreements</li>
              <li>Letter from educational institutions</li>
              <li>Kiwisaver statement</li>
            </List>
          </Container>
        </Container>
        <Text>When uploading your documents, you’ll need to enter:</Text>
        <List marginTop="0" fontSize={16}>
          <li>Your name</li>
          <li>
            Your application number <strong>{applicationRef}</strong>
          </li>
        </List>
        <Text>Once you’ve uploaded your documents our team will review and be in touch within 2 days.</Text>
        <Link
          appearance="primary-button"
          variant="blue-button"
          width="100%"
          width-xs="100%"
          width-sm="260px"
          href={getUploadUrl({ firstName, lastName, applicationRef })}
          newTab
          onClick={() =>
            trackEventSender({
              location: 'outcome',
              label: UPLOAD_DOCUMENTS,
            })
          }
        >
          {UPLOAD_DOCUMENTS}
        </Link>
        {withPOI && (
          <>
            <Text textStyle="heading6">Income verification</Text>
            <br />
            <Container>
              <Text testId="gotEverythingForNow">We've got everything we need for now.</Text>
              <Grid margin="6 0 0">
                <Grid.Item rowSpan="1">
                  <PhoneIcon src={phone} theme={basisTheme} />
                </Grid.Item>
                <Grid.Item rowSpan="1">
                  <WeWillBeInTouch />
                </Grid.Item>
              </Grid>
            </Container>
          </>
        )}

        {merchantName === APPLE_MERCHANT && <AppleApplicantsBox storeState={storeState} />}
        <ReturnButton variant="text" />
      </Stack>
    </LayoutOutcomePage>
  );
}
