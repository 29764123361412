import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const modalAnimation = keyframes`
 0% { transform: translateY(20px); opacity: 0 }
 100% { transform: translateY(0); opacity: 1 }
`;

const backdropAnimation = keyframes`
 0% { opacity: 0 }
 100% { opacity: 1 }
`;

export const ModalContainer = styled.div`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.25);
  overflow: auto;
`;

export const ModalWrapper = styled.div`
  box-sizing: border-box;
  min-height: 100%;
  position: relative;
  padding: 20px;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Modal = styled.div`
  box-sizing: border-box;
  position: relative;
  pointer-events: all;
  margin: 0 auto;
  z-index: 1;
  padding: 10px;
  background: #fff;
  width: 600px;
  max-width: 100%;
  border-radius: 5px;
  min-height: 64px;
  animation-name: ${modalAnimation};
  animation-duration: 400ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
`;

export const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.25);
  animation-name: ${backdropAnimation};
  animation-duration: 400ms;
  animation-iteration-count: 1;
  animation-fill-mode: forward;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
`;

export const ModalClose = styled.button`
  appearance: none;
  border: none;
  padding: 0;
  background: transparent;
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;

  &:active {
    opacity: 0.5;
  }
`;
