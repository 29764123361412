import styled from '@emotion/styled';

export const InfoBox = styled.p`
  font-size: 16px;
  font-weight: 300;
  color: #000000;
  line-height: 24px;
  text-align: inherit;
  font-family: 'Roboto', sans-serif;
  letter-spacing: 0px;
  margin-top: 24px;
`;
