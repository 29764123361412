import React, { useState, useEffect, useCallback } from 'react';
import { LayoutPage, LayoutContent, Errors } from 'components';
import { Container, Text, Button, Stack, Grid } from 'basis';
import { useHistory } from 'react-router-dom';
import { useStoreValue } from 'store';
import { useGetApplicationDraft, useErrorTrackingAndUpdateStore, useSteps, useGetBiometricsResult } from 'hooks';
import { STEP_BIOMETRICS_RETURN, STEP_ABOUT_YOU } from '_config';
import { usedBiometrics } from 'hooks/useSteps';
import { isFeatureOn } from 'featureToggles';
import MobileIcon from '../../images/icon-mobile.svg';
import IdentityDocumentIcon from '../../images/icon-identity-information.svg';
import WalletIcon from '../../images/icon-wallet.svg';
import PersonIcon from '../../images/icon-person.svg';

export const BiometricsReturn = () => {
  const saveAndResumeFeature = isFeatureOn('saveAndResume');
  const history = useHistory();
  const { handleErrorCallback: handleGetDraftError } = useErrorTrackingAndUpdateStore('Get Application Draft Error');
  const { handleErrorCallback: handleDraftNotFoundError } = useErrorTrackingAndUpdateStore('Draft Not Found');
  const { handleErrorCallback: handleBiometricsResultNotFoundError } = useErrorTrackingAndUpdateStore(
    'Biometrics result not found',
  );
  const [store, updateStore] = useStoreValue();
  const [isDraftFetched, updateIsDraftFetched] = useState(false);
  const { goNext } = useSteps();

  const onContinue = useCallback(() => {
    if (store.biometricsVerification === 'SUCCESSFUL') {
      goNext();
    } else if (store.biometricsVerification === 'DATA_INVALID') {
      updateStore({ activeStep: STEP_ABOUT_YOU });
      history.push(STEP_ABOUT_YOU);
    }
  }, [goNext, history, store.biometricsVerification, updateStore]);

  const onGotBiometricsResult = useCallback(
    biometricsResult => {
      if (biometricsResult.isSuccessful) {
        updateStore({
          applicationErrors: null,
          biometricsVerification: 'SUCCESSFUL',
          biometricsResult: {
            isSuccessful: biometricsResult.isSuccessful,
            idType: biometricsResult?.extraDetails.idType,
            firstName: biometricsResult?.contactDetails.firstName,
            dateOfBirth: biometricsResult?.contactDetails.dateOfBirth,
            address: biometricsResult.otherDetails?.currentAddress?.streetAddress,
          },
          activeStep: STEP_BIOMETRICS_RETURN,
        });
        history.push(STEP_BIOMETRICS_RETURN);
      } else {
        updateStore({
          applicationErrors: null,
          biometricsVerification: 'DATA_INVALID',
        });
      }
    },
    [history, updateStore],
  );

  const { getBiometricsResult, loading: isLoadingBiometricsResult } = useGetBiometricsResult({
    onSuccess: onGotBiometricsResult,
    onError: handleBiometricsResultNotFoundError,
  });

  const fetchedDraft = draft => {
    updateIsDraftFetched(true);
    if (!draft) {
      handleDraftNotFoundError();
    } else {
      const { biometricsResult, biometricsVerification, retrieveflag, ...storePatch } = draft;
      updateStore({
        ...storePatch,
        applicationErrors: null,
        activeStep: STEP_BIOMETRICS_RETURN,
      });
    }
  };

  const { getApplicationDraft, loading: isLoadingDraft } = useGetApplicationDraft({
    applicationId: store.applicationId,
    onError: () => handleGetDraftError,
    onSuccess: fetchedDraft,
  });

  useEffect(() => {
    if (saveAndResumeFeature && !isDraftFetched && !isLoadingDraft) {
      getApplicationDraft();
    }
  }, [getApplicationDraft, isDraftFetched, saveAndResumeFeature, isLoadingDraft]);

  const isBiometricsResultFetched = usedBiometrics(store);

  useEffect(() => {
    if (!isBiometricsResultFetched && store.applicationId) {
      getBiometricsResult(store.applicationId);
    }
  }, [store.applicationId, getBiometricsResult, isBiometricsResultFetched]);

  const loading = (saveAndResumeFeature && isLoadingDraft) || isLoadingBiometricsResult;

  if (store.applicationErrors) {
    const retry = () => {
      updateIsDraftFetched(false);
      getApplicationDraft();
    };

    return (
      <LayoutPage>
        <Errors applicationRef={store.applicationRef} retry={retry} retrying={loading} />
      </LayoutPage>
    );
  }

  return (
    <LayoutPage headerAlign="center">
      <LayoutContent rowsGap="0">
        <Container padding="2" padding-md="4" padding-lg="6" hasBreakpointWidth>
          <Stack gap="6">
            <Container bg="secondary.lightBlue.t15" padding="4 4">
              <Container bg="white" padding="4 4">
                <Stack gap="6">
                  <Item isDone text="Provided your contact details" icon={<img src={MobileIcon} alt="mobile icon" />} />
                  <Item
                    isDone
                    text="Captured your identity information"
                    icon={<img src={IdentityDocumentIcon} alt="identity icon" />}
                  />
                  <Item
                    isDone={false}
                    text="Tell us a little more about yourself"
                    icon={<img src={PersonIcon} alt="person icon" />}
                  />
                  <Item
                    isDone={false}
                    text="Tell us about your finances"
                    icon={<img src={WalletIcon} alt="Wallet icon" />}
                  />
                </Stack>
              </Container>
            </Container>

            <Button
              loading={(saveAndResumeFeature && !isDraftFetched) || !isBiometricsResultFetched || loading}
              onClick={onContinue}
              width="90%"
              margin="0 5"
            >
              Continue with your application
            </Button>
          </Stack>
        </Container>
      </LayoutContent>
    </LayoutPage>
  );
};

const Item = ({ text, icon, isDone }) => (
  <Container bg={isDone ? 'secondary.lightBlue.t15' : 'grey.t03'} padding="6 8">
    <Grid>
      <Grid.Item colSpan="1-7">
        <Container padding="0 4 0 0">
          <Text align="left" color="primary.blue.t100">
            <strong>{text}</strong>
          </Text>
        </Container>
      </Grid.Item>
      <Grid.Item colSpan="8-9">
        <Text align="right">{icon}</Text>
      </Grid.Item>
    </Grid>
  </Container>
);
