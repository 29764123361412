import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useErrorTrackingAndUpdateStore } from './useErrorTrackingAndUpdateStore';

export const UPDATE_DEBTS_GQL = gql`
  mutation useUpdateDebts($input: UpdateDebtsInput!) {
    updateDebts(input: $input) {
      status
    }
  }
`;

export function useUpdateDebts() {
  const { handleErrorCallback } = useErrorTrackingAndUpdateStore('hook:updateDVSConsent');
  const [mutate, { loading }] = useMutation(UPDATE_DEBTS_GQL, {
    onError: err => {
      handleErrorCallback(err);
    },
  });

  const sendRequest = useCallback(
    async payload => {
      const result = await mutate({ variables: { input: payload } });
      return result.data.updateDebts;
    },
    [mutate],
  );

  return {
    sendRequest,
    loading,
  };
}
