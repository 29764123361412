import { CHANNEL_DIRECT, CHANNEL_INSTORE, CHANNEL_ONLINE } from '_config';

export function getSolCode(channel, productCode) {
  if (!channel) {
    return undefined;
  }
  const found = solCodes.find(solCode => solCode.channel === channel && solCode.productCode === productCode)?.solCode;
  if (!found) {
    throw new Error(`Sol code not found for channel: ${channel} and product code: ${productCode}`);
  }
  return found;
}

// full documentation at https://latitudefs.atlassian.net/wiki/spaces/CS/pages/179929889/Activate+Solicitation+Code+Remapping+Solution
const solCodes = [
  {
    channel: CHANNEL_INSTORE,
    productCode: 'CCAUGOM',
    solCode: 'NX7',
  },
  {
    channel: CHANNEL_DIRECT,
    productCode: 'CCAUGOM',
    solCode: 'ND7',
  },
  {
    channel: CHANNEL_ONLINE,
    productCode: 'CCAUGOM',
    solCode: 'NB7',
  },
  {
    channel: CHANNEL_INSTORE,
    productCode: 'CCAUGEM',
    solCode: 'EX1',
  },
  {
    channel: CHANNEL_DIRECT,
    productCode: 'CCAUGEM',
    solCode: 'ED1',
  },
  {
    channel: CHANNEL_ONLINE,
    productCode: 'CCAUGEM',
    solCode: 'EB1',
  },
  {
    channel: CHANNEL_DIRECT,
    productCode: 'CCAUAFS',
    solCode: 'KD1',
  },
];
