import React from 'react';
import styled from '@emotion/styled';
import { Container, Text } from 'basis';
import { useStoreValue } from 'store';

const TileBox = styled.div`
  padding: 0 12px 12px 24px;
  margin: 32px 0;
  @media (max-width: ${props => props.theme.screenSize?.md}) {
    margin-top: 24px;
    margin-bottom: 56px;
  }
  border-left: 12px solid ${props => props?.theme?.colors?.secondary?.pink?.t60 ?? '#FFC9E4'};
`;

const OfferBody = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: ${props => props?.theme?.colors?.secondary?.blue?.t100 ?? '#0555C8'};
  line-height: 130%;
`;

const OfferCondition = styled.div`
  font-size: 12px;
  font-weight: 300;
  line-height: 150%;
`;

export function OfferTile({ title, description, promoEnd }) {
  const [storeState] = useStoreValue();
  if (!storeState.offer || storeState.offer.status !== 'VALID') {
    return null;
  }

  return (
    <TileBox>
      <Container testId="offer-title">
        <Text textStyle="heading4">{storeState.offer.title}</Text>
      </Container>
      <Container margin="4 0" testId="offer-description">
        <OfferBody textStyle="heading4">{storeState.offer.description}</OfferBody>
      </Container>
      {storeState.offer.promoEnd && (
        <Container testId="offer-tnc">
          <OfferCondition>
            New customers only, must be approved by {storeState.offer.promoEnd}. T&Cs apply.
          </OfferCondition>
        </Container>
      )}
    </TileBox>
  );
}
