import deepmerge from 'deepmerge';

function flattenData(data, prefixParam, resultParam) {
  const prefix = prefixParam || '';
  const result = resultParam || {};
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const lowerCaseKey = key.toLowerCase();
      if (typeof data[key] !== 'object' || data[key] instanceof Array) {
        result[prefix + lowerCaseKey] = data[key];
      } else {
        flattenData(data[key], `${prefix}${lowerCaseKey}_`, result);
      }
    }
  }
  return result;
}

export function getDataLayer({ siteData, pageData, eventData }) {
  const data = deepmerge.all([siteData, pageData, eventData].filter(Boolean), {
    arrayMerge: (_destinationArray, sourceArray) => sourceArray,
  });

  return flattenData(data, '', {});
}
