import React, { useEffect, useRef, useMemo } from 'react';
import { Form as BasisForm, Message } from 'basis';
import { DevAutoFill, DEV_FEATURES } from '__dev';
import { useStoreValue } from 'store';
import { LayoutContent } from 'components';
import { scroller, Element, Events } from 'react-scroll';
import { useFormCache, useSaveDraft, useSteps } from 'hooks';
import { SessionTimeout } from 'components/session-timeout/SessionTimeout';
import { byCountry } from 'utils/byConfig';
import { startCase } from 'lodash';
import { FormButtons } from './FormButtons';

const FORM_SUBMIT_REQUIRED_SCROLL_ID = 'form-submit-required-id';

export function Form({
  id: formId,
  initialValues,
  onSubmit,
  children,
  loading,
  submitButtonLabel = byCountry({ AU: 'Next - %%NEXT_STEP', NZ: 'Next' }),
  disableFormCache,
  footer,
  showSaveDraftButton = false,
  saveDraftOnSubmit = false,
  saveDraftOnSessionTimeout = false,
  hideFormButtons = false,
  disableFormButtons = false,
  showBackButton,
  disableFormButton = false,
}) {
  const [storeState] = useStoreValue();
  const { updateFormCacheValues, setSubmitRequired } = useFormCache();
  const scrollingRef = useRef(false);
  const { saveDraft, savingDraft } = useSaveDraft();
  const { formSubmitRequired } = storeState;
  const { nextStep } = useSteps();

  const buttonLabel = useMemo(() => {
    const nextStepTitle = startCase(nextStep);
    return submitButtonLabel.replace(/%%NEXT_STEP/, nextStepTitle);
  }, [nextStep, submitButtonLabel]);

  useEffect(() => {
    if (formSubmitRequired && !scrollingRef.current) {
      scrollingRef.current = true;
      Events.scrollEvent.register('end', () => {
        scrollingRef.current = false;
      });
      scroller.scrollTo(FORM_SUBMIT_REQUIRED_SCROLL_ID, {
        duration: 800,
        delay: 50,
        smooth: 'easeInOutQuart',
      });
    }
  });

  const handleSubmit = data => {
    setSubmitRequired(false);
    onSubmit(data);
    if (saveDraftOnSubmit && !savingDraft && !Object.keys(data.errors).length) {
      const newStoreState = {
        ...storeState,
        acquisition: {
          ...storeState.acquisition,
          [formId]: { ...data.values },
        },
      };
      saveDraft(newStoreState);
    }
  };

  return (
    <BasisForm initialValues={initialValues} onSubmit={handleSubmit} debug={DEV_FEATURES.SHOW_FORM_DEBUG}>
      {formData => {
        !disableFormCache && updateFormCacheValues(formId, formData.state.values);

        return (
          <>
            {saveDraftOnSessionTimeout && (
              <SessionTimeout persistDraft formId={formId} formValues={formData.state.values} />
            )}
            <DevAutoFill id={formId} setFormValues={formData.setValues} />
            {typeof children === 'function' ? children(formData) : children}
            {formSubmitRequired && (
              <Element name={FORM_SUBMIT_REQUIRED_SCROLL_ID}>
                <LayoutContent>
                  <Message severity="blocking" bg="secondary.pink.t30">
                    You have unsaved changes on this page. Please select <strong>{buttonLabel}</strong> to save your
                    information.
                  </Message>
                </LayoutContent>
              </Element>
            )}

            {footer && React.cloneElement(footer, { values: formData.state.values })}

            {!hideFormButtons && (
              <FormButtons
                formId={formId}
                formData={formData}
                loading={loading}
                disabled={disableFormButtons}
                submitButtonLabel={buttonLabel}
                noSaveDraft={!showSaveDraftButton}
                showBackButton={showBackButton}
              />
            )}
          </>
        );
      }}
    </BasisForm>
  );
}
