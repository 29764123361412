import { storageStateKey, useStoreValue, getDefaultState } from 'store';
import { formToStepMap } from '_config';
import { setSessionStorage } from 'utils';
import { useCallback } from 'react';

const initialValues = {};
const initialSubmitRequired = false;

export const formCache = {
  submitRequired: initialSubmitRequired,
  values: { ...initialValues },
};

export const useFormCache = () => {
  const [state, updateStore] = useStoreValue();
  const getSubmitRequired = useCallback(() => formCache.submitRequired, []);

  const getFormCacheValues = id => formCache.values[id];

  const updateFormCacheValues = (id, values) => {
    formCache.values[id] = values;

    if (process.env.REACT_APP_ENV === 'dev') {
      setSessionStorage(storageStateKey, values, `acquisition.${id}`);
    }

    const isActiveStepForm = formToStepMap[id] === state.activeStep;
    if (!isActiveStepForm) {
      // TODO: determine if the follow code can be deleted
      // This was to prevent users from clicking the "Back" button from browser without saving the current page field changes
      // However, now going back without save is kind of allowed in our UX.
      // formCache.submitRequired = !_isEqual(formCache.values[id], state.acquisition[id]);
    }
  };

  const resetFormCacheValues = () => {
    formCache.submitRequired = initialSubmitRequired;
    formCache.values = { ...initialValues };

    if (process.env.REACT_APP_ENV === 'dev') {
      setSessionStorage(storageStateKey, getDefaultState());
    }
  };

  const setSubmitRequired = value => {
    formCache.submitRequired = value;
  };

  const updateStateFlag = flag => {
    updateStore({ formSubmitRequired: flag });
  };

  return {
    getFormCacheValues,
    resetFormCacheValues,
    updateFormCacheValues,
    setSubmitRequired,
    getSubmitRequired,
    updateStateFlag,
  };
};
