import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useStoreValue } from 'store';
import { useErrorTracking } from './useErrorTrackingAndUpdateStore';

export const SEND_WELCOME_EMAIL_GQL = gql`
  mutation useSendWelcomeEmail($input: SendWelcomeMessageInput!) {
    sendWelcomeMessage(input: $input) {
      success
    }
  }
`;

export function useSendWelcomeEmail() {
  const { handleErrorTracking: handleError } = useErrorTracking('hook:useSendWelcomeEmail');
  const [storeState] = useStoreValue();

  const [commitMutation, { loading }] = useMutation(SEND_WELCOME_EMAIL_GQL, {
    onError: error => {
      handleError(error);
    },
  });

  const sendEmail = useCallback(async () => {
    if (loading) {
      return null;
    }

    return commitMutation({
      variables: {
        input: {
          applicationId: storeState.applicationId,
        },
      },
    });
  }, [commitMutation, loading, storeState.applicationId]);

  return { sendEmail, loading };
}
