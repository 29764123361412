import React from 'react';
import { Modal } from 'components/modal/Modal';
import { Button, Container, Flex, Text, Link } from 'basis';
import { useDataLayerAndAnalyticsLinks } from 'hooks';
import { useStoreValue } from 'store';

const VerifierModal = ({ isOpen, onClose }) => {
  const [storeState] = useStoreValue();
  const { trackEventSender } = useDataLayerAndAnalyticsLinks(storeState);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Container padding="3" padding-md="6">
        <Container padding="4 2 2">
          <Text textStyle="heading4" textStyle-md="heading4">
            Verifier’s terms of service
          </Text>
        </Container>

        <Container padding="2 2 6">
          <Text>
            Latitude is required by the Anti-Money Laundering & Counter-Terrorism Financing Act 2006 to verify my
            identity before providing me with a product or service.
          </Text>
          <Text>
            You agree Verifier Australia Pty Ltd (us, we, our) may, on your behalf, request access to data that
            organisations hold about your superannuation contributions, payroll and employer. This request is made using
            YOUR right of access to your personal information. Your right arises under the Privacy Act.
          </Text>
        </Container>

        <Container padding="2 2 6">
          <Text>
            You also agree that we may use and disclose that data to calculate your income and give the lender a
            response about your income and employment. You can ask your lender to give you access to the data that we
            obtained on your behalf. More information about how we use, disclose and store your data is set out in the{' '}
            <Link
              newTab
              href="https://www.verifier.me/privacy"
              onClick={() =>
                trackEventSender({
                  action: 'external-link',
                  location: 'landing',
                  label: 'Verifier Privacy Policy',
                })
              }
            >
              Verifier Privacy Policy
            </Link>
            .
          </Text>
        </Container>

        <Container padding="2 2 6">
          <Text>
            To enable us to find the data about you, your lender will give us your name, address, mobile number, email
            address and date of birth (as provided by you in your credit application) and may also obtain your prior
            addresses from your credit report and give them to us.
          </Text>
        </Container>
        <Flex placeItems="center">
          <Container padding="2 2 4 2">
            <Button width="160px" onClick={onClose}>
              Close
            </Button>
          </Container>
        </Flex>
      </Container>
    </Modal>
  );
};

export { VerifierModal };
