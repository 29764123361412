import styled from '@emotion/styled';
import { useBreakpoint, useTheme } from 'basis';
import React from 'react';
import { config } from '_config';

const StyledImageContainer = styled.div`
  padding-top: 100%;
  position: relative;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  ${props => `
    ${props.theme.minMediaQueries.sm} {
      padding-top: 80%;

      img {
        top: -15%;
      }
    }

    ${props.theme.minMediaQueries.md} {
      padding-top: 0;
      position: static;

      img {
        position: static;
      }
    }
  `}
`;

export const ImageFull = styled.img`
  ${config.countryCode === 'NZ' && `width: 100%;`}
  ${config.countryCode === 'AU' && `width: 60%;`}
  ${config.countryCode === 'AU' && `padding: 10% 25%;`}
  ${props => `
    ${props.theme.minMediaQueries.md} {
      display: block;
    }
  `}
`;

export const ImageMobile = styled.img`
  margin: 0 auto 60px;
  width: 100%;
  ${props => `

    ${props.theme.minMediaQueries.md} {
      margin: 0 auto;
      display: none;
    }
  `}
`;

export const ImageContainer = ({ children }) => {
  const theme = useTheme();
  return <StyledImageContainer theme={theme}>{children}</StyledImageContainer>;
};

export const HeroImg = ({ src, srcMobile, alt }) => {
  const theme = useTheme();
  const breakpoint = useBreakpoint();

  return ['md', 'lg', 'xl'].includes(breakpoint) ? (
    src ? (
      <ImageFull src={src} alt={alt} theme={theme} />
    ) : null
  ) : srcMobile ? (
    <ImageMobile src={srcMobile} alt={alt} theme={theme} />
  ) : null;
};
