import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import React from 'react';

export const IconTitleWrapper = styled.div`
  display: flex;
  margin-top: 14px;
  margin-bottom: 32px;
  & > img {
    margin-right: 8px;
  }

  ${props => `
  ${`@media (min-width: ${props.theme.screenSize.sm})`} {
    font-size: 18px;
  }
`}
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  padding-bottom: 50px;

  li::before {
    background-color: black;
    width: 0.3em;
    height: 0.3em;
  }

  li:not(:first-of-type) {
    margin-top: 12px;
  }

  a {
    cursor: pointer;
  }

  h6 {
    font-weight: 500;
  }

  ${props => `
    ${`@media (min-width: ${props.theme.screenSize.sm})`} {
      & > div {
        width: 100%;
        flex-direction: column;
      }
    }
    ${`@media (min-width: ${props.theme.screenSize.lg})`} {
      & > div:first-of-type {
        flex-direction: column;
      }

      & > div {
        max-width: 1220px;
        display: flex;
        flex-direction: column;
      }
    }

    ${`@media (max-width: ${props.theme.screenSize.md})`} {
      margin-top: 70px;
      h4 {
        font-size: 20px;
      }
      h6 {
        font-size: 16px;
      }
      padding-bottom: 30px;
    }
  `}
`;

export const Banner = styled.div`
  background: linear-gradient(90.86deg, #5a5a5a 40.49%, #757575 75.35%);
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props => `
    ${`@media (min-width: ${props.theme.screenSize.sm})`} {
      & > div {
        width: 100%;
      }
    }
    ${`@media (min-width: ${props.theme.screenSize.lg})`} {
      & > div:first-of-type {
        height: 378px;
      }

      & > div {
        max-width: 1220px;
        display: flex;
      }
    }
  `}
`;

export const AnchorTag = styled.a`
  font-size: 8px;
  vertical-align: top;
  text-decoration: underline;
  cursor: pointer;
  ${props => `
    color: ${props.theme.a.color};
  `};
`;

export const BannerTitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
`;

export const ImageIcons = styled.img`
  height: 68px;
  margin-right: 16px;
  ${props => `
      ${`@media (min-width: ${props.theme.screenSize.lg})`} {
        height: 68px;
      }
  `};
`;

export const HeaderBannerTextStyle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  button {
    border: 1px white solid;
    border-radius: 4px;
    padding: 12px 16px;
    font-size: 14px;
    line-height: 20px;

    span {
      font-size: 14px;
      line-height: 20px;
    }
  }
  ${props => `
      ${`@media (min-width: ${props.theme.screenSize.md})`} {
        background-color: transparent !important;
        height: 100% !important;
      }

      ${`@media (min-width: ${props.theme.screenSize.sm})`} {
        height: 240px;
      }

      ${`@media (max-width: ${props.theme.screenSize.sm})`} {
        height: 330px;
        align-items: flex-start;
      }
  `}
`;

export const LandingContentItem = styled.div`
  display: flex;
  img {
    margin-right: 10px;
    width: 24px;
    height: 24px;
  }
  svg {
    width: 24px;
    height: 24px;
    margin-left: -6px;
    margin-right: 4px;
  }
  ul {
    margin-top: 2px;
    margin-left: 1.5em;
  }
  margin-bottom: 20px;
  ${props => `
    ${`@media (max-width: ${props.theme.screenSize.md})`} {
      margin-bottom: 12px;
    }
`}
`;

export const ListWrapper = styled.div`
  li::before {
    background-color: black;
  }
`;

export const BannerImageContainer = styled.div`
  padding-top: 80%;
  position: relative;
  overflow: hidden;

  img {
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    height: 338px;

    ${props => `
      ${`@media (max-width: ${props.theme.screenSize.md})`} {
        top: 0;
        height: auto;
        width: 100%;
      }
    `}
  }

  ${props => `
      ${`@media (max-width: ${props.theme.screenSize.md})`} {
        padding-top: 80%;
      }
    `}
`;

export const CardImageContainer = styled.div`
  display: flex;
  position: relative;
  align-items: flex-end;

  img {
    margin-top: -230px;
    width: 286px;
    height: 180px;
  }

  ${props => `
    ${`@media (max-width: ${props.theme.screenSize.md})`} {
      width: 100%;
    }
  `}
`;

export const CardImage = ({ children }) => {
  const theme = useTheme();
  return <CardImageContainer theme={theme}>{children}</CardImageContainer>;
};

export const BannerImage = ({ children }) => {
  return <BannerImageContainer>{children}</BannerImageContainer>;
};
