export const validNamePattern = "^([A-Za-z]+[-' ]?)?[A-Za-z]+$";
// Email RegEx has been taken from https://emailregex.com/
export const validEmailPattern =
  '^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';
export const emailMaxLength = 255;

export const promoCodePattern = '^[a-zA-Z0-9]{0,20}$';

export const validLicenceNumberPattern = '^[a-zA-Z]{2}[0-9]{6}$';
export const validResidencyNumberLetterPattern = '^[a-zA-Z0-9_.-]*$';

export const numbersOnlyPattern = '^[0-9]*$';

export const validVerbalPasswordCharsPattern = '^[a-zA-Z0-9]+$';
export const validVerbalPasswordLettersPattern = '^(.*[a-zA-Z]+.*){2,}$'; // 2 or more letters anywhere in string
export const validVerbalPasswordNumbersPattern = '^(.*[0-9]+.*){2,}$'; // 2 or more numbers anywhere in string
export const validVerbalPasswordMinLength = 6;
export const validVerbalPasswordMaxLength = 15;

export const APPLY_BTN_LABEL = 'Start application';
export const RESUME_BTN_LABEL = 'Resume application';
export const APPLE_MERCHANT = 'Apple';

export const APPLICATION_RESUME_PERIOD_DAYS = 60;
export const APPLICATION_START_DATE_PARAMETER = 'applicationStartDate';
export const APPLICATION_START_DATE_PATTERN = '^\\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$'; // ISO 8601 Date

// Modern web browsers aggressively predict credit card forms and provide auto-fill.
// Here we use a mis-labeled attribute to prevent auto-fill.
// https://stackoverflow.com/a/64143471/1401634
export const PREVENT_CREDIT_CARD_AUTOFILL = ['tel', 'cc-csc', 'organization'];

export * from './titleOptions';
export { countryOptions } from './countryOptions';
export * from './applicationReasonOptions';
export { relationshipStatusOptions } from './relationshipStatusOptions';
export { yesNoOptions } from './yesNoOptions';
export * from './industryOptions';
export * from './residentialStatusOptions';
export * from './employmentStatusOptions';
export { occupationOptions } from './occupationOptions';
export { OptionUser, OptionSystem, creditLimitSourceOptions } from './creditLimitSourceOptions';
export * from './medicareCardColourOptions';
export * from './externalLinks';
