import React from 'react';
import { Container, Message, Text, Icon, Grid, List, Link } from 'basis';
import { LayoutPage, LayoutContent, SaveAndCompleteLaterButton } from 'components';
import styled from '@emotion/styled';
import { useStoreValue } from 'store';
import AusPostImg from 'images/aus-post.png';
import { useDataLayerAndAnalyticsLinks } from 'hooks';
import { OverviewSources, STEP_AUS_POST } from '_config';
import { device } from 'media';
import { AusPostAction } from './AusPostAction';

const IconWrapper = styled.span`
  position: relative;
  top: 5px;
  svg {
    width: 24px;
    height: 24px;
    display: inline;
    margin: 0 8px;
  }
`;

const ContentWrapper = styled.div`
  li::before {
    background-color: black;
    width: 0.3em;
    height: 0.3em;
  }

  hr {
    border: 1px solid #d6d6d6;
    margin: 40px 0;
  }

  img {
    height: auto;
    width: 120px;
  }

  div[data-testid='inner-container'] {
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
  }

  @media ${device.mobileOrSmaller} {
    button {
      width: 100%;
    }
  }
`;

const SecondaryButton = styled.div`
  display: inherit;
  a:hover {
    svg path {
      fill: white;
    }
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px 20px;
`;

export const AusPost = () => {
  const [store] = useStoreValue();
  const { trackEventSender } = useDataLayerAndAnalyticsLinks(store);

  return (
    <LayoutPage hideBackButton>
      <LayoutContent rowsGap="0">
        <ContentWrapper>
          <Container padding="0 0 6 0" padding-md="0 0 8 0">
            <Message testId="yellow-message" severity="blocking" bg="secondary.pink.t30">
              <strong>Unfortunately, we weren't able to verify your identity.</strong>
            </Message>
            <Text as="p" margin="6 0">
              Follow the steps below, and take the required documentation and form to an Australia Post office. Once{' '}
              completed, this can take up to <strong>5-10 business days</strong> to process.
            </Text>
            <Text as="p" testId="application-ref">
              Application reference number: <strong>{store.applicationRef}</strong>
            </Text>
          </Container>
          <Container bg="grey.t03" padding="4" padding-md="10">
            <Container bg="white" padding="8" testId="inner-container">
              <Container>
                <AusPostAction order="1">Print the form</AusPostAction>
                <Text as="p" margin="0 0 0 9">
                  Download and print the Australia Post form. Ensure your details on the form match your Identity and{' '}
                  application details.
                </Text>
                <Grid margin="8 0 0 0">
                  <Link
                    newTab
                    appearance="primary-button"
                    testId="aus-post-download-form-btn"
                    href={OverviewSources.AU.auPostForm.url}
                    onClick={() => {
                      trackEventSender({
                        action: 'external-link',
                        location: STEP_AUS_POST,
                        label: OverviewSources.AU.auPostForm.label,
                      });
                    }}
                  >
                    <IconWrapper>
                      <Icon name="download" color="white" />
                    </IconWrapper>
                    <span>{OverviewSources.AU.auPostForm.label}</span>
                  </Link>
                </Grid>
              </Container>
              <hr />
              <Container>
                <FlexWrapper>
                  <Container>
                    <AusPostAction order="2">
                      Take your form to a post office that has Bank@Post facilities
                    </AusPostAction>
                    <Text as="p" margin="0 0 0 9">
                      Find your nearest Bank@Post facility using the link below.{' '}
                    </Text>
                  </Container>
                  <img src={AusPostImg} alt="1 Post Logo" />
                </FlexWrapper>
                <Grid margin="8 0 0 0">
                  <SecondaryButton>
                    <Link
                      newTab
                      appearance="secondary-button"
                      testId="aus-post-find-location-btn"
                      href={OverviewSources.AU.findAuPost.url}
                      onClick={() => {
                        trackEventSender({
                          action: 'external-link',
                          location: window.location.pathname,
                          label: OverviewSources.AU.findAuPost.label,
                        });
                      }}
                    >
                      <span>{OverviewSources.AU.findAuPost.label}</span>
                      <IconWrapper>
                        <Icon name="external-link" color="highlight.blue.t100" />
                      </IconWrapper>
                    </Link>
                  </SecondaryButton>
                </Grid>
              </Container>
              <hr />
              <Container>
                <AusPostAction order="3">DO NOT COMPLETE Section C</AusPostAction>
                <Text as="p" margin="0 0 0 9">
                  Australia Post does not require you to fill out this section.
                </Text>
              </Container>
              <hr />
              <Container>
                <AusPostAction order="4">DO NOT COMPLETE Section D</AusPostAction>
                <Text as="p" margin="0 0 0 9">
                  Your signature must be witnessed by the interviewer.
                </Text>
              </Container>
              <hr />
              <Container>
                <AusPostAction order="5">Identification documents must be originals and current</AusPostAction>
                <Text as="p" margin="0 0 8 9">
                  You <strong>MUST supply ONE primary</strong> document from the list below.
                </Text>
              </Container>
              <Message severity="info-or-minor" bg="secondary.lightBlue.t25">
                <List>
                  <List.Item>
                    If your primary document contains <strong>BOTH a photo AND your current residential address</strong>{' '}
                    - then no identification is required.
                  </List.Item>
                  <List.Item>
                    If your primary document <strong>DOES NOT</strong> contain both a photo and current residential{' '}
                    address, then you must also{' '}
                    <strong>supply ONE secondary document that contains your current residential address</strong>.
                  </List.Item>
                </List>
              </Message>
              <Container margin="6 0">
                <Text margin="3 0">
                  <strong>Accepted Primary ID Documents</strong>
                </Text>
                <List>
                  <List.Item>Australian Driver Licence (current)</List.Item>
                  <List.Item>Australian Learners Permit (current)</List.Item>
                  <List.Item>Australian Passport (not expired more than 2 years)</List.Item>
                  <List.Item>Foreign Passport (current only)</List.Item>
                  <List.Item>Proof of Age card/NSW Photo Card (current and government issued)</List.Item>
                  <List.Item>Centrelink Pension Card (current)</List.Item>
                </List>
              </Container>
              <Container margin="6 0">
                <Text margin="3 0">
                  <strong>Accepted Secondary ID Documents</strong>
                </Text>
                <List>
                  <List.Item>Utility Bill or Council Rates Notice (less than 3 months old)</List.Item>
                  <List.Item>Taxation notice or Centrelink statement (less than 12 months old)</List.Item>
                </List>
              </Container>
            </Container>
          </Container>
          <Container margin="8 0">
            <SaveAndCompleteLaterButton />
          </Container>
        </ContentWrapper>
      </LayoutContent>
    </LayoutPage>
  );
};
