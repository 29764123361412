import React from 'react';
import { List, Text } from 'basis';
import { OverviewContent } from 'siteContent/overviewContent';
import { config } from '_config';

const { atLeast18, validInfo, residentInfo } = OverviewContent[config.countryCode];

const EligibilityContent = () => {
  return (
    <List>
      <List.Item>
        <Text>{atLeast18}</Text>
      </List.Item>
      <List.Item>
        <Text margin="4 0">{validInfo}</Text>
      </List.Item>
      <List.Item>
        <Text margin="4 0">{residentInfo}</Text>
      </List.Item>
    </List>
  );
};

export { EligibilityContent };
