import { useCallback, useEffect, useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { useHandleApiErrors } from './useHandleApiErrors';

export const GET_BIOMETRICS_RESULT_GQL = gql`
  query getBiometricsResult($id: ID!) {
    applicationBiometrics(id: $id) {
      isSuccessful
      contactDetails {
        firstName
        dateOfBirth {
          day
          month
          year
        }
      }
      extraDetails {
        idType
      }
      otherDetails {
        currentAddress {
          addressType
          streetAddress {
            propertyName
            unitNumber
            streetNumber
            streetName
            streetType
            suburb
            city
            postcode
            countryCode
          }
        }
      }
    }
  }
`;

export const useGetBiometricsResult = ({ onSuccess, onError }) => {
  const [loaded, updateLoaded] = useState(false);
  const { handleError } = useHandleApiErrors('apollo', onError);
  const [query, { data, error, loading }] = useLazyQuery(GET_BIOMETRICS_RESULT_GQL, {
    fetchPolicy: 'network-only',
  });

  const sendRequest = useCallback(
    applicationId => {
      if (!loading) {
        updateLoaded(false);
        query({ variables: { id: applicationId } });
      }
    },
    [loading, query],
  );

  useEffect(() => {
    if (loaded) {
      return;
    }

    if (error) {
      handleError(error);
      updateLoaded(true);
    }

    if (data?.applicationBiometrics) {
      updateLoaded(true);
      onSuccess(data.applicationBiometrics);
    }
  }, [data, error, handleError, loaded, loading, onSuccess]);

  return {
    getBiometricsResult: sendRequest,
    loading,
  };
};
