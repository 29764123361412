import { FiConnectStyle } from 'fi-connect/lib/types';
import { getPath } from 'basis/dist/utils/objectPath';
import { defaultTheme as defaultBasisTheme } from 'basis';
import { stepperTheme } from './stepper';
import { radioGroupTheme } from './radioGroup';
import { checkboxTheme } from './checkbox';
import { fieldTheme } from './field';
import { linkTheme } from './link';
import { listTheme } from './list';
import { accordionTheme } from './accordion';
import { sharedTheme } from '../shared';

const textBodyColor = 'grey.t75';
const fontSizes = [
  '14px', // 0
  '16px', // 1
  '18px', // 2
  '20px', // 3
  '24px', // 4
  '32px', // 5
  '40px', // 6
  '48px', // 7
  '104px', // 8
];
const lineHeights = [
  '20px', // 0
  '22px', // 1
  '24px', // 2
  '28px', // 3
  '36px', // 4
  '48px', // 5
  '56px', // 6
  '120px', // 7
];

const baseTheme = {
  ...sharedTheme,
  highlightColor: 'grey.t16',
  backgroundColor: '#F6F6FE',
  radioColor: 'white',
  checkboxColor: 'white',
  headingColor: '#333',
  fiConnectStyle: FiConnectStyle.WHITE_LABEL,
  headingSize: 'heading4',
  fontSizes,
  lineHeights,
  textStyles: {
    hero: {
      fontFamily: defaultBasisTheme.fonts.heading,
      fontWeight: defaultBasisTheme.fontWeights.semiBold,
      fontSize: fontSizes[8],
      lineHeight: lineHeights[7],
      letterSpacing: defaultBasisTheme.letterSpacings.hero,
    },
    // This is needed in order to override browser's default
    'hero.bold': {
      fontWeight: defaultBasisTheme.fontWeights.semiBold,
    },
    heading1: {
      fontFamily: defaultBasisTheme.fonts.heading,
      fontWeight: defaultBasisTheme.fontWeights.semiBold,
      fontSize: fontSizes[7],
      lineHeight: lineHeights[6],
      letterSpacing: defaultBasisTheme.letterSpacings.heading1,
    },
    'heading1.bold': {
      fontWeight: defaultBasisTheme.fontWeights.semiBold,
    },
    heading2: {
      fontFamily: defaultBasisTheme.fonts.heading,
      fontWeight: defaultBasisTheme.fontWeights.semiBold,
      fontSize: fontSizes[6],
      lineHeight: lineHeights[5],
      letterSpacing: defaultBasisTheme.letterSpacings.heading2,
    },
    'heading2.bold': {
      fontWeight: defaultBasisTheme.fontWeights.semiBold,
    },
    heading3: {
      fontFamily: defaultBasisTheme.fonts.heading,
      fontWeight: defaultBasisTheme.fontWeights.semiBold,
      fontSize: fontSizes[5],
      lineHeight: lineHeights[4],
      letterSpacing: defaultBasisTheme.letterSpacings.heading3,
    },
    'heading3.bold': {
      fontWeight: defaultBasisTheme.fontWeights.semiBold,
    },
    heading4: {
      fontFamily: defaultBasisTheme.fonts.heading,
      fontWeight: defaultBasisTheme.fontWeights.semiBold,
      fontSize: fontSizes[4],
      lineHeight: lineHeights[3],
      letterSpacing: defaultBasisTheme.letterSpacings.heading4,
    },
    'heading4.bold': {
      fontWeight: defaultBasisTheme.fontWeights.semiBold,
    },
    heading5: {
      fontFamily: defaultBasisTheme.fonts.heading,
      fontWeight: defaultBasisTheme.fontWeights.semiBold,
      fontSize: fontSizes[3],
      lineHeight: lineHeights[2],
      letterSpacing: defaultBasisTheme.letterSpacings.heading5,
    },
    'heading5.bold': {
      fontWeight: defaultBasisTheme.fontWeights.semiBold,
    },
    heading6: {
      fontFamily: defaultBasisTheme.fonts.heading,
      fontWeight: defaultBasisTheme.fontWeights.semiBold,
      fontSize: fontSizes[1],
      lineHeight: lineHeights[1],
      letterSpacing: defaultBasisTheme.letterSpacings.heading6,
    },
    'heading6.bold': {
      fontWeight: defaultBasisTheme.fontWeights.semiBold,
    },
    subtitle1: {
      fontFamily: defaultBasisTheme.fonts.body,
      fontWeight: defaultBasisTheme.fontWeights.light,
      fontSize: fontSizes[4],
      lineHeight: lineHeights[4],
      letterSpacing: defaultBasisTheme.letterSpacings.body,
    },
    'subtitle1.bold': {
      fontWeight: defaultBasisTheme.fontWeights.medium,
    },
    subtitle2: {
      fontFamily: defaultBasisTheme.fonts.body,
      fontWeight: defaultBasisTheme.fontWeights.light,
      fontSize: fontSizes[2],
      lineHeight: lineHeights[3],
      letterSpacing: defaultBasisTheme.letterSpacings.body,
    },
    'subtitle2.bold': {
      fontWeight: defaultBasisTheme.fontWeights.medium,
    },
    body1: {
      fontFamily: defaultBasisTheme.fonts.body,
      fontWeight: defaultBasisTheme.fontWeights.light,
      defaultColor: textBodyColor,
      fontSize: fontSizes[1],
      lineHeight: lineHeights[2],
      letterSpacing: defaultBasisTheme.letterSpacings.body,
    },
    'body1.bold': {
      defaultColor: textBodyColor,
      fontWeight: defaultBasisTheme.fontWeights.medium,
    },
    body2: {
      defaultColor: textBodyColor,
      fontFamily: defaultBasisTheme.fonts.body,
      fontWeight: defaultBasisTheme.fontWeights.light,
      fontSize: fontSizes[0],
      lineHeight: lineHeights[0],
      letterSpacing: defaultBasisTheme.letterSpacings.body,
    },
    'body2.bold': {
      defaultColor: textBodyColor,
      fontWeight: defaultBasisTheme.fontWeights.medium,
    },
    legal: {
      defaultColor: textBodyColor,
      fontFamily: defaultBasisTheme.fonts.body,
      fontWeight: defaultBasisTheme.fontWeights.light,
      fontSize: fontSizes[0],
      lineHeight: lineHeights[2],
      letterSpacing: defaultBasisTheme.letterSpacings.body,
    },
    'legal.bold': {
      defaultColor: textBodyColor,
      fontWeight: defaultBasisTheme.fontWeights.medium,
    },
    overline: {
      defaultColor: textBodyColor,
      fontFamily: defaultBasisTheme.fonts.body,
      fontWeight: defaultBasisTheme.fontWeights.bold,
      fontSize: fontSizes[0],
      lineHeight: lineHeights[0],
      letterSpacing: defaultBasisTheme.letterSpacings.overline,
      textTransform: 'uppercase',
    },
    'overline.bold': {
      defaultColor: textBodyColor,
      fontWeight: defaultBasisTheme.fontWeights.bold,
    },
  },
  primaryButtonColor: '#0061EE',
  spinnerColor: 'black',
  link: {
    color: '#0061ee',
  },
  colors: {
    ...sharedTheme.colors,
    specialBackground: '#EEEBE7',
  },
};

const helpers = {
  getColor: color => {
    return getPath(baseTheme.colors, color);
  },
  getTextStyle: ({ name, mode }) => {
    switch (mode) {
      case 'self': {
        return baseTheme.textStyles[name];
      }

      case 'self-bold': {
        return {
          ...baseTheme.textStyles[name],
          ...baseTheme.textStyles[`${name}.bold`],
        };
      }

      case 'container': {
        const boldCSS = baseTheme.textStyles[`${name}.bold`];

        return {
          ...baseTheme.textStyles[name],
          ...(boldCSS && {
            '& strong': boldCSS,
            '& b': boldCSS,
          }),
        };
      }

      default: {
        return null;
      }
    }
  },
};

export const davidJonesTheme = {
  ...baseTheme,
  stepper: stepperTheme(baseTheme, helpers),
  radioGroup: radioGroupTheme(baseTheme, helpers),
  checkbox: checkboxTheme(baseTheme, helpers),
  field: fieldTheme(baseTheme, helpers),
  link: linkTheme(baseTheme, helpers),
  list: listTheme(baseTheme, helpers),
  accordion: accordionTheme(baseTheme, helpers),
};
