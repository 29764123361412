import React from 'react';
import { Container } from 'basis';
import { ReturnButton } from 'components';

function DownloadResources() {
  return (
    <Container padding="5" padding-md="5 0 0 10">
      <ReturnButton />
    </Container>
  );
}

export { DownloadResources };
