import { CUSTOMER_SELECTED } from 'store';
import { FIELD_DISCLOSED_DEBTS, FIELD_ADDITIONAL_LOANS } from './constants';

export const toUpdateDebtsPayload = (formValues, storeState) => {
  const undisclosedDebts = storeState.undisclosedDebts.map((ccrDebt, index) => {
    const userInput = formValues[FIELD_DISCLOSED_DEBTS].find(debtInput => debtInput.id === ccrDebt.id);
    return {
      id: ccrDebt.id,
      source: ccrDebt.source,
      creditProvider: ccrDebt.creditProvider,
      category: ccrDebt.category,
      currentLimit: ccrDebt.currentLimit,
      isPrimaryResidencyAssociated: userInput?.isPrimaryResidencyAssociated || false,
      doNotContributeFlag: !!userInput?.doNotContribute,
      comment: userInput?.loanClosedReason || '',
      repayment: {
        amount: userInput?.monthlyRepayment || '0',
        frequency: 'MONTHLY',
      },
    };
  });

  const baseId = storeState.undisclosedDebts.length;

  const additionalLoans = formValues[FIELD_ADDITIONAL_LOANS].map((additionalLoan, index) => ({
    id: baseId + index + 1,
    doNotContributeFlag: false,
    comment: '',
    isPrimaryResidencyAssociated: additionalLoan?.isPrimaryResidencyAssociated || false,
    source: CUSTOMER_SELECTED,
    category: additionalLoan.category,
    creditProvider: additionalLoan.creditProvider,
    currentLimit: parseFloat(additionalLoan.currentLimit),
    repayment: {
      amount: additionalLoan.monthlyRepayment,
      frequency: 'MONTHLY',
    },
  }));

  return {
    applicationId: storeState.applicationId,
    debts: [...undisclosedDebts, ...additionalLoans],
  };
};
