import { setSessionStorage } from 'utils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getDefaultFeatures } from 'featureToggles';

const useLaunchDarklyFlags = () => {
  const launchDarklyFlags = useFlags();
  const defaultFlags = getDefaultFeatures();
  const flags = Object.keys(launchDarklyFlags)
    .filter(key => Object.keys(defaultFlags).includes(key))
    .reduce((cur, key) => {
      return Object.assign(cur, { [key]: launchDarklyFlags[key] });
    }, {});
  const allFlags = { ...defaultFlags, ...flags };
  setSessionStorage('launchDarklyFlags', allFlags);
};

export { useLaunchDarklyFlags };
