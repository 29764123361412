import React from 'react';
import { FieldCheckbox, FieldMoneyInput, FieldInput, HiddenInput } from 'components';
import { Divider } from '@chakra-ui/react';
import { Stack, Text } from 'basis';
import { LoanProviderDetails } from './LoanProviderDetails';
import { FIELD_DISCLOSED_DEBTS } from '../constants';
import { HomeLoanCheckbox } from './Fields';

export function UndisclosedDebtSection({ debts, header, watch, showHomeLoanCheckbox = false }) {
  if (debts.length === 0) {
    return null;
  }
  return (
    <>
      <Text textStyle="heading5" margin="5 0">
        {header}
      </Text>
      {debts.map((debt, index) => (
        <Stack key={index} gap="6">
          <HiddenInput id={`${FIELD_DISCLOSED_DEBTS}.${debt.index}.id`} value={debt.id} />
          <HomeLoanCheckbox
            id={`${FIELD_DISCLOSED_DEBTS}.${debt.index}.isPrimaryResidencyAssociated`}
            conditional={showHomeLoanCheckbox}
          />
          <LoanProviderDetails loanProviderName={debt.creditProvider} loanProviderBalance={debt.currentLimit} />
          <FieldCheckbox
            id={`${FIELD_DISCLOSED_DEBTS}.${debt.index}.doNotContribute`}
            label="I do not contribute to this loan"
          />
          <FieldInput
            id={`${FIELD_DISCLOSED_DEBTS}.${debt.index}.loanClosedReason`}
            label="Please tell us why you don't contribute to this loan"
            conditional={watch(`${FIELD_DISCLOSED_DEBTS}.${debt.index}.doNotContribute`) === true}
            maxLength={500}
            rules={{
              required: {
                value: true,
                message: 'Please provide a reason',
              },
            }}
          />
          <FieldMoneyInput
            id={`${FIELD_DISCLOSED_DEBTS}.${debt.index}.monthlyRepayment`}
            label="Monthly repayment"
            helpText="If the repayment amount is incorrect please amend it."
            conditional={watch(`${FIELD_DISCLOSED_DEBTS}.${debt.index}.doNotContribute`) !== true}
          />
          {index + 1 !== debts.length && <Divider marginBottom="6" />}
        </Stack>
      ))}
    </>
  );
}
