import React from 'react';
import { LayoutPage, FormRenderer, LayoutContent } from 'components';
import { Container, Button, Message, Form } from 'basis';
import { submitHandler } from 'utils';
import { useStoreValue } from 'store';
import { useHistory } from 'react-router-dom';
import { useEventTracking } from 'react-event-tracker';
import { InfoBox } from './InfoBox';
import { confirmCreditLimitEvent } from './dataLayer';

export const PreBureau = ({ FORM_ID, initialValues, formConfig }) => {
  const [, updateStore] = useStoreValue();
  const history = useHistory();
  const { trackEvent } = useEventTracking();

  const onSubmit = ({ values, errors }) => {
    function submit() {
      updateStore({
        applicationErrors: null,
        activeStep: '/acceptance',
        creditLimitAccepted: parseInt(values.newCreditLimit, 10),
      });
      trackEvent(confirmCreditLimitEvent);
      history.push('/acceptance');
    }
    submitHandler({ submit, values, errors });
  };

  return (
    <LayoutPage>
      <Container margin="0 0 13 0" hasBreakpointWidth>
        <Form
          id={FORM_ID}
          initialValues={initialValues}
          onSubmit={onSubmit}
          submitButtonLabel="Next"
          showSaveDraftButton
          saveDraftOnSubmit
          saveDraftOnSessionTimeout
        >
          {formData => {
            return (
              <>
                <LayoutContent rowsGap="0" noSessionTimeout>
                  <Container margin="8 0 0 0">
                    <FormRenderer config={formConfig} formData={formData} />
                  </Container>
                  <InfoBox>
                    This credit limit amount is subject to finalisation of your application and responsible lending
                    requirements.
                  </InfoBox>

                  <Button margin="8 0 0 0" type="submit">
                    Confirm credit limit
                  </Button>
                  <Container margin="5 0 0 0">
                    <Message margin="8 0 0 0" severity="info-or-minor" bg="secondary.lightBlue.t25">
                      You can always contact us in the future to request a decrease or increase to your credit limit if
                      your circumstances change.
                    </Message>
                  </Container>
                </LayoutContent>
              </>
            );
          }}
        </Form>
      </Container>
    </LayoutPage>
  );
};
