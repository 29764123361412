import React from 'react';
import { Container, Flex } from 'basis';
import styled from '@emotion/styled';
import attentionImage from 'images/Attention.png';

const AttentionImage = styled.img`
  width: ${props => props.iconSize || '43px'}};
  height: auto;
`;

const Box = styled.div`
  display: block;
  box-sizing: border-box;
  padding: 12px 4px;
  background-color: ${props => props.backgroundColor || '#eff7ff'}};
`;

export function InfoBanner({ children, backgroundColor, iconSize }) {
  return (
    <Box backgroundColor={backgroundColor}>
      <Flex placeItems="center left">
        <AttentionImage src={attentionImage} iconSize={iconSize} alt="information icon" />
        <Container padding="0 0 0 1">{children}</Container>
      </Flex>
    </Box>
  );
}
