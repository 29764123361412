import React, { useCallback, useMemo } from 'react';
import { useEnv, useOktaClient, useErrorTrackingAndUpdateStore } from 'hooks';
import { useStoreValue, OKTA_EMAIL_FACTOR } from 'store';
import { SessionTimeout } from 'components/session-timeout/SessionTimeout';
import { LayoutPage, LayoutContent, SaveAndCompleteLaterButton, Text } from 'components';
import styled from '@emotion/styled';
import { Stack, Grid, Button, List } from 'basis';
import { SuccessMessage } from 'components/message/SuccessMessage';
import { STEP_EMAIL_VERIFICATION, STEP_LATITUDE_ID } from '_config';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useEventTracking } from 'react-event-tracker';
import { getDataLayerElements } from 'utils';
import { DEV_FEATURES } from '__dev/devFeatures';
import { device } from 'media';

const BoldText = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 36px;
  font-family: 'montserrat';
  @media ${device.mobileOrSmaller} {
    font-size: 16px;
  }
`;
export const LatitudeIdIntroduction = () => {
  const [storeState, updateStore] = useStoreValue();
  const history = useHistory();
  const getEnv = useEnv();
  const { trackEvent } = useEventTracking();
  const sessionDurationInSec = getEnv('REACT_APP_SESSION_EXPIRATION_IN_SEC');
  const handleSubmitSuccess = useCallback(() => {
    updateStore({ activeStep: STEP_EMAIL_VERIFICATION, applicationErrors: null });
    history.push(STEP_EMAIL_VERIFICATION);
  }, [history, updateStore]);
  const { handleErrorCallback } = useErrorTrackingAndUpdateStore();
  const { loginAndSendOTP, loading } = useOktaClient({
    onSuccess: handleSubmitSuccess,
    onError: handleErrorCallback,
  });

  const isBrandNewUser = !storeState.isEmailVerified && !storeState.isLatitudeIdPasswordSet;
  const introText = isBrandNewUser
    ? `Your Latitude ID is your username for our digital platforms, the Latitude App and the Latitude Service
  Centre. You need this ID to make it easy for you to sign in and manage your card online.`
    : `Your Latitude ID is your username for our digital platforms, the Latitude App and the Latitude Service Centre. You need this ID to make it easy for you to sign in and manage your card online.`;
  const buttonLabel = !storeState.isEmailVerified ? 'Next - Verify your email' : 'Next - Create password';
  const steps = useMemo(() => {
    const result = [];
    if (!storeState.isEmailVerified) {
      result.push(`Verify your email (${storeState.acquisition.contactDetails.emailAddress})`);
    }
    if (!storeState.isLatitudeIdPasswordSet) {
      result.push('Create a password');
    }
    return result;
  }, [
    storeState.acquisition.contactDetails.emailAddress,
    storeState.isEmailVerified,
    storeState.isLatitudeIdPasswordSet,
  ]);

  const onNext = useCallback(async () => {
    const nextStep = !storeState.isEmailVerified ? STEP_EMAIL_VERIFICATION : STEP_LATITUDE_ID;
    updateStore({
      activeStep: nextStep,
      applicationErrors: null,
    });

    trackEvent({
      event: {
        category: 'application',
        action: 'application-navigation',
        location: 'latitude-id-introduction',
        label: isBrandNewUser ? 'Set up your Latitude ID' : 'Finalise your Latitude ID',
      },
      ...getDataLayerElements(storeState),
    });

    if (nextStep === STEP_EMAIL_VERIFICATION) {
      if (DEV_FEATURES.OTP_SKIP) {
        handleSubmitSuccess();
        return;
      }

      await loginAndSendOTP(storeState.acquisition.contactDetails.emailAddress, OKTA_EMAIL_FACTOR);
      return;
    }

    history.push(nextStep);
  }, [handleSubmitSuccess, history, isBrandNewUser, loginAndSendOTP, storeState, trackEvent, updateStore]);

  return (
    <LayoutPage
      noSessionTimeout
      noTitlePadding
      preHeader={
        <LayoutContent rowsGap="0">
          <SuccessMessage />
        </LayoutContent>
      }
      headerOverride={isBrandNewUser ? 'Set up your Latitude ID' : 'Finalise your Latitude ID'}
    >
      <SessionTimeout sessionDurationInSec={sessionDurationInSec} />
      <LayoutContent rowsGap="0">
        <Stack gap="6">
          <Text>{introText}</Text>
          <BoldText>Here's how:</BoldText>
          <Stack gap="6" marginBottom="2">
            <List type="steps">
              {steps.map((step, index) => (
                <List.Item>
                  <Text>
                    <strong>{step}</strong>
                  </Text>
                </List.Item>
              ))}
            </List>
          </Stack>

          <Buttons onClick={onNext} loading={loading} buttonLabel={buttonLabel} />
        </Stack>
      </LayoutContent>
    </LayoutPage>
  );
};

const Buttons = ({ onClick, loading, buttonLabel }) => {
  return (
    <Grid rowsGap="4" colsGap="10" cols="2" margin="4 0 0 0">
      <Grid.Item colSpan="all" colSpan-sm="0" alignItems="center">
        <FormButtonWrapper>
          <Grid>
            <Button onClick={onClick} loading={loading} padding="0 2">
              {buttonLabel}
            </Button>
          </Grid>
        </FormButtonWrapper>
      </Grid.Item>
      <Grid.Item colSpan="all" colSpan-sm="1" rowSpan-sm="0">
        <FormButtonWrapper>
          <Grid>
            <SaveAndCompleteLaterButton />
          </Grid>
        </FormButtonWrapper>
      </Grid.Item>
    </Grid>
  );
};

const FormButtonWrapper = styled.div`
  & button {
    padding: 12px 16px;
  }
`;
