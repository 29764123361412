import React from 'react';
import {
  Select,
  DatePicker,
  Input,
  RadioGroup,
  TimeSpan,
  Frequency,
  useBreakpoint,
  Checkbox,
  Message,
  OtpInput,
  Grid,
} from 'basis';
import { OneTimeCode } from 'components/one-time-code/OneTimeCode';
import { ConditionalStop } from 'components/conditional-stop/ConditionalStop';
import { InfoBlock } from 'components/info-block/InfoBlock';
import { InfoBanner } from 'components/info-banner/InfoBanner';
import { ReplayName } from 'components/replay-name/ReplayName';
import { Address } from 'components/address/Address';
import { DLNumber } from 'components/dl-number/DLNumber';
import { ValidateInput } from 'components/validate-input/ValidateInput';
import { byCountry } from 'utils';

const ComponentList = {
  Select,
  DatePicker,
  Input,
  OtpInput,
  RadioGroup,
  TimeSpan,
  Frequency,
  Checkbox,
  OneTimeCode,
  ConditionalStop,
  InfoBlock,
  InfoBanner,
  ReplayName,
  Address,
  DLNumber,
  Message,
  ValidateInput,
  CustomComponent: true,
};

export function FormRenderer({ config, formData, disabled, storeState = {} }) {
  const screenSize = useBreakpoint();
  return config.map(
    ({ component = '', condition, breakpoint, disabled: fieldDisabled = false, ...otherProps }, index) => {
      const { values } = formData.state;
      const canRender = !condition || condition(values, storeState);
      const bpProps = breakpoint ? breakpoint(screenSize) : null;
      const props = {
        ...otherProps,
        ...bpProps,
        ...(typeof otherProps.label === 'function' ? { label: otherProps.label(values, storeState) } : null),
      };
      if (canRender) {
        if (props.fields) {
          if (props.horizontal) {
            return (
              <Grid cols="1fr" cols-sm="1fr 1fr" colsGap="6" rowsGap="7">
                <FormRenderer key={props.name} config={props.fields} formData={formData} disabled={disabled} />
              </Grid>
            );
          }
          return <FormRenderer key={props.name} config={props.fields} formData={formData} disabled={disabled} />;
        }

        const Component = ComponentList[component];
        if (!Component) {
          return null;
        }

        switch (component) {
          // basis
          case 'Select': {
            if (typeof props.options === 'function') {
              const options = props.options(values);
              return (
                <div data-hj-suppress key={props.name}>
                  <Component
                    {...props}
                    name={props.name}
                    label={props.label}
                    options={options}
                    value={values[props.name]}
                    disabled={disabled || fieldDisabled}
                  />
                </div>
              );
            }
            return (
              <Component
                {...props}
                key={props.name}
                disabled={disabled || fieldDisabled}
                validateData={formData.state}
              />
            );
          }
          case 'RadioGroup':
            return (
              <Component
                columns={byCountry({ AU: 1, NZ: undefined })}
                color={byCountry({ AU: 'white', NZ: undefined })}
                {...props}
                key={props.name}
                disabled={disabled || fieldDisabled}
                validateData={formData.state}
              />
            );
          case 'Frequency':
            return (
              <Component
                commaSeparated={byCountry({ AU: props.amountPrefix === '$', NZ: false })}
                {...props}
                key={props.name}
                disabled={disabled || fieldDisabled}
                validateData={formData.state}
              />
            );
          case 'Input':
          case 'Checkbox':
          case 'DLNumber':
          case 'ValidateInput':
            return (
              <Component
                {...props}
                key={props.name}
                disabled={disabled || fieldDisabled}
                validateData={formData.state}
              />
            );
          case 'DatePicker':
          case 'TimeSpan':
            return (
              <div data-hj-suppress key={props.name}>
                <Component {...props} disabled={disabled || fieldDisabled} validateData={formData.state} />
              </div>
            );
          // apply-ui
          case 'InfoBanner':
          case 'InfoBlock':
          case 'ReplayName':
          case 'Message':
            return <Component {...props} key={props.name} disabled={disabled || fieldDisabled} />;
          case 'Address':
          case 'OneTimeCode':
          case 'ConditionalStop':
            return <Component {...props} key={props.name} disabled={disabled} formData={formData} />;

          case 'CustomComponent':
            return React.createElement(otherProps.children, {
              ...props,
              key: props.name,
              formData,
              validateData: formData.state,
              disabled: disabled || fieldDisabled,
              storeState,
            });
          default:
            return null;
        }
      }
      return null;
    },
  );
}
