import React from 'react';
import {
  POITYPE_PAYSLIP_UPLOAD,
  POITYPE_DOC_UPLOAD,
  PROOF_OF_INCOME,
  PROOF_OF_ADDRESS_AND_PROOF_OF_INCOME,
  ANTI_MONEY_LAUNDERING_AND_PROOF_OF_INCOME,
  UPLOAD_DOC_SUPPORTED_STATUS,
  PROOF_OF_ADDRESS,
  ANTI_MONEY_LAUNDERING,
} from 'store';
import { Container } from 'basis';
import { DocUpload } from './Documents';
import { PayslipsUpload } from './Payslips';

import { OutcomeReferred } from '../OutcomeReferred/OutcomeReferred';
import { ProofOfAddress } from '../OutcomeConditionallyApproved/ProofOfAddress';
import { AntiMoneyLaundering } from '../OutcomeConditionallyApproved/AntiMoneyLaundering';

const OutcomeConditionallyUpload = ({ storeState }) => {
  const { applicationStatusReason } = storeState;

  if (!UPLOAD_DOC_SUPPORTED_STATUS.includes(applicationStatusReason)) {
    switch (applicationStatusReason) {
      case PROOF_OF_ADDRESS:
        return <ProofOfAddress storeState={storeState} />;
      case ANTI_MONEY_LAUNDERING:
        return <AntiMoneyLaundering storeState={storeState} />;
      default:
        return <OutcomeReferred storeState={storeState} />;
    }
  } else {
    switch (storeState.incomeVerificationType) {
      case POITYPE_PAYSLIP_UPLOAD:
        return (
          <Container padding="8 0 13 0" padding-md="12 0 13 0" margin="0 8">
            <Container hasBreakpointWidth>
              {applicationStatusReason === PROOF_OF_INCOME && <PayslipsUpload POI storeState={storeState} />}
              {applicationStatusReason === ANTI_MONEY_LAUNDERING_AND_PROOF_OF_INCOME && (
                <PayslipsUpload POIAML storeState={storeState} />
              )}
              {applicationStatusReason === PROOF_OF_ADDRESS_AND_PROOF_OF_INCOME && (
                <PayslipsUpload POIPOA storeState={storeState} />
              )}
            </Container>
          </Container>
        );
      case POITYPE_DOC_UPLOAD:
        return (
          <Container padding="8 0 13 0" padding-md="12 0 13 0" margin="0 8">
            <Container hasBreakpointWidth>
              {applicationStatusReason === PROOF_OF_INCOME && <DocUpload POI storeState={storeState} />}
              {applicationStatusReason === ANTI_MONEY_LAUNDERING_AND_PROOF_OF_INCOME && (
                <DocUpload POIAML storeState={storeState} />
              )}
              {applicationStatusReason === PROOF_OF_ADDRESS_AND_PROOF_OF_INCOME && (
                <DocUpload POIPOA storeState={storeState} />
              )}
            </Container>
          </Container>
        );
      default:
        return <OutcomeReferred storeState={storeState} />;
    }
  }
};

export { OutcomeConditionallyUpload };
