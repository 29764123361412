import React, { useState } from 'react';
import { Checkbox, Text, Link } from 'basis';
import { CrbModal } from './Crb.Modal';

export const CrbConsent = () => {
  const [crbModalOpen, setCrbModalOpen] = useState(false);

  const handleCrbClick = event => {
    event.preventDefault();
    setCrbModalOpen(true);
  };

  const closeCrbModals = () => {
    setCrbModalOpen(false);
  };

  return (
    <>
      <Checkbox name="crbConsent" testId="crb-consent" label="" validate={false}>
        <Text>
          I consent to third party ID checks with{' '}
          <Link onClick={handleCrbClick} newTab={false} testId="crb-consent-link">
            credit reporting bodies
          </Link>
          .<Text textStyle="body2">This is not a credit check. </Text>
          <Text textStyle="body2">
            We only check that your name, address and DOB match what the credit reporting bodies hold.
          </Text>
        </Text>
      </Checkbox>

      <CrbModal isOpen={crbModalOpen} onClose={closeCrbModals} />
    </>
  );
};
