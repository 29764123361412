import { useCallback, useMemo, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { getProductInfo, isAU, isNZ, shouldShowAch } from 'utils';
import { getDataLayerElements } from 'utils/getDataLayerElements';
import { useEventTracking } from 'react-event-tracker';
import {
  STEP_ABOUT_YOU,
  STEP_BIOMETRICS_ABOUT_YOU,
  STEP_VERIFY_INCOME,
  STEP_PROCESSING_POI,
  STEP_PROCESSING,
  STEP_CONFIRM_CREDIT_LIMIT,
  STEP_ACCEPTANCE,
  STEP_PROCESSING_ACCEPTANCE,
  STEP_OUTCOME,
  STEP_SAVED,
  STEP_RESUME_YOUR_APPLICATION,
  STEP_RESUME_APPLICATION_EXPIRED,
  STEP_RESUME_ACCEPTANCE,
  STEP_LANDING,
  STEP_CONTACT_DETAILS,
  STEP_VERIFY,
  STEP_VERIFY_IDENTITY,
  STEP_VERIFY_FICONNECT,
  STEP_BIOMETRICS_RETURN,
  STEP_LATITUDE_ID,
  STEP_CONFIRM_INCOME,
  STEP_BIOMETRICS_COMPLETE,
  STEP_BIOMETRICS_CANCELLED,
  STEP_DAVID_JONES_PRESTIGE,
  STEP_DAVID_JONES_PREMIERE,
  STEP_PRODUCT_COMPARISON,
  STEP_EIDV,
  STEP_PROCESSING_IDV,
  STEP_PROCESSING_LOAN_INFORMATION,
  STEP_VERIFY_DOCUMENT_UPLOAD,
  STEP_DVS_CONSENT,
  STEP_OUTCOME_FIN_TABLE,
  STEP_TWENTY_EIGHT_DEGREES,
  STEP_OUTCOME_TIMEOUT,
  STEP_LOAN_INFORMATION,
  STEP_PROCESSING_BIOMETRICS,
  STEP_PROCESSING_PENDING_POI,
  STEP_BIOMETRICS_ADDRESS_DETAILS,
  STEP_PASSWORD,
  STEP_VERIFY_TO_RESUME,
  STEP_GO,
  STEP_AUS_POST,
  STEP_COMMITMENTS,
  STEP_LATITUDE_ID_INTRODUCTION,
  STEP_EMAIL_VERIFICATION,
  STEP_GEM_AU,
  STEP_CREDIT_LINE,
  STEP_BIOMETRICS_IDENTIFICATION,
  STEP_INCOME,
  STEP_EXPENSES,
  STEP_CREDIT_LIMIT,
  STEP_ADDRESS_DETAILS,
  STEP_IDENTIFICATION,
  STEP_ADDITIONAL_CARD_HOLDER,
  config,
  STEP_VERIFY_INSTORE,
  STEP_LOW_RATE,
} from '_config';

import { useStoreValue } from 'store';
import { isDCLOn, isFeatureOn } from 'featureToggles';

const getActiveStepIndex = (activeStep, stepsOrder, current = 0) => {
  return stepsOrder.indexOf(activeStep, current);
};

const getAccessibleSteps = (activeStep, stepsOrder, reverseLimit, isPostSubmission = false) => {
  return stepsOrder.slice(reverseLimit, isPostSubmission ? undefined : getActiveStepIndex(activeStep, stepsOrder) + 2);
};

export const utils = {
  getActiveStepIndex,
  getAccessibleSteps,
};

export const useSteps = () => {
  const [store, updateStore] = useStoreValue();
  const history = useHistory();

  const isBiometricsFlow = ['STARTED', 'SUCCESSFUL'].includes(store.biometricsVerification);

  const draftSteps = useMemo(() => (isBiometricsFlow ? biometricsDraftSteps(store) : nonBiometricsDraftSteps(store)), [
    isBiometricsFlow,
    store,
  ]);
  const { activeStep } = store;
  const isDrafting = draftSteps.includes(activeStep);

  const dynamicCreditLimitFlag = isFeatureOn('dynamicCreditLimit');
  const biometricsFlag = isFeatureOn('biometrics');
  const incomeReplayFlag = isFeatureOn('incomeReplay');

  const stepsOrder = useMemo(() => {
    if (isAU()) {
      return [
        STEP_PRODUCT_COMPARISON,
        STEP_BIOMETRICS_COMPLETE,
        STEP_RESUME_ACCEPTANCE,
        STEP_DAVID_JONES_PRESTIGE,
        STEP_DAVID_JONES_PREMIERE,
        STEP_GO,
        STEP_TWENTY_EIGHT_DEGREES,
        STEP_GEM_AU,
        STEP_CREDIT_LINE,
        STEP_LOW_RATE,
        STEP_LANDING,
        STEP_CONTACT_DETAILS,
        STEP_VERIFY,
        ...(isFeatureOn('emailVerification') ? [] : [STEP_LATITUDE_ID]),
        ...draftSteps,
        STEP_PROCESSING,
        STEP_LOAN_INFORMATION,
        STEP_PROCESSING_LOAN_INFORMATION,
        STEP_CONFIRM_CREDIT_LIMIT,
        STEP_ACCEPTANCE,
        STEP_PROCESSING_ACCEPTANCE,
        ...(isFeatureOn('emailVerification')
          ? [STEP_LATITUDE_ID_INTRODUCTION, STEP_EMAIL_VERIFICATION, STEP_LATITUDE_ID]
          : []),
        STEP_DVS_CONSENT,
        STEP_EIDV,
        STEP_PROCESSING_IDV,
        STEP_VERIFY_INCOME,
        STEP_VERIFY_DOCUMENT_UPLOAD,
        STEP_VERIFY_FICONNECT,
        STEP_PROCESSING_POI,
        STEP_PROCESSING_PENDING_POI,
        STEP_PROCESSING_BIOMETRICS,
        STEP_AUS_POST,
        STEP_VERIFY_INSTORE,
        STEP_OUTCOME,
        STEP_OUTCOME_FIN_TABLE,
      ];
    }
    return [
      STEP_BIOMETRICS_COMPLETE,
      STEP_BIOMETRICS_CANCELLED,
      STEP_RESUME_ACCEPTANCE,
      STEP_LANDING,
      STEP_CONTACT_DETAILS,
      STEP_VERIFY,
      STEP_LATITUDE_ID,
      ...(biometricsFlag ? [STEP_VERIFY_IDENTITY] : []),
      ...draftSteps,
      STEP_VERIFY_INCOME,
      STEP_VERIFY_DOCUMENT_UPLOAD,
      ...(incomeReplayFlag
        ? store.incomeVerificationType === 'POITYPE_BANK_CONNECT'
          ? [STEP_VERIFY_FICONNECT, STEP_PROCESSING_POI, STEP_CONFIRM_INCOME]
          : [STEP_CONFIRM_INCOME]
        : [STEP_VERIFY_FICONNECT, STEP_PROCESSING_POI]),
      STEP_PROCESSING,
      ...(dynamicCreditLimitFlag ? [STEP_CONFIRM_CREDIT_LIMIT] : []),
      STEP_ACCEPTANCE,
      STEP_PROCESSING_ACCEPTANCE,
      STEP_OUTCOME,
    ];
  }, [store.incomeVerificationType, biometricsFlag, draftSteps, incomeReplayFlag, dynamicCreditLimitFlag]);

  const { pathname } = useLocation();
  const activeStepIndex = utils.getActiveStepIndex(activeStep, stepsOrder);
  const indexOfSubmissionStep = stepsOrder.indexOf(STEP_PROCESSING);
  const isPostSubmission = activeStepIndex >= indexOfSubmissionStep;

  // TODO: this can be an issue. When component re-mounts, it will reset reverseLimit to 0. Then user can go back to the previous stage. E.g. user refresh on page `/address-details`,then they can go back to `/verify` page.
  /**
   * Min step index that user can reverse to, via either "Back" button or URL changes.
   */
  const reverseLimit = useRef(0);
  // results in accessibleSteps after this point starting with [activeStep, ...] ie: user can't go back past activeStep
  if (reverseLimit.current !== activeStepIndex && isNewStage(activeStep)) {
    reverseLimit.current = activeStepIndex;
  }

  const canGoBack = useMemo(() => {
    const hasErrors = !!store.applicationErrors;
    return !hasErrors && !isNewStage(pathname);
  }, [pathname, store.applicationErrors]);

  const accessibleSteps = utils.getAccessibleSteps(activeStep, stepsOrder, reverseLimit.current, isPostSubmission);

  accessibleSteps.push(STEP_OUTCOME_FIN_TABLE);
  accessibleSteps.push(STEP_SAVED);
  if (pathname === STEP_VERIFY) {
    accessibleSteps.push(STEP_LATITUDE_ID);
    accessibleSteps.push(STEP_ABOUT_YOU);
    accessibleSteps.push(STEP_VERIFY_IDENTITY);

    if (isNZ() && !biometricsFlag) {
      accessibleSteps.push(STEP_PASSWORD);
    }
  }

  if (pathname === STEP_LATITUDE_ID) {
    accessibleSteps.push(STEP_ABOUT_YOU);
    accessibleSteps.push(STEP_VERIFY_IDENTITY);
  }

  if (isNZ() && pathname === STEP_PROCESSING_BIOMETRICS) {
    accessibleSteps.push(STEP_VERIFY_IDENTITY);
  }

  if (pathname === STEP_BIOMETRICS_RETURN) {
    accessibleSteps.push(STEP_ABOUT_YOU);
    accessibleSteps.push(STEP_BIOMETRICS_ABOUT_YOU);
    accessibleSteps.push(STEP_BIOMETRICS_ADDRESS_DETAILS);
  }

  if ([STEP_COMMITMENTS, STEP_PASSWORD, STEP_CONFIRM_CREDIT_LIMIT, STEP_CONFIRM_INCOME].includes(pathname)) {
    accessibleSteps.push(STEP_PROCESSING);
  }

  if (pathname === STEP_PROCESSING_IDV) {
    accessibleSteps.push(STEP_EIDV);
  }

  if (pathname === STEP_VERIFY_INCOME) {
    accessibleSteps.push(STEP_VERIFY_FICONNECT);
    accessibleSteps.push(STEP_VERIFY_DOCUMENT_UPLOAD);

    if (incomeReplayFlag) {
      accessibleSteps.push(STEP_CONFIRM_INCOME);
    }
  }

  if (pathname === STEP_PROCESSING_ACCEPTANCE) {
    accessibleSteps.push(STEP_VERIFY_FICONNECT);
    accessibleSteps.push(STEP_VERIFY_DOCUMENT_UPLOAD);
  }

  if (config.productCode) {
    accessibleSteps.push(getProductInfo().landingPath);
  }

  if (store.biometricsVerification === 'STARTED') {
    // Enable cancellation on Biometrics vendor that will redirect user to manual flow
    accessibleSteps.push(STEP_ABOUT_YOU);
  }

  if (pathname === '/') {
    accessibleSteps.push(STEP_RESUME_YOUR_APPLICATION);
  }
  if (pathname === '/') {
    accessibleSteps.push(STEP_OUTCOME_FIN_TABLE);
  }
  if (pathname === STEP_RESUME_YOUR_APPLICATION) {
    accessibleSteps.push(STEP_VERIFY_TO_RESUME);
  }
  const currentStepIndex = stepsOrder.findIndex(step => step === pathname);
  const { trackEvent } = useEventTracking();
  const nextStep = stepsOrder[currentStepIndex + 1];
  const previousStep = stepsOrder[currentStepIndex - 1];

  const goNext = useCallback(
    ({ storePatch, dataLayerEventLabel } = { storePatch: null, dataLayerEventLabel: null }) => {
      updateStore({
        activeStep: nextStep,
        ...(storePatch || {}),
      });

      trackEvent({
        event: {
          category: 'application',
          action: 'application-navigation',
          location: pathname.slice(1),
          label: dataLayerEventLabel || 'Next',
        },
        ...getDataLayerElements({ ...store, ...storePatch }),
      });

      history.push(nextStep);
    },
    [history, nextStep, pathname, store, trackEvent, updateStore],
  );

  const goBack = useCallback(
    ({ dataLayerEventLabel } = { storePatch: null, dataLayerEventLabel: null }) => {
      if (!canGoBack) {
        return;
      }
      updateStore({
        activeStep: previousStep,
      });

      trackEvent({
        event: {
          category: 'application',
          action: 'application-navigation',
          location: pathname.slice(1),
          label: dataLayerEventLabel || 'Back',
        },
        ...getDataLayerElements(store),
      });

      if (store.previousPathName === previousStep) {
        history.goBack();
      } else {
        history.push(previousStep);
      }
    },
    [canGoBack, history, pathname, previousStep, store, trackEvent, updateStore],
  );

  const isLastDraftStep = pathname === draftSteps[draftSteps.length - 1];

  return {
    isLastDraftStep,
    pathname,
    accessibleSteps,
    draftSteps,
    activeStep,
    previousStep,
    isDrafting,
    canGoBack,
    goNext,
    goBack,
    nextStep,
  };
};

const isNewStage = path => {
  if (path.includes('processing')) {
    return true;
  }

  if (
    [
      STEP_VERIFY,
      STEP_VERIFY_TO_RESUME,
      STEP_BIOMETRICS_COMPLETE,
      STEP_SAVED,
      STEP_RESUME_ACCEPTANCE,
      STEP_RESUME_APPLICATION_EXPIRED,
      STEP_RESUME_YOUR_APPLICATION,
      STEP_CONTACT_DETAILS,
      STEP_LATITUDE_ID_INTRODUCTION,
      STEP_LATITUDE_ID,
      STEP_EMAIL_VERIFICATION,
      STEP_ABOUT_YOU,
      STEP_BIOMETRICS_RETURN,
      STEP_BIOMETRICS_ABOUT_YOU,
      STEP_LOAN_INFORMATION,
      STEP_PROCESSING_LOAN_INFORMATION,
      STEP_DVS_CONSENT,
      STEP_EIDV,
      STEP_VERIFY_INCOME,
      STEP_CONFIRM_INCOME,
      STEP_CONFIRM_CREDIT_LIMIT,
      STEP_ACCEPTANCE,
      STEP_PROCESSING_BIOMETRICS,
      STEP_OUTCOME,
      STEP_OUTCOME_TIMEOUT,
    ].includes(path)
  ) {
    return true;
  }

  return false;
};

function biometricsDraftSteps(storeState) {
  const isIdDocVerifiedFromBiometrics = !!storeState?.biometricsResult?.idType;

  return [
    STEP_PROCESSING_BIOMETRICS,
    STEP_BIOMETRICS_RETURN,
    STEP_BIOMETRICS_ABOUT_YOU,
    STEP_BIOMETRICS_ADDRESS_DETAILS,
    ...(isIdDocVerifiedFromBiometrics ? [] : [STEP_BIOMETRICS_IDENTIFICATION]),
    STEP_INCOME,
    STEP_EXPENSES,
    STEP_COMMITMENTS,
    ...(isDCLOn() ? [] : [STEP_CREDIT_LIMIT]),
  ];
}

function nonBiometricsDraftSteps(storeState) {
  if (isNZ()) {
    return [
      ...(!isFeatureOn('biometrics') ? [STEP_PASSWORD] : []),
      STEP_ABOUT_YOU,
      STEP_ADDRESS_DETAILS,
      STEP_IDENTIFICATION,
      STEP_INCOME,
      STEP_EXPENSES,
      STEP_COMMITMENTS,
      ...(!isFeatureOn('dynamicCreditLimit') ? [STEP_CREDIT_LIMIT] : []),
      ...(isFeatureOn('biometrics') && !usedBiometrics(storeState) ? [STEP_PASSWORD] : []),
    ];
  }
  if (isAU()) {
    return [
      STEP_ABOUT_YOU,
      STEP_ADDRESS_DETAILS,
      STEP_INCOME,
      STEP_EXPENSES,
      STEP_COMMITMENTS,
      ...(shouldShowAch(storeState) ? [STEP_ADDITIONAL_CARD_HOLDER] : []),
    ];
  }
  throw new Error(`Unexpected country code: ${config.countryCode}`);
}

export const usedBiometrics = storeState => ['SUCCESSFUL', 'DATA_INVALID'].includes(storeState?.biometricsVerification);
