import React from 'react';
import styled from '@emotion/styled';

const InternalLink = styled.button`
  position: relative;
  font-size: 18px;
  line-height: 28px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  margin: 16px 0px 0px 0px;
  border: 0;
  padding: 0 24px;
  min-height: 48px;
  overflow: hidden;
  -webkit-transition: background-color 150ms ease, color 150ms ease;
  transition: background-color 150ms ease, color 150ms ease;
  border-radius: 4px;
  outline: 0;
  background-color: transparent;
  color: #006aff;
  border-width: 1px;
  border-style: solid;
  border-color: #006aff;
  &: hover {
    background-color: #006aff;
    color: #ffffff;
  }
`;

export const BiometricsChangeAddressButton = ({ children, onClick }) => {
  return (
    <InternalLink data-testid="change-address" onClick={onClick}>
      <strong style={{ fontWeight: 500 }}>{children}</strong>
    </InternalLink>
  );
};
