import { defaultTheme as defaultBasisTheme } from 'basis';

const primaryFontColor = '#42484D';
const primaryFontSize = '14px';
const linkFontColor = '#066AFF';

export const sharedTheme = {
  ...defaultBasisTheme,
  color: primaryFontColor,
  fontSize: primaryFontSize,
  approvedBackgroundColor: '#eff7ff',
  approvedBorderColor: '#d8edff',
  screenSize: {
    xxs: '320px',
    xs: '375px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
  },
  breakpointMaxWidths: {
    sm: '540px',
    md: '720px',
    lg: '960px',
    xl: '1150px',
  },
  a: {
    color: linkFontColor,
    textDecoration: 'none',
    cursor: 'hand',
  },
  colors: {
    black: '#000000',
    grey: {
      t75: '#414141',
      t65: '#595959',
      t30: '#b2b2b2',
      t16: '#d6d6d6',
      t10: '#e5e5e5',
      t07: '#ececec',
      t05: '#f2f2f2',
      t03: '#f8f8f8',
    },
    white: '#ffffff',
    primary: {
      blue: {
        t100: '#0046aa',
        t80: '#336bbb',
        t60: '#7fa2d4',
        t30: '#b2c7e5',
        t10: '#e5ecf6',
      },
    },
    secondary: {
      lightBlue: {
        t100: '#63b8ff',
        t80: '#82c6ff',
        t60: '#b1dbff',
        t25: '#d8edff',
        t15: '#eff7ff',
      },
      pink: {
        t100: '#ff94ca',
        t80: '#ffa9d5',
        t60: '#ffc9e4',
        t30: '#ffdeef',
        t15: '#fff4f9',
      },
      purple: {
        t100: '#aba7f6',
        t80: '#bcb9f8',
        t60: '#d5d3fa',
        t30: '#e5e4fc',
        t15: '#f6f6fe',
      },
      turquoise: {
        t100: '#0fdbf3',
        t80: '#3fe2f5',
        t60: '#87edf9',
        t30: '#b7f4fb',
        t10: '#e7fbfd',
      },
    },
    highlight: {
      blue: {
        t100: '#006aff',
        t80: '#337ef8',
        t50: '#4693fa',
        t30: '#52a0fb',
        t15: '#54acff',
      },
      pink: {
        t100: '#c31b6c',
        t80: '#cf337f',
        t50: '#e1589b',
        t30: '#ed6fae',
        t15: '#f682bc',
      },
      purple: {
        t100: '#7a65d4',
        t80: '#8472db',
        t50: '#9386e5',
        t30: '#9c93ec',
        t15: '#a49df1',
      },
    },
    conditional: {
      positive: {
        graphics: '#1a8450',
        text: '#1b633c',
      },
      attention: {
        graphics: '#f9b845',
        text: '#414141',
      },
      negative: {
        graphics: '#cf000f',
        text: '#b3000c',
      },
    },
  },
};
