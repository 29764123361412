import React from 'react';
import { Container, Grid, Link, Stack, Text } from 'basis';
import { LayoutPage } from 'components';
import AdrianaMartinezSignature from 'images/adriana_martinez.png';
import { config } from '../../../../_config';
import { davidJonesTheme } from '../../../../theme';
import {
  StyledDivSpaceBetween,
  StyledULWideLeftPadding as StyledUL,
  StyledLeftDivWidth75,
  StyledDivAlignRight,
} from './Styles';

export function GoFinancialTable({
  ratesAndFees,
  statementDate,
  downloadOrPrintFile,
  productName,
  accountNumber,
  creditLimit,
  productCode,
}) {
  const {
    purchaseAnnualRate,
    cashAnnualRate,
    expiredPromotionalPurchaseRate,
    monthlyFee,
    cashAdvanceFeePercentagePerTransaction,
    cashAdvanceFeeFixed,
    lateFee,
    paperStatementFee,
    ausPostHandlingFee,
    onlinePaymentHandlingFee,
    balanceTransferringFee,
    establishmentFee,
    internationalCashAccessRate,
    internationalCashAccessFee,
    internationalCashAccessAdditionalFee,
    internationalCounterTransactionFee,
    internationalTransactionFee,
    replacementCardFee,
  } = ratesAndFees;

  return (
    <LayoutPage hideBackButton>
      <Container margin="0 0 0 0">
        <Container padding="5 0" padding-md="5" testId="LAYOUT FORM">
          <Grid preset="page" debug={false}>
            <Grid.Item colSpan="all">
              <Grid rowsGap={0}>
                <Container padding="4 4" margin="0 8">
                  <div style={{ marginBottom: '40px' }}>
                    <Link variant="secondary" id="pdfButton" onClick={downloadOrPrintFile}>
                      Download or Print file
                    </Link>
                  </div>
                  <Grid preset="page" colsGap={11}>
                    <Grid.Item colSpan="all">
                      <Grid rowsGap={1}>
                        <Container padding="0">
                          <div id="financial-table">
                            <Stack gap="1">
                              <Text
                                as="h1"
                                textStyle="heading5"
                                textStyle-md="heading4"
                                color={davidJonesTheme.headingColor}
                              >
                                FINANCIAL TABLE
                              </Text>
                              <hr />
                              <Text>
                                The details below are correct as at the disclosure date. The{' '}
                                <strong>financial table</strong> does not contain all of the pre-contractual information{' '}
                                <strong>we</strong> are required by law to give <strong>you</strong>. Further terms and{' '}
                                conditions (and an explanation of some of the words used in the{' '}
                                <strong>financial table</strong>) are in the Conditions of Use in the enclosed booklet{' '}
                                entitled {productName} Conditions of Use and Credit Guide, which includes important{' '}
                                information about <strong>your credit contract</strong> and privacy.
                              </Text>
                              <br />
                              <StyledDivSpaceBetween>
                                <Container>
                                  <Text textStyle="heading6">Account Number</Text>
                                </Container>
                                <Container>
                                  <Text textStyle="heading6">Disclosure Date</Text>
                                </Container>
                                <Text textStyle="heading6">Credit Limit</Text>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <Container>
                                  <data-hj-suppress>
                                    <Text>{accountNumber}</Text>
                                  </data-hj-suppress>
                                </Container>
                                <Container margin={0} margin-md="0 0 0 -11">
                                  <Text>{statementDate}</Text>
                                </Container>
                                <data-hj-suppress>
                                  <Text>${creditLimit}</Text>
                                </data-hj-suppress>
                              </StyledDivSpaceBetween>
                              <br />
                              <Text>
                                <strong>Cash Limit</strong> <i>(refer to your statement)</i>
                              </Text>
                              <hr />
                              <Text>
                                A cash limit forms part of your credit limit which may be withdrawn in cash or cash{' '}
                                equivalent transactions. A cash limit may be imposed, withdrawn or changed on your{' '}
                                account from time to time, which is notified to you on each statement we give you and,{' '}
                                is effective from the date of each statement.{' '}
                              </Text>
                              <br />
                              <Text textStyle="heading5">Annual Percentage Rates</Text>
                              <hr />
                              <StyledDivSpaceBetween>
                                <Text textStyle="heading6">Credit Card Annual Percentage Rate</Text>
                                <Text>{purchaseAnnualRate}%</Text>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <Text textStyle="heading6">Cash Annual Percentage Rate</Text>
                                <Text>{cashAnnualRate}%</Text>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <Container>
                                  <Text textStyle="heading6">Expired Promotional Purchases</Text>
                                  <Text textStyle="heading6">Annual Percentage Rate</Text>
                                </Container>
                                <Text>{expiredPromotionalPurchaseRate}%</Text>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <div>
                                  <Text textStyle="heading6">Balance transfer rate for balance transfer promotion</Text>
                                  <Text>
                                    Please refer to the balance transfer interest rate set out in any offer presented to
                                    you.
                                  </Text>
                                </div>
                              </StyledDivSpaceBetween>
                              <StyledUL>
                                <li>
                                  The <strong>Credit Card Annual Percentage Rate</strong> applies to the{' '}
                                  <strong>credit card purchase balance</strong>.
                                </li>
                                <li>
                                  The <strong>Cash Annual Percentage Rate</strong> applies to the{' '}
                                  <strong>cash advance balance</strong>.
                                </li>
                                <li>
                                  The <strong>Cash Annual Percentage Rate</strong> applies to the{' '}
                                  <strong>expired balance transfer</strong> on either a{' '}
                                  <strong>special rate balance transfer</strong> or{' '}
                                  <strong>balance transfer offer</strong>.
                                </li>
                                <li>
                                  The <strong>Expired Promotional Purchase Annual Percentage Rate</strong> applies to
                                  the <strong>expired purchases balance</strong>, which includes{' '}
                                  <strong>expired promotional purchases</strong>, <strong>general fees</strong> and{' '}
                                  amounts (if any) transferred under clause 45.2 of the Conditions of Use.
                                </li>
                                <li>
                                  For each <strong>special rate purchase balance</strong>, the relevant{' '}
                                  <strong>special annual percentage rate</strong> applies and for each{' '}
                                  <strong>own-it-now purchase balance</strong>, the relevant{' '}
                                  <strong>own-it-now annual percentage rate</strong> applies.
                                </li>
                                <li>
                                  For each <strong>special rate balance transfer</strong> or{' '}
                                  <strong>balance transfer offer</strong>, the relevant{' '}
                                  <strong>special annual percentage rate</strong> applies as disclosed to you in the
                                  offer.
                                </li>
                              </StyledUL>
                              <br />
                              <Text textStyle="heading5">Maximum Interest Free Period</Text>
                              <hr />
                              <Text>
                                Up to 55 days for <strong>purchases</strong> except for{' '}
                                <strong>promotional offers</strong> with an interest free period. The maximum period for{' '}
                                <strong>purchases</strong> and <strong>balance transfers</strong> under{' '}
                                <strong>promotional offers</strong> is up to 60 months.
                              </Text>
                              <br />
                              <Text textStyle="heading5">Minimum Repayments</Text>
                              <hr />
                              <Text>
                                The minimum amount payable for the first <strong>statement period</strong> will consist{' '}
                                of:
                              </Text>
                              <StyledUL>
                                <li>
                                  Your <strong>minimum monthly payment</strong> – this amount is payable by the{' '}
                                  <strong>due date</strong> shown on the relevant statement of account; and
                                </li>
                                <li>
                                  Any <strong>overdue</strong> amount and any <strong>over limit</strong> amount - these{' '}
                                  amounts are payable immediately; and
                                </li>
                                <li>
                                  Any other amount due under the <strong>credit contract</strong> which is payable on{' '}
                                  demand (for example, enforcement expenses).
                                </li>
                              </StyledUL>
                              <br />
                              <Text textStyle="heading5">Fees and Charges</Text>
                              <hr />
                              <StyledDivSpaceBetween>
                                <StyledLeftDivWidth75>
                                  <Text>
                                    An establishment fee is payable as part of the{' '}
                                    <strong>minimum monthly payment</strong>, due immediately after the first{' '}
                                    <strong>statement period</strong> in which the <strong>closing balance</strong>{' '}
                                    includes an amount owing other than for the establishment fee or a{' '}
                                    <strong>buy now pay later promotion</strong>. This is also the total amount of{' '}
                                    credit fees and charges, to the extent ascertainable.
                                  </Text>
                                </StyledLeftDivWidth75>
                                <div style={{ textAlign: 'right' }}>
                                  <Text>
                                    <strong>${establishmentFee?.toFixed(2)}</strong>
                                  </Text>
                                </div>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <StyledLeftDivWidth75>
                                  <Text>An account service fee will be charged to the account monthly.</Text>
                                </StyledLeftDivWidth75>
                                <div style={{ textAlign: 'right' }}>
                                  <Text>
                                    <strong>${monthlyFee}</strong>
                                  </Text>
                                </div>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <StyledLeftDivWidth75>
                                  <Text>
                                    A <strong>cash advance fee</strong> will be charged to the <strong>account</strong>{' '}
                                    on each occasion on which you obtain a <strong>cash advance</strong> or make a{' '}
                                    <strong>cash equivalent transaction</strong>.
                                  </Text>
                                </StyledLeftDivWidth75>
                                <div style={{ textAlign: 'right' }}>
                                  <Text>{cashAdvanceFeePercentagePerTransaction}% of the</Text>
                                  <Text>
                                    <strong>cash advance</strong> or
                                  </Text>
                                  <Text>
                                    <strong>${cashAdvanceFeeFixed}</strong> whichever is greater
                                  </Text>
                                </div>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <Text>
                                  An international cash access fee will be charged to the <strong>account</strong> in
                                  addition to the <strong>cash advance</strong> fee on each occasion you obtain cash
                                  from an ATM outside Australia.
                                </Text>
                                <StyledDivAlignRight>
                                  <Text>
                                    Greater of {internationalCashAccessRate}% or $
                                    {internationalCashAccessFee?.toFixed(2)} PLUS $
                                    {internationalCashAccessAdditionalFee?.toFixed(2)}
                                  </Text>
                                </StyledDivAlignRight>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <Text>
                                  An international counter transaction fee will be charged to the{' '}
                                  <strong>account</strong> in addition to the <strong>cash advance</strong> fee on each{' '}
                                  occasion you obtain a <strong>cash advance</strong> or make a{' '}
                                  <strong>cash equivalent transaction</strong> outside Australia other than from an{' '}
                                  <strong>ATM</strong>.
                                </Text>
                                <StyledDivAlignRight>
                                  <Text>
                                    <strong>${internationalCounterTransactionFee?.toFixed(2)}</strong>
                                  </Text>
                                </StyledDivAlignRight>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <Text>
                                  An international transaction fee equivalent to a specific percentage of the amount of{' '}
                                  the <strong>international transaction</strong> converted to Australian dollars, the{' '}
                                  amount of the <strong>international transaction</strong> will be charged to your{' '}
                                  <strong>account</strong> when the <strong>international transaction</strong> is{' '}
                                  debited to your <strong>account</strong>. The fee will be included with the{' '}
                                  <strong>international transaction</strong> amount when it appears on your statement of{' '}
                                  account. This fee will also be shown separately on your statement.
                                </Text>
                                <StyledDivAlignRight>
                                  <Text>
                                    {internationalTransactionFee?.toFixed(2)}% of the Australian dollar value of the{' '}
                                    <strong>international transaction</strong>
                                  </Text>
                                </StyledDivAlignRight>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <Text>
                                  A <strong>late fee</strong> will be charged to the <strong>account</strong> in each{' '}
                                  <strong>statement period</strong> when any amount due and payable to{' '}
                                  <strong>us</strong> is not paid in full by the <strong>due date</strong>.
                                </Text>
                                <StyledDivAlignRight>
                                  <Text>
                                    <strong>${lateFee?.toFixed(2)}</strong>
                                  </Text>
                                </StyledDivAlignRight>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <StyledLeftDivWidth75>
                                  <Text>
                                    A paper statement fee will be charged for each paper statement of account sent to{' '}
                                    <strong>you</strong>. This fee will not be charged if <strong>you</strong> consent
                                    to receiving <strong>your</strong> statements electronically.
                                  </Text>
                                </StyledLeftDivWidth75>
                                <StyledDivAlignRight>
                                  <Text>
                                    <strong>${paperStatementFee?.toFixed(2)}</strong>
                                  </Text>
                                </StyledDivAlignRight>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <StyledLeftDivWidth75>
                                  <Text>
                                    A payment handling fee will be charged to <strong>your account</strong> each time
                                    you make a payment to <strong>your account</strong>, except that no fee is payable
                                    for payments made by mail, by direct debit or via the{' '}
                                    <strong>Online Service Centre</strong>.
                                  </Text>
                                </StyledLeftDivWidth75>
                                <StyledDivAlignRight>
                                  <Text>
                                    <strong>${ausPostHandlingFee?.toFixed(2)} (in person)</strong>
                                  </Text>
                                  <Text>
                                    <strong>${onlinePaymentHandlingFee?.toFixed(2)} (online)</strong>
                                  </Text>
                                </StyledDivAlignRight>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <StyledLeftDivWidth75>
                                  <Text>
                                    A replacement card fee will be charged to the <strong>account</strong> when you{' '}
                                    request that we provide <strong>you</strong> or an{' '}
                                    <strong>additional cardholder</strong> with a replacement card when any{' '}
                                    <strong>card</strong> is lost, stolen or damaged.
                                  </Text>
                                </StyledLeftDivWidth75>
                                <StyledDivAlignRight>
                                  <Text>
                                    <strong>${replacementCardFee?.toFixed(2)}</strong>
                                  </Text>
                                </StyledDivAlignRight>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <StyledLeftDivWidth75>
                                  <Text>
                                    A balance transfer fee will be charged to the account if you take up a balance{' '}
                                    transfer offer
                                  </Text>
                                </StyledLeftDivWidth75>
                                <StyledDivAlignRight>
                                  <Text>
                                    <strong>Up to {balanceTransferringFee}% of</strong>
                                  </Text>
                                  <Text>
                                    <strong>Balance transfer amount</strong>
                                  </Text>
                                </StyledDivAlignRight>
                              </StyledDivSpaceBetween>
                              <hr />
                              <Container>
                                <Text>
                                  These fees, once charged, form part of the <strong>closing balance</strong> and,{' '}
                                  unless specified otherwise, are payable as part of your{' '}
                                  <strong>monthly payment</strong>. You will not have to make a payment in a statement{' '}
                                  period if your monthly payment would otherwise consist only of a paper statement fee.
                                </Text>
                              </Container>
                              <br />
                              <Container>
                                <Text textStyle="heading5">Changes to the Credit Contract</Text>
                              </Container>
                              <hr />
                              <Container>
                                <Text>
                                  The <strong>credit limit</strong>, the <strong>annual percentage rate(s)</strong>, the{' '}
                                  maximum interest free period, the amount, frequency or time for payment or method of{' '}
                                  calculation of a fee or charge or minimum repayment may be changed, and new fees or{' '}
                                  charges may be imposed, in each case without your consent (except that we cannot{' '}
                                  increase the <strong>credit limit</strong> except at your request or with your consent{' '}
                                  in any form required by law). However, no change will be made to an{' '}
                                  <strong>own-it-now annual percentage rate</strong> or a{' '}
                                  <strong>special annual percentage rate</strong> during the term of any{' '}
                                  <strong>promotional offer</strong> to which they apply. You will be notified of the{' '}
                                  change or of new fees or charges in the manner set out in the Conditions of Use{' '}
                                  accompanying this <strong>financial table</strong>.
                                </Text>
                              </Container>
                              <br />
                              <Container>
                                <Text textStyle="heading5">Commission</Text>
                                <hr />
                                <Text>
                                  We pay commission (the amounts of which are unascertainable) to merchants, their staff{' '}
                                  and their head offices, when further credit is provided to you for subsequent{' '}
                                  transactions through those merchants. Head office means the company that provides{' '}
                                  certain coordination, administrative or other services for a chain of stores. This may{' '}
                                  be a related company, or in the case of a franchise, the franchisor. We also pay{' '}
                                  commission to our staff when they process subsequent{' '}
                                  <strong>cash advances, purchases</strong> or <strong>balance transfers</strong>.
                                </Text>
                              </Container>
                              <Container>
                                <Text textStyle="heading5">Other Information</Text>
                                <hr />
                                <Text>
                                  If you do not wish to receive direct marketing offers, please call 1300 GO CARD (
                                  {config.phoneNumbers.assistance2}).
                                </Text>
                                <Text>
                                  Variation to the Conditions of Use and Credit Guide (versions December 2017 and prior)
                                </Text>
                                <hr />
                                <Text>
                                  <strong>Effective from 27 November 2018 - Interpretations:</strong>
                                </Text>
                                <StyledUL>
                                  <li>
                                    <strong>'due date'</strong> means in respect of a <strong>statement period</strong>{' '}
                                    the 25th day of the next statement period.
                                  </li>
                                  <li>
                                    <strong>'modified closing balance'</strong> is not due to expire on or before the{' '}
                                    <strong>due date</strong> during the <strong>statement period</strong> after the{' '}
                                    statement of account to which the <strong>closing balance</strong> relates.
                                  </li>
                                </StyledUL>
                              </Container>
                              <br />
                              <Container>
                                <Text textStyle="heading5">Offer to New {productName} Account Holders</Text>
                              </Container>
                              <hr />
                              <Text>
                                Latitude Finance Australia ABN 42 008 583 588, Australian Credit Licence number 392145,{' '}
                                as the credit provider, offers to provide you with a {productName}{' '}
                                <strong>account</strong> on the conditions set out in the{' '}
                                <strong>credit contract</strong>. Subject to clause 2 of the Conditions of Use, you{' '}
                                accept this offer for a <strong>credit contract</strong> by using the{' '}
                                <strong>account</strong> to access or drawdown credit to incur a liability on the{' '}
                                <strong>account</strong>.
                              </Text>
                              <br />
                              <Container padding="4 0">
                                <Text>Signed on behalf of Latitude Finance Australia.</Text>
                                <br />
                                <img src={AdrianaMartinezSignature} width="150px" alt="form-title-icon" />
                                <Text>
                                  <strong>Adriana Martinez</strong>
                                </Text>
                                <Text>Executive General Manager - Latitude Pay</Text>
                              </Container>
                            </Stack>
                          </div>
                        </Container>
                      </Grid>
                    </Grid.Item>
                  </Grid>
                </Container>
              </Grid>
            </Grid.Item>
          </Grid>
        </Container>
      </Container>
    </LayoutPage>
  );
}
