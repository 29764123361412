import React, { useMemo } from 'react';
import { useTheme } from '@emotion/react';
import { Container, Button, Text, Link, Grid } from 'basis';
import styled from '@emotion/styled';
import { isAU } from '../../utils';

export const ImageIcons = styled.img`
  height: 60px;
  margin-right: 16px;
  ${props => `
      ${`@media (min-width: ${props.theme.screenSize.lg})`} {
        height: 60px;
  `};
`;
export const ImageIconsAU = styled.img`
  height: 29px;
  width: 32px;
  margin-right: 16px;
`;

export const BannerTitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
`;

export const ShadowedContainer = styled.div`
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
`;

export const InformationCard = ({
  icon,
  iconAlt,
  title,
  linkHref,
  btnText,
  loading,
  onClick,
  testId,
  children,
  variant = 'secondary',
  background = 'primary',
}) => {
  const InnerBox = useMemo(() => {
    return background === 'primary' ? PrimaryInnerBox : SecondaryInnerBox;
  }, [background]);
  const theme = useTheme();

  const InfoCardNZ = () => {
    return (
      <Container padding="3 0">
        <InnerBox>
          <BannerTitleContainer>
            <ImageIcons src={icon} alt={iconAlt} theme={theme} />

            <Text textStyle="heading6">{title}</Text>
          </BannerTitleContainer>

          <Container padding={btnText ? '0 0 8 0' : '0'}>{children}</Container>

          {btnText &&
            (linkHref ? (
              <Link
                onClick={onClick}
                width="100%"
                width-xs="100%"
                width-sm="276px"
                appearance="secondary-button"
                variant={variant}
                href={linkHref}
                testId={testId}
                disabled={loading}
                newTab
              >
                {btnText}
              </Link>
            ) : (
              <Grid>
                <Button
                  testId={testId}
                  loading={loading}
                  onClick={onClick}
                  variant={variant}
                  color="highlight.blue.t100"
                >
                  {btnText}
                </Button>
              </Grid>
            ))}
        </InnerBox>
      </Container>
    );
  };
  const InfoCardAU = () => {
    return (
      <Container padding="0">
        <ShadowedContainer>
          <Container bg="white" padding="6" padding-md="8">
            <BannerTitleContainer>
              <ImageIconsAU src={icon} alt={iconAlt} theme={theme} />

              <Text textStyle="heading5">{title}</Text>
            </BannerTitleContainer>

            <Container padding={btnText ? '0 0 8 0' : '0'}>{children}</Container>

            {btnText &&
              (linkHref ? (
                <Link
                  onClick={onClick}
                  width="100%"
                  width-xs="100%"
                  width-sm="276px"
                  appearance="secondary-button"
                  variant={variant}
                  href={linkHref}
                  testId={testId}
                  disabled={loading}
                  newTab
                >
                  {btnText}
                </Link>
              ) : (
                <Grid>
                  <Button
                    testId={testId}
                    loading={loading}
                    onClick={onClick}
                    variant={variant}
                    color="highlight.blue.t100"
                  >
                    {btnText}
                  </Button>
                </Grid>
              ))}
          </Container>
        </ShadowedContainer>
      </Container>
    );
  };

  return isAU() ? <InfoCardAU /> : <InfoCardNZ />;
};

const PrimaryInnerBox = ({ children }) => (
  <Container bg="secondary.lightBlue.t15" hasBorder-md padding="4" padding-md="8">
    {children}
  </Container>
);

const SecondaryInnerBox = styled.div`
  background-color: white;
  border: 10px solid #d0e9ff;
  padding: 30px 25px;
`;
