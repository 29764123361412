import React from 'react';
import { Modal } from 'components/modal/Modal';
import { Button, Container, Flex, List, Text } from 'basis';
import { config, PRODUCT_CODE_TWENTY_EIGHT_DEGREES } from '_config';
import { byCard, getConditionsOfUseTerm, getFinancialTableTerm } from 'utils';
import { djAssistanceNumber } from '_config/au';
import { ListWrapper } from './LandingPageDJ.styles';

const modalContent = [
  'I apply to Latitude Finance Australia (ABN 42 008 583 588) ("Latitude") for a credit facility to enable me and each additional cardholder named in this application to purchase goods and services on credit and obtain cash advances (when available).',
  `I understand that if Latitude accepts my application, Latitude will supply me an offer to enter into a credit contract, a ${getFinancialTableTerm()} and ${getConditionsOfUseTerm()} for the credit facility.`,
  `I acknowledge that I will be bound by the credit contract as set out in the ${getFinancialTableTerm()} and ${getConditionsOfUseTerm()} when I accept that offer.`,
  `From time to time, cardholders may be sent general information on products and services which may be of interest. If you don’t want to receive this information please call ${
    !config.productCode
      ? djAssistanceNumber
      : byCard({
          [PRODUCT_CODE_TWENTY_EIGHT_DEGREES]: config?.phoneNumbers?.assistance2,
          default: config?.phoneNumbers?.assistance,
        })
  }.`,
  'I acknowledge that the information I’ve provided in this application is correct, and I have not withheld any information which could cause Latitude to incorrectly grant credit to me.',
  'I’m not currently aware of anything that may adversely impact my ability to service the credit card being considered.',
];

export const DeclarationModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Container padding="3" padding-md="6">
        <Container padding="4 2 2">
          <Text textStyle="heading4" textStyle-md="heading4">
            Declaration and Credit Application
          </Text>
        </Container>

        <ListWrapper>
          <List>
            {modalContent.map((item, index) => {
              return (
                <List.Item key={index}>
                  <Container padding="0 3 3 0">
                    <Text testId={`textSubtitle-${index}`}>{item}</Text>
                  </Container>
                </List.Item>
              );
            })}
          </List>
        </ListWrapper>

        <Flex placeItems="center">
          <Container padding="2 2 4 2">
            <Button width="160px" onClick={onClose}>
              Close
            </Button>
          </Container>
        </Flex>
      </Container>
    </Modal>
  );
};
