import austPassport from 'images/AUS-Passport.png';
import medicareGreen from 'images/Medicare-Green.png';
import vicDriverLicence from 'images/VIC-Driver.png';
import { countryOptions, numbersOnlyPattern } from '../_constants';
import { idTypeOptions, minExpiryDate, auStateOptions } from './constants';

const licenceNumberValidationMessages = {
  required: 'Please enter your licence number.',
  fixedLength: 'Must have {{fixedLength}} characters.',
  invalid: 'Invalid Licence Number.',
};

const versionValidationMessages = {
  required: 'Please enter the 3 digit version number.',
  fixedLength: 'Must have {{fixedLength}} characters.',
  invalid: 'Invalid Licence Version.',
};

const idNumberValidationMessages = {
  required: 'Please enter your ID number.',
  minLength: 'Must have at least {{minLength}} characters.',
  maxLength: 'Must have at most {{maxLength}} characters.',
  invalid: 'Invalid ID Number.',
};

const expiryDateValidationMessages = {
  required: 'Please enter your expiry date.',
  invalid: 'Please enter a valid date.',
  completeYear: 'Please enter the complete year YYYY.',
  minDate: 'Please enter a future date.',
  dayError: 'Day must be within 1-31.',
  monthError: 'Month must be within 1-12.',
};

const validationMessages = {
  licenceNumber: licenceNumberValidationMessages,
  version: versionValidationMessages,
  idNumber: idNumberValidationMessages,
  expiryDate: expiryDateValidationMessages,
};

const fields = [
  {
    component: 'Select',
    name: 'idType',
    label: 'Identification Document',
    options: idTypeOptions,
    testId: 'identification',
    validate: {
      messages: {
        required: 'Please choose an I.D. to help us verify you.',
      },
    },
  },
  {
    condition: values => values.idType === 'AU_MEDICARE',
    fields: [
      {
        component: 'InfoBlock',
        name: 'medicareInfoBlock',
        images: [{ url: medicareGreen, alt: 'medicare card' }],
      },
      {
        component: 'Input',
        name: 'medicareCardName',
        label: 'Your name on Medicare card',
        testId: 'medicare-card-name',
        helpText: 'This must be exact.',
      },
      {
        component: 'Input',
        name: 'individualReferenceNumber',
        label: 'Individual reference number',
        testId: 'individual-reference-number',
        helpText: 'This is located beside your name.',
      },
      {
        component: 'Input',
        name: 'medicareCardNumber',
        label: 'Card number',
        testId: 'medicare-card-number',
      },
    ],
  },
  {
    condition: values => values.idType === 'AU_DRIVERS_LICENCE',
    fields: [
      {
        component: 'InfoBlock',
        name: 'driversLicenceInfoBlock',
        images: [{ url: vicDriverLicence, alt: 'drivers licence' }],
      },
      {
        component: 'Input',
        name: 'licenceNumber',
        label: 'Licence number',
        testId: 'licence-number',
      },
      {
        component: 'Select',
        name: 'australianState',
        label: 'State',
        testId: 'australian-state',
        options: auStateOptions,
      },
    ],
  },
  {
    condition: values => values.idType === 'AU_PASSPORT',
    fields: [
      {
        component: 'InfoBlock',
        name: 'passportInfoBlock',
        images: [{ url: austPassport, alt: 'au passport' }],
      },
      {
        component: 'ReplayName',
        name: 'replayName',
        label: 'Name:',
        stateKey: 'personalDetails',
      },
      {
        component: 'Input',
        name: 'auPassportNumber',
        label: 'Passport number (Document No.)',
        testId: 'passport-number',
      },
    ],
  },
  {
    condition: values => values.idType === 'AU_DRIVERS_LICENCE',
    component: 'Input',
    name: 'version',
    label: 'Version',
    testId: 'version',
    validate: {
      props: { fixedLength: 3, validPattern: numbersOnlyPattern },
      messages: validationMessages.version,
    },
  },
  {
    condition: values => values.idType !== '',
    component: 'DatePicker',
    name: 'expiryDate',
    label: 'Expiry date',
    testId: 'expiry-date',
    day: true,
    validate: {
      props: { minDate: minExpiryDate, validPattern: numbersOnlyPattern },
      messages: validationMessages.expiryDate,
      component: 'DatePicker',
    },
  },
  {
    condition: values => values.idType === 'OTHER_PASSPORT' || isAUOrNZFirearm(values.idType),
    component: 'Select',
    name: 'issuingCountry',
    label: 'Issuing country',
    testId: 'issuing-country',
    options: values =>
      isAUOrNZFirearm(values.idType)
        ? countryOptions.filter(c => c.value === 'AU' || c.value === 'NZ')
        : countryOptions,
  },
];

const isAUOrNZFirearm = idType => ['NZ_FIREARM_LICENCE', 'AU_PASSPORT'].includes(idType);

export const extraDetails = fields;
