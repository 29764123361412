import React from 'react';
import { LayoutPage, LayoutContent, ApprovedCreditLimit, BenefitInfo } from 'components';
import { Container, Button, Form, Text } from 'basis';
import { useStoreValue } from 'store';
import { useTheme } from '@emotion/react';
import { formatNumber } from 'utils/formatNumber';

export const DclDownsell = ({ onSubmit, submitting }) => {
  const [storeState] = useStoreValue();
  const theme = useTheme();
  const { creditLimitApproved } = storeState;

  return (
    <LayoutPage>
      <Container margin="0 0 13 0" hasBreakpointWidth>
        <Form
          id="confirmMinimumCreditLimit"
          onSubmit={onSubmit}
          showSaveDraftButton
          disableFormCache
          saveDraftOnSubmit
          saveDraftOnSessionTimeout
        >
          {formData => {
            return (
              <LayoutContent rowsGap="0" noSessionTimeout>
                <Container margin="8 0 0 0">
                  <Text as="h1" textStyle="heading5" textStyle-md="heading4" color={theme?.headingColor}>
                    Your credit card limit
                  </Text>
                  <Container margin="10 0 5 0">
                    <Text>We can offer you a credit card limit* of:</Text>
                  </Container>
                  <ApprovedCreditLimit creditLimitApproved={creditLimitApproved} />
                  <Container margin="8 0" />
                  <BenefitInfo />
                </Container>
                <Container>
                  <Button margin="8 0 0 0" type="submit" loading={submitting}>
                    Accept ${formatNumber(storeState.creditLimitApproved)} credit card limit
                  </Button>
                </Container>
              </LayoutContent>
            );
          }}
        </Form>
      </Container>
    </LayoutPage>
  );
};
