import React from 'react';
import { Text, Container } from 'basis';
import { useTheme } from '@emotion/react';
import { LayoutContent } from 'components/layout-content/LayoutContent';

export function PageTitle({ header, textAlign = 'left', noPadding = false }) {
  const theme = useTheme();
  return (
    <LayoutContent noPadding={noPadding}>
      {header && (
        <Container textAlign={textAlign}>
          <Text as="h1" textStyle="heading5" textStyle-md="heading4" color={theme.headingColor}>
            {header}
          </Text>
        </Container>
      )}
    </LayoutContent>
  );
}

PageTitle.ID = 'PageTitle';
