import React, { useState, useCallback } from 'react';
import { formatNumber, convertRateToMonthlyNumber } from 'utils';

import { Text } from 'basis';
import {
  yesNoOptions,
  numbersOnlyPattern,
  RS_HOME_OWNER,
  RS_MORTGAGE,
  RS_BOARDING,
  RS_RENT,
  RS_LIVING_WITH_RELATIVE,
  RS_OTHER,
} from '../_constants';

const dollarAmountValidationMessages = {
  required: 'You must enter a dollar amount.',
  invalid: 'Please enter a whole dollar amount, without cents.',
};

const maxLength = 6;

export const expensesDetails = [
  {
    component: 'InfoBlock',
    name: 'headerInfoText',
    children: (
      <Text margin="-8 0 0 0">
        This is how much you spend every month. Don’t include things like investment properties, we ask this question
        later.
      </Text>
    ),
  },
  {
    component: 'Frequency',
    name: 'mortgageRentBoard',
    label: 'What is your share of home loan, rent or board?',
    testId: 'mortgage-rent-board',
    mode: 'select',
    amountPrefix: '$',
    quarterly: false,
    amountMaxLength: maxLength,
    validate: {
      props: { maxLength, validPattern: numbersOnlyPattern },
      messages: {
        ...dollarAmountValidationMessages,
        invalid: 'Please provide your share of residential expenses. If you have no mortgage, please enter 0.',
        required: 'Please provide your share of mortgage, rent or board.',
      },
      component: 'Frequency',
    },
  },
  {
    name: 'isExpensesShared',
    label: 'Do you share your expenses?',
    columns: 1,
    color: 'white',
    component: 'RadioGroup',
    options: yesNoOptions,
    showCircles: true,
    testId: 'is-expenses-shared',
  },
  {
    condition: values => values.isExpensesShared === 'yes',
    component: 'InfoBlock',
    name: 'onlyYourShare',
    children: (
      <Text>
        Please only include <b>your share of the expense</b>.
      </Text>
    ),
  },
  {
    component: 'Frequency',
    name: 'foodGroceriesLifestyleEntertainment',
    label: values =>
      values.isExpensesShared === 'yes'
        ? 'Your share of food, groceries, lifestyle & entertainment'
        : 'Food, groceries, lifestyle & entertainment',
    testId: 'food-groceries-lifestyle-entertainment',
    helpText: 'e.g. eating out, supermarket shop, holidays, hobbies & pet etc.',
    mode: 'select',
    amountPrefix: '$',
    quarterly: false,
    amountMaxLength: maxLength,
    validate: {
      props: { maxLength, validPattern: numbersOnlyPattern },
      messages: {
        ...dollarAmountValidationMessages,
        required: 'Please provide your share of food, groceries & entertainment expenses.',
      },
      component: 'Frequency',
    },
  },
  {
    component: 'Frequency',
    name: 'insurance',
    label: values => (values.isExpensesShared === 'yes' ? 'Your share of insurance' : 'Insurance'),
    testId: 'insurance',
    helpText: 'e.g. health, car, home and life insurance etc.',
    mode: 'select',
    amountPrefix: '$',
    quarterly: false,
    amountMaxLength: maxLength,
    validate: {
      props: { maxLength, validPattern: numbersOnlyPattern },
      messages: {
        ...dollarAmountValidationMessages,
        required: 'Please provide your share of insurance expenses.',
      },
      component: 'Frequency',
    },
  },
  {
    component: 'Frequency',
    name: 'utilities',
    label: values => (values.isExpensesShared === 'yes' ? 'Your share of utilities' : 'Utilities'),
    testId: 'utilities',
    helpText:
      'e.g. electricity, gas, water, council rates, body corporate fees, phone, internet, streaming and pay TV etc.',
    mode: 'select',
    amountPrefix: '$',
    quarterly: false,
    amountMaxLength: maxLength,
    validate: {
      props: { maxLength, validPattern: numbersOnlyPattern },
      messages: {
        ...dollarAmountValidationMessages,
        required: 'Please provide your share of utilities expenses.',
      },
      component: 'Frequency',
    },
  },
  {
    component: 'Frequency',
    name: 'educationAndChildcare',
    label: values =>
      values.isExpensesShared === 'yes' ? 'Your share of education & childcare' : 'Education & childcare',
    testId: 'education-and-childcare',
    helpText: 'e.g. tuition fees (primary & secondary), uniform, books and excursions etc.',
    mode: 'select',
    amountPrefix: '$',
    quarterly: false,
    amountMaxLength: maxLength,
    validate: {
      props: { maxLength, validPattern: numbersOnlyPattern },
      messages: {
        ...dollarAmountValidationMessages,
        required: 'Please provide your share of education and childcare expenses.',
      },
      component: 'Frequency',
    },
  },
  {
    component: 'Frequency',
    name: 'transport',
    label: values => (values.isExpensesShared === 'yes' ? 'Your share of transport' : 'Transport'),
    testId: 'transport',
    helpText: 'e.g. public transport, registration, servicing, petrol, parking fees & road tolls',
    mode: 'select',
    amountPrefix: '$',
    quarterly: false,
    amountMaxLength: maxLength,
    validate: {
      props: { maxLength, validPattern: numbersOnlyPattern },
      messages: {
        ...dollarAmountValidationMessages,
        required: 'Please provide your share of transport expenses.',
      },
      component: 'Frequency',
    },
  },
  {
    component: 'Frequency',
    name: 'personalAndOtherExpense',
    label: values =>
      values.isExpensesShared === 'yes' ? 'Your share of personal and other expenses' : 'Personal and other expenses',
    testId: 'personal-and-other-expense',
    helpText: 'e.g. clothing, haircuts, cosmetics, medical, fitness, personal care for you and your dependants etc.',
    mode: 'select',
    amountPrefix: '$',
    quarterly: false,
    amountMaxLength: maxLength,
    validate: {
      props: { maxLength, validPattern: numbersOnlyPattern },
      messages: {
        ...dollarAmountValidationMessages,
        required: 'Please provide your share of personal and other expenses.',
      },
      component: 'Frequency',
    },
  },
];

export const useWarning = () => {
  const [warnings, setWarning] = useState([]);

  const check = useCallback((values, storeState) => {
    const { residentialStatus } = storeState.acquisition.otherDetails;

    const mortgageRentBoardMonthly = convertRateToMonthlyNumber(values.mortgageRentBoard);
    const mortgageRentBoardMonthlyDisplay = formatNumber(mortgageRentBoardMonthly);

    const newWarnings = [];
    if (mortgageRentBoardMonthly === 0 && residentialStatus === RS_RENT) {
      newWarnings.push(
        `Please review and confirm your share of the monthly rent is $${mortgageRentBoardMonthlyDisplay}.`,
      );
    }

    if (mortgageRentBoardMonthly > 15000) {
      switch (residentialStatus) {
        case RS_MORTGAGE:
          newWarnings.push(
            `Please review and confirm your share of the monthly mortgage repayments is $${mortgageRentBoardMonthlyDisplay}.`,
          );
          break;
        case RS_HOME_OWNER:
        case RS_OTHER:
        case RS_LIVING_WITH_RELATIVE:
          newWarnings.push(
            `Please review and confirm your share of the monthly housing expenses is $${mortgageRentBoardMonthlyDisplay}.`,
          );
          break;
        case RS_BOARDING:
          newWarnings.push(
            `Please review and confirm your share of the monthly board is $${mortgageRentBoardMonthlyDisplay}.`,
          );
          break;
        case RS_RENT:
          newWarnings.push(
            `Please review and confirm your share of the monthly rent is $${mortgageRentBoardMonthlyDisplay}.`,
          );
          break;
        default:
          throw new Error(`Unexpected residential status: ${residentialStatus}`);
      }
    }

    setWarning(newWarnings);
    return newWarnings.length === 0;
  }, []);

  return { check, warnings };
};
