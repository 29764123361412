import React from 'react';
import { Text, Container, Button } from 'basis';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import ErrorDogImage from 'images/errordog.svg';
import { PhoneNumberLink } from '../phone-number-link/PhoneNumberLink';

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  width: 100%;
`;

const ContentWrapper = styled.div`
  max-width: 450px;
  margin: ${props => (props.margin ? props.margin : '16px')};
`;

function NZErrors({ applicationRef, retry, retrying }) {
  const theme = useTheme();

  return (
    <Container padding="12 0" testId="error" hasBreakpointWidth>
      <ErrorWrapper>
        <Container padding="6 0 0 0">
          <img src={ErrorDogImage} alt={ErrorDogImage} />
        </Container>

        <ContentWrapper>
          <Container padding="6 0 0 0">
            <Text as="h4" textStyle="heading4" color={theme.headingColor} align="left">
              Oops, Something went wrong
            </Text>
          </Container>

          <Container padding="2 0">
            <Text as="p">Looks like the server is taking too long to respond.</Text>
          </Container>

          {applicationRef && (
            <Container padding="6 0">
              <Text as="p">
                You can retry loading the page, or if the issue continues you can call us and quote &nbsp;
                <b>{applicationRef}</b>.
              </Text>
            </Container>
          )}

          {retry && (
            <Container padding="2 0 0 0">
              <Button testId="retry" loading={retrying} onClick={retry} width="100%">
                Retry
              </Button>
            </Container>
          )}

          <Container padding="12 0 0 0">
            <Text as="h5" textStyle="heading5" color={theme.headingColor} align="left">
              Need help? Call us
            </Text>
          </Container>

          <Container padding="2 0 0 0">
            <PhoneNumberLink
              id="originationsTeam"
              appearance="secondary-button"
              variant="blue-button"
              width="100%"
              prefix="Call"
            />
          </Container>

          <Container padding="6 0 6 0">
            <Text>Monday – Wednesday: 8.30am – 6pm</Text>
            <Text>Thursday: 8.30am – 9pm</Text>
            <Text>Friday: 8.30am – 7pm</Text>
            <Text>Saturday & Sunday: 9am – 6pm</Text>
          </Container>
        </ContentWrapper>
      </ErrorWrapper>
    </Container>
  );
}

export { NZErrors };
