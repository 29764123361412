import { useCallback, useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { APPLICATION_NOT_FOUND, CREATED } from 'store';
import { ALL_APPLICATION_FIELDS } from 'gql';
import { STEP_RESUME_APPLICATION_EXPIRED } from '_config';
import { useHistory } from 'react-router-dom';
import { useErrorTrackingAndUpdateStore } from './useErrorTrackingAndUpdateStore';
import { calculateNextStep } from './useWaitAndRedirect/calculateNextStep';
import { updateStoreAndRedirect } from './useWaitAndRedirect/updateStoreAndRedirect';

export const GET_APPLICATION_DRAFT_GQL = gql`
  query getApplicationDraft($id: ID!) {
    applicationDraft(id: $id)
  }
`;

export const GET_APPLICATION_GQL = gql`
  ${ALL_APPLICATION_FIELDS}

  query getApplicationForResume($id: ID!) {
    application(id: $id) {
      ...AllFields
    }
  }
`;

export function useResume({ applicationId, updateStore }) {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const [fetchApplication] = useLazyQuery(GET_APPLICATION_GQL, {
    fetchPolicy: 'network-only',
  });

  const [fetchDraft] = useLazyQuery(GET_APPLICATION_DRAFT_GQL, {
    fetchPolicy: 'network-only',
  });

  const { handleErrorCallback: handleError } = useErrorTrackingAndUpdateStore(`hook:useResume`);

  const resumeApplication = useCallback(
    async storeState => {
      const { data: { application } = {} } = await fetchApplication({ variables: { id: applicationId } });

      if (!application || [APPLICATION_NOT_FOUND, CREATED].includes(application?.status)) {
        return { resumed: false };
      }

      const nextStep = calculateNextStep(application, storeState);
      updateStore({ applicationRetrieveflag: true });
      updateStoreAndRedirect({
        application,
        nextStep,
        updateStore,
        history,
        storeState,
      });
      return { resumed: true };
    },
    [applicationId, fetchApplication, history, updateStore],
  );

  const resumeDraft = useCallback(async () => {
    const { data: { applicationDraft } = {} } = await fetchDraft({ variables: { id: applicationId } });

    if (!applicationDraft) {
      return { resumed: false };
    }
    const draftState = JSON.parse(applicationDraft);
    updateStore({
      ...draftState,
      applicationRetrieveflag: true,
      applicationErrors: null,
    });
    history.push(draftState.activeStep);
    return { resumed: true };
  }, [applicationId, fetchDraft, history, updateStore]);

  const resume = useCallback(
    async ({ storeState }) => {
      if (loading) {
        return;
      }

      setLoading(true);

      try {
        const { resumed: applicationResumed } = await resumeApplication(storeState);
        if (applicationResumed) {
          setLoading(false);
          return;
        }

        const { resumed: draftResumed } = await resumeDraft();
        if (draftResumed) {
          setLoading(false);
          return;
        }

        const nextStep = STEP_RESUME_APPLICATION_EXPIRED;
        updateStore({
          activeStep: nextStep,
        });
        history.push(nextStep);
      } catch (error) {
        setLoading(false);
        handleError(error);
      }
    },
    [handleError, history, loading, resumeApplication, resumeDraft, updateStore],
  );

  return {
    resume,
    loading,
  };
}
