import React from 'react';
import { displayCreditLimit } from 'utils';
import { useTheme } from '@emotion/react';
import { config } from '_config';
import { ReturnButton } from 'components';
import {
  PROOF_OF_INCOME_LIST,
  PROOF_OF_ADDRESS_LIST,
  UPLOAD_BTN_LABEL,
  PROOF_OF_INCOME_LIST_ACCORDION,
} from '_config/_constants/documentUpload';
import { Accordion, Container, Grid, Text } from 'basis';
import { AplyIdSteps } from '../components/AplyIdSteps';
import { List, CustomWrapper } from '../Outcome.styles';
import { UploadDocumentButton } from '../components/UploadDocumentButton';

export const DocUpload = ({ storeState, POIAML, POIPOA, POI }) => {
  const theme = useTheme();
  const { applicationRef } = storeState;
  const creditLimit = displayCreditLimit(storeState);

  const handleVerifyNowClick = () => {
    // eslint-disable-next-line
    // TODO: Analytics here?
  };

  return (
    <Grid preset="page" colsGap={12}>
      <Grid.Item colSpan="all" colSpan-sm="1-6" colSpan-md="1-6" colSpan-lg="2-9" colSpan-xl="2-9">
        <Grid rowsGap={8}>
          <Text as="h1" textStyle="heading5" textStyle-md="heading4" color={theme.headingColor}>
            Based on the information you’ve given us we can conditionally approve you for ${creditLimit}
          </Text>

          <Text>
            Your application number is <b>{applicationRef}</b>
          </Text>

          <Text>
            {(POIAML || POIPOA) && (
              <b>All you need to do to complete your application is prove your income and confirm your ID.</b>
            )}
            {POI && <b>All you need to do to complete your application is prove your income.</b>}
            <br />
            Once you’ve uploaded your documents, your application is complete and our team will review and be in touch
            within 2 days.
          </Text>

          {POIAML && (
            <>
              <Text as="h1" textStyle="heading5" color={theme.headingColor}>
                Step 1: Confirm your ID
              </Text>
              <AplyIdSteps
                type="DOC_POIAML"
                linkLabel="Verify now"
                linkHref={config.aplyIdUrl}
                linkOnClick={handleVerifyNowClick}
              />
            </>
          )}

          {(POIPOA || POIAML || POI) && (
            <>
              <Text as="h1" textStyle="heading5" color={theme.headingColor}>
                {POI ? '' : POIPOA ? 'Step 1: ' : 'Step 2: '}Upload proof of income
              </Text>

              <Text>
                The most common way to do this is upload either bank statements, IRD summary of earnings or financial
                statements.
              </Text>

              <Text>
                <b>See the full list of documents we accept relating to your type of income, below:</b>
              </Text>

              <Accordion>
                {PROOF_OF_INCOME_LIST_ACCORDION.map(accordion => {
                  return (
                    <Accordion.Item>
                      <CustomWrapper>
                        <Accordion.Item.Header>{accordion.accordionTitle}</Accordion.Item.Header>
                      </CustomWrapper>
                      <Accordion.Item.Content padding="6">
                        {accordion.accordionData.map((data, key) => {
                          if (data.description) {
                            return (
                              <Container key={key} padding="4 0">
                                <Text key={key}>{data.description}</Text>
                              </Container>
                            );
                          }
                          return (
                            <Container key={key} padding="4 0 0 0">
                              <Text>
                                <b>{data.listTitle}</b>
                              </Text>
                              {data.listItem && (
                                <List marginTop="10" noPadding>
                                  {data.listItem.map((item, itemKey) => (
                                    <li key={itemKey}>{item}</li>
                                  ))}
                                </List>
                              )}
                              {key !== PROOF_OF_INCOME_LIST.length - 1 && <br />}
                            </Container>
                          );
                        })}
                      </Accordion.Item.Content>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            </>
          )}

          {POIPOA && (
            <>
              <Text as="h1" textStyle="heading5" color={theme.headingColor}>
                Step 2: Upload proof of address
              </Text>

              <Text>
                If the document you choose to prove your income doesn’t clearly show your current address, we’ll also
                need you to upload an additional document that does. See the full list of documents we accept below.
              </Text>

              <Accordion>
                <Accordion.Item>
                  <CustomWrapper>
                    <Accordion.Item.Header>What documents do we accept?</Accordion.Item.Header>
                  </CustomWrapper>
                  <Accordion.Item.Content padding="6">
                    {PROOF_OF_ADDRESS_LIST.map((data, key) => {
                      return (
                        <div key={key}>
                          <Text>
                            <b>{data.listTitle}</b>
                          </Text>
                          {data.listItem && (
                            <List marginTop="10" noPadding>
                              {data.listItem.map((item, itemKey) => (
                                <li key={itemKey}>{item}</li>
                              ))}
                            </List>
                          )}
                          {key !== PROOF_OF_ADDRESS_LIST.length - 1 && <br />}
                        </div>
                      );
                    })}
                  </Accordion.Item.Content>
                </Accordion.Item>
              </Accordion>
            </>
          )}

          {!POI && (
            <Text>
              <b>You can upload all your documents through the same portal. </b>
            </Text>
          )}
          <UploadDocumentButton label={UPLOAD_BTN_LABEL} storeState={storeState} />
        </Grid>

        <Text margin="2 0" textStyle="legal">
          Files can be PDFs, photographs or scanned formats.{' '}
        </Text>

        <Container padding-md="2 0 10">
          <ReturnButton variant="text" />
        </Container>
      </Grid.Item>
    </Grid>
  );
};
