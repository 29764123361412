import { numbersOnlyPattern } from '../_constants';
import { minCreditLimit, maxCreditLimit, creditLimitIncrement } from './constants';

const creditLimitValidationMessages = {
  required: 'Please tell us your preferred credit limit in dollars.',
  minValue: `Please enter an credit limit amount of \${{minValue}} or more in \${{incrementValue}} increments.`,
  maxValue: `The maximum amount you can enter is \${{maxValue}}.`,
  minLength: 'Must have at least {{minLength}} digits.',
  maxLength: 'Must have at most {{maxLength}} digits.',
  incrementValue: `Please enter an amount in \${{incrementValue}} increments.`,
  invalid: 'Please enter a whole dollar amount, without cents.',
};

export const preferredCreditLimitDetails = [
  {
    component: 'InfoBlock',
    name: 'preferredCreditLimitInfo',
    text: `You’ll be able to confirm your credit limit later, based on the information you provide in the application.`,
  },
  {
    label: 'Enter your preferred credit limit',
    helpText: `Choose between a minimum of $${new Intl.NumberFormat().format(
      minCreditLimit,
    )} and maximum of $${new Intl.NumberFormat().format(maxCreditLimit)}`,
    component: 'Input',
    variant: 'commaNumeric',
    name: 'creditLimit',
    testId: 'credit-limit',
    prefix: '$',
    validate: {
      props: {
        minValue: minCreditLimit,
        maxValue: maxCreditLimit,
        minLength: 3,
        maxLength: 6,
        incrementValue: creditLimitIncrement,
        validPattern: numbersOnlyPattern,
      },
      messages: creditLimitValidationMessages,
    },
  },
];
