import React from 'react';
import { Text, Button, Container, Flex, List } from 'basis';
import { Modal } from 'components/modal/Modal';

const modalStyle = {
  width: 600,
};

const CrbModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} style={modalStyle}>
      <Container padding="3" padding-md="4">
        <Container padding="3" padding-md="4">
          <Text textStyle="heading4" margin="0 0 6 0" margin-md="0 0 7 0">
            Credit reporting bodies
          </Text>

          <Container padding="6 0 1 0">
            <Text textStyle="body2">
              Latitude is required by the Anti-Money Laundering & Counter-Terrorism Financing Act 2006 to verify your
              identity before providing you with a product or service.
            </Text>
            <Text textStyle="body2">
              By acknowledging this statement, you agree you have read this information and allow Latitude to provide
              personal information about you to credit reporting bodies to enable verification of your identity.
            </Text>
            <Text textStyle="body2">
              Personal information that Latitude may provide to the credit reporting body includes:
            </Text>
          </Container>

          <Container padding="3 1">
            <List textStyle="body2">
              <List.Item>Full Name</List.Item>
              <List.Item>Address</List.Item>
              <List.Item>Date of Birth</List.Item>
            </List>
          </Container>

          <Container padding="4 0 1 0">
            <Text textStyle="body2">
              You understand that in addition to the circumstances described in the privacy consent, your personal
              information will be used by the credit reporting body and by Latitude to assess whether the information
              provided by Latitude matches the information held about you by the credit reporting body for the purpose
              of verifying your identity. You also understand that use of your personal information in this way{' '}
              <strong>does not constitute a credit check or impact your credit standing</strong> in any way.
            </Text>
          </Container>

          <Container padding="6 0 4 0">
            <Text textStyle="body2">
              The credit reporting body may inform Latitude as to whether the personal information provided is a full or
              partial match with information held by it. Latitude cannot rectify any discrepancies but will advise you
              by letter or email and provide contact details of the credit reporting body where you can confirm your
              details.
            </Text>
          </Container>

          <Flex placeItems="center">
            <Container padding="2">
              <Button width="160px" onClick={onClose}>
                Close
              </Button>
            </Container>
          </Flex>
        </Container>
      </Container>
    </Modal>
  );
};

export { CrbModal };
