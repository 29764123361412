import { useCallback } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import {
  UNKNOWN_API_ERROR,
  APOLLO_NETWORK_ERROR,
  APOLLO_GRAPHQL_ERROR,
  APOLLO_UNKNOWN_ERROR,
  AXIOS_NETWORK_ERROR,
  AXIOS_NO_RESPONSE_ERROR,
  AXIOS_UNKNOWN_ERROR,
  OKTA_ERROR,
  DATADOG_CUSTOM_SOURCE,
  ERROR_KIND_SYSTEM_ERROR,
} from 'store';
import { ApolloError } from '@apollo/client';

function useHandleApiErrors(type, onError) {
  const callback = useCallback(
    (error, doOnError) => {
      if (!['prod', 'test'].includes(process.env.REACT_APP_ENV)) {
        // eslint-disable-next-line
        console.warn(error);
      }

      const sourceError = error?.error || error;
      if (!(sourceError instanceof ApolloError)) {
        // Apollo errors are already logged to datadogRum in Apollo link middleware with correlationId and extra info.
        // As Apollo does not have a way to extend the error object with extra info, so we need to handle it in middleware instead.
        const errorType = error?.type || error.error?.type;
        sourceError.type = errorType;
        const context = {
          kind: error.kind || ERROR_KIND_SYSTEM_ERROR,
          correlationId: sourceError?.response?.headers?.['x-correlation-id'], // from Axios
          httpStatusCode: sourceError?.response?.status, // from Axios
        };

        datadogRum.addError(sourceError, context, DATADOG_CUSTOM_SOURCE);
      }

      doOnError && onError && onError(error);
    },
    [onError],
  );

  const handleError = useCallback(
    (error, doOnError = true) => {
      let err = { type: UNKNOWN_API_ERROR, error };

      if (error) {
        if (error.type) {
          err = error;
        } else {
          if (type === 'apollo') {
            if (error.graphQLErrors?.length) {
              err = { type: APOLLO_GRAPHQL_ERROR, error };
            } else if (Object.keys(error.networkError || {}).length) {
              err = { type: APOLLO_NETWORK_ERROR, error };
            } else {
              err = { type: APOLLO_UNKNOWN_ERROR, error };
            }
          }

          if (type === 'axios') {
            if (error.response) {
              // The request was made and the server responded with a status code that falls out of the range of 2xx
              err = { type: AXIOS_NETWORK_ERROR, error };
            } else if (error.request) {
              // The request was made but no response was received
              err = { type: AXIOS_NO_RESPONSE_ERROR, error };
            } else {
              // Something happened in setting up the request that triggered an Error
              err = { type: AXIOS_UNKNOWN_ERROR, error };
            }
          }

          if (type === 'okta') {
            err = { type: OKTA_ERROR, error };
          }
        }
      }

      callback(err, doOnError);
    },
    [type, callback],
  );

  return {
    handleError,
  };
}

export { useHandleApiErrors };
