import { useEffect, useState } from 'react';

/**
 * 🐨 This is used to add the 3rd party on demand, rather than
 * being added in the head tag. The id is an optional field which is
 * needed for Zendesk script.
 * source: https://usehooks.com/useScript/
 * @param id is optional. It is needed for zendesk script.
 * @param src of the 3rd party script to the page
 * @returns {string} status of the added 3rd party script.
 */
export const useAsyncScript = (src, id = '') => {
  // Keep track of script status ("idle", "loading", "ready", "error")
  const [status, setStatus] = useState(src ? 'loading' : 'idle');

  useEffect(() => {
    // Allow falsy src value if waiting on other data needed for
    // constructing the script URL passed to this hook.
    if (!src) {
      setStatus('idle');
      return () => {};
    }

    // Fetch existing script element by src
    // It may have been added by another instance of this hook
    let script = document.querySelector(`script[src="${src}"]`);

    if (!script) {
      // Create script
      script = document.createElement('script');
      script.src = src;
      script.async = true;
      script.setAttribute('data-status', 'loading');

      if (id) {
        script.id = id;
      }
      // Add script to document body
      document.body.appendChild(script);

      // Store status in attribute on script
      // This can be read by other instances of this hook
      const setAttributeFromEvent = event => {
        if (!script) {
          return;
        }
        script.setAttribute('data-status', event.type === 'load' ? 'ready' : 'error');
      };

      script.addEventListener('load', setAttributeFromEvent);
      script.addEventListener('error', setAttributeFromEvent);
    } else {
      // Grab existing script status from attribute and set to state.
      const existingStatus = script.getAttribute('data-status');
      setStatus(existingStatus ?? 'error');
    }

    // Script event handler to update status in state
    // Note: Even if the script already exists we still need to add
    // event handlers to update the state for *this* hook instance.
    const setStateFromEvent = event => {
      setStatus(event.type === 'load' ? 'ready' : 'error');
    };

    // Add event listeners
    script.addEventListener('load', setStateFromEvent);
    script.addEventListener('error', setStateFromEvent);

    // Remove event listeners on cleanup
    return () => {
      if (script) {
        script.removeEventListener('load', setStateFromEvent);
        script.removeEventListener('error', setStateFromEvent);
      }
    };
  }, [id, src]);

  return status;
};
