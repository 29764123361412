export const AU_DRIVERS_LICENCE = 'AU_DRIVERS_LICENCE';
export const AU_MEDICARE = 'AU_MEDICARE';
export const AU_PASSPORT = 'AU_PASSPORT';

export const idTypeOptions = [
  {
    value: AU_DRIVERS_LICENCE,
    label: 'Drivers Licence',
  },
  {
    value: AU_MEDICARE,
    label: 'Medicare',
  },
  {
    value: AU_PASSPORT,
    label: 'Australian Passport',
  },
];
