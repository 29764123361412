import React from 'react';
import { UPLOAD_PAYSLIPS, UPLOAD_BTN_LABEL } from '_config/_constants/documentUpload';
import { ReturnButton } from 'components';
import { Container, Text, Grid, Message } from 'basis';
import { useTheme } from '@emotion/react';
import { List } from '../Outcome.styles';
import { UploadDocumentButton } from '../components/UploadDocumentButton';

export const UploadPayslips = ({ storeState }) => {
  const theme = useTheme();
  return (
    <Container padding="8 0 13 0" padding-md="12 0 13 0" margin="0 8">
      <Container hasBreakpointWidth>
        <Grid preset="page" colsGap={12}>
          <Grid.Item colSpan="all" colSpan-sm="1-6" colSpan-md="1-6" colSpan-lg="2-9" colSpan-xl="2-9">
            <Grid rowsGap={8}>
              <Text as="h1" textStyle="heading5" textStyle-md="heading4" color={theme.headingColor}>
                You're nearly there!
              </Text>
              <Text>
                Your application number is <b>{storeState.applicationRef}</b>
              </Text>
              <Text as="h1" textStyle="heading5" color={theme.headingColor}>
                Upload payslips
              </Text>
              <Message
                severity="info-or-minor"
                bg="secondary.lightBlue.t25"
                title="All you need to do to complete your application is upload your payslips."
              >
                Once you’ve uploaded your documents, our team will review your application and be in touch within 2
                days.
              </Message>

              {UPLOAD_PAYSLIPS.map((data, key) => {
                return (
                  <div key={key}>
                    <Text>We’ll need you to upload 2 consecutive payslips that clearly show:</Text>
                    <List marginTop="10" noPadding>
                      {data.listItem.map((item, itemKey) => {
                        return <li key={itemKey}>{item}</li>;
                      })}
                    </List>
                  </div>
                );
              })}
              <UploadDocumentButton label={UPLOAD_BTN_LABEL} storeState={storeState} />
            </Grid>
            <Text margin="2 0" textStyle="legal">
              Files can be PDFs, photographs or scanned formats.{' '}
            </Text>

            <Container padding-md="2 0 10">
              <ReturnButton variant="text" />
            </Container>
          </Grid.Item>
        </Grid>
      </Container>
    </Container>
  );
};
