import { IdvConsents } from 'components/idv-consent';
import { YellowMessage } from 'components/message/YellowMessage';
import { Message } from 'basis';
import React from 'react';
import {
  numbersOnlyPattern,
  relationshipStatusOptions,
  titleOptionsAU,
  validNamePattern,
  yesNoOptions,
} from '../_constants';
import {
  minAgeMaxDate,
  minAge,
  maxAgeDate,
  maxAge,
  auStateOptions,
  validAUDriverLicenceNumberPattern,
  validAUDriverLicenceCardNumberPattern,
} from './constants';

export const nameValidationMessages = {
  required: `Please provide us your legal {{typeName}} as shown on your ID.`,
  minLength: 'Your {{typeName}} must be between {{minLength}}-{{maxLength}} characters long.',
  maxLength: 'Your {{typeName}} must be between {{minLength}}-{{maxLength}} characters long.',
  invalid: "Only one special character is accepted, ( -' ) or space as shown on your ID.",
};

const dateOfBirthValidationMessages = {
  required: 'Please enter your date of birth.',
  invalid: 'Please enter a valid date of birth (DD MM YYYY).',
  completeYear: 'Please enter the complete year YYYY.',
  maxDate: `You must be ${minAge} years or older to apply.`,
  minDate: `The maximum age is ${maxAge} years old. Please update your entry.`,
  dayError: 'Day must be within 1-31.',
  monthError: 'Month must be within 1-12.',
};
export const numberOfDependentsValidationMessages = {
  required: 'Please enter the number of dependant you financially support.',
  minValue: 'Your number of dependants must be {{minValue}} characters or more.',
  maxValue: 'Your number of dependants must be {{maxValue}} characters or less',
  invalid: 'Your number of dependants must be numeric characters only.',
};

const licenceNumberValidationMessages = {
  required: 'Please provide us your Australian Driver Licence number.',
  invalid: 'Please enter a valid Driver Licence number, length must be {{length}} {{charType}} characters',
};

const licenceCardNumberValidationMessages = {
  required: 'Please provide us your licence card number.',
  invalid: 'Please enter a valid card number, length must be {{length}} {{charType}} characters',
};

const validationMessages = {
  firstName: nameValidationMessages,
  middleName: nameValidationMessages,
  lastName: nameValidationMessages,
  numberOfDependents: numberOfDependentsValidationMessages,
  dateOfBirth: dateOfBirthValidationMessages,
  driverLicenceNumber: licenceNumberValidationMessages,
};

const fields = [
  {
    component: 'Select',
    name: 'title',
    label: 'Title',
    options: titleOptionsAU,
    testId: 'title',
    validate: {
      messages: {
        required: 'Please provide us your title.',
      },
    },
  },
  {
    component: 'Input',
    label: 'First name',
    name: 'firstName',
    testId: 'first-name',
    helpText: 'Legal name as shown on ID',
    validate: {
      props: { minLength: 2, maxLength: 15, typeName: 'first name', validPattern: validNamePattern },
      messages: validationMessages.firstName,
    },
  },
  {
    component: 'RadioGroup',
    name: 'hasMiddleName',
    label: 'Do you have any middle name(s)?',
    options: yesNoOptions,
    showCircles: true,
    columns: 1,
    color: 'white',
    testId: 'has-middle-name',
  },
  {
    condition: values => values.hasMiddleName === 'yes',
    component: 'Input',
    name: 'middleName',
    label: 'Middle name(s)',
    testId: 'middle-name',
    helpText: 'Legal middle name as shown on your ID',
    validate: {
      props: { minLength: 1, maxLength: 15, typeName: 'middle name', validPattern: validNamePattern },
      messages: validationMessages.middleName,
    },
  },
  {
    component: 'Input',
    name: 'lastName',
    label: 'Last name',
    testId: 'last-name',
    helpText: 'Legal name as shown on ID',
    validate: {
      props: { minLength: 2, maxLength: 23, typeName: 'last name', validPattern: validNamePattern },
      messages: validationMessages.lastName,
    },
  },
  {
    component: 'DatePicker',
    name: 'dateOfBirth',
    label: 'Date of birth',
    testId: 'date-of-birth',
    validate: {
      props: {
        maxDate: minAgeMaxDate,
        minDate: maxAgeDate.toISOString().split('T')[0],
        validPattern: '[1-9]+',
      },
      messages: validationMessages.dateOfBirth,
      component: 'DatePicker',
    },
  },
  {
    component: 'RadioGroup',
    name: 'hasAuDL',
    label: 'Do you have an Australian Driver Licence?',
    options: yesNoOptions,
    showCircles: true,
    columns: 1,
    color: 'white',
    testId: 'has-au-dl',
  },
  {
    condition: values => values.hasAuDL === 'yes',
    component: 'Select',
    name: 'australianState',
    label: 'Which state or territory issued your driver licence?',
    testId: 'australian-state',
    options: auStateOptions,
  },
  {
    condition: values => values.hasAuDL === 'yes',
    component: 'ValidateInput',
    name: 'driverLicenceNumber',
    label: 'Licence number',
    testId: 'licence-number',
    formName: 'personalDetails',
    keyName: 'australianState',
    validates: {
      validationRules: validAUDriverLicenceNumberPattern,
      messages: licenceNumberValidationMessages,
    },
  },
  {
    condition: values => values.hasAuDL === 'yes',
    component: 'DLNumber',
    name: 'driverLicenceCardNumber',
    label: 'Licence card number',
    testId: 'licence-card-number',
    formName: 'personalDetails',
    keyName: 'australianState',
    autoComplete: 'cc-csc',
    validates: {
      validationRules: validAUDriverLicenceCardNumberPattern,
      messages: licenceCardNumberValidationMessages,
    },
  },
  {
    component: 'Select',
    name: 'relationshipStatus',
    label: 'Your relationship status',
    options: relationshipStatusOptions,
    testId: 'relationship-status',
    breakpoint: size => {
      const columns = ['md', 'lg', 'xl'].includes(size) ? 2 : 1;
      return { columns };
    },
    validate: {
      messages: {
        required: 'Please provide us your relationship status.',
      },
    },
  },
  {
    component: 'Input',
    variant: 'numeric',
    name: 'numberOfDependents',
    label: 'Number of financial dependants',
    helpText: 'Not including your partner, how many people do you financially support?',
    testId: 'number-of-dependents',
    validate: {
      props: {
        minValue: 0,
        maxValue: 99,
        validPattern: numbersOnlyPattern,
      },
      messages: validationMessages.numberOfDependents,
    },
  },
  {
    component: 'CustomComponent',
    name: 'idvConsents',
    children: IdvConsents,
  },
  {
    component: 'CustomComponent',
    name: 'consentMessage',
    testId: 'consent-message',
    margin: '4 0',
    children: () => (
      <YellowMessage>
        <Message testId="yellow-message" severity="warning-or-significant">
          If you do not consent to the above, we will attempt to verify you using alternative electronic verification
          means.
        </Message>
      </YellowMessage>
    ),
  },
];

export const personalDetails = fields;
