import React, { useEffect, useState } from 'react';
import { Input, Button, Icon, VisuallyHidden, Grid } from 'basis';
import styled from '@emotion/styled';
import { byCountry, isAU } from 'utils';
import { SecureIcon, Text } from 'components';
import {
  validPasswordSymbolsPattern,
  validPasswordLowerLettersPattern,
  validPasswordUpperLettersPattern,
  validPasswordNumbersPattern,
  validPasswordMinLength,
} from './validations';
import { useFocusOnFirstFormElement } from '../../hooks/useFocusOnFirstFormElement';

const validPasswordSymbolsRegex = new RegExp(validPasswordSymbolsPattern);
const validPasswordLowerLettersRegex = new RegExp(validPasswordLowerLettersPattern);
const validPasswordUpperLettersRegex = new RegExp(validPasswordUpperLettersPattern);
const validPasswordNumbersRegex = new RegExp(validPasswordNumbersPattern);

function Password({ error, name, label, testId, validate, maxLength, helpText, formData }) {
  const [state, setState] = useState({
    hasValidLength: false,
    hasValidSymbols: false,
    hasValidLetters: false,
    hasValidNumbers: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);

  const isValidInput = state.hasValidLength && state.hasValidSymbols && state.hasValidLetters && state.hasValidNumbers;

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  useFocusOnFirstFormElement();
  useEffect(() => {
    const value = formData.state.values.password;

    setState({
      hasValidLength: value.length >= validPasswordMinLength,
      hasValidSymbols: value.length && validPasswordSymbolsRegex.test(value),
      hasValidLetters:
        value.length && validPasswordLowerLettersRegex.test(value) && validPasswordUpperLettersRegex.test(value),
      hasValidNumbers: value.length && validPasswordNumbersRegex.test(value),
    });

    const hasFormErrors = !!Object.keys(formData.state.errors).length;
    setHasErrors(hasFormErrors);
  }, [formData.state]);

  return (
    <>
      <Container>
        <Input
          name={name}
          label={label}
          type={showPassword ? 'text' : 'password'}
          autocomplete="new-password"
          maxLength={maxLength}
          validate={validate}
          testId={testId}
          helpText={helpText}
        />
        <Button variant="icon" onClick={toggleShowPassword}>
          {showPassword ? (
            <>
              <Icon name="eye-visible" color="primary.blue.t100" />
              <VisuallyHidden>hide password</VisuallyHidden>
            </>
          ) : (
            <>
              <Icon name="eye-hidden" color="primary.blue.t100" />
              <VisuallyHidden>show password</VisuallyHidden>
            </>
          )}
        </Button>
        <ValidationContainer>
          {[
            {
              label: `${validPasswordMinLength} characters`,
              isValid: state.hasValidLength,
            },
            { label: '1 upper + lower case', isValid: state.hasValidLetters },
            { label: '1 number', isValid: state.hasValidNumbers },
            { label: '1 symbol', isValid: state.hasValidSymbols },
          ].map(rule => (
            <ValidationRule key={rule.label} isValid={rule.isValid} formHasErrors={hasErrors}>
              <ValidationCheck isValid={rule.isValid} formHasErrors={hasErrors}>
                {rule.isValid ? (
                  <Icon name="tick" color="conditional.positive.graphics" />
                ) : hasErrors ? (
                  <Icon name="cross" color="conditional.negative.graphics" />
                ) : null}
              </ValidationCheck>
              <Text textStyle="body2">
                <strong>{rule.label}</strong>
              </Text>
            </ValidationRule>
          ))}
        </ValidationContainer>
      </Container>

      <Grid
        colsGap="10"
        cols={byCountry({
          AU: '2',
          NZ: '1',
        })}
      >
        <Grid.Item colSpan="all" colSpan-sm="0" alignItems="center">
          <Grid>
            <Button testId="nextBtn" type="submit" disabled={!isValidInput}>
              {isAU() && (
                <IconAndText>
                  <SecureIcon color={isValidInput ? 'white' : '#414141'} />
                  Set up now
                </IconAndText>
              )}
              {!isAU() && 'Next'}
            </Button>
          </Grid>
        </Grid.Item>
      </Grid>
    </>
  );
}

const IconAndText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    margin-right: 8px;
    margin-top: -2px;
  }
`;

const Container = styled.div`
  position: relative;
  & > button {
    position: absolute !important;
    top: 31px;
    right: 0px;
  }
`;

const ValidationContainer = styled.div`
  padding-top: 8px;
  display: grid;
  grid-template-columns: 50% 50%;
  column-gap: 16px;
  row-gap: 8px;
`;

const ValidationRule = styled.div`
  display: flex;
  align-items: center;
  & p {
    color: ${p => (p.isValid ? '#1b633c' : p.formHasErrors ? '#CF000FFF' : '#000000')};
  }
  & strong {
    white-space: nowrap;
  }
`;

const ValidationCheck = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  border: 1px solid ${p => (p.isValid ? '#1b633c' : p.formHasErrors ? '#CF000FFF' : '#d6d6d6')};
  background-color: ${p => (p.isValid || p.formHasErrors ? '#ffffff' : '#ececec')};
  margin-right: 8px;
`;

export { Password };
