import React from 'react';
import {
  COUNTRY_CODE_AU,
  COUNTRY_CODE_NZ,
  PRODUCTS,
  PRODUCT_CODE_DAVID_JONES_PREMIERE,
  PRODUCT_CODE_DAVID_JONES_PRESTIGE,
  PRODUCT_CODE_GO,
  PRODUCT_CODE_TWENTY_EIGHT_DEGREES,
  PRODUCT_DAVID_JONES,
  config,
  CREDIT_CARD_TYPE_MASTER,
  CREDIT_CARD_TYPE_VISA,
  CREDIT_CARD_TYPE_PLAIN,
  PRODUCT_CODE_AFS,
  PRODUCT_CODE_AU_GEM,
  PRODUCT_CODE_LOW_RATE,
} from '_config';

export function isAU() {
  return config.countryCode === COUNTRY_CODE_AU;
}

export function isNZ() {
  return config.countryCode === COUNTRY_CODE_NZ;
}

export function getProductInfo() {
  if (!config.productCode) {
    throw new Error('Product code is not set');
  }

  const product = PRODUCTS[config.countryCode][config.productCode];

  if (!product) {
    throw new Error(`Product code ${config.productCode} is not found in PRODUCT config.`);
  }

  return product;
}

export function getProductName() {
  const productName = getProductInfo()?.name;
  if (!productName.endsWith('®')) {
    return productName;
  }

  return (
    <>
      {productName.substring(0, productName.length - 1)}
      <sup>®</sup>
    </>
  );
}

export function getProductTeamName() {
  return getProductInfo()?.teamName ?? getProductInfo()?.name;
}

export function getConditionsOfUseTerm() {
  if (!config.productCode) {
    return 'Conditions of Use';
  }
  return getProductInfo()?.terms?.conditionsOfUse ?? 'Conditions of Use';
}

export function getFinancialTableTerm() {
  if (!config.productCode) {
    return 'Financial Table';
  }
  return getProductInfo()?.terms?.financialTable ?? 'Financial Table';
}

export function getMaxCreditLimit() {
  if (!config.productCode) {
    return 50000;
  }
  return getProductInfo().maxCreditLimit;
}

export function isDJ() {
  return (
    config.productCode === PRODUCT_CODE_DAVID_JONES_PRESTIGE ||
    config.productCode === PRODUCT_CODE_DAVID_JONES_PREMIERE ||
    (config.countryCode === COUNTRY_CODE_AU && config.productCode === undefined)
  );
}

export function is28D() {
  return config.productCode === PRODUCT_CODE_TWENTY_EIGHT_DEGREES;
}

export function isGO() {
  return config.productCode === PRODUCT_CODE_GO;
}

export function isCreditLine() {
  return config.productCode === PRODUCT_CODE_AFS;
}

export function isGemAU() {
  return config.productCode === PRODUCT_CODE_AU_GEM;
}

export function isLowRate() {
  return config.productCode === PRODUCT_CODE_LOW_RATE;
}

export function isMasterCard() {
  return getProductInfo()?.creditCardType === CREDIT_CARD_TYPE_MASTER;
}

export function isVisaCard() {
  return getProductInfo()?.creditCardType === CREDIT_CARD_TYPE_VISA;
}

export function isPlainCard() {
  return getProductInfo()?.creditCardType === CREDIT_CARD_TYPE_PLAIN;
}

/**
 * Switch by country code
 * @param {{ AU: any, NZ: any }} handle
 * @returns {any} Return value of the handle
 * Examples:
 * - Return values
 * ```js
    const url = byCountry({
      AU: `https://fileupload-qa1.lfs.local/fileupload/income`,
      NZ: `https://upload.gemfinance.co.nz/fileupload/income`,
    });
 * ```

  * - Return functions
 * ```js
    const nextStep = byCountry({
      AU: () => saveAndSubmit(newAcquisition),
      NZ: () => save(newAcquisition),
    });

    nextStep();
  ```

  - Lazy evaluation. Return value from per executed function. However, only execute the branch that is matched with the country code.
  * ```js
  const getDefaultApplyAndBuyParams = () => {
    return byCountry({
      AU: () =>
        byCard({
          [PRODUCT_CODE_DAVID_JONES_PRESTIGE]: () => DAVID_JONES_PRESTIGE_APPLY_AND_BUY,
          [PRODUCT_CODE_DAVID_JONES_PLATINUM]: () => DAVID_JONES_PLATINUM_APPLY_AND_BUY,
        }),
      NZ: () => NZ_DEFAULT_APPLY_AND_BUY,
    }, { lazy: true });
  };

  - Force to execute a branch. Useful for testing.
  * ```js
    // in test Address.spec.js
    render(<Address force="AU" />)

    // in Address.js
    export function Address(props) {
      const validator = byCountry({
        AU: auValidator,
        NZ: undefined,
      }, { force: props.force });
    }
  ```
 */
export const byCountry = (branches, opt = { lazy: false }) => {
  let result = null;

  if (opt.force) {
    return branches[opt.force];
  }

  const { AU, NZ } = branches;

  if (isAU()) {
    result = AU;
  } else if (isNZ()) {
    result = NZ;
  } else {
    throw new Error(`Unexpected country code: ${config.countryCode}`);
  }

  if (typeof result === 'function' && opt.lazy === true) {
    return result();
  }

  return result;
};

/**
 *
 * @param {{ [key: string]: any }} handle
 * @returns {any} Return value of the handle

 * Examples:

  ```js
    const params = byCard({
      [PRODUCT_CODE_DAVID_JONES_PRESTIGE]: DAVID_JONES_PRESTIGE_APPLY_AND_BUY,
      [PRODUCT_CODE_DAVID_JONES_PREMIERE]: DAVID_JONES_PLATINUM_APPLY_AND_BUY,
    })
  ```
 */
export const byCard = (handle, { whenNoProductCode } = {}) => {
  if (whenNoProductCode && config.productCode === undefined) {
    return whenNoProductCode;
  }

  if (handle[PRODUCT_DAVID_JONES] !== undefined && isDJ()) {
    return handle[PRODUCT_DAVID_JONES];
  }

  if (!Object.keys(handle).includes(config.productCode)) {
    if (handle.default !== undefined) {
      return handle.default;
    }
    throw new Error(`Unexpected product code: ${config.productCode}`);
  }
  return handle[config.productCode];
};
