import React from 'react';
import { Container, Grid, Link, Stack, Text } from 'basis';
import { LayoutPage } from 'components';
import AdrianaMartinezSignature from 'images/adriana_martinez.png';
import { config } from '../../../../_config';
import { davidJonesTheme } from '../../../../theme';
import {
  StyledDivSpaceBetween,
  StyledULWideLeftPadding as StyledUL,
  StyledLeftDivWidth75,
  StyledDivAlignRight,
} from './Styles';

export function AuGemFinancialTable({
  ratesAndFees,
  statementDate,
  downloadOrPrintFile,
  productName,
  accountNumber,
  creditLimit,
  productCode,
}) {
  const {
    purchaseAnnualRate,
    cashAnnualRate,
    expiredPromotionalPurchaseRate,
    maximumPurchase1,
    maximumPurchase2,
    minimumRepaymentRate,
    minimumRepaymentBalance,
    adjustedClosingBalance,
    monthlyFee,
    cashAdvanceFeePercentagePerTransaction,
    cashAdvanceFeeFixed,
    internationalTransactionRate,
    lateFee,
    paperStatementFee,
    ausPostHandlingFee,
    onlinePaymentHandlingFee,
    balanceTransferringFee,
  } = ratesAndFees;

  return (
    <LayoutPage hideBackButton>
      <Container margin="0 0 0 0">
        <Container padding="5 0" padding-md="5" testId="LAYOUT FORM">
          <Grid preset="page" debug={false}>
            <Grid.Item colSpan="all">
              <Grid rowsGap={0}>
                <Container padding="4 4" margin="0 8">
                  <div style={{ marginBottom: '40px' }}>
                    <Link variant="secondary" id="pdfButton" onClick={downloadOrPrintFile}>
                      Download or Print file
                    </Link>
                  </div>
                  <Grid preset="page" colsGap={11}>
                    <Grid.Item colSpan="all">
                      <Grid rowsGap={1}>
                        <Container padding="0">
                          <div id="financial-table">
                            <Stack gap="1">
                              <Text
                                as="h1"
                                textStyle="heading5"
                                textStyle-md="heading4"
                                color={davidJonesTheme.headingColor}
                              >
                                FINANCIAL TABLE
                              </Text>
                              <hr />
                              <Text>
                                The details below are correct as at the disclosure date. The{' '}
                                <strong>financial table</strong> does not contain all of the pre-contractual information{' '}
                                <strong>we</strong> are required by law to give <strong>you</strong>. Further terms and{' '}
                                conditions (and an explanation of some of the words used in the{' '}
                                <strong>financial table</strong>) are in the Conditions of Use in the enclosed booklet{' '}
                                entitled {productName} Conditions of Use and Credit Guide, which includes important{' '}
                                information about <strong>your credit contract</strong> and privacy.
                              </Text>
                              <br />
                              <StyledDivSpaceBetween>
                                <Container>
                                  <Text textStyle="heading6">Account Number</Text>
                                </Container>
                                <Container>
                                  <Text textStyle="heading6">Disclosure Date</Text>
                                </Container>
                                <Text textStyle="heading6">Credit Limit</Text>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <Container>
                                  <data-hj-suppress>
                                    <Text>{accountNumber}</Text>
                                  </data-hj-suppress>
                                </Container>
                                <Container margin={0} margin-md="0 0 0 -11">
                                  <Text>{statementDate}</Text>
                                </Container>
                                <data-hj-suppress>
                                  <Text>${creditLimit}</Text>
                                </data-hj-suppress>
                              </StyledDivSpaceBetween>
                              <br />
                              <Text>
                                <strong>Cash Limit</strong> <i>(refer to your statement)</i>
                              </Text>
                              <hr />
                              <Text>
                                A cash limit forms part of your credit limit which may be withdrawn in cash or cash{' '}
                                equivalent transactions. A cash limit may be imposed, withdrawn or changed on your{' '}
                                account from time to time, which is notified to you on each statement we give you and,{' '}
                                is effective from the date of each statement.{' '}
                              </Text>
                              <br />
                              <Text textStyle="heading5">Annual Percentage Rates</Text>
                              <hr />
                              <StyledDivSpaceBetween>
                                <Text textStyle="heading6">Card purchase rate</Text>
                                <Text>{purchaseAnnualRate}%</Text>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <Text textStyle="heading6">Cash advance rate</Text>
                                <Text>{cashAnnualRate}%</Text>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <Text textStyle="heading6">Expired promotional purchase rate</Text>
                                <Text>{expiredPromotionalPurchaseRate}%</Text>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <div>
                                  <Text textStyle="heading6">Balance transfer rate for balance transfer promotion</Text>
                                  <Text>
                                    Please refer to the balance transfer interest rate set out in any offer presented to{' '}
                                    you.
                                  </Text>
                                </div>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledUL>
                                <li>
                                  The <strong>card purchase rate</strong> applies to the interest bearing balance. This{' '}
                                  includes the balance of <strong>purchases</strong> with expired{' '}
                                  <strong>interest free periods</strong>.
                                </li>
                                <li>
                                  The <strong>cash advance rate</strong> applies to the{' '}
                                  <strong>cash advance balance</strong> and{' '}
                                  <strong>expired balance transfer balance</strong>.
                                </li>
                                <li>
                                  The <strong>expired promotional purchase rate</strong> applies to{' '}
                                  <strong>expired promotional purchases</strong>, interest bearing fees and charges.
                                </li>
                              </StyledUL>
                              <br />
                              <Text textStyle="heading5">Maximum Interest Free Period</Text>
                              <hr />
                              <Text>
                                Up to 55 days for purchases up to ${maximumPurchase1} and 6 months for purchases of $
                                {maximumPurchase2?.toFixed(2)} or more. At the time of making a{' '}
                                <strong>purchase</strong> under a <strong>promotional offer</strong>, you may agree to a{' '}
                                different <strong>interest free period</strong> for that <strong>purchase</strong>. The{' '}
                                maximum period for <strong>purchases</strong> and balance transfers under{' '}
                                <strong>promotional offers</strong> is 60 months.
                              </Text>
                              <br />
                              <Text textStyle="heading5">Minimum Repayments</Text>
                              <hr />
                              <Text>
                                The <strong>minimum monthly payment</strong> you must pay for a{' '}
                                <strong>statement period</strong> is {minimumRepaymentRate?.toFixed(2)}% of the{' '}
                                <strong>adjusted closing balance</strong> or ${adjustedClosingBalance}, whichever is{' '}
                                greater, plus any <strong>instalment payment</strong> due for that{' '}
                                <strong>statement period</strong>. However:
                              </Text>
                              <StyledUL>
                                <li>
                                  If the <strong>adjusted closing balance</strong> is less than $
                                  {adjustedClosingBalance}, the <strong>minimum monthly payment</strong> will be the{' '}
                                  <strong>adjusted closing balance</strong>; and
                                </li>
                                <li>
                                  If the <strong>adjusted closing balance</strong> is less than $
                                  {minimumRepaymentBalance}, the <strong>minimum monthly payment</strong> will be $0
                                  (nil).
                                </li>
                              </StyledUL>
                              <Text>
                                Each <strong>minimum monthly payment</strong> is payable by the{' '}
                                <strong>due date</strong> shown on the relevant monthly statement of{' '}
                                <strong>account</strong>.
                              </Text>
                              <br />
                              <Text textStyle="heading5">Fees and Charges</Text>
                              <hr />
                              <StyledDivSpaceBetween>
                                <StyledLeftDivWidth75>
                                  <Text>An account service fee will be charged to the account monthly.</Text>
                                </StyledLeftDivWidth75>
                                <div style={{ textAlign: 'right' }}>
                                  <Text>
                                    <strong>${monthlyFee?.toFixed(2)}</strong>
                                  </Text>
                                </div>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <StyledLeftDivWidth75>
                                  <Text>
                                    A <strong>cash advance</strong> fee will be charged when we process each domestic or
                                    international <strong>cash advance</strong> (including any{' '}
                                    <strong>BPAY payments</strong> which you have elected to treat as{' '}
                                    <strong>cash advances</strong> under the <strong>Online Service Centre</strong>{' '}
                                    terms and conditions).
                                  </Text>
                                </StyledLeftDivWidth75>
                                <div style={{ textAlign: 'right' }}>
                                  <Text>Greater of ${cashAdvanceFeeFixed}</Text>
                                  <Text>or {cashAdvanceFeePercentagePerTransaction}% of the</Text>
                                  <Text>
                                    <strong>cash advance</strong>
                                  </Text>
                                  <Text>amount</Text>
                                </div>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <Text>
                                  An <strong>international transaction</strong> fee of a specific percentage of the{' '}
                                  amount of the <strong>international transaction</strong> (as converted to Australian{' '}
                                  dollars if not originally in Australian dollars) will be charged when we process each{' '}
                                  <strong>international transaction</strong>.
                                </Text>
                                <StyledDivAlignRight>
                                  <Text>{internationalTransactionRate?.toFixed(2)}% of the</Text>
                                  <Text>Australian dollar value of the</Text>
                                  <Text>
                                    <strong>international transaction</strong>
                                  </Text>
                                </StyledDivAlignRight>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <Text>
                                  A <strong>late fee</strong> will be charged to the <strong>account</strong> in each{' '}
                                  <strong>statement period</strong> when any amount due and payable to us is not paid in
                                  full by the <strong>due date</strong>.
                                </Text>
                                <StyledDivAlignRight>
                                  <Text>
                                    <strong>${lateFee?.toFixed(2)}</strong>
                                  </Text>
                                </StyledDivAlignRight>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <StyledLeftDivWidth75>
                                  <Text>
                                    A paper statement fee will be charged for each paper statement of{' '}
                                    <strong>account</strong> sent to you. This fee will not be charged if you consent to{' '}
                                    receiving your statements electronically.
                                  </Text>
                                </StyledLeftDivWidth75>
                                <StyledDivAlignRight>
                                  <Text>
                                    <strong>${paperStatementFee?.toFixed(2)}</strong>
                                  </Text>
                                </StyledDivAlignRight>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <StyledLeftDivWidth75>
                                  <Text>
                                    A payment handling fee will be charged to <strong>your account</strong> each time{' '}
                                    you make a payment to <strong>your account</strong>, except that no fee is payable{' '}
                                    for payments made by mail, by direct debit or via the{' '}
                                    <strong>Online Service Centre</strong>.
                                  </Text>
                                </StyledLeftDivWidth75>
                                <StyledDivAlignRight>
                                  <Text>
                                    <strong>${ausPostHandlingFee?.toFixed(2)} (in person)</strong>
                                  </Text>
                                  <Text>
                                    <strong>${onlinePaymentHandlingFee?.toFixed(2)} (online)</strong>
                                  </Text>
                                </StyledDivAlignRight>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <StyledLeftDivWidth75>
                                  <Text>
                                    A balance transfer fee will be charged to the account if you take up a balance{' '}
                                    transfer offer
                                  </Text>
                                </StyledLeftDivWidth75>
                                <StyledDivAlignRight>
                                  <Text>Up to {balanceTransferringFee}% of balance transfer amount</Text>
                                </StyledDivAlignRight>
                              </StyledDivSpaceBetween>
                              <hr />
                              <Container>
                                <Text>
                                  These fees and charges, once charged, will be debited to your <strong>account</strong>{' '}
                                  and will form part of the <strong>adjusted closing balance</strong> which is used to
                                  calculate your <strong>minimum monthly payments</strong>.
                                </Text>
                              </Container>
                              <br />
                              <Container>
                                <Text textStyle="heading5">Changes to Limits, Rates, Fees and Charges</Text>
                              </Container>
                              <hr />
                              <Container>
                                <Text>
                                  The <strong>credit limit, annual percentage rate(s)</strong>, maximum{' '}
                                  <strong>interest free period</strong>, the amount, frequency or time for payment or{' '}
                                  method of calculation of a fee or charge or <strong>minimum monthly payment</strong>{' '}
                                  may be changed, and new fees or charges may be imposed without your consent. You will{' '}
                                  be notified of changes or new fees or charges in the manner set out in the Conditions{' '}
                                  of Use.
                                </Text>
                              </Container>
                              <br />
                              <Container>
                                <Text textStyle="heading5">Commission</Text>
                                <hr />
                                <Text>
                                  An unascertainable commission is payable for the introduction of this credit business{' '}
                                  to any media buying agency, merchant and related entity of a merchant which is{' '}
                                  involved in the introduction.
                                </Text>
                              </Container>
                              <Container>
                                <Text textStyle="heading5">Other Information</Text>
                                <hr />
                                <Text>
                                  If you do not want to receive direct marketing information about other products or
                                  services, please call {config.phoneNumbers.assistance2}.
                                </Text>
                              </Container>
                              <Container>
                                <Text textStyle="heading5">Offer to New Account Holders</Text>
                              </Container>
                              <hr />
                              <Text>
                                Latitude Finance Australia (ABN 42 008 583 588), Australian Credit Licence number
                                392145, as the credit provider, offers to provide you a{' '}
                                <strong>{productName} account</strong> on the conditions set out in the{' '}
                                <strong>credit contract</strong> comprised of this <strong>contract schedule</strong>{' '}
                                and the Conditions of Use. You can accept this offer by one of the methods set out in{' '}
                                clause 2 of the Condition of Use.
                              </Text>
                              <br />
                              <Container padding="4 0">
                                <Text>Signed on behalf of Latitude Finance Australia.</Text>
                                <br />
                                <img src={AdrianaMartinezSignature} width="150px" alt="form-title-icon" />
                                <Text>
                                  <strong>Adriana Martinez</strong>
                                </Text>
                                <Text>Executive General Manager - Latitude Pay</Text>
                              </Container>
                            </Stack>
                          </div>
                        </Container>
                      </Grid>
                    </Grid.Item>
                  </Grid>
                </Container>
              </Grid>
            </Grid.Item>
          </Grid>
        </Container>
      </Container>
    </LayoutPage>
  );
}
