import React, { useCallback, useState } from 'react';
import { LayoutPage, Errors, LayoutContent, SaveAndCompleteLaterButton, YellowMessage } from 'components';
import { Container, Text, Stack, LoadingIcon, Grid, Flex, Message } from 'basis';
import { useHistory } from 'react-router-dom';
import { useStoreValue } from 'store';
import { SessionTimeout } from 'components/session-timeout/SessionTimeout';
import {
  useEnv,
  useLongPollBiometricsApplication,
  useErrorTracking,
  useWaitAndRedirect,
  useResendBiometricsSms,
} from 'hooks';
import { BIOMETRICS_COMPLETED, BIOMETRICS_CANCELLED } from 'store/constants';
import { isMobileOnly } from 'react-device-detect';
import { ClickHereLink } from 'components/styles';
import { DEV_FEATURES } from '__dev/devFeatures';
import { config, STEP_ABOUT_YOU, STEP_BIOMETRICS_RETURN } from '_config';
import { byCountry, getDataLayerElements } from 'utils';
import styled from '@emotion/styled';
import { ShadowedContainer } from 'components/info-card/InformationCard';
import { SuccessMessage } from 'components/message/SuccessMessage';
import BioIllustration from 'images/biometric-verification.svg';
import IconId from 'images/icons/id.svg';
import IconMobile from 'images/icons/mobile.svg';
import IconMessage from 'images/icons/message.svg';
import IconTimer from 'images/icons/timer.svg';
import { useEventTracking } from 'react-event-tracker';

export const ProcessingBiometrics = () => {
  const Component = byCountry({
    AU: ProcessingBiometricsAU,
    NZ: ProcessingBiometricsNZ,
  });

  return <Component />;
};

export const ProcessingBiometricsAU = () => {
  const [storeState] = useStoreValue();

  useWaitAndRedirect({
    applicationId: storeState.applicationId,
    testId: 'longPollingWhileProcessingBiometrics',
  });
  const getEnv = useEnv();
  const sessionDurationInSec = 2 * getEnv('REACT_APP_SESSION_EXPIRATION_IN_SEC');
  return (
    <LayoutPage
      noSessionTimeout
      noTitlePadding
      preHeader={
        <LayoutContent rowsGap="0">
          <SuccessMessage />
        </LayoutContent>
      }
    >
      <SessionTimeout sessionDurationInSec={sessionDurationInSec} />
      <LayoutContent rowsGap="0">
        <Stack gap="6">
          <Container>
            <Text>
              To fully verify your identity online, we use Biometric verification. We’ve partnered with IDVerse to
              complete this simple and secure process.
            </Text>
            <Flex height="100%" placeItems="center center" margin="8 0 2 0">
              <Stack gap="4">
                <img src={BioIllustration} alt="Biometric verification illustration" />
                <Text>
                  <strong>It should take about 3 minutes</strong>
                </Text>
              </Stack>
            </Flex>
          </Container>
          <Container bg="grey.t03" padding-xs="4" padding-sm="10" margin="0 2 0 0" margin-xs={0}>
            <ShadowedContainer>
              <Container bg="white" padding="8">
                <ListItem>
                  <img src={IconMessage} alt="message icon" />
                  <Text>
                    <strong>Open the SMS </strong>we've just sent to <strong>{storeState.maskedMobileNumber}</strong>{' '}
                    and tap on the link.
                  </Text>
                </ListItem>
                <ListItem>
                  <img src={IconMobile} alt="phone icon" />
                  <Text>
                    <strong>Follow the prompts on your mobile phone</strong> to complete each step.
                  </Text>
                </ListItem>
                <ListItem>
                  <img src={IconId} alt="identity icon" />
                  <Text>
                    <strong>You'll need a government issued photo ID</strong> like a Passport or Drivers Licence.
                  </Text>
                </ListItem>
              </Container>
            </ShadowedContainer>
          </Container>
          <Text>
            Haven't received your SMS link or link expired? <ResendSmsButton />
          </Text>

          <Text margin="2 0 4 0">
            <strong>Any further questions please get in touch with us on {config.phoneNumbers.assistance}.</strong>
          </Text>
          <SaveAndCompleteLaterButton infoMessage="If you've completed the Biometric step please wait. This page can take some time to update." />
          <MessageWrapper>
            <img src={IconTimer} alt="Timer icon" />
            <Text>
              Please hold tight once you've completed the Biometric step. This page can take some time to update.
            </Text>
          </MessageWrapper>
        </Stack>
      </LayoutContent>
    </LayoutPage>
  );
};

const ProcessingBiometricsNZ = () => {
  const [store, updateStore] = useStoreValue();
  const { applicationRef, applicationErrors, applicationId } = store;
  const history = useHistory();
  const getEnv = useEnv();
  const { handleErrorTracking } = useErrorTracking();
  const sessionDurationInSec = 8 * getEnv('REACT_APP_SESSION_EXPIRATION_IN_SEC');
  /** Note `isMobileOnly` is excluding tablet devices as APLYiD currently has issues with treating tablets as desktops. After they fix it we can change to use `isMobile` instead */
  const isMobileOrSimulated = isMobileOnly || DEV_FEATURES.SIMULATE_MOBILE;

  const onError = useCallback(
    error => {
      updateStore({ applicationErrors: error });
      handleErrorTracking(error);
    },
    [handleErrorTracking, updateStore],
  );

  const onManualVerify = useCallback(() => {
    updateStore({
      applicationErrors: null,
      biometricsVerification: 'CANCELLED',
      activeStep: STEP_ABOUT_YOU,
    });

    history.push(STEP_ABOUT_YOU);
  }, [history, updateStore]);

  const onFinishedBiometrics = useCallback(
    application => {
      if (application.status === BIOMETRICS_CANCELLED) {
        updateStore({
          applicationErrors: null,
          biometricsVerification: 'CANCELLED',
          activeStep: STEP_ABOUT_YOU,
        });
        history.push(`${STEP_ABOUT_YOU}?cancel=true`);
      } else if (application.status === BIOMETRICS_COMPLETED) {
        updateStore({
          applicationErrors: null,
          activeStep: STEP_BIOMETRICS_RETURN,
        });
        history.push(STEP_BIOMETRICS_RETURN);
      }
    },
    [history, updateStore],
  );

  const { retry, loading } = useLongPollBiometricsApplication({
    applicationId,
    onError,
    onSuccess: onFinishedBiometrics,
  });

  if (applicationErrors) {
    return (
      <LayoutPage>
        <Errors applicationRef={applicationRef} retry={retry} retrying={loading} />
      </LayoutPage>
    );
  }

  if (isMobileOrSimulated) {
    // User finished biometrics on mobile and is now on desktop and waiting for IDV service finished loading biometrics result from APLYiD to Pavlova
    return (
      <LayoutPage headerAlign="center" headerOverride="" noSessionTimeout>
        <Container testId="processingBiometrics" margin="6 0" padding="8 0" height="400" hasBreakpointWidth>
          <Flex height="100%" placeItems="center">
            <Stack gap="8">
              <Container textAlign="center">
                <LoadingIcon size="medium" />
              </Container>
              <Text align="center">
                <strong>Taking you back to your application</strong>
              </Text>
            </Stack>
          </Flex>
        </Container>
      </LayoutPage>
    );
  }

  return (
    <LayoutPage headerAlign="center" noSessionTimeout>
      <SessionTimeout sessionDurationInSec={sessionDurationInSec} persistDraft />
      <Container testId="processingBiometrics" margin="6 0" padding="8 0" padding-md="8 5" hasBreakpointWidth>
        <Grid preset="page" debug={false}>
          <Grid.Item colSpan="all" colSpan-sm="1-6" colSpan-md="1-6" colSpan-lg="3-8" colSpan-xl="3-8">
            <Stack gap="5">
              <Text>
                Completing your identity verification now will save you time as we will attempt to pre-fill your ID
                details into your application form.
              </Text>
              <Text>
                If you haven't received your SMS you can{' '}
                <ClickHereLink onClick={onManualVerify} newTab={false}>
                  skip and complete your identity verification later.
                </ClickHereLink>
              </Text>
            </Stack>
          </Grid.Item>
        </Grid>
      </Container>
    </LayoutPage>
  );
};

const MessageWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-start;
  line-height: 24px;
  margin-bottom: 32px;
`;

const NotificationWrapper = styled.div`
  margin-top: 16px;
`;

const ListItem = styled.div`
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #d6d6d6;
  padding-bottom: 32px;
  gap: 12px;
  padding-top: 32px;

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    border-width: 0;
    padding-bottom: 0;
  }
`;

const TextButton = styled.span`
  color: ${props => props.theme.a.color};
  ${props =>
    props.disabled
      ? `
          cursor: not-allowed;
          text-decoration: none;
        `
      : `
          cursor: pointer;
          text-decoration: underline;`}
`;

const resendFrequencyLimitInSec = 30;

const ResendSmsButton = () => {
  const [storeState] = useStoreValue();
  const { trackEvent } = useEventTracking();
  const { resendBiometricsSms, loading: isResendingBiometricsSms } = useResendBiometricsSms();
  const [isInCoolDown, setIsInCoolDown] = useState(false);
  const [remainingSMSAttempts, setRemainingSMSAttempts] = useState();
  const [attemptsExceeded, setAttemptsExceeded] = useState(false);
  const isDisabled = isResendingBiometricsSms || isInCoolDown;
  const onClick = useCallback(async () => {
    if (isDisabled) {
      return;
    }
    if (remainingSMSAttempts === 0) {
      setAttemptsExceeded(true);
      return;
    }
    trackEvent({
      event: {
        category: 'application',
        action: 'application-navigation',
        location: 'biometrics-processing',
        label: 'resend sms link',
      },
      ...getDataLayerElements(storeState),
    });
    const remainingResendSMSAttempts = await resendBiometricsSms();
    setRemainingSMSAttempts(remainingResendSMSAttempts);
    setIsInCoolDown(true);
    setTimeout(() => {
      setIsInCoolDown(false);
    }, resendFrequencyLimitInSec * 1000);
  }, [isDisabled, remainingSMSAttempts, resendBiometricsSms, storeState, trackEvent]);
  const label = isResendingBiometricsSms ? 'Sending…' : isInCoolDown ? 'SMS link sent' : 'Resend SMS';
  return (
    <>
      {!attemptsExceeded && (
        <TextButton
          onClick={onClick}
          disabled={isDisabled}
          style={{
            color: isResendingBiometricsSms ? 'black' : isInCoolDown ? '#1B633C' : '#0061EE',
          }}
        >
          <strong>{label}</strong>
        </TextButton>
      )}

      {!isInCoolDown && remainingSMSAttempts >= 0 && !attemptsExceeded && (
        <NotificationWrapper>
          <YellowMessage>
            <Message testId="yellow-message" severity="warning-or-significant">
              <Text>You have {remainingSMSAttempts} SMS link attempts remaining.</Text>
            </Message>
          </YellowMessage>
        </NotificationWrapper>
      )}

      {!!attemptsExceeded && (
        <NotificationWrapper>
          <Message testId="exceeded-sms-attempts-message" severity="blocking" bg="secondary.pink.t30">
            <Text>
              <strong>You have exceeded the number of SMS attempts.</strong>
            </Text>
            <Text>Please contact {config.phoneNumbers.assistance}</Text>
            <Text>Your application no. {storeState.applicationRef}</Text>
          </Message>
        </NotificationWrapper>
      )}
    </>
  );
};
