import React from 'react';
import {
  PROOF_OF_ADDRESS,
  ANTI_MONEY_LAUNDERING,
  PROOF_OF_INCOME,
  PROOF_OF_ADDRESS_AND_PROOF_OF_INCOME,
  ANTI_MONEY_LAUNDERING_AND_PROOF_OF_INCOME,
  POITYPE_PAYSLIP_UPLOAD,
  POITYPE_DOC_UPLOAD,
} from 'store';
import { ProofOfAddress } from './ProofOfAddress';
import { AntiMoneyLaundering } from './AntiMoneyLaundering';
import { ProofOfIncome } from './ProofOfIncome';

import { OutcomeConditionallyUpload } from '../OutcomeConditionallyUpload';
import { OutcomeReferred } from '../OutcomeReferred/OutcomeReferred';

function OutcomeConditionallyApproved({ storeState }) {
  const { applicationStatusReason, incomeVerificationType } = storeState;

  if (incomeVerificationType === POITYPE_PAYSLIP_UPLOAD || incomeVerificationType === POITYPE_DOC_UPLOAD) {
    return <OutcomeConditionallyUpload storeState={storeState} />;
  }

  switch (applicationStatusReason) {
    case PROOF_OF_ADDRESS:
      return <ProofOfAddress storeState={storeState} />;
    case ANTI_MONEY_LAUNDERING:
      return <AntiMoneyLaundering storeState={storeState} />;
    case PROOF_OF_INCOME:
      return <ProofOfIncome storeState={storeState} />;
    case PROOF_OF_ADDRESS_AND_PROOF_OF_INCOME:
      return <ProofOfAddress storeState={storeState} withPOI />;
    case ANTI_MONEY_LAUNDERING_AND_PROOF_OF_INCOME:
      return <AntiMoneyLaundering storeState={storeState} withPOI />;
    default:
      return <OutcomeReferred storeState={storeState} />;
  }
}

export { OutcomeConditionallyApproved };
