import React, { useCallback, useEffect } from 'react';
import { Stack, Text, Button, Container, Grid } from 'basis';
import { Modal } from 'components/modal/Modal';
import { useTheme } from '@emotion/react';
import { useStoreValue, getDefaultState } from 'store';
import { useCountdownTimer } from 'hooks/useCountdownTimer';
import { useEventTracking } from 'react-event-tracker';
import { useHistory } from 'react-router-dom';
import { useEnv, useRoutePageInfo, useSaveDraft, useFormCache } from 'hooks';
import { byCountry, getDataLayerElements, getProductInfo, isAU } from 'utils';
import { STEP_SAVED } from '_config';
import { isFeatureOn } from 'featureToggles';

export const SessionTimeout = ({ persistDraft = false, formId, formValues, sessionDurationInSec = undefined }) => {
  const [storeState, updateStore] = useStoreValue();
  const { trackEvent } = useEventTracking();
  const history = useHistory();
  const { pageTitle, pathname } = useRoutePageInfo();
  const { saveDraft, savingDraft } = useSaveDraft();
  const { resetFormCacheValues } = useFormCache();
  const theme = useTheme();
  const conditionallySaveDraft = useCallback(() => {
    if (persistDraft && !savingDraft) {
      const newStoreState = {
        ...storeState,
        acquisition: {
          ...storeState.acquisition,
          ...(formId && formValues ? { [formId]: { ...formValues } } : {}),
        },
      };

      saveDraft(newStoreState);
    }
  }, [formValues, formId, persistDraft, saveDraft, savingDraft, storeState]);

  const eventConfig = useCallback(
    label => ({
      event: {
        label,
        category: 'application',
        action: 'application-timeout',
        location: pageTitle,
      },
    }),
    [pageTitle],
  );

  const endSession = useCallback(() => {
    conditionallySaveDraft();
    const newState = byCountry({
      NZ: getDefaultState(),
      AU: {
        ...getDefaultState(),
        // For AU, we decided to keep application reference number for display purpose on saved-application page. More details: https://di.latitudefinancial.com/jira/browse/OBS-4932
        applicationRef: storeState.applicationRef,
      },
    });
    updateStore(newState);
    resetFormCacheValues();
    if (pathname !== '/') {
      if (storeState.applicationStatus === '') {
        const { landingPath } = getProductInfo();
        history.push(landingPath);
      } else {
        history.push(
          byCountry({
            NZ: '/',
            AU: STEP_SAVED,
          }),
        );
      }
    }
  }, [
    conditionallySaveDraft,
    storeState.applicationRef,
    storeState.applicationStatus,
    updateStore,
    resetFormCacheValues,
    pathname,
    history,
  ]);

  const onTimedOut = useCallback(() => {
    trackEvent(eventConfig('Session expired'));
    endSession();
  }, [trackEvent, eventConfig, endSession]);

  const getEnv = useEnv();
  const defaultSessionDuration = getEnv('REACT_APP_SESSION_EXPIRATION_IN_SEC');
  const [seconds, resetTimer] = useCountdownTimer(sessionDurationInSec || defaultSessionDuration, onTimedOut);
  const isOpen = seconds <= byCountry({ NZ: 60, AU: 300 });

  useEffect(() => {
    const events = ['mousemove', 'load', 'click', 'scroll', 'keypress'];

    if (!isOpen) {
      events.forEach(event => {
        window.addEventListener(event, resetTimer);
      });
    }

    return () => {
      events.forEach(event => {
        window.removeEventListener(event, resetTimer);
      });
    };
  }, [resetTimer, isOpen]);

  useEffect(() => {
    if (isOpen) {
      trackEvent(eventConfig('Session about to expire'));
    }
  }, [isOpen, trackEvent, eventConfig]);

  const onClickResumeApplication = useCallback(() => {
    resetTimer();
    updateStore(getDefaultState());
    resetFormCacheValues();

    updateStore({
      activeStep: '/resume-your-application',
      isResuming: true,
    });

    trackEvent({
      event: {
        category: 'application',
        action: 'resume-application',
        location: pathname,
        label: 'Resume application',
      },
      ...getDataLayerElements(storeState),
    });

    history.push('/resume-your-application');
  }, [history, pathname, storeState, trackEvent, updateStore, resetFormCacheValues, resetTimer]);

  const onClickEndSession = useCallback(() => {
    trackEvent(eventConfig('No, exit'));
    endSession();
  }, [endSession, eventConfig, trackEvent]);

  const onClickExtendSession = useCallback(() => {
    trackEvent(eventConfig('Yes, continue'));
    resetTimer();
  }, [eventConfig, resetTimer, trackEvent]);
  function getMinutes() {
    const minutes = Math.floor(seconds / 60);

    return `${minutes > 1 ? `${minutes} minutes` : minutes === 1 ? '1 minute' : ''}`;
  }
  function getRemainingSeconds() {
    const remainingSeconds = seconds % 60;

    return `${remainingSeconds} ${remainingSeconds > 1 ? 'seconds' : 'second'}`;
  }
  const timeoutHeader = byCountry({
    NZ: (
      <Text textStyle="heading5" color={theme.headingColor}>
        Your session is about to expire
      </Text>
    ),
    AU: (
      <Text textStyle="heading5" color={theme.headingColor} align="center">
        Don’t lose your application
      </Text>
    ),
  });
  const timeoutHeaderPostSubmission = (
    <Text textStyle="heading5" color={theme.headingColor} align={isAU() ? 'center' : 'left'}>
      Your session is about to expire
    </Text>
  );
  const countDownText = (
    <b data-testid="CountDown">
      {getMinutes()} {getRemainingSeconds()}
    </b>
  );
  const SessionContentNZ = (
    <Text>
      Your session will expire in <b data-testid="CountDown">{seconds} seconds</b> due to inactivity. Do you want to
      continue your session?
    </Text>
  );
  const sessionContent = byCountry({
    NZ: SessionContentNZ,
    AU: (
      <Text>
        Your session will expire in <b data-testid="CountDown">{countDownText}</b>.<br />
        Click 'Continue' to resume your application. If your session expires or you click 'Exit', your application will
        be lost and you will have to start again.
      </Text>
    ),
  });
  const sessionContentPostSubmission = byCountry({
    NZ: SessionContentNZ,
    AU: (
      <Text>
        Your session will expire in <b data-testid="CountDown">{countDownText}</b>.<br />
        Click 'Continue' to resume your application. If your session expires or you click 'Exit', you can resume your
        application via the link in your email.
      </Text>
    ),
  });
  const exitButton = byCountry({
    NZ: 'No, exit',
    AU: 'Exit',
  });
  const continueButton = byCountry({
    NZ: 'Yes, continue',
    AU: 'Continue',
  });
  return (
    <Modal isOpen={isOpen} disableExitShortcuts>
      <Container padding="3" padding-md="6" testId="SessionTimeout">
        {seconds > 0 || !isFeatureOn('saveAndResume') || isAU() ? (
          <Stack gap="6" gap-md="10">
            {storeState.applicationStatus === '' ? timeoutHeader : timeoutHeaderPostSubmission}
            {storeState.applicationStatus === '' ? sessionContent : sessionContentPostSubmission}
            <Container padding-md="0 14">
              <Grid>
                <Grid.Item colSpan="all" colSpan-md="0-1">
                  <Container padding="2 0" padding-md="2">
                    <Button
                      type="button"
                      width="100%"
                      variant="secondary"
                      testId="EndButton"
                      onClick={onClickEndSession}
                    >
                      {exitButton}
                    </Button>
                  </Container>
                </Grid.Item>
                <Grid.Item colSpan="all" colSpan-md="2-3">
                  <Container padding="2 0" padding-md="2">
                    <Button type="button" width="100%" testId="ExtendButton" onClick={onClickExtendSession}>
                      {continueButton}
                    </Button>
                  </Container>
                </Grid.Item>
              </Grid>
            </Container>
          </Stack>
        ) : (
          <Stack gap="6">
            <Text textStyle="heading5" color={theme.headingColor}>
              Session expired
            </Text>
            <Text padding="2 0">Your session has expired due to inactivity.</Text>
            <Text>
              You can resume your application now or a time that suits you. We've emailed you a link to return and
              complete any time within the next 60 days.
            </Text>
            <Container padding-md="0 14">
              <Grid>
                <Grid.Item colSpan="all">
                  <Container padding="2 2" padding-md="2 2">
                    <Button
                      type="button"
                      width="100%"
                      testId="ResumeApplicationButton"
                      onClick={onClickResumeApplication}
                    >
                      Resume application
                    </Button>
                  </Container>
                </Grid.Item>
              </Grid>
            </Container>
          </Stack>
        )}
      </Container>
    </Modal>
  );
};
