import React from 'react';
import { Text, Message, Container } from 'basis';
import { YellowMessage } from 'components/message/YellowMessage';
import { formatNumber } from 'utils';

/**
 * @name NegativeCapacityModalContent
 * @description Component to display the negative capacity modal content
 * @typedef {Object} PropTypes
 * @property {number} monthlyIncome
 * @property {number} monthlyLivingExpenses
 * @property {number} totalCardLimits
 * @property {number} monthlyInvestmentPropertyRepayments
 * @property {number} monthlyOtherLoanRepayments
 * @param {PropTypes} props
 * @returns {JSX.Element}
 */
const NegativeCapacityModalContent = ({
  monthlyIncome,
  monthlyLivingExpenses,
  totalCardLimits,
  monthlyInvestmentPropertyRepayments,
  monthlyOtherLoanRepayments,
}) => {
  return (
    <>
      <Text>
        <b>Total monthly income:</b> ${formatNumber(monthlyIncome)}
      </Text>
      <Text>
        <b>Total monthly living expenses:</b> ${formatNumber(monthlyLivingExpenses)}
      </Text>
      <Text>
        <b>Total monthly Investment property repayments:</b> ${formatNumber(monthlyInvestmentPropertyRepayments)}
      </Text>
      <Text>
        <b>Total monthly Other loan repayments: </b> ${formatNumber(monthlyOtherLoanRepayments)}
      </Text>
      <Text>
        <b>Total credit card limits:</b> ${formatNumber(totalCardLimits)}
      </Text>

      <Container margin="4 0 0 0">
        <YellowMessage>
          <Message testId="yellow-message" padding="3 3 3 4" severity="warning-or-significant">
            <Text>If something isn't right, go back and edit your finances</Text>
          </Message>
        </YellowMessage>
      </Container>
    </>
  );
};

export { NegativeCapacityModalContent };
