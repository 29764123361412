import React from 'react';
import { useEventTracking } from 'react-event-tracker';
import { Link, Text, Container, Flex, Icon } from 'basis';
import styled from '@emotion/styled';
import { ProductComparisonLinks } from '_config';
import boldLogo from 'images/latitude-bold-logo.svg';

const DarkLogoWrapper = styled.div`
  svg {
    path {
      fill: #000;
    }
  }
`;

const LinkWrapper = styled.div`
  display: inline-block;
  a {
    span {
      font-size: 14px;
      display: flex;
      line-height: 26px;
      border-bottom: 0;

      svg {
        display: flex;
        height: 24px;
        width: 24px;
      }
    }
  }
`;

const TextWrapper = styled.span`
  p {
    display: inline;
  }
`;

export function ComparisonFooter() {
  const { trackEvent } = useEventTracking();

  return (
    <Container padding="4" testId="David-Jones-Comparison-footer">
      <Container hasBreakpointWidth>
        <Flex direction="column" direction-sm="row" placeItems="center">
          <DarkLogoWrapper>
            <img src={boldLogo} alt="latitude-bold-logo" />
          </DarkLogoWrapper>
        </Flex>

        <Flex direction="column" direction-sm="row" placeItems="center">
          <Container textStyle="legal" margin="2 0 0 0">
            <TextWrapper>
              <Text>
                Credit facility provided by Latitude Finance Australia (ABN 42 008 583 588) Australian Credit Licence{' '}
                Number 392145.{' '}
              </Text>
            </TextWrapper>
            <LinkWrapper>
              <Link
                newTab
                href={ProductComparisonLinks.about_us.url}
                onClick={() => {
                  trackEvent({
                    event: {
                      category: 'text-link',
                      action: 'external-link',
                      location: 'footer',
                      label: 'About Latitude',
                    },
                  });
                }}
              >
                About Latitude <Icon name="external-link" color="primary.blue.t100" />
              </Link>
            </LinkWrapper>
          </Container>
        </Flex>
      </Container>
    </Container>
  );
}
