import React from 'react';
import { Text, Link, Stack } from 'basis';
import styled from '@emotion/styled';
import { PhoneNumberLink } from 'components';
import aplyIdLogo from 'images/applyid-logo.png';

export const NumberList = styled.ol`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

const NumberListItem = styled.li`
  &:not(:first-of-type) {
    margin-top: 24px;
  }
  & :before {
    content: "${p => p.number}";
    display: inline-block;
    text-align: center;
    line-height: 32px;
    font-size: 20px;
    font-weight: 600;
    color: #FFFFFF;
    background-color: #0046AA;
    border-radius: 32px;
    width: 32px;
    height: 32px;
    margin-right: 8px;
  }
`;

const AplyIdLogoImg = styled.img`
  width: 200px;
  height: 40px;
  display: block;
  margin: 0px auto;
`;

function AplyIdSteps({ type, linkLabel, linkOnClick, linkHref }) {
  if (type === 'ID_AND_ADDRESS') {
    return (
      <Stack gap="8">
        <Text textStyle="heading6">We need to verify your identity and your address</Text>
        <AplyIdLogoImg src={aplyIdLogo} alt="aply-id" />
        <Text>
          Through our partnership with APLYiD we can verify your identity and address digitally and it only takes a
          couple of minutes.
        </Text>
        <Text>You can do this with 3 easy steps using your mobile phone:</Text>
        <NumberList>
          <NumberListItem number={1}>
            <Text>Take a photo of your ID document</Text>
          </NumberListItem>
          <NumberListItem number={2}>
            <Text>Take a photo of yourself</Text>
          </NumberListItem>
          <NumberListItem number={3}>
            <Text>Confirm your address</Text>
          </NumberListItem>
        </NumberList>
        <Text>Once you've completed the verification process we'll be in contact with you.</Text>
        <Link newTab appearance="primary-button" variant="blue-button" href={linkHref} onClick={linkOnClick}>
          {linkLabel}
        </Link>
        <Text>
          Alternatively, you can call us on <PhoneNumberLink id="originationsTeam" />
        </Text>
      </Stack>
    );
  }

  if (type === 'ID_ONLY') {
    return (
      <Stack gap="8">
        <Text textStyle="heading6">We need to verify your identity</Text>
        <AplyIdLogoImg src={aplyIdLogo} alt="aply-id" />
        <Text>
          Through our partnership with APLYiD we can verify your identity digitally and it only takes a couple of
          minutes.
        </Text>
        <Text>You can do this with 2 easy steps using your mobile phone:</Text>
        <NumberList>
          <NumberListItem number={1}>
            <Text>Take a photo of your ID document</Text>
          </NumberListItem>
          <NumberListItem number={2}>
            <Text>Take a photo of yourself</Text>
          </NumberListItem>
        </NumberList>
        <Text>Once you've completed the verification process we'll be in contact with you.</Text>
        <Link newTab appearance="primary-button" variant="blue-button" href={linkHref} onClick={linkOnClick}>
          {linkLabel}
        </Link>
        <Text>
          Alternatively, you can call us on <PhoneNumberLink id="originationsTeam" />
        </Text>
      </Stack>
    );
  }

  if (type === 'DOC_POIAML') {
    return (
      <Stack gap="8">
        <AplyIdLogoImg src={aplyIdLogo} alt="aply-id" />
        <Text>
          Through our partnership with APLYiD we can verify your identity digitally and it only takes a couple of
          minutes. You can do this with 2 easy steps using your mobile phone:
        </Text>
        <NumberList>
          <NumberListItem number={1}>
            <Text>Take a photo of your ID document</Text>
          </NumberListItem>
          <NumberListItem number={2}>
            <Text>Take a photo of yourself</Text>
          </NumberListItem>
        </NumberList>
        <Text>Once you've completed the verification process we'll be in contact with you.</Text>
        <Link newTab appearance="primary-button" variant="blue-button" href={linkHref} onClick={linkOnClick}>
          {linkLabel}
        </Link>
        <Text>
          Alternatively, if you have a smart phone and current NZ Passport or drivers licence, you can call us on{' '}
          <PhoneNumberLink id="originationsTeam" />
          {` `}
          to complete your ID verification digitally and talk further about your income.
        </Text>
      </Stack>
    );
  }
  return null;
}

export { AplyIdSteps };
