import { isFeatureOn } from 'featureToggles';
import {
  yesNoOptions,
  industryOptionsNZ,
  occupationOptions,
  employmentStatusOptionsNZ,
  employmentStatusLabelsByValueNZ,
  numbersOnlyPattern,
  isRentalOrInvestment,
  isSelfEmployedByStatus,
  isEligibleForBankConnect,
  isSuperOrGov,
} from '../_constants';
import { validEmployerNamePattern } from './constants';

const incomeValidationMessages = {
  required: 'Please enter your income after tax.',
  minLength: 'Must have at least {{minLength}} characters.',
  maxLength: 'Must have at most {{maxLength}} characters',
  invalid: 'Please enter a whole dollar amount, without cents.',
};

const lengthOfTimeAtCurrentEmployerMessages = {
  required: 'Please enter your length of time employed.',
  invalid: 'Please enter numbers only.',
  monthsError: 'Please enter the number of months at your current employer. If less than 1 month, enter 0.',
  yearsError: 'Please enter the number of years at your current employer.',
  monthRangeError: 'Months must be within {{minMonths}}-{{maxMonths}}.',
  yearRangeError: 'Years must be within {{minYears}}-{{maxYears}}.',
};
const isSelfEmployedLengthOfTimeWithCurrentBusinessMessages = {
  required: 'Please enter your length of time with this business.',
  invalid: 'Please enter numbers only.',
  monthsError: 'Please enter the number of months with this business. If less than 1 month, enter 0.',
  yearsError: 'Please enter the number of years with this business.',
  monthRangeError: 'Months must be within {{minMonths}}-{{maxMonths}}.',
  yearRangeError: 'Years must be within {{minYears}}-{{maxYears}}.',
};

const employerNameMessages = {
  required: "Please enter your employer's name.",
  invalid: 'Please enter alphanumeric characters only.',
  minLength: 'Must have at least {{minLength}} characters.',
  maxLength: 'Must have at most {{maxLength}} characters',
};

const validationMessages = {
  income: incomeValidationMessages,
  lengthOfTimeAtCurrentEmployer: lengthOfTimeAtCurrentEmployerMessages,
  employerName: employerNameMessages,
};
const selfEmployedEmployerNameMessages = {
  required: 'Please enter your business name.',
  invalid: 'Please enter alphanumeric characters only.',
  minLength: 'Must have at least {{minLength}} characters.',
  maxLength: 'Must have at most {{maxLength}} characters',
};

const hasEmployerByOccupation = values => {
  const { occupation } = values;
  const hasNoEmployer = ['UNEMPLOYED', 'STUDENT', 'SELF_EMPLOYED', 'RETIRED', 'BENEFIT'].includes(occupation);

  return !hasNoEmployer;
};

const getYearlyIncome = income => {
  if (!income) {
    return 0;
  }

  const { amount, frequency } = income;
  switch (frequency) {
    case 'quarterly':
      return amount * 4;
    case 'monthly':
      return amount * 12;
    case 'fortnightly':
      return amount * 26;
    case 'weekly':
    default:
      return amount * 52;
  }
};

const getTotalYearlyIncome = data => {
  const income = getYearlyIncome(data.income);
  const otherIncome = getYearlyIncome(data.otherIncome);

  return income + otherIncome;
};

export const validateExpectedIncomeChangeConfig = () => {
  let initialIncome;

  return {
    onMount: values => {
      initialIncome = getTotalYearlyIncome(values);
    },
    onUnmount: () => {
      initialIncome = undefined;
    },
    onValidate: values => {
      const currentIncome = getTotalYearlyIncome(values);
      const offset = currentIncome - initialIncome;
      return offset < 0;
    },
    onValidationChange: (isValid, formData) => {
      if (isValid) {
        formData.setValues(values => ({
          ...values,
          expectedIncomeChange: 'no',
        }));
      }
    },
  };
};

export const employmentDetails = [
  {
    component: 'Select',
    name: 'employmentStatus',
    label: "What's your main source of income?",
    options: employmentStatusOptionsNZ,
    testId: 'employment-status',
    validate: {
      messages: {
        required: 'Please select your main source of income',
      },
    },
  },
  {
    condition: values => !!values.employmentStatus,
    name: 'hasEmploymentStatus',
    fields: [
      {
        condition: values => !isSuperOrGov(values.employmentStatus) && !isRentalOrInvestment(values.employmentStatus),
        name: 'hasOccupation',
        fields: [
          {
            component: 'Select',
            name: 'occupation',
            label: 'Occupation',
            options: occupationOptions,
            testId: 'occupation',
            validate: {
              messages: {
                required: 'Please select your occupation.',
              },
            },
          },
          {
            condition: values => !isRentalOrInvestment(values.employmentStatus),
            component: 'Select',
            name: 'industry',
            label: 'Industry',
            options: industryOptionsNZ,
            testId: 'industry',
            validate: {
              messages: {
                required: 'Please select your industry.',
              },
            },
          },
          {
            condition: values =>
              hasEmployerByOccupation(values) &&
              !isSelfEmployedByStatus(values.employmentStatus) &&
              !isRentalOrInvestment(values.employmentStatus),
            component: 'Input',
            name: 'employerName',
            label: 'Employer name',
            testId: 'employer-name',
            validate: {
              props: {
                minLength: 1,
                maxLength: 30,
                validPattern: validEmployerNamePattern,
              },
              messages: validationMessages.employerName,
            },
          },
          {
            condition: values =>
              isSelfEmployedByStatus(values.employmentStatus) && !isRentalOrInvestment(values.employmentStatus),
            component: 'Input',
            name: 'employerName',
            label: 'What is the name of your business?',
            testId: 'employer-name',
            validate: {
              props: {
                minLength: 1,
                maxLength: 30,
                validPattern: validEmployerNamePattern,
              },
              messages: selfEmployedEmployerNameMessages,
            },
          },
        ],
      },
      {
        condition: values =>
          !['UNEMPLOYED', 'RETIRED', 'BENEFIT', 'STUDENT'].includes(values.occupation) &&
          !isSelfEmployedByStatus(values.employmentStatus),
        component: 'TimeSpan',
        name: 'lengthOfTimeAtCurrentEmployer',
        label: values => {
          if (['SUPERANNUATION', 'GOVERNMENT_BENEFIT'].includes(values.employmentStatus)) {
            return `How long have you been receiving this ${employmentStatusLabelsByValueNZ[values.employmentStatus]}?`;
          }
          if (['ACC', 'RENTAL_INCOME', 'INVESTMENT'].includes(values.employmentStatus)) {
            return `How long have you been receiving this income?`;
          }
          return 'Length of time at employer';
        },
        testId: 'current-employer-length',
        validate: {
          props: { minYears: 0, maxYears: 99, minMonths: 0, maxMonths: 11, validPattern: numbersOnlyPattern },
          messages: validationMessages.lengthOfTimeAtCurrentEmployer,
          component: 'TimeSpan',
        },
      },
      {
        condition: values => isSelfEmployedByStatus(values.employmentStatus),
        component: 'TimeSpan',
        name: 'lengthOfTimeAtCurrentEmployer',
        label: 'Length of time at business',
        testId: 'current-employer-length',
        validate: {
          props: { minYears: 0, maxYears: 99, minMonths: 0, maxMonths: 11, validPattern: numbersOnlyPattern },
          messages: isSelfEmployedLengthOfTimeWithCurrentBusinessMessages,
          component: 'TimeSpan',
        },
      },
      {
        condition: values => !isFeatureOn('incomeReplay') || !isEligibleForBankConnect(values.employmentStatus),
        component: 'Frequency',
        name: 'income',
        label: 'How much do you earn after tax?',
        mode: 'select',
        amountPrefix: '$',
        annually: false,
        testId: 'income',
        validate: {
          props: { minLength: 1, maxLength: 6, validPattern: numbersOnlyPattern },
          messages: validationMessages.income,
          component: 'Frequency',
        },
      },
      {
        component: 'RadioGroup',
        name: 'hasOtherIncome',
        label: 'Do you have another source of income?',
        testId: 'has-other-income',
        options: yesNoOptions,
        showCircles: true,
        validate: {
          messages: {
            required: 'Please select yes or no.',
          },
        },
      },
      {
        condition: values => values.hasOtherIncome === 'yes',
        name: 'otherIncomeGroup',
        fields: [
          {
            condition: values => !isFeatureOn('incomeReplay') || !isEligibleForBankConnect(values.employmentStatus),
            component: 'Frequency',
            name: 'otherIncome',
            label: 'How much do you earn after tax?',
            mode: 'select',
            amountPrefix: '$',
            annually: false,
            testId: 'other-income',
            validate: {
              props: { minLength: 1, maxLength: 6, validPattern: numbersOnlyPattern },
              messages: validationMessages.income,
              component: 'Frequency',
            },
          },
          {
            component: 'Select',
            name: 'otherEmploymentStatus',
            label: "What's your other source of income?",
            options: employmentStatusOptionsNZ,
            testId: 'other-employment-status',
            validate: {
              messages: {
                required: 'Please select your main source of income',
              },
            },
          },
        ],
      },
      {
        condition: values =>
          !isFeatureOn('incomeReplay') || (!isEligibleForBankConnect(values.employmentStatus) && values.hasOtherIncome),
        component: 'RadioGroup',
        name: 'expectedIncomeChange',
        label: 'Are you aware of any changes in the near future that may reduce your income?',
        testId: 'has-income-changes',
        options: yesNoOptions,
        showCircles: true,
        validate: {
          messages: {
            required: 'Please select yes or no.',
          },
        },
      },
      {
        condition: values => values.expectedIncomeChange === 'yes' && values.income.amount,
        component: 'ConditionalStop',
        name: 'conditionalStop',
        testId: 'income-adjustment-required',
        title: 'Please adjust your income',
        subtitle: 'Please adjust your income to the expected lower amount in the income fields above.',
        ...validateExpectedIncomeChangeConfig(),
      },
    ],
  },
];
