import isDate from 'lodash/isDate';

const MILLISECONDS_IN_DAY = 1000 * 60 * 60 * 24;

export const getDaysBetweenDates = (startDate, endDate) => {
  if (!isDate(startDate) || !isDate(endDate)) {
    return 0;
  }

  const diff = endDate.getTime() - startDate.getTime();

  if (diff < 0) {
    return 0;
  }

  return Math.floor(diff / MILLISECONDS_IN_DAY);
};
