export const formatNumber = value => {
  if (!isFinite(value) || !value) {
    return value;
  }
  return new Intl.NumberFormat('en', {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(value);
};

export const cleanNumberFormat = stringNumber => {
  if (!stringNumber) {
    return stringNumber;
  }
  const num = stringNumber.replace(new RegExp('\\,', 'g'), '');
  return isFinite(stringNumber) ? String(parseFloat(stringNumber.replace(new RegExp('\\,', 'g'), ''))) : num;
};
