import React from 'react';
import { Button, Container, Text } from 'basis';
import { useTheme } from '@emotion/react';
import { config, PRODUCT_CODE_DAVID_JONES_PRESTIGE } from '_config';
import {
  headerTextDavidJonesPremiere,
  headerTextDavidJonesPrestige,
  OverviewContent,
} from 'siteContent/overviewContent';
import { HeaderBannerTextStyle } from './LandingPageDJ.styles';
import { isNZ } from '../../utils';

const { headerIntroText } = OverviewContent[config.countryCode];

const HeaderBannerTextArea = ({ openFeatureAndBenefitsModal }) => {
  const theme = useTheme();
  const productCodeHeaderText = isNZ()
    ? OverviewContent.NZ.headerText
    : config.productCode === PRODUCT_CODE_DAVID_JONES_PRESTIGE
    ? headerTextDavidJonesPrestige
    : headerTextDavidJonesPremiere;
  return (
    <HeaderBannerTextStyle theme={theme}>
      <Container
        padding="0 4"
        padding-xs="0 6 6 5"
        padding-md="0 0 6 0"
        margin="0"
        margin-md="0 0 0 5"
        margin-lg="0 0 0 5"
        margin-xl="0 0 0 0"
        margin-sm="0 0 10 5"
        width="100%"
        width-xs="100%"
        width-sm="auto"
      >
        <Container margin="3 0 8 0">
          <Text
            as="h1"
            textStyle="heading3"
            textStyle-md="heading2"
            textStyle-lg="heading3"
            textStyle-xl="heading1"
            color="white"
          >
            {productCodeHeaderText}
          </Text>
        </Container>
        <Text margin="4 0">{headerIntroText}</Text>
        <Button
          width="208px"
          // width-xs="40%"
          // width-sm="40%"
          variant="secondary"
          color="white"
          onClick={openFeatureAndBenefitsModal}
        >
          View features and benefits
        </Button>
      </Container>
    </HeaderBannerTextStyle>
  );
};

export { HeaderBannerTextArea };
