import React from 'react';
import { Container, Grid, Link, Stack, Text, Icon, Flex } from 'basis';
import { LayoutPage } from 'components';
import AdrianaMartinezSignature from 'images/adriana_martinez.png';
import { davidJonesTheme } from '../../../../theme';
import { StyledDivSpaceBetween, StyledLeftDivWidth75, StyledDivAlignRight } from './Styles';

export function LowRateFinancialTable({
  ratesAndFees,
  statementDate,
  downloadOrPrintFile,
  productName,
  accountNumber,
  creditLimit,
  productCode,
}) {
  const {
    purchaseAnnualRate,
    cashAnnualRate,
    cashAdvanceFeePercentagePerTransaction,
    annualFee,
    lateFee,
    cashAdvanceFeeFixed,
    paperStatementFee,
    ausPostHandlingFee,
    onlinePaymentHandlingFee,
    minimumMonthlyPaymentAmount,
    minimumMonthlyPaymentRate,
    maximumBalanceTransferFeeRate,
    internationalTransactionFeeRate,
    internationalATMWithdrawFee,
    internationalCounterWithdrawFee,
    maximumCreditBalanceProcessingFee,
  } = ratesAndFees;

  return (
    <LayoutPage hideBackButton>
      <Container margin="0 0 0 0">
        <Container padding="5 0" padding-md="5" testId="LAYOUT FORM">
          <Grid preset="page" debug={false}>
            <Grid.Item colSpan="all">
              <Grid rowsGap={0}>
                <Container padding="4 4" margin="0 8">
                  <div style={{ marginBottom: '40px' }}>
                    <Link variant="secondary" id="pdfButton" onClick={downloadOrPrintFile}>
                      Download or Print file
                    </Link>
                  </div>
                  <Grid preset="page" colsGap={11}>
                    <Grid.Item colSpan="all">
                      <Grid rowsGap={1}>
                        <Container padding="0">
                          <div id="financial-table">
                            <Stack gap="1">
                              <Text
                                as="h1"
                                textStyle="heading5"
                                textStyle-md="heading4"
                                color={davidJonesTheme.headingColor}
                              >
                                {productName} Contract Schedule
                              </Text>
                              <Container padding="6 0 0 0">
                                <Text textStyle="heading5">Offer to New {productName} Account Holders</Text>
                              </Container>
                              <Text>
                                Latitude Finance Australia ABN 42 008 583 588 Australian Credit Licence number 392145,
                                as the credit provider, offers to provide you with a {productName} credit card and{' '}
                                account on the conditions set out in the Credit Contract.
                              </Text>
                              <Container padding="4 0">
                                <Text>Signed on behalf of Latitude Finance Australia.</Text>
                                <br />
                                <img src={AdrianaMartinezSignature} width="150px" alt="form-title-icon" />
                                <Text>
                                  <strong>Adriana Martinez</strong>
                                </Text>
                                <Text>Executive General Manager, Latitude Pay</Text>
                              </Container>
                              <Container>
                                <Text textStyle="heading3">Features of your {productName} credit card</Text>
                                <hr />
                                <Text>
                                  As per the Latitude Credit Card General Terms and Conditions, the following features
                                  are available through your {productName} credit card:
                                </Text>
                                <hr />
                                <StyledDivSpaceBetween>
                                  <StyledLeftDivWidth75>
                                    <Flex>
                                      <Icon name="cross-small" color="grey.t75" />
                                      <span style={{ fontWeight: 300, lineHeight: '32px' }}>
                                        Interest Free Payment Plans
                                      </span>
                                    </Flex>
                                  </StyledLeftDivWidth75>
                                  <StyledDivAlignRight>
                                    <Text>Not available</Text>
                                  </StyledDivAlignRight>
                                </StyledDivSpaceBetween>
                                <hr />
                                <StyledDivSpaceBetween>
                                  <StyledLeftDivWidth75>
                                    <Flex>
                                      <Icon name="cross-small" color="grey.t75" />
                                      <span style={{ fontWeight: 300, lineHeight: '32px' }}>
                                        Instalment Offer Plans (interest bearing)
                                      </span>
                                    </Flex>
                                  </StyledLeftDivWidth75>
                                  <StyledDivAlignRight>
                                    <Text>Not available</Text>
                                  </StyledDivAlignRight>
                                </StyledDivSpaceBetween>
                                <hr />
                                <StyledDivSpaceBetween>
                                  <StyledLeftDivWidth75>
                                    <Flex>
                                      <Icon name="tick-small" color="grey.t75" />
                                      <span style={{ fontWeight: 300, lineHeight: '32px' }}>
                                        International transactions
                                      </span>
                                    </Flex>
                                  </StyledLeftDivWidth75>
                                  <StyledDivAlignRight>
                                    <Text>Available</Text>
                                  </StyledDivAlignRight>
                                </StyledDivSpaceBetween>
                                <hr />
                              </Container>
                              <Container>
                                <Text textStyle="heading3">Financial Table</Text>
                                <hr />
                                <Text>
                                  The details below are correct as at the disclosure date. The financial table does not
                                  contain all of the pre-contractual information we are required by law to give you.
                                  Further terms and conditions (and an explanation of some of the words used in the
                                  financial table) are in the General Terms and Conditions. You must read this Contract
                                  Schedule together with the General Terms and Conditions.
                                </Text>
                              </Container>
                              <br />
                              <StyledDivSpaceBetween>
                                <Container>
                                  <Text textStyle="heading6">Account Number</Text>
                                </Container>
                                <Container>
                                  <Text textStyle="heading6">Disclosure Date</Text>
                                </Container>
                                <Text textStyle="heading6">Credit Limit</Text>
                                <Container>
                                  <Text textStyle="heading6">Credit sub-limit</Text>
                                </Container>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <Container>
                                  <data-hj-suppress>
                                    <Text>{accountNumber}</Text>
                                  </data-hj-suppress>
                                </Container>
                                <Container margin={0} margin-md="0 0 0 -11">
                                  <Text>{statementDate}</Text>
                                </Container>
                                <Container margin="0 10 0 0" padding="0 4 0 0">
                                  <data-hj-suppress>
                                    <Text>${creditLimit}</Text>
                                  </data-hj-suppress>
                                </Container>
                                <Container margin={0} margin-md="0 0 0 -11">
                                  <Text>Not Applicable</Text>
                                </Container>
                              </StyledDivSpaceBetween>
                              <br />
                              <Text>
                                <strong>Cash Limit</strong> <i>(refer to your statement)</i>
                              </Text>
                              <hr />
                              <Text>
                                A cash limit forms part of your credit limit and may be withdrawn in cash or cash{' '}
                                equivalent transactions. In each statement we give you, we may notify you of any cash{' '}
                                limit we impose, withdraw or change from time to time.
                              </Text>
                              <br />
                              <Text textStyle="heading5">Annual Percentage Rates</Text>
                              <hr />
                              <StyledDivSpaceBetween>
                                <Text>Purchase Rate - applies to the general purchase balance</Text>
                                <Text>{purchaseAnnualRate}%</Text>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <Text>Cash Advance Rate - applies to the cash advance balance</Text>
                                <Text>{cashAnnualRate}%</Text>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <Text>Balance Transfer Rate – applies to balance transfer offers</Text>
                                <Text>
                                  The Cash Advance Rate applies, unless a specific balance transfer rate is subsequently
                                  offered
                                </Text>
                              </StyledDivSpaceBetween>
                              <hr />
                              <Container margin="6 0">
                                <Text textStyle="heading5">Interest Free Period</Text>
                                <hr />
                                <StyledDivSpaceBetween>
                                  <Text>Up to 55 days</Text>
                                  <Text>Available on purchases of goods and services (not cash advances)</Text>
                                </StyledDivSpaceBetween>
                                <hr />
                              </Container>

                              <Container margin="6 0">
                                <Text textStyle="heading5">Payments Explained</Text>
                                <hr />
                                <StyledDivSpaceBetween>
                                  <Text>Minimum monthly payment</Text>
                                  <Text>
                                    The minimum monthly payment for a statement period is an amount equal to the <br />
                                    greater of ${minimumMonthlyPaymentAmount} or {minimumMonthlyPaymentRate}% of the{' '}
                                    closing balance, shown for the statement period on your <br />
                                    statement of account. If your outstanding balance is less than $25, you must pay{' '}
                                    that <br />
                                    balance.
                                  </Text>
                                </StyledDivSpaceBetween>
                                <hr />
                                <StyledDivSpaceBetween>
                                  <Text>Payment to reduce interest (Modified Closing Balance)</Text>
                                  <Text>
                                    The modified closing balance is the amount you need to pay by the due date to{' '}
                                    qualify <br />
                                    for interest free days on general purchases.
                                  </Text>
                                </StyledDivSpaceBetween>
                                <hr />
                              </Container>

                              <Container>
                                <Text textStyle="heading5">Fees and Charges</Text>
                                <hr />

                                <StyledDivSpaceBetween>
                                  <StyledLeftDivWidth75>
                                    <Text>A credit card fee will be charged to the account annually</Text>
                                  </StyledLeftDivWidth75>
                                  <div style={{ textAlign: 'right' }}>
                                    <Text>${annualFee?.toFixed(2)}</Text>
                                  </div>
                                </StyledDivSpaceBetween>
                                <hr />
                              </Container>

                              <StyledDivSpaceBetween>
                                <StyledLeftDivWidth75>
                                  <Text>
                                    A cash advance fee will be charged when we process each domestic or <br />
                                    international cash advance.
                                  </Text>
                                </StyledLeftDivWidth75>
                                <div style={{ textAlign: 'right' }}>
                                  <Text>
                                    Greater of ${cashAdvanceFeeFixed} or {cashAdvanceFeePercentagePerTransaction}% of{' '}
                                    the <br />
                                    cash advance amount
                                  </Text>
                                </div>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <Text>
                                  An international transaction fee of a specific percentage of the amount of the
                                  <br />
                                  international transaction (as converted to Australian dollars if not originally in
                                  <br />
                                  Australian dollars) will be charged when we process each international transaction.
                                </Text>
                                <div style={{ textAlign: 'right' }}>
                                  <Text>
                                    {internationalTransactionFeeRate?.toFixed(2)}% of the Australian dollar value of the
                                    <br />
                                    international transaction
                                  </Text>
                                </div>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <StyledLeftDivWidth75>
                                  <Text>
                                    An international ATM fee will be charged to the account in addition to the cash
                                    <br />
                                    advance fee on each occasion you obtain cash from an ATM outside Australia.
                                  </Text>
                                </StyledLeftDivWidth75>
                                <StyledDivAlignRight>
                                  <Text>${internationalATMWithdrawFee?.toFixed(2)}</Text>
                                </StyledDivAlignRight>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <StyledLeftDivWidth75>
                                  <Text>
                                    An international over the counter fee will be charged to the account in addition to
                                    <br />
                                    the cash advance fee on each occasion you obtain a cash advance or make a cash
                                    <br />
                                    equivalent transaction outside Australia other than from an ATM
                                  </Text>
                                </StyledLeftDivWidth75>
                                <StyledDivAlignRight>
                                  <Text>${internationalCounterWithdrawFee?.toFixed(2)}</Text>
                                </StyledDivAlignRight>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <StyledLeftDivWidth75>
                                  <Text>
                                    A balance transfer fee will be charged to the account if you take up a balance
                                    <br />
                                    transfer offer.
                                  </Text>
                                </StyledLeftDivWidth75>
                                <StyledDivAlignRight>
                                  <div style={{ textAlign: 'right' }}>
                                    <Text>
                                      Up to {maximumBalanceTransferFeeRate}% of balance transfer amount or in
                                      <br /> accordance with the specific balance <br />
                                      transfer offer
                                    </Text>
                                  </div>
                                </StyledDivAlignRight>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <StyledLeftDivWidth75>
                                  <Text>
                                    A late fee will be charged to the account in each statement period when any amount
                                    <br />
                                    due and payable to us is not paid in full by the due date.
                                  </Text>
                                </StyledLeftDivWidth75>
                                <StyledDivAlignRight>
                                  <Text>${lateFee}</Text>
                                </StyledDivAlignRight>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <StyledLeftDivWidth75>
                                  <Text>
                                    A paper statement fee will be charged for each paper statement of account sent to
                                    <br />
                                    you. This fee will not be charged if you consent to receiving your statements
                                    <br />
                                    electronically.
                                  </Text>
                                </StyledLeftDivWidth75>
                                <StyledDivAlignRight>
                                  <Text>${paperStatementFee?.toFixed(2)}</Text>
                                </StyledDivAlignRight>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <StyledLeftDivWidth75>
                                  <Text>
                                    A payment handling fee will be charged to your account each time you make a <br />
                                    payment to your account, except that no fee is payable for payments made by mail,
                                    <br /> by direct debit or via the Latitude Service Centre.
                                  </Text>
                                </StyledLeftDivWidth75>
                                <StyledDivAlignRight>
                                  <Text>${ausPostHandlingFee?.toFixed(2)} (in person)</Text>
                                  <Text>${onlinePaymentHandlingFee?.toFixed(2)} (online)</Text>
                                </StyledDivAlignRight>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <StyledLeftDivWidth75>
                                  <Text>
                                    A credit balance processing fee will be charged when your account is closed and{' '}
                                    <br />
                                    still holds a credit balance of less than $20.00; unless an instruction is provided
                                    <br />
                                    to us on how to return the credit balance to you.
                                  </Text>
                                </StyledLeftDivWidth75>
                                <StyledDivAlignRight>
                                  <Text>
                                    The fee amount will be the lesser of your balance or $
                                    {maximumCreditBalanceProcessingFee?.toFixed(2)}
                                  </Text>
                                </StyledDivAlignRight>
                              </StyledDivSpaceBetween>
                              <hr />
                              <Container>
                                <Text>
                                  These fees and charges will be charged and debited to your account and will form part{' '}
                                  of the closing balance which is used to calculate your minimum monthly payments.
                                </Text>
                              </Container>
                              <br />
                              <Container>
                                <Text textStyle="heading5">Changes to the Credit Contract</Text>
                              </Container>
                              <hr />
                              <Container>
                                <Text>
                                  Subject to applicable law, we may change the variable annual percentage rate(s),{' '}
                                  maximum interest free period, the amount, frequency or time for payment or method of{' '}
                                  calculation of a fee or charge or minimum monthly payment, reduce the credit limit, or{' '}
                                  impose new fees or charges without your consent where we have determined it is{' '}
                                  reasonably necessary to protect our legitimate interests. You will be notified of{' '}
                                  changes or new fees or charges in the manner set out in the General Terms and{' '}
                                  Conditions. If you are not happy with any changes made to your credit contract, you{' '}
                                  may close your credit contract in accordance with clause 28.6 of the General Terms and{' '}
                                  Conditions.
                                </Text>
                              </Container>
                              <br />
                              <Container>
                                <Text textStyle="heading5">Commission</Text>
                                <hr />
                                <Text>
                                  An unascertainable commission may be payable for the introduction of this credit{' '}
                                  business to any merchant and related entity of a merchant which is involved in the{' '}
                                  introduction.
                                </Text>
                                <hr />
                              </Container>
                            </Stack>
                          </div>
                        </Container>
                      </Grid>
                    </Grid.Item>
                  </Grid>
                </Container>
              </Grid>
            </Grid.Item>
          </Grid>
        </Container>
      </Container>
    </LayoutPage>
  );
}
