export function getNameOptions(storeState) {
  const { firstName, middleName: inputMiddleName, lastName } = storeState.acquisition.personalDetails;

  const names = [];
  names.push({ fullName: `${firstName} ${lastName}`, middleName: '' });

  if (inputMiddleName) {
    const middleNames = inputMiddleName.split(' ');
    const mnDisplays = getMiddleNameDisplays(middleNames);
    names.push(
      ...mnDisplays.map(middleName => {
        const fullName = `${firstName} ${middleName} ${lastName}`;
        return { fullName, middleName };
      }),
    );
  }

  return names.map(name => ({ value: JSON.stringify(name), label: name.fullName }));
}

function getMiddleNameDisplays(names) {
  return [...new Set(recursiveCombinations('', names))];
}

function recursiveCombinations(prefix, names) {
  if (!names.length) {
    return [prefix.trim()];
  }

  const [first, ...rest] = names;
  const withInitial = recursiveCombinations(`${prefix} ${first.charAt(0)}`, rest);
  const withFull = recursiveCombinations(`${prefix} ${first}`, rest);
  return [...withInitial, ...withFull];
}
