import { useEffect } from 'react';
import { usePageTracking } from 'react-event-tracker';
import { useStoreValue } from 'store';
import { getDataLayerElements } from 'utils/getDataLayerElements';

import { config, PRODUCT_CODE_DAVID_JONES_PREMIERE, PRODUCT_CODE_DAVID_JONES_PRESTIGE } from '_config';
import { getProductInfo } from 'utils';

export const usePageTracker = (siteSubSection, pageTitle, isError) => {
  const [state] = useStoreValue();

  const trackDataLayerOnCompareProducts = () => {
    const elements = {
      product: {
        productId: [PRODUCT_CODE_DAVID_JONES_PREMIERE, PRODUCT_CODE_DAVID_JONES_PRESTIGE],
      },
    };
    return pageTitle === 'davidjones-product-comparison' ? elements : '';
  };

  const { trackPageView } = usePageTracking(
    {
      page: {
        pageType: isError ? 'error-page' : 'funnel-page',
        siteSection: config.productCode ? getProductInfo().datalayer.siteSection : 'dj',
        siteSubSection,
        pageTitle,
      },
      product: {
        productId: config.productCode ? [config.productCode] : null,
      },
      ...getDataLayerElements(state),
      ...trackDataLayerOnCompareProducts(),
    },
    {
      trackPageViewByDefault: false,
    },
  );

  useEffect(() => {
    trackPageView();
  }, [trackPageView, pageTitle]);

  return null;
};
