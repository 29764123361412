import React, { useCallback } from 'react';
import { Link } from 'basis';
import { useEventTracking } from 'react-event-tracker';

/**
 * This is Tealium trackable URL link component that should be used
 * for all internal links, i.e. target href is within Latitude domain.
 *
 * The generated HREF will contain Tealium query string parameter 'gemid2'
 */
export function InternalLink({ label, url, newTab = false, eventConfig: { category, action, location } }) {
  const { trackEvent, getQueryString } = useEventTracking();

  const trackEventSender = useCallback(() => {
    trackEvent({
      event: {
        category,
        action,
        location,
        label,
      },
    });
  }, [action, category, label, location, trackEvent]);

  const gemid2 = getQueryString({
    event: {
      category,
      action,
      location,
      label,
    },
  });
  const enhancedUrl = new URL(url);
  enhancedUrl.searchParams.set('gemid2', gemid2);

  return (
    <Link href={enhancedUrl.href} newTab={newTab} onClick={trackEventSender}>
      {label}
    </Link>
  );
}
