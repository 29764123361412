import React from 'react';
import { Text, Stack, Container } from 'basis';
import { initFormConfig } from 'utils';
import { DvsConsent } from 'components/idv-consent/DvsConsent';

export const initialValues = {
  dvsConsent: false,
};

export const validationMessages = {
  income: {
    required: 'Please enter your income after tax.',
    minLength: 'Must have at least {{minLength}} characters.',
    maxLength: 'Must have at most {{maxLength}} characters',
    invalid: 'Please enter a whole dollar amount, without cents.',
  },
};

export const getFormConfig = () =>
  initFormConfig([
    {
      component: 'InfoBlock',
      name: 'intro',
      children: (
        <Container padding="2 0 8 0">
          <Stack gap="5">
            <Container>
              <Text>At the start of your application we asked permission to verify your identity online.</Text>
              <Text margin="8 0 1 0">
                <b>Are you sure you don’t want to verify your identity using an identity document?</b>
              </Text>
              <Text>
                If you choose not to consent we will attempt to verify you using alternative electronic means.
              </Text>
            </Container>
          </Stack>
        </Container>
      ),
    },
    {
      component: 'CustomComponent',
      name: 'dvsConsent',
      children: DvsConsent,
    },
  ]);
