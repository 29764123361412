import React from 'react';
import { Container, Text, Stack } from 'basis';
import { LayoutContent, LayoutPage } from 'components';
import styled from '@emotion/styled';

import confettiTL from 'images/confetti-TL.svg';
import confettiTR from 'images/confetti-TR.svg';
import confettiBR from 'images/confetti-BR.svg';
import confettiBL from 'images/confetti-BL.svg';
import { useTheme } from '@emotion/react';
import { useQueryParam } from 'hooks';
import { byCountry } from 'utils';
import { setConfig } from '_config';

const confetti = {
  TL: confettiTL,
  TR: confettiTR,
  BR: confettiBR,
  BL: confettiBL,
};

const PageContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  background-color: ${p => p.backgroundColor};
`;

const ShadowContainer = styled.div`
  position: relative;
  max-width: 680px;
  margin: 0 auto;

  &:before {
    content: '';
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 20px;
    left: 20px;
    box-sizing: border-box;
    border: ${p => `40px solid ${p.borderColor}`};
  }

  & > div:last-of-type {
    position: relative;
    border: none;
  }
  margin-bottom: '140px';
  &:before {
    display: block;
  }
  & > div:last-of-type {
    margin: 0 0 20px;
  }
`;

const Confetti = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
`;

const ConfettiPiece = styled.img`
  position: absolute;
  ${p => {
    switch (p.align) {
      case 'TL':
        return `top: -33px; left: -6px; transform: translate(-100%, -10%); width: 18%; height: 18%;`;
      case 'TR':
        return `top: -28px; right: -10px; transform: translate(100%, -20%); width: 18%; height: 18%;`;
      case 'BR':
        return `bottom: -20px; right: -10px; transform: translate(100%, 100%); width: 18%; height: 18%;`;
      case 'BL':
        return `bottom: -20px; left: 0; transform: translate(-100%, 100%); width: 18%; height: 18%;`;
      default:
        return '';
    }
  }}
`;

export const BiometricsComplete = () => {
  const productCode = useQueryParam('productCode');
  if (productCode) {
    setConfig({ productCode });
  }

  const Component = byCountry({
    AU: AuBiometricsComplete,
    NZ: NzBiometricsComplete,
  });

  return <Component />;
};

const AuBiometricsComplete = () => {
  const theme = useTheme();
  return (
    <LayoutPage headerAlign="center" noProgressStepper>
      <LayoutContent>
        <Container margin="14 0 0 0">
          <Stack gap="8">
            <Text as="h1" textStyle="heading5" textStyle-md="heading4" color={theme.headingColor}>
              Your facial verification is complete
            </Text>
            <Text>
              To view the status of your application, simply navigate back to your Latitude credit card application in
              your browser tab or alternatively check your email for an update.{' '}
            </Text>
            <Text>You can close this window.</Text>
          </Stack>
        </Container>
      </LayoutContent>
    </LayoutPage>
  );
};

const NzBiometricsComplete = () => {
  const theme = useTheme();

  return (
    <LayoutPage headerAlign="center" noProgressStepper>
      <PageContainer backgroundColor={theme.approvedBackgroundColor}>
        <Container margin="14 12">
          <ShadowContainer borderColor={theme.approvedBorderColor}>
            <Confetti>
              {Object.keys(confetti).map(key => (
                <ConfettiPiece key={key} src={confetti[key]} align={key} />
              ))}
            </Confetti>
            <Container padding="13 6" bg="white" hasBorder>
              <Stack gap="8">
                <Text as="h1" textStyle="heading5" textStyle-md="heading4" color={theme.headingColor}>
                  Thanks for completing your identity verification
                </Text>
                <Text>Return to your desktop computer to continue your application.</Text>
              </Stack>
            </Container>
          </ShadowContainer>
        </Container>
      </PageContainer>
    </LayoutPage>
  );
};
