export const occupationOptions = [
  {
    value: 'ACCOUNTANT_FIN_ANALYST',
    label: 'Accountant/Fin.Analyst',
  },
  {
    value: 'ADMINISTRATION',
    label: 'Administration',
  },
  {
    value: 'ASSISTANT',
    label: 'Assistant',
  },
  {
    value: 'BENEFIT',
    label: 'Benefit',
  },
  {
    value: 'CLEANER',
    label: 'Cleaner',
  },
  {
    value: 'CLERICAL_WORK',
    label: 'Clerical Work',
  },
  {
    value: 'COURIER_DRIVER',
    label: 'Courier Driver',
  },
  {
    value: 'ENGINEER',
    label: 'Engineer',
  },
  {
    value: 'FACTORY_HAND',
    label: 'Factory Hand',
  },
  {
    value: 'GOVERNMENT_DEFENCE',
    label: 'Government/Defence',
  },
  {
    value: 'HOME_DUTIES',
    label: 'Home Duties',
  },
  {
    value: 'HOSPITALITY',
    label: 'Hospitality',
  },
  {
    value: 'IT',
    label: 'IT',
  },
  {
    value: 'LABOURER',
    label: 'Labourer',
  },
  {
    value: 'LEGAL',
    label: 'Legal',
  },
  {
    value: 'MACHINE_DRIVER_OPERATOR',
    label: 'Machine Driver/Operator',
  },
  {
    value: 'MANAGER',
    label: 'Manager',
  },
  {
    value: 'PROFESSIONAL',
    label: 'Professional',
  },
  {
    value: 'QUALIFIED_TRADESPERSON',
    label: 'Qualified Tradesperson',
  },
  {
    value: 'RETAIL',
    label: 'Retail',
  },
  {
    value: 'RETIRED',
    label: 'Retired',
  },
  {
    value: 'SALES_ASSISTANT',
    label: 'Sales Assistant',
  },
  {
    value: 'SALES_REPRESENTATIVE',
    label: 'Sales Representative',
  },
  {
    value: 'SELF_EMPLOYED',
    label: 'Self Employed',
  },
  {
    value: 'STORE_PERSON',
    label: 'Store Person',
  },
  {
    value: 'STUDENT',
    label: 'Student',
  },
  {
    value: 'TEACHER',
    label: 'Teacher',
  },
  {
    value: 'TECHNICIAN',
    label: 'Technician',
  },
  {
    value: 'UNEMPLOYED',
    label: 'Unemployed',
  },
];
