import React, { useCallback, useMemo } from 'react';
import { useStoreValue } from 'store';
import { submitHandler } from 'utils';
import { STEP_PROCESSING_IDV } from '_config';
import { LayoutPage, FormRenderer, LayoutContent, Errors, Form, SaveAndCompleteLaterButton } from 'components';
import { Container, Button, Grid } from 'basis';
import { useHistory } from 'react-router-dom';
import { useUpdateDVSConsent, useDataLayerAndAnalyticsLinks } from 'hooks';
import { initialValues, getFormConfig } from './formConfig';

export const EIDVConsent = () => {
  const history = useHistory();
  const [storeState, updateStore] = useStoreValue();
  const { trackEventSender } = useDataLayerAndAnalyticsLinks(storeState);
  const formConfig = useMemo(() => getFormConfig(storeState), [storeState]);
  const { sendRequest, loading } = useUpdateDVSConsent();
  const onSubmit = useCallback(
    ({ values, errors }) => {
      const postAndRedirect = async () => {
        const result = await sendRequest(values);
        const nextStep = STEP_PROCESSING_IDV;
        updateStore({
          applicationErrors: null,
          applicationStatus: result.status,
          activeStep: nextStep,
        });
        history.push(nextStep);
      };

      trackEventSender(
        {
          category: 'application',
          action: 'application-navigation',
          location: 'dvs-consent',
          label: 'Next',
        },
        { applicationStatus: storeState.applicationStatus },
      );

      submitHandler({ submit: postAndRedirect, errors });
    },
    [history, sendRequest, storeState.applicationStatus, trackEventSender, updateStore],
  );

  if (storeState.applicationErrors) {
    return (
      <LayoutPage>
        <Errors />
      </LayoutPage>
    );
  }

  return (
    <LayoutPage>
      <Container margin="0 0 13 0" hasBreakpointWidth>
        <Form
          id="eIDVConsent"
          key="eIDVConsent"
          initialValues={initialValues}
          onSubmit={onSubmit}
          submitButtonLabel="Next"
          showSaveDraftButton
          saveDraftOnSubmit
          saveDraftOnSessionTimeout
          disableFormCache
          hideFormButtons
        >
          {formData => {
            return (
              <>
                <LayoutContent rowsGap="0" noSessionTimeout>
                  <Container>
                    <FormRenderer config={formConfig} formData={formData} />
                  </Container>
                  <Grid rowsGap="4" colsGap="10" cols="2" margin="8 0 0 0">
                    <Grid.Item colSpan="all" colSpan-sm="0" alignItems="center">
                      <Grid>
                        <Button width="100%" type="submit" loading={loading}>
                          Next
                        </Button>
                      </Grid>
                    </Grid.Item>
                    <Grid.Item colSpan="all" rowSpan="1" colSpan-sm="1" rowSpan-sm="0">
                      <Grid>
                        <SaveAndCompleteLaterButton />
                      </Grid>
                    </Grid.Item>
                  </Grid>
                </LayoutContent>
              </>
            );
          }}
        </Form>
      </Container>
    </LayoutPage>
  );
};
