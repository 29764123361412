export const getDaysInMonth = (month, year) => {
  const isLeapYear = new Date(year, 1, 29).getDate() === 29;

  const monthLength = {
    1: 31,
    2: isLeapYear ? 29 : 28,
    3: 31,
    4: 30,
    5: 31,
    6: 30,
    7: 31,
    8: 31,
    9: 30,
    10: 31,
    11: 30,
    12: 31,
  };

  return monthLength[month];
};
