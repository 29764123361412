import React from 'react';
import { Container } from 'basis';
import { Text } from 'components/text';

export const InfoBlock = props => {
  const { padding, title, titleStyle, children, text, images, color } = props;
  return (
    <Container padding={padding}>
      {title && (
        <Text as="h4" textStyle={titleStyle || 'heading6'} color={color || 'black'}>
          {title}
        </Text>
      )}
      {children}
      {text && <Text as="p">{text}</Text>}
      {images && (
        <Container hasBreakpointWidth>
          {images.map(({ url, alt, ...rest }) => (
            <img key={url} src={url} alt={alt} {...rest} />
          ))}
        </Container>
      )}
    </Container>
  );
};
