import React from 'react';
import { Container, Grid, Flex } from 'basis';

import StufflayoutBag from 'images/stufflayout-bag.png';
import StufflayoutCouch from 'images/stufflayout-couch.png';
import StufflayoutGlasses from 'images/stufflayout-glasses.png';
import StufflayoutHeadphone from 'images/stufflayout-headphone.png';
import StufflayoutShoes from 'images/stufflayout-shoes.png';
import StufflayoutWatch from 'images/stufflayout-watch.png';
import { LayoutImageIcons } from '../Outcome.styles';

const StufflayoutContent = [
  { link: StufflayoutHeadphone, description: 'StufflayoutHeadphone', colSpan: '0-3' },
  { link: StufflayoutWatch, description: 'StufflayoutWatch', colSpan: '4-7' },
  { link: StufflayoutGlasses, description: 'StufflayoutGlasses', colSpan: '8-11' },
  { link: StufflayoutBag, description: 'StufflayoutBag', colSpan: '0-3' },
  { link: StufflayoutShoes, description: 'StufflayoutShoes', colSpan: '4-7' },
  { link: StufflayoutCouch, description: 'StufflayoutCouch', colSpan: '8-11' },
];

const StuffLayout = () => {
  return (
    <Container padding="4 0 10" padding-sm="2 0 6" padding-md="4 0 10" padding-lg="4 0 10">
      <Grid rowsGap={6} rowsGap-md={10} rowsGap-lg={10}>
        {StufflayoutContent.map((item, key) => {
          return (
            <Grid.Item key={key} colSpan={item.colSpan}>
              <Flex placeItems="center">
                <LayoutImageIcons src={item.link} alt={item.description} />
              </Flex>
            </Grid.Item>
          );
        })}
      </Grid>
    </Container>
  );
};

export { StuffLayout };
