import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useHandleApiErrors } from './useHandleApiErrors';

export const ACCEPT_PCA_CONTRACT = gql`
  mutation acceptPCAContract($input: AcceptPCAContractInput!) {
    acceptPCAContract(input: $input) {
      applicationId
    }
  }
`;

export function useAcceptPcaContract({ applicationId, onSuccess, onError }) {
  const { handleError } = useHandleApiErrors('apollo', onError);
  const [acceptPcaContract, { loading }] = useMutation(ACCEPT_PCA_CONTRACT, {
    onCompleted: data => {
      onSuccess(data.acceptPCAContract);
    },
    onError: error => {
      handleError(error);
    },
  });

  const submit = useCallback(
    formValues => {
      const input = { applicationId, ...formValues };
      acceptPcaContract({ variables: { input } });
    },
    [applicationId, acceptPcaContract],
  );

  return {
    submit,
    loading,
  };
}
