import React from 'react';
import { ReturnButton } from 'components';
import {
  PROOF_OF_INCOME_LIST,
  UPLOAD_BTN_LABEL,
  PROOF_OF_INCOME_LIST_ACCORDION,
} from '_config/_constants/documentUpload';
import { Accordion, Container, Grid, Text, Message } from 'basis';
import { useTheme } from '@emotion/react';
import { List, CustomWrapper } from '../Outcome.styles';
import { UploadDocumentButton } from '../components/UploadDocumentButton';

const UploadDocuments = ({ storeState }) => {
  const theme = useTheme();
  const { applicationRef } = storeState;

  return (
    <Container padding="8 0 13 0" padding-md="12 0 13 0" margin="0 8">
      <Container hasBreakpointWidth>
        <Grid preset="page" colsGap={12}>
          <Grid.Item colSpan="all" colSpan-sm="1-6" colSpan-md="1-6" colSpan-lg="2-9" colSpan-xl="2-9">
            <Grid rowsGap={8}>
              <Text as="h1" textStyle="heading5" textStyle-md="heading4" color={theme.headingColor}>
                You're nearly there!
              </Text>

              <Text>
                Your application number is <b>{applicationRef}</b>
              </Text>

              <Text as="h1" textStyle="heading5" color={theme.headingColor}>
                Upload proof of income
              </Text>

              <Message
                severity="info-or-minor"
                bg="secondary.lightBlue.t25"
                title="All you need to do to complete your application is upload your proof of income."
              >
                Once you’ve uploaded your documents, our team will review your application and be in touch within 2
                days.
              </Message>

              <Text>
                The most common way to do this is upload either bank statements, IRD summary of earnings or financial
                statements.
              </Text>

              <Text>
                <b>See the full list of documents we accept relating to your type of income, below:</b>
              </Text>

              <Accordion>
                {PROOF_OF_INCOME_LIST_ACCORDION.map(accordion => {
                  return (
                    <Accordion.Item>
                      <CustomWrapper>
                        <Accordion.Item.Header>{accordion.accordionTitle}</Accordion.Item.Header>
                      </CustomWrapper>
                      <Accordion.Item.Content padding="6">
                        {accordion.accordionData.map((data, key) => {
                          if (data.description) {
                            return (
                              <Container key={key} padding="4 0">
                                <Text key={key}>{data.description}</Text>
                              </Container>
                            );
                          }
                          return (
                            <Container key={key} padding="4 0 0 0">
                              <Text>
                                <b>{data.listTitle}</b>
                              </Text>
                              {data.listItem && (
                                <List marginTop="10" noPadding>
                                  {data.listItem.map((item, itemKey) => (
                                    <li key={itemKey}>{item}</li>
                                  ))}
                                </List>
                              )}
                              {key !== PROOF_OF_INCOME_LIST.length - 1 && <br />}
                            </Container>
                          );
                        })}
                      </Accordion.Item.Content>
                    </Accordion.Item>
                  );
                })}
              </Accordion>

              <Text>
                <b>You can upload all your documents through the same portal. </b>
              </Text>

              <UploadDocumentButton label={UPLOAD_BTN_LABEL} storeState={storeState} />
            </Grid>

            <Text margin="2 0" textStyle="legal">
              Files can be PDFs, photographs or scanned formats.{' '}
            </Text>

            <Container padding-md="2 0 10">
              <ReturnButton variant="text" />
            </Container>
          </Grid.Item>
        </Grid>
      </Container>
    </Container>
  );
};

export { UploadDocuments };
