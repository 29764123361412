import React from 'react';
import { Text, Stack } from 'basis';

export const MeaningOfTerms = () => (
  <Stack gap="3" gap-md="5">
    {words.map(w => (
      <div key={`term-${w.term}`}>
        <Text textStyle="heading6">{w.term}</Text>
        {typeof w.content === 'function' ? w.content() : <Text>{w.content}</Text>}
      </div>
    ))}
  </Stack>
);

const words = [
  {
    content: 'In your credit contract:',
  },
  {
    term: 'account',
    content: 'means your Gem Visa account;',
  },
  {
    term: 'additional cardholder',
    content: 'means a person issued with a card in accordance with clause 3;',
  },
  {
    term: 'ATM',
    content: 'means automatic teller machine;',
  },
  {
    term: 'balance transfer',
    content:
      'means a transaction where you ask us to debit your account with a specified amount and to pay that amount to another card issuer, financial institution or us, pursuant to a balance transfer offer;',
  },
  {
    term: 'balance transfer offer',
    content: 'means an offer made by us in relation to a balance transfer;',
  },
  {
    term: 'card',
    content:
      'means the Gem Visa credit card issued to you and to any additional cardholders on your account and includes any replacement cards. All cards are issued subject to your credit contract;',
  },
  {
    term: 'cardholder',
    content:
      'means the person to whom a card has been issued, including you and, unless the context otherwise requires, any additional cardholder;',
  },
  {
    term: 'cash advance',
    content: 'means any withdrawal of cash from your account or a cash equivalent transaction;',
  },
  {
    term: 'cash equivalent transaction',
    content: () => (
      <div>
        <Text>means a transaction involving the issue of substitutes for cash, including:</Text>
        <ol type="a">
          <li>
            <Text>the purchase of travellers cheques,</Text>
          </li>
          <li>
            <Text>
              any transaction in the nature of a wager, gaming, gambling transaction or game of chance, including a
              purchase of gambling chips or tokens or any lottery, raffle or sweepstake ticket,
            </Text>
          </li>
          <li>
            <Text>the purchase of, or loading of value onto, a pre-paid or stored-value card,</Text>
          </li>
          <li>
            <Text>
              any other transactions (including Cryptocurrency transactions), that we notify you will be treated as cash
              equivalent transactions, or
            </Text>
          </li>
          <li>
            <Text>any transactions which a merchant or card scheme identifies as a cash advance;</Text>
          </li>
        </ol>
      </div>
    ),
  },
  {
    term: 'CCCFA',
    content: 'means the Credit Contracts and Consumer Finance Act 2003 and all regulations under that Act;',
  },
  {
    term: 'closing balance',
    content:
      'means the amount shown on your monthly statements as the closing balance for the period to which the statement relates;',
  },
  {
    term: 'credit contract',
    content: 'means these Conditions of Use and the initial disclosure statement;',
  },
  {
    term: 'daily balance',
    content: 'means the balance of your account at the end of a day;',
  },
  {
    term: 'deferred payment advance',
    content: 'means any advance made on your account where a deferred payment period is applicable;',
  },
  {
    term: 'deferred payment period',
    content:
      'means the period during which, subject to clause 20.1, no interest will accrue and no monthly payments need be made on any deferred payment advance;',
  },
  {
    term: 'EFT',
    content:
      'means ‘Electronic Funds Transfer’, being the process by which funds are withdrawn electronically from your account. You authorise an EFT by using your card and PIN or signature at an EFT terminal, or in the case of a Visa payWave reader, through the use of your card;',
  },
  {
    term: 'EFT terminal',
    content:
      'means any terminal or device approved by us in which you may use your card and PIN. This may include ATMs and EFTPOS terminals;',
  },
  {
    term: 'EFTPOS',
    content:
      'means an ‘Electronic Funds Transfer at Point of Sale’ whereby funds are debited to your account at the merchant’s point of sale to pay for goods and services;',
  },
  {
    term: 'EFTPOS terminal',
    content: 'means an EFT terminal, other than an ATM, located at a merchant’s point of sale;',
  },
  {
    term: 'Gem Visa promotional purchase',
    content: 'means an interest free start-up advance carried out at an EFTPOS terminal;',
  },
  {
    term: 'Gem Visa Shopper’s Protection',
    content: 'means the optional consumer credit insurance which may be purchased by you from us;',
  },
  {
    term: 'initial disclosure statement',
    content:
      'means the statement entitled ‘Initial Disclosure Statement’ provided to you before you opened your account as required under the CCCFA;',
  },
  {
    term: 'instalment plan advance',
    content: () => (
      <div>
        <Text>
          means any advance made on your account which is to be repaid by fixed monthly payments over such period as may
          be agreed between us and you or any additional cardholder on your behalf. An instalment plan advance may be
          interest bearing or interest free. An instalment plan advance also falls within (a) or (b) as follows,
          depending on the period:
        </Text>
        <ol type="a">
          <li>
            <Text>
              Instalment plan advance with a period of less than 36 months. Additional features are described in clause
              2.7.
            </Text>
          </li>
          <li>
            <Text>Instalment plan advance with a period of 36 months or more.,</Text>
          </li>
        </ol>
      </div>
    ),
  },
  {
    term: 'international transaction',
    content:
      'means any transaction on your account denominated in a currency other than New Zealand dollars, or any transaction where Visa designates the supplier of the relevant goods or services to be located in a country other than New Zealand, regardless of whether the transaction is denominated in New Zealand dollars;',
  },
  {
    term: 'interest free start-up advance',
    content:
      'means any advance made on your account in relation to which an extended interest free start-up period is applicable and includes a Gem Visa promotional purchase;',
  },
  {
    term: 'interest free start-up period',
    content:
      'means the period during which, subject to clause 20.1, no interest will accrue on any interest free start-up advance;',
  },
  {
    term: 'PIN',
    content:
      'means the ‘Personal Identification Number’ selected by you and any additional cardholder, for use with cards in any EFT terminal;',
  },
  {
    term: 'Privacy Act',
    content: 'means the Privacy Act 1993;',
  },
  {
    term: 'promotional purchase',
    content: 'means an interest free start-up advance, a deferred payment advance, and an instalment plan advance;',
  },
  {
    term: 'purchase',
    content:
      'means a purchase of goods or services and includes a promotional purchase, a retail purchase, a funds transfer debited to your account including foreign remittances and any Gem Visa Shopper’s Protection premium, but excludes any cash advance cash equivalent transaction or balance transfer;',
  },
  {
    term: 'retail purchase',
    content: 'means any purchase other than a promotional purchase;',
  },
  {
    term: 'statement',
    content: 'means a monthly statement of account sent to you in accordance with your credit contract;',
  },
  {
    term: 'statement period',
    content: 'means the period of a statement as determined by us;',
  },
  {
    term: 'statemented',
    content: 'in relation to a transaction on your account means any transaction that is shown in a statement;',
  },
  {
    term: 'unauthorised transaction',
    content: 'means any transaction on your account made without your knowledge or consent;',
  },
  {
    term: 'un-statemented',
    content:
      'in relation to a transaction on your account means any transaction on your account that has not yet appeared on a statement.',
  },
  {
    term: 'Visa',
    content: 'means Visa International Service Association;',
  },
  {
    term: 'Visa payWave',
    content:
      'is a contactless means of authorising and carrying out retail purchases by waving your card in front of a Visa payWave reader at a merchant’s point of sale, without the need for a PIN or signature;',
  },
  {
    term: 'Visa payWave reader',
    content:
      'means an EFTPOS terminal equipped with a secure reader through which Visa payWave transactions may be effected;',
  },
  {
    term: 'working day',
    content: 'means a day other that a Saturday or Sunday or public holiday in New Zealand;',
  },
  {
    term: 'we, us or our',
    content: 'means Latitude Financial Services Limited;',
  },
  {
    term: 'you and your',
    content: 'means the account holder;',
  },
  {
    term: 'your personal information',
    content:
      'means any information about you that you provide to us and any information about you that we may hold now or in the future, which is ‘personal information’ as defined in the Privacy Act.',
  },
];
