// Source https://di.latitudefinancial.com/wiki/pages/viewpage.action?spaceKey=LFS&title=Activate+Additional+Info
export const industryOptionsNZ = [
  {
    value: 'ADVERTISING',
    label: 'Advertising',
  },
  {
    value: 'AGRI_FORST',
    label: 'Agri/Forst',
  },
  {
    value: 'ARMED_SERVICES',
    label: 'Armed Services',
  },
  {
    value: 'BANKING_FINANCE',
    label: 'Banking/Finance',
  },
  {
    value: 'CIVIL_SERVICE',
    label: 'Civil Service',
  },
  {
    value: 'CLEANING_LAUNDRY',
    label: 'Cleaning/Laundry',
  },
  {
    value: 'COMPUTER_STAFF',
    label: 'Computer Staff',
  },
  {
    value: 'CONSTRUCTION',
    label: 'Construction',
  },
  {
    value: 'EDUCATION',
    label: 'Education',
  },
  {
    value: 'EMERGENCY_SERVICES',
    label: 'Emergency Services',
  },
  {
    value: 'HOME_DUTIES',
    label: 'Home Duties',
  },
  {
    value: 'HOSPITAL',
    label: 'Hospital',
  },
  {
    value: 'HOTEL',
    label: 'Hotel',
  },
  {
    value: 'INDUSTRY_SERVICES',
    label: 'Industry Services',
  },
  {
    value: 'INSURANCE',
    label: 'Insurance',
  },
  {
    value: 'MANUFACTURING',
    label: 'Manufacturing',
  },
  {
    value: 'MEDIA',
    label: 'Media',
  },
  {
    value: 'MINING',
    label: 'Mining',
  },
  {
    value: 'OTHER',
    label: 'Other',
  },
  {
    value: 'PENSIONER',
    label: 'Pensioner',
  },
  {
    value: 'LEISURE_ENTERTAINMENT',
    label: 'Leisure/Entertainment',
  },
  {
    value: 'PROPERTY_BUSINESS',
    label: 'Property/Business',
  },
  {
    value: 'RESTAURANT',
    label: 'Restaurant',
  },
  {
    value: 'RETAIL_WHOLESALE',
    label: 'Retail/Wholesale',
  },
  {
    value: 'RETIRED',
    label: 'Retired',
  },
  {
    value: 'SCIENCES',
    label: 'Sciences',
  },
  {
    value: 'STUDENT',
    label: 'Student',
  },
  {
    value: 'TRANSPORT',
    label: 'Transport',
  },
  {
    value: 'UNEMPLOYED',
    label: 'Unemployed',
  },
  {
    value: 'SEASONAL',
    label: 'Seasonal',
  },
];

export const auNonGovIndustryOptions = [
  { value: 'ADVERTISING', label: 'Advertising' },
  { value: 'AGRI_FORST', label: 'Agri/Horticulture' },
  { value: 'ARMED_SERVICES', label: 'Armed Services' },
  { value: 'BANKING_FINANCE', label: 'Banking/Finance' },
  { value: 'CIVIL_SERVICE', label: 'Civil Service' },
  { value: 'CLEANING_LAUNDRY', label: 'Cleaning/Laundry' },
  { value: 'CONSTRUCTION', label: 'Construction' },
  { value: 'EDUCATION', label: 'Education' },
  { value: 'EMERGENCY_SERVICES', label: 'Emergency Services' },
  { value: 'HOSPITAL', label: 'Hospital' },
  { value: 'HOTEL', label: 'Hotel' },
  { value: 'INDUSTRY_SERVICES', label: 'Industry Services/Trade' },
  { value: 'INSURANCE', label: 'Insurance' },
  { value: 'COMPUTER_STAFF', label: 'IT' },
  { value: 'LEISURE_ENTERTAINMENT', label: 'Leisure/Entertainment' },
  { value: 'MANUFACTURING', label: 'Manufacturing' },
  { value: 'MEDIA', label: 'Media' },
  { value: 'MINING', label: 'Mining' },
  { value: 'PROPERTY_BUSINESS', label: 'Property/Business' },
  { value: 'RESTAURANT', label: 'Restaurant' },
  { value: 'RETAIL_WHOLESALE', label: 'Retail/Wholesale' },
  { value: 'SCIENCES', label: 'Sciences' },
  { value: 'TRANSPORT', label: 'Transport' },
  { value: 'OTHER', label: 'Other' },
];

export const auGovIndustryOptions = [
  { value: 'HOME_DUTIES', label: 'Home Duties' },
  { value: 'PENSIONER', label: 'Pensioner' },
  { value: 'RETIRED', label: 'Retired' },
  { value: 'STUDENT', label: 'Student' },
  { value: 'OTHER', label: 'Other' },
];
