export const VerifyBankStatementContent = {
  AU: {
    institutionsTextTitle: 'Check your income',
    institutionsTextIntro:
      'You’re almost there! We just need to verify a few things before we finalise your application.',
    verifyFiConnectButtonLabel: 'Verify online',
    labelSelectInstitution: 'Select the bank your income is paid into: ',
    textTermsAndConditions: "By clicking '{{buttonSubmit}}' you agree to the ",
    accountsTextInfo: 'Select account/s your income is paid into',
    optionNoIncome: 'I don’t see my income here',
    commonLinkSecureMessage: 'See how it works.',
    commonTextSecureMessageContent:
      "Latitude has partnered with Illion Bank Statements to securely provide digitally encrypted statements directly from your bank. It's a safe and secure service. Your login details are never stored and it is a one time access only. This is the fastest way to get a decision.",
    incomeButtonSubmit: 'Confirm income',
  },
  NZ: {
    institutionsTextTitle: 'Verify your income',
    institutionsTextIntro: 'Legislation requires us to verify your income.',
    pleaseSelectHeader: '',
    verifyFiConnectButtonLabel: 'Share bank statement',
    incomeButtonSubmit: 'Confirm and submit',
  },
};
