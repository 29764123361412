export const idTypeOptions = [
  {
    value: 'NZ_DRIVERS_LICENCE',
    label: 'NZ Drivers Licence',
  },
  {
    value: 'NZ_PASSPORT',
    label: 'NZ Passport',
  },
  {
    value: 'AU_PASSPORT',
    label: 'Australian/Cook Islands/Tokelau/Niue Passport',
  },
  {
    value: 'OTHER_PASSPORT',
    label: 'Other Passport',
  },
  {
    value: 'NZ_FIREARM_LICENCE',
    label: 'NZ Firearm Licence',
  },
];
