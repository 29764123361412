import React, { useEffect } from 'react';
import { LayoutPage, LayoutContent, FormRenderer, Form, Errors } from 'components';
import { useStoreValue, APOLLO_NETWORK_ERROR, APOLLO_UNKNOWN_ERROR } from 'store';
import { initFormConfig, submitHandler } from 'utils';
import { config } from '_config';
import { useEventTracking } from 'react-event-tracker';
import { getDataLayerElements } from 'utils/getDataLayerElements';
import { useFormInitialValues, useSteps, useSaveApplication, useErrorTrackingAndUpdateStore } from 'hooks';
import { usedBiometrics } from 'hooks/useSteps';

const FORM_ID = 'password';
const formConfig = initFormConfig(config.formConfigs[FORM_ID]);
export function VerbalPassword() {
  const [{ acquisition, ...state }] = useStoreValue();
  const initialValues = useFormInitialValues(FORM_ID);
  const { goNext, isLastDraftStep } = useSteps();
  const [storeState, updateStore] = useStoreValue();
  const { handleErrorCallback } = useErrorTrackingAndUpdateStore('Next');
  const { trackEvent } = useEventTracking();
  useEffect(() => {
    if (usedBiometrics(storeState)) {
      goNext({ dataLayerEventLabel: 'Skip due to completion of biometrics verification' });
    }
  });

  const { save, loading } = useSaveApplication({
    storeState,
    onSuccess: goNext,
    onError: handleErrorCallback,
  });
  const onSubmit = ({ values, errors }) => {
    const newAcquisition = {
      ...acquisition,
      password: { password: values.password },
    };

    function proceed() {
      updateStore({
        applicationSubmitted: isLastDraftStep,
        acquisition: newAcquisition,
      });

      trackEvent({
        event: {
          category: 'application',
          action: isLastDraftStep ? 'application-submitted' : 'application-navigation',
          location: 'password',
          label: 'Next',
        },
        ...getDataLayerElements({ acquisition: newAcquisition, ...state }),
      });

      if (isLastDraftStep) {
        save(newAcquisition);
      } else {
        goNext({ storePatch: { acquisition: newAcquisition } });
      }
    }

    submitHandler({
      submit: proceed,
      errors,
    });
  };

  const retrySubmit = () => {
    trackEvent({
      event: {
        category: 'application',
        action: 'application-submitted',
        location: 'password',
        label: 'Retry',
      },
    });

    save(acquisition);
  };

  if (state.applicationErrors) {
    const retry = [APOLLO_NETWORK_ERROR, APOLLO_UNKNOWN_ERROR].includes(state.applicationErrors.type)
      ? retrySubmit
      : null;
    return (
      <LayoutPage>
        <Errors applicationRef={state.applicationRef} retry={retry} retrying={loading} />
      </LayoutPage>
    );
  }

  return (
    <LayoutPage>
      <Form id={FORM_ID} initialValues={initialValues} onSubmit={onSubmit} loading={loading} hideFormButtons>
        {formData => {
          return (
            <LayoutContent>
              <FormRenderer config={formConfig} formData={formData} />
            </LayoutContent>
          );
        }}
      </Form>
    </LayoutPage>
  );
}
