import { useMemo } from 'react';
import { useStoreValue } from 'store';
import { useFormCache } from './useFormCache';

export function useFormInitialValues(id) {
  const [{ acquisition }] = useStoreValue();
  const { getFormCacheValues } = useFormCache();

  const initialValues = useMemo(() => {
    return {
      ...acquisition[id],
      ...getFormCacheValues(id),
    };
    // eslint-disable-next-line
  }, []);

  return initialValues;
}
