import initTealiumTracker from 'tealium-tracker';
import { byCountry } from 'utils';
import { setConfig } from '_config';
import { getDataLayer } from '../library/tealiumUtils';

const tealiumTracker = initTealiumTracker({
  debugMode: localStorage.getItem('tealiumDebugMode') === 'true',
});

const queryParams = new URLSearchParams(window.location.search);
const productCode = queryParams.get('productCode');
if (productCode) {
  setConfig({ productCode });
}

export const siteData = {
  page: {
    site: 'cc-funnel',
    siteCountry: byCountry({
      AU: 'au',
      NZ: 'nz',
    }),
    siteSubSection: '',
  },
};

export const trackingConfig = {
  siteData,
  eventTracking: {
    trackEvent: data => {
      tealiumTracker.trackEvent(getDataLayer(data));
    },
    getQueryString: ({ pageData, eventData }) => {
      const details = {
        site: siteData.page.site,
        siteSection: pageData?.page.siteSection,
        siteSubSection: pageData?.page.siteSubSection,
        pageTitle: pageData?.page.pageTitle,
        buttonName: eventData?.event.label,
        location: eventData?.event.location,
      };

      return Object.keys(details)
        .filter(item => Boolean(details[item]))
        .map(key => encodeURIComponent(details[key]))
        .join(':');
    },
  },
  pageTracking: {
    trackPageView: data => {
      tealiumTracker.trackPageLoad(getDataLayer(data));
    },
  },
};
