import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { isAU } from 'utils/byConfig';
import { config } from '_config';
import { isFeatureOn } from 'featureToggles';
import { useStoreValue } from 'store';
import { useHandleApiErrors } from './useHandleApiErrors';

export const INIT_APPLICATION = gql`
  mutation initApplication($input: InitApplicationInput!) {
    initApplication(input: $input) {
      applicationId
      applicationRef
    }
  }
`;

function useInitApplication({ onSuccess, onError }) {
  const [storeState] = useStoreValue();
  const { handleError } = useHandleApiErrors('hook:useInitApplication', onError);

  const [initApplication, { loading }] = useMutation(INIT_APPLICATION, {
    onCompleted: data => {
      onSuccess(data.initApplication);
    },
    onError: err => {
      handleError(err);
    },
  });

  const submit = useCallback(
    formData => {
      if (formData) {
        const input = {
          channel: storeState?.applyAndBuy?.channel,
          merchantId: storeState?.applyAndBuy?.merchantId,
          applicationSource: isFeatureOn('balanceTransfer') ? storeState?.applyAndBuy?.sourceCode : undefined,
          promoCode: isFeatureOn('balanceTransfer') ? storeState?.applyAndBuy?.promoCode : undefined,
          consentGiven: formData.consentGiven,
          ...(isAU() ? { productCode: config.productCode } : {}),
        };
        initApplication({ variables: { input } }).then();
      }
    },
    [
      initApplication,
      storeState.applyAndBuy.channel,
      storeState.applyAndBuy.merchantId,
      storeState.applyAndBuy.promoCode,
      storeState.applyAndBuy.sourceCode,
    ],
  );

  return {
    submit,
    loading,
  };
}

export { useInitApplication };
