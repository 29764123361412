import { useCallback, useEffect, useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { useHandleApiErrors } from './useHandleApiErrors';

export const GET_APPLICATION_DRAFT_GQL = gql`
  query getApplicationDraft($id: ID!) {
    applicationDraft(id: $id)
  }
`;

export const useGetApplicationDraft = ({ applicationId, onSuccess, onError }) => {
  const [loaded, updateLoaded] = useState(false);
  const { handleError } = useHandleApiErrors('apollo', onError);
  const [query, { data, error, loading }] = useLazyQuery(GET_APPLICATION_DRAFT_GQL, {
    fetchPolicy: 'network-only',
  });

  const getApplicationDraft = useCallback(() => {
    if (!applicationId) {
      throw new Error(`Unexpected applicationId: ${applicationId}`);
    }

    if (!loading) {
      updateLoaded(false);
      query({ variables: { id: applicationId } });
    }
  }, [applicationId, loading, query]);

  useEffect(() => {
    if (loaded) {
      return;
    }

    if (error) {
      handleError(error);
      updateLoaded(true);
    }

    if (data) {
      updateLoaded(true);

      const draft = data.applicationDraft === null ? null : JSON.parse(data.applicationDraft);

      onSuccess(draft);
    }
  }, [data, error, handleError, loaded, loading, onSuccess]);

  return {
    getApplicationDraft,
    loading,
  };
};
