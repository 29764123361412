import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { toInternationalNumber } from 'utils/mobileNumber';
import {
  STEP_ABOUT_YOU,
  STEP_PROCESSING_BIOMETRICS,
  STEP_BIOMETRICS_COMPLETE,
  STEP_BIOMETRICS_CANCELLED,
} from '_config';
import { useHandleApiErrors } from './useHandleApiErrors';

export const INIT_APPLICATION = gql`
  mutation initBiometrics($input: InitBiometricsInput!) {
    initBiometrics(input: $input) {
      startUrl
    }
  }
`;

export const useInitBiometrics = ({ applicationId, mobileNumber, onSuccess, onError }) => {
  const { handleError } = useHandleApiErrors('apollo', onError);

  const [mutate, { loading }] = useMutation(INIT_APPLICATION, {
    onCompleted: data => {
      onSuccess(data.initBiometrics);
    },
    onError: err => {
      handleError(err);
    },
  });

  const internationalNumber = toInternationalNumber(mobileNumber);

  const initBiometrics = useCallback(
    ({ useDesktopFlow }) => {
      const redirectSuccessUrl = useDesktopFlow
        ? `${window.location.protocol}//${window.location.host}${STEP_BIOMETRICS_COMPLETE}`
        : `${window.location.protocol}//${window.location.host}${STEP_PROCESSING_BIOMETRICS}`;
      const redirectCancelUrl = useDesktopFlow
        ? `${window.location.protocol}//${window.location.host}${STEP_BIOMETRICS_CANCELLED}`
        : `${window.location.protocol}//${window.location.host}${STEP_ABOUT_YOU}?cancel=true`;

      const input = {
        applicationId,
        redirectSuccessUrl,
        redirectCancelUrl,
        ...(useDesktopFlow
          ? {
              mobileNumber: internationalNumber,
            }
          : {}),
      };
      return mutate({ variables: { input } });
    },
    [applicationId, internationalNumber, mutate],
  );

  return {
    initBiometrics,
    loading,
  };
};
