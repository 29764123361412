import React, { useState } from 'react';
import { Link } from 'basis';
import { CardNumberModal } from './CardNumber.Modal';

const DLNumberHelpText = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalClick = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Link onClick={handleModalClick} newTab={false} testId="dl-number-helptext-link">
        Card number
      </Link>{' '}
      is located on the back of the card either in the middle near birth year or on the top right
      <CardNumberModal isOpen={modalOpen} onClose={closeModal} />
    </>
  );
};

export { DLNumberHelpText };
