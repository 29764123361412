import React from 'react';
import { getUploadUrl } from 'utils';
import { useDataLayerAndAnalyticsLinks } from 'hooks';
import { Link } from 'basis';

const UploadDocumentButton = ({ storeState, label }) => {
  const { acquisition, applicationRef } = storeState;
  const { firstName, lastName } = acquisition.personalDetails;

  const { trackEventSender } = useDataLayerAndAnalyticsLinks(storeState);

  return (
    <Link
      appearance="primary-button"
      variant="blue-button"
      width="100%"
      width-xs="100%"
      width-sm="260px"
      href={getUploadUrl({ firstName, lastName, applicationRef })}
      newTab
      onClick={() =>
        trackEventSender({
          location: 'outcome',
          label,
        })
      }
    >
      {label}
    </Link>
  );
};

export { UploadDocumentButton };
