import React from 'react';
import { LoadingIcon } from 'basis';
import styled from '@emotion/styled';

const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 35%;
  ${props => `
    ${`@media (max-width: ${props.theme.screenSize?.md})`} {
      width: 100%;
    }
  `};
  background-color: ${props => props.backgroundColor || '#006aff'};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  border: none;
  border-radius: 4px;
  cursor: ${props => (props.disabled || props.loading ? 'not-allowed' : 'pointer')};
  transition: all 0.3s;
`;

export const StartApplicationButton = ({ loading, disabled, children, backgroundColor = false }) => {
  return (
    <StyledButton
      backgroundColor={backgroundColor}
      disabled={disabled}
      type="submit"
      loading={loading}
      data-testid="start-application-button"
    >
      {loading ? <LoadingIcon color="white" /> : <b style={{ color: 'white', fontSize: 18 }}>{children}</b>}
    </StyledButton>
  );
};
