import { initMessagesObject } from '../../utils/initMessagesObject';
import { getDaysInMonth } from '../../utils/getDaysInMonth';

export const validation = (
  {
    minLength,
    maxLength,
    fixedLength,
    minValue,
    maxValue,
    incrementValue,
    minMonths,
    maxMonths,
    minYears,
    maxYears,
    ageAsUpperLimit,
    maxDate,
    minDate,
    typeName,
    matchField,
    addressType,
    validPattern,
  },
  msg,
  component,
  rest,
) => {
  const messages = initMessagesObject(msg, {
    minLength,
    maxLength,
    fixedLength,
    minValue,
    maxValue,
    incrementValue,
    minMonths,
    maxMonths,
    minYears,
    maxYears,
    ageAsUpperLimit,
    maxDate,
    minDate,
    typeName,
    ...(matchField ? { matchFieldLabel: matchField.label } : null),
    addressType,
  });
  const validRegExp = validPattern && !Array.isArray(validPattern) ? new RegExp(validPattern) : null;

  return [
    (value, { data = {} } = {}) => {
      if (component === 'DatePicker')
        return datePickerValidation(value, { maxDate, minDate }, validRegExp, messages, rest);

      if (component === 'Frequency') {
        return frequencyValidation(
          value,
          { minLength, maxLength, fixedLength, minValue, maxValue },
          validRegExp,
          messages,
        );
      }

      if (component === 'TimeSpan') {
        return timeSpanValidation(
          value,
          { minMonths, maxMonths, minYears, maxYears, ageAsUpperLimit },
          validRegExp,
          messages,
        );
      }

      return stringTypeValidation(
        value,
        data,
        { minLength, maxLength, fixedLength, minValue, maxValue, incrementValue, matchField },
        validRegExp,
        messages,
        validPattern,
      );
    },
    messages,
  ];
};

const stringTypeValidation = (
  value,
  data,
  { minLength, maxLength, fixedLength, minValue, maxValue, incrementValue, matchField },
  validRegExp,
  messages,
  validPattern,
) => {
  const valueLength = value.trim().length;

  if (!valueLength) {
    return messages.required;
  }
  if (validRegExp && !validRegExp.test(value)) {
    return messages.invalid;
  }
  if (Array.isArray(validPattern)) {
    let valid = true;
    validPattern.forEach(pattern => {
      valid = valid && new RegExp(pattern).test(value);
    });
    if (!valid) {
      return messages.invalid;
    }
  }
  if (typeof minValue === 'number' && parseInt(value, 10) < minValue) {
    return messages.minValue;
  }
  if (typeof minValue === 'number' && parseInt(value, 10) > maxValue) {
    return messages.maxValue;
  }
  if (typeof incrementValue === 'number' && parseInt(value, 10) % incrementValue !== 0) {
    return messages.incrementValue;
  }
  if (fixedLength && valueLength !== fixedLength) {
    return messages.fixedLength;
  }
  if (minLength && valueLength < minLength) {
    return messages.minLength;
  }
  if (maxLength && valueLength > maxLength) {
    return messages.maxLength;
  }
  if (matchField && data.values[matchField.name] !== value) {
    return messages.matchField;
  }

  return null;
};

const timeSpanValidation = (
  value,
  { minMonths, maxMonths, minYears, maxYears, ageAsUpperLimit },
  validRegExp,
  messages,
) => {
  const { years, months } = value;

  if (years === '' && months === '') {
    return messages.required;
  }
  if ((validRegExp && !validRegExp.test(years)) || !validRegExp.test(months)) {
    return messages.invalid;
  }
  if (years === '') {
    return messages.yearsError;
  }
  if (months === '') {
    return messages.monthsError;
  }
  if ((minYears && years < minYears) || (maxYears && years > maxYears)) {
    return messages.yearRangeError;
  }
  if (ageAsUpperLimit && Number(years) + Number(months) / 12 > ageAsUpperLimit) {
    return messages.excessAgeError;
  }
  if ((minMonths && months < minMonths) || (maxMonths && months > maxMonths)) {
    return messages.monthRangeError;
  }
  return null;
};

const frequencyValidation = (
  value,
  { minLength, maxLength, fixedLength, minValue, maxValue },
  validRegExp,
  messages,
) => {
  const valueLength = value.amount.length;

  if (!valueLength) {
    return messages.required;
  }
  if (validRegExp && !validRegExp.test(value.amount)) {
    return messages.invalid;
  }
  if (typeof minValue === 'number' && parseInt(value.amount, 10) < minValue) {
    return messages.minValue;
  }
  if (typeof minValue === 'number' && parseInt(value.amount, 10) > maxValue) {
    return messages.maxValue;
  }
  if (fixedLength && valueLength !== fixedLength) {
    return messages.fixedLength;
  }
  if (minLength && valueLength < minLength) {
    return messages.minLength;
  }
  if (maxLength && valueLength > maxLength) {
    return messages.maxLength;
  }
  return null;
};

const datePickerValidation = (
  { day: dayStr, month: monthStr, year: yearStr },
  { maxDate, minDate },
  validRegExp,
  messages,
  rest,
) => {
  if ((rest?.dayMode !== 'none' && !dayStr) || !monthStr || !yearStr) {
    return messages.required;
  }

  const day = parseInt(dayStr, 10);
  const month = parseInt(monthStr, 10);
  const year = parseInt(yearStr, 10);

  if (rest?.dayMode !== 'none' && (day < 1 || day > 31)) {
    return messages.dayError;
  }
  if (month < 1 || month > 12) {
    return messages.monthError;
  }
  if (yearStr.length < 4) {
    return messages.completeYear;
  }
  if (year < 1900) {
    return messages.minDate;
  }
  if (
    (validRegExp &&
      ((rest?.dayMode !== 'none' && !validRegExp.test(day)) || !validRegExp.test(month) || !validRegExp.test(year))) ||
    day > getDaysInMonth(month, year)
  ) {
    return messages.invalid;
  }

  const twoDigitsDayStr = rest?.dayMode === 'none' ? '01' : dayStr.length === 1 ? `0${day}` : dayStr;
  const twoDigitsMonthStr = monthStr.length === 1 ? `0${month}` : monthStr;

  if (maxDate && Date.parse(`${year}-${twoDigitsMonthStr}-${twoDigitsDayStr}`) > Date.parse(maxDate)) {
    return messages.maxDate;
  }
  if (minDate && Date.parse(`${year}-${twoDigitsMonthStr}-${twoDigitsDayStr}`) < Date.parse(minDate)) {
    return messages.minDate;
  }
  return null;
};
