import { OktaAuth } from '@okta/okta-auth-js';

const config = {
  issuer: `${process.env.REACT_APP_OKTA_DOMAIN}/oauth2/${process.env.REACT_APP_OKTA_ISSUER_ID}`,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  storageManager: {
    token: {
      storageTypes: ['sessionStorage', 'memory'],
    },
  },
};

const oktaAuthClient = new OktaAuth(config);

// Expose getAccessToken for cypress tests;
if (window.Cypress) {
  window.getAccessToken = () => oktaAuthClient.getAccessToken();
}

export { oktaAuthClient };
