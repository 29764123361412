import React from 'react';
import { useHistory } from 'react-router-dom';
import { LayoutPage, LayoutContent, InformationCard, Errors, ActionLink } from 'components';
import { Container, Text, List, Stack } from 'basis';
import { useStoreValue } from 'store';
import ShareBankStatement from 'images/vi_share_your_bank_statement.svg';
import { isMobileOnly } from 'react-device-detect';
import { useInitBiometrics, useErrorTrackingAndUpdateStore, useSaveDraft } from 'hooks';
import { DEV_FEATURES } from '__dev/devFeatures';
import { STEP_PROCESSING_BIOMETRICS } from '_config';
import { isFeatureOn } from 'featureToggles';

export const VerifyIdentity = () => {
  const { handleErrorCallback } = useErrorTrackingAndUpdateStore('Verify now');
  const [store, updateStore] = useStoreValue();
  const history = useHistory();
  const { saveDraft } = useSaveDraft();
  /** Note `isMobileOnly` is excluding tablet devices as APLYiD currently has issues with treating tablets as desktops. After they fix it we can change to use `isMobile` instead */
  const isMobileOrSimulated = isMobileOnly || DEV_FEATURES.SIMULATE_MOBILE;

  const onManualVerify = () => {
    updateStore({
      applicationErrors: null,
      biometricsVerification: 'NOT_USED',
      activeStep: '/about-you',
    });

    history.push('/about-you');
  };

  const onInitBiometricsSuccess = ({ startUrl }) => {
    updateStore({
      applicationErrors: null,
      activeStep: STEP_PROCESSING_BIOMETRICS,
    });

    if (isMobileOrSimulated) {
      window.location.assign(startUrl);
    } else {
      history.push(STEP_PROCESSING_BIOMETRICS);
    }
  };

  const { initBiometrics, loading: startingBiometrics } = useInitBiometrics({
    applicationId: store.applicationId,
    mobileNumber: store.acquisition.contactDetails.mobileNumber,
    onError: handleErrorCallback,
    onSuccess: onInitBiometricsSuccess,
  });

  const onBiometricsVerify = () => {
    updateStore({
      biometricsVerification: 'STARTED',
    });
    if (isFeatureOn('saveAndResume')) {
      saveDraft(store);
    }

    initBiometrics({
      useDesktopFlow: !isMobileOrSimulated,
    });
  };

  if (store.applicationErrors) {
    return (
      <LayoutPage>
        <Errors applicationRef={store.applicationRef} />
      </LayoutPage>
    );
  }

  return (
    <LayoutPage>
      <Container margin="0 0 13 0" hasBreakpointWidth>
        <LayoutContent rowsGap="0">
          <Stack gap="4">
            <Container>
              <Text>
                To protect New Zealanders from identity fraud, we use facial verification technology to biometrically
                match your face to the picture on your ID. The process is{' '}
                <strong>quick, simple and secure, it should only take 3 minutes to complete</strong>. If required, we
                may use this information for additional identity verification.
              </Text>
              <br />
              <InformationCard
                icon={ShareBankStatement}
                iconAlt="Face match"
                title="Digitally verify your identity (using APLYiD)"
                btnText="Verify now"
                onClick={onBiometricsVerify}
                testId="applyid-button"
                variant="primary"
                background="secondary"
                loading={startingBiometrics || store.biometricsVerification === 'STARTED'}
              >
                <List>
                  <List.Item>
                    <Text>
                      <strong>Take a picture</strong> of your New Zealand Passport or Driver Licence
                    </Text>
                  </List.Item>
                  <List.Item>
                    <Text>
                      <strong>Verify that the details</strong> captured off your Driver Licence or Passport are correct
                    </Text>
                  </List.Item>
                  <List.Item>
                    <Text>
                      <strong>Take a live selfie of your face,</strong> on-screen prompts will guide you through this
                      process
                    </Text>
                  </List.Item>
                </List>
              </InformationCard>
            </Container>

            <Container bg="secondary.lightBlue.t15" padding="6 8">
              <Text>
                <strong>Manually provide your identification details to us</strong>
              </Text>
              <Text>
                This process can take a little longer, <strong>1 - 2 days</strong>, as we may need to contact you to
                verify your identity.{' '}
                <ActionLink testId="manual-verify-btn" onClick={onManualVerify}>
                  <strong>Continue</strong>
                </ActionLink>
              </Text>
            </Container>
          </Stack>
        </LayoutContent>
      </Container>
    </LayoutPage>
  );
};
