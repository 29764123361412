import { emailMaxLength, validEmailPattern } from '../_constants';
import { validAUMobilePattern } from './constants';

const mobileNumberValidationMessages = {
  required: 'Please provide us your Australian mobile number.',
  invalid: 'Please enter as 04 and the 8-digit mobile number.',
};

const emailValidationMessages = {
  required: 'Please provide us your email address.',
  matchField: 'The email address provided does not match the above. Please re-enter it.',
  maxLength: 'Email must be {{maxLength}} characters or less.',
  invalid: 'Please enter in the format of name@example.com',
};

const validationMessages = {
  mobileNumber: mobileNumberValidationMessages,
  emailAddress: emailValidationMessages,
  confirmEmail: emailValidationMessages,
};

const fields = isResuming => {
  if (isResuming) {
    return [
      {
        component: 'Input',
        name: 'mobileNumber',
        label: 'Mobile number',
        testId: 'mobile-number',
        helpText: `Only Australian mobile phone numbers are accepted.`,
        validate: { props: { validPattern: validAUMobilePattern }, messages: validationMessages.mobileNumber },
      },
      {
        component: 'Input',
        name: 'emailAddress',
        label: 'Email address',
        testId: 'email-address',
        helpText: `Make sure to use the same email address you used to begin your application.`,
        validate: {
          props: { maxLength: emailMaxLength, validPattern: validEmailPattern },
          messages: validationMessages.emailAddress,
        },
      },
      {
        component: 'Input',
        name: 'confirmEmail',
        label: 'Confirm email address',
        testId: 'confirm-email-address',
        validate: {
          props: {
            maxLength: emailMaxLength,
            validPattern: validEmailPattern,
            matchField: { name: 'emailAddress', label: 'Email address' },
          },
          messages: validationMessages.confirmEmail,
        },
        pasteAllowed: false,
      },
    ];
  }
  return [
    {
      component: 'Input',
      name: 'mobileNumber',
      label: 'Mobile number',
      testId: 'mobile-number',
      helpText: `Only Australian mobile phone numbers are accepted.`,
      validate: { props: { validPattern: validAUMobilePattern }, messages: validationMessages.mobileNumber },
    },
    {
      component: 'Input',
      name: 'emailAddress',
      label: 'Email address',
      testId: 'email-address',
      helpText: `We will send you updates about your application`,
      validate: {
        props: { maxLength: emailMaxLength, validPattern: validEmailPattern },
        messages: validationMessages.emailAddress,
      },
    },
    {
      component: 'Input',
      name: 'confirmEmail',
      label: 'Confirm email address',
      testId: 'confirm-email-address',
      validate: {
        props: {
          maxLength: emailMaxLength,
          validPattern: validEmailPattern,
          matchField: { name: 'emailAddress', label: 'Email address' },
        },
        messages: validationMessages.confirmEmail,
      },
      pasteAllowed: false,
    },
  ];
};

export const contactDetails = isResuming => fields(isResuming);
