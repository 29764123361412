import React from 'react';
import { Link, Container } from 'basis';
import { useEventTracking } from 'react-event-tracker';
import { useStoreValue } from 'store';
import { getDataLayerElements } from 'utils/getDataLayerElements';

// type Props = {
//   eventLocation?: string
//   variant?: 'text' | 'button'
// }
export const ReturnButton = props => {
  const variant = props.variant || 'button';
  const eventLocation = props.eventLocation || 'outcome';

  const [storeState] = useStoreValue();

  const { trackEvent } = useEventTracking();

  if (!storeState.applyAndBuy || !storeState.applyAndBuy.returnUrl) {
    return null;
  }

  const {
    applyAndBuy: { merchantName, returnUrl },
  } = storeState;
  const label = `Take me back to ${merchantName}`;

  const onClick = () => {
    trackEvent({
      event: {
        label,
        location: eventLocation,
        category: 'button-link',
        action: 'external-link',
      },
      ...getDataLayerElements(storeState),
    });
  };

  return (
    <Container margin="0 0 6 0">
      <Link
        appearance={variant === 'text' ? 'text' : 'primary-button'}
        variant={variant === 'text' ? 'text' : 'blue-button'}
        testId="returnButton"
        href={returnUrl}
        onClick={onClick}
        newTab
      >
        {label}
      </Link>
    </Container>
  );
};
