import { useCallback } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { config } from '_config';
import { useStoreValue } from 'store';
import { getFeatureValue } from 'featureToggles';
import { useHandleApiErrors } from './useHandleApiErrors';

export const OFFERS_GRAPHQL = gql`
  query offers($productCode: String!, $promoCode: String!) {
    offers(productCode: $productCode, promoCode: $promoCode) {
      status
      offerType
      shortDescription
      promoStart
      promoEnd
    }
  }
`;

function useValidateOffers({ onError }) {
  const [storeState, updateStore] = useStoreValue();
  const { applyAndBuy } = storeState;

  const { handleError } = useHandleApiErrors('hook:useValidateOffers', onError);
  const offerTypeMapping = getFeatureValue('offerTypeMapping');

  const [valid, { loading }] = useLazyQuery(OFFERS_GRAPHQL, {
    onCompleted: data => {
      const { status, promoStart, promoEnd, offerType, shortDescription } = data.offers;
      if (status !== 'VALID') {
        updateStore({
          ...storeState,
          applyAndBuy: { ...applyAndBuy, promoCode: undefined },
          offer: {
            status,
          },
        });
        return;
      }

      const offer = {
        status,
        type: offerType,
        title: offerTypeMapping[offerType?.toUpperCase()],
        description: shortDescription,
        promoStart: convertUTCToLocalDate(promoStart),
        promoEnd: convertUTCToLocalDate(promoEnd),
      };

      updateStore({
        ...storeState,
        offer,
      });
    },
    onError: err => {
      handleError(err);
    },
  });

  const validateOffer = useCallback(() => {
    const input = {
      productCode: config.productCode,
      promoCode: applyAndBuy?.promoCode,
    };
    valid({ variables: input }).then();
  }, [applyAndBuy.promoCode, valid]);

  return {
    validateOffer,
    loading,
  };
}

function convertUTCToLocalDate(utcTime) {
  const utcDate = new Date(utcTime);
  if (isNaN(utcDate)) {
    return null;
  }
  return utcDate.toLocaleDateString('en-AU', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
}

export { useValidateOffers };
