import styled from '@emotion/styled';

export const ClickHereLink = styled.span`
  font-weight: 300;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  -webkit-transition: background-color 200ms ease-out, border-bottom-color 200ms ease-out;
  transition: background-color 200ms ease-out, border-bottom-color 200ms ease-out;
  color: #0046aa;
  border-bottom-color: rgba(0, 70, 170, 0.5);
  cursor: pointer;
  &:hover {
    border-bottom-color: #0046aa;
    background-color: #d8edff;
  }
`;
