import { COUNTRY_CODE_AU } from '_config';
import { getAge } from 'utils/getDataLayerElementsHelper';
import { residentialStatusOptionsAU, numbersOnlyPattern } from '../_constants';
import { minimumResidencyInMonths } from './constants';

export const lengthOfCurrentResidencyValidationMessages = {
  required: 'Please enter the number of years you have lived at this address.',
  invalid: 'Please enter numbers only.',
  monthsError: 'Please enter the number of months you have lived at this address. If less than 1 month, enter 0.',
  yearsError: 'Please enter the number of years you have lived at this address.',
  monthRangeError: 'Please enter between 0 to 11.',
  yearRangeError: 'Please enter between 0 to 99.',
  excessAgeError: `The length of time at you current residential address can't exceed your age. Please re-enter a valid number.`,
};

const postalAddressOptions = [
  {
    label: 'Same as residential address',
    value: 'yes',
  },
  {
    label: 'Different to residential address',
    value: 'no',
  },
];

export function showPreviousAddress({ years, months }) {
  if (years && months) {
    const durationMonths = parseInt(years, 10) * 12 + parseInt(months, 10);
    return durationMonths < minimumResidencyInMonths;
  }
  return false;
}

export const otherDetails = [
  {
    component: 'Select',
    name: 'residentialStatus',
    label: 'Living arrangement',
    testId: 'residential-status',
    options: residentialStatusOptionsAU,
    validate: {
      messages: {
        required: 'Please select your living arrangement',
      },
    },
  },
  {
    component: 'Address',
    name: 'currentAddress',
    label: 'Current residential address',
    testId: 'current-residential-address',
    allowPropertyName: false,
    allowPO: false,
    countryCode: COUNTRY_CODE_AU,
    autoAddressErrorMessage: 'Please enter your current residential address.',
    // Overwrite the default help text into empty string
    autoAddressHelpText: '',
  },
  {
    component: 'RadioGroup',
    name: 'isPostalSameWithResidential',
    label: 'Postal address',
    testId: 'is-postal-same-with-residential',
    options: postalAddressOptions,
    showCircles: true,
    columns: 1,
    color: 'white',
    validate: {
      messages: {
        required: 'Is your postal address the same as your current residential address?',
      },
    },
  },
  {
    component: 'TimeSpan',
    name: 'lengthOfCurrentResidency',
    label: 'Length of time at current residential address',
    testId: 'current-residential-address-length',
    validate: {
      props: storeState => {
        const age = getAge(storeState.acquisition.personalDetails.dateOfBirth, undefined, false);
        return {
          ageAsUpperLimit: age,
          minYears: 0,
          maxYears: 99,
          minMonths: 0,
          maxMonths: 11,
          validPattern: numbersOnlyPattern,
        };
      },
      messages: lengthOfCurrentResidencyValidationMessages,
      component: 'TimeSpan',
    },
  },
  {
    condition: values => values.isPostalSameWithResidential === 'no',
    component: 'Address',
    name: 'postalAddress',
    label: 'Postal address',
    testId: 'postal-address',
    allowPropertyName: false,
    allowPO: true,
    countryCode: COUNTRY_CODE_AU,
    autoAddressErrorMessage: 'Please enter your postal address.',
  },
  {
    condition: values => showPreviousAddress(values.lengthOfCurrentResidency),
    name: 'previousAddressGroup',
    fields: [
      {
        condition: values => values.hasNonDomesticPreviousAddress === false,
        component: 'Address',
        name: 'previousAddress',
        label: 'Previous residential address',
        testId: 'previous-residential-address',
        allowPropertyName: false,
        allowPO: false,
        countryCode: COUNTRY_CODE_AU,
        autoAddressErrorMessage: 'Please provide your previous AU address.',
        // Overwrite the default help text into empty string
        autoAddressHelpText: '',
      },
    ],
  },
];
