import React from 'react';
import { Container, Text, Stack } from 'basis';
import styled from '@emotion/styled';

export const BracketList = styled.ol`
  counter-reset: list;
  padding-inline-start: 22px;
  margin-block: 4px;
  list-style-type: lower-alpha;

  & > li {
    list-style: none;
    position: relative;
    padding-left: 20px;
  }
  & > li:before {
    counter-increment: list;
    content: '(' counter(list, lower-alpha) ') ';
    position: absolute;
    left: -1.4em;
  }
`;
BracketList.Item = ({ children }) => (
  <li>
    <Text>{children}</Text>
  </li>
);

export const Section = ({ children, ...props }) => (
  <Container margin="3 0" margin-md="5 0">
    <Stack gap="3" gap-md="5" {...props}>
      {children}
    </Stack>
  </Container>
);

export const SectionTitleBox = ({ children, ...props }) => (
  <Container bg="grey.t05" padding="2" {...props}>
    {children}
  </Container>
);
