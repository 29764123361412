export function radioGroupTheme(theme, { getColor, getTextStyle }) {
  return {
    getCSS: ({ targetElement, color, isChecked }) => {
      switch (targetElement) {
        case 'radio': {
          return {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          };
        }

        case 'radioInput': {
          return {
            ...theme.focusStyles.focusVisibleAdjacentLabel,
            ':checked + label': {
              backgroundColor: theme.colors.white,
              color: theme.colors.grey.t75,
            },
          };
        }

        case 'radioLabel': {
          return {
            display: 'inline-flex',
            alignItems: 'flex-start',
            padding: `${theme.space[3]} ${theme.space[4]}`,
            minHeight: '24px',
            height: '100%',
            ...getTextStyle({ name: 'body1', mode: 'container' }),
            color: theme.colors.grey.t75,
            backgroundColor: getColor(color),
            borderRadius: theme.radii[0],
          };
        }

        case 'circleSvg': {
          return {
            flexShrink: 0,
            width: '24px',
            height: '24px',
            marginRight: theme.space[3],
          };
        }

        case 'outerCircle': {
          return {
            r: '10px',
            fill: theme.colors.white,
            stroke: theme.colors.grey.t75,
            strokeWidth: theme.borderWidths[1],
          };
        }

        case 'innerCircle': {
          return {
            r: '7',
            fill: theme.colors.grey.t75,
          };
        }

        case 'description': {
          return {
            marginTop: theme.space[1],
          };
        }

        default: {
          return null;
        }
      }
    },
  };
}
