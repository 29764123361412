import React from 'react';
import ReactDOM from 'react-dom';

import smoothscroll from 'smoothscroll-polyfill';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import { getLaunchDarklyUserKey, loadDataDogRum } from 'utils';
import * as serviceWorker from './serviceWorker';
import { App } from './App';
import { GlobalStyle } from './index.styles';

smoothscroll.polyfill();

const userKey = getLaunchDarklyUserKey();

const LDProviderConfig = {
  clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
  ...(userKey && {
    user: {
      kind: 'user',
      key: getLaunchDarklyUserKey(),
    },
  }),
};

// Load Launchdarkly SDK V3 asynchronously at the root of the app
// to prevent blocking the app on startup and re-renders.
(async () => {
  const { asyncWithLDProvider } = await import(
    /* webpackChunkName: "launchdarklyReactClientSDK" */ 'launchdarkly-react-client-sdk'
  );
  const LDProvider = await asyncWithLDProvider(LDProviderConfig);
  ReactDOM.render(
    <React.StrictMode>
      <GlobalStyle />
      <LDProvider>
        <App />
      </LDProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  );

  // Load Tealium & DatadogRum asynchronously without react dependencies
  // to prevent unnecessary blocking and App re-renders.
  loadDataDogRum();
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
