import React from 'react';
import { useEventTracking } from 'react-event-tracker';
import { LayoutPage, FormRenderer, Form, LayoutContent, Errors } from 'components';
import { useStoreValue, APOLLO_NETWORK_ERROR, APOLLO_UNKNOWN_ERROR } from 'store';
import { submitHandler, initFormConfig, getDataLayerElements } from 'utils';
import { config } from '_config';
import { useSaveApplication, useErrorTrackingAndUpdateStore, useFormInitialValues, useSteps } from 'hooks';
import { byCountry } from 'utils/byConfig';
import { useSaveAndSubmitApplication } from '../../hooks/useSaveAndSubmitApplication';

const FORM_ID = 'creditLimitDetails';
const formConfig = initFormConfig(config.formConfigs[FORM_ID]);

const CreditLimit = () => {
  const [storeState, updateStore] = useStoreValue();
  const { acquisition, ...otherState } = storeState;
  const { trackEvent } = useEventTracking();
  const { handleErrorCallback } = useErrorTrackingAndUpdateStore('Next');
  const initialValues = useFormInitialValues(FORM_ID);
  const { goNext, isLastDraftStep } = useSteps();

  const { save, loading } = useSaveApplication({
    storeState,
    onSuccess: goNext,
    onError: handleErrorCallback,
  });

  const { saveAndSubmit, loading: loadingForSaveAndSubmit } = useSaveAndSubmitApplication({
    storeState,
    onSuccess: goNext,
    onError: handleErrorCallback,
  });

  const onSubmit = ({ values: creditLimitDetails, errors }) => {
    function submit() {
      const newAcquisition = {
        ...acquisition,
        creditLimitDetails,
      };

      updateStore({
        applicationSubmitted: true,
        acquisition: newAcquisition,
      });

      const dataLayer = getDataLayerElements({
        ...otherState,
        applicationSubmitted: true,
        acquisition: newAcquisition,
      });
      const { requestedCreditLimit, approvedCreditLimit, productMaxCreditLimit } = dataLayer.application;

      const values = [requestedCreditLimit, approvedCreditLimit, productMaxCreditLimit]
        .filter(v => !!v)
        .map(v => parseFloat(v));

      trackEvent({
        event: {
          category: 'application',
          action: isLastDraftStep ? 'application-submitted' : 'application-navigation',
          location: 'credit-limit',
          label: 'Next',
          value: Math.min(...values).toString(),
        },
        ...dataLayer,
      });

      const proceed = byCountry({
        AU: () => saveAndSubmit(newAcquisition),
        NZ: () => save(newAcquisition),
      });

      if (isLastDraftStep) {
        proceed();
      } else {
        goNext();
      }
    }

    submitHandler({ submit, errors });
  };

  const retrySubmit = () => {
    trackEvent({
      event: {
        category: 'application',
        action: 'application-submitted',
        location: 'credit-limit',
        label: 'Retry',
      },
    });

    save(acquisition);
  };

  if (otherState.applicationErrors) {
    const retry = [APOLLO_NETWORK_ERROR, APOLLO_UNKNOWN_ERROR].includes(otherState.applicationErrors.type)
      ? retrySubmit
      : null;
    return (
      <LayoutPage>
        <Errors
          applicationRef={otherState.applicationRef}
          retry={retry}
          retrying={loading || loadingForSaveAndSubmit}
        />
      </LayoutPage>
    );
  }

  return (
    <LayoutPage noSessionTimeout>
      <Form
        id={FORM_ID}
        initialValues={initialValues}
        onSubmit={onSubmit}
        loading={loading || loadingForSaveAndSubmit}
        submitButtonLabel="Next"
        showSaveDraftButton
        saveDraftOnSessionTimeout
      >
        {formData => (
          <LayoutContent>
            <FormRenderer config={formConfig} formData={formData} disabled={loading || loadingForSaveAndSubmit} />
          </LayoutContent>
        )}
      </Form>
    </LayoutPage>
  );
};

export { CreditLimit };
