import React from 'react';
import { Container, Text } from 'basis';
import { COUNTRY_CODE_AU } from '_config';
import { yesNoOptions, numbersOnlyPattern, residentialStatusOptions } from '../_constants';
import { lengthOfCurrentResidencyValidationMessages, showPreviousAddress } from './otherDetails';

const CurrentAddress = () => {
  // TODO: get real data than mock after card https://di.latitudefinancial.com/jira/browse/AUP-1359
  return (
    <Container testId="currentAddress">
      <Text as="h4" textStyle="heading6" color="black">
        Current AU residential address
      </Text>
      <Text testId="currentAddress.street">8 Tangihua Street</Text>
      <Text testId="currentAddress.city">Auckland Central Auckland</Text>
      <Text testId="currentAddress.postcode">1010</Text>
      <Text testId="currentAddress.country">New Zealand</Text>
    </Container>
  );
};

export const biometricsAddressDetails = [
  {
    component: 'Select',
    name: 'residentialStatus',
    label: 'Residential status',
    testId: 'residential-status',
    options: residentialStatusOptions,
    validate: {
      messages: {
        required: 'Please select residential status.',
      },
    },
  },
  {
    component: 'CustomComponent',
    condition: (values, store) => store.isBiometricsAddressValid,
    children: CurrentAddress,
  },
  {
    component: 'Address',
    condition: (values, store) => !store.isBiometricsAddressValid,
    name: 'currentAddress',
    label: 'Current AU residential address',
    testId: 'current-residential-address',
    allowPropertyName: false,
    allowPO: false,
    countryCode: COUNTRY_CODE_AU,
    autoAddressErrorMessage: 'Please enter your current AU residential address.',
    autoAddressHelpText: 'We do not accept PO Box or international addresses',
  },
  {
    component: 'RadioGroup',
    name: 'isPostalSameWithResidential',
    label: 'This is also my mailing address',
    testId: 'is-postal-same-with-residential',
    options: yesNoOptions,
    showCircles: true,
    validate: {
      messages: {
        required: 'Is your postal address the same as your current residential address?',
      },
    },
  },
  {
    condition: values => values.isPostalSameWithResidential === 'no',
    component: 'Address',
    name: 'postalAddress',
    label: 'Postal address',
    testId: 'postal-address',
    allowPropertyName: false,
    allowPO: true,
    countryCode: COUNTRY_CODE_AU,
    autoAddressErrorMessage: 'Please enter your postal address.',
  },
  {
    component: 'TimeSpan',
    name: 'lengthOfCurrentResidency',
    label: 'Length of time at current residential address',
    testId: 'current-residential-address-length',
    validate: {
      props: { minYears: 0, maxYears: 99, minMonths: 0, maxMonths: 11, validPattern: numbersOnlyPattern },
      messages: lengthOfCurrentResidencyValidationMessages,
      component: 'TimeSpan',
    },
  },
  {
    condition: values => showPreviousAddress(values.lengthOfCurrentResidency),
    name: 'previousAddressGroup',
    fields: [
      {
        condition: values => values.hasNonDomesticPreviousAddress === false,
        component: 'Address',
        name: 'previousAddress',
        label: 'Previous residential address',
        testId: 'previous-residential-address',
        allowPropertyName: false,
        allowPO: false,
        countryCode: COUNTRY_CODE_AU,
        autoAddressErrorMessage: 'Please provide your previous AU address or confirm that it was not in AU.',
      },
      {
        component: 'Checkbox',
        name: 'hasNonDomesticPreviousAddress',
        label: '',
        children: 'My previous address was not in New Zealand',
        testId: 'previous-address-non-domestic',
        validate: false,
      },
    ],
  },
];
