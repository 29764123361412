import React, { useCallback } from 'react';
import { Grid, Button } from 'basis';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { useStoreValue, getInitialState } from 'store';
import styled from '@emotion/styled';
import { useEventTracking } from 'react-event-tracker';
import { useHistory } from 'react-router-dom';
import { canGoBackToMerchant } from 'utils';
import { STEP_RESUME_YOUR_APPLICATION } from '_config';

export function ResumeOrBackToMerchant() {
  const [storeState, updateStore] = useStoreValue();
  const { trackEvent } = useEventTracking();
  const history = useHistory();

  const onClickResume = useCallback(() => {
    trackEvent({
      event: {
        category: 'application',
        action: 'application-navigation',
        location: window.location.pathname,
        label: 'Resume application',
      },
    });
    const resumePath = `${STEP_RESUME_YOUR_APPLICATION}?applicationId=${storeState?.applicationId}&productCode=${storeState?.productCode}`;
    updateStore(getInitialState());
    history.push(resumePath);
  }, [history, storeState.applicationId, storeState.productCode, trackEvent, updateStore]);

  const onClickBackToMerchant = useCallback(() => {
    trackEvent({
      event: {
        category: 'application',
        action: 'application-navigation',
        location: window.location.pathname,
        label: 'Back to merchant',
      },
    });
    window.open(storeState.applyAndBuy.upstreamUrl, '_blank');
  }, [storeState.applyAndBuy.upstreamUrl, trackEvent]);

  if (!canGoBackToMerchant(storeState)) {
    return null;
  }

  return (
    <GroupContainer>
      <Grid rowsGap="4" colsGap="4" cols="2">
        <Grid.Item colSpan="all" colSpan-sm="0" alignItems="center">
          <ButtonContainer>
            <Grid>
              <Button testId="resume-button" variant="secondary" onClick={onClickResume}>
                Resume application
              </Button>
            </Grid>
          </ButtonContainer>
        </Grid.Item>

        <Grid.Item colSpan="all" colSpan-sm="1" rowSpan-sm="0">
          <ButtonContainer>
            <Grid>
              <Button testId="back-to-merchant-button" onClick={onClickBackToMerchant}>
                <ButtonContentWithIconOnRight>
                  <span>Return to {storeState.applyAndBuy.merchantGroupName}</span>
                  <ExternalLinkIcon width="20" height="20" />
                </ButtonContentWithIconOnRight>
              </Button>
            </Grid>
          </ButtonContainer>
        </Grid.Item>
      </Grid>
    </GroupContainer>
  );
}

const ButtonContainer = styled.div`
  & button {
    padding: 12px 16px;
  }

  & svg {
    margin-left: 14px;
    padding-bottom: 1px;
  }
`;

const GroupContainer = styled.div`
  padding-top: 12px;
`;

const ButtonContentWithIconOnRight = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & span {
    width: 90%;
    align-content: center;
  }
`;
