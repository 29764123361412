import React, { useEffect } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { DEV_FEATURES } from '__dev/devFeatures';
import { useSteps, usePrevious } from 'hooks';
import { useStoreValue } from 'store';
import { compareUrlPathWithStep } from './string';

export const RouteRedirectHandler = () => {
  const [store] = useStoreValue();
  const history = useHistory();
  const { accessibleSteps, activeStep, pathname } = useSteps();
  const hasErrors = !!store.applicationErrors;
  const override = DEV_FEATURES.ALLOW_NAVIGATION_TO_ALL_ROUTES;
  const prevPathname = usePrevious(pathname);

  useEffect(() => {
    const unblock = history.block(args => {
      const { pathname: nextPathname } = args;

      if (history.action !== 'POP') {
        // The user is navigating to a new location.
        return true;
      }

      // The user is navigating back.
      if (isNavigationAllowed({ pathname: nextPathname, store, accessibleSteps })) {
        return true;
      }

      return false;
    });

    return () => {
      unblock();
    };
  }, [accessibleSteps, hasErrors, history, override, store]);

  if (!hasErrors && isNavigationAllowed({ pathname, store, accessibleSteps })) {
    return null;
  }

  const lowerCasePath = accessibleSteps.find(step => compareUrlPathWithStep(step, pathname));

  const redirectPath = prevPathname === accessibleSteps[0] ? prevPathname : lowerCasePath ?? activeStep;
  const redirectParams = { pathname: redirectPath };

  if (window.location.search) {
    redirectParams.search = window.location.search;
  }

  return <Redirect to={redirectParams} />;
};

function isNavigationAllowed({ pathname, store, accessibleSteps }) {
  const hasErrors = !!store.applicationErrors;
  const override = DEV_FEATURES.ALLOW_NAVIGATION_TO_ALL_ROUTES;
  const canAccessStep = accessibleSteps.includes(pathname);
  return override || (!hasErrors && canAccessStep);
}
