import React from 'react';
import { Global, css } from '@emotion/core';

const styles = css`
  html {
    margin: 0;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 1fr;
  }
  body {
    margin: 0;
    display: grid;
    grid-template-rows: 1fr;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  #root {
    display: grid;
    height: 100vh;
    grid-template-rows: auto 1fr auto;
  }

  main {
    display: grid;
    grid-template-rows: 1fr;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
`;

export const GlobalStyle = () => <Global styles={styles} />;
