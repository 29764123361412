import { DEV_FEATURES } from '__dev/devFeatures';

export function submitHandler({ submit, errors = {} }) {
  if (DEV_FEATURES.DISABLE_SUBMISSION_VALIDATION || Object.keys(errors).length === 0) {
    return submit();
  }

  const ariaInvalid = document.querySelectorAll('[aria-invalid=true]');

  if (ariaInvalid.length > 0) {
    return window.scrollTo({
      top: ariaInvalid.item(0).getBoundingClientRect().top - document.body.getBoundingClientRect().top,
      behavior: 'smooth',
    });
  }

  return null;
}
