import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Grid, Text, useBreakpoint, Message, List } from 'basis';
import { useTheme as emotionUseTheme } from '@emotion/react';
import { LayoutPage, Hero, Form, Errors, FormRenderer, InternalLink } from 'components';
import { OverviewContent } from 'siteContent/overviewContent';
import { useStoreValue, APOLLO_NETWORK_ERROR, APOLLO_UNKNOWN_ERROR } from 'store';
import { submitHandler, getDataLayerElements } from 'utils';
import { useEventTracking } from 'react-event-tracker';
import { APPLY_BTN_LABEL } from '_config/_constants';
import HandIcon from 'images/hero-hand.svg';
import SaleIcon from 'images/hero-sale.svg';
import { scroller, Element } from 'react-scroll';
import { config, STEP_CONTACT_DETAILS } from '_config';
import { OverviewSources } from '_config/_constants/externalLinks';
import { useInitApplication, useErrorTrackingAndUpdateStore, useFormInitialValues, useValidateOffers } from 'hooks';
import { kebabCase } from 'lodash';
import { getRatesAndFees, isFeatureOn } from 'featureToggles';
import { IconTitleWrapper, ContentWrapper } from './Overview.styles';
import { FeaturesAndBenefitsModal } from './FeaturesAndBenefitsModal';
import { HeaderBannerTextArea } from './HeaderBannerTextArea';
import { ImportantFeatures } from './ImportantFeatures';
import { EligibilityAndInformationTextArea } from './EligibilityAndInformationTextArea';
import { useAdblockDetector } from '../../hooks/useAdblockDetector';
import { StartApplicationButton } from './StartApplicationButton';
import { DEV_FEATURES } from '../../__dev';

const { groupLFrameCardImage, groupLFrameCardImageMobile, formHeader } = OverviewContent[config.countryCode];
const overviewSources = OverviewSources[config.countryCode];

const FORM_ID = 'overview';

const formTealiumLocation = kebabCase(formHeader);
const pageTealiumLocation = 'what-to-expect';
const internalLinkEventConfig = {
  category: 'text-link',
  action: 'internal-link',
  location: formTealiumLocation,
};

export function Overview() {
  const theme = emotionUseTheme();
  const breakpoint = useBreakpoint();
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);
  const [storeState, updateStore] = useStoreValue();
  const { trackEvent } = useEventTracking();
  const { handleErrorCallback } = useErrorTrackingAndUpdateStore();
  const initialValues = useFormInitialValues(FORM_ID);
  const { validateOffer } = useValidateOffers({ onError: handleErrorCallback });

  const [isAdblockCheckComplete, isAdblockerDetected] = useAdblockDetector(
    `//tags.tiqcdn.com/utag/latitudefs/apply-funnel/${process.env.REACT_APP_TEALIUM_ENV}/utag.sync.js`,
  );

  const nzRatesAndFees = getRatesAndFees();
  const cashAnnualRate = nzRatesAndFees?.cashAnnualRate;
  const annualFee = nzRatesAndFees?.annualFee;
  const biAnnualFee = annualFee / 2;
  const currentInterestRate = nzRatesAndFees?.purchaseAnnualRate;
  const establishmentFee = nzRatesAndFees?.establishmentFee;
  const cashWithdrawalFee = nzRatesAndFees?.cashWithdrawalFee;
  const trackApplicationReasonChange = useCallback(
    ({ selectedOption: { value, label } }) => {
      trackEvent({
        event: {
          category: 'application',
          location: formTealiumLocation,
          action: 'interaction',
          label,
        },
        ...getDataLayerElements(storeState),
      });
    },
    [storeState, trackEvent],
  );

  useEffect(() => {
    window.DD_RUM?.startSessionReplayRecording();
    if (isFeatureOn('balanceTransfer') && storeState?.applyAndBuy?.promoCode) {
      validateOffer(handleErrorCallback);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formConfig = useMemo(
    () =>
      config.generateOverviewFormConfig({
        TermAndConditions,
        StopperMessage,
        trackApplicationReasonChange,
      }),
    [trackApplicationReasonChange],
  );

  const handleSubmitSuccess = data => {
    const updates = {
      applicationRef: data.applicationRef,
      applicationId: data.applicationId,
      isResuming: false,
    };

    trackEvent({
      event: {
        category: 'application',
        action: 'application-started',
        location: formTealiumLocation,
        label: APPLY_BTN_LABEL,
      },
      ...getDataLayerElements({
        ...storeState,
        ...updates,
      }),
    });

    updateStore({
      applicationErrors: null,
      activeStep: STEP_CONTACT_DETAILS,
      ...updates,
    });

    history.push(STEP_CONTACT_DETAILS);
  };

  const { submit, loading } = useInitApplication({
    onSuccess: handleSubmitSuccess,
    onError: handleErrorCallback,
  });

  const onSubmit = ({ values, errors }) => {
    function initialiseApplication() {
      const applicationStartDate = new Date().toISOString();
      const newAcquisition = {
        ...storeState.acquisition,
        overview: { ...values },
      };
      updateStore({ applicationStartDate, acquisition: newAcquisition });
      submit(values);
    }
    submitHandler({ submit: initialiseApplication, errors });
  };

  // const handleResumeApplication = () => {
  //   updateStore({
  //     activeStep: '/resume-your-application',
  //     isResuming: true,
  //   });

  //   trackEvent({
  //     event: {
  //       category: 'application',
  //       action: 'resume-application',
  //       location: formTealiumLocation,
  //       label: RESUME_BTN_LABEL,
  //     },
  //     ...getDataLayerElements(storeState),
  //   });

  //   history.push('/resume-your-application');
  // };

  const retrySubmit = () => {
    submit(storeState.acquisition.overview);
  };

  const openFeatureAndBenefitsModal = () => {
    setModalOpen(true);
    trackEvent({
      event: {
        category: 'text-link',
        action: 'interaction',
        location: pageTealiumLocation,
        label: 'View features and benefits',
      },
      ...getDataLayerElements(storeState),
    });
  };

  const closeFeatureAndBenefitsModal = () => {
    setModalOpen(false);
  };

  const scrollTo = targetName => {
    scroller.scrollTo(targetName, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
    });
  };

  if (storeState.applicationErrors) {
    const retry = [APOLLO_NETWORK_ERROR, APOLLO_UNKNOWN_ERROR].includes(storeState.applicationErrors.type)
      ? retrySubmit
      : null;
    return (
      <LayoutPage hideBackButton noChildrenPadding>
        <Errors retry={retry} retrying={loading} isPreSubmission />
      </LayoutPage>
    );
  }

  const srcMobile = groupLFrameCardImageMobile;
  const src = groupLFrameCardImage;
  return (
    <LayoutPage noSessionTimeout={!storeState.acquisition.overview.consentGiven} hideBackButton noChildrenPadding>
      <FeaturesAndBenefitsModal isOpen={modalOpen} onClose={closeFeatureAndBenefitsModal} />
      <ContentWrapper theme={theme}>
        <Container width="100%" padding="0" bg="secondary.lightBlue.t15" padding-lg="0">
          <Grid preset="page">
            <Grid.Item colSpan="all" colSpan-sm="all" colSpan-md="5-11" rowSpan-md="0-2">
              <Hero src={src} srcMobile={srcMobile} alt="LFrame card" />
            </Grid.Item>

            <Grid.Item colSpan="all" colSpan-sm="all" colSpan-md="0-4" rowSpan-md="0-2">
              <HeaderBannerTextArea openFeatureAndBenefitsModal={openFeatureAndBenefitsModal} />
            </Grid.Item>
          </Grid>
        </Container>

        <Container bg="white" margin-md="auto">
          <Grid colsGutter="0">
            <Grid.Item colSpan="all" colSpan-md="0-3" colSpan-lg="0-6" rowSpan="0" rowSpan-md="0" margin-md="0">
              <EligibilityAndInformationTextArea />
            </Grid.Item>
            <Grid.Item colSpan="all" colSpan-md="4-7" colSpan-lg="7-13" rowSpan="1" rowSpan-md="0">
              <div style={['lg', 'xl'].includes(breakpoint) ? { minWidth: 610 } : {}}>
                <Container
                  bg="white"
                  padding="0"
                  padding-sm="0"
                  padding-md="4 10 4 6"
                  padding-lg="4 10 4 10"
                  padding-xl="6 8 6 10"
                >
                  <Form id={FORM_ID} initialValues={initialValues} onSubmit={onSubmit} hideFormButtons disableFormCache>
                    {formData => {
                      // const isValid = config.isValidOverview(formData);
                      const isHidden = config.isSubmitHiddenOnOverview(formData);
                      return (
                        <>
                          <IconTitleWrapper theme={theme}>
                            <Text as="h2" textStyle="heading4" margin="6 0 3 0">
                              {formHeader}
                            </Text>
                          </IconTitleWrapper>
                          <Grid rowsGap="4">
                            <FormRenderer config={formConfig} formData={formData} />
                          </Grid>
                          <Container>
                            {!isHidden && (
                              <Container margin="6 0" width-md="100%">
                                <StartApplicationButton
                                  disabled={
                                    isAdblockCheckComplete &&
                                    isAdblockerDetected &&
                                    !DEV_FEATURES.OVERRIDE_AD_BLOCKER_STOP
                                  }
                                  loading={loading}
                                >
                                  {APPLY_BTN_LABEL}
                                </StartApplicationButton>
                              </Container>
                            )}
                            {/* TODO: we are not going to support landing page Resume button for Day 2 release. Also, we likely are going to have a new design for the resume button
                            {isFeatureOn('saveAndResume') && (
                              <Container margin="6 0" width-md="100%">
                                <Button
                                  disabled={
                                    isAdblockCheckComplete &&
                                    isAdblockerDetected &&
                                    !DEV_FEATURES.OVERRIDE_AD_BLOCKER_STOP
                                  }
                                  width="100%"
                                  variant={
                                    !(
                                      isAdblockCheckComplete &&
                                      isAdblockerDetected &&
                                      !DEV_FEATURES.OVERRIDE_AD_BLOCKER_STOP
                                    )
                                      ? 'secondary'
                                      : 'primary'
                                  }
                                  onClick={handleResumeApplication}
                                >
                                  {RESUME_BTN_LABEL}
                                </Button>
                              </Container>
                            ) */}
                          </Container>
                          {isAdblockCheckComplete && isAdblockerDetected && !DEV_FEATURES.OVERRIDE_AD_BLOCKER_STOP && (
                            <Message severity="stop" bg="secondary.pink.t30">
                              Please turn off your adblocker for the application to work and refresh your browser once
                              it is done.
                            </Message>
                          )}
                        </>
                      );
                    }}
                  </Form>
                </Container>
              </div>
            </Grid.Item>
          </Grid>
        </Container>

        {config.countryCode === 'NZ' && (
          <>
            <ImportantFeatures smallDevices scrollTo={scrollTo} SaleIcon={SaleIcon} HandIcon={HandIcon} theme={theme} />
            <Container>
              <Container hasBreakpointWidth bg="secondary.lightBlue.t15" padding="6 8" margin="0 0 8 0">
                <Container padding="0 0 4 0">
                  <Container margin="0 0 4 0">
                    <Text align="left">
                      <strong>Important information</strong>
                    </Text>
                  </Container>

                  <Container>
                    <Element name="anchor_a">
                      <Text textStyle="legal" align="left">
                        *Normal credit and lending criteria and fees, including a Gem Visa ${establishmentFee}{' '}
                        establishment fee and ${annualFee} annual fee (charged ${biAnnualFee?.toFixed(2)} half-yearly)
                        apply. Prevailing interest rate (currently {currentInterestRate}% p.a.) applies to any remaining
                        balance on the expiry of the interest free period. Paying only the minimum monthly repayment of
                        3% of the outstanding monthly balance or $20 (whichever is more), will not be sufficient to
                        repay the purchase amount(s) within the promotional period. Amount payable will be shown on your
                        monthly statement. For cash advances, an interest rate of {cashAnnualRate}% p.a. and a $
                        {cashWithdrawalFee} fee applies. Further information on rates and fees can be found at{' '}
                        <InternalLink
                          label={overviewSources.gemfinance_url.label}
                          url={overviewSources.gemfinance_url.url}
                          eventConfig={internalLinkEventConfig}
                          newTab
                        />
                        . Available to approved Gem Visa credit card customers only. Credit provided by Latitude
                        Financial Services Ltd.
                      </Text>
                    </Element>

                    <br />

                    <Element name="anchor_b">
                      <Text textStyle="legal" align="left">
                        Long term interest free offers may vary. Available at participating retailers to approved
                        customers only. Conditions, fees and charges may apply. Ask in-store for further details.
                      </Text>
                    </Element>
                  </Container>
                </Container>

                <Container padding="0 0 6 0">
                  <Container margin="0 0 4 0">
                    <Text align="left">
                      <strong>Financial Advice – Prescribed Statement Disclosure</strong>
                    </Text>
                  </Container>

                  <Container>
                    <Text textStyle="legal" align="left">
                      You are protected by responsible lending laws. Because of these protections, the recommendations
                      given to you by us about personal loans, credit cards and any credit related insurance products
                      are not regulated financial advice. This means that duties and requirements imposed on people who
                      give financial advice do not apply to these recommendations. This includes a duty to comply with a
                      code of conduct and a requirement to be licensed.
                    </Text>
                  </Container>
                </Container>
              </Container>
            </Container>
          </>
        )}
      </ContentWrapper>
    </LayoutPage>
  );
}

function TermAndConditions() {
  return (
    <>
      <Text>By starting this application, I confirm:</Text>
      <br />
      <List>
        <List.Item>
          I meet the eligibility criteria and I understand I am applying for a general-purpose credit card which I
          require on an ongoing basis.
        </List.Item>
        <List.Item>
          I have read and understand the{' '}
          <InternalLink
            label={overviewSources.important_information.label}
            url={overviewSources.important_information.url}
            eventConfig={internalLinkEventConfig}
            newTab
          />{' '}
          relating to Gem Visa credit card and I agree to the{' '}
          <InternalLink
            label={overviewSources.privacy_acknowledgement_and_authorisation.label}
            url={overviewSources.privacy_acknowledgement_and_authorisation.url}
            eventConfig={internalLinkEventConfig}
            newTab
          />
          {', '}
          <InternalLink
            label={overviewSources.gem_visa_conditions_of_use.label}
            url={overviewSources.gem_visa_conditions_of_use.url}
            eventConfig={internalLinkEventConfig}
            newTab
          />
          , and the generic product{' '}
          <InternalLink
            label={overviewSources.initial_disclosure_statement.label}
            url={overviewSources.initial_disclosure_statement.url}
            eventConfig={internalLinkEventConfig}
            newTab
          />
          .
        </List.Item>
      </List>
    </>
  );
}

function StopperMessage() {
  return (
    <>
      Please be advised this may not be the right product for you. You can{' '}
      <InternalLink
        label={overviewSources.visit_our_website.label}
        url={overviewSources.visit_our_website.url}
        eventConfig={internalLinkEventConfig}
        newTab
      />{' '}
      to decide which product suits your needs best.
    </>
  );
}
