export function toConstantCase(str) {
  if (typeof str !== 'string') {
    return str;
  }

  return str
    .replace(/([a-z])([A-Z])/g, '$1_$2')
    .toUpperCase()
    .replace(/[^A-Z0-9]+/g, '_');
}

export function compareUrlPathWithStep(step, pathname) {
  const path = pathname.length > 1 && pathname.endsWith('/') ? pathname.slice(0, -1) : pathname;
  return step.toLowerCase() === path.toLowerCase();
}
