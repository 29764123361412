export const titleOptionsNZ = [
  {
    value: 'MR',
    label: 'Mr',
  },
  {
    value: 'MRS',
    label: 'Mrs',
  },
  {
    value: 'MISS',
    label: 'Miss',
  },
  {
    value: 'MS',
    label: 'Ms',
  },
  {
    value: 'DR',
    label: 'Dr',
  },
];

export const titleOptionsAU = [
  {
    value: 'MR',
    label: 'Mr',
  },
  {
    value: 'MRS',
    label: 'Mrs',
  },
  {
    value: 'MISS',
    label: 'Miss',
  },
  {
    value: 'MS',
    label: 'Ms',
  },
];
