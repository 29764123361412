import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useHandleApiErrors } from './useHandleApiErrors';

export const CREATE_BANK_CONNECT_SESSION_GQL = gql`
  mutation createBankConnectSession($input: CreateBankConnectSessionInput!) {
    createBankConnectSession(input: $input) {
      guid
      userToken
      assessmentId
    }
  }
`;

const useCreateBankConnectSession = ({ onSuccess, onError }) => {
  const { handleError } = useHandleApiErrors('apollo', onError);
  const [createBankConnectSession, { loading }] = useMutation(CREATE_BANK_CONNECT_SESSION_GQL, {
    onCompleted: data => {
      onSuccess(data.createBankConnectSession);
    },
    onError: err => {
      handleError(err);
    },
  });

  const createSession = useCallback(
    applicationId => {
      if (applicationId) {
        createBankConnectSession({ variables: { input: { applicationId } } });
      }
    },
    [createBankConnectSession],
  );

  return {
    createSession,
    loading,
  };
};

export { useCreateBankConnectSession };
