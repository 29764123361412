import React from 'react';
import styled from '@emotion/styled';

const MessageWrapper = styled.div`
  & div[data-testid='green-message'] {
    background-color: #def8e5;
  }
`;
export const GreenMessage = props => {
  const { children } = props;
  return <MessageWrapper>{children}</MessageWrapper>;
};
