import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useErrorTracking } from 'hooks';
import { config } from '_config';
import { useStoreValue } from 'store';
import { isFeatureOn } from 'featureToggles';
import { useHandleApiErrors } from './useHandleApiErrors';

export const SAVE_DRAFT_GQL = gql`
  mutation saveDraft($input: SaveDraftInput!) {
    saveDraft(input: $input) {
      applicationId
    }
  }
`;

export function useSaveDraft({ onSuccess, onError } = {}) {
  const { handleErrorTracking } = useErrorTracking('hook:useSaveDraft');
  const { handleError } = useHandleApiErrors('hook:useSaveDraft', onError);
  const [store] = useStoreValue();
  const [commitMutation, { loading }] = useMutation(SAVE_DRAFT_GQL, {
    onCompleted: onSuccess
      ? data => {
          onSuccess(data.saveDraft);
        }
      : undefined,
    onError: err => {
      const { oktaFactor, ...draftStoreState } = store;
      // eslint-disable-next-line no-console
      console.error(`saveDraft API error: ${JSON.stringify(err)},
        applicationId: ${store.applicationId},
        productCode: ${config.productCode},
        hasDraft: ${!!draftStoreState}`);
      handleErrorTracking(err);
      if (onError) {
        handleError(err);
      }
    },
  });

  const saveDraft = useCallback(
    storeState => {
      if (!isFeatureOn('saveAndResume')) {
        return;
      }
      const { oktaFactor, ...draftStoreState } = storeState;
      commitMutation({
        variables: {
          input: {
            applicationID: storeState.applicationId,
            productCode: config.productCode,
            draft: JSON.stringify(draftStoreState),
          },
        },
      });
    },
    [commitMutation],
  );

  return {
    saveDraft,
    savingDraft: loading,
  };
}
