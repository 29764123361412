import React from 'react';
import { Text as BasisV1Text, useTheme } from 'basis';

/**
 * <Text> component from Basis with an overridden default color prop.
 * The default font color is textStyle based and sourced by `theme.fontSizes[].defaultColor`.
 */
export const Text = ({ children, ...props }) => {
  const { textStyle = 'body1', color } = props;
  const theme = useTheme();
  const defaultColor = theme.textStyles[textStyle].defaultColor || 'black';
  const finalColor = color || defaultColor;
  return (
    <BasisV1Text color={finalColor} {...props}>
      {children}
    </BasisV1Text>
  );
};
