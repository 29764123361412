import { yesNoOptions, numbersOnlyPattern } from '../_constants';

const dollarAmountValidationMessages = {
  required: "You must enter a dollar amount. If it's zero, please enter $0.",
  minLength: 'Must have at least {{minLength}} digits.',
  maxLength: 'Must have at most {{maxLength}} digits.',
  invalid: 'Please enter a whole dollar amount, without cents.',
};

const validationMessages = {
  dollarAmount: dollarAmountValidationMessages,
};

export const additionalDebtsDetails = [
  {
    component: 'RadioGroup',
    name: 'hasOtherDebts',
    label: 'Do you have any loans, credit cards, or overdrafts?',
    testId: 'has-other-debts',
    options: yesNoOptions,
    showCircles: true,
    validate: {
      messages: {
        required: 'Please select yes or no.',
      },
    },
  },
  {
    condition: values => values.hasOtherDebts === 'yes',
    name: 'hasOtherDebtsYes',
    fields: [
      {
        component: 'Frequency',
        name: 'loanRepayment',
        label: 'What are the loan repayments, excluding overdrafts?',
        testId: 'loan-repayment',
        mode: 'select',
        annually: false,
        quarterly: false,
        amountPrefix: '$',
        validate: {
          props: { validPattern: numbersOnlyPattern },
          messages: validationMessages.dollarAmount,
          component: 'Frequency',
        },
      },
      {
        component: 'Input',
        label: 'What’s the current balance outstanding on all your credit cards, overdrafts, finance and charge cards?',
        name: 'amountOwingOnCreditCard',
        testId: 'amount-owing-on-credit-cards',
        variant: 'numeric',
        prefix: '$',
        validate: {
          props: {
            minLength: 1,
            maxLength: 6,
            validPattern: numbersOnlyPattern,
          },
          messages: validationMessages.dollarAmount,
        },
      },
      {
        component: 'Input',
        label: 'What’s the total credit limit on these?',
        name: 'totalCardLimits',
        testId: 'total-card-limits',
        variant: 'numeric',
        prefix: '$',
        validate: {
          props: {
            minLength: 1,
            maxLength: 6,
            validPattern: numbersOnlyPattern,
          },
          messages: validationMessages.dollarAmount,
        },
      },
      {
        component: 'RadioGroup',
        name: 'hasLoanLumpSumPayment',
        label: 'Do any of your loans include a lump sum payment either during or at the end of the loan?',
        testId: 'has-lump-sum-payments',
        options: yesNoOptions,
        showCircles: true,
        validate: {
          messages: {
            required: 'Please select yes or no.',
          },
        },
      },
    ],
  },
  {
    condition: values => values.hasOtherDebts === 'yes' && values.hasLoanLumpSumPayment === 'yes',
    name: 'hasLoanLumpSumPaymentYes',
    fields: [
      {
        component: 'RadioGroup',
        name: 'payLumpSumWithExistingSaving',
        label: 'Do you intend to pay off the lump sum using existing savings, refinancing or returning the asset?',
        testId: 'pay-lump-sum-with-existing-saving',
        options: yesNoOptions,
        showCircles: true,
        validate: {
          messages: {
            required: 'Please select yes or no.',
          },
        },
      },
    ],
  },
];
