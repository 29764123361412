export function loadingIconTheme(theme, { getColor, getTextStyle }) {
  return {
    getCSS: ({ targetElement, color, isChecked }) => {
      switch (targetElement) {
        case 'loadingIcon': {
          return {
            color: theme.colors.secondary.blue.t100,
          };
        }

        default: {
          return null;
        }
      }
    },
  };
}
