import { useCallback } from 'react';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { useStoreValue } from 'store';
import { useErrorTrackingAndUpdateStore } from './useErrorTrackingAndUpdateStore';

export const RESEND_BIOMETRICS_SMS = gql`
  mutation useResendBiometricsSMS($input: ResendBiometricsSMSInput!) {
    resendBiometricsSMS(input: $input) {
      status
    }
  }
`;

export const GET_APPLICATION_GQL = gql`
  query getRemainingResendSMSAttempts($id: ID!) {
    application(id: $id) {
      remainingResendSMSAttempts
    }
  }
`;

export const useResendBiometricsSms = () => {
  const [storeState] = useStoreValue();
  const { handleErrorCallback: handleError } = useErrorTrackingAndUpdateStore(`hook:useResendBiometricsSms`);
  const [fetchApplication] = useLazyQuery(GET_APPLICATION_GQL, {
    fetchPolicy: 'network-only',
  });

  const [mutate, { loading }] = useMutation(RESEND_BIOMETRICS_SMS, {
    onError: error => {
      handleError(error);
    },
  });

  const resendBiometricsSms = useCallback(async () => {
    if (loading) {
      return null;
    }

    await mutate({
      variables: {
        input: {
          applicationId: storeState.applicationId,
        },
      },
    });

    const {
      data: { application },
    } = await fetchApplication({ variables: { id: storeState.applicationId } });

    return application.remainingResendSMSAttempts;
  }, [fetchApplication, loading, mutate, storeState.applicationId]);

  return {
    resendBiometricsSms,
    loading,
  };
};
