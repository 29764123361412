import React from 'react';
import { Grid, Container, Text, Icon, Flex, Link } from 'basis';
import { PRODUCT_CODE_DAVID_JONES_PREMIERE, PRODUCT_CODE_DAVID_JONES_PRESTIGE, config, OverviewSources } from '_config';
import { EarnRateTiers } from 'components';
import { useStoreValue } from 'store';
import { useDataLayerAndAnalyticsLinks } from 'hooks';
import { getRatesAndFees } from 'featureToggles';
import { LandingContentItem } from './LandingPageDJ.styles';

export const ProsAndCons = () => {
  const [storeState] = useStoreValue();
  const { trackEventSender } = useDataLayerAndAnalyticsLinks(storeState);

  const learnMoreLink = (
    <Link
      newTab
      href={OverviewSources.AU.learnMore.url}
      onClick={() =>
        trackEventSender({
          location: 'landing',
          action: 'external-link',
          label: OverviewSources.AU.learnMore.label,
        })
      }
    >
      {OverviewSources.AU.learnMore.label}
    </Link>
  );

  const content = {
    [PRODUCT_CODE_DAVID_JONES_PREMIERE]: {
      pros: [
        'A lower annual card fee than the David Jones Prestige credit card.',
        'Earn David Jones points -',
        'Access to David Jones sales, previews, complimentary express delivery, gift wrapping and more.',
        'Minimum credit limit is $1,000.',
      ],
      cons: [
        'Complimentary travel insurance not included.',
        'Additional cardholder available for a fee.',
        'If you have an existing David Jones credit card or David Jones store card, you don’t need to apply.',
      ],
    },
    [PRODUCT_CODE_DAVID_JONES_PRESTIGE]: {
      pros: [
        'Earn David Jones points quicker -',
        'Complimentary travel insurance',
        'Access to two airport lounge passes each year.',
        'Exclusive access to David Jones sales, previews and events, complimentary same day delivery, gift wrapping and more.',
      ],
      cons: [
        `A higher annual fee ($${getRatesAndFees().annualFee}) than the David Jones Premiere credit card.`,
        'Minimum credit limit requirement of $6,000.',
        'If you have an existing David Jones credit card or David Jones store card, you don’t need to apply.',
      ],
    },
  };

  return (
    <Grid colsGutter="0" cols="6" colsGap="6" colSpan="4" rowsGap-md="10">
      <Grid.Item colSpan="all" colSpan-md="0-2" colSpan-lg="0-2" rowSpan="0" rowSpan-md="0" margin-md="0">
        <Container margin="0 0 3 0" margin-md="0 0 6 0" padding="0">
          <Text textStyle="heading5" as="h6">
            Features & Benefits
          </Text>
        </Container>
        {content[config.productCode]?.pros?.map((item, index) => {
          return (index === 1 && config.productCode === PRODUCT_CODE_DAVID_JONES_PREMIERE) ||
            (index === 0 && config.productCode === PRODUCT_CODE_DAVID_JONES_PRESTIGE) ? (
            <LandingContentItem key={index}>
              <div>
                <Flex>
                  <div>
                    <Icon name="tick" color="black" />
                  </div>
                  <Text>{item}</Text>
                </Flex>
                <EarnRateTiers productCode={config.productCode} />
              </div>
            </LandingContentItem>
          ) : (
            <LandingContentItem key={index}>
              <div>
                <Icon name="tick" color="black" />
              </div>
              <Text>{item}</Text>
            </LandingContentItem>
          );
        })}
      </Grid.Item>
      <Grid.Item colSpan="all" colSpan-md="3-5" colSpan-lg="3-5" rowSpan="1" rowSpan-md="0">
        <Container margin="0 0 3 0" margin-md="0 0 6 0" padding="0">
          <Text textStyle="heading5" as="h6">
            Considerations
          </Text>
        </Container>
        <Container margin="0" margin-md="2 0" padding="0">
          {content[config.productCode]?.cons?.map((item, index) => (
            <LandingContentItem key={index}>
              <Flex>
                <div>
                  <Icon name="cross" color="black" />
                </div>
                <Text>
                  {item} {index === 2 ? learnMoreLink : null}
                </Text>
              </Flex>
            </LandingContentItem>
          ))}
        </Container>
      </Grid.Item>
    </Grid>
  );
};
