import startCase from 'lodash/startCase';
import {
  PENDING_CUSTOMER_ACCEPTANCE,
  PROCESSING_ACCEPTANCE,
  PENDING,
  CONDITIONALLY_APPROVED,
  PROOF_OF_ADDRESS,
  ANTI_MONEY_LAUNDERING,
  PROOF_OF_INCOME,
  SERVICE_UNAVAILABLE,
  ANTI_MONEY_LAUNDERING_AND_PROOF_OF_INCOME,
  REFERRED,
  PROCESSING_TIMEOUT,
  PROOF_OF_ADDRESS_AND_PROOF_OF_INCOME,
  POI_CHECK_REQUIRED,
  IDV_CHECK_REQUIRED,
  CCR_PENDING,
  CCR_REQUIRED,
} from 'store/constants';

/* eslint-disable no-plusplus */
export const getGender = title => {
  if (!title.length) {
    return '';
  }

  if (title === 'MR') {
    return 'Male';
  }

  if (title === 'DR' || (title !== 'MR' && title !== 'MS' && title !== 'MISS' && title !== 'MRS')) {
    return 'Unknown';
  }

  return 'Female';
};

export const getAge = (date, targetDate = new Date(), roundDown = true) => {
  if (!date) {
    return '';
  }

  const { day, month, year } = date;

  if (day === '' || month === '' || year === '') {
    return '';
  }

  const birthdate = `${year}-${month}-${day}`;

  const birthDate = new Date(birthdate);
  const years = targetDate.getFullYear() - birthDate.getFullYear();
  const months = targetDate.getMonth() - birthDate.getMonth();

  const age = years + months / 12;

  if (roundDown) {
    return Math.floor(age);
  }

  return age;
};

export const getEmploymentStatus = isSelfEmployed => {
  if (!isSelfEmployed) {
    return '';
  }

  return isSelfEmployed === 'yes' ? 'Self Employed' : 'Employed';
};

export const convertToYears = ({ years, months }, toFixed = 2) => {
  if (years === '' && months === '') {
    return '';
  }
  return (Math.round((parseFloat(years) + parseFloat(months / 12)) * 100) / 100).toFixed(toFixed);
};

export const getSelectorLabel = (options, value) => {
  return options.find(option => option.value === value)?.label ?? '';
};

export const getOutcomeReason = (applicationStatus, applicationStatusReason) => {
  if (applicationStatus === CONDITIONALLY_APPROVED) {
    switch (applicationStatusReason) {
      case PROOF_OF_ADDRESS:
        return 'ConditionalPOA';
      case ANTI_MONEY_LAUNDERING:
        return 'ConditionalIDV';
      case PROOF_OF_INCOME:
        return 'ConditionalPOI';
      case ANTI_MONEY_LAUNDERING_AND_PROOF_OF_INCOME:
        return 'ConditionalPOIandIDV';
      case PROOF_OF_ADDRESS_AND_PROOF_OF_INCOME:
        return 'ConditionalPOAandPOI';
      default:
        return null;
    }
  }
  if (applicationStatus === REFERRED) {
    switch (applicationStatusReason) {
      case SERVICE_UNAVAILABLE:
        return 'system-unavailable';
      case PROCESSING_TIMEOUT:
        return 'activate-timeout';
      default:
        return null;
    }
  }
  if (applicationStatus === POI_CHECK_REQUIRED) {
    return 'ConditionalPOI';
  }
  if (applicationStatus === IDV_CHECK_REQUIRED) {
    return 'ConditionalIDV';
  }
  return null;
};

// 'Approved', 'Declined', 'Referred', 'ConditionalApproval', 'Error', ''
export const getApplicationOutcome = outcome => {
  switch (outcome) {
    case PENDING:
    case PENDING_CUSTOMER_ACCEPTANCE:
    case PROCESSING_ACCEPTANCE:
    case CCR_PENDING:
    case CCR_REQUIRED:
      return '';
    case CONDITIONALLY_APPROVED:
    case PROOF_OF_INCOME:
    case ANTI_MONEY_LAUNDERING_AND_PROOF_OF_INCOME:
    case PROOF_OF_ADDRESS_AND_PROOF_OF_INCOME:
    case IDV_CHECK_REQUIRED:
    case POI_CHECK_REQUIRED:
      return 'ConditionalApproval';
    default:
      return startCase((outcome || '').toLowerCase());
  }
};
