import React from 'react';
import { Text } from 'basis';
import { useStoreValue } from 'store';

const ReplayName = props => {
  const [{ acquisition }] = useStoreValue();
  const { label, stateKey } = props;
  const { firstName, middleName, lastName } = acquisition[stateKey];
  return (
    <Text>
      <strong>{`${label} `}</strong> {`${firstName} ${middleName ? `${middleName} ` : ''}${lastName}`}
    </Text>
  );
};

export { ReplayName };
