import { useEffect, useCallback, useState, useMemo } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { useStoreValue, TEMPORARY_STATUSES } from 'store';
import { config, STEP_PROCESSING, STEP_CONFIRM_INCOME } from '_config';

import { useHistory } from 'react-router-dom';
import { ALL_APPLICATION_FIELDS } from 'gql';
import { isFeatureOn } from 'featureToggles';
import { useErrorTrackingAndUpdateStore } from '../useErrorTrackingAndUpdateStore';
import { useSubmitSavedApplication } from '../useSubmitSavedApplication';
import { calculateNextStep } from './calculateNextStep';
import { updateStoreAndRedirect } from './updateStoreAndRedirect';

/**
 * Wait for an application's status changes to a new state. Then process the result and redirect to the next page.
 * Long polling is used to check the status of the application via the `application` GraphQL query.
 * It considers the status is new when the status is not one of the temporary statuses from constant `TEMPORARY_STATUSES`,
 * or the status is changed from the first fetched initial status.
 */
export function useWaitAndRedirect({
  applicationId,
  testId,
  isNzProcessingPOI = false,
  longPolling = true,
  startOnMount = true,
  calculateNextStep: argCalculateNextStep,
}) {
  const GET_APPLICATION_GQL = useMemo(
    () => gql`
      ${ALL_APPLICATION_FIELDS}

      query ${testId}${config.countryCode}($id: ID!) {
        application(id: $id) {
          ...AllFields
        }
      }
    `,
    [testId],
  );

  const history = useHistory();
  const [initialStatus, setInitialStatus] = useState();
  const [loading, setLoading] = useState(false);
  const [storeState, updateStore] = useStoreValue();
  const { handleErrorCallback: handleError } = useErrorTrackingAndUpdateStore(
    `hook:useWaitAndProcess:${testId}:${config.countryCode}`,
  );
  const { submit: submitSavedApplication } = useSubmitSavedApplication({
    storeState,
    onError: handleError,
    onSuccess: application => {
      const nextStep = argCalculateNextStep ? argCalculateNextStep(application, storeState) : STEP_PROCESSING;
      updateStoreAndRedirect({ application, nextStep, updateStore, history, storeState });
    },
  });
  const onNewStatus = useCallback(
    application => {
      if (isNzProcessingPOI) {
        if (isFeatureOn('incomeReplay')) {
          const nextStep = argCalculateNextStep ? argCalculateNextStep(application, storeState) : STEP_CONFIRM_INCOME;
          updateStoreAndRedirect({ application, nextStep, updateStore, history, storeState });
        } else {
          submitSavedApplication({ incomeVerificationType: storeState.incomeVerificationType });
        }
      } else {
        const nextStep = argCalculateNextStep
          ? argCalculateNextStep(application, storeState)
          : calculateNextStep(application, storeState);
        updateStoreAndRedirect({ application, nextStep, updateStore, history, storeState });
      }
    },
    [argCalculateNextStep, history, isNzProcessingPOI, storeState, submitSavedApplication, updateStore],
  );

  const [query, { data, error, stopPolling, refetch }] = useLazyQuery(GET_APPLICATION_GQL, {
    pollInterval: 1000,
    fetchPolicy: 'network-only',
  });

  const start = useCallback(() => {
    if (!applicationId) {
      throw new Error(`Unexpected applicationId: ${applicationId}`);
    }

    if (loading === false) {
      setLoading(true);
      query({ variables: { id: applicationId } });
    }
  }, [applicationId, loading, query]);

  useEffect(() => {
    if (startOnMount) {
      start();
    }
  }, [applicationId, query, start, startOnMount]);

  useEffect(() => {
    if (data) {
      const { application } = data;

      if (!initialStatus) {
        setInitialStatus(application.status);
      }

      // when NZ processing POI, we are waiting for response to have `proofOfIncomeVerified: true` instead of status change
      const isNzPoiVerified = application.proofOfIncomeVerified === true;
      const isStatusChanged = application.status !== initialStatus && initialStatus !== undefined;
      const isNotTemporaryStatus = !TEMPORARY_STATUSES.includes(application.status);

      // In AU saveAndSubmit mutation, the application status is firstly updated from CREATED to SAVED, and then Activate does callback
      // to Pavlova and update status again from SAVED to PENDING. Apply UI should display the same /processing page during these two status.
      // To avoid getting into rerender-loop when application changes from SAVED to PENDING, we check if the new step is already in the store.
      const nextStep = argCalculateNextStep
        ? argCalculateNextStep(application, storeState)
        : calculateNextStep(application, storeState);
      const hasNewStep = nextStep !== storeState.activeStep;

      const isReadyForNextStep = (isNotTemporaryStatus || isStatusChanged) && hasNewStep;

      if (!longPolling || (isNzProcessingPOI ? isNzPoiVerified : isReadyForNextStep)) {
        stopPolling();
        setLoading(false);
        onNewStatus(application);
      }
      return;
    }
    if (error) {
      stopPolling();
      setLoading(false);
      handleError(error);
    }
  }, [
    data,
    error,
    stopPolling,
    onNewStatus,
    handleError,
    isNzProcessingPOI,
    initialStatus,
    longPolling,
    argCalculateNextStep,
    storeState,
  ]);

  const retry = useCallback(() => {
    setLoading(true);
    refetch();
  }, [refetch]);

  const stop = useCallback(() => {
    stopPolling();
    setLoading(false);
  }, [stopPolling]);

  return {
    loading,
    retry,
    stop,
    start,
  };
}
