import React, { useEffect, useState } from 'react';
import { Input, Button, Icon, VisuallyHidden, Text } from 'basis';
import {
  validVerbalPasswordCharsPattern,
  validVerbalPasswordLettersPattern,
  validVerbalPasswordNumbersPattern,
  validVerbalPasswordMinLength,
  validVerbalPasswordMaxLength,
} from '_config';
import styled from '@emotion/styled';
import { useFocusOnFirstFormElement } from '../../hooks/useFocusOnFirstFormElement';

const Container = styled.div`
  position: relative;
  & > button {
    position: absolute !important;
    top: 31px;
    right: 0px;
  }
`;

const ValidationContainer = styled.div`
  padding-top: 8px;
  display: grid;
  grid-template-columns: 50% 50%;
  column-gap: 16px;
  row-gap: 8px;
`;

const ValidationRule = styled.div`
  display: flex;
  align-items: center;
  & p {
    color: ${p => (p.isValid ? '#1b633c' : p.formHasErrors ? '#CF000FFF' : '#000000')};
  }
  & strong {
    white-space: nowrap;
  }
`;

const ValidationCheck = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  border: 1px solid ${p => (p.isValid ? '#1b633c' : p.formHasErrors ? '#CF000FFF' : '#d6d6d6')};
  background-color: ${p => (p.isValid || p.formHasErrors ? '#ffffff' : '#ececec')};
  margin-right: 8px;
`;

const validPasswordCharsRegex = new RegExp(validVerbalPasswordCharsPattern);
const validPasswordLettersRegex = new RegExp(validVerbalPasswordLettersPattern);
const validPasswordNumbersRegex = new RegExp(validVerbalPasswordNumbersPattern);

export function Password({ name, label, testId, validate, maxLength, helpText, formData }) {
  const [state, setState] = useState({
    hasValidLength: false,
    hasValidChars: false,
    hasValidLetters: false,
    hasValidNumbers: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);

  const isValidInput = state.hasValidLength && state.hasValidChars && state.hasValidLetters && state.hasValidNumbers;

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  useFocusOnFirstFormElement();
  useEffect(() => {
    const value = formData.state.values.password;

    setState({
      hasValidLength: value.length >= validVerbalPasswordMinLength && value.length <= validVerbalPasswordMaxLength,
      hasValidChars: value.length && validPasswordCharsRegex.test(value),
      hasValidLetters: value.length && validPasswordLettersRegex.test(value),
      hasValidNumbers: value.length && validPasswordNumbersRegex.test(value),
    });

    const hasFormErrors = !!Object.keys(formData.state.errors).length;
    setHasErrors(hasFormErrors);
  }, [formData.state]);

  return (
    <>
      <Container>
        <Input
          name={name}
          label={label}
          type={showPassword ? 'text' : 'password'}
          autocomplete="new-password"
          maxLength={maxLength}
          validate={validate}
          testId={testId}
          helpText={helpText}
        />
        <Button variant="icon" onClick={toggleShowPassword}>
          {showPassword ? (
            <>
              <Icon name="eye-visible" color="grey.t75" />
              <VisuallyHidden>hide password</VisuallyHidden>
            </>
          ) : (
            <>
              <Icon name="eye-hidden" color="grey.t75" />
              <VisuallyHidden>show password</VisuallyHidden>
            </>
          )}
        </Button>
        <ValidationContainer>
          {[
            { label: '6 - 15 characters', isValid: state.hasValidLength },
            { label: '2 letters', isValid: state.hasValidLetters },
            { label: 'No special characters', isValid: state.hasValidChars },
            { label: '2 numbers', isValid: state.hasValidNumbers },
          ].map(rule => (
            <ValidationRule key={rule.label} isValid={rule.isValid} formHasErrors={hasErrors}>
              <ValidationCheck isValid={rule.isValid} formHasErrors={hasErrors}>
                {rule.isValid ? (
                  <Icon name="tick" color="conditional.positive.graphics" />
                ) : hasErrors ? (
                  <Icon name="cross" color="conditional.negative.graphics" />
                ) : null}
              </ValidationCheck>
              <Text textStyle="body2">
                <strong>{rule.label}</strong>
              </Text>
            </ValidationRule>
          ))}
        </ValidationContainer>
      </Container>
      <Button type="submit" disabled={!isValidInput}>
        Next
      </Button>
    </>
  );
}
