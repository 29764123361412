import nzDriverLicenceBack from 'images/NZ-Driver-Back.png';
import nzDriverLicenceFront from 'images/NZ-Driver-Front.png';
import {
  countryOptions,
  validLicenceNumberPattern,
  numbersOnlyPattern,
  validResidencyNumberLetterPattern,
} from '../_constants';
import { idTypeOptions, minExpiryDate } from './constants';

const licenceNumberValidationMessages = {
  required: 'Please enter your licence number.',
  fixedLength: 'Must have {{fixedLength}} characters.',
  invalid: 'Invalid Licence Number.',
};

const versionValidationMessages = {
  required: 'Please enter the 3 digit version number.',
  fixedLength: 'Must have {{fixedLength}} characters.',
  invalid: 'Invalid Licence Version.',
};

const idNumberValidationMessages = {
  required: 'Please enter your ID number.',
  minLength: 'Must have at least {{minLength}} characters.',
  maxLength: 'Must have at most {{maxLength}} characters.',
  invalid: 'Invalid ID Number.',
};

const nzResidencyNumberValidationMessages = {
  required: 'Please enter your NZ Residency number.',
  minLength: 'Must have at least {{minLength}} characters.',
  maxLength: 'Must have at most {{maxLength}} characters.',
  invalid: 'Invalid ID Number.',
};

export const numberOfDependentsValidationMessages = {
  required: 'Please enter your number of dependants.',
  minValue: 'Your number of dependants must be {{minValue}} or more.',
  maxValue: 'Your number of dependants must be {{maxValue}} or less.',
  invalid: 'Please enter numbers only.',
};

const expiryDateValidationMessages = {
  required: 'Please enter your expiry date.',
  invalid: 'Please enter a valid date.',
  completeYear: 'Please enter the complete year YYYY.',
  minDate: 'Please enter a future date.',
  dayError: 'Day must be within 1-31.',
  monthError: 'Month must be within 1-12.',
};

const validationMessages = {
  licenceNumber: licenceNumberValidationMessages,
  version: versionValidationMessages,
  idNumber: idNumberValidationMessages,
  nzResidencyNumber: nzResidencyNumberValidationMessages,
  numberOfDependents: numberOfDependentsValidationMessages,
  expiryDate: expiryDateValidationMessages,
};

export const biometricsIdentification = [
  {
    component: 'Select',
    name: 'idType',
    label: 'Identification Document',
    options: idTypeOptions,
    testId: 'identification',
    validate: {
      messages: {
        required: 'Please choose an I.D. to help us verify you.',
      },
    },
  },
  {
    condition: values => values.idType === 'NZ_DRIVERS_LICENCE',
    component: 'InfoBlock',
    name: 'driversLicenseInfoBlock',
    images: [
      { url: nzDriverLicenceFront, alt: 'nz licence front', width: 190, height: 123 },
      { url: nzDriverLicenceBack, alt: 'nz licence back', width: 190, height: 123 },
    ],
  },
  {
    condition: values => values.idType === 'NZ_DRIVERS_LICENCE' || values.idType === 'NZ_FIREARM_LICENCE',
    component: 'Input',
    name: 'licenceNumber',
    label: 'Licence number',
    testId: 'licence-number',
    validate: {
      props: { fixedLength: 8, validPattern: validLicenceNumberPattern },
      messages: validationMessages.licenceNumber,
    },
  },
  {
    condition: values => values.idType === 'NZ_DRIVERS_LICENCE',
    component: 'Input',
    name: 'version',
    label: 'Version',
    testId: 'version',
    validate: {
      props: { fixedLength: 3, validPattern: numbersOnlyPattern },
      messages: validationMessages.version,
    },
  },
  {
    condition: values =>
      values.idType !== 'NZ_DRIVERS_LICENCE' && values.idType !== 'NZ_FIREARM_LICENCE' && values.idType !== '',
    component: 'Input',
    name: 'idNumber',
    label: 'ID number',
    testId: 'id-number',
    validate: {
      props: { minLength: 5, maxLength: 16, validPattern: validResidencyNumberLetterPattern },
      messages: validationMessages.idNumber,
    },
  },
  {
    condition: values => values.idType !== '',
    component: 'DatePicker',
    name: 'expiryDate',
    label: 'Expiry date',
    testId: 'expiry-date',
    day: true,
    validate: {
      props: { minDate: minExpiryDate, validPattern: numbersOnlyPattern },
      messages: validationMessages.expiryDate,
      component: 'DatePicker',
    },
  },
  {
    condition: values => values.idType === 'OTHER_PASSPORT',
    component: 'Input',
    name: 'nzResidencyNumber',
    label: 'NZ Residency number',
    testId: 'nz-residency-number',
    validate: {
      props: { minLength: 1, maxLength: 10, validPattern: numbersOnlyPattern },
      messages: validationMessages.nzResidencyNumber,
    },
  },
  {
    condition: values => values.idType === 'OTHER_PASSPORT' || isAUOrNZFirearm(values.idType),
    component: 'Select',
    name: 'issuingCountry',
    label: 'Issuing country',
    testId: 'issuing-country',
    options: values =>
      isAUOrNZFirearm(values.idType)
        ? countryOptions.filter(c => c.value === 'AU' || c.value === 'NZ')
        : countryOptions,
  },
];

const isAUOrNZFirearm = idType => ['NZ_FIREARM_LICENCE', 'AU_PASSPORT'].includes(idType);
