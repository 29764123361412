const TEALIUM_URL = `//tags.tiqcdn.com/utag/latitudefs/apply-funnel/${process.env.REACT_APP_TEALIUM_ENV}/utag.js`;

export const loadTealium = () => {
  try {
    new Promise((resolve, reject) => {
      const s = document.createElement('script');
      s.src = TEALIUM_URL;
      s.async = true;
      s.onload = resolve;
      s.onerror = reject;
      document.head.appendChild(s);
    }).then(() => {
      window.utag_cfg_ovrd = window.utag_cfg_ovrd || {};
      window.utag_cfg_ovrd.noview = true;
      document.dispatchEvent(new Event('utag-loaded'));
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error loading Tealium', e);
  }
};
