import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Container, Grid, Text, useBreakpoint, List, Message, Link } from 'basis';
import { Global } from '@emotion/core';
import { useTheme as emotionUseTheme } from '@emotion/react';
import { LayoutPage, Form, Errors, ButtonToggle } from 'components';
import HomeIcon from 'images/icons/home.svg';
import MoneyIcon from 'images/icons/finance-pay-manual.svg';
import ProfileIcon from 'images/icons/profile-single.svg';
import WalletIcon from 'images/icons/finance-wallet.svg';
import { useStoreValue } from 'store';
import { submitHandler, byCard, getProductInfo } from 'utils';
import { isFeatureOn } from 'featureToggles';
import {
  config,
  STEP_CONTACT_DETAILS,
  ProductComparisonLinks,
  PRODUCT_CODE_DAVID_JONES_PRESTIGE,
  PRODUCT_CODE_DAVID_JONES_PREMIERE,
  APPLY_BTN_LABEL,
} from '_config';
import {
  useInitApplication,
  useErrorTrackingAndUpdateStore,
  useFormInitialValues,
  useDataLayerAndAnalyticsLinks,
  useApplyAndBuyQueryParams,
  useValidateOffers,
} from 'hooks';
import DJBanner from 'images/dj-hero.png';
import PremiereCardImg from 'images/premiere-card.png';
import PrestigeCardImg from 'images/prestige-card.png';
import { ContentWrapper, Banner, LandingContentItem, BannerImage, CardImage } from './LandingPageDJ.styles';
import { FeaturesAndBenefitsModal } from './FeaturesAndBenefitsModal';
import { VerifierModal } from './VerifierModal';
import { DeclarationModal } from './DeclarationModal';
import { PrivacyNoticeModal } from './PrivacyNoticeModal';
import { HeaderBannerTextArea } from './HeaderBannerTextArea';
import { useAdblockDetector } from '../../hooks/useAdblockDetector';
import { StartApplicationButton } from './StartApplicationButton';
import { DEV_FEATURES } from '../../__dev';
import { ProsAndCons } from './ProsAndCons';

const FORM_ID = 'overview';
const formTealiumLocation = 'landing';

export function LandingPageDJ() {
  const theme = emotionUseTheme();
  const breakpoint = useBreakpoint();
  const history = useHistory();
  const [featureModalOpen, setFeatureModalOpen] = useState(false);
  const [verifierModalOpen, setVerifierModalOpen] = useState(false);
  const [declarationModalOpen, setDeclarationModalOpen] = useState(false);
  const [privacyNoticeModalOpen, setPrivacyNoticeModalOpen] = useState(false);
  const [started, setStarted] = useState(false);
  const [storeState, updateStore] = useStoreValue();
  const { trackEventSender } = useDataLayerAndAnalyticsLinks(storeState);
  const { handleErrorCallback } = useErrorTrackingAndUpdateStore();
  const { validateOffer } = useValidateOffers({ onError: handleErrorCallback });
  const initialValues = useFormInitialValues(FORM_ID);
  const {
    name: productName,
    urls: { conditionsOfUse: conditionsOfUseUrl },
  } = getProductInfo();
  const [isAdblockCheckComplete, isAdblockerDetected] = useAdblockDetector(
    `//tags.tiqcdn.com/utag/latitudefs/apply-funnel/${process.env.REACT_APP_TEALIUM_ENV}/utag.sync.js`,
  );
  const applyAndBuy = useApplyAndBuyQueryParams();

  useEffect(() => {
    window.DD_RUM?.startSessionReplayRecording();
    if (isFeatureOn('balanceTransfer') && storeState?.applyAndBuy?.promoCode) {
      validateOffer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!storeState.applyAndBuy.merchantId && applyAndBuy.merchantId) {
      updateStore({
        applyAndBuy: {
          ...storeState.applyAndBuy,
          merchantId: applyAndBuy.merchantId,
        },
      });
    }
  }, [applyAndBuy, storeState.applyAndBuy, storeState.applyAndBuy.merchantId, updateStore]);

  const externalLinkEventSender = () =>
    trackEventSender({
      action: 'external-link',
      label: productName,
      location: formTealiumLocation,
    });

  const handleSubmitSuccess = data => {
    const updates = {
      applicationRef: data.applicationRef,
      applicationId: data.applicationId,
      isResuming: false,
    };

    updateStore({
      applicationErrors: null,
      activeStep: STEP_CONTACT_DETAILS,
      ...updates,
    });

    trackEventSender(
      {
        category: 'application',
        action: 'application-started',
        location: formTealiumLocation,
        label: APPLY_BTN_LABEL,
      },
      updates,
    );

    history.push(STEP_CONTACT_DETAILS);
  };

  const { submit, loading } = useInitApplication({
    onSuccess: handleSubmitSuccess,
    onError: handleErrorCallback,
  });

  const onSubmit = ({ values, errors }) => {
    function initialiseApplication() {
      const applicationStartDate = new Date().toISOString();
      const newAcquisition = {
        ...storeState.acquisition,
        overview: {
          ...values,
          consentGiven: values.consentGiven === 'yes',
        },
      };
      updateStore({ applicationStartDate, acquisition: newAcquisition });
      submit(newAcquisition.overview);
    }
    submitHandler({ submit: initialiseApplication, errors });
  };

  const retrySubmit = () => {
    submit(storeState.acquisition.overview);
  };

  const openFeatureAndBenefitsModal = () => {
    setFeatureModalOpen(true);
    trackEventSender({
      category: 'button-link',
      action: 'interaction',
      location: formTealiumLocation,
      label: 'View features and benefits',
    });
  };

  const closeFeatureAndBenefitsModal = () => {
    setFeatureModalOpen(false);
  };

  const openVerifierModal = () => {
    setVerifierModalOpen(true);
    trackEventSender({
      action: 'interaction',
      location: formTealiumLocation,
      label: 'Verifier’s terms of service',
    });
  };

  const closeVerifierModal = () => {
    setVerifierModalOpen(false);
  };

  const openDeclarationModal = () => {
    setDeclarationModalOpen(true);
    trackEventSender({
      action: 'interaction',
      location: formTealiumLocation,
      label: 'Declaration and Credit Application',
    });
  };

  const closeDeclarationModal = () => {
    setDeclarationModalOpen(false);
  };

  const openPrivacyNoticeModal = () => {
    setPrivacyNoticeModalOpen(true);
    trackEventSender({
      action: 'interaction',
      location: formTealiumLocation,
      label: 'Privacy Notice',
    });
  };

  const closePrivacyNoticeModal = () => {
    setPrivacyNoticeModalOpen(false);
  };

  if (storeState.applicationErrors) {
    return (
      <LayoutPage hideBackButton noChildrenPadding>
        <Errors retry={retrySubmit} retrying={loading} isPreSubmission />
      </LayoutPage>
    );
  }

  return (
    <LayoutPage noSessionTimeout={!storeState.acquisition.overview.consentGiven} hideBackButton noChildrenPadding>
      <Global
        styles={{
          main: {
            display: 'block',
          },
        }}
      />
      <FeaturesAndBenefitsModal
        isOpen={featureModalOpen}
        onClose={closeFeatureAndBenefitsModal}
        trackEventSender={externalLinkEventSender}
      />
      <VerifierModal isOpen={verifierModalOpen} onClose={closeVerifierModal} />
      <DeclarationModal isOpen={declarationModalOpen} onClose={closeDeclarationModal} />
      <PrivacyNoticeModal isOpen={privacyNoticeModalOpen} onClose={closePrivacyNoticeModal} />
      <Banner>
        <Container width="100%" padding="0" padding-lg="0">
          <Grid preset="page">
            <Grid.Item colSpan="all" colSpan-sm="all" colSpan-md="6-11" rowSpan-md="0-2">
              <Container>
                <BannerImage theme={theme}>
                  <img src={DJBanner} alt="LFrame card" />
                </BannerImage>
              </Container>
            </Grid.Item>

            <Grid.Item colSpan="all" colSpan-sm="all" colSpan-md="0-5" rowSpan-md="0-2">
              <HeaderBannerTextArea openFeatureAndBenefitsModal={openFeatureAndBenefitsModal} />
            </Grid.Item>
          </Grid>
        </Container>
      </Banner>
      <Container padding="5" paddingMd="0">
        <Form id={FORM_ID} initialValues={initialValues} onSubmit={onSubmit} hideFormButtons disableFormCache>
          {formData => {
            return (
              <ContentWrapper theme={theme}>
                <CardImage theme={theme}>
                  <img
                    src={byCard({
                      [PRODUCT_CODE_DAVID_JONES_PREMIERE]: PremiereCardImg,
                      [PRODUCT_CODE_DAVID_JONES_PRESTIGE]: PrestigeCardImg,
                    })}
                    alt="LFrame card"
                  />
                </CardImage>
                <Container bg="white" margin="auto" margin-xs="8 0" margin-md="auto">
                  <Grid colsGutter="0" cols="6" rowsGap="4" rowsGap-md="10">
                    <Grid.Item colSpan="all" colSpan-md="0-1" colSpan-lg="0-1" rowSpan="0" rowSpan-md="0" margin-md="0">
                      <Container margin="0" padding="0">
                        <Text textStyle="heading4" as="h4">
                          Who can apply?
                        </Text>
                      </Container>
                    </Grid.Item>
                    <Grid.Item colSpan="all" colSpan-md="2-7" colSpan-lg="2-5" rowSpan="1" rowSpan-md="0">
                      <div style={['lg', 'xl'].includes(breakpoint) ? { minWidth: 610 } : {}}>
                        <LandingContentItem>
                          <img src={ProfileIcon} alt="profile-icon" />
                          <Text as="p">You're 18 years of age or over</Text>
                        </LandingContentItem>
                        <LandingContentItem>
                          <img src={HomeIcon} alt="house-icon" />
                          <Text>
                            A resident of Australia or a temporary resident who legally has the right to work in
                            Australia (and intends to stay) for at least the next 12 months.
                          </Text>
                        </LandingContentItem>
                      </div>
                    </Grid.Item>

                    <Grid.Item colSpan="all" colSpan-md="0-1" colSpan-lg="0-2" rowSpan="2" rowSpan-md="1" margin-md="0">
                      <Container margin="0">
                        <Text textStyle="heading4" as="h4">
                          Details to have handy
                        </Text>
                      </Container>
                    </Grid.Item>
                    <Grid.Item colSpan="all" colSpan-md="2-7" colSpan-lg="2-5" rowSpan="3" rowSpan-md="1">
                      <div style={['lg', 'xl'].includes(breakpoint) ? { minWidth: 610 } : {}}>
                        <LandingContentItem>
                          <img src={WalletIcon} alt="wallet-icon" />
                          <Text as="p">A driver licence, passport or medicare card to help verify your identity.</Text>
                        </LandingContentItem>
                        <LandingContentItem>
                          <img src={MoneyIcon} alt="money-icon" />
                          <Text>Details about your income, living expenses & debts.</Text>
                        </LandingContentItem>
                      </div>
                    </Grid.Item>

                    <Grid.Item colSpan="all" colSpan-md="0-1" colSpan-lg="0-2" rowSpan="4" rowSpan-md="2" margin-md="0">
                      <Container margin="0">
                        <Text textStyle="heading4" as="h4">
                          Things to consider
                        </Text>
                      </Container>
                    </Grid.Item>
                    <Grid.Item colSpan="all" colSpan-md="2-7" colSpan-lg="2-5" rowSpan="5" rowSpan-md="2">
                      <div style={['lg', 'xl'].includes(breakpoint) ? { minWidth: 610 } : {}}>
                        <ProsAndCons />
                      </div>
                    </Grid.Item>

                    {!started && (
                      <Grid.Item colSpan="all" colSpan-md="2-7" colSpan-lg="2-5" rowSpan="6" rowSpan-md="3">
                        <div style={['lg', 'xl'].includes(breakpoint) ? { minWidth: 610 } : {}}>
                          <Container margin="4 0" margin-md="0">
                            <Button
                              width="100%"
                              width-md="35%"
                              onClick={() => {
                                trackEventSender({
                                  category: 'application',
                                  action: 'application-started',
                                  location: formTealiumLocation,
                                  label: 'Get started',
                                });
                                setStarted(true);
                              }}
                              testId="landing-get-started-button"
                            >
                              Get started
                            </Button>
                          </Container>
                        </div>
                      </Grid.Item>
                    )}

                    {started && (
                      <>
                        <Grid.Item
                          colSpan="all"
                          colSpan-md="0-1"
                          colSpan-lg="0-1"
                          rowSpan="6"
                          rowSpan-md="3"
                          margin-md="0"
                        >
                          <Container margin="0" margin-md="0 5" padding="6 0 0 0" padding-md="0 5">
                            <Text textStyle="heading4">Please make sure this product is right for you</Text>
                          </Container>
                        </Grid.Item>
                        <Grid.Item colSpan="all" colSpan-md="2-7" colSpan-lg="2-5" rowSpan="7" rowSpan-md="3">
                          <div style={['lg', 'xl'].includes(breakpoint) ? { minWidth: 610 } : {}}>
                            <Container margin="0 0 8 0">
                              <Text>
                                Before we begin your application, we need to ask you a question to confirm the{' '}
                                {productName} credit card is right for you.
                              </Text>
                            </Container>
                            <Container margin="0 0 3 0">
                              <strong>Are you after a credit card that gives you:</strong>
                            </Container>
                            <Container margin="0 0 3 0">
                              <List>
                                <List.Item>
                                  the ability to make everyday credit card purchases in-store and online at businesses{' '}
                                  who accept Mastercard<sup>®</sup>, including David Jones, and perform other{' '}
                                  transactions; and
                                </List.Item>
                                <List.Item>
                                  the ability to earn David Jones points on eligible purchases which are redeemable for{' '}
                                  future benefits?
                                </List.Item>
                              </List>
                            </Container>
                            <Container margin="8 0" margin-md="0">
                              <ButtonToggle
                                name="consentGiven"
                                options={[
                                  {
                                    label: 'No',
                                    value: 'no',
                                  },
                                  {
                                    label: 'Yes',
                                    value: 'yes',
                                  },
                                ]}
                                testId="landing-consent-toggle"
                              />
                            </Container>
                          </div>
                        </Grid.Item>
                        {formData.state.values.consentGiven === 'yes' && (
                          <>
                            <Grid.Item
                              colSpan="all"
                              colSpan-md="0-1"
                              colSpan-lg="0-1"
                              rowSpan="8"
                              rowSpan-md="4"
                              margin-md="0"
                            >
                              <Container margin="0 0 4 0" margin-md="0 5" padding="0" padding-md="0 5">
                                <Text textStyle="heading4">By starting this application you agree:</Text>
                              </Container>
                            </Grid.Item>
                            <Grid.Item colSpan="all" colSpan-md="2-7" colSpan-lg="2-5" rowSpan="9" rowSpan-md="4">
                              <div style={['lg', 'xl'].includes(breakpoint) ? { minWidth: 610 } : {}}>
                                <List>
                                  <List.Item>
                                    <Link
                                      newTab
                                      href={
                                        config.productCode === PRODUCT_CODE_DAVID_JONES_PRESTIGE
                                          ? ProductComparisonLinks.dj_prestige_compare.url
                                          : ProductComparisonLinks.dj_premiere_compare.url
                                      }
                                      onClick={() => externalLinkEventSender()}
                                    >
                                      {productName}
                                    </Link>{' '}
                                    credit card meets your key credit card requirements
                                  </List.Item>
                                  <List.Item>
                                    Have read, understood and agree with the{' '}
                                    <Link onClick={openPrivacyNoticeModal}>Privacy Notice</Link>,{' '}
                                    <Link
                                      newTab
                                      href={byCard({
                                        [PRODUCT_CODE_DAVID_JONES_PREMIERE]:
                                          'https://assets.latitudefinancial.com/legals/key-fact-sheet/DJ-Premiere/KFS.html',
                                        [PRODUCT_CODE_DAVID_JONES_PRESTIGE]:
                                          'https://assets.latitudefinancial.com/legals/key-fact-sheet/DJ-Prestige/KFS.html',
                                      })}
                                      onClick={() =>
                                        trackEventSender({
                                          location: formTealiumLocation,
                                          label: 'Key Facts Sheet',
                                        })
                                      }
                                    >
                                      Key Facts Sheet
                                    </Link>{' '}
                                    and{' '}
                                    <Link
                                      newTab
                                      href={conditionsOfUseUrl}
                                      onClick={() =>
                                        trackEventSender({
                                          location: formTealiumLocation,
                                          label: 'Conditions of Use',
                                        })
                                      }
                                    >
                                      Conditions of Use
                                    </Link>{' '}
                                    and <Link onClick={openDeclarationModal}>Declaration and Credit Application</Link>.
                                  </List.Item>
                                  <List.Item>
                                    To the use and disclosure of your personal information for marketing communications{' '}
                                    from David Jones, Latitude and other third parties. For details, including how to{' '}
                                    opt-out, please see our Privacy Notice.
                                  </List.Item>
                                  <List.Item>To let us complete a credit check.</List.Item>
                                  <List.Item>
                                    To electronically receive your credit contract as set out in the Financial Table and
                                    Conditions of Use.
                                  </List.Item>
                                  <List.Item>
                                    To electronically verify your income using a secure process with Verifier (see{' '}
                                    <Link onClick={openVerifierModal}>Verifier’s terms of service</Link>
                                    ). If your income can’t be verified this way, we will provide alternative means.{' '}
                                  </List.Item>
                                </List>
                              </div>
                            </Grid.Item>
                          </>
                        )}
                        {formData.state.values.consentGiven === 'no' && (
                          <>
                            <Grid.Item
                              colSpan="all"
                              colSpan-md="0-1"
                              colSpan-lg="0-1"
                              rowSpan="8"
                              rowSpan-md="4"
                              margin-md="0"
                            >
                              <Container margin="0 0 4 0" margin-md="0 5" padding="0" padding-md="0 5">
                                <Text textStyle="heading4" as="h4">
                                  Looks like this product isn’t right for you
                                </Text>
                              </Container>
                            </Grid.Item>
                            <Grid.Item colSpan="all" colSpan-md="2-7" colSpan-lg="2-5" rowSpan="9" rowSpan-md="4">
                              <div style={['lg', 'xl'].includes(breakpoint) ? { minWidth: 610 } : {}}>
                                <LandingContentItem>
                                  <Text>
                                    You can find out about other David Jones credit cards by visiting the{' '}
                                    <Link
                                      href={ProductComparisonLinks.dj_compare_cards.url}
                                      newTab
                                      onClick={() => {
                                        trackEventSender({
                                          category: 'application',
                                          action: 'application-started',
                                          location: formTealiumLocation,
                                          label: 'comparison page',
                                        });
                                      }}
                                    >
                                      comparison page
                                    </Link>
                                    .
                                  </Text>
                                </LandingContentItem>
                              </div>
                            </Grid.Item>
                          </>
                        )}
                        <Grid.Item
                          colSpan="all"
                          colSpan-md="2-7"
                          colSpan-lg="2-5"
                          rowSpan={formData.state.values.consentGiven ? 10 : 8}
                          rowSpan-md={formData.state.values.consentGiven ? 5 : 4}
                          margin-md="0"
                        >
                          <Container>
                            {formData.state.values.consentGiven && (
                              <>
                                <Container margin="8 0 0 0" margin-md="0" width-md="100%">
                                  {formData.state.values.consentGiven === 'yes' && (
                                    <>
                                      <StartApplicationButton
                                        disabled={
                                          isAdblockCheckComplete &&
                                          isAdblockerDetected &&
                                          !DEV_FEATURES.OVERRIDE_AD_BLOCKER_STOP
                                        }
                                        loading={loading}
                                        theme={theme}
                                      >
                                        {APPLY_BTN_LABEL}
                                      </StartApplicationButton>
                                      {/* TODO: SR Day 2 is not going to have Resume button on landing page. Plus, we likely will get a new design in the future.
                                      {isFeatureOn('saveAndResume') && (
                                        <Container margin="6 0" width-md="100%">
                                          <Button
                                            width="100%"
                                            width-md="35%"
                                            disabled={
                                              isAdblockCheckComplete &&
                                              isAdblockerDetected &&
                                              !DEV_FEATURES.OVERRIDE_AD_BLOCKER_STOP
                                            }
                                            variant={
                                              !(
                                                isAdblockCheckComplete &&
                                                isAdblockerDetected &&
                                                !DEV_FEATURES.OVERRIDE_AD_BLOCKER_STOP
                                              )
                                                ? 'secondary'
                                                : 'primary'
                                            }
                                            testId="resume-application-button"
                                            onClick={handleResumeApplication}
                                          >
                                            {RESUME_BTN_LABEL}
                                          </Button>
                                        </Container>
                                      )} */}
                                    </>
                                  )}
                                </Container>
                              </>
                            )}
                          </Container>
                          {isAdblockCheckComplete && isAdblockerDetected && !DEV_FEATURES.OVERRIDE_AD_BLOCKER_STOP && (
                            <Message severity="stop" bg="secondary.pink.t30">
                              Please turn off your adblocker for the application to work and refresh your browser once
                              it is done.
                            </Message>
                          )}
                        </Grid.Item>
                      </>
                    )}
                  </Grid>
                </Container>
              </ContentWrapper>
            );
          }}
        </Form>
      </Container>
    </LayoutPage>
  );
}
