/* eslint-disable no-param-reassign */
import { validation } from '_config/_validation/validation';

export const initFormConfig = (fields, storeState) => {
  return fields?.map(rawField => {
    const resultField = { ...rawField };
    if (resultField.fields) {
      resultField.fields = initFormConfig(resultField.fields, storeState);
    }
    return withTransformedValidate(resultField, storeState);
  }, {});
};

const withTransformedValidate = (field, storeState) => {
  if (!field.validate) {
    return field;
  }

  const props =
    typeof field.validate.props === 'function' ? field.validate.props(storeState) : field.validate.props || {};

  return {
    ...field,
    maxLength: field.validate.props?.maxLength || field.validate.props?.fixedLength,
    validate: validation(props, field.validate.messages, field.validate.component, field)[0],
  };
};
