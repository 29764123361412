import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useStoreValue } from 'store';
import { submitHandler, getPageTitle, getDataLayerElements, isAU } from 'utils';
import { STEP_PROCESSING_IDV } from '_config';
import { LayoutPage, FormRenderer, LayoutContent, Form, SaveAndCompleteLaterButton } from 'components';
import { Container, Button, Grid } from 'basis';
import { useHistory, useLocation } from 'react-router-dom';
import { useUpdateIdvDetails } from 'hooks';
import { useEventTracking } from 'react-event-tracker';
import { initialValues, getFormConfig } from './formConfig';
import { toIdvPayload } from './toIdvPayload';
import { EIDVFailureMessage } from './eidv.style';
import { ConfirmationModal } from './ConfirmationModal';

export const EIDV = () => {
  const history = useHistory();
  const { trackEvent } = useEventTracking();
  const [storeState, updateStore] = useStoreValue();
  const formConfig = useMemo(() => getFormConfig(storeState), [storeState]);
  const location = useLocation();
  const { sendRequest, loading } = useUpdateIdvDetails();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const onSubmit = useCallback(
    ({ values, errors }) => {
      const postAndRedirect = async () => {
        const result = await sendRequest(toIdvPayload(values));
        const nextStep = STEP_PROCESSING_IDV;
        updateStore({
          applicationErrors: null,
          applicationStatus: result.status,
          activeStep: nextStep,
        });
        history.push(nextStep);
      };

      trackEvent({
        event: {
          category: 'application',
          action: 'application-navigation',
          location: 'confirmation modal',
          label: 'Verify now',
        },
        ...getDataLayerElements(storeState),
      });

      submitHandler({ submit: postAndRedirect, errors });
    },
    [history, sendRequest, storeState, trackEvent, updateStore],
  );
  const onClickNext = useCallback(
    props => {
      if (Object.keys(props.errors).length !== 0) {
        return;
      }

      trackEvent({
        event: {
          category: 'application',
          action: 'application-navigation',
          location: getPageTitle(location.pathname),
          label: 'Next',
        },
        ...getDataLayerElements(storeState),
      });

      if (props.values.idType === 'NONE') {
        onSubmit(props);
      } else {
        setIsConfirmationModalOpen(true);
      }
    },
    [location.pathname, onSubmit, storeState, trackEvent],
  );

  const onEdit = useCallback(() => {
    trackEvent({
      event: {
        category:
          storeState.eIDVMedicareChecked || storeState.eIDVPassportChecked ? 'application-error' : 'application',
        action: 'application-navigation',
        location: 'confirmation modal',
        label: 'Edit',
      },
      ...getDataLayerElements(storeState),
    });
    setIsConfirmationModalOpen(false);
  }, [storeState, trackEvent]);

  const onVerify = useCallback(
    state => {
      if (isAU()) {
        storeState.acquisition.extraDetails.idType = state.values.idType;
      }
      onSubmit(state);
    },
    [onSubmit, storeState],
  );

  useEffect(() => {
    if (storeState.eIDVMedicareChecked || storeState.eIDVPassportChecked) {
      trackEvent({
        event: {
          category: 'application',
          action: 'application-error',
          location: 'identification',
          label: 'unable to verify id',
        },
        ...getDataLayerElements(storeState),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LayoutPage>
      <Container margin="0 0 13 0" hasBreakpointWidth>
        <EIDVFailureMessage>
          <Form
            id="eIDV"
            key="eIDV"
            submitButtonLabel="Next"
            initialValues={initialValues}
            onSubmit={onClickNext}
            loading={loading}
            showSaveDraftButton
            saveDraftOnSubmit
            saveDraftOnSessionTimeout
            disableFormCache
            hideFormButtons
          >
            {formData => (
              <LayoutContent noSessionTimeout>
                <FormRenderer config={formConfig} formData={formData} storeState={storeState} />

                <Grid rowsGap="4" colsGap="10" cols="2">
                  <Grid.Item colSpan="all" colSpan-sm="0" alignItems="center">
                    <Grid>
                      <Button width="100%" type="submit" loading={loading}>
                        Next
                      </Button>
                    </Grid>
                  </Grid.Item>
                  <Grid.Item colSpan="all" rowSpan="1" colSpan-sm="1" rowSpan-sm="0">
                    <Grid>
                      <SaveAndCompleteLaterButton />
                    </Grid>
                  </Grid.Item>
                </Grid>
                <ConfirmationModal
                  storeState={storeState}
                  isOpen={isConfirmationModalOpen}
                  formValues={formData.state.values}
                  onClose={onEdit}
                  onVerify={() => onVerify(formData.state)}
                  loading={loading}
                />
              </LayoutContent>
            )}
          </Form>
        </EIDVFailureMessage>
      </Container>
    </LayoutPage>
  );
};
