import { DEV_FEATURES } from '__dev/devFeatures';
import {
  PRODUCT_CODE_DAVID_JONES_PREMIERE,
  PRODUCT_CODE_DAVID_JONES_PRESTIGE,
  DAVID_JONES_FEATURE_PREFIX,
  config,
  COUNTRY_CODE_AU,
} from '_config';
import { isBoolean, mapValues, merge, startsWith, upperFirst } from 'lodash';
import { byCard, getProductInfo, isDJ, isNZ } from 'utils/byConfig';
import { getSessionStorage } from 'utils/sessionStorage';
import { productCodeToDefaultFeatures } from './defaultValues';

export const sharedFeatures = ['maintenanceMessage', 'auDmsUpload', 'nzDmsUpload', 'offerTypeMapping'];

export const featuresStorageKey = 'features';

export const getFeatures = () => {
  if (
    DEV_FEATURES.USE_LOCAL_FEATURES || // when turn on USE_LOCAL_FEATURES
    process.env.REACT_APP_LAUNCH_DARKLY_INACTIVE // when unit test running any where
  ) {
    return getTransformedFeatures(DEV_FEATURES?.FEATURE_TOGGLE_BOOLEAN_VALUE ?? true);
  }

  return getSessionStorage('launchDarklyFlags');
};

export function convertToFeatureKey(featureKey) {
  if (sharedFeatures.includes(featureKey)) {
    return featureKey;
  }

  if (isNZ()) {
    return featureKey;
  }

  if (isDJ()) {
    return `${DAVID_JONES_FEATURE_PREFIX}${upperFirst(featureKey)}`;
  }

  const prefix = getProductInfo().featureToggleKeyPrefix;
  return `${prefix}${upperFirst(featureKey)}`;
}

export function convertToFeatureName(featureName) {
  if (isNZ()) {
    return featureName;
  }

  const { featureToggleKeyPrefix } = getProductInfo();
  const lengthOfPrefix = featureToggleKeyPrefix.length;

  return startsWith(featureName, featureToggleKeyPrefix) ? featureName.substring(lengthOfPrefix) : featureName;
}

const getTransformedFeatures = (booleanValue = true) => {
  const defaultFeatures = getDefaultFeatures();
  const transformedFeatures = mapValues(defaultFeatures, (defaultValue, key) => {
    const isMaintenanceToggle = key.toLowerCase().includes('maintenancepage');
    if (isBoolean(defaultValue) && !isMaintenanceToggle && key !== 'afsAch' && key.indexOf('Launched') === -1) {
      return booleanValue;
    }
    return defaultValue;
  });
  const storageFeatures = getSessionStorage(featuresStorageKey) || {};
  return merge({}, transformedFeatures, storageFeatures);
};

export function isFeatureOn(feature) {
  return getFeatureValue(feature) === true;
}

export function getFeatureValue(feature) {
  const featureKey = convertToFeatureKey(feature);

  if (getFeatures()) {
    return getFeatures()[featureKey];
  }
  return null;
}

export function getRatesAndFees() {
  const features = getFeatures();
  if (isDJ()) {
    if (!config.productCode) {
      // either on page dj-product-comparison or resume-your-application with deprecated params `cardType=dj` without `productCode`
      return {};
    }

    return byCard({
      [PRODUCT_CODE_DAVID_JONES_PRESTIGE]: features.prestigeRatesAndFees,
      [PRODUCT_CODE_DAVID_JONES_PREMIERE]: features.premiereRatesAndFees,
    });
  }
  if (isNZ()) {
    return features.nzRatesAndFees; // TODO: make NZ feature keys consistent with prefix
  }

  return getFeatureValue('ratesAndFees');
}

export const isDCLOn = () => config.countryCode === COUNTRY_CODE_AU || isFeatureOn('dynamicCreditLimit');

export const getDefaultFeatures = () => {
  return byCard(productCodeToDefaultFeatures);
};
