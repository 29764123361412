import React from 'react';
import { Container, Grid, Link, Stack, Text } from 'basis';
import { LayoutPage } from 'components';
import AdrianaMartinezSignature from 'images/adriana_martinez.png';
import { PRODUCT_CODE_DAVID_JONES_PREMIERE, PRODUCT_CODE_DAVID_JONES_PRESTIGE, config } from '../../../../_config';
import { davidJonesTheme } from '../../../../theme';
import { StyledDivSpaceBetween, StyledUL, StyledLeftDivWidth75, StyledDivAlignRight } from './Styles';

export function DjFinancialTable({
  ratesAndFees,
  statementDate,
  downloadOrPrintFile,
  productName,
  accountNumber,
  creditLimit,
  productCode,
}) {
  const {
    purchaseAnnualRate,
    cashAnnualRate,
    annualFee,
    additionalCardHolderFee,
    lateFee,
    paperStatementFee,
    ausPostHandlingFee,
    replacementCardFee,
  } = ratesAndFees;

  return (
    <LayoutPage hideBackButton>
      <Container margin="0 0 0 0">
        <Container padding="5 0" padding-md="5" testId="LAYOUT FORM">
          <Grid preset="page" debug={false}>
            <Grid.Item colSpan="all">
              <Grid rowsGap={0}>
                <Container padding="4 4" margin="0 8">
                  <div style={{ marginBottom: '40px' }}>
                    <Link variant="secondary" id="pdfButton" onClick={downloadOrPrintFile}>
                      Download or Print file
                    </Link>
                  </div>
                  <Grid preset="page" colsGap={11}>
                    <Grid.Item colSpan="all">
                      <Grid rowsGap={1}>
                        <Container padding="0">
                          <div id="financial-table">
                            <Stack gap="1">
                              <Text
                                as="h1"
                                textStyle="heading5"
                                textStyle-md="heading4"
                                color={davidJonesTheme.headingColor}
                              >
                                FINANCIAL TABLE
                              </Text>
                              <hr />
                              <Text>
                                The details below relate to the {productName} credit facility and are correct as at the
                                disclosure date. The <strong>financial table</strong> does not contain all of the
                                pre-contractual information <strong>we</strong> are required by law to give{' '}
                                <strong>you</strong>. Further terms and conditions (and an explanation of some of the
                                words used in the <strong>financial table</strong>) are in the Conditions of Use and
                                Credit Guide, which includes important information about{' '}
                                <strong>your credit contract</strong> and privacy.
                              </Text>
                              <br />
                              <StyledDivSpaceBetween>
                                <Container>
                                  <Text textStyle="heading6">Account Number</Text>
                                </Container>
                                <Container>
                                  <Text textStyle="heading6">Disclosure Date</Text>
                                </Container>
                                <Text textStyle="heading6">Credit Limit</Text>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <Container>
                                  <data-hj-suppress>
                                    <Text>{accountNumber}</Text>
                                  </data-hj-suppress>
                                </Container>
                                <Container margin={0} margin-md="0 0 0 -11">
                                  <Text>{statementDate}</Text>
                                </Container>
                                <data-hj-suppress>
                                  <Text>${creditLimit}</Text>
                                </data-hj-suppress>
                              </StyledDivSpaceBetween>
                              <br />
                              <Text>
                                <strong>Cash Limit</strong> <i>(if applicable, refer to your statement)</i>
                              </Text>
                              <hr />
                              <Text>
                                A cash limit forms part of your credit limit which may be withdrawn in cash or cash
                                equivalent transactions. A cash limit may be imposed, withdrawn or changed on your
                                account from time to time, which is notified to you on each statement we give you and,
                                is effective from the date of each statement.{' '}
                              </Text>
                              <br />
                              <Text textStyle="heading5">Annual Percentage Rates</Text>
                              <hr />
                              <StyledDivSpaceBetween>
                                <Text textStyle="heading6">Purchase annual percentage rate</Text>
                                <Text>{purchaseAnnualRate}% p.a.</Text>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <Text textStyle="heading6">Cash annual percentage rate</Text>
                                <Text>{cashAnnualRate}% p.a.</Text>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledUL>
                                {productCode === PRODUCT_CODE_DAVID_JONES_PRESTIGE && (
                                  <li>
                                    The <strong>purchase annual percentage rate</strong> applies to the{' '}
                                    <strong>purchases and charges balance</strong> (except those which are subject to a{' '}
                                    <strong>promotional offer</strong> or which not subject to interest).{' '}
                                  </li>
                                )}
                                {productCode === PRODUCT_CODE_DAVID_JONES_PREMIERE && (
                                  <li>
                                    The <strong>purchase annual percentage rate</strong> applies to the{' '}
                                    <strong>purchases and charges balance</strong>, and includes any expired{' '}
                                    <strong>promotional offer</strong>.
                                  </li>
                                )}
                                <li>
                                  The <strong>cash annual percentage rate</strong> applies to the{' '}
                                  <strong>cash advance balance</strong>.
                                </li>
                              </StyledUL>
                              <Text textStyle="heading5">Maximum Interest Free Period</Text>
                              <hr />
                              <Text>
                                Up to 55 days for <strong>purchases</strong> except for{' '}
                                <strong>promotional offers</strong> with an interest free period.
                              </Text>
                              <hr />
                              <Text textStyle="heading5">Minimum Repayments</Text>
                              <Text>
                                The minimum amount payable for the first <strong>statement period</strong> will consist
                                of:
                              </Text>
                              <StyledUL>
                                <li>
                                  your <strong>minimum monthly payment</strong> — this amount is payable by the{' '}
                                  <strong>due date</strong> shown on the relevant statement of <strong>account;</strong>{' '}
                                  and
                                </li>
                                <li>
                                  any <strong>overdue amount</strong> — this amount is payable immediately; and
                                </li>
                                <li>
                                  any <strong>overlimit amount</strong> — this amount is payable immediately; and
                                </li>
                                <li>
                                  any other amount due under the <strong>credit contract</strong> which is payable on
                                  demand (for example, enforcement expenses).
                                </li>
                              </StyledUL>
                              <Text textStyle="heading5">Fees and Charges</Text>
                              <hr />
                              <StyledDivSpaceBetween>
                                <StyledLeftDivWidth75>
                                  <Text>
                                    An <strong>annual fee</strong> will be charged when you or an{' '}
                                    <strong>additional cardholder</strong> first initiate a transaction on your{' '}
                                    <strong>account</strong> and will be payable on the date specified in your next
                                    statement of <strong>account</strong>. After that it will be payable on each
                                    anniversary of that date.
                                  </Text>
                                </StyledLeftDivWidth75>
                                <div>
                                  <Text>${annualFee}</Text>
                                </div>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <StyledLeftDivWidth75>
                                  <Text>
                                    An <strong>additional cardholder</strong> fee will be charged pro-rata from the date
                                    the additional card is issued to the annual fee anniversary date of the{' '}
                                    <strong>account</strong> and will be payable on the date specified in your next
                                    statement of <strong>account</strong>. After that it will be payable on each
                                    anniversary of the <strong>annual fee</strong> date of the <strong>account.</strong>
                                  </Text>
                                </StyledLeftDivWidth75>
                                <Container>
                                  <Text>
                                    {Number.isNaN(Number.parseFloat(additionalCardHolderFee))
                                      ? additionalCardHolderFee
                                      : `$${additionalCardHolderFee}`}
                                  </Text>
                                </Container>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <StyledLeftDivWidth75>
                                  <Text>
                                    A <strong>cash advance</strong> fee (if applicable) will be charged to{' '}
                                    <strong>your account</strong> on each occasion <strong>you</strong> obtain a{' '}
                                    <strong>cash advance</strong> or make a <strong>cash equivalent</strong> transaction
                                    (except that if the <strong>cash advance</strong> is made by a direct transfer to a
                                    bank account nominated by you). This fee will not exceed $30 for that transaction.
                                  </Text>
                                </StyledLeftDivWidth75>
                                <StyledDivAlignRight>
                                  <Text>
                                    3% of the <strong>cash advance</strong> or $3 whichever is greater
                                  </Text>
                                </StyledDivAlignRight>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <StyledLeftDivWidth75>
                                  <Text>
                                    An <strong>international transaction</strong> fee equivalent to a specific
                                    percentage of the amount of the <strong>international transaction</strong> converted
                                    to Australian dollars, the amount of the <strong>international transaction</strong>{' '}
                                    will be charged to <strong>your account</strong> when the international transaction
                                    is debited to <strong>your account</strong>. The fee will be included with the{' '}
                                    <strong>international transaction</strong> amount when it appears on your statement
                                    of <strong>account</strong>. This fee will also be shown separately on your
                                    statement.
                                  </Text>
                                </StyledLeftDivWidth75>
                                <StyledDivAlignRight>
                                  <Text>
                                    3% of the Australian dollar value of the <strong>international transaction</strong>
                                  </Text>
                                </StyledDivAlignRight>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <StyledLeftDivWidth75>
                                  <Text>
                                    A late fee will be charged to the <strong>account</strong> in each{' '}
                                    <strong>statement period</strong> when any amount due and payable to{' '}
                                    <strong>us</strong> is not paid in full by the <strong>due date.</strong>
                                  </Text>
                                </StyledLeftDivWidth75>
                                <StyledDivAlignRight>
                                  <Text>
                                    <strong>${lateFee?.toFixed(2)}</strong>
                                  </Text>
                                </StyledDivAlignRight>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <StyledLeftDivWidth75>
                                  <Text>
                                    A paper statement fee (if applicable) will be charged for each paper statement of{' '}
                                    <strong>account</strong> sent to you. This fee will not be charged if you consent to
                                    receiving your statements electronically.
                                  </Text>
                                </StyledLeftDivWidth75>
                                <StyledDivAlignRight>
                                  <Text>
                                    <strong>${paperStatementFee?.toFixed(2)}</strong>
                                  </Text>
                                </StyledDivAlignRight>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <StyledLeftDivWidth75>
                                  <Text>
                                    A payment handling fee will be charged to <strong>your account</strong> each time{' '}
                                    <strong>you</strong> make a payment to <strong>your account</strong> at Australia
                                    Post. No fee is payable for payments made by BPAY®, direct debit or via the{' '}
                                    <strong>Latitude Service Centre</strong>.
                                  </Text>
                                </StyledLeftDivWidth75>
                                <StyledDivAlignRight>
                                  <Text>
                                    <strong>${ausPostHandlingFee?.toFixed(2)}</strong>
                                  </Text>
                                </StyledDivAlignRight>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <StyledLeftDivWidth75>
                                  <Text>
                                    A replacement card fee will be charged to the <strong>account</strong> when you
                                    request that we provide <strong>you</strong> or an{' '}
                                    <strong>additional cardholder</strong> with a replacement card when any{' '}
                                    <strong>card</strong> is lost, stolen or damaged.
                                  </Text>
                                </StyledLeftDivWidth75>
                                <StyledDivAlignRight>
                                  <Text>
                                    <strong>${replacementCardFee?.toFixed(2)}</strong>
                                  </Text>
                                </StyledDivAlignRight>
                              </StyledDivSpaceBetween>
                              <hr />
                              <Container>
                                <Text>
                                  These fees, once charged, form part of the <strong>closing balance</strong>, and
                                  unless specified otherwise, are payable as part of your{' '}
                                  <strong>minimum monthly payment</strong>. You will not have to make a payment in a{' '}
                                  <strong>statement period</strong> if your <strong>minimum monthly payment</strong>{' '}
                                  would otherwise consist of a paper statement fee.
                                </Text>
                              </Container>
                              <br />
                              <Container>
                                <Text textStyle="heading5">Changes to Limits, Rates, Fees and Charges</Text>
                              </Container>
                              <hr />
                              <Container>
                                <Text>
                                  The <strong>credit limit</strong>, the <strong>annual percentage rate(s)</strong>, the
                                  maximum interest free period (if applicable), the amount, frequency or time for
                                  payment or method of calculation of a fee or charge or{' '}
                                  <strong>minimum monthly payment</strong> may be changed, and new fees or charges may
                                  be imposed, in each case without your consent (except that <strong>we</strong> cannot
                                  increase the <strong>credit limit</strong> except at <strong>your</strong> request or
                                  with your consent in any form required by law). You will be notified of the change or
                                  of new fees or charges in the manner set out in the Conditions of Use accompanying
                                  this <strong>financial table</strong>.
                                </Text>
                              </Container>
                              <br />
                              <Container>
                                <Text textStyle="heading5">Other Information</Text>
                              </Container>
                              <hr />
                              <Text>
                                If you do not want to receive direct marketing information about other products or
                                services, please call {config.phoneNumbers.assistance}. You can also unsubscribe from
                                email marketing by clicking unsubscribe in the footer of our emails.
                              </Text>
                              <div style={{ marginTop: '170px' }}>
                                <Text textStyle="heading5">Offer to New Account Holders</Text>
                              </div>
                              <hr />
                              <Text>
                                Latitude Finance Australia (ABN 42 008 583 588), Australian Credit Licence number
                                392145, as the credit provider, offers to provide you with a {productName}{' '}
                                <strong>account</strong> on the conditions set out in the{' '}
                                <strong>credit contract</strong>. Subject to clause 2 of the Conditions of Use, you
                                accept this offer for a <strong>credit contract</strong> by using the{' '}
                                <strong>account</strong> to access or drawdown credit to incur a liability on the{' '}
                                <strong>account</strong>.
                              </Text>
                              <Text>Signed on behalf of Latitude Finance Australia.</Text>
                              <br />
                              <img src={AdrianaMartinezSignature} width="150px" alt="form-title-icon" />
                              <Text>
                                <strong>Adriana Martinez</strong>
                              </Text>
                              <Text>Executive General Manager, Latitude Pay</Text>
                            </Stack>
                          </div>
                        </Container>
                      </Grid>
                    </Grid.Item>
                  </Grid>
                </Container>
              </Grid>
            </Grid.Item>
          </Grid>
        </Container>
      </Container>
    </LayoutPage>
  );
}
