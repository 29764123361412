import { useEffect, useRef, useCallback } from 'react';

export function useOnClickOutside(close) {
  const ref = useRef();

  const handleClose = useCallback(
    evt => {
      if (ref.current && !ref.current?.contains(evt.target)) {
        close && close();
      }
    },
    [close],
  );

  useEffect(() => {
    window.addEventListener('click', handleClose);
    return () => {
      window.removeEventListener('click', handleClose);
    };
  }, [handleClose]);

  return ref;
}
