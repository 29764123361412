import React, { useMemo } from 'react';
import { Container, Grid } from 'basis';
import { byCountry } from 'utils';
import styled from '@emotion/styled';

export const VerticalContainer = styled.div`
  padding: ${props =>
    props.padding || props.noPadding
      ? '0'
      : `${props?.theme?.space?.[5] ?? '20px'} 0px ${props?.theme?.space?.[5] ?? '20px'} 0px`};
  & + & {
    padding-top: 0;
  }
`;

const defaultSizing = {
  colSpan: 'all',
  'colSpan-sm': '1-6',
  'colSpan-lg': '3-8',
};

const widerSizing = {
  colSpan: 'all',
  'colSpan-lg': '2-9',
};

export function LayoutContent({ children, rowsGap, testId, padding, noPadding, ...props }) {
  const isWider = byCountry({
    NZ: false,
    AU: true,
  });

  const sizing = useMemo(() => (isWider ? widerSizing : defaultSizing), [isWider]);

  return (
    <VerticalContainer padding={padding} noPadding={noPadding} data-testid={testId || 'layout-content'}>
      <Container hasBreakpointWidth {...props}>
        <Grid preset="page" debug={false}>
          <Grid.Item {...sizing}>
            <Grid rowsGap={rowsGap || '8'}>{children}</Grid>
          </Grid.Item>
        </Grid>
      </Container>
    </VerticalContainer>
  );
}
