import { titleOptionsNZ, relationshipStatusOptions, numbersOnlyPattern } from '../_constants';
import { numberOfDependentsValidationMessages } from './extraDetails';

export const biometricsAboutYou = [
  {
    component: 'InfoBlock',
    name: 'biometricsInfo',
    text: 'To save you time we captured your name and date of birth when you provided your identity details.',
  },
  {
    component: 'Select',
    name: 'title',
    label: 'Title',
    options: titleOptionsNZ,
    testId: 'title',
    validate: {
      messages: {
        required: 'Please select a title.',
      },
    },
  },
  {
    component: 'RadioGroup',
    name: 'relationshipStatus',
    label: 'Relationship status',
    options: relationshipStatusOptions,
    testId: 'relationship-status',
    breakpoint: size => {
      const columns = ['md', 'lg', 'xl'].includes(size) ? 2 : 1;
      return { columns };
    },
    validate: {
      messages: {
        required: 'Please enter your relationship status.',
      },
    },
  },
  {
    component: 'Input',
    variant: 'numeric',
    name: 'numberOfDependents',
    label: 'How many dependants do you have?',
    helpText: 'Adults and children who are financially dependent on you.',
    testId: 'number-of-dependents',
    validate: {
      props: {
        minValue: 0,
        maxValue: 99,
        validPattern: numbersOnlyPattern,
      },
      messages: numberOfDependentsValidationMessages,
    },
  },
];
