import React from 'react';
import { Text, Container } from 'basis';
import { CrbConsent } from './CrbConsent';
import { DvsConsent } from './DvsConsent';

export const IdvConsents = () => {
  return (
    <div>
      <Text textStyle="heading5">Verify your identity</Text>
      <Container padding="3 0">
        <Text>These checks are safe and secure.</Text>
      </Container>
      <CrbConsent />
      <DvsConsent />
    </div>
  );
};
