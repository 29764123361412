import { Container, Text } from 'basis';
import React from 'react';
import { APPLE_MERCHANT } from '_config/_constants';
import { getConditionsOfUseTerm } from 'utils';

const ApprovedInformation = ({ merchantName, firstName, email, creditLimit, gemVisaAccountNumber }) => {
  return (
    <Container>
      <Container padding="5" padding-md="10 0 4 10">
        <Container padding="0 0 2 0">
          <Text
            testId="congratulation"
            as="h1"
            color="highlight.blue.t100"
            textStyle="heading4"
            textStyle-md="heading3"
            padding="4"
          >
            Congratulations {firstName}!
          </Text>
        </Container>

        <Container padding="0 0 2 0">
          <Text
            testId="creditLimitOutcome"
            as="h1"
            color="highlight.blue.t100"
            textStyle="heading4"
            textStyle-md="heading3"
            padding="4"
          >
            You’re approved for <data-hj-suppress>${creditLimit}</data-hj-suppress>
          </Text>
        </Container>

        {merchantName === APPLE_MERCHANT && (
          <Container>
            <Text as="h1" color="highlight.blue.t100" textStyle="heading4" textStyle-md="heading3" padding="4">
              You can now shop at Apple
            </Text>
          </Container>
        )}
      </Container>

      <Container padding="5" padding-md="2 0 0 10">
        {gemVisaAccountNumber && (
          <Container padding="0 0 4 0">
            <Text testId="accountNumber" textStyle="body1" textStyle-md="subtitle2" padding="4">
              Your Gem Visa account number is <b data-hj-suppress>{gemVisaAccountNumber}</b>
            </Text>
          </Container>
        )}

        <Container padding="0 0 4 0">
          <Text testId="emailSent" textStyle="body1" textStyle-md="subtitle2" padding="4">
            We've sent you a confirmation email to{' '}
            <b style={{ color: '#006aff' }} data-hj-suppress>
              {email}
            </b>{' '}
            with your Initial Disclosure Statement, {getConditionsOfUseTerm()} and account details. You'll need to keep
            these documents for future reference.
          </Text>
        </Container>

        <Container padding="0 0 4 0">
          <Text textStyle="body1" textStyle-md="subtitle2">
            Your Gem Visa card is being posted to you and will be with you within 7 days.
          </Text>
        </Container>
      </Container>
    </Container>
  );
};

export { ApprovedInformation };
