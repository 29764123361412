import React from 'react';
import { List, Text } from 'basis';
import { config } from '_config';
import { OverviewContent } from 'siteContent/overviewContent';

const { passportOrDriverLicence, proofOfIncome } = OverviewContent[config.countryCode];

const WhatYouMayNeed = () => {
  return (
    <>
      <Text>
        <strong>To provide a form of identification</strong>
      </Text>

      <List margin="2 0 0 0">
        <List.Item>{passportOrDriverLicence}</List.Item>
      </List>

      <Text margin="5 0 0 0">
        <strong>To provide proof of income</strong>
      </Text>

      <List margin="2 0 0 0">
        <List.Item>{proofOfIncome}</List.Item>
      </List>
    </>
  );
};

export { WhatYouMayNeed };
