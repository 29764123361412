import React from 'react';
import { Container, Text, Stack, Grid, List } from 'basis';
import { config } from '_config';
import { PhoneNumberLink } from 'components';
import { getRatesAndFees } from 'featureToggles';
import { BracketList, Section, SectionTitleBox } from './shared';

export const InitialDisclosureStatement = ({ trackEventSender, creditLimit }) => {
  const ratesAndFees = getRatesAndFees();
  const purchaseAnnualRate = ratesAndFees?.purchaseAnnualRate;
  const cashAnnualRate = ratesAndFees?.cashAnnualRate;
  const annualFee = ratesAndFees?.annualFee;
  const biAnnualFee = ratesAndFees?.annualFee / 2;
  const lateFee = ratesAndFees?.lateFee;
  const paperStatementFee = ratesAndFees?.paperStatementFee;
  const nzPostHandlingFee = ratesAndFees?.nzPostHandlingFee;
  const replacementCardFee = ratesAndFees?.replacementCardFee;
  const establishmentFee = ratesAndFees?.establishmentFee;
  const atmWithdrawalFee = ratesAndFees?.atmWithdrawalFee;
  const atmWithdrawalFeeInternational = ratesAndFees?.atmWithdrawalFeeInternational;
  const cashWithdrawalFee = ratesAndFees?.cashWithdrawalFee;
  const statementReprintFee = ratesAndFees?.statementReprintFee;
  const internationalTransactionFee = ratesAndFees?.internationalTransactionFee;
  return (
    <Stack gap="6" gap-md="10">
      <Text textStyle="heading5">Initial Disclosure Statement - Gem Visa revolving credit card facility</Text>
      <Text>
        <b>Important</b> - Latitude Financial Services Limited ("Latitude") as the creditor is required to provide you
        with this disclosure statement under section 17 of the Credit Contracts and Consumer Finance Act 2003 ("the
        Act"). This disclosure statement sets out the key information about your consumer credit contract and is dated
        the date of the letter/email it is sent to you with. This disclosure statement and the <GemVisa /> Conditions of
        Use, together make up your consumer credit contract ("this contract"). You should read this contract thoroughly.{' '}
        <b>If you do not understand anything in this contract, you should seek independent advice.</b> You should keep
        this contract in a safe place.
      </Text>

      <Text>Gem is the trading name of Latitude Financial Services Limited.</Text>

      <Text>
        This Disclosure Statement must be provided to you before the contract is entered into. The law gives you a
        limited right to cancel the consumer credit contract. See the statement of right to cancel below for full
        details of your right to cancel. Note that strict time limits apply.
      </Text>
      <Section>
        <SectionTitleBox>
          <Text textStyle="heading5">Full name and address of creditor</Text>
          <Text textStyle="subtitle2">This is the person providing you the credit</Text>
        </SectionTitleBox>
        <Text>You may send notices to the creditor by writing to the creditor at the creditor's postal address.</Text>
        <Text>
          Latitude Financial Services Limited
          <br /> 8 Tangihua Street,
          <br /> Auckland 1010
        </Text>

        <Text>
          or PO Box 4058, Shortland Street, Auckland 1140
          <br />
          Latitude is a registered financial services provider and the Financial Service Providers Registration Number
          FSP 434746
        </Text>
      </Section>

      <Section>
        <SectionTitleBox>
          <Text textStyle="heading5">Continuing disclosure</Text>
        </SectionTitleBox>
        <div>
          <Text textStyle="heading6">Continuing disclosure statements</Text>
          <Text>
            Latitude is required to provide you with regular statements. The statements will give you information about
            your account (e.g. any interest or fees charged during the statement period) and the amount and timing of
            your next payment. Statements will be provided monthly.
          </Text>
        </div>
      </Section>
      <Section>
        <SectionTitleBox>
          <Text textStyle="heading5">Initial unpaid balance</Text>
          <Text textStyle="subtitle2">
            This is the amount you owe (including any fees charged), when this statement is provided to you
          </Text>
        </SectionTitleBox>
        <div>
          <Text>$Nil.</Text>
          <Text>
            All transactions on your account (including fees and charges) will appear on your continuing disclosure
            statements.
          </Text>
        </div>
      </Section>
      <Section>
        <SectionTitleBox>
          <Text textStyle="heading5">Payment</Text>
        </SectionTitleBox>
        <Grid>
          <Left>
            <Text textStyle="heading6">Minimum payment</Text>
            <Text>The minimum monthly payment is:</Text>
            <Section>
              <Text>
                (a) any payment due under an instalment plan advance (equal monthly payments of principal and interest
                to repay the transaction amount over the promotional term*); and either $20 or 3% of the closing balance
                (excluding any instalment plan advance or unexpired deferred payment advance) whichever is the greater,
                together with any amount shown as overdue; and an amount sufficient to reduce the closing balance to
                your credit limit (if applicable); or
              </Text>

              <Text>(b) any closing balance (excluding any unexpired deferred payment advance) under $20; or</Text>

              <Text>(c) other amounts as agreed upon from time to time between you and Latitude.</Text>

              <Text>You may choose to pay more than the minimum payment.</Text>
            </Section>
          </Left>
          <Right>
            <Text textStyle="heading6">When payments are due</Text>
            <Section>
              <Text>
                Payments must be made monthly. You will be sent continuing disclosure statements commencing one month
                after funds are advanced under this contract. The date that payments are due will be specified in your
                continuing disclosure statements. You will not be sent a monthly statement if there have been no
                transactions on your account and there is a nil balance.
              </Text>
              <Text>
                <b>*</b> If your first <GemVisa /> transaction is an instalment plan advance, the establishment fee will
                be payable as part of the minimum monthly payment in addition to the equal instalment amounts.
              </Text>
            </Section>
          </Right>
        </Grid>
      </Section>

      <Section>
        <SectionTitleBox>
          <Text textStyle="heading5">Credit details</Text>
        </SectionTitleBox>
        <Grid>
          <Left>
            <Text textStyle="heading6">Annual interest rates</Text>
            <List type="unordered">
              <List.Item>
                <b>Current annual interest rate for purchases</b>: {purchaseAnnualRate}% (this may vary). Interest at
                this rate will begin to accrue at the end of any interest free start-up period or deferred payment
                period (each a "start-up period") applicable to any promotional purchase made on your account where the
                transaction amount has not been repaid in full at the expiry of the start-up period. Interest at this
                rate will also apply to all advances (other than cash advances) made on your account, which are not
                subject to a start-up period.
              </List.Item>
              <List.Item>
                <b>Current annual interest rate for cash advances</b>: {cashAnnualRate}% (this may vary). Interest at
                this rate accrues from the date of the advance.{' '}
              </List.Item>
            </List>
          </Left>
          <Right>
            <Container margin="5 0">
              <Text>
                <b>Current annual interest rate for balance transfers</b>: {cashAnnualRate}% (this may vary). The
                interest rate applied to balance transfers is the cash advance rate. Interest at this rate will begin to
                accrue at the end of any promotional period applicable to any balance transfer made on your account
                where the transaction amount has not been repaid in full at the expiry of the promotional period.
              </Text>
            </Container>
            <Container margin="5 0">
              <Text>
                <b>Credit Limit</b>: ${creditLimit} - subject to change once the assessment of your application has been
                completed
              </Text>
            </Container>
            <Text>
              Interest charges (if any) are calculated by multiplying any interest bearing unpaid balance at the end of
              the day by a daily interest rate. The daily interest rate is calculated by dividing the annual Interest
              rate by 365. Interest is charged monthly to your account.
            </Text>
          </Right>
        </Grid>
      </Section>

      <Section>
        <SectionTitleBox>
          <Text textStyle="heading5">Credit fees and charges</Text>
        </SectionTitleBox>
        <Text>
          The following credit fees and charges are, or may become payable, under or in connection with this contract.
          This contract allows Latitude to vary these fees and charges.
        </Text>
        <List>
          <List.Item>
            ${establishmentFee} establishment fee for processing your application when you open your <GemVisa /> account
          </List.Item>
          <List.Item>
            ${annualFee} annual account fee for maintaining your account which is charged ${biAnnualFee?.toFixed(2)}{' '}
            every six months from the date your account was opened (in arrears).
          </List.Item>
          <List.Item>${paperStatementFee} paper statement for sending your monthly statement by post</List.Item>
          <List.Item>
            ${atmWithdrawalFee} ATM withdrawal fee for processing an ATM withdrawal transaction at any New Zealand ATM
          </List.Item>
          <List.Item>
            ${atmWithdrawalFeeInternational?.toFixed(2)} ATM withdrawal fee for processing an ATM withdrawal transaction
            at any international ATM
          </List.Item>
          <List.Item>
            ${cashWithdrawalFee} cash withdrawal fee for processing an over the counter withdrawal request.
          </List.Item>
          <List.Item>
            ${nzPostHandlingFee?.toFixed(2)} payment handling fee for over the counter payments at NZ Post Shop branches
          </List.Item>
          <List.Item>${statementReprintFee} statement reprint fee for any statement copy provided to you</List.Item>
          <List.Item>
            ${replacementCardFee} replacement card fee for any replacement card issued to you or an additional
            cardholder
          </List.Item>
          <List.Item>
            An international transaction fee of {internationalTransactionFee}% of the New Zealand dollar converted value
            of any transaction on your account denominated in a currency other than New Zealand dollars, or any
            transaction on your account where Visa designates the supplier of the relevant goods or services to be
            located in a country other than New Zealand, regardless of whether the transaction is denominated in New
            Zealand dollars.
          </List.Item>
        </List>
      </Section>

      <Section>
        <SectionTitleBox>
          <Text textStyle="heading5">What could happen if you fail to meet your commitments</Text>
        </SectionTitleBox>
        <Text>
          In the event of a default in payment and while the default continues you may be required (if notified to do so
          by Latitude) to immediately repay all amounts outstanding under this contract.
        </Text>
      </Section>
      <Section>
        <SectionTitleBox>
          <Text textStyle="heading5">Default fees</Text>
        </SectionTitleBox>
        <Text>
          In the event of a breach of this contract the default fee specified below may be payable. This contract allows
          the creditor to vary this fee or introduce new fees.
        </Text>
        <List type="unordered">
          <List.Item>${lateFee} late payment fee where any payment is not made in full by its due date.</List.Item>
        </List>
      </Section>
      <Section>
        <SectionTitleBox>
          <Text textStyle="heading5">Right to cancel</Text>
        </SectionTitleBox>
        <Text>
          You are entitled to cancel this contract by giving notice to the creditor within a short time of receiving
          this form.
        </Text>
        <div>
          <Text textStyle="heading6">How to cancel:</Text>
          <Text>
            If you want to cancel this contract, you must give written notice to Latitude. You must also return to
            Latitude any advance and any other property received by you under the contract.
          </Text>
        </div>
        <div>
          <Text textStyle="heading6">Time Limits for Cancellation:</Text>
          <Text>
            You must give notice that you intend to cancel within 10 working days of entering into the contract.
            Saturdays, Sundays, and national public holidays are not counted as working days.
          </Text>
        </div>
        <div>
          <Text textStyle="heading6">What you may have to pay if you cancel:</Text>
          <Text>If you cancel the contract Latitude can charge you:</Text>
          <BracketList>
            <BracketList.Item>
              The amount of any reasonable expenses Latitude had to pay in connection with the contract and its
              cancellation (including legal fees and fees for credit reports, etc); and
            </BracketList.Item>
            <BracketList.Item>
              Any interest for the period from the date of any transaction made on your account until the transaction
              amount is paid in full.
            </BracketList.Item>
          </BracketList>
        </div>
      </Section>
      <Section>
        <SectionTitleBox>
          <Text textStyle="heading5">What to do if you suffer unforeseen hardship</Text>
        </SectionTitleBox>
        <div>
          <Text>
            If you are unable to keep up your payments because of an unexpected event that causes you hardship, for
            example illness, injury, loss of employment or the end of a relationship, you can apply to the creditor for
            a hardship variation. To apply for a hardship variation you need to make a request to Latitude in writing
            which explains your situation and requests one of the following variations:
          </Text>
          <BracketList>
            <BracketList.Item>
              reducing the amount of each payment due under the contract (without a consequential change being made to
              the annual interest rate(s)); or
            </BracketList.Item>
            <BracketList.Item>
              giving you longer to pay by postponing, during a specified period, the dates on which payments are due
              under the contract (without a consequential change being made to the annual interest rate(s)); or
            </BracketList.Item>
            <BracketList.Item>
              both of the above - postponing payments for a specified time and reducing the amount of your payments
            </BracketList.Item>
          </BracketList>
        </div>
        <Text>
          {`Do this as soon as possible because, if you leave it for too long, the creditor does not have to consider your
          application. For further information, please contact our Hardship team on ${config.phoneNumbers.hardship}.`}
        </Text>
      </Section>
      <Section>
        <SectionTitleBox>
          <Text textStyle="heading5">Disputes resolution</Text>
        </SectionTitleBox>
        <div>
          <Text>If you wish to make a complaint you can:</Text>
          <Text>
            <b>Telephone</b>:{' '}
            <PhoneNumberLink
              id="disputes"
              onClick={() =>
                trackEventSender({
                  category: 'contact-us',
                  action: 'phone-link',
                  location: 'How to contact us',
                  label: config.phoneNumbers.disputes,
                })
              }
            />
          </Text>

          <Text>or</Text>

          <Text>
            <b>write to</b>: Latitude Financial Services Limited at the above address, Attn: Customer Resolution Team,
          </Text>

          <Text>or</Text>

          <Text>
            <b>email</b>: drt.nz@gemfinance.co.nz
          </Text>
        </div>
        <div>
          <Text>
            If your complaint remains unresolved, you may refer the matter to the Insurance & Financial Services
            Ombudsman Scheme. Their contact details are:
          </Text>
          <Text>
            <b>Telephone</b>:{' '}
            <PhoneNumberLink
              id="ombudsman"
              onClick={() =>
                trackEventSender({
                  category: 'contact-us',
                  action: 'phone-link',
                  location: 'Disputes resolution',
                  label: config.phoneNumbers.ombudsman,
                })
              }
            />{' '}
            or{' '}
            <PhoneNumberLink
              id="ombudsmanAlt"
              onClick={() =>
                trackEventSender({
                  category: 'contact-us',
                  action: 'phone-link',
                  location: 'Disputes resolution',
                  label: config.phoneNumbers.ombudsmanAlt,
                })
              }
            />
          </Text>

          <Text>or</Text>

          <Text>
            <b>email</b>: info@ifso.nz
          </Text>

          <Text>or</Text>

          <Text>
            <b>write to</b>: Office of the IFSO, PO Box 10-845, Wellington 6143.
          </Text>
        </div>
      </Section>
    </Stack>
  );
};

const Left = ({ children, ...props }) => (
  <Grid.Item colSpan="all" colSpan-md="1-2" {...props}>
    <Container padding="0" padding-md="0 3 0 0">
      {children}
    </Container>
  </Grid.Item>
);

const Right = ({ children, ...props }) => (
  <Grid.Item colSpan="all" colSpan-md="3-4" {...props}>
    <Container padding="0" padding-md="0 0 0 3">
      {children}
    </Container>
  </Grid.Item>
);

const GemVisa = () => (
  <i>
    <b>Gem Visa</b>
  </i>
);
