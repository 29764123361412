import React from 'react';
import { Footer, Link, Text } from 'basis';
import { FooterSources } from '_config/_constants/externalLinks';
import { config } from '_config';

function DefaultFooter({ trackEventSender, getLinkWithAnalytics }) {
  return (
    <Footer>
      <Footer.Header>
        <Footer.Header.Logo name={config.countryCode === 'NZ' ? 'gem' : 'latitude'} />
      </Footer.Header>
      <Footer.Legal>
        <Footer.Legal.Links>
          {Object.keys(FooterSources).map((key, index) => {
            return (
              <Link
                key={index}
                href={getLinkWithAnalytics(key)}
                newTab
                onClick={() => trackEventSender(FooterSources[key].label)}
              >
                {FooterSources[key].label}
              </Link>
            );
          })}
        </Footer.Legal.Links>
        <Footer.Legal.Copy>
          <Text>Gem Visa is provided by Latitude Financial Services Limited.</Text>
        </Footer.Legal.Copy>
      </Footer.Legal>
    </Footer>
  );
}

export { DefaultFooter };
