import { convertRateToMonthlyNumber } from 'utils/convertRateToMonthly';
import { calculatedTotalMonthlyExpenses } from 'utils/expensesFooterHelper';
import 'types';

const CREDIT_CARD_LIMIT_PERCENTAGE = 3.82; // 3.82% of the total credit card(s) limit.

/**
 * @name getNegativeCapacity
 * @typedef {Object} NegativeCapacityValues
 * @property {number} monthlyTotalIncome - The total monthly income
 * @property {number} monthlyLivingExpenses - The total monthly living expenses
 * @property {number} totalCardLimits - The total credit card(s) limit
 * @property {number} monthlyInvestmentPropertyRepayments - The total monthly investment property repayments
 * @property {number} monthlyOtherLoanRepayments - The total monthly other loan repayments
 * @property {boolean} isNegativeCapacity - Whether the user has a negative capacity
 * @typedef {Object} FormValues
 * @property {string} hasOtherDebts - Whether the user has other debts
 * @property {object} secondaryPropertyMortgageRentBoard - The secondary property mortgage, rent or board
 * @property {string} secondaryPropertyMortgageRentBoard.amount - The secondary property mortgage, rent or board amount
 * @property {string} secondaryPropertyMortgageRentBoard.frequency - The secondary property mortgage, rent or board frequency
 * @property {string} totalCardLimits - The total credit card(s) limit
 * @property {string} amountOwingOnCreditCard - The amount owing on credit card(s)
 * @property {Object} loanRepayment - The loan repayment
 * @property {string} loanRepayment.amount - The loan repayment amount
 * @property {string} loanRepayment.frequency - The loan repayment frequency
 * @property {string} hasLoanLumpSumPayment - Whether the user has a loan lump sum payment
 * @property {string} payLumpSumWithExistingSaving - Whether the user pays a lump sum with an existing savings account
 * @param {storeState} storeState - The store state
 * @param {FormValues} formValues - The Additional debts details form values
 * @returns {NegativeCapacityValues}
 */
const getNegativeCapacity = (storeState, formValues) => {
  const {
    acquisition: { employmentDetails, expensesDetails },
  } = storeState;
  const hasOtherDebts = formValues.hasOtherDebts === 'yes';
  const monthlyIncome = convertRateToMonthlyNumber(employmentDetails.income);
  const monthlyOtherIncome = convertRateToMonthlyNumber(employmentDetails.otherIncome);
  const monthlyTotalIncome = monthlyIncome + monthlyOtherIncome;
  const monthlyLivingExpenses = calculatedTotalMonthlyExpenses(expensesDetails);
  const totalCardLimits = hasOtherDebts ? parseFloat(formValues.totalCardLimits) : 0;
  const calculatedMonthlyCreditCardRepayment = (CREDIT_CARD_LIMIT_PERCENTAGE * totalCardLimits) / 100;
  const monthlyInvestmentPropertyRepayments = hasOtherDebts
    ? convertRateToMonthlyNumber(formValues.secondaryPropertyMortgageRentBoard)
    : 0;
  const monthlyOtherLoanRepayments = hasOtherDebts ? convertRateToMonthlyNumber(formValues.loanRepayment) : 0;
  const monthlyTotalExpenses =
    monthlyLivingExpenses +
    monthlyInvestmentPropertyRepayments +
    monthlyOtherLoanRepayments +
    calculatedMonthlyCreditCardRepayment;

  return {
    monthlyIncome: monthlyTotalIncome,
    monthlyLivingExpenses,
    totalCardLimits,
    monthlyInvestmentPropertyRepayments,
    monthlyOtherLoanRepayments,
    isNegativeCapacity: monthlyTotalIncome < monthlyTotalExpenses,
  };
};

export { getNegativeCapacity };
