import React from 'react';
import { useStoreValue, APOLLO_GRAPHQL_ERROR } from 'store';
import { useHistory } from 'react-router-dom';
import { OKTA_ERROR_SESSION_EXPIRED_MESSAGE } from 'store/constants';
import { STEP_OUTCOME_TIMEOUT } from '_config';
import { useOktaClient } from 'hooks';
import { SystemUnavailable } from './SystemUnavailable';
import { NetworkErrors } from './NetworkErrors';

export function AUErrors({ applicationRef, retry, retrying, isPreSubmission = false }) {
  const history = useHistory();
  const { softLogout } = useOktaClient();
  const [storeState] = useStoreValue();

  // if okta session timeout, redirect to outcome timeout page
  if (storeState.applicationErrors?.error?.message === OKTA_ERROR_SESSION_EXPIRED_MESSAGE) {
    softLogout();
    history.push(STEP_OUTCOME_TIMEOUT, { isOktaSessionExpired: true });
  }

  // Display pre-submission network error page if retry is specified network error is not null.
  // However if error type starts with APOLLO, display system unavailable page instead.
  if (isPreSubmission) {
    if (retry && storeState.applicationErrors?.type !== APOLLO_GRAPHQL_ERROR) {
      return <NetworkErrors applicationRef={applicationRef} retry={retry} retrying={retrying} />;
    }
  }

  return <SystemUnavailable applicationRef={applicationRef} isPreSubmission={isPreSubmission} />;
}
