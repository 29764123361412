import React from 'react';
import { Button, Container, Text, Grid, Message } from 'basis';
import { Modal } from 'components/modal/Modal';
import { YellowMessage } from 'components/message/YellowMessage';

export const SaveAndCompleteLaterModal = ({ isOpen, onClose, onContinue, isSaving = false, infoMessage }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} style={{ maxWidth: 420 }}>
      <Container padding="11 8 8 8">
        <div style={{ textAlign: 'center' }}>
          <Text textStyle="heading5">Are you sure?</Text>
        </div>
        <Container padding="4 0">
          <Text>Are you sure you want to save and complete your application later?</Text>
        </Container>

        {infoMessage && (
          <Container margin="0 0 4 0">
            <YellowMessage>
              <Message testId="yellow-message" padding="3 3 3 4" severity="warning-or-significant">
                <Text>{infoMessage}</Text>
              </Message>
            </YellowMessage>
          </Container>
        )}

        <Grid rowsGap="4" colsGap="2">
          <Grid.Item colSpan="all" colSpan-sm="0-1">
            <Grid>
              <Button testId="editButton" type="button" variant="secondary" onClick={onClose}>
                No, cancel
              </Button>
            </Grid>
          </Grid.Item>
          <Grid.Item colSpan="all" colSpan-sm="2-3" alignItems="center">
            <Grid>
              <Button onClick={onContinue} loading={isSaving}>
                Yes, continue
              </Button>
            </Grid>
          </Grid.Item>
        </Grid>
      </Container>
    </Modal>
  );
};
