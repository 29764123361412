import React from 'react';
import { useStoreValue } from 'store';
import { LayoutPage, LayoutContent, FormRenderer, Form } from 'components';
import { initFormConfig, submitHandler } from 'utils';
import { config } from '_config';
import { useFormInitialValues, useSteps } from '../../hooks';

const FORM_ID = 'biometricsIdentification';
const formConfig = initFormConfig(config.formConfigs[FORM_ID]);

export function BiometricsIdentification() {
  const [storeState] = useStoreValue();
  const initialValues = useFormInitialValues(FORM_ID);
  const { goNext } = useSteps();

  const onSubmit = ({ values, errors }) => {
    function updateStoreAndRedirect() {
      const newAcquisition = {
        ...storeState.acquisition,
        biometricsIdentification: { ...values },
      };
      goNext({
        storePatch: { acquisition: newAcquisition },
        dataLayerEventLabel: 'Next',
      });
    }

    submitHandler({ submit: updateStoreAndRedirect, errors });
  };

  return (
    <LayoutPage noSessionTimeout>
      <Form
        id={FORM_ID}
        initialValues={initialValues}
        onSubmit={onSubmit}
        showSaveDraftButton
        saveDraftOnSubmit
        saveDraftOnSessionTimeout
      >
        {formData => {
          return (
            <LayoutContent>
              <FormRenderer config={formConfig} formData={formData} storeState={storeState} />
            </LayoutContent>
          );
        }}
      </Form>
    </LayoutPage>
  );
}
