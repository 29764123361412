import { byCountry } from 'utils/byConfig';

const AU_INTERNATIONAL = '+61';
const NZ_INTERNATIONAL = '+64';
const IN_INTERNATIONAL = '+91';

const AU_LOCAL = '04';
const NZ_LOCAL = '02';

export const toLocalNumber = mobileNumber => {
  if (mobileNumber.startsWith(NZ_INTERNATIONAL)) {
    return byCountry({
      AU:
        process.env.REACT_APP_ENV === 'prod'
          ? mobileNumber.replace(NZ_INTERNATIONAL, '0')
          : // create a fake AU number to pass non-prod AU backend validations.
            mobileNumber.replace(NZ_INTERNATIONAL, AU_LOCAL),
      NZ: mobileNumber.replace(NZ_INTERNATIONAL, '0'),
    });
  }
  if (mobileNumber.startsWith(AU_INTERNATIONAL)) {
    return byCountry({
      AU: mobileNumber.replace(AU_INTERNATIONAL, '0'),
      NZ:
        process.env.REACT_APP_ENV === 'prod'
          ? mobileNumber.replace(AU_INTERNATIONAL, '0')
          : // create a fake NZ number to pass non-prod NZ backend validations.
            mobileNumber.replace(AU_INTERNATIONAL, NZ_LOCAL),
    });
  }
  if (mobileNumber.startsWith(IN_INTERNATIONAL) && process.env.REACT_APP_ENV !== 'prod') {
    return byCountry({
      // create a fake AU or NZ number to pass non-prod backend validations.
      AU: '0412345678',
      NZ: '021234567',
    });
  }
  return mobileNumber;
};

export const toInternationalNumber = mobileNumber => {
  if (mobileNumber.startsWith(NZ_LOCAL)) {
    // if mobile number starts with 02 then it is a NZ number and should replace `0` with `+64`
    return mobileNumber.replace('0', NZ_INTERNATIONAL);
  }

  if (mobileNumber.startsWith(AU_LOCAL)) {
    return mobileNumber.replace('0', AU_INTERNATIONAL);
  }

  return mobileNumber;
};
