export const VerifyIncomeContent = {
  AU: {
    subHeader: 'You’re almost there! We just need to verify a few things before we finalise your application.',
    fastestWay: '',
    verifyFiConnectButtonLabel: 'Verify online',
  },
  NZ: {
    subHeader: 'NZ legislation requires us to verify your income.',
    fastestWay: 'The fastest way to get an outcome',
    pleaseSelectHeader: '',
    verifyFiConnectButtonLabel: 'Share bank statement',
  },
};
